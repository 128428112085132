import { default as LightList } from '@/Types/Resources/CheckList/Light';
import { default as LightCheck } from '@/Types/Resources/Check/Light';
import { default as LightCard } from '@/Types/Resources/Card/Light';
import { updateLightCardFunction } from '@/Pages/Boards/Types';
import { sendRequest } from '@/mixins';
import { computed, Ref } from 'vue';

export function useCheckDragControl(lists: Ref<LightList[]>, card: Ref<LightCard|null>, checkIsDragging: Ref<boolean>, handleUpdateCard: updateLightCardFunction)
{
  const checkDragOptions = computed(() => ({
    group: { name: 'checks', pull: true },
    ghostClass: 'check-ghost',
    filter: '.check-no-drag',
    emptyInsertThreshold: 50,
    handle: 'check-handle',
    preventOnFilter: false,
    forceFallback: true,
    animation: 200,
    itemKey: 'id',
    delay: 15
  }));

  function onCheckPositionChange(event: any): void
  {
    if((event.added || event.moved)?.element){
      sendRequest({
        name: 'api.v1.boards.cards.checklists.checks.positions.update',
        data: {
          checks: lists.value.map((list: LightList) => {
            return list.checks.map((check: LightCheck) => {
              return {
                id: check.id,
                list_id: list.id,
                content: check.content,
                is_completed: check.is_completed,
                position: check.position,
              }
            });
          }).flat()
        }
      });

      handleUpdateCard({...card.value, checklists: lists.value} as LightCard);
    }
  }
  
  function onCheckDragStart(): void
  {
    checkIsDragging.value = true;
  }

  function onCheckDragEnd(): void
  {
    setTimeout(() => checkIsDragging.value = false, 500);
  }

  return {
    onCheckPositionChange,
    checkDragOptions,
    onCheckDragStart,
    onCheckDragEnd
  };
}