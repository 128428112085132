import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "sticky bg-white dark:bg-gray-900 border-gray-200 dark:border-gray-750 top-[-1px] z-40 flex h-16 shrink-0 items-center gap-x-4 border-b px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-4" }
const _hoisted_2 = { class: "flex flex-1 gap-x-4 self-stretch lg:gap-x-6 justify-end" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = {
  key: 0,
  class: "inline-flex absolute -top-2 -right-2 justify-center items-center w-6 h-6 text-nano font-bold text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900"
}
const _hoisted_5 = { class: "block py-2 px-4 cursor-default text-base font-medium text-center text-gray-900 dark:text-gray-300 border-b border-gray-100 dark:border-gray-750" }
const _hoisted_6 = {
  key: 0,
  class: "block py-2 px-4 text-sm font-normal text-center text-gray-700 dark:text-gray-400"
}
const _hoisted_7 = { class: "text-center" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "flex-shrink-0" }
const _hoisted_10 = {
  key: 0,
  class: "inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200"
}
const _hoisted_11 = {
  key: 1,
  class: "inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200"
}
const _hoisted_12 = {
  key: 2,
  class: "inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200"
}
const _hoisted_13 = {
  key: 3,
  class: "inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-lg dark:bg-blue-700 dark:text-blue-200"
}
const _hoisted_14 = { class: "pl-2 w-full" }
const _hoisted_15 = { class: "text-gray-500 font-normal text-sm dark:text-gray-400 break-words" }
const _hoisted_16 = { class: "font-semibold text-gray-900 dark:text-white" }
const _hoisted_17 = { class: "text-nano font-medium text-primary-700 dark:text-primary-400" }
const _hoisted_18 = { class: "inline-flex items-center" }
const _hoisted_19 = ["src"]
const _hoisted_20 = {
  class: "select-none py-3 px-4 border-b border-gray-100 dark:border-gray-750",
  role: "none"
}
const _hoisted_21 = {
  class: "text-sm truncate text-gray-900 dark:text-white",
  role: "none"
}
const _hoisted_22 = {
  class: "pb-1",
  role: "none"
}
const _hoisted_23 = { class: "select-none py-2 px-4 text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-white" }
const _hoisted_24 = ["onClick"]
const _hoisted_25 = { class: "cursor-pointer text-sm block" }
const _hoisted_26 = { class: "text-sm flex-none text-gray-500 dark:text-gray-400" }
const _hoisted_27 = { class: "select-none flex border-t border-gray-100 dark:border-gray-750 py-2 px-4 text-red-700 dark:hover:text-white dark:hover:bg-red-800 hover:bg-red-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BellIcon = _resolveComponent("BellIcon")!
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_ExclamationTriangleIcon = _resolveComponent("ExclamationTriangleIcon")!
  const _component_InformationCircleIcon = _resolveComponent("InformationCircleIcon")!
  const _component_EyeIcon = _resolveComponent("EyeIcon")!
  const _component_Link = _resolveComponent("Link")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_CommandPalette = _resolveComponent("CommandPalette")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default"),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Menu, {
            as: "div",
            class: "ml-2 relative"
          }, {
            default: _withCtx(({ close }) => [
              _createElementVNode("div", null, [
                _createVNode(_component_MenuButton, { class: "p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-700" }, {
                  default: _withCtx(() => [
                    _cache[0] || (_cache[0] = _createElementVNode("span", { class: "sr-only" }, "View notifications", -1)),
                    _createVNode(_component_BellIcon, {
                      class: "h-6 w-6",
                      "aria-hidden": "true"
                    }),
                    (_ctx.new_notifications)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.new_notifications_length), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_Transition, {
                "enter-active-class": "transition ease-out duration-100",
                "enter-from-class": "transform opacity-0 scale-95",
                "enter-to-class": "transform opacity-100 scale-100",
                "leave-active-class": "transition ease-in duration-75",
                "leave-from-class": "transform opacity-100 scale-100",
                "leave-to-class": "transform opacity-0 scale-95"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MenuItems, { class: "bg-gray-50 dark:bg-gray-850 border border-gray-200 dark:border-gray-750 origin-top-right w-72 absolute mt-2 right-0 py-1 my-4 max-w-sm text-base list-none rounded divide-gray-100 shadow-sm dark:divide-gray-750" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$trans('main.notifications_title')), 1),
                      (!_ctx.notifications?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$trans('main.notifications_empty')), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications || [], (notification) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: notification.uuid,
                                class: _normalizeClass([{'last:border-b-0': !_ctx.new_notifications}, "break-all flex cursor-default py-1 px-3 border-b hover:bg-gray-100 dark:hover:bg-gray-750 dark:border-gray-750"])
                              }, [
                                _createElementVNode("div", _hoisted_9, [
                                  (notification.type === 'success')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                        _createVNode(_component_CheckIcon, { class: "w-5 h-5" })
                                      ]))
                                    : (notification.type === 'error')
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                          _createVNode(_component_XMarkIcon, { class: "w-5 h-5" })
                                        ]))
                                      : (notification.type === 'warning')
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                            _createVNode(_component_ExclamationTriangleIcon, { class: "w-5 h-5" })
                                          ]))
                                        : (notification.type === 'info')
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                              _createVNode(_component_InformationCircleIcon, { class: "w-5 h-5" })
                                            ]))
                                          : _createCommentVNode("", true)
                                ]),
                                _createElementVNode("div", _hoisted_14, [
                                  _createElementVNode("div", _hoisted_15, [
                                    _createElementVNode("span", _hoisted_16, _toDisplayString(notification.title), 1),
                                    _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                                    _createTextVNode(" " + _toDisplayString(notification.description), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_17, _toDisplayString(notification.format_created_at), 1)
                                ])
                              ], 2))
                            }), 128))
                          ])),
                      (_ctx.new_notifications)
                        ? (_openBlock(), _createBlock(_component_Link, {
                            key: 2,
                            onClick: () => close(),
                            as: "div",
                            method: ('post' as Method),
                            href: _ctx.route('notifications.read'),
                            class: "block cursor-pointer pt-2 text-sm font-normal text-center text-gray-900 bg-gray-50 hover:bg-gray-100 dark:bg-gray-850 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:underline"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_18, [
                                _createVNode(_component_EyeIcon, { class: "mr-2 w-5 h-5" }),
                                _createTextVNode(" " + _toDisplayString(_ctx.$trans('main.notifications_read_all')), 1)
                              ])
                            ]),
                            _: 2
                          }, 1032, ["onClick", "href"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          }),
          _createVNode(_component_Menu, {
            as: "div",
            class: "ml-4 relative"
          }, {
            default: _withCtx(({close}) => [
              _createElementVNode("div", null, [
                _createVNode(_component_MenuButton, { class: "max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-700" }, {
                  default: _withCtx(() => [
                    _cache[2] || (_cache[2] = _createElementVNode("span", { class: "sr-only" }, "Open user menu", -1)),
                    _createElementVNode("img", {
                      class: _normalizeClass(["block h-8 w-8 rounded-full", [ _ctx.auth.user?.photo_path ? 'object-cover' : 'dark:filter-white']]),
                      src: _ctx.auth.user?.photo_path ? _ctx.auth.user.photo_path : '/dist/images/user-placeholder.svg'
                    }, null, 10, _hoisted_19)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_Transition, {
                "enter-active-class": "transition ease-out duration-100",
                "enter-from-class": "transform opacity-0 scale-95",
                "enter-to-class": "transform opacity-100 scale-100",
                "leave-active-class": "transition ease-in duration-75",
                "leave-from-class": "transform opacity-100 scale-100",
                "leave-to-class": "transform opacity-0 scale-95"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MenuItems, { class: "border border-gray-200 dark:border-gray-750 origin-top-right absolute mt-2 right-0 w-48 py-1 text-base list-none bg-white rounded divide-gray-100 shadow-sm dark:bg-gray-850 dark:divide-gray-750" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.auth.user?.name), 1),
                        _createElementVNode("p", {
                          class: _normalizeClass(["text-sm", {'text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-pink-500 to-teal-400': _ctx.auth.user?.role.value == 'super_admin',
                              'text-primary-700 dark:text-primary-500': _ctx.auth.user?.role.value == 'admin',
                              'text-teal-700 dark:text-teal-500': _ctx.auth.user?.role.value == 'moderator',
                              'text-gray-700 dark:text-gray-500': _ctx.auth.user?.role.value == 'user'}])
                        }, _toDisplayString(_ctx.auth.user?.role.name), 3)
                      ]),
                      _createElementVNode("ul", _hoisted_22, [
                        _createElementVNode("li", _hoisted_23, [
                          _createVNode(_component_Link, {
                            href: _ctx.route('profile.edit'),
                            class: "cursor-pointer block text-sm"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$trans("main.settings")), 1)
                            ]),
                            _: 1
                          }, 8, ["href"])
                        ]),
                        _createElementVNode("li", {
                          onClick: ($event: any) => (_ctx.setCmdOpen(true, close)),
                          class: "select-none flex justify-between py-2 px-4 hover:bg-gray-100 text-gray-700 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-white"
                        }, [
                          _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$trans("cmd.title")), 1),
                          _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.isMac() ? '⌘ K' : 'Ctrl K'), 1)
                        ], 8, _hoisted_24),
                        _createElementVNode("li", _hoisted_27, [
                          _createVNode(_component_Link, {
                            method: "delete",
                            href: _ctx.route('logout'),
                            as: "button",
                            class: "cursor-pointer flex-auto truncate block w-full text-sm text-left"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$trans('main.logout')), 1)
                            ]),
                            _: 1
                          }, 8, ["href"])
                        ])
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createVNode(_component_CommandPalette, {
      isOpen: _ctx.cmdOpen,
      close: (value) => _ctx.cmdOpen = value
    }, null, 8, ["isOpen", "close"])
  ], 64))
}