<template>
  <Head :title="$trans('table.commands.title')"/>
  <Layout :body-classes="['bg-white', 'dark:bg-gray-900', 'h-full']"
          :main-classes="['bg-white', 'dark:bg-gray-900']">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="mt-8 flow-root">
        <div class="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 overflow-x-scroll">
          <div class="inline-block min-w-full py-2 align-middle">
            <table class="min-w-full border-separate border-spacing-0">
              <thead>
                <tr>
                  <th v-html="$trans('table.commands.head.id')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:table-cell sm:pl-6 lg:pl-8"></th>
                  <th v-html="$trans('table.commands.head.pid')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"></th>
                  <th v-html="$trans('table.commands.head.user')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"></th>
                  <th v-html="$trans('table.commands.head.command')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"></th>
                  <th v-html="$trans('table.commands.head.status')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"></th>
                  <th v-html="$trans('table.commands.head.started_at')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"></th>
                  <th v-html="$trans('table.commands.head.finished_at')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"></th>
                  <th scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(command, commandIdx) in commands?.data" :key="command.id">
                  <td :class="[commandIdx !== commands.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 lg:pl-8']">
                    {{ command.id }}
                  </td>
                  <td :class="[commandIdx !== commands.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 text-center whitespace-nowrap uppercase py-4 px-4 text-sm font-medium']">
                    {{ command.pid ?? $trans('table.shared.no_data') }}
                  </td>
                  <td :class="[commandIdx !== commands.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 text-center whitespace-nowrap py-4 px-4 text-sm font-medium']">
                    {{ command.user.name }}
                  </td>
                  <td :class="[commandIdx !== commands.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 text-center whitespace-nowrap py-4 px-4 text-sm font-medium']">
                    {{ command.value ?? $trans('table.shared.no_data')  }}
                  </td>
                  <td :class="[commandIdx !== commands.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500']">
                    <Badge :type="getBadgeType(command.status)" :text="$trans(`main.commands.statuses.${command.status}`)"/>
                  </td>
                  <td :class="[commandIdx !== commands.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 lg:pl-8']">
                    {{ command.started_at ?? $trans('table.shared.no_data') }}
                  </td>
                  <td :class="[commandIdx !== commands.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 lg:pl-8']">
                    {{ command.finished_at ?? $trans('table.shared.no_data') }}
                  </td>
                  <td :class="[commandIdx !== commands.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 relative whitespace-nowrap py-4 px-2 text-right text-sm font-medium sm:pr-8 lg:pr-8']">
                    <span @click="show(command)" class="text-primary-600 dark:text-primary-800 hover:text-primary-900 dark:hover:text-primary-700 cursor-pointer pr-1">
                      {{$trans('table.shared.show')}}
                    </span>
                    <Link v-if="command.status === 'running'" as="span" :href="route('command.stop', {'command': command?.id})" method="put" preserve-scroll class="text-yellow-600 dark:text-yellow-800 hover:text-yellow-900 dark:hover:text-yellow-700 cursor-pointer pr-1">
                      {{$trans('main.commands.buttons.stop')}}
                      <br class="sm:hidden"/>
                    </Link>
                    <Link v-if="command.status === 'completed' || command.status === 'failed' || command.status === 'stopped'" as="span" :href="route('command.destroy', {'command': command?.id})" method="delete" preserve-scroll class="text-red-600 dark:text-red-800 hover:text-red-900 dark:hover:text-red-700 cursor-pointer">
                      {{$trans('table.shared.delete')}}
                      <br class="sm:hidden"/>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="!commands?.data?.length" class="flex items-center justify-center my-48">
              <h2 class="text-gray-400 dark:text-gray-300 box-border block text-2xl leading-5 relative text-center">
                {{ $trans('table.commands.placeholder') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Pagination v-if="commands?.data?.length" :data="commands"/>
  </Layout>
  <Show :command="showedCommand"
    :isOpen="drawerOpen" 
    :onClose="close"/>
</template>
<script lang="ts">
import { default as BaseCommand } from '@/Types/Resources/Command/Base';
import { defineComponent, ref, PropType, onMounted, watch } from "vue";
import Breadcrumb from '@js/Shared/Breadcrumb.vue';
import Pagination from '@js/Shared/Pagination.vue';
import Show from '@js/Pages/Commands/Show.vue';
import Paginator from '@/Types/App/Paginator';
import { Head, Link } from '@inertiajs/vue3';
import Layout from '@js/Shared/Layout.vue';
import Badge from '@js/Shared/Badge.vue';
import { router } from '@inertiajs/vue3';
import { truncateText } from '@/mixins';
import Icon from '@js/Shared/Icon.vue';
import route from '@/ziggy';

export default defineComponent({
  props: {
    command: {
      type: [Object, null] as PropType<BaseCommand|null>,
      required: false,
    },
    commands: {
      type: Object as () => Paginator<BaseCommand>,
      required: true,
    }
  },
  components: {
    Pagination,
    Breadcrumb,
    Layout,
    Badge,
    Icon,
    Link,
    Show,
    Head,
  },
  setup(props) {
    const commands = ref<Paginator<BaseCommand>>(props.commands);
    const drawerOpen = ref<Boolean>(false);
    const showedCommand = ref<BaseCommand | null>(null);

    watch(() => props.commands, (newCommands: Paginator<BaseCommand>) => {
      commands.value = newCommands;
    });

    function show(command: BaseCommand): void
    {
      showedCommand.value = null;

      router.get(route('commands', { command_id: command.id}), {}, {
        preserveState: true,
        only: ['command'],
        onSuccess: (page) => {
          showedCommand.value = page.props.command as BaseCommand;
          drawerOpen.value = true;
        },
      });
    }

    function getBadgeType(status: BaseCommand['status']): 'success' | 'warning' | 'attempt' | 'error' | 'info'
    {
      switch (status) {
        case 'queued':
          return 'warning';
        case 'running':
          return 'warning';
        case 'completed':
          return 'success';
        case 'failed':
          return 'error';
        case 'stopping':
          return 'info';
        case 'stopped':
          return 'info';
        default:
          return 'info';
      }
    }

    function close(): void
    {
      drawerOpen.value = false;

      router.get(route('commands'), {}, {
        preserveState: true,
        only: ['commands'],
        onSuccess: (page) => {
          commands.value = page.props.commands as Paginator<BaseCommand>;
        },
      });

      drawerOpen.value = false;

      setTimeout(() => {
        showedCommand.value = null;
      }, 500);
    }

    onMounted(() => {
      if(props.command !== null && props.command !== undefined) {
        show(props.command);
      }
    });

    return {
      showedCommand,
      getBadgeType,
      truncateText,
      drawerOpen,
      commands,
      close,
      route,
      show,
    }
  },
})
</script>