import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "px-4 sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "sm:flex sm:items-center" }
const _hoisted_3 = { class: "sm:flex-auto" }
const _hoisted_4 = { class: "text-base my-4 font-semibold leading-6 text-gray-900 dark:text-white" }
const _hoisted_5 = { class: "sm:flex" }
const _hoisted_6 = { class: "items-center mb-3 sm:flex sm:divide-x sm:divide-gray-100 sm:mb-0 dark:divide-gray-700" }
const _hoisted_7 = { class: "lg:pr-3" }
const _hoisted_8 = {
  for: "users-search",
  class: "sr-only"
}
const _hoisted_9 = { class: "relative lg:w-64 xl:w-96" }
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = { class: "flex items-center ml-auto space-x-2 sm:space-x-3" }
const _hoisted_12 = { class: "mt-8 flow-root" }
const _hoisted_13 = { class: "-mx-4 -my-2 sm:-mx-6 lg:-mx-8 overflow-x-scroll" }
const _hoisted_14 = { class: "inline-block min-w-full py-2 align-middle" }
const _hoisted_15 = { class: "min-w-full border-separate border-spacing-0" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = { class: "text-base font-semibold text-gray-900 dark:text-white" }
const _hoisted_23 = { class: "text-sm font-normal text-gray-500 dark:text-gray-400" }
const _hoisted_24 = { class: "text-base font-semibold text-gray-900 dark:text-white" }
const _hoisted_25 = { class: "text-sm font-normal text-gray-500 dark:text-gray-400" }
const _hoisted_26 = ["onClick"]
const _hoisted_27 = { class: "sr-only" }
const _hoisted_28 = { class: "sr-only" }
const _hoisted_29 = { class: "sr-only" }
const _hoisted_30 = ["onClick"]
const _hoisted_31 = { class: "sr-only" }
const _hoisted_32 = { class: "sr-only" }
const _hoisted_33 = {
  key: 0,
  class: "flex items-center justify-center my-48"
}
const _hoisted_34 = { class: "text-gray-400 dark:text-gray-300 box-border block text-2xl leading-5 relative text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Head = _resolveComponent("Head")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Link = _resolveComponent("Link")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_Layout = _resolveComponent("Layout")!
  const _component_Create = _resolveComponent("Create")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_Attempt = _resolveComponent("Attempt")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Head, {
      title: _ctx.$trans('table.users.title')
    }, null, 8, ["title"]),
    _createVNode(_component_Layout, {
      "body-classes": ['bg-white', 'dark:bg-gray-950', 'h-full'],
      "main-classes": ['bg-white', 'dark:bg-gray-950']
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$trans('table.users.title')), 1),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("label", _hoisted_8, _toDisplayString(`${_ctx.$trans('main.shared.search')}...`), 1),
                    _createElementVNode("div", _hoisted_9, [
                      _withDirectives(_createElementVNode("input", {
                        id: "users-search",
                        type: "text",
                        placeholder: `${_ctx.$trans('main.shared.search')}...`,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.search) = $event)),
                        class: "bg-gray-50 dark:bg-gray-850 dark:focus:ring-primary-500 dark:focus:border-primary-500 border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700 block w-full pr-10 focus:outline-none sm:text-sm rounded-md"
                      }, null, 8, _hoisted_10), [
                        [_vModelText, _ctx.form.search]
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("button", {
                    type: "button",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setCreateModalIsOpen(true))),
                    class: "bg-gray-900 dark:bg-primary-800 text-white dark:text-gray-100 hover:bg-gray-700 dark:hover:bg-primary-750 focus:ring-gray-700 dark:focus:ring-primary-950 focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent inline-flex items-center justify-center w-1/2 px-3 py-2 text-sm font-medium text-center rounded-lg sm:w-auto"
                  }, [
                    _createVNode(_component_Icon, {
                      name: "plus",
                      mainClass: "w-6 h-6 mr-2 -ml-1"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$trans('table.users.buttons.create_user')), 1)
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("table", _hoisted_15, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", {
                        innerHTML: _ctx.$trans('table.users.head.id'),
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 hidden border-b py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:table-cell sm:pl-6 lg:pl-8"
                      }, null, 8, _hoisted_16),
                      _createElementVNode("th", {
                        innerHTML: _ctx.$trans('table.users.head.html.name_email'),
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-left text-sm font-semibold lg:table-cell"
                      }, null, 8, _hoisted_17),
                      _createElementVNode("th", {
                        innerHTML: _ctx.$trans('table.users.head.html.country_language'),
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 hidden border-b px-3 py-3.5 text-left text-sm font-semibold lg:table-cell"
                      }, null, 8, _hoisted_18),
                      _createElementVNode("th", {
                        innerHTML: _ctx.$trans('table.users.head.html.role'),
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 hidden border-b px-3 py-3.5 text-left text-sm font-semibold lg:table-cell"
                      }, null, 8, _hoisted_19),
                      _createElementVNode("th", {
                        innerHTML: _ctx.$trans('table.users.head.html.last_activity'),
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 hidden border-b px-3 py-3.5 text-left text-sm font-semibold lg:table-cell"
                      }, null, 8, _hoisted_20),
                      _createElementVNode("th", {
                        innerHTML: _ctx.$trans('table.users.head.html.created_at'),
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 hidden border-b px-3 py-3.5 text-left text-sm font-semibold lg:table-cell"
                      }, null, 8, _hoisted_21),
                      _cache[2] || (_cache[2] = _createElementVNode("th", {
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"
                      }, null, -1))
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users?.data, (user, personIdx) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: user.email
                      }, [
                        _createElementVNode("td", {
                          class: _normalizeClass([personIdx !== _ctx.users.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap hidden sm:table-cell py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 lg:pl-8'])
                        }, _toDisplayString(user.id), 3),
                        _createElementVNode("td", {
                          class: _normalizeClass([personIdx !== _ctx.users.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 whitespace-nowrap px-3 py-4 text-sm text-gray-500'])
                        }, [
                          _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.truncateText(user.name, 30)), 1),
                          _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.truncateText(user.email, 30)), 1)
                        ], 2),
                        _createElementVNode("td", {
                          class: _normalizeClass([personIdx !== _ctx.users.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell'])
                        }, [
                          _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.truncateText(user.country_name, 20)), 1),
                          _createElementVNode("div", _hoisted_25, _toDisplayString(user.language ? _ctx.$trans(`main.shared.languages.${user.language}`) : _ctx.$trans('main.shared.undefined_up')), 1)
                        ], 2),
                        _createElementVNode("td", {
                          class: _normalizeClass({'border-b border-gray-200 dark:border-gray-850': personIdx !== _ctx.users.data.length - 1, 
                              'bg-white dark:bg-gray-950 text-gray-500 whitespace-nowrap hidden px-3 py-4 text-sm lg:table-cell': true,
                              'text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-pink-500 to-teal-400': user.role?.value == 'super_admin',
                              'text-primary-700 dark:text-primary-500': user.role?.value == 'admin',
                              'text-teal-700 dark:text-teal-500': user.role?.value == 'moderator',
                              'text-gray-700 dark:text-gray-500': user.role?.value == 'user' })
                        }, _toDisplayString(user.role?.name), 3),
                        _createElementVNode("td", {
                          class: _normalizeClass([personIdx !== _ctx.users.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell'])
                        }, _toDisplayString(user.last_activity ? user.last_activity : _ctx.$trans('main.shared.undefined_up')), 3),
                        _createElementVNode("td", {
                          class: _normalizeClass([personIdx !== _ctx.users.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell'])
                        }, _toDisplayString(user.created_at), 3),
                        _createElementVNode("td", {
                          class: _normalizeClass([personIdx !== _ctx.users.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 relative whitespace-nowrap py-4 px-2 text-right text-sm font-medium sm:pr-8 lg:pr-8'])
                        }, [
                          _createElementVNode("span", {
                            onClick: ($event: any) => (_ctx.setEditDrawerIsOpen(true, user)),
                            class: "text-primary-600 dark:text-primary-800 hover:text-primary-900 dark:hover:text-primary-700 cursor-pointer"
                          }, [
                            _createTextVNode(_toDisplayString(_ctx.$trans('table.shared.edit')) + " ", 1),
                            _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.$trans('table.users.buttons.edit_user')), 1),
                            _cache[3] || (_cache[3] = _createElementVNode("br", { class: "sm:hidden" }, null, -1))
                          ], 8, _hoisted_26),
                          (!user.block)
                            ? (_openBlock(), _createBlock(_component_Link, {
                                key: 0,
                                as: "span",
                                href: _ctx.route('users.block', {'user': user?.id}),
                                method: ('put' as Method),
                                "preserve-scroll": "",
                                class: "text-yellow-600 dark:text-yellow-800 hover:text-yellow-900 dark:hover:text-yellow-700 cursor-pointer"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$trans('table.shared.block')) + " ", 1),
                                  _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.$trans('table.shared.block')), 1),
                                  _cache[4] || (_cache[4] = _createElementVNode("br", { class: "sm:hidden" }, null, -1))
                                ]),
                                _: 2
                              }, 1032, ["href"]))
                            : _createCommentVNode("", true),
                          (user.block)
                            ? (_openBlock(), _createBlock(_component_Link, {
                                key: 1,
                                as: "span",
                                href: _ctx.route('users.unblock', {'user': user?.id}),
                                method: ('put' as Method),
                                "preserve-scroll": "",
                                class: "text-yellow-600 dark:text-yellow-800 hover:text-yellow-900 dark:hover:text-yellow-700 cursor-pointer"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$trans('table.shared.unblock')) + " ", 1),
                                  _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.$trans('table.shared.unblock')), 1),
                                  _cache[5] || (_cache[5] = _createElementVNode("br", { class: "sm:hidden" }, null, -1))
                                ]),
                                _: 2
                              }, 1032, ["href"]))
                            : _createCommentVNode("", true),
                          (!user.deleted_at)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 2,
                                onClick: ($event: any) => (_ctx.setDeleteModalIsOpen(true, user?.id)),
                                class: "text-red-600 dark:text-red-800 hover:text-red-900 dark:hover:text-red-700 cursor-pointer"
                              }, [
                                _createTextVNode(_toDisplayString(_ctx.$trans('table.shared.delete')) + " ", 1),
                                _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.$trans('table.shared.delete')), 1),
                                _cache[6] || (_cache[6] = _createElementVNode("br", { class: "sm:hidden" }, null, -1))
                              ], 8, _hoisted_30))
                            : _createCommentVNode("", true),
                          _withDirectives(_createVNode(_component_Link, {
                            as: "span",
                            href: _ctx.route('users.restore', {'user': user?.id}),
                            method: ('put' as Method),
                            "preserve-scroll": "",
                            class: "text-yellow-600 dark:text-yellow-800 hover:text-yellow-900 dark:hover:text-yellow-700 cursor-pointer"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$trans('table.shared.restore')) + " ", 1),
                              _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.$trans('table.shared.restore')), 1),
                              _cache[7] || (_cache[7] = _createElementVNode("br", { class: "sm:hidden" }, null, -1))
                            ]),
                            _: 2
                          }, 1032, ["href"]), [
                            [_vShow, user.deleted_at]
                          ])
                        ], 2)
                      ]))
                    }), 128))
                  ])
                ]),
                (!_ctx.users?.data?.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                      _createElementVNode("h2", _hoisted_34, _toDisplayString(_ctx.$trans('table.users.placeholder')), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]),
        (_ctx.users?.data?.length)
          ? (_openBlock(), _createBlock(_component_Pagination, {
              key: 0,
              data: _ctx.users
            }, null, 8, ["data"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_Create, {
      drawerIsOpen: (_ctx.createDrawerIsOpen as boolean),
      close: (value) => _ctx.createDrawerIsOpen = value,
      countries: _ctx.countries,
      languages: (_ctx.languages as Array<string>),
      roles: _ctx.roles
    }, null, 8, ["drawerIsOpen", "close", "countries", "languages", "roles"]),
    _createVNode(_component_Edit, {
      isOpen: (_ctx.editDrawerIsOpen as boolean),
      onClose: (value) => _ctx.editDrawerIsOpen = value,
      user: _ctx.editUser,
      countries: _ctx.countries,
      languages: _ctx.languages,
      roles: _ctx.roles
    }, null, 8, ["isOpen", "onClose", "user", "countries", "languages", "roles"]),
    _createVNode(_component_Attempt, {
      open: _ctx.deleteModalIsOpen,
      close: (value) => _ctx.deleteModalIsOpen = value,
      routeName: "users.destroy",
      routeArgument: { id: _ctx.deleteUserId },
      title: _ctx.$trans('main.attempts.delete_user.title'),
      description: _ctx.$trans('main.attempts.delete_user.description'),
      successButtonText: _ctx.$trans('main.attempts.buttons.success_button'),
      cancelButtonText: _ctx.$trans('main.attempts.buttons.cancel_button')
    }, null, 8, ["open", "close", "routeArgument", "title", "description", "successButtonText", "cancelButtonText"])
  ], 64))
}