<template>
  <!-- <aside class="hidden md:block overflow-scroll h-[calc(100vh-57px)] sticky top-[57px] border-l border-slate-100 dark:border-zinc-800 w-64 bg-white dark:bg-zinc-900"> -->
  <aside class="hidden md:block rounded-tr-lg rounded-br-lg overflow-scroll h-[calc(100vh-5.2rem)] sticky border-l border-gray-100 dark:border-gray-800 w-64 bg-white dark:bg-gray-850/60">
    <div class="flex flex-col divide-y divide-gray-100 dark:divide-gray-800">
      <div class="text-sm w-full relative cursor-pointer rounded-tl-lg">
          <!-- <a href="https://studio.design/?utm_source=layers&amp;utm_medium=banner&amp;utm_campaign=april&amp;via=inspired" target="_blank" rel="noopener nofollow" class="flex flex-col">
              <div class="relative w-full h-full group mx-auto" style="max-width: 2160px; max-height: 2160px;">
                  <div class="absolute z-30 inset-0 shadow-[inset_0_0_0px_1px_rgba(0,0,0,0.05)] dark:shadow-[inset_0_0_0px_1px_rgba(255,255,255,0.1)] pointer-events-none rounded-lg"></div>
                  <video
                      preload="none"
                      poster="https://d2wx6rahy8yxgr.cloudfront.net/filters:format(webp)/988f8da0-7746-4529-8966-5d0ef77b764e-thumb.jpeg"
                      loop=""
                      autoplay=""
                      playsinline=""
                      class="absolute z-10 w-full h-full flex items-center justify-center select-none transition-all duration-300 rounded-lg opacity-100"
                  >
                      <source src="https://layers-uploads-prod.s3.eu-west-2.amazonaws.com/41323c8e-a1c0-4f59-949c-f76175013dcf-Advertising-with-Layers-video.mp4#t=0.1" type="video/mp4" />
                  </video>
                  <div class="w-full h-full flex items-center justify-center text-slate-300" style="aspect-ratio: 1 / 1;"></div>
              </div>
              <div class="text-sm dark:text-zinc-200 font-medium mt-2">STUDIO</div>
              <div class="text-nano text-slate-500 dark:text-zinc-500">Inspired? Publish your site without code. Feel the power. Love the ease.</div>
          </a>
          <a class="absolute top-9 right-9 z-20 block cursor-pointer mix-blend-difference rounded-md p-0.5 border border-white/10 text-white/60 hover:border-white/20 hover:text-white active:scale-95 transition-all" href="/plus">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="w-[10px] h-[10px]">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
          </a> -->
        <DatePicker v-model="date" :time-class="['!h-5 !w-5']"/>
      </div>
      <!-- <div class="relative flex flex-col p-8 min-h-[264px]">
          <h2 class="text-sm text-slate-900 dark:text-zinc-200 mb-5 font-medium">Recent <span class="text-slate-500 dark:text-zinc-500 font-normal">Signups</span></h2>
          <a class="group flex items-center gap-2 text-sm rounded-full text-slate-500 dark:text-zinc-400 hover:text-slate-800 dark:hover:text-zinc-200 mb-2 active:scale-[0.98] transition-all" href="/sandipanbera">
              <div class="shrink-0 rounded-full overflow-hidden" style="width: 24px; height: 24px;">
                  <picture>
                      <source
                          type="image/webp"
                          sizes="(max-width: 320px) 320px"
                          srcset="https://d2wx6rahy8yxgr.cloudfront.net/fit-in/320x0/filters:format(webp)/filters:quality(100)/c858418e-e4a5-4f93-b5b7-d5434219e359-myphoto.png 320w"
                      />
                      <img
                          alt="Avatar of Sandipan Bera"
                          src="https://d2wx6rahy8yxgr.cloudfront.net/fit-in/48x0/filters:format(webp)/filters:quality(100)/c858418e-e4a5-4f93-b5b7-d5434219e359-myphoto.png"
                          loading="eager"
                          decoding="async"
                          class="rounded-full block object-cover"
                          style="width: 24px; height: 24px;"
                      />
                  </picture>
              </div>
              <div class="flex flex-col"><span class="capitalize">Sandipan Bera</span></div>
          </a>
          <a class="group flex items-center gap-2 text-sm rounded-full text-slate-500 dark:text-zinc-400 hover:text-slate-800 dark:hover:text-zinc-200 mb-2 active:scale-[0.98] transition-all" href="/gabrryn">
              <div class="shrink-0 rounded-full overflow-hidden" style="width: 24px; height: 24px;">
                  <picture>
                      <source
                          type="image/webp"
                          sizes="(max-width: 320px) 320px"
                          srcset="https://d2wx6rahy8yxgr.cloudfront.net/fit-in/320x0/filters:format(webp)/filters:quality(100)/6a28ead6-debb-4876-9d9e-506d63c4e339-Screenshot-2021-10-29-at-15-42-36.jpg 320w"
                      />
                      <img
                          alt="Avatar of Bradley Gabr-Ryn"
                          src="https://d2wx6rahy8yxgr.cloudfront.net/fit-in/48x0/filters:format(webp)/filters:quality(100)/6a28ead6-debb-4876-9d9e-506d63c4e339-Screenshot-2021-10-29-at-15-42-36.jpg"
                          loading="eager"
                          decoding="async"
                          class="rounded-full block object-cover"
                          style="width: 24px; height: 24px;"
                      />
                  </picture>
              </div>
              <div class="flex flex-col"><span class="capitalize">Bradley Gabr-Ryn</span></div>
          </a>
          <a class="group flex items-center gap-2 text-sm rounded-full text-slate-500 dark:text-zinc-400 hover:text-slate-800 dark:hover:text-zinc-200 mb-2 active:scale-[0.98] transition-all" href="/abdillsp">
              <div class="shrink-0 rounded-full overflow-hidden" style="width: 24px; height: 24px;">
                  <picture>
                      <source
                          type="image/webp"
                          sizes="(max-width: 320px) 320px"
                          srcset="https://d2wx6rahy8yxgr.cloudfront.net/fit-in/320x0/filters:format(webp)/filters:quality(100)/59403916-7a1c-4fe0-af23-6b47738f9c48-7M207318-min.JPG 320w"
                      />
                      <img
                          alt="Avatar of Abdillah Syah Putra"
                          src="https://d2wx6rahy8yxgr.cloudfront.net/fit-in/48x0/filters:format(webp)/filters:quality(100)/59403916-7a1c-4fe0-af23-6b47738f9c48-7M207318-min.JPG"
                          loading="eager"
                          decoding="async"
                          class="rounded-full block object-cover"
                          style="width: 24px; height: 24px;"
                      />
                  </picture>
              </div>
              <div class="flex flex-col"><span class="capitalize">Abdillah Syah</span></div>
          </a>
          <a class="group flex items-center gap-2 text-sm rounded-full text-slate-500 dark:text-zinc-400 hover:text-slate-800 dark:hover:text-zinc-200 mb-2 active:scale-[0.98] transition-all" href="/logosohel">
              <div class="shrink-0 rounded-full overflow-hidden" style="width: 24px; height: 24px;">
                  <picture>
                      <source
                          type="image/webp"
                          sizes="(max-width: 320px) 320px"
                          srcset="https://d2wx6rahy8yxgr.cloudfront.net/fit-in/320x0/filters:format(webp)/filters:quality(100)/10722c4c-e4d3-4902-a084-5002a324b548-Sohel-in-Blue.jpg 320w"
                      />
                      <img
                          alt="Avatar of Mohammad Sohel"
                          src="https://d2wx6rahy8yxgr.cloudfront.net/fit-in/48x0/filters:format(webp)/filters:quality(100)/10722c4c-e4d3-4902-a084-5002a324b548-Sohel-in-Blue.jpg"
                          loading="eager"
                          decoding="async"
                          class="rounded-full block object-cover"
                          style="width: 24px; height: 24px;"
                      />
                  </picture>
              </div>
              <div class="flex flex-col"><span class="capitalize">Mohammad Sohel</span></div>
          </a>
          <a class="group flex items-center gap-2 text-sm rounded-full text-slate-500 dark:text-zinc-400 hover:text-slate-800 dark:hover:text-zinc-200 mb-2 active:scale-[0.98] transition-all" href="/wowrakibul">
              <div class="shrink-0 rounded-full overflow-hidden" style="width: 24px; height: 24px;">
                  <picture>
                      <source
                          type="image/webp"
                          sizes="(max-width: 320px) 320px"
                          srcset="https://d2wx6rahy8yxgr.cloudfront.net/fit-in/320x0/filters:format(webp)/filters:quality(100)/4a09183d-4414-499c-bc43-02e00974c367-IMG_20240113_133722.jpg 320w"
                      />
                      <img
                          alt="Avatar of Wow Rakibul"
                          src="https://d2wx6rahy8yxgr.cloudfront.net/fit-in/48x0/filters:format(webp)/filters:quality(100)/4a09183d-4414-499c-bc43-02e00974c367-IMG_20240113_133722.jpg"
                          loading="eager"
                          decoding="async"
                          class="rounded-full block object-cover"
                          style="width: 24px; height: 24px;"
                      />
                  </picture>
              </div>
              <div class="flex flex-col"><span class="capitalize">Wow Rakibul</span></div>
          </a>
      </div> -->
      <div class="p-8 min-h-[264px]">
          <div class="relative flex flex-col">
              <h2 class="text-sm text-slate-900 dark:text-zinc-200 mb-5 font-medium">
                  <a href="/jobs">Recent <span class="text-slate-500 dark:text-zinc-500 font-normal">Jobs</span></a>
              </h2>
              <ul>
                  <li>
                      <a
                          href="/jobs?highlightId=clwsmp0gy004pjh0dldcmpi79"
                          class="group flex items-center gap-2 text-sm rounded-full text-slate-500 dark:text-zinc-400 hover:text-slate-800 dark:hover:text-zinc-200 mb-2 active:scale-[0.98] transition-all"
                      >
                          <div class="shrink-0 rounded-full overflow-hidden" style="width: 24px; height: 24px;">
                              <picture>
                                  <source
                                      type="image/webp"
                                      sizes="(max-width: 320px) 320px"
                                      srcset="https://d2wx6rahy8yxgr.cloudfront.net/fit-in/320x0/filters:format(webp)/filters:quality(100)/c92285fa-9b30-4837-9784-9efeb6f17ad3-Logo-Guideflow.png 320w"
                                  />
                                  <img
                                      alt="Avatar of Guideflow"
                                      src="https://d2wx6rahy8yxgr.cloudfront.net/fit-in/48x0/filters:format(webp)/filters:quality(100)/c92285fa-9b30-4837-9784-9efeb6f17ad3-Logo-Guideflow.png"
                                      loading="eager"
                                      decoding="async"
                                      class="rounded-full block object-cover"
                                      style="width: 24px; height: 24px;"
                                  />
                              </picture>
                          </div>
                          <div><span class="">Founder Product Designer</span></div>
                      </a>
                  </li>
                  <li>
                      <a
                          href="/jobs?highlightId=clws2di1a0029l70c3so169qb"
                          class="group flex items-center gap-2 text-sm rounded-full text-slate-500 dark:text-zinc-400 hover:text-slate-800 dark:hover:text-zinc-200 mb-2 active:scale-[0.98] transition-all"
                      >
                          <div class="shrink-0 rounded-full overflow-hidden" style="width: 24px; height: 24px;">
                              <picture>
                                  <source
                                      type="image/webp"
                                      sizes="(max-width: 320px) 320px"
                                      srcset="https://d2wx6rahy8yxgr.cloudfront.net/fit-in/320x0/filters:format(webp)/filters:quality(100)/ef03d71b-bb1f-4023-8139-e5d4a96d0f3f-icon.png 320w"
                                  />
                                  <img
                                      alt="Avatar of Atlas"
                                      src="https://d2wx6rahy8yxgr.cloudfront.net/fit-in/48x0/filters:format(webp)/filters:quality(100)/ef03d71b-bb1f-4023-8139-e5d4a96d0f3f-icon.png"
                                      loading="eager"
                                      decoding="async"
                                      class="rounded-full block object-cover"
                                      style="width: 24px; height: 24px;"
                                  />
                              </picture>
                          </div>
                          <div><span class="">Lead Product Designer</span></div>
                      </a>
                  </li>
                  <li>
                      <a
                          href="/jobs?highlightId=clwh6ai4x0003l20jpwu2aen7"
                          class="group flex items-center gap-2 text-sm rounded-full text-slate-500 dark:text-zinc-400 hover:text-slate-800 dark:hover:text-zinc-200 mb-2 active:scale-[0.98] transition-all"
                      >
                          <div class="shrink-0 rounded-full overflow-hidden" style="width: 24px; height: 24px;">
                              <picture>
                                  <source
                                      type="image/webp"
                                      sizes="(max-width: 320px) 320px"
                                      srcset="https://d2wx6rahy8yxgr.cloudfront.net/fit-in/320x0/filters:format(webp)/filters:quality(100)/7af703fa-867a-4ae4-a3aa-77028ca0ef7e-lindy-logo-square.png 320w"
                                  />
                                  <img
                                      alt="Avatar of Lindy.ai"
                                      src="https://d2wx6rahy8yxgr.cloudfront.net/fit-in/48x0/filters:format(webp)/filters:quality(100)/7af703fa-867a-4ae4-a3aa-77028ca0ef7e-lindy-logo-square.png"
                                      loading="eager"
                                      decoding="async"
                                      class="rounded-full block object-cover"
                                      style="width: 24px; height: 24px;"
                                  />
                              </picture>
                          </div>
                          <div><span class="">Product Designer </span></div>
                      </a>
                  </li>
                  <li>
                      <a
                          href="/jobs?highlightId=clwh01n0e0031l50htk8pgzzr"
                          class="group flex items-center gap-2 text-sm rounded-full text-slate-500 dark:text-zinc-400 hover:text-slate-800 dark:hover:text-zinc-200 mb-2 active:scale-[0.98] transition-all"
                      >
                          <div class="shrink-0 rounded-full overflow-hidden" style="width: 24px; height: 24px;">
                              <picture>
                                  <source
                                      type="image/webp"
                                      sizes="(max-width: 320px) 320px"
                                      srcset="https://d2wx6rahy8yxgr.cloudfront.net/fit-in/320x0/filters:format(webp)/filters:quality(100)/9a62f033-ef81-4faa-8da5-d83f5c791a6f-4.jpg 320w"
                                  />
                                  <img
                                      alt="Avatar of Elicit"
                                      src="https://d2wx6rahy8yxgr.cloudfront.net/fit-in/48x0/filters:format(webp)/filters:quality(100)/9a62f033-ef81-4faa-8da5-d83f5c791a6f-4.jpg"
                                      loading="eager"
                                      decoding="async"
                                      class="rounded-full block object-cover"
                                      style="width: 24px; height: 24px;"
                                  />
                              </picture>
                          </div>
                          <div><span class="">Senior Product Designer</span></div>
                      </a>
                  </li>
                  <li>
                      <a
                          href="/jobs?highlightId=clw9yldim000hjn0djdia3ghi"
                          class="group flex items-center gap-2 text-sm rounded-full text-slate-500 dark:text-zinc-400 hover:text-slate-800 dark:hover:text-zinc-200 mb-2 active:scale-[0.98] transition-all"
                      >
                          <div class="shrink-0 rounded-full overflow-hidden" style="width: 24px; height: 24px;">
                              <picture>
                                  <source
                                      type="image/webp"
                                      sizes="(max-width: 320px) 320px"
                                      srcset="https://d2wx6rahy8yxgr.cloudfront.net/fit-in/320x0/filters:format(webp)/filters:quality(100)/bc0abd53-2777-4b61-9a12-4341feaabbd8-CUBE.png 320w"
                                  />
                                  <img
                                      alt="Avatar of Cube"
                                      src="https://d2wx6rahy8yxgr.cloudfront.net/fit-in/48x0/filters:format(webp)/filters:quality(100)/bc0abd53-2777-4b61-9a12-4341feaabbd8-CUBE.png"
                                      loading="lazy"
                                      decoding="async"
                                      class="rounded-full block object-cover"
                                      style="width: 24px; height: 24px;"
                                  />
                              </picture>
                          </div>
                          <div><span class="">Product Designer</span></div>
                      </a>
                  </li>
              </ul>
              <a class="absolute top-[3px] right-0 text-slate-400 hover:text-slate-700 dark:text-zinc-500 dark:hover:text-zinc-200 transition-all text-nano" href="/jobs/new">Post a job</a>
          </div>
      </div>
      <div class="relative flex flex-col p-8">
          <span class="text-sm text-slate-600 dark:text-zinc-200 font-medium">layers</span><span class="text-nano text-slate-400 dark:text-zinc-500">A home for designers</span>
          <ul class="flex flex-wrap gap-1 mt-3">
              <li class="flex"><a class="leading-none text-nano text-slate-300 hover:text-slate-400 dark:text-zinc-700 dark:hover:text-zinc-600 transition-all" href="/about">About</a></li>
              <li class="flex"><a class="leading-none text-nano text-slate-300 hover:text-slate-400 dark:text-zinc-700 dark:hover:text-zinc-600 transition-all" href="/advertise">Advertise</a></li>
              <li class="flex"><a class="leading-none text-nano text-slate-300 hover:text-slate-400 dark:text-zinc-700 dark:hover:text-zinc-600 transition-all" href="/contact">Contact</a></li>
              <li class="flex"><a class="leading-none text-nano text-slate-300 hover:text-slate-400 dark:text-zinc-700 dark:hover:text-zinc-600 transition-all" href="/legal/terms-and-conditions">Terms</a></li>
              <li class="flex"><a class="leading-none text-nano text-slate-300 hover:text-slate-400 dark:text-zinc-700 dark:hover:text-zinc-600 transition-all" href="/legal/privacy-policy">Privacy</a></li>
              <li class="flex"><a href="mailto:support@layers.to" class="leading-none text-nano text-slate-300 hover:text-slate-400 dark:text-zinc-700 dark:hover:text-zinc-600 transition-all">Support</a></li>
          </ul>
          <div class="flex gap-2 mt-2">
              <a href="https://x.com/layers_to" target="_blank" rel="noreferrer noopener" class="w-4 h-4">
                  <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="twitter"
                      class="svg-inline--fa fa-twitter text-slate-300 hover:text-slate-400 dark:text-zinc-700 dark:hover:text-zinc-600 transition-all w-full h-full"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                  >
                      <path
                          fill="currentColor"
                          d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                      ></path>
                  </svg>
              </a>
              <a href="https://www.instagram.com/layers_to" target="_blank" rel="noreferrer noopener" class="w-4 h-4">
                  <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="instagram"
                      class="svg-inline--fa fa-instagram text-slate-300 hover:text-slate-400 dark:text-zinc-700 dark:hover:text-zinc-600 transition-all w-full h-full"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                  >
                      <path
                          fill="currentColor"
                          d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                      ></path>
                  </svg>
              </a>
              <a href="https://www.linkedin.com/in/layers_to" target="_blank" rel="noreferrer noopener" class="w-4 h-4">
                  <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="linkedin"
                      class="svg-inline--fa fa-linkedin text-slate-300 hover:text-slate-400 dark:text-zinc-700 dark:hover:text-zinc-600 transition-all w-full h-full"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                  >
                      <path
                          fill="currentColor"
                          d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                      ></path>
                  </svg>
              </a>
          </div>
      </div>
    </div>
  </aside>
</template>
<script lang="ts">
// import DatePicker from './DatePicker.vue';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  components: {
    // DatePicker,
  },
  setup(){
    const date = ref(new Date());

    return {
      date,
    };
  }
});
</script>