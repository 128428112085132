import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "px-4 sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "mt-8 flow-root" }
const _hoisted_3 = { class: "-mx-4 -my-2 sm:-mx-6 lg:-mx-8 overflow-x-scroll" }
const _hoisted_4 = { class: "inline-block min-w-full py-2 align-middle" }
const _hoisted_5 = { class: "min-w-full border-separate border-spacing-0" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = {
  key: 0,
  class: "flex items-center justify-center my-48"
}
const _hoisted_15 = { class: "text-gray-400 dark:text-gray-300 box-border block text-2xl leading-5 relative text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Head = _resolveComponent("Head")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_Layout = _resolveComponent("Layout")!
  const _component_Show = _resolveComponent("Show")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Head, {
      title: _ctx.$trans('table.reports.title')
    }, null, 8, ["title"]),
    _createVNode(_component_Layout, {
      "body-classes": ['bg-white', 'dark:bg-gray-900', 'h-full'],
      "main-classes": ['bg-white', 'dark:bg-gray-900']
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("table", _hoisted_5, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", {
                        innerHTML: _ctx.$trans('table.reports.head.html.id'),
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:table-cell sm:pl-6 lg:pl-8"
                      }, null, 8, _hoisted_6),
                      _createElementVNode("th", {
                        innerHTML: _ctx.$trans('table.reports.head.html.type'),
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"
                      }, null, 8, _hoisted_7),
                      _createElementVNode("th", {
                        innerHTML: _ctx.$trans('table.reports.head.html.user'),
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"
                      }, null, 8, _hoisted_8),
                      _createElementVNode("th", {
                        innerHTML: _ctx.$trans('table.reports.head.html.ip'),
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"
                      }, null, 8, _hoisted_9),
                      _createElementVNode("th", {
                        innerHTML: _ctx.$trans('table.reports.head.html.success'),
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"
                      }, null, 8, _hoisted_10),
                      _createElementVNode("th", {
                        innerHTML: _ctx.$trans('table.reports.head.html.text'),
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"
                      }, null, 8, _hoisted_11),
                      _createElementVNode("th", {
                        innerHTML: _ctx.$trans('table.users.head.html.created_at'),
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"
                      }, null, 8, _hoisted_12),
                      _cache[0] || (_cache[0] = _createElementVNode("th", {
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"
                      }, null, -1))
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reports?.data, (report, reportIdx) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: report.id
                      }, [
                        _createElementVNode("td", {
                          class: _normalizeClass([reportIdx !== _ctx.reports.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 lg:pl-8'])
                        }, _toDisplayString(report.id), 3),
                        _createElementVNode("td", {
                          class: _normalizeClass([reportIdx !== _ctx.reports.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 text-center whitespace-nowrap uppercase py-4 px-4 text-sm font-medium'])
                        }, _toDisplayString(report.type), 3),
                        _createElementVNode("td", {
                          class: _normalizeClass([reportIdx !== _ctx.reports.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 text-center whitespace-nowrap py-4 px-4 text-sm font-medium'])
                        }, _toDisplayString(report?.user_name ?? _ctx.$trans('table.shared.no_data')), 3),
                        _createElementVNode("td", {
                          class: _normalizeClass([reportIdx !== _ctx.reports.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 text-center whitespace-nowrap py-4 px-4 text-sm font-medium'])
                        }, _toDisplayString(report.ip_address ?? _ctx.$trans('table.shared.no_data')), 3),
                        _createElementVNode("td", {
                          class: _normalizeClass([reportIdx !== _ctx.reports.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500'])
                        }, [
                          (report.success_type != null)
                            ? (_openBlock(), _createBlock(_component_Badge, {
                                key: 0,
                                type: report.success_type,
                                text: report.is_success
                              }, null, 8, ["type", "text"]))
                            : _createCommentVNode("", true)
                        ], 2),
                        _createElementVNode("td", {
                          class: _normalizeClass([reportIdx !== _ctx.reports.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 lg:pl-8'])
                        }, _toDisplayString(report.text ?? _ctx.$trans('table.shared.no_data')), 3),
                        _createElementVNode("td", {
                          class: _normalizeClass([reportIdx !== _ctx.reports.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 lg:pl-8'])
                        }, _toDisplayString(report.created_at ?? _ctx.$trans('table.shared.no_data')), 3),
                        _createElementVNode("td", {
                          class: _normalizeClass([reportIdx !== _ctx.reports.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 relative whitespace-nowrap py-4 px-2 text-right text-sm font-medium sm:pr-8 lg:pr-8'])
                        }, [
                          _createElementVNode("span", {
                            onClick: ($event: any) => (_ctx.show(report)),
                            class: "text-primary-600 dark:text-primary-800 hover:text-primary-900 dark:hover:text-primary-700 cursor-pointer"
                          }, _toDisplayString(_ctx.$trans('table.shared.show')), 9, _hoisted_13)
                        ], 2)
                      ]))
                    }), 128))
                  ])
                ]),
                (!_ctx.reports?.data?.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("h2", _hoisted_15, _toDisplayString(_ctx.$trans('table.reports.placeholder')), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]),
        (_ctx.reports?.data?.length)
          ? (_openBlock(), _createBlock(_component_Pagination, {
              key: 0,
              data: _ctx.reports
            }, null, 8, ["data"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_Show, {
      report: _ctx.showedReport,
      isOpen: _ctx.drawerOpen,
      onClose: _ctx.close
    }, null, 8, ["report", "isOpen", "onClose"])
  ], 64))
}