import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "px-4 sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "mt-8 flow-root" }
const _hoisted_3 = { class: "-mx-4 -my-2 sm:-mx-6 lg:-mx-8 overflow-x-scroll" }
const _hoisted_4 = { class: "inline-block min-w-full py-2 align-middle" }
const _hoisted_5 = { class: "min-w-full border-separate border-spacing-0" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = {
  key: 0,
  class: "flex items-center justify-center my-48"
}
const _hoisted_15 = { class: "text-gray-400 dark:text-gray-300 box-border block text-2xl leading-5 relative text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Head = _resolveComponent("Head")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Link = _resolveComponent("Link")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_Layout = _resolveComponent("Layout")!
  const _component_Show = _resolveComponent("Show")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Head, {
      title: _ctx.$trans('table.commands.title')
    }, null, 8, ["title"]),
    _createVNode(_component_Layout, {
      "body-classes": ['bg-white', 'dark:bg-gray-900', 'h-full'],
      "main-classes": ['bg-white', 'dark:bg-gray-900']
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("table", _hoisted_5, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", {
                        innerHTML: _ctx.$trans('table.commands.head.id'),
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:table-cell sm:pl-6 lg:pl-8"
                      }, null, 8, _hoisted_6),
                      _createElementVNode("th", {
                        innerHTML: _ctx.$trans('table.commands.head.pid'),
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"
                      }, null, 8, _hoisted_7),
                      _createElementVNode("th", {
                        innerHTML: _ctx.$trans('table.commands.head.user'),
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"
                      }, null, 8, _hoisted_8),
                      _createElementVNode("th", {
                        innerHTML: _ctx.$trans('table.commands.head.command'),
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"
                      }, null, 8, _hoisted_9),
                      _createElementVNode("th", {
                        innerHTML: _ctx.$trans('table.commands.head.status'),
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"
                      }, null, 8, _hoisted_10),
                      _createElementVNode("th", {
                        innerHTML: _ctx.$trans('table.commands.head.started_at'),
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"
                      }, null, 8, _hoisted_11),
                      _createElementVNode("th", {
                        innerHTML: _ctx.$trans('table.commands.head.finished_at'),
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"
                      }, null, 8, _hoisted_12),
                      _cache[0] || (_cache[0] = _createElementVNode("th", {
                        scope: "col",
                        class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"
                      }, null, -1))
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commands?.data, (command, commandIdx) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: command.id
                      }, [
                        _createElementVNode("td", {
                          class: _normalizeClass([commandIdx !== _ctx.commands.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 lg:pl-8'])
                        }, _toDisplayString(command.id), 3),
                        _createElementVNode("td", {
                          class: _normalizeClass([commandIdx !== _ctx.commands.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 text-center whitespace-nowrap uppercase py-4 px-4 text-sm font-medium'])
                        }, _toDisplayString(command.pid ?? _ctx.$trans('table.shared.no_data')), 3),
                        _createElementVNode("td", {
                          class: _normalizeClass([commandIdx !== _ctx.commands.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 text-center whitespace-nowrap py-4 px-4 text-sm font-medium'])
                        }, _toDisplayString(command.user.name), 3),
                        _createElementVNode("td", {
                          class: _normalizeClass([commandIdx !== _ctx.commands.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 text-center whitespace-nowrap py-4 px-4 text-sm font-medium'])
                        }, _toDisplayString(command.value ?? _ctx.$trans('table.shared.no_data')), 3),
                        _createElementVNode("td", {
                          class: _normalizeClass([commandIdx !== _ctx.commands.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500'])
                        }, [
                          _createVNode(_component_Badge, {
                            type: _ctx.getBadgeType(command.status),
                            text: _ctx.$trans(`main.commands.statuses.${command.status}`)
                          }, null, 8, ["type", "text"])
                        ], 2),
                        _createElementVNode("td", {
                          class: _normalizeClass([commandIdx !== _ctx.commands.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 lg:pl-8'])
                        }, _toDisplayString(command.started_at ?? _ctx.$trans('table.shared.no_data')), 3),
                        _createElementVNode("td", {
                          class: _normalizeClass([commandIdx !== _ctx.commands.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 lg:pl-8'])
                        }, _toDisplayString(command.finished_at ?? _ctx.$trans('table.shared.no_data')), 3),
                        _createElementVNode("td", {
                          class: _normalizeClass([commandIdx !== _ctx.commands.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 relative whitespace-nowrap py-4 px-2 text-right text-sm font-medium sm:pr-8 lg:pr-8'])
                        }, [
                          _createElementVNode("span", {
                            onClick: ($event: any) => (_ctx.show(command)),
                            class: "text-primary-600 dark:text-primary-800 hover:text-primary-900 dark:hover:text-primary-700 cursor-pointer pr-1"
                          }, _toDisplayString(_ctx.$trans('table.shared.show')), 9, _hoisted_13),
                          (command.status === 'running')
                            ? (_openBlock(), _createBlock(_component_Link, {
                                key: 0,
                                as: "span",
                                href: _ctx.route('command.stop', {'command': command?.id}),
                                method: "put",
                                "preserve-scroll": "",
                                class: "text-yellow-600 dark:text-yellow-800 hover:text-yellow-900 dark:hover:text-yellow-700 cursor-pointer pr-1"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$trans('main.commands.buttons.stop')) + " ", 1),
                                  _cache[1] || (_cache[1] = _createElementVNode("br", { class: "sm:hidden" }, null, -1))
                                ]),
                                _: 2
                              }, 1032, ["href"]))
                            : _createCommentVNode("", true),
                          (command.status === 'completed' || command.status === 'failed' || command.status === 'stopped')
                            ? (_openBlock(), _createBlock(_component_Link, {
                                key: 1,
                                as: "span",
                                href: _ctx.route('command.destroy', {'command': command?.id}),
                                method: "delete",
                                "preserve-scroll": "",
                                class: "text-red-600 dark:text-red-800 hover:text-red-900 dark:hover:text-red-700 cursor-pointer"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$trans('table.shared.delete')) + " ", 1),
                                  _cache[2] || (_cache[2] = _createElementVNode("br", { class: "sm:hidden" }, null, -1))
                                ]),
                                _: 2
                              }, 1032, ["href"]))
                            : _createCommentVNode("", true)
                        ], 2)
                      ]))
                    }), 128))
                  ])
                ]),
                (!_ctx.commands?.data?.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("h2", _hoisted_15, _toDisplayString(_ctx.$trans('table.commands.placeholder')), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]),
        (_ctx.commands?.data?.length)
          ? (_openBlock(), _createBlock(_component_Pagination, {
              key: 0,
              data: _ctx.commands
            }, null, 8, ["data"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_Show, {
      command: _ctx.showedCommand,
      isOpen: _ctx.drawerOpen,
      onClose: _ctx.close
    }, null, 8, ["command", "isOpen", "onClose"])
  ], 64))
}