import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "absolute inset-0 overflow-hidden" }
const _hoisted_2 = { class: "pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10 sm:pr-16" }
const _hoisted_3 = { class: "pointer-events-auto w-screen max-w-2xl" }
const _hoisted_4 = { class: "flex-1 overflow-y-auto mb-8" }
const _hoisted_5 = { class: "bg-gray-50 dark:bg-gray-850 px-4 py-6 sm:px-6" }
const _hoisted_6 = { class: "flex justify-between items-start" }
const _hoisted_7 = { class: "space-y-6 px-4 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 dark:sm:divide-gray-700" }
const _hoisted_8 = { class: "max-h-[calc(100vh-200px)]" }
const _hoisted_9 = { class: "grid-cols-2" }
const _hoisted_10 = { class: "mt-5 md:mt-0 md:col-span-2" }
const _hoisted_11 = { class: "grid grid-cols-6 gap-6" }
const _hoisted_12 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_13 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_14 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_15 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_16 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_17 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_18 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_19 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_20 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_21 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_22 = { class: "flex-shrink-0 border-t border-gray-200 dark:border-gray-750 px-4 py-5 sm:px-6" }
const _hoisted_23 = { class: "flex justify-end space-x-3" }
const _hoisted_24 = { class: "flex justify-end space-x-3" }
const _hoisted_25 = {
  key: 0,
  role: "status",
  class: "inline w-4 h-4 mr-3 text-white animate-spin",
  viewBox: "0 0 100 101",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogOverlay = _resolveComponent("DialogOverlay")!
  const _component_DialogTitle = _resolveComponent("DialogTitle")!
  const _component_Cross = _resolveComponent("Cross")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Select = _resolveComponent("Select")!
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createBlock(_component_TransitionRoot, {
    as: "template",
    show: _ctx.createDrawerIsOpen
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Dialog, {
        as: "div",
        class: "z-50 fixed inset-0 overflow-hidden",
        onClose: _cache[12] || (_cache[12] = ($event: any) => (_ctx.close()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_DialogOverlay, { class: "absolute inset-0 bg-black bg-opacity-50 dark:bg-opacity-50" }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_TransitionChild, {
                as: "template",
                enter: "transform transition ease-in-out duration-300 sm:duration-500",
                "enter-from": "-translate-x-full",
                "enter-to": "translate-x-0",
                leave: "transform transition ease-in-out duration-300 sm:duration-500",
                "leave-from": "translate-x-0",
                "leave-to": "-translate-x-full"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("form", {
                      class: "flex h-full flex-col bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-200 shadow-xl",
                      onSubmit: _cache[11] || (_cache[11] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
                    }, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", null, [
                              _createVNode(_component_DialogTitle, { class: "text-lg font-medium text-gray-900 dark:text-white" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$trans('table.users.form.titles.create_new_user')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _createVNode(_component_Cross, {
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
                            })
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("div", _hoisted_9, [
                              _createElementVNode("div", _hoisted_10, [
                                _createElementVNode("div", _hoisted_11, [
                                  _createElementVNode("div", _hoisted_12, [
                                    _createVNode(_component_Input, {
                                      id: "first_name",
                                      type: "text",
                                      name: "first_name",
                                      label: "table.users.form.first_name",
                                      autocomplete: "on",
                                      modelValue: _ctx.createForm.first_name,
                                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.createForm.first_name) = $event)),
                                      required: true,
                                      form: _ctx.createForm
                                    }, null, 8, ["modelValue", "form"])
                                  ]),
                                  _createElementVNode("div", _hoisted_13, [
                                    _createVNode(_component_Input, {
                                      id: "last_name",
                                      type: "text",
                                      name: "last_name",
                                      label: "table.users.form.last_name",
                                      autocomplete: "on",
                                      modelValue: _ctx.createForm.last_name,
                                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.createForm.last_name) = $event)),
                                      required: true,
                                      form: _ctx.createForm
                                    }, null, 8, ["modelValue", "form"])
                                  ]),
                                  _createElementVNode("div", _hoisted_14, [
                                    _createVNode(_component_Input, {
                                      id: "email",
                                      type: "email",
                                      name: "email",
                                      label: "table.users.form.email",
                                      autocomplete: "on",
                                      modelValue: _ctx.createForm.email,
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.createForm.email) = $event)),
                                      required: true,
                                      form: _ctx.createForm
                                    }, null, 8, ["modelValue", "form"])
                                  ]),
                                  _createElementVNode("div", _hoisted_15, [
                                    _createVNode(_component_Input, {
                                      id: "phone_number",
                                      type: "text",
                                      name: "phone_number",
                                      label: "table.users.form.phone_number",
                                      autocomplete: "on",
                                      modelValue: _ctx.createForm.phone_number,
                                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.createForm.phone_number) = $event)),
                                      required: false,
                                      form: _ctx.createForm
                                    }, null, 8, ["modelValue", "form"])
                                  ]),
                                  _createElementVNode("div", _hoisted_16, [
                                    _createVNode(_component_Input, {
                                      id: "address",
                                      type: "text",
                                      name: "address",
                                      label: "table.users.form.address",
                                      autocomplete: "on",
                                      modelValue: _ctx.createForm.address,
                                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.createForm.address) = $event)),
                                      required: false,
                                      form: _ctx.createForm
                                    }, null, 8, ["modelValue", "form"])
                                  ]),
                                  _createElementVNode("div", _hoisted_17, [
                                    _createVNode(_component_Select, {
                                      id: "role",
                                      name: "role",
                                      label: "table.users.form.role",
                                      modelValue: _ctx.createForm.role,
                                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.createForm.role) = $event)),
                                      items: _ctx.roles,
                                      required: true,
                                      errors: _ctx.createForm.errors
                                    }, null, 8, ["modelValue", "items", "errors"])
                                  ]),
                                  _createElementVNode("div", _hoisted_18, [
                                    _createVNode(_component_Select, {
                                      id: "country",
                                      name: "country",
                                      label: "table.users.form.country",
                                      modelValue: _ctx.createForm.country_id,
                                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.createForm.country_id) = $event)),
                                      key: "id",
                                      value: "id",
                                      title: "name",
                                      items: _ctx.countries,
                                      required: false,
                                      errors: _ctx.createForm.errors
                                    }, null, 8, ["modelValue", "items", "errors"])
                                  ]),
                                  _createElementVNode("div", _hoisted_19, [
                                    _createVNode(_component_Select, {
                                      id: "language",
                                      name: "language",
                                      label: "table.users.form.language",
                                      modelValue: _ctx.createForm.language,
                                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.createForm.language) = $event)),
                                      value: "item",
                                      "trans-key": "main.shared.languages",
                                      items: _ctx.languages,
                                      required: true,
                                      form: _ctx.createForm
                                    }, null, 8, ["modelValue", "items", "form"])
                                  ]),
                                  _createElementVNode("div", _hoisted_20, [
                                    _createVNode(_component_Input, {
                                      id: "password",
                                      type: "password",
                                      name: "password",
                                      label: "table.users.form.password",
                                      autocomplete: "on",
                                      modelValue: _ctx.createForm.password,
                                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.createForm.password) = $event)),
                                      required: true,
                                      form: _ctx.createForm
                                    }, null, 8, ["modelValue", "form"])
                                  ]),
                                  _createElementVNode("div", _hoisted_21, [
                                    _createVNode(_component_Input, {
                                      id: "password-confirmation",
                                      type: "password",
                                      name: "password_confirmation",
                                      label: "table.users.form.password_confirmation",
                                      autocomplete: "on",
                                      modelValue: _ctx.createForm.password_confirmation,
                                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.createForm.password_confirmation) = $event)),
                                      required: true,
                                      form: _ctx.createForm
                                    }, null, 8, ["modelValue", "form"])
                                  ])
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("div", _hoisted_24, [
                            _createElementVNode("button", {
                              type: "submit",
                              class: _normalizeClass([{'cursor-progress': _ctx.createForm.processing}, "bg-gray-900 dark:bg-primary-800 hover:bg-gray-700 dark:hover:bg-primary-700 text-white dark:text-gray-100 focus:ring-gray-700 dark:focus:ring-primary-950 focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent font-medium rounded-lg text-sm px-5 py-2.5 text-center"])
                            }, [
                              (_ctx.createForm.processing)
                                ? (_openBlock(), _createElementBlock("svg", _hoisted_25, _cache[13] || (_cache[13] = [
                                    _createElementVNode("path", {
                                      d: "M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z",
                                      fill: "#E5E7EB"
                                    }, null, -1),
                                    _createElementVNode("path", {
                                      d: "M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z",
                                      fill: "currentColor"
                                    }, null, -1)
                                  ])))
                                : _createCommentVNode("", true),
                              _createTextVNode(" " + _toDisplayString(_ctx.$trans('table.shared.create')) + _toDisplayString(_ctx.createForm.processing ? '...' : ''), 1)
                            ], 2)
                          ])
                        ])
                      ])
                    ], 32)
                  ])
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show"]))
}