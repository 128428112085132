import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "absolute inset-0 overflow-hidden" }
const _hoisted_2 = { class: "pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10 sm:pr-16" }
const _hoisted_3 = { class: "pointer-events-auto w-auto" }
const _hoisted_4 = { class: "flex h-full flex-col bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-200 shadow-xl min-w-auto max-w-[100vw] sm:min-w-96" }
const _hoisted_5 = { class: "flex-1 overflow-y-auto" }
const _hoisted_6 = { class: "bg-gray-50 dark:bg-gray-850 px-4 py-6 sm:px-6" }
const _hoisted_7 = { class: "flex justify-between items-start" }
const _hoisted_8 = { class: "space-y-6 px-4 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 dark:sm:divide-gray-700 overflow-y-auto" }
const _hoisted_9 = { class: "max-h-[calc(100vh-200px)]" }
const _hoisted_10 = { class: "grid-cols-2" }
const _hoisted_11 = { class: "mt-5 md:mt-0 md:col-span-2" }
const _hoisted_12 = { class: "p-4" }
const _hoisted_13 = { class: "space-y-4 text-sm" }
const _hoisted_14 = { class: "flex justify-between" }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = { class: "break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300" }
const _hoisted_17 = { class: "flex justify-between" }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300" }
const _hoisted_20 = { class: "flex justify-between" }
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = { class: "break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300" }
const _hoisted_23 = { class: "flex justify-between" }
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = { class: "break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300" }
const _hoisted_26 = {
  key: 0,
  role: "status"
}
const _hoisted_27 = {
  key: 0,
  class: "flex"
}
const _hoisted_28 = { class: "terminal-window" }
const _hoisted_29 = { class: "flex justify-between" }
const _hoisted_30 = ["innerHTML"]
const _hoisted_31 = { class: "break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300" }
const _hoisted_32 = { class: "flex items-start justify-between" }
const _hoisted_33 = ["innerHTML"]
const _hoisted_34 = { class: "break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300" }
const _hoisted_35 = { class: "flex-shrink-0 border-t border-gray-200 dark:border-gray-750 px-4 py-5 sm:px-6" }
const _hoisted_36 = { class: "flex justify-end space-x-3" }
const _hoisted_37 = { class: "flex justify-end space-x-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Head = _resolveComponent("Head")!
  const _component_DialogOverlay = _resolveComponent("DialogOverlay")!
  const _component_DialogTitle = _resolveComponent("DialogTitle")!
  const _component_Cross = _resolveComponent("Cross")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_highlightjs = _resolveComponent("highlightjs")!
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.command !== null)
      ? (_openBlock(), _createBlock(_component_Head, {
          key: 0,
          title: `${_ctx.$trans('main.commands.show.title')} #${_ctx.command?.pid} | ${_ctx.getAppName()}`
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    _createVNode(_component_TransitionRoot, {
      as: "template",
      show: _ctx.open
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Dialog, {
          as: "div",
          class: "z-50 fixed inset-0 overflow-hidden",
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.close()))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_DialogOverlay, { class: "absolute inset-0 bg-black bg-opacity-50 dark:bg-opacity-50" }),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_TransitionChild, {
                  as: "template",
                  enter: "transform transition ease-in-out duration-300 sm:duration-500",
                  "enter-from": "-translate-x-full",
                  "enter-to": "translate-x-0",
                  leave: "transform transition ease-in-out duration-300 sm:duration-500",
                  "leave-from": "translate-x-0",
                  "leave-to": "-translate-x-full"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", _hoisted_7, [
                              _createElementVNode("div", null, [
                                _createVNode(_component_DialogTitle, { class: "text-lg font-medium text-gray-900 dark:text-white" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(`${_ctx.$trans('main.commands.show.title')} ${_ctx.command?.pid !== null ? `#${_ctx.command?.pid}` : ''}`), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _createVNode(_component_Cross, {
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
                              })
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("div", _hoisted_9, [
                              _createElementVNode("div", _hoisted_10, [
                                _createElementVNode("div", _hoisted_11, [
                                  _createElementVNode("div", _hoisted_12, [
                                    _createElementVNode("dl", _hoisted_13, [
                                      _createElementVNode("div", _hoisted_14, [
                                        _createElementVNode("dt", {
                                          class: "font-semibold text-gray-800 dark:text-gray-100",
                                          innerHTML: _ctx.$trans('main.commands.show.id')
                                        }, null, 8, _hoisted_15),
                                        _createElementVNode("dd", _hoisted_16, _toDisplayString(_ctx.command?.id ? _ctx.command.id : _ctx.$trans('table.shared.no_data')), 1)
                                      ]),
                                      _createElementVNode("div", _hoisted_17, [
                                        _createElementVNode("dt", {
                                          class: "font-semibold text-gray-800 dark:text-gray-100",
                                          innerHTML: _ctx.$trans('main.commands.show.user_name')
                                        }, null, 8, _hoisted_18),
                                        _createElementVNode("dd", _hoisted_19, _toDisplayString(_ctx.command?.user ? _ctx.command.user.name : _ctx.$trans('table.shared.no_data')), 1)
                                      ]),
                                      _createElementVNode("div", _hoisted_20, [
                                        _createElementVNode("dt", {
                                          class: "font-semibold text-gray-800 dark:text-gray-100",
                                          innerHTML: _ctx.$trans('main.commands.show.command')
                                        }, null, 8, _hoisted_21),
                                        _createElementVNode("dd", _hoisted_22, _toDisplayString(_ctx.command?.value ? _ctx.command.value : _ctx.$trans('table.shared.no_data')), 1)
                                      ]),
                                      _createElementVNode("div", _hoisted_23, [
                                        _createElementVNode("dt", {
                                          class: "font-semibold text-gray-800 dark:text-gray-100",
                                          innerHTML: _ctx.$trans('main.commands.show.status')
                                        }, null, 8, _hoisted_24),
                                        _createElementVNode("dd", _hoisted_25, [
                                          (_ctx.command)
                                            ? (_openBlock(), _createBlock(_component_Badge, {
                                                key: 0,
                                                type: _ctx.getBadgeType(_ctx.command?.status),
                                                text: _ctx.$trans(`main.commands.statuses.${_ctx.command?.status}`)
                                              }, {
                                                default: _withCtx(() => [
                                                  (_ctx.command?.status === 'running')
                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_26, _cache[3] || (_cache[3] = [
                                                        _createElementVNode("svg", {
                                                          "aria-hidden": "true",
                                                          class: "w-4 h-4 me-2 text-yellow-200 animate-spin dark:text-yellow-400 fill-yellow-600",
                                                          viewBox: "0 0 100 101",
                                                          fill: "none",
                                                          xmlns: "http://www.w3.org/2000/svg"
                                                        }, [
                                                          _createElementVNode("path", {
                                                            d: "M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z",
                                                            fill: "currentColor"
                                                          }),
                                                          _createElementVNode("path", {
                                                            d: "M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z",
                                                            fill: "currentFill"
                                                          })
                                                        ], -1),
                                                        _createElementVNode("span", { class: "sr-only" }, "Loading...", -1)
                                                      ])))
                                                    : _createCommentVNode("", true)
                                                ]),
                                                _: 1
                                              }, 8, ["type", "text"]))
                                            : _createCommentVNode("", true)
                                        ])
                                      ]),
                                      (_ctx.command?.output !== '' && _ctx.command?.output !== null)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                            _createElementVNode("dd", _hoisted_28, [
                                              _createVNode(_component_highlightjs, {
                                                autodetect: "",
                                                code: _ctx.command?.output ?? ''
                                              }, null, 8, ["code"])
                                            ])
                                          ]))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("div", _hoisted_29, [
                                        _createElementVNode("dt", {
                                          class: "font-semibold text-gray-800 dark:text-gray-100",
                                          innerHTML: _ctx.$trans('main.commands.show.started_at')
                                        }, null, 8, _hoisted_30),
                                        _createElementVNode("dd", _hoisted_31, _toDisplayString(_ctx.command?.started_at ? _ctx.command.started_at : _ctx.$trans('table.shared.no_data')), 1)
                                      ]),
                                      _createElementVNode("div", _hoisted_32, [
                                        _createElementVNode("dt", {
                                          class: "font-semibold text-gray-800 dark:text-gray-100",
                                          innerHTML: _ctx.$trans('main.commands.show.finished_at')
                                        }, null, 8, _hoisted_33),
                                        _createElementVNode("dd", _hoisted_34, _toDisplayString(_ctx.command?.finished_at ? _ctx.command.finished_at : _ctx.$trans('table.shared.no_data')), 1)
                                      ])
                                    ])
                                  ])
                                ])
                              ])
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_35, [
                          _createElementVNode("div", _hoisted_36, [
                            _createElementVNode("div", _hoisted_37, [
                              _createElementVNode("button", {
                                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
                                class: "bg-gray-900 dark:bg-primary-800 hover:bg-gray-700 dark:hover:bg-primary-700 text-white dark:text-gray-100 focus:ring-gray-700 dark:focus:ring-primary-950 focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                              }, _toDisplayString(_ctx.$trans('table.shared.close')), 1)
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}