import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "divide-gray-200 dark:divide-gray-800 divide-y" }
const _hoisted_2 = { class: "grid lg:max-w-full max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8" }
const _hoisted_3 = { class: "text-gray-900 dark:text-white text-base font-semibold leading-7" }
const _hoisted_4 = { class: "text-gray-600 dark:text-gray-400 mt-1 text-sm leading-6" }
const _hoisted_5 = { class: "grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6" }
const _hoisted_6 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_7 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_8 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_9 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_10 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_11 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_12 = { class: "mt-6 flex items-center justify-start gap-x-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Layout = _resolveComponent("Layout")!
  const _component_Attempt = _resolveComponent("Attempt")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Layout, {
      "site-id": _ctx.site.id,
      "site-name": _ctx.site.name
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createElementVNode("h2", _hoisted_3, _toDisplayString(`${_ctx.$trans('main.sites.edit_title')} ${_ctx.site.name}`), 1),
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$trans('main.sites.edit_description')), 1)
            ]),
            _createElementVNode("form", {
              class: "md:col-span-2",
              onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_Input, {
                    id: "site_name",
                    type: "text",
                    name: "s_name",
                    label: "validation.attributes.s_name",
                    autocomplete: "on",
                    modelValue: _ctx.editForm.s_name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editForm.s_name) = $event)),
                    "div-class": "mt-2 relative rounded-md shadow-sm",
                    required: false,
                    form: _ctx.editForm
                  }, null, 8, ["modelValue", "form"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_Input, {
                    id: "site_tag",
                    type: "text",
                    name: "s_tag",
                    label: "validation.attributes.s_tag",
                    autocomplete: "on",
                    modelValue: _ctx.editForm.s_tag,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editForm.s_tag) = $event)),
                    "div-class": "mt-2 relative rounded-md shadow-sm",
                    required: false,
                    form: _ctx.editForm
                  }, null, 8, ["modelValue", "form"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_Input, {
                    id: "site_path",
                    type: "text",
                    name: "s_path",
                    label: "validation.attributes.s_path",
                    autocomplete: "on",
                    modelValue: _ctx.editForm.s_path,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editForm.s_path) = $event)),
                    "div-class": "mt-2 relative rounded-md shadow-sm",
                    required: false,
                    form: _ctx.editForm
                  }, null, 8, ["modelValue", "form"])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_Select, {
                    id: "active",
                    label: "validation.attributes.s_active",
                    name: "s_active",
                    modelValue: _ctx.editForm.s_active,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editForm.s_active) = $event)),
                    value: "item",
                    items: [true, false],
                    "div-class": "mt-2 relative rounded-md shadow-sm",
                    required: false,
                    errors: _ctx.editForm.errors
                  }, null, 8, ["modelValue", "errors"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_Select, {
                    id: "protocol",
                    label: "validation.attributes.s_protocol",
                    name: "s_protocol",
                    modelValue: _ctx.editForm.s_protocol,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editForm.s_protocol) = $event)),
                    "div-class": "mt-2 relative rounded-md shadow-sm",
                    value: "item",
                    items: ['http', 'https'],
                    required: false,
                    errors: _ctx.editForm.errors
                  }, null, 8, ["modelValue", "errors"])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_Select, {
                    id: "version",
                    label: "validation.attributes.s_version",
                    name: "s_version",
                    modelValue: _ctx.editForm.s_version,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editForm.s_version) = $event)),
                    "div-class": "mt-2 relative rounded-md shadow-sm",
                    value: "item",
                    items: ['v1', 'v2'],
                    required: false,
                    errors: _ctx.editForm.errors
                  }, null, 8, ["modelValue", "errors"])
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_Button, {
                  text: _ctx.$trans('main.sites.update_site'),
                  loading: _ctx.editForm.processing,
                  cursorNotAllowed: !_ctx.editForm.isDirty
                }, null, 8, ["text", "loading", "cursorNotAllowed"]),
                _createVNode(_component_Button, {
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setDeleteModalIsOpen(true, _ctx.site?.id))),
                  type: "button",
                  color: "red",
                  text: _ctx.$trans('table.shared.delete')
                }, null, 8, ["text"])
              ])
            ], 32)
          ])
        ])
      ]),
      _: 1
    }, 8, ["site-id", "site-name"]),
    _createVNode(_component_Attempt, {
      open: _ctx.deleteModalIsOpen,
      close: (value) => _ctx.deleteModalIsOpen = value,
      "route-name": "sites.destroy",
      "route-argument": { id: _ctx.deleteSiteId },
      title: _ctx.$trans('main.attempts.delete_site.title'),
      description: _ctx.$trans('main.attempts.delete_site.description'),
      "success-button-text": _ctx.$trans('main.attempts.buttons.success_button'),
      "cancel-button-text": _ctx.$trans('main.attempts.buttons.cancel_button')
    }, null, 8, ["open", "close", "route-argument", "title", "description", "success-button-text", "cancel-button-text"])
  ], 64))
}