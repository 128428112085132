<template>
  <div>
    <label :for="id" class="block text-sm font-medium text-gray-700 dark:text-white">
      {{ $trans(label) }}
    </label>
    <div :class="[divClass, 'cursor-pointer']" 
      @click="triggerFileInput"
      :tabindex="0"
      @keydown.enter="triggerFileInput"
      @keydown.space="triggerFileInput">
      <div class="bg-gray-50 dark:bg-gray-850 border rounded-md px-3 py-2 shadow-sm focus:outline-none focus:ring-1 sm:text-sm"
        :class="{
          'border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700': !form.invalid(name),
          'border-red-300 dark:border-red-500 text-red-700 dark:text-red-500 focus:ring-red-500 focus:border-red-500': form.invalid(name),
        }">
        <span class="block truncate">{{ fileName || $trans(placeholder) }}</span>
      </div>
      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <CloudArrowUpIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
      </div>
      <input 
        type="file" 
        :id="id"
        ref="fileInput" 
        :name="name"
        :accept="accept" 
        @change="handleFileChange" 
        class="hidden"
      />
    </div>
    <p v-if="form.invalid(name)" class="mt-2 text-sm text-red-600 dark:text-red-500">
      {{ form.errors[name] }}
    </p>
  </div>
</template>

<script lang="ts">
import { CloudArrowUpIcon } from '@heroicons/vue/24/outline';
import { useForm } from 'laravel-precognition-vue-inertia';
import { defineComponent, ref, PropType } from 'vue';

export default defineComponent({
  name: 'FileUpload',
  components: {
    CloudArrowUpIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    accept: {
      type: String,
      default: '*/*',
    },
    form: {
      type: Object as PropType<ReturnType<typeof useForm>>,
      required: true,
    },
    divClass: {
      type: String,
      default: 'mt-1 relative rounded-md shadow-sm',
    },
    placeholder: {
      type: String,
      default: 'main.shared.buttons.no_file_chosen',
    },
  },
  emits: ['file-selected'],
  setup(props, { emit }) {
    const fileInput = ref<HTMLInputElement | null>(null);
    const fileName = ref<string | null>(null);

    const triggerFileInput = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    };

    const handleFileChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        const file = target.files[0];
        fileName.value = file.name;
        emit('file-selected', file);
        props.form.validate(props.name);
      }
    };

    return {
      triggerFileInput,
      handleFileChange,
      fileInput,
      fileName,
    };
  },
});
</script>