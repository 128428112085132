import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "float-left mr-2 mb-4 max-w-full" }
const _hoisted_2 = { class: "text-gray-900 dark:text-gray-400 inline-flex items-center mb-2 text-sm font-semibold text-center" }
const _hoisted_3 = { class: "flex flex-row flex-wrap" }
const _hoisted_4 = { class: "flex flex-wrap items-center justify-start -space-x-2" }
const _hoisted_5 = { class: "relative inline-block text-left" }
const _hoisted_6 = { class: "items-center grid grid-cols-[32px_1fr_32px] p-[4px_8px] relative text-center" }
const _hoisted_7 = { class: "text-gray-500 dark:text-gray-400 z-1 col-[1_/_span_3] box-border block text-sm font-semibold row-start-1 h-10 leading-10 m-0 overflow-hidden px-8 relative overflow-ellipsis whitespace-nowrap" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "block overflow-x-hidden overflow-y-auto py-0 px-3 pb-3" }
const _hoisted_10 = {
  key: 0,
  class: "bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-white rounded m-0 p-[24px_6px] text-center"
}
const _hoisted_11 = { class: "text-black dark:text-white flex items-center" }
const _hoisted_12 = { class: "text-nano font-semibold leading-4 mt-4 mb-2" }
const _hoisted_13 = { class: "text-black dark:text-white" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "flex-1 text-left overflow-hidden overflow-ellipsis whitespace-nowrap" }
const _hoisted_16 = { class: "text-black dark:text-white flex items-center" }
const _hoisted_17 = { class: "text-nano font-semibold leading-4 mt-4 mb-2" }
const _hoisted_18 = { class: "text-black dark:text-white" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "flex-1 text-left overflow-hidden overflow-ellipsis whitespace-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserGroupIcon = _resolveComponent("UserGroupIcon")!
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_PlusIcon = _resolveComponent("PlusIcon")!
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_UserGroupIcon, { class: "w-4 h-4 mr-1" }),
      _createTextVNode(" " + _toDisplayString(_ctx.$trans('main.boards.titles.members')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardMembers, (member) => {
          return (_openBlock(), _createBlock(_component_UserAvatar, {
            onClick: _ctx.openMenu,
            user: member,
            size: "xs",
            key: member.id
          }, null, 8, ["onClick", "user"]))
        }), 128))
      ]),
      _createVNode(_component_Menu, null, {
        default: _withCtx(({ close }) => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_MenuButton, {
              ref: "menuButtonRef",
              class: _normalizeClass(["bg-white dark:bg-gray-700 text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 border-gray-200 dark:border-gray-600 border font-semibold rounded-lg text-nano px-2 py-1 text-center inline-flex items-center", {'ml-1': _ctx.cardMembers.length}]),
              as: "button"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PlusIcon, {
                  class: _normalizeClass(["w-4 h-4 stroke-2", {'mr-1': !_ctx.cardMembers.length}])
                }, null, 8, ["class"]),
                (!_ctx.cardMembers.length)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.$trans('main.boards.buttons.join')), 1)
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_MenuItems, {
              as: "div",
              class: "absolute left-0 mt-2 w-60 origin-top-right bg-white dark:bg-gray-950 border-gray-200 dark:border-gray-750 divide-gray-100 dark:divide-gray-750 border z-50 text-black dark:text-white font-sans text-sm leading-5 font-normal rounded-lg shadow-lg outline-none"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$trans('main.boards.titles.members')), 1),
                  _createElementVNode("button", {
                    onClick: ($event: any) => (close()),
                    class: "text-gray-500 dark:text-gray-400 z-2 rounded-md col-start-3 row-start-1 p-1.5"
                  }, [
                    _createVNode(_component_XMarkIcon, { class: "w-4 h-4 stroke-2" })
                  ], 8, _hoisted_8)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["block max-h-96", {'max-h-96': _ctx.searchUnselectedMembers.length && _ctx.searchCardMembers.length}])
                  }, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                      onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.ignoreSpace && _ctx.ignoreSpace(...args))),
                      class: "border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700 bg-gray-50 dark:bg-gray-850 dark:focus:ring-primary-500 dark:focus:border-primary-500 mt-1 block w-full focus:outline-none text-xs rounded-md"
                    }, null, 544), [
                      [_vModelText, _ctx.search]
                    ]),
                    (!_ctx.searchCardMembers.length && !_ctx.searchUnselectedMembers.length)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$trans('main.boards.no_results')), 1))
                      : _createCommentVNode("", true),
                    (_ctx.searchCardMembers.length)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("h4", _hoisted_12, _toDisplayString(_ctx.$trans('main.boards.titles.card_members')), 1)
                          ]),
                          _createElementVNode("ul", _hoisted_13, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchCardMembers, (member) => {
                              return (_openBlock(), _createElementBlock("li", {
                                key: member.id
                              }, [
                                _createElementVNode("button", {
                                  onClick: ($event: any) => (_ctx.removeMember(member.id)),
                                  class: "p-[4px_8px_4px_4px] h-10 flex flex-row items-center justify-start w-full m-0 bg-transparent"
                                }, [
                                  (_openBlock(), _createBlock(_component_UserAvatar, {
                                    user: member,
                                    "with-tooltip": false,
                                    classes: "mr-2",
                                    size: "xs",
                                    key: member.id
                                  }, null, 8, ["user"])),
                                  _createElementVNode("div", _hoisted_15, _toDisplayString(member.name), 1)
                                ], 8, _hoisted_14)
                              ]))
                            }), 128))
                          ])
                        ], 64))
                      : _createCommentVNode("", true),
                    (_ctx.searchUnselectedMembers.length)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                          _createElementVNode("div", _hoisted_16, [
                            _createElementVNode("h4", _hoisted_17, _toDisplayString(_ctx.$trans('main.boards.titles.board_members')), 1)
                          ]),
                          _createElementVNode("ul", _hoisted_18, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchUnselectedMembers, (member) => {
                              return (_openBlock(), _createElementBlock("li", null, [
                                _createElementVNode("button", {
                                  onClick: ($event: any) => (_ctx.addMember(member)),
                                  class: "p-[4px_8px_4px_4px] h-10 flex flex-row items-center justify-start w-full m-0 bg-transparent"
                                }, [
                                  (_openBlock(), _createBlock(_component_UserAvatar, {
                                    user: member,
                                    "with-tooltip": false,
                                    classes: "mr-2",
                                    size: "xs",
                                    key: member.id
                                  }, null, 8, ["user"])),
                                  _createElementVNode("div", _hoisted_20, _toDisplayString(member.name), 1)
                                ], 8, _hoisted_19)
                              ]))
                            }), 256))
                          ])
                        ], 64))
                      : _createCommentVNode("", true)
                  ], 2)
                ])
              ]),
              _: 2
            }, 1024)
          ])
        ]),
        _: 1
      })
    ])
  ]))
}