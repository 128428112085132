<template>
  <h3 class="text-gray-950 dark:text-white text-lg/7 font-semibold tracking-[-0.015em] sm:text-base/7 ">
    {{ $trans('main.boards.titles.share_board') }}
  </h3>
  <p data-slot="text" class="text-gray-500 dark:text-gray-400 mt-2 text-sm  sm:text-sm">
    {{ $trans('main.boards.descriptions.share_board') }}
  </p>
  <div class="mt-4 flex max-w-md items-center gap-3 whitespace-nowrap">
    <input class="bg-gray-50 dark:bg-gray-850 dark:focus:ring-primary-500 dark:focus:border-primary-500 border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700 block w-full focus:outline-none sm:text-sm rounded-md"
      :value="hashLink"
      @click="selectInput"
      :id="inputIdName"
      type="text"
      readonly/>
    <ButtonSecondary :loading="copyLoading" @click="copyToClipboard" size="lg" type="button" :text="$trans('main.boards.buttons.copy_button')">
      <CheckBadgeIcon v-if="copyComplete" class="inline text-green-500 dark:text-green-400 h-5 w-5 mr-1.5"/>
    </ButtonSecondary>
  </div>
  <div class="mt-3">
    <button @click="regenerateInviteLink" type="button" class="text-gray-950 dark:text-white hover:text-gray-700 dark:hover:text-gray-300 text-base font-medium sm:text-sm">
      {{ $trans('main.boards.buttons.reset_invite_button') }}
    </button>
  </div>
</template>
<script lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { CheckIcon, CheckBadgeIcon } from '@heroicons/vue/24/outline';
import { PayloadResponse } from '@/Types/API/PayloadResponse';
import ButtonSecondary from '@/Shared/ButtonSecondary.vue';
import { ref, watch, defineComponent, toRef, PropType } from 'vue';
import { sendRequest } from '@/mixins';
import route from '@/ziggy';

export default defineComponent({
  emits: [
    'update-hash-link',
    'update-hash-id'
  ],
  components: {
    ButtonSecondary,
    TransitionChild,
    CheckBadgeIcon,
    TransitionRoot,
    DialogPanel,
    DialogTitle,
    CheckIcon,
    Dialog,
  },
  props: {
    boardId: {
      type: [String, null] as PropType<string | null>,
      default: null,
      required: false,
    },
    hashLink: {
      type: [String, null] as PropType<string | null>,
      default: null,
      required: false,
    },
    hashId: {
      type: [Number, null] as PropType<number | null>,
      default: null,
      required: false,
    },
  },
  setup(props, { emit }) {
    const boardId = toRef(props, 'boardId');
    const hashLink = toRef(props, 'hashLink');
    const hashId = toRef(props, 'hashId');
    const inputIdName = 'board-invite-link';
    const inviteLink = ref<HTMLInputElement | null>(null);
    const copyLoading = ref<boolean>(false);
    const copyComplete = ref<boolean>(false);

    watch(copyComplete, (value) => {
      if(value){
        setTimeout(() => {
          copyComplete.value = false;
        }, 1000);
      }
    });

    function selectInput(): void
    {
      inviteLink.value = document.getElementById(inputIdName) as HTMLInputElement;

      if(inviteLink.value) inviteLink.value.select();
    };

    function copyToClipboard(): void
    {
      inviteLink.value = document.getElementById(inputIdName) as HTMLInputElement;
      copyLoading.value = true;

      if(inviteLink.value){
        navigator.clipboard.writeText(inviteLink.value.value);
      }

      setTimeout(() => {
        copyLoading.value = false;
        copyComplete.value = true;
      }, 500);
    };

    function regenerateInviteLink(): void
    {
      if(boardId.value !== null){
        sendRequest({
          name: 'api.v1.boards.regenerate-hash',
          params: {
            board: boardId.value
          }
        }, (data: PayloadResponse<{hash_id: number, hash_link: string, hash_encoded: string}>) => {
          emit('update-hash-link', data.payload.hash_link);
          emit('update-hash-id', data.payload.hash_id);
        });
      }else{
        sendRequest({
          name: 'api.v1.boards.generate-hash',
        }, (data: PayloadResponse<{hash_id: number, hash_link: string, hash_encoded: string}>) => {
          emit('update-hash-link', data.payload.hash_link);
          emit('update-hash-id', data.payload.hash_id);
        });
      }
    }


    return { 
      regenerateInviteLink,
      copyToClipboard,
      copyComplete,
      copyLoading,
      inputIdName,
      selectInput,
      hashLink,
      hashId,
      route,
      open 
    };
  },
});
</script>