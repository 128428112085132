<template>
  <Layout :site-id="site.id" :site-name="site.name">
    <template #header>
      <!-- Heading -->
      <div class="border-gray-200 dark:border-gray-800 border-b flex flex-col items-start justify-between gap-x-8 gap-y-4 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8">
        <div>
          <div class="flex items-center gap-x-3">
            <div :class="{
                'text-green-500 bg-green-100 dark:bg-green-900': site.last_beat?.success === true,
                'text-red-500 bg-red-100 dark:bg-red-900': site.last_beat?.success === false,
              }" class="flex-none rounded-full p-1">
                <div class="h-2 w-2 rounded-full bg-current"/>
              </div>
            <h1 class="flex gap-x-3 text-base leading-7">
              <span v-text="site.name" class="text-gray-800 dark:text-white font-semibold"/>
              <span class="text-gray-400">/</span>
              <span v-text="site.tag" class="text-gray-800 dark:text-white font-semibold"/>
            </h1>
          </div>
          <p v-text="$trans('main.sites.health_description')" class="text-gray-500 dark:text-gray-400 mt-2 text-nano leading-6"/>
        </div>
      </div>
      <!-- Stats -->
      <div class="border-gray-200 dark:border-gray-800 border-b grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
        <div class="border-gray-200 dark:border-gray-800 border-t py-6 px-4 sm:px-6 lg:px-8 ">
          <p class="text-gray-900 dark:text-gray-400 text-sm font-medium leading-6">
            {{ $trans('main.sites.stats.average_response_time') }}
          </p>
          <p class="mt-2 flex items-baseline gap-x-2">
            <span class="text-gray-900 dark:text-white text-4xl font-semibold tracking-tight">
              {{ stats?.average_response_time ? parseAverageResponseTime(stats?.average_response_time) : 0 + $trans('main.times.mils') }}
            </span>
          </p>
        </div>
        <div class="border-gray-200 dark:border-gray-800 border-t py-6 px-4 sm:px-6 lg:px-8 sm:border-l">
          <p class="text-gray-900 dark:text-gray-400 text-sm font-medium leading-6">
            {{ $trans('main.sites.stats.average_actions_per_day') }}
          </p>
          <p class="mt-2 flex items-baseline gap-x-2">
            <span class="text-gray-900 dark:text-white text-4xl font-semibold tracking-tight">
              {{ stats?.average_actions_per_day ? stats?.average_actions_per_day : 0 }}
            </span>
          </p>
        </div>
        <div class="border-gray-200 dark:border-gray-800 border-t py-6 px-4 sm:px-6 lg:px-8 lg:border-l">
          <p class="text-gray-900 dark:text-gray-400 text-sm font-medium leading-6">
            {{ $trans('main.sites.stats.popular_iso_code') }}
          </p>
          <p class="mt-2 flex items-baseline gap-x-2">
            <span class="text-gray-900 dark:text-white text-4xl font-semibold tracking-tight">
              {{ (popularCountryEmoji ?? $trans('table.shared.no_data')) + truncateText(popularCountryName, 20) }}
            </span>
          </p>
        </div>
        <div class="border-gray-200 dark:border-gray-800 border-t py-6 px-4 sm:px-6 lg:px-8 sm:border-l">
          <p class="text-gray-900 dark:text-gray-400 text-sm font-medium leading-6">
            {{ $trans('main.sites.stats.percentage_of_successful_actions') }}
          </p>
          <p class="mt-2 flex items-baseline gap-x-2">
            <span class="text-gray-900 dark:text-white text-4xl font-semibold tracking-tight">
              {{ stats?.percentage_of_successful_actions ? `${stats?.percentage_of_successful_actions}%`: $trans('table.shared.no_data') }}
            </span>
          </p>
        </div>
      </div>
    </template>
    <template #content>
      <div class="border-gray-200 dark:border-gray-800 overflow-x-auto border-t pt-11">
        <h2 class="px-4 text-base font-semibold leading-7 text-black dark:text-white sm:px-6 lg:px-8">
          {{ $trans('table.sites.actions.title') }}
        </h2>
        <table class="min-w-full border-separate border-spacing-0">
          <thead>
            <tr>
              <th v-html="$trans('table.sites.actions.head.id')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold"></th>
              <th v-html="$trans('table.sites.actions.head.ip_address')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold"></th>
              <th v-html="$trans('table.sites.actions.head.route')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold"></th>
              <th v-html="$trans('table.sites.actions.head.method')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold"></th>
              <th v-html="$trans('table.sites.actions.head.status_code_text')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold"></th>
              <th v-html="$trans('table.sites.actions.head.others')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold"></th>
              <th v-html="$trans('table.sites.actions.head.response_time')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold"></th>
              <th v-html="$trans('table.sites.actions.head.created_at')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold"></th>
              <!-- <th scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"></th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(action, actionIdx) in actions?.data" :key="action.id">
              <td :class="[checkLastIndex(actionIdx) ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap p-4 text-sm text-center font-medium']">
                {{ action.id }}
              </td>
              <td :class="[checkLastIndex(actionIdx) ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap p-4 text-sm text-center font-medium']">
                {{ action.iso_code != null ? findCountryByIsoCode(action.iso_code)?.emoji : '' }}
                {{ action.ip_address }}
                {{ action.iso_code != null ? `(${action.iso_code})` : '' }}
              </td>
              <td :class="[checkLastIndex(actionIdx) ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap p-4 text-sm text-center font-medium']">
                {{ truncateText(action.route, 30) }}
              </td>
              <td :class="[checkLastIndex(actionIdx) ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap p-4 text-sm text-center font-medium']">
                <Badge :type="action.method === 'GET' ? 'success' : 'warning'" :text="action.method" class="mt-1"/>
              </td>
              <td :class="[checkLastIndex(actionIdx) ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap px-3 py-4 text-sm text-center']">
                <div :class="[statusCodeColor(action.status_code), 'flex items-center justify-center gap-x-2 text-base font-semibold']">
                  <div :class="[statusCodeColor(action.status_code, false), 'flex-none rounded-full p-1']">
                    <div class="h-1.5 w-1.5 rounded-full bg-current"></div>
                  </div>
                  <!-- <span class="text-red-500 dark:text-red-400"> -->
                  <span :class="[statusCodeColor(action.status_code)]">
                    {{ action.status_code }}
                  </span>
                </div>
                <div class="text-sm font-normal text-gray-500 dark:text-gray-400">
                  {{ truncateText(action.status_text, 30) }}
                </div>
              </td>
              <td :class="[checkLastIndex(actionIdx) ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap p-4 text-sm text-center font-medium']">
                <Badge v-if="action.is_secure" type="success" :text="$trans('table.sites.actions.tags.secure')" class="m-1"/>
                <Badge v-if="action.is_redirect" type="info" :text="$trans('table.sites.actions.tags.redirect')" class="m-1"/>
                <Badge v-if="action.is_ajax" type="info" :text="$trans('table.sites.actions.tags.ajax')" class="m-1"/>
              </td>
              <td :class="[checkLastIndex(actionIdx) ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap p-4 text-sm text-center']">
                {{ action.response_time }}
              </td>
              <td :class="[checkLastIndex(actionIdx) ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap p-4 text-sm text-center']">
                {{ action.created_at }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="!actions?.data?.length" class="flex items-center justify-center my-48">
        <h2 class="text-gray-400 dark:text-gray-300 box-border block text-2xl leading-5 relative text-center">
          {{ $trans('main.sites.actions_placeholder') }}
        </h2>
      </div>
      <Pagination v-if="actions?.data != null" :data="actions"/>
    </template>
  </Layout>
</template>
<script lang="ts">
import { Bars3Icon, MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
import { default as SiteList } from '@/Types/Resources/Site/List';
import { default as SiteAction } from '@/Types/API/Site/Action';
import { findCountryByIsoCode, truncateText } from '@/mixins';
import { default as SiteStat } from '@/Types/API/Site/Stat';
import { HttpStatusCode } from '@/Types/API/HttpStatusCode';
import Layout from '@js/Pages/Sites/Components/Layout.vue'
import Site from '@js/Pages/Sites/Components/Site.vue'
import Pagination from '@js/Shared/Pagination.vue';
import Paginator from '@/Types/App/Paginator';
import Country from '@/Types/App/Country';
import Badge from '@js/Shared/Badge.vue';
import { defineComponent } from 'vue';
import 'chartist/dist/index.css';

export default defineComponent({
  props: {
    site: {
      type: Object as () => SiteList,
      required: true,
    },
    actions: {
      type: Object as () => Paginator<SiteAction> | null,
      required: true,
    },
    stats: {
      type: Object as () => SiteStat | null,
      required: true,
    },
  },
  components: {
    MagnifyingGlassIcon,
    Pagination,
    Bars3Icon,
    Layout,
    Badge,
    Site,
  },
  setup(props) {
    const popularCountry: Country|null = findCountryByIsoCode(props.stats?.popular_iso_code);
    const popularCountryEmoji: string|null = popularCountry?.emoji ?? null;
    const popularCountryName: string = popularCountry?.name ?? "";

    function checkLastIndex(index: number): boolean
    {
      if(props.actions?.data !== null && props.actions?.data !== undefined){
        return index !== props.actions.data.length - 1;
      }else{
        return false;
      }
    }

    function statusCodeColor(statusCode: HttpStatusCode|null, withoutBackground: boolean = true): String
    {
      const code: HttpStatusCode = statusCode === null ? 0 : statusCode;
      let color: String = '';

      switch (Math.floor(code / 100))
      {
        case 1:
          color = 'blue';
          break;
        case 2:
          color = 'green';
          break;
        case 3:
          color = 'yellow';
          break;
        case 4:
          color = 'red';
          break;
        case 5:
          color = 'red';
          break;
        default:
          color = 'gray';
          break;
      }

      return withoutBackground 
        ? `text-${color}-500 dark:text-${color}-400`
        : `text-${color}-400 bg-${color}-100 dark:bg-${color}-900`;
    };

    function parseAverageResponseTime(timeString: string): string
    {
      const regex = /(\d+):(\d+):(\d+)\.(\d+)/;
      const parts = timeString.match(regex);

      if (!parts || parts.length !== 5) {
        throw new Error('Invalid time format');
      }
      
      const [, hoursStr, minutesStr, secondsStr, millisecondsStr] = parts;
      const hours = parseInt(hoursStr, 10);
      const minutes = parseInt(minutesStr, 10);
      const seconds = parseInt(secondsStr, 10);
      const milliseconds = parseInt(millisecondsStr, 10);

      let totalSeconds = hours * 3600 + minutes * 60 + seconds;
      let result: string[] = [];

      if (totalSeconds > 0) {
        // @ts-ignore
        result.push(`${totalSeconds}${this.$trans('main.times.seconds')}`);
      }
      
      if (milliseconds > 0) {
        // @ts-ignore
        result.push(`${milliseconds}${this.$trans('main.times.mils')}`);
      }

      return result.join(' ');
    };

    return {
      parseAverageResponseTime,
      findCountryByIsoCode,
      popularCountryEmoji,
      popularCountryName,
      statusCodeColor,
      checkLastIndex,
      truncateText,
    };
  },
})
</script>