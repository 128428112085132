import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "sm:flex px-4 py-4 sm:px-6 sm:py-6 lg:px-8" }
const _hoisted_2 = { class: "dark:divide-gray-700 sm:divide-gray-100 items-center mb-3 sm:flex sm:divide-x sm:mb-0" }
const _hoisted_3 = { class: "lg:pr-3" }
const _hoisted_4 = { class: "flex items-center ml-auto space-x-2 sm:space-x-3" }
const _hoisted_5 = {
  role: "list",
  class: "block text-sm text-gray-300 list-disc m-0 pl-0"
}
const _hoisted_6 = {
  key: 1,
  class: "flex items-center justify-center my-48"
}
const _hoisted_7 = { class: "text-gray-400 dark:text-gray-300 box-border block text-2xl leading-5 relative text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Head = _resolveComponent("Head")!
  const _component_PlusIcon = _resolveComponent("PlusIcon")!
  const _component_Site = _resolveComponent("Site")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_Create = _resolveComponent("Create")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Head, {
      title: _ctx.$trans('main.sites.list_title')
    }, null, 8, ["title"]),
    _createVNode(_component_Layout, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$trans('main.sites.list_title')), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setCreateDrawerIsOpen(true))),
              class: "bg-gray-900 dark:bg-primary-800 text-white dark:text-gray-100 hover:bg-gray-700 dark:hover:bg-primary-750 focus:ring-gray-700 dark:focus:ring-primary-950 focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent inline-flex items-center justify-center w-1/2 px-3 py-2 text-sm font-medium text-center rounded-lg sm:w-auto"
            }, [
              _createVNode(_component_PlusIcon, { class: "w-5 h-5 mr-2 -ml-1" }),
              _createTextVNode(" " + _toDisplayString(_ctx.$trans('main.sites.create_site')), 1)
            ])
          ])
        ]),
        _createElementVNode("ul", _hoisted_5, [
          (_ctx.sites.data.length)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.sites.data, (site) => {
                return (_openBlock(), _createBlock(_component_Site, { site: site }, null, 8, ["site"]))
              }), 256))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$trans('main.sites.list_placeholder')), 1)
              ]))
        ]),
        (_ctx.sites.data.length)
          ? (_openBlock(), _createBlock(_component_Pagination, {
              key: 0,
              data: _ctx.sites
            }, null, 8, ["data"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Create, {
          isOpen: _ctx.createDrawerIsOpen,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setCreateDrawerIsOpen(false)))
        }, null, 8, ["isOpen"])
      ]),
      _: 1
    })
  ], 64))
}