<template>
  <div class="w-[--column-width] my-0 mx-1.5 first:ml-3 box-border inline-block h-full scroll-m-2 align-top whitespace-nowrap">
    <div class="bg-gray-800 w-full h-full whitespace-normal relative flex flex-col box-border rounded-xl p-3 animate-pulse">
      <div class="h-8 bg-gray-700 rounded-md mb-3 flex items-center justify-between">
        <div class="w-3/4 h-4 bg-gray-600 rounded ml-1"></div>
        <div class="w-5 h-5 bg-gray-600 rounded-full mr-1 my-0.5"></div>
      </div>
      <div class="space-y-2 overflow-y-hidden flex-grow pr-1">
        <div v-for="i in randomValue" :key="i" class="bg-gray-700 rounded-md p-2 shadow-sm">
          <div class="flex space-x-1 mb-2">
            <div v-for="j in 4" :key="j" class="h-2 bg-gray-600 rounded w-8"></div>
          </div>
          <div class="h-4 bg-gray-600 rounded w-11/12 mb-2"></div>
          <div class="h-3 bg-gray-600 rounded w-1/2 mb-2"></div>
          <div class="flex justify-between items-center mt-2">
            <div class="flex space-x-1">
              <div v-for="k in 3" :key="k" class="w-5 h-5 bg-gray-600 rounded-full"></div>
            </div>
            <div class="w-8 h-3 bg-gray-600 rounded"></div>
          </div>
        </div>
      </div>
      <div class="h-12 bg-gray-700 rounded-md mt-3 flex items-center">
        <div class="w-5 h-5 bg-gray-600 rounded-full ml-2"></div>
        <div class="w-1/2 h-3 bg-gray-600 rounded ml-2"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  name: 'ColumnSkeleton',
  setup() {
    const randomValue = ref<number>(0);
   
    const generateRandomValue = () => {
      const minValue = 10;
      const maxValue = Math.floor(Math.random() * (25 - 10 + 1)) + 10;
      randomValue.value = Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
    };

    onMounted(() => generateRandomValue());

    return { randomValue };
  }
});
</script>

<style scoped>
.animate-pulse { animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite; }
@keyframes pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: .8; }
}
</style>