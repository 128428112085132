import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "md:flex max-w-none mx-auto" }
const _hoisted_2 = { class: "flex-1 flex-col relative" }
const _hoisted_3 = { class: "flex items-center justify-between gap-2 p-4 md:p-8 pb-0" }
const _hoisted_4 = { class: "hidden md:flex items-center justify-between text-sm rounded-lg" }
const _hoisted_5 = { class: "flex gap-1" }
const _hoisted_6 = { class: "inline-block" }
const _hoisted_7 = { class: "md:hidden relative flex justify-center" }
const _hoisted_8 = { class: "relative mt-2" }
const _hoisted_9 = { class: "flex items-center justify-between bg-gray-100 dark:bg-gray-800 dark:text-gray-200 px-3.5 py-1.5 text-sm rounded-lg font-medium" }
const _hoisted_10 = { class: "flex gap-2" }
const _hoisted_11 = { class: "flex items-center bg-slate-100 dark:bg-zinc-800 dark:text-zinc-200 text-sm rounded-lg divide-x-4 divide-white dark:divide-zinc-900" }
const _hoisted_12 = { class: "flex flex-col flex-1 p-4 md:p-8 md:pt-0" }
const _hoisted_13 = { class: "relative w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Link = _resolveComponent("Link")!
  const _component_ComboboxInput = _resolveComponent("ComboboxInput")!
  const _component_ChevronUpDownIcon = _resolveComponent("ChevronUpDownIcon")!
  const _component_ComboboxButton = _resolveComponent("ComboboxButton")!
  const _component_ComboboxOption = _resolveComponent("ComboboxOption")!
  const _component_ComboboxOptions = _resolveComponent("ComboboxOptions")!
  const _component_Combobox = _resolveComponent("Combobox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Layout = _resolveComponent("Layout", true)!
  const _component_Create = _resolveComponent("Create")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Layout, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("nav", null, [
                  _createElementVNode("ul", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statuses, (status) => {
                      return (_openBlock(), _createElementBlock("li", _hoisted_6, [
                        _createVNode(_component_Link, {
                          class: _normalizeClass(["flex items-center gap-2 px-3.5 py-1.5 rounded-md cursor-pointer", {'bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-200': _ctx.isCurrentRoute([_ctx.route('boards', status)], true),
                            'text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-gray-800 dark:hover:text-gray-200': !_ctx.isCurrentRoute([_ctx.route('boards', status)], true)}]),
                          href: _ctx.route('boards', { status })
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$trans(`main.boards.statuses_names.${status}`)), 1)
                          ]),
                          _: 2
                        }, 1032, ["href", "class"])
                      ]))
                    }), 256))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_Combobox, {
                  as: "div",
                  modelValue: _ctx.selectedStatus,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedStatus) = $event))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_ComboboxInput, {
                        onChange: _ctx.redirectToStatus,
                        hidden: "",
                        "display-value": (status) => _ctx.$trans(`main.boards.statuses.${status}`)
                      }, null, 8, ["onChange", "display-value"]),
                      _createVNode(_component_ComboboxButton, { type: "button" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$trans(`main.boards.statuses.${_ctx.selectedStatus}`)), 1),
                            _createVNode(_component_ChevronUpDownIcon, {
                              class: "w-4 ml-1 text-gray-500 dark:text-gray-400",
                              "aria-hidden": "true"
                            })
                          ])
                        ]),
                        _: 1
                      }),
                      (_ctx.statuses.length > 0)
                        ? (_openBlock(), _createBlock(_component_ComboboxOptions, {
                            key: 0,
                            class: "bg-white/95 dark:bg-gray-800 absolute z-50 top-[2.4rem] left-0 w-32 rounded-lg backdrop-blur drop-shadow-2xl focus:outline-none select-none transform opacity-100 scale-100"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statuses, (status, index) => {
                                return (_openBlock(), _createBlock(_component_ComboboxOption, {
                                  key: `${status}-${index}`,
                                  value: status,
                                  as: "template"
                                }, {
                                  default: _withCtx(({ active, selected }) => [
                                    _createElementVNode("li", {
                                      class: _normalizeClass(['relative cursor-default select-none py-2 pl-3 pr-3 text-sm', active ? 'text-gray-900 dark:text-white' : 'text-gray-900 dark:text-white'])
                                    }, [
                                      _createElementVNode("span", {
                                        class: _normalizeClass(['block truncate', selected && 'font-semibold'])
                                      }, _toDisplayString(_ctx.$trans(`main.boards.statuses.${status}`)), 3)
                                    ], 2)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_Button, {
                    text: _ctx.$trans('main.boards.buttons.create_board'),
                    type: "button",
                    size: "sm",
                    onClick: _ctx.openCreateDrawer
                  }, null, 8, ["text", "onClick"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _renderSlot(_ctx.$slots, "default")
              ])
            ])
          ])
        ])
      ]),
      _: 3
    }),
    _createVNode(_component_Create, {
      "is-open": _ctx.createDrawerIsOpen,
      members: _ctx.members,
      statuses: _ctx.statuses,
      "selected-status": _ctx.selectedStatus,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.createDrawerIsOpen = false))
    }, null, 8, ["is-open", "members", "statuses", "selected-status"])
  ], 64))
}