<template>
  <div v-if="toastNotifications.length" class="z-50 fixed inset-0 flex items-end px-6 py-6 pointer-events-none sm:p-6 sm:items-start">
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <Notification v-for="notification in toastNotifications" 
        :key="notification.id"
        :notification="notification"/>
    </div>
  </div>
  <div v-if="toast" class="z-50 fixed inset-0 flex items-end px-6 py-6 pointer-events-none sm:p-6 sm:items-start">
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <Toast v-if="toast" 
        @hide="toast = null"
        :type="toast.type"
        :title="toast.title"
        :description="toast.description"/>
    </div>
  </div>
  <Header
    @change-notifications-drawer-state="changeNotificationsDrawerState"
    :prop-notifications-drawer-is-open="notificationsDrawerIsOpen"
    @update-notifications="handleUpdateNotifications"
    :prop-notifications-length="notificationsLength"
    :prop-notifications="notifications"
    :navigation-color="navigationColor"
    :logo-color="logoColor"/>
  <main :class="[...mainClasses]" class="flex flex-1 flex-col sm:pb-2 md:px-2 min-h-[calc(100vh-8rem)] sm:min-h-[calc(100vh-4.6rem)]">
    <div :class="[...divClasses]" class="grow md:rounded-lg md:shadow-sm md:ring-1">
      <slot/>
    </div>  
  </main>
  <Footer/>
  <CommandPalette :isOpen="cmdOpen" :close="(value: Boolean) => cmdOpen = value"/>
</template>
<script lang="ts">
import { defineComponent, PropType, reactive, onMounted, onUnmounted, watch, inject, ref, computed } from 'vue';
import ShortNotification from '@/Types/Resources/Notification/Short';
import Notification from "@js/Shared/Notification.vue";
import { socketChannelNameBuilder } from '@/mixins';
import CommandPalette from '@js/Pages/Command.vue';
import { Channel } from '../../socket/channels.js';
import ToastType from "@/Types/App/Feedback/Toast";
import { TransitionRoot } from '@headlessui/vue';
import { useRemember } from '@inertiajs/vue3';
import Sidebar from '@js/Shared/Sidebar.vue';
import Header from '@js/Shared/Header.vue';
import Footer from '@js/Shared/Footer.vue';
import { Socket } from 'socket.io-client';
import { usePage } from '@inertiajs/vue3';
import Toast from "@js/Shared/Toast.vue";
import Auth from '@/Types/App/Auth';

export default defineComponent({
  props: {
    bodyClasses: {
      type: Array as PropType<Array<string>>,
      default: ['bg-white','dark:bg-gray-950'],
    },
    mainClasses: {
      type: Array as PropType<Array<string>>,
      default: ['bg-white','dark:bg-gray-950'],
    },
    divClasses: {
      type: Array as PropType<Array<string>>,
      default: ['bg-white', 'dark:bg-gray-900', 'ring-gray-300', 'dark:ring-gray-800'],
    },
    logoColor: {
      type: Array as PropType<Array<string>>,
      default: ['dark:filter-white'],
    },
    navigationColor: {
      type: Array as PropType<Array<string>>,
      default: ['text-slate-800', 'dark:text-gray-200'],
    },
  },
  components: {
    CommandPalette,
    TransitionRoot,
    Notification,
    Sidebar,
    Footer,
    Header,
    Toast,
  },
  setup(props) {
    const html: HTMLElement = reactive(document.documentElement as HTMLElement);
    const body: HTMLBodyElement = reactive(document.body as HTMLBodyElement);

    const cmdOpen = ref<Boolean>(false);
    
    const auth = ref<Auth>(usePage().props?.auth as Auth);
    const notificationsDrawerIsOpen = ref<boolean>(false);
    const notifications = ref<Auth['notifications']>(useRemember(auth.value.notifications, 'notifications') as Auth['notifications']);
    const notificationsLength = ref<number>(localStorage.getItem('notifications_length') === null ? auth.value.notifications_length : parseInt(localStorage.getItem('notifications_length') as string));
    const toast = ref<ToastType|null>(null);
    const toastNotifications = ref([] as Array<ShortNotification>);

    const socket = inject<Socket>('$socket');
    const socketUserNotificationsChannel = ref<string|null>(null);

    watch(notificationsLength, (length) => {
      localStorage.setItem('notifications_length', length.toString());
    });
    watch(() => usePage().props.toast as ToastType|null, (newToast: ToastType|null) => {
      if(localStorage.getItem('last_toast_id') !== newToast?.id
      && typeof newToast === 'object' 
      && newToast !== undefined
      && newToast !== null){
        setTimeout(() => {
          toast.value = newToast;
          localStorage.setItem('last_toast_id', toast.value?.id as string);
        }, 600);
      }
    },{
      immediate: true
    });

    function handleUpdateNotifications(newNotifications: Auth['notifications']): void
    {
      let unreadNotificationsLength = newNotifications.data.filter((n) => n.is_read === false).length;
      
      auth.value.notifications_length = unreadNotificationsLength;
      notifications.value.data = newNotifications.data;
      notificationsLength.value = unreadNotificationsLength;

      useRemember(notifications.value, 'notifications');
    }

    function changeNotificationsDrawerState(value: boolean): void
    {
      if(notificationsDrawerIsOpen.value !== value){
        notificationsDrawerIsOpen.value = value;
      }
    }
    
    function hideToastNotification(notification: ShortNotification): void
    {
      toastNotifications.value = toastNotifications.value.filter((n) => n.id !== notification.id);
    }
    
    onMounted(() => {
      html.classList.add('h-full');
      body.classList.add('h-full', ...props.bodyClasses);

      socketUserNotificationsChannel.value = socketChannelNameBuilder(Channel.UserNotificationsEvent, auth.value.user?.id as number);
      socket?.on(socketUserNotificationsChannel.value, function (data: {notification: ShortNotification}) {
        changeNotificationsDrawerState(false);

        notifications.value.data.unshift(data.notification);
        notificationsLength.value += 1;
        
        toastNotifications.value.unshift(data.notification);
      });
    });

    onUnmounted(() => {
      html.classList.remove('h-full');
      body.classList.remove('h-full', ...props.bodyClasses);

      socket?.off(socketUserNotificationsChannel.value ?? undefined);
      socketUserNotificationsChannel.value = null;
    });

    return { 
      changeNotificationsDrawerState,
      notificationsDrawerIsOpen,
      handleUpdateNotifications,
      hideToastNotification,
      notificationsLength,
      toastNotifications,
      notifications,
      cmdOpen,
      toast 
    };
  }
});
</script>