import { default as List } from '@/Types/Resources/CheckList/Light';
import { ref, computed, Ref, watch } from 'vue';
import { sendRequest } from '@/mixins';

export function useCheckListDragControl(lists: Ref<List[]>)
{
  const isListBeingDragged = ref<boolean>(false);
  const listDragOptions = computed(() => ({
    group: {
      name: 'lists',
      pull: 'clone',
      put: (to: any, from: any) => from.options.group.name === 'lists'
    },
    emptyInsertThreshold: 50,
    ghostClass: 'list-ghost',
    filter: '.list-no-drag',
    preventOnFilter: false,
    handle: 'list-handle',
    forceFallback: true,
    animation: 200,
    itemKey: 'id',
    delay: 15
  }));

  watch(() => lists.value?.map(list => ({
    position: list.position,
    checks: list.checks
  })), () => {
    lists.value?.forEach((list, index) => {
      list.position = index;
      list.checks.forEach((check, index) => {
        check.position = index;
      });
    });

    lists.value?.sort((a, b) => a.position - b.position);
    lists.value?.forEach((list) => {
      list.checks.sort((a, b) => a.position - b.position);
    });
  },{
    deep: true,
    immediate: true
  });

  function onListPositionChange(): void 
  {
    sendRequest({
      name: 'api.v1.boards.cards.checklists.positions.update',
      data: {
        lists: lists.value.map((list: List) => ({
          id: list.id,
          name: list.name,
          position: list.position
        }))
      },
    });
  }

  function onListDragStart(): void
  {
    isListBeingDragged.value = true;
  }

  function onListDragEnd(): void 
  {
    isListBeingDragged.value = false;
  }

  return {
    onListPositionChange,
    isListBeingDragged, 
    listDragOptions,
    onListDragStart,
    onListDragEnd
  };
}