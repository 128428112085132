<template>
  <div class="wysiwyg-editor">
    <div class="flex space-x-2">
      <button v-for="(action, index) in actions"
        :key="index"
        type="button"
        @click="executeAction(action.command)"
        class="p-1 rounded-md text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 dark:focus:ring-offset-gray-800">
        <component :is="action.icon" class="h-4 w-4 stroke-2" />
      </button>
    </div>
    <label v-if="label" :for="id" class="block text-sm font-medium text-gray-700 dark:text-gray-300">
      {{ label }}
    </label>
    <div :class="[
        'mt-1 border rounded-md shadow-sm focus-within:ring-1 focus-within:ring-gray-700 focus-within:border-gray-700',
        'dark:bg-gray-850/50 dark:border-gray-700 dark:focus-within:ring-primary-700',
        { 'border-red-300 dark:border-red-500': form.errors[name] }
      ]">
      <editor-content v-if="editor" :editor="editor" />
    </div>
    <p v-if="form.errors[name]" class="mt-2 text-sm text-red-600 dark:text-red-400">
      {{ form.errors[name] }}
    </p>
  </div>
</template>
<style>
.wysiwyg-editor .ProseMirror-trailingBreak {
  display: none;
}
.wysiwyg-editor .ProseMirror {
  @apply p-2 min-h-[100px] outline-none leading-8;
}
.wysiwyg-editor .ProseMirror p.is-editor-empty:first-child::before {
  @apply text-gray-400 dark:text-gray-500 float-left h-0 pointer-events-none;
  content: attr(data-placeholder);
}
.mention {
  @apply bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-200 rounded px-2 py-1;
}
.mention[is-current-user="true"] {
  @apply bg-primary-100 dark:bg-primary-900 text-primary-800 dark:text-primary-200;
}
.editor-link {
  @apply text-primary-600 dark:text-primary-400 underline hover:text-primary-800 dark:hover:text-primary-300 transition-colors duration-150 ease-in-out inline-block truncate align-bottom;
}
.editor-paragraph {
  margin: 0;
  padding: 0;
}
</style>
<script lang="ts">
import { BoldIcon, ItalicIcon, UnderlineIcon, QueueListIcon, LinkIcon } from '@heroicons/vue/24/outline';
import { defineComponent, shallowRef, onMounted, onBeforeUnmount, PropType, watch } from 'vue';
import { default as UserMember } from '@/Types/Resources/User/Member';
import { Editor, EditorContent } from '@tiptap/vue-3';
import Underline from '@tiptap/extension-underline';
import Paragraph from '@tiptap/extension-paragraph';
import Mention from '@tiptap/extension-mention';
import { mergeAttributes } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import { VueRenderer } from '@tiptap/vue-3';
import MentionList from './MentionList.vue';
import Link from '@tiptap/extension-link';
import { useForm } from '@inertiajs/vue3';
import tippy from 'tippy.js';

interface Action {
  icon: any
  command: string
}

export default defineComponent({
  name: 'Editor',
  components: {
    EditorContent,
    QueueListIcon,
    UnderlineIcon,
    ItalicIcon,
    BoldIcon,
    LinkIcon,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    form: {
      type: Object as PropType<ReturnType<typeof useForm>>,
      required: true,
    },
    members: {
      type: Array as () => Array<UserMember>,
      default: () => [],
    },
    currentUserId: {
      type: Number,
      required: true,
    }
  },
  emits: [
    'update:modelValue',
    'focusout',
    'focus',
  ],
  setup(props, { emit, expose }) {
    const editor = shallowRef<Editor | null>(null)

    const focusEditor = () => {
      if (editor.value) {
        editor.value.commands.focus();
      }
    };

    const actions: Action[] = [
      { icon: 'BoldIcon', command: 'toggleBold' },
      { icon: 'ItalicIcon', command: 'toggleItalic' },
      { icon: 'UnderlineIcon', command: 'toggleUnderline' },
      // { icon: 'QueueListIcon', command: 'toggleBulletList' },
      // { icon: 'LinkIcon', command: 'toggleLink' },
    ]

    const mentionSuggestion = {
      items: ({ query }: { query: string }) => {
        return props.members
          ?.filter(item => item.name.toLowerCase().startsWith(query.toLowerCase()))
          .map(member => ({
            id: member.id,
            name: member.name,
            label: member.name,
            is_current_user: props.currentUserId === member.id,
          }))
          .slice(0, 15) || []
      },
      render: () => {
        let component: VueRenderer
        let popup: any

        return {
          onStart: (props: any) => {
            component = new VueRenderer(MentionList, {
              props,
              editor: props.editor,
            })

            popup = tippy('body', {
              getReferenceClientRect: props.clientRect,
              appendTo: () => document.body,
              content: component?.element as Element ?? undefined,
              showOnCreate: true,
              interactive: true,
              trigger: 'manual',
              placement: 'bottom-start',
            })
          },

          onUpdate(props: any) {
            component.updateProps(props)

            popup[0].setProps({
              getReferenceClientRect: props.clientRect,
            })
          },

          onKeyDown(props: any) {
            if (props.event.key === 'Escape') {
              popup[0].hide()
              return true
            }

            return component.ref?.onKeyDown(props)
          },

          onExit() {
            setTimeout(() => {
              try {
                if (popup && popup[0] && !popup[0].state.isDestroyed) {
                  popup[0].destroy();
                }
              } catch (error) {
                console.error('Mentions popup destroy error:', error);
              }

              try {
                if (component && component.element && component.element.parentNode) {
                  component.destroy();
                }
              } catch (error) {
                console.error('Mentions component destroy error:', error);
              }
            }, 0);
          },
        }
      },
    }

    onMounted(() => {
      editor.value = new Editor({
        content: props.modelValue,
        extensions: [
          StarterKit.configure({
            paragraph: false
          }),
          Paragraph.configure({
            HTMLAttributes: {
              class: 'min-h-4',
            }
          }),
          Link.configure({
            autolink: true,
            linkOnPaste: true,
            openOnClick: false,
            HTMLAttributes: {
              class: 'editor-link',
            },
          }),
          Underline,
          Mention.configure({
            HTMLAttributes: {
              class: 'mention',
            },
            suggestion: mentionSuggestion as any,
            renderHTML({ options, node }) {
              return [
                'span',
                mergeAttributes({ 'is-current-user': node.attrs.id == props.currentUserId }, options.HTMLAttributes),
                `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`,
              ]
            },
          }),
        ],
        onUpdate: ({ editor }) => {
          const html = editor.getHTML()
          emit('update:modelValue', html);
        },
        onFocus: ({ event }) => {
          emit('focus', event);
        },
        onBlur: ({ event }) => {
          emit('focusout', event);
        },
      })
    })

    watch(() => props.modelValue, (newValue) => {
      if (editor.value && newValue !== editor.value.getHTML()) {
        editor.value.commands.setContent(newValue)
      }
    })

    const executeAction = (command: string) => {
      if (editor.value) {
        switch (command) {
          case 'toggleBold':
            editor.value.chain().focus().toggleBold().run()
            break
          case 'toggleItalic':
            editor.value.chain().focus().toggleItalic().run()
            break
          case 'toggleUnderline':
            editor.value.chain().focus().toggleUnderline().run()
            break
          // case 'toggleBulletList':
          //   editor.value.chain().focus().toggleBulletList().run()
          //   break
          // case 'toggleLink':
          //   const url = prompt('Введите URL:')
          //   if (url) {
          //     // editor.value.chain().focus().setLink({ href: url }).run()
          //   }
          //   break
          default:
            console.warn(`Unknown command: ${command}`)
        }
      }
    }

    onBeforeUnmount(() => {
      if(editor.value) editor.value.destroy();
    })

    expose({
      focusEditor,
    });

    return {
      editor,
      actions,
      executeAction,
    }
  },
})
</script>