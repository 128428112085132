<template>
  <div v-if="hasMoreCards" ref="observer" class="w-full" :class="isVisible && hasMoreCards && 'p-2', mainClass">
    <div v-if="isVisible && hasMoreCards" class="bg-white dark:bg-gray-800 rounded-md p-3 animate-pulse">
      <div class="flex justify-between items-center mb-3">
        <div class="flex-grow">
          <div class="h-5 bg-gray-300 dark:bg-gray-600 rounded w-[70%]"></div>
        </div>
      </div>
      <div class="flex space-x-1">
        <div v-for="i in 4" :key="i" class="w-6 h-6 rounded-full bg-gray- bg-gray-300 dark:bg-gray-600"></div>
      </div>
    </div>
    <div v-else class="h-[1px]"></div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, onUnmounted, watch, computed } from 'vue';

export default defineComponent({
  name: 'CardSkeleton',
  props: {
    columnId: {
      type: String,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    lastPage: {
      type: Number,
      required: true,
    },
    mainClass: {
      type: String,
      default: '',
    },
  },
  emits: ['load-more'],
  setup(props, { emit }) {
    const observer = ref<HTMLElement | null>(null);
    const intersectionObserver = ref<IntersectionObserver | null>(null);
    const isVisible = ref(false);
    const isLoading = ref(false);

    const hasMoreCards = computed(() => props.currentPage < props.lastPage);

    const log = (message: string, data?: any) => {
      // console.log(`[CardSkeleton ${props.columnId}] ${message}`, data);
    };

    const createObserver = () => {
      log('Creating observer');
      intersectionObserver.value = new IntersectionObserver(
        (entries) => {
          const entry = entries[0];
          isVisible.value = entry.isIntersecting;
          log('Intersection observed', {
            isIntersecting: entry.isIntersecting,
            hasMoreCards: hasMoreCards.value,
            isLoading: isLoading.value
          });
          if (entry.isIntersecting && hasMoreCards.value && !isLoading.value) {
            log('Triggering load more');
            isLoading.value = true;
            emit('load-more');
          }
        },
        { threshold: 0.1, rootMargin: '100px' }
      );

      if (observer.value) {
        intersectionObserver.value.observe(observer.value);
        log('Observer attached');
      }
    };

    onMounted(() => {
      log('Component mounted');
      createObserver();
    });

    onUnmounted(() => {
      log('Component unmounting');
      if (intersectionObserver.value) {
        intersectionObserver.value.disconnect();
      }
    });

    watch(() => hasMoreCards.value, (newVal) => {
      log('hasMoreCards changed', newVal);
      if (!newVal) {
        isLoading.value = false;
      }
    });

    const resetLoading = () => {
      log('Resetting loading state');
      isLoading.value = false;
    };

    return {
      observer,
      isVisible,
      isLoading,
      hasMoreCards,
      resetLoading
    };
  },
});
</script>