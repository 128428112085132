const Channel = {
  UserCommandUpdatedEvent: 'private-user.command.updated',
  UserNotificationsEvent: 'private-user.notifications',
  
  BoardDetailsUpdatedEvent: 'private-board.details.updated',
  BoardColumnsUpdatedEvent: 'private-board.columns.updated',
  BoardCardMovedEvent: 'private-board.card.moved',
  BoardCardPreviewUpdatedEvent: 'private-board.card.preview.updated',
  BoardCardDetailsUpdatedEvent: 'private-board.card.details.updated',
  BoardCardActionsUpdatedEvent: 'private-board.card.actions.updated',
};

const ChannelEvents = {
  [Channel.UserCommandUpdatedEvent]: 'App\\Events\\User\\CommandUpdatedEvent',
  [Channel.UserNotificationsEvent]: 'App\\Events\\User\\NotificationsEvent',
  
  [Channel.BoardDetailsUpdatedEvent]: 'App\\Events\\Board\\DetailsUpdatedEvent',
  [Channel.BoardColumnsUpdatedEvent]: 'App\\Events\\Board\\ColumnsUpdatedEvent',
  [Channel.BoardCardMovedEvent]: 'App\\Events\\Board\\CardMovedEvent',
  [Channel.BoardCardPreviewUpdatedEvent]: 'App\\Events\\Board\\CardPreviewUpdatedEvent', //TURN OFF
  [Channel.BoardCardDetailsUpdatedEvent]: 'App\\Events\\Board\\CardDetailsUpdatedEvent', //TURN OFF
  [Channel.BoardCardActionsUpdatedEvent]: 'App\\Events\\Board\\CardActionsUpdatedEvent', //TURN OFF
};

module.exports = { Channel, ChannelEvents };