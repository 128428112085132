import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "divide-gray-200 dark:divide-gray-800 divide-y" }
const _hoisted_2 = { class: "grid lg:max-w-full max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-10 sm:px-6 md:grid-cols-3 lg:px-8" }
const _hoisted_3 = { class: "text-gray-900 dark:text-white text-base font-semibold leading-7" }
const _hoisted_4 = { class: "text-gray-600 dark:text-gray-400 mt-1 text-sm leading-6" }
const _hoisted_5 = { class: "grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6" }
const _hoisted_6 = { class: "col-span-full flex items-center gap-x-8" }
const _hoisted_7 = { class: "text-gray-900 dark:text-white mb-1 max-w-48 truncate text-xl font-bold" }
const _hoisted_8 = {
  key: 0,
  class: "mt-2 text-sm font-medium text-red-700 dark:text-red-500"
}
const _hoisted_9 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_10 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_11 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_12 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_13 = { class: "col-span-6 sm:col-span-full" }
const _hoisted_14 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_15 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_16 = { class: "mt-6 flex" }
const _hoisted_17 = { class: "grid lg:max-w-full max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-10 sm:px-6 md:grid-cols-3 lg:px-8" }
const _hoisted_18 = { class: "text-gray-900 dark:text-white text-base font-semibold leading-7" }
const _hoisted_19 = { class: "text-gray-600 dark:text-gray-400 mt-1 text-sm leading-6" }
const _hoisted_20 = { class: "grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6" }
const _hoisted_21 = { class: "col-span-full" }
const _hoisted_22 = { class: "col-span-full" }
const _hoisted_23 = { class: "col-span-full" }
const _hoisted_24 = { class: "mt-6 flex" }
const _hoisted_25 = { class: "grid lg:max-w-full max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-10 sm:px-6 md:grid-cols-3 lg:px-8" }
const _hoisted_26 = { class: "text-gray-900 dark:text-white text-base font-semibold leading-7" }
const _hoisted_27 = { class: "text-gray-600 dark:text-gray-400 mt-1 text-sm leading-6" }
const _hoisted_28 = { class: "grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6" }
const _hoisted_29 = { class: "col-span-full" }
const _hoisted_30 = { class: "col-span-full" }
const _hoisted_31 = { class: "col-span-full" }
const _hoisted_32 = { class: "mt-6 flex" }
const _hoisted_33 = { class: "grid lg:max-w-full max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-10 sm:px-6 md:grid-cols-3 lg:px-8" }
const _hoisted_34 = { class: "text-gray-900 dark:text-white text-base font-semibold leading-7" }
const _hoisted_35 = { class: "text-gray-600 dark:text-gray-400 mt-1 text-sm leading-6" }
const _hoisted_36 = { class: "grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6" }
const _hoisted_37 = { class: "col-span-full" }
const _hoisted_38 = { class: "mt-6 flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, null, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$trans('main.profile.personal.title')), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$trans('main.profile.personal.description')), 1)
          ]),
          _createElementVNode("form", {
            class: "md:col-span-2",
            onSubmit: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitGeneral && _ctx.submitGeneral(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_UserAvatar, {
                  user: _ctx.user,
                  size: "3xl",
                  withTooltip: false,
                  classes: `${_ctx.user.photo_path ? 'rounded-lg' : ''}`
                }, null, 8, ["user", "classes"]),
                _createElementVNode("div", null, [
                  _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.user.name), 1),
                  _createElementVNode("div", {
                    class: _normalizeClass(["mb-2 text-sm font-normal", {'text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-pink-500 to-teal-400 font-bold': _ctx.user.role.value == 'super_admin',
                            'text-primary-700 dark:text-primary-500': _ctx.user.role.value == 'admin',
                            'text-teal-700 dark:text-teal-500': _ctx.user.role.value == 'moderator',
                            'text-gray-700 dark:text-gray-500': _ctx.user.role.value == 'user'}])
                  }, _toDisplayString(_ctx.user.role.name), 3),
                  _createElementVNode("input", {
                    type: "file",
                    class: "hidden",
                    ref: "fileInput",
                    accept: "image/*",
                    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeAvatar && _ctx.changeAvatar(...args)))
                  }, null, 544),
                  _createElementVNode("button", {
                    type: "button",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.browse && _ctx.browse(...args))),
                    class: "bg-gray-900 dark:bg-primary-800 hover:bg-gray-700 dark:hover:bg-primary-700 text-white dark:text-gray-100 focus:ring-gray-700 dark:focus:ring-primary-950 max-w-48 focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent inline-flex items-center py-1 px-3 text-sm font-light text-center rounded-md"
                  }, [
                    _cache[20] || (_cache[20] = _createElementVNode("svg", {
                      class: "mr-2 -ml-1 w-5 h-5",
                      xmlns: "http://www.w3.org/2000/svg",
                      fill: "none",
                      viewBox: "0 0 24 24",
                      "stroke-width": "1.5",
                      stroke: "currentColor"
                    }, [
                      _createElementVNode("path", {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        d: "M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                      })
                    ], -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.$trans('main.profile.titles.avatar')), 1)
                  ]),
                  _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
                  (_ctx.avatarForm.errors.photo)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.avatarForm.errors.photo), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_Input, {
                  id: "p_first_name",
                  type: "text",
                  label: "validation.attributes.p_first_name",
                  "div-class": "mt-1 relative rounded-md shadow-sm",
                  name: "p_first_name",
                  modelValue: _ctx.generalForm.p_first_name,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.generalForm.p_first_name) = $event)),
                  placeholder: _ctx.$trans('main.profile.placeholders.first_name'),
                  required: true,
                  form: _ctx.generalForm
                }, null, 8, ["modelValue", "placeholder", "form"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_Input, {
                  id: "p_last_name",
                  type: "text",
                  label: "validation.attributes.p_last_name",
                  "div-class": "mt-1 relative rounded-md shadow-sm",
                  name: "p_last_name",
                  modelValue: _ctx.generalForm.p_last_name,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.generalForm.p_last_name) = $event)),
                  placeholder: _ctx.$trans('main.profile.placeholders.last_name'),
                  required: true,
                  form: _ctx.generalForm
                }, null, 8, ["modelValue", "placeholder", "form"])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_Input, {
                  id: "p_nickname",
                  type: "text",
                  label: "validation.attributes.p_nickname",
                  "div-class": "mt-1 relative rounded-md shadow-sm",
                  name: "p_nickname",
                  modelValue: _ctx.generalForm.p_nickname,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.generalForm.p_nickname) = $event)),
                  placeholder: _ctx.$trans('main.profile.placeholders.nickname'),
                  required: true,
                  form: _ctx.generalForm
                }, null, 8, ["modelValue", "placeholder", "form"])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_Input, {
                  id: "p_phone_number",
                  type: "text",
                  label: "validation.attributes.p_phone_number",
                  "div-class": "mt-1 relative rounded-md shadow-sm",
                  name: "p_phone_number",
                  modelValue: _ctx.generalForm.p_phone_number,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.generalForm.p_phone_number) = $event)),
                  placeholder: _ctx.$trans('main.profile.placeholders.phone_number'),
                  required: false,
                  form: _ctx.generalForm
                }, null, 8, ["modelValue", "placeholder", "form"])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_Input, {
                  id: "p_address",
                  type: "text",
                  label: "validation.attributes.p_address",
                  "div-class": "mt-1 relative rounded-md shadow-sm",
                  name: "p_address",
                  modelValue: _ctx.generalForm.p_address,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.generalForm.p_address) = $event)),
                  placeholder: _ctx.$trans('main.profile.placeholders.address'),
                  required: false,
                  form: _ctx.generalForm
                }, null, 8, ["modelValue", "placeholder", "form"])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_Select, {
                  id: "p_country",
                  label: "validation.attributes.p_country",
                  name: "p_country",
                  modelValue: _ctx.generalForm.p_country,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.generalForm.p_country) = $event)),
                  "option-key": "id",
                  value: "id",
                  title: "name",
                  items: _ctx.countries,
                  required: false,
                  errors: _ctx.generalForm.errors
                }, null, 8, ["modelValue", "items", "errors"])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_Select, {
                  id: "p_language",
                  label: "validation.attributes.p_language",
                  name: "p_language",
                  modelValue: _ctx.generalForm.p_language,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.generalForm.p_language) = $event)),
                  "option-key": "value",
                  value: "value",
                  title: "name",
                  items: _ctx.languages,
                  required: false,
                  errors: _ctx.generalForm.errors
                }, null, 8, ["modelValue", "items", "errors"])
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_Button, {
                text: _ctx.$trans('main.profile.buttons.update'),
                disabled: _ctx.generalForm.invalid('p_first_name') || _ctx.generalForm.invalid('p_last_name'),
                loading: _ctx.generalForm.processing,
                cursorNotAllowed: !_ctx.generalForm.isDirty
              }, null, 8, ["text", "disabled", "loading", "cursorNotAllowed"])
            ])
          ], 32)
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", null, [
            _createElementVNode("h2", _hoisted_18, _toDisplayString(_ctx.$trans('main.profile.password.title')), 1),
            _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$trans('main.profile.password.description')), 1)
          ]),
          _createElementVNode("form", {
            class: "md:col-span-2",
            onSubmit: _cache[13] || (_cache[13] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitPassword && _ctx.submitPassword(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createVNode(_component_Input, {
                  id: "p_current_password",
                  type: "password",
                  label: "validation.attributes.p_current_password",
                  "div-class": "mt-1 relative rounded-md shadow-sm",
                  name: "p_current_password",
                  modelValue: _ctx.passwordForm.p_current_password,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.passwordForm.p_current_password) = $event)),
                  placeholder: _ctx.$trans('main.profile.placeholders.old_password'),
                  required: true,
                  form: _ctx.passwordForm
                }, null, 8, ["modelValue", "placeholder", "form"])
              ]),
              _createElementVNode("div", _hoisted_22, [
                _createVNode(_component_Input, {
                  id: "p_password",
                  type: "password",
                  label: "validation.attributes.p_password",
                  "div-class": "mt-1 relative rounded-md shadow-sm",
                  name: "p_password",
                  modelValue: _ctx.passwordForm.p_password,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.passwordForm.p_password) = $event)),
                  placeholder: _ctx.$trans('main.profile.placeholders.new_password'),
                  required: true,
                  form: _ctx.passwordForm
                }, null, 8, ["modelValue", "placeholder", "form"])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createVNode(_component_Input, {
                  id: "p_password_confirmation",
                  type: "password",
                  label: "validation.attributes.p_password_confirmation",
                  "div-class": "mt-1 relative rounded-md shadow-sm",
                  name: "p_password_confirmation",
                  modelValue: _ctx.passwordForm.p_password_confirmation,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.passwordForm.p_password_confirmation) = $event)),
                  placeholder: _ctx.$trans('main.profile.placeholders.new_password_confirmation'),
                  required: true,
                  form: _ctx.passwordForm
                }, null, 8, ["modelValue", "placeholder", "form"])
              ])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createVNode(_component_Button, {
                text: _ctx.$trans('main.profile.buttons.update'),
                loading: _ctx.passwordForm.processing,
                cursorNotAllowed: !_ctx.passwordForm.isDirty,
                disabled: _ctx.passwordForm.invalid('p_current_password') ||
                          _ctx.passwordForm.invalid('p_password') ||
                          _ctx.passwordForm.invalid('p_password_confirmation')
              }, null, 8, ["text", "loading", "cursorNotAllowed", "disabled"])
            ])
          ], 32)
        ]),
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("div", null, [
            _createElementVNode("h2", _hoisted_26, _toDisplayString(_ctx.$trans('main.profile.email.title')), 1),
            _createElementVNode("p", _hoisted_27, _toDisplayString(_ctx.$trans('main.profile.email.description')), 1)
          ]),
          _createElementVNode("form", {
            class: "md:col-span-2",
            onSubmit: _cache[17] || (_cache[17] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitEmail && _ctx.submitEmail(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("div", _hoisted_29, [
                _createVNode(_component_Input, {
                  id: "p_current_email",
                  type: "email",
                  label: "validation.attributes.p_current_email",
                  "div-class": "mt-1 relative rounded-md shadow-sm",
                  name: "p_current_email",
                  modelValue: _ctx.emailForm.p_current_email,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.emailForm.p_current_email) = $event)),
                  placeholder: _ctx.$trans('main.profile.placeholders.current_email'),
                  required: true,
                  form: _ctx.emailForm
                }, null, 8, ["modelValue", "placeholder", "form"])
              ]),
              _createElementVNode("div", _hoisted_30, [
                _createVNode(_component_Input, {
                  id: "p_email",
                  type: "email",
                  label: "validation.attributes.p_email",
                  "div-class": "mt-1 relative rounded-md shadow-sm",
                  name: "p_email",
                  modelValue: _ctx.emailForm.p_email,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.emailForm.p_email) = $event)),
                  placeholder: _ctx.$trans('main.profile.placeholders.new_email'),
                  required: true,
                  form: _ctx.emailForm
                }, null, 8, ["modelValue", "placeholder", "form"])
              ]),
              _createElementVNode("div", _hoisted_31, [
                _createVNode(_component_Input, {
                  id: "p_email_confirmation",
                  type: "email",
                  label: "validation.attributes.p_email_confirmation",
                  "div-class": "mt-1 relative rounded-md shadow-sm",
                  name: "p_email_confirmation",
                  modelValue: _ctx.emailForm.p_email_confirmation,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.emailForm.p_email_confirmation) = $event)),
                  placeholder: _ctx.$trans('main.profile.placeholders.new_email_confirmation'),
                  required: true,
                  form: _ctx.emailForm
                }, null, 8, ["modelValue", "placeholder", "form"])
              ])
            ]),
            _createElementVNode("div", _hoisted_32, [
              _createVNode(_component_Button, {
                text: _ctx.$trans('main.profile.buttons.update'),
                loading: _ctx.emailForm.processing,
                cursorNotAllowed: !_ctx.emailForm.isDirty,
                disabled: _ctx.emailForm.invalid('p_current_email') ||
                          _ctx.emailForm.invalid('p_email') ||
                          _ctx.emailForm.invalid('p_email_confirmation')
              }, null, 8, ["text", "loading", "cursorNotAllowed", "disabled"])
            ])
          ], 32)
        ]),
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("div", null, [
            _createElementVNode("h2", _hoisted_34, _toDisplayString(_ctx.$trans('main.profile.sessions.title')), 1),
            _createElementVNode("p", _hoisted_35, _toDisplayString(_ctx.$trans('main.profile.sessions.description')), 1)
          ]),
          _createElementVNode("form", {
            class: "md:col-span-2",
            onSubmit: _cache[19] || (_cache[19] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitSession && _ctx.submitSession(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_36, [
              _createElementVNode("div", _hoisted_37, [
                _createVNode(_component_Input, {
                  id: "p_s_password",
                  type: "password",
                  label: "validation.attributes.p_s_password",
                  "div-class": "mt-1 relative rounded-md shadow-sm",
                  name: "p_s_password",
                  modelValue: _ctx.sessionForm.p_s_password,
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.sessionForm.p_s_password) = $event)),
                  required: true,
                  form: _ctx.sessionForm
                }, null, 8, ["modelValue", "form"])
              ])
            ]),
            _createElementVNode("div", _hoisted_38, [
              _createVNode(_component_Button, {
                text: _ctx.$trans('main.profile.sessions.title'),
                loading: _ctx.sessionForm.processing,
                cursorNotAllowed: !_ctx.sessionForm.isDirty,
                disabled: _ctx.sessionForm.invalid('p_s_password')
              }, null, 8, ["text", "loading", "cursorNotAllowed", "disabled"])
            ])
          ], 32)
        ])
      ])
    ]),
    _: 1
  }))
}