import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center items-center px-6 mx-auto h-screen xl:px-0 dark:bg-gray-900" }
const _hoisted_2 = { class: "text-center xl:max-w-4xl" }
const _hoisted_3 = { class: "mb-3 text-2xl font-bold leading-tight text-gray-900 sm:text-4xl lg:text-5xl dark:text-white" }
const _hoisted_4 = { class: "mb-5 text-base font-normal text-gray-500 md:text-lg dark:text-gray-400" }
const _hoisted_5 = { class: "inline-flex rounded-md shadow-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Head = _resolveComponent("Head")!
  const _component_Link = _resolveComponent("Link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Head, {
      title: _ctx.$trans(`error.${_ctx.status}.title`)
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "block md:max-w-lg" }, [
        _createElementVNode("img", {
          src: "/dist/images/error.svg",
          class: "filter-black dark:filter-white",
          alt: "Error illustration"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$trans(`error.${_ctx.status}.title`)), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$trans(`error.${_ctx.status}.description`)), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Link, {
            as: "button",
            href: _ctx.route('main'),
            class: "inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-transparent rounded-lg border border-gray-900 hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
          }, {
            default: _withCtx(() => [
              _cache[0] || (_cache[0] = _createElementVNode("svg", {
                class: "mr-2 w-4 h-4 fill-current",
                fill: "currentColor",
                viewBox: "0 0 20 20",
                xmlns: "http://www.w3.org/2000/svg"
              }, [
                _createElementVNode("path", {
                  "fill-rule": "evenodd",
                  d: "M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z",
                  "clip-rule": "evenodd"
                })
              ], -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.$trans("main.back")), 1)
            ]),
            _: 1
          }, 8, ["href"])
        ])
      ])
    ])
  ], 64))
}