<template>
  <li @click="open" class="dark:bg-gray-950 text-gray-300 border-gray-300 dark:border-gray-800 px-4 py-4 sm:px-6 lg:px-8 cursor-pointer items-center space-x-4 flex text-sm justify-between list-disc break-words text-left border-t">
    <div class="block text-sm text-gray-300 list-disc break-words text-left">
      <div class="text-gray-300 inline-block text-sm list-disc mb-1 break-words text-left">
        <h3 class="text-gray-300 block text-lg font-semibold list-disc m-0 text-left break-all">
          <div class="flex items-center gap-x-3">
              <div :class="{
                'text-green-500 bg-green-100 dark:bg-green-900': site.last_beat?.success === true,
                'text-red-500 bg-red-100 dark:bg-red-900': site.last_beat?.success === false,
              }" class="flex-none rounded-full p-1">
                <div class="h-2 w-2 rounded-full bg-current"/>
              </div>
            <h2 class="text-gray-800 dark:text-white min-w-0 text-sm font-semibold leading-6">
              <Link :href="route('sites.show', site.id)" class="flex gap-x-2">
                <span v-text="site.name" class="truncate"/>
                <span class="text-gray-500 dark:text-gray-400">/</span>
                <span v-text="site.tag" class="whitespace-nowrap"/>
              </Link>
            </h2>
          </div>
        </h3>
      </div>
      <div class="text-gray-600 dark:text-gray-400 mt-3 flex items-center gap-x-2.5 text-nano leading-5">
        <p v-text="$trans('main.sites.health_check')" class="truncate"/>
        <svg viewBox="0 0 2 2" class="fill-gray-500 dark:fill-gray-300 h-0.5 w-0.5 flex-none">
          <circle cx="1" cy="1" r="1"></circle>
        </svg>
        <span v-if="site.last_beat?.format_created_at" class="whitespace-nowrap">
          {{ site.last_beat?.format_created_at }}
        </span>
      </div>
    </div>
    <div class="flex flex-row items-center justify-around text-sm text-gray-300 ml-4 list-disc text-left">
      <LineChart :actions="site.last_beat?.actions ?? []"/>
      <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd"></path>
      </svg>
    </div>
  </li>
</template>
<script lang="ts">
import { default as SiteList } from '@/Types/Resources/Site/List';
import LineChart from '@js/Pages/Sites/Components/LineChart.vue';
import { Link, router } from '@inertiajs/vue3';
import { defineComponent } from "vue";
import route from '@/ziggy';

export default defineComponent({
  components: {
    LineChart,
    Link
  },
  props: {
    site: {
      type: Object as () => SiteList,
      required: true,
    },
  },
  setup (props) {
    function open(): void
    {
      router.get(route('sites.show', props.site.id));
    }

    return {
      route,
      open,
    };
  }
});
</script>