import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "absolute inset-0 overflow-hidden" }
const _hoisted_2 = { class: "pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10 sm:pr-16" }
const _hoisted_3 = { class: "pointer-events-auto w-screen max-w-2xl" }
const _hoisted_4 = { class: "flex h-full flex-col bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-200 shadow-xl" }
const _hoisted_5 = { class: "flex-1 overflow-y-auto mb-8" }
const _hoisted_6 = { class: "bg-gray-50 dark:bg-gray-850 px-4 py-6 sm:px-6" }
const _hoisted_7 = { class: "flex justify-between items-start" }
const _hoisted_8 = { class: "space-y-6 px-4 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 dark:sm:divide-gray-700" }
const _hoisted_9 = { class: "max-h-[calc(100vh-200px)]" }
const _hoisted_10 = { class: "grid-cols-2" }
const _hoisted_11 = { class: "mt-5 md:mt-0 md:col-span-2" }
const _hoisted_12 = { class: "p-4" }
const _hoisted_13 = { class: "space-y-4 text-sm" }
const _hoisted_14 = { class: "flex justify-between" }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = { class: "break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300" }
const _hoisted_17 = { class: "flex justify-between" }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300" }
const _hoisted_20 = { class: "flex justify-between" }
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = { class: "break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300" }
const _hoisted_23 = { class: "flex justify-between" }
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = { class: "break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300" }
const _hoisted_26 = { class: "flex justify-between" }
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = { class: "break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300" }
const _hoisted_29 = { class: "flex justify-between items-center" }
const _hoisted_30 = ["innerHTML"]
const _hoisted_31 = { class: "flex justify-between" }
const _hoisted_32 = ["innerHTML"]
const _hoisted_33 = { class: "break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300" }
const _hoisted_34 = { class: "flex items-start justify-between" }
const _hoisted_35 = ["innerHTML"]
const _hoisted_36 = { class: "break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300" }
const _hoisted_37 = { class: "flex items-start justify-between" }
const _hoisted_38 = ["innerHTML"]
const _hoisted_39 = { class: "break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300" }
const _hoisted_40 = { class: "flex justify-between" }
const _hoisted_41 = ["innerHTML"]
const _hoisted_42 = { class: "break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300" }
const _hoisted_43 = { class: "flex justify-between" }
const _hoisted_44 = ["innerHTML"]
const _hoisted_45 = { class: "break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300" }
const _hoisted_46 = { class: "flex-shrink-0 border-t border-gray-200 dark:border-gray-750 px-4 py-5 sm:px-6" }
const _hoisted_47 = { class: "flex justify-end space-x-3" }
const _hoisted_48 = { class: "flex justify-end space-x-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogOverlay = _resolveComponent("DialogOverlay")!
  const _component_DialogTitle = _resolveComponent("DialogTitle")!
  const _component_Cross = _resolveComponent("Cross")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createBlock(_component_TransitionRoot, {
    as: "template",
    show: _ctx.open
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Dialog, {
        as: "div",
        class: "z-50 fixed inset-0 overflow-hidden",
        onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.close()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_DialogOverlay, { class: "absolute inset-0 bg-black bg-opacity-50 dark:bg-opacity-50" }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_TransitionChild, {
                as: "template",
                enter: "transform transition ease-in-out duration-300 sm:duration-500",
                "enter-from": "-translate-x-full",
                "enter-to": "translate-x-0",
                leave: "transform transition ease-in-out duration-300 sm:duration-500",
                "leave-from": "translate-x-0",
                "leave-to": "-translate-x-full"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("div", null, [
                              _createVNode(_component_DialogTitle, { class: "text-lg font-medium text-gray-900 dark:text-white" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(`${_ctx.$trans('table.logs.show.title')} #${_ctx.log?.id}`), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _createVNode(_component_Cross, {
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
                            })
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("div", _hoisted_11, [
                                _createElementVNode("div", _hoisted_12, [
                                  _createElementVNode("dl", _hoisted_13, [
                                    _createElementVNode("div", _hoisted_14, [
                                      _createElementVNode("dt", {
                                        class: "font-semibold text-gray-800 dark:text-gray-100",
                                        innerHTML: _ctx.$trans('table.logs.show.id')
                                      }, null, 8, _hoisted_15),
                                      _createElementVNode("dd", _hoisted_16, _toDisplayString(_ctx.log?.id ? _ctx.log.id : _ctx.$trans('table.shared.no_data')), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_17, [
                                      _createElementVNode("dt", {
                                        class: "font-semibold text-gray-800 dark:text-gray-100",
                                        innerHTML: _ctx.$trans('table.logs.show.user_id')
                                      }, null, 8, _hoisted_18),
                                      _createElementVNode("dd", _hoisted_19, _toDisplayString(_ctx.log?.user_id ? _ctx.log.user_id : _ctx.$trans('table.shared.no_data')), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_20, [
                                      _createElementVNode("dt", {
                                        class: "font-semibold text-gray-800 dark:text-gray-100",
                                        innerHTML: _ctx.$trans('table.logs.show.function')
                                      }, null, 8, _hoisted_21),
                                      _createElementVNode("dd", _hoisted_22, _toDisplayString(_ctx.log?.function ? _ctx.getShortenedFunction(_ctx.log.function) : _ctx.$trans('table.shared.no_data')), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_23, [
                                      _createElementVNode("dt", {
                                        class: "font-semibold text-gray-800 dark:text-gray-100",
                                        innerHTML: _ctx.$trans('table.logs.show.ip')
                                      }, null, 8, _hoisted_24),
                                      _createElementVNode("dd", _hoisted_25, _toDisplayString(_ctx.log?.ip ? _ctx.log.ip : _ctx.$trans('table.shared.no_data')), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_26, [
                                      _createElementVNode("dt", {
                                        class: "font-semibold text-gray-800 dark:text-gray-100",
                                        innerHTML: _ctx.$trans('table.logs.show.type')
                                      }, null, 8, _hoisted_27),
                                      _createElementVNode("dd", _hoisted_28, _toDisplayString(_ctx.log?.type ? _ctx.log.type : _ctx.$trans('table.shared.no_data')), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_29, [
                                      _createElementVNode("dt", {
                                        class: "font-semibold text-gray-800 dark:text-gray-100",
                                        innerHTML: _ctx.$trans('table.logs.show.status')
                                      }, null, 8, _hoisted_30),
                                      _createVNode(_component_Badge, {
                                        type: _ctx.log?.status,
                                        text: _ctx.log?.status ? _ctx.log.status : _ctx.$trans('table.shared.no_data')
                                      }, null, 8, ["type", "text"])
                                    ]),
                                    _createElementVNode("div", _hoisted_31, [
                                      _createElementVNode("dt", {
                                        class: "font-semibold text-gray-800 dark:text-gray-100",
                                        innerHTML: _ctx.$trans('table.logs.show.url')
                                      }, null, 8, _hoisted_32),
                                      _createElementVNode("dd", _hoisted_33, _toDisplayString(_ctx.log?.url ? _ctx.log.url : _ctx.$trans('table.shared.no_data')), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_34, [
                                      _createElementVNode("dt", {
                                        class: "font-semibold text-gray-800 dark:text-gray-100",
                                        innerHTML: _ctx.$trans('table.logs.show.data')
                                      }, null, 8, _hoisted_35),
                                      _createElementVNode("dd", _hoisted_36, _toDisplayString(_ctx.log?.encoded_data ? _ctx.log.encoded_data : _ctx.$trans('table.shared.no_data')), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_37, [
                                      _createElementVNode("dt", {
                                        class: "font-semibold text-gray-800 dark:text-gray-100",
                                        innerHTML: _ctx.$trans('table.logs.show.info')
                                      }, null, 8, _hoisted_38),
                                      _createElementVNode("dd", _hoisted_39, _toDisplayString(_ctx.log?.info ? _ctx.log.info : _ctx.$trans('table.shared.no_data')), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_40, [
                                      _createElementVNode("dt", {
                                        class: "font-semibold text-gray-800 dark:text-gray-100",
                                        innerHTML: _ctx.$trans('table.logs.show.created_at')
                                      }, null, 8, _hoisted_41),
                                      _createElementVNode("dd", _hoisted_42, _toDisplayString(_ctx.log?.created_at ? _ctx.log.created_at : _ctx.$trans('table.shared.no_data')), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_43, [
                                      _createElementVNode("dt", {
                                        class: "font-semibold text-gray-800 dark:text-gray-100",
                                        innerHTML: _ctx.$trans('table.logs.show.updated_at')
                                      }, null, 8, _hoisted_44),
                                      _createElementVNode("dd", _hoisted_45, _toDisplayString(_ctx.log?.updated_at ? _ctx.log.updated_at : _ctx.$trans('table.shared.no_data')), 1)
                                    ])
                                  ])
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_46, [
                        _createElementVNode("div", _hoisted_47, [
                          _createElementVNode("div", _hoisted_48, [
                            _createElementVNode("button", {
                              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
                              class: "bg-gray-900 dark:bg-primary-800 hover:bg-gray-700 dark:hover:bg-primary-700 text-white dark:text-gray-100 focus:ring-gray-700 dark:focus:ring-primary-950 focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            }, _toDisplayString(_ctx.$trans('table.shared.close')), 1)
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show"]))
}