import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex content-center flex-row items-center justify-between py-2 px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogOverlay = _resolveComponent("DialogOverlay")!
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_CommandLineIcon = _resolveComponent("CommandLineIcon")!
  const _component_ComboboxInput = _resolveComponent("ComboboxInput")!
  const _component_Combobox = _resolveComponent("Combobox")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createBlock(_component_TransitionRoot, {
    show: _ctx.open,
    as: "template",
    onAfterLeave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.query = ''))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Dialog, {
        open: _ctx.open,
        as: "div",
        class: "fixed inset-0 z-50 overflow-y-auto p-4 sm:p-6 md:p-20",
        onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setOpen(false)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_TransitionChild, {
            as: "template",
            enter: "ease-out duration-300",
            "enter-from": "opacity-0",
            "enter-to": "opacity-100",
            leave: "ease-in duration-200",
            "leave-from": "opacity-100",
            "leave-to": "opacity-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DialogOverlay, { class: "bg-gray-900/50 dark:bg-gray-900/90 fixed inset-0 transition-opacity" })
            ]),
            _: 1
          }),
          _createVNode(_component_TransitionChild, {
            as: "template",
            enter: "ease-out duration-300",
            "enter-from": "opacity-0 scale-95",
            "enter-to": "opacity-100 scale-100",
            leave: "ease-in duration-200",
            "leave-from": "opacity-100 scale-100",
            "leave-to": "opacity-0 scale-95"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Combobox, {
                as: "div",
                displayValue: (item) => item.value,
                class: "mx-auto max-w-2xl transform divide-y divide-gray-300 dark:divide-gray-750 divide-opacity-10 overflow-hidden rounded-xl bg-white dark:bg-gray-850 bg-opacity-80 shadow-2xl ring-1 ring-black ring-opacity-5 backdrop-blur backdrop-filter transition-all"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_CommandLineIcon, { class: "pointer-events-none top-4 left-4 h-5 w-5 text-gray-900 dark:text-gray-500 text-opacity-40" }),
                    _createVNode(_component_ComboboxInput, {
                      placeholder: _ctx.$trans('cmd.description'),
                      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.query = $event.target.value)),
                      onKeydown: _ctx.handleKeyDown,
                      class: "w-full border-0 bg-transparent pr-4 text-gray-900 dark:text-white placeholder-gray-500 focus:ring-0 sm:text-sm"
                    }, null, 8, ["placeholder", "onKeydown"])
                  ])
                ]),
                _: 1
              }, 8, ["displayValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["open"])
    ]),
    _: 1
  }, 8, ["show"]))
}