import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full py-3.5 px-5 relative" }
const _hoisted_2 = { class: "flex-shrink-0 flex items-center max-h-12" }
const _hoisted_3 = { class: "ml-3 flex-1 flex items-center flex-col text-start" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "absolute top-3 right-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createBlock(_component_TransitionRoot, {
    appear: "",
    as: "div",
    show: (_ctx.show as boolean),
    class: "bg-white dark:bg-gray-800 select-none max-w-xs w-full shadow-lg rounded-2xl pointer-events-auto overflow-hidden relative",
    enter: "transform ease-out duration-300 transition",
    "enter-from": "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2",
    "enter-to": "translate-y-0 opacity-100 sm:translate-x-0",
    leave: "transform ease-out duration-300 transition",
    "leave-from": "translate-y-0 opacity-100 sm:translate-x-0",
    "leave-to": "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.notification.url ? 'Link' : 'div'), {
        key: _ctx.notification.id,
        href: _ctx.notification.url,
        class: _normalizeClass([_ctx.notification.url != null ? 'cursor-pointer' : 'cursor-default', "z-10 pointer-events-auto w-full max-w-md rounded-2xl relative"])
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass(["flex items-start", [_ctx.notification.footer != null ? 'items-start' : 'items-center']])
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_UserAvatar, {
                  user: _ctx.notification.initiator,
                  size: "md",
                  "with-tooltip": false
                }, null, 8, ["user"])
              ]),
              _createElementVNode("div", _hoisted_3, [
                (_ctx.notification.header != null)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 0,
                      innerHTML: _ctx.notification.header,
                      class: "leading-4 text-start text-small font-nunito text-gray-900 dark:text-gray-200"
                    }, null, 8, _hoisted_4))
                  : _createCommentVNode("", true),
                (_ctx.notification.footer != null)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 1,
                      innerHTML: _ctx.notification.footer,
                      class: "text-petite w-full text-balance leading-4 font-nunito font-light text-gray-500 dark:text-gray-400"
                    }, null, 8, _hoisted_5))
                  : _createCommentVNode("", true)
              ])
            ], 2),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.show = false), ["prevent"])),
                type: "button",
                class: "inline-flex rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-gray-900"
              }, [
                _cache[1] || (_cache[1] = _createElementVNode("span", { class: "sr-only" }, "Close", -1)),
                _createVNode(_component_XMarkIcon, {
                  class: "h-5 w-5",
                  "aria-hidden": "true"
                })
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["href", "class"]))
    ]),
    _: 1
  }, 8, ["show"]))
}