import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-gray-950 dark:text-white text-lg/7 font-semibold tracking-[-0.015em] sm:text-base/7" }
const _hoisted_2 = {
  "data-slot": "text",
  class: "text-gray-500 dark:text-gray-400 mt-2 text-sm sm:text-sm"
}
const _hoisted_3 = { class: "mt-4 flex max-w-md items-center gap-3 whitespace-nowrap" }
const _hoisted_4 = ["value", "id"]
const _hoisted_5 = { class: "mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckBadgeIcon = _resolveComponent("CheckBadgeIcon")!
  const _component_ButtonSecondary = _resolveComponent("ButtonSecondary")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$trans('main.boards.titles.share_board')), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$trans('main.boards.descriptions.share_board')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("input", {
        class: "bg-gray-50 dark:bg-gray-850 dark:focus:ring-primary-500 dark:focus:border-primary-500 border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700 block w-full focus:outline-none sm:text-sm rounded-md",
        value: _ctx.hashLink,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectInput && _ctx.selectInput(...args))),
        id: _ctx.inputIdName,
        type: "text",
        readonly: ""
      }, null, 8, _hoisted_4),
      _createVNode(_component_ButtonSecondary, {
        loading: _ctx.copyLoading,
        onClick: _ctx.copyToClipboard,
        size: "lg",
        type: "button",
        text: _ctx.$trans('main.boards.buttons.copy_button')
      }, {
        default: _withCtx(() => [
          (_ctx.copyComplete)
            ? (_openBlock(), _createBlock(_component_CheckBadgeIcon, {
                key: 0,
                class: "inline text-green-500 dark:text-green-400 h-5 w-5 mr-1.5"
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["loading", "onClick", "text"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.regenerateInviteLink && _ctx.regenerateInviteLink(...args))),
        type: "button",
        class: "text-gray-950 dark:text-white hover:text-gray-700 dark:hover:text-gray-300 text-base font-medium sm:text-sm"
      }, _toDisplayString(_ctx.$trans('main.boards.buttons.reset_invite_button')), 1)
    ])
  ], 64))
}