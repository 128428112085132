import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-4" }
const _hoisted_2 = { class: "flex items-start" }
const _hoisted_3 = {
  key: 0,
  class: "flex-shrink-0"
}
const _hoisted_4 = { class: "ml-3 w-0 flex-1 pt-0.5" }
const _hoisted_5 = { class: "text-sm font-medium text-gray-900 dark:text-white" }
const _hoisted_6 = { class: "mt-1 text-sm text-gray-500" }
const _hoisted_7 = { class: "ml-4 flex-shrink-0 flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createBlock(_component_TransitionRoot, {
    appear: "",
    as: "div",
    class: _normalizeClass(["select-none max-w-sm w-full bg-gray-50 text-gray-500 dark:text-gray-400 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden", {'dark:bg-gray-800': !_ctx.loggedIn, 'dark:bg-gray-950': _ctx.loggedIn}]),
    show: (_ctx.show as boolean),
    onMouseenter: _ctx.onMouseEnter,
    onMouseleave: _ctx.onMouseLeave,
    enter: "transform ease-out duration-300 transition",
    "enter-from": "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2",
    "enter-to": "translate-y-0 opacity-100 sm:translate-x-0",
    leave: "transform ease-out duration-300 transition",
    "leave-from": "translate-y-0 opacity-100 sm:translate-x-0",
    "leave-to": "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.type)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.type === 'success')
                  ? (_openBlock(), _createBlock(_component_Icon, {
                      key: 0,
                      name: "toast-success",
                      mainClass: "h-6 w-6 text-green-400"
                    }))
                  : (_ctx.type === 'error')
                    ? (_openBlock(), _createBlock(_component_Icon, {
                        key: 1,
                        name: "toast-error",
                        mainClass: "h-6 w-6 text-red-400"
                      }))
                    : (_ctx.type === 'warning')
                      ? (_openBlock(), _createBlock(_component_Icon, {
                          key: 2,
                          name: "toast-warning",
                          mainClass: "h-6 w-6 text-yellow-400"
                        }))
                      : (_ctx.type === 'info')
                        ? (_openBlock(), _createBlock(_component_Icon, {
                            key: 3,
                            name: "toast-info",
                            mainClass: "h-6 w-6 text-blue-400"
                          }))
                        : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.title), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.description), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = !_ctx.show)),
              class: _normalizeClass([{'dark:bg-gray-800': !_ctx.loggedIn, 'dark:bg-gray-950': _ctx.loggedIn}, "ml-auto -mx-1.5 -my-1.5 p-1.5 bg-gray-50 text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-primary-300 dark:focus:ring-primary-950 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-gray-400"])
            }, [
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "sr-only" }, "Close", -1)),
              _createVNode(_component_Icon, {
                name: "close",
                mainClass: "h-5 w-5"
              })
            ], 2)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["class", "show", "onMouseenter", "onMouseleave"]))
}