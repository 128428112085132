<template>
  <div class="user-avatar-wrapper"
    :class="{'no-tooltip': !withTooltip}"
    :data-tooltip="tooltipText">
    <div :class="[classes, sizeClass, 'user-avatar rounded-full overflow-hidden']">
      <img v-if="user?.photo_path"
        :src="user.photo_path"
        :alt="altText"
        class="w-full h-full object-cover"/>
      <div v-else
        :class="[
          `bg-${userColor}-${colorShade}`,
          'flex items-center justify-center w-full h-full'
        ]">
        <span :class="[fontSizeClass, 'font-nunito font-semibold text-white select-none']">
          {{ initials }}
        </span>
      </div>
    </div>
  </div>
</template>
<style scoped>
.user-avatar-wrapper {
  position: relative;
  display: inline-block;
}
.user-avatar-wrapper:not(.no-tooltip)::before {
  content: attr(data-tooltip);
  position: absolute;
  left: 50%;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
  z-index: 9999;
}
.user-avatar-wrapper:not(.no-tooltip)::after {
  content: "";
  position: absolute;
  left: 50%;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
  z-index: 10;
}
.user-avatar-wrapper:not(.no-tooltip)::before {
  bottom: calc(100% + 8px);
  transform: translateX(-50%);
  background-color: #1e293b;
  color: white;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 14px;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-avatar-wrapper:not(.no-tooltip)::after {
  bottom: calc(100% + 3px);
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: #1e293b;
}
.user-avatar-wrapper:not(.no-tooltip):hover::before,
.user-avatar-wrapper:not(.no-tooltip):hover::after {
  opacity: 1;
  visibility: visible;
}
.user-avatar-wrapper:not(.no-tooltip):hover::before {
  transform: translateX(-50%) translateY(-2px);
}
.user-avatar-wrapper:not(.no-tooltip):hover::after {
  transform: translateX(-50%) translateY(-2px) rotate(45deg);
}
</style>
<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';

interface User {
  id: number;
  name?: string;
  first_name?: string;
  last_name?: string;
  photo_path: string | null;
}

const colors = ['primary', 'blue', 'green', 'yellow', 'red', 'indigo', 'purple', 'pink'];
const shades = ['400', '500', '600', '700'];

const sizeClasses = {
  'xs': 'w-6 h-6',
  'sm': 'w-8 h-8',
  'md': 'w-10 h-10',
  'lg': 'w-12 h-12',
  'xl': 'w-16 h-16',
  '2xl': 'w-20 h-20',
  '3xl': 'w-24 h-24'
};

const fontSizeClasses = {
  'xs': 'text-micro',
  'sm': 'text-petite',
  'md': 'text-base',
  'lg': 'text-lg',
  'xl': 'text-xl',
  '2xl': 'text-2xl',
  '3xl': 'text-4xl'
};

export default defineComponent({
  name: 'UserAvatar',
  props: {
    user: {
      type: Object as PropType<User | null>,
      required: true,
    },
    size: {
      type: String,
      default: 'md',
      validator: (value: string) => ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl'].includes(value),
    },
    classes: {
      type: String,
      default: '',
    },
    withTooltip: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const initials = computed(() => {
      if (!props.user) return '';
      const name = props.user.name || `${props.user.first_name || ''} ${props.user.last_name || ''}`.trim();
      return name.split(' ').slice(0, 2).map(part => part[0]).join('').toUpperCase();
    });

    const altText = computed(() => 
      props.user ? `${props.user.name || `${props.user.first_name || ''} ${props.user.last_name || ''}`.trim()}'s avatar` : 'User avatar'
    );

    const tooltipText = computed(() => 
      props.withTooltip ? (props.user?.name || `${props.user?.first_name || ''} ${props.user?.last_name || ''}`.trim() || 'User') : undefined
    );

    const hashCode = (str: string) => str.split('').reduce((acc, char) => (char.charCodeAt(0) + ((acc << 5) - acc)) | 0, 0);

    const colorIndex = computed(() => props.user ? Math.abs(hashCode(props.user.id.toString())) : 0);
    const userColor = computed(() => colors[colorIndex.value % colors.length]);
    const colorShade = computed(() => shades[colorIndex.value % shades.length]);

    const sizeClass = computed(() => sizeClasses[props.size]);
    const fontSizeClass = computed(() => fontSizeClasses[props.size]);

    return {
      fontSizeClass,
      tooltipText,
      colorShade,
      userColor,
      sizeClass,
      initials,
      altText,
    };
  },
});
</script>