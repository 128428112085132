<template>
  <TransitionRoot
    appear
    as="div"
    class="select-none max-w-sm w-full bg-gray-50 text-gray-500 dark:text-gray-400 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
    :class="{'dark:bg-gray-800': !loggedIn, 'dark:bg-gray-950': loggedIn}" 
    :show="(show as boolean)"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    enter="transform ease-out duration-300 transition"
    enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    enter-to="translate-y-0 opacity-100 sm:translate-x-0"
    leave="transform ease-out duration-300 transition"
    leave-from="translate-y-0 opacity-100 sm:translate-x-0"
    leave-to="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2">
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0" v-if="type">
              <Icon v-if="type === 'success'" name="toast-success" mainClass="h-6 w-6 text-green-400"/>
              <Icon v-else-if="type === 'error'" name="toast-error" mainClass="h-6 w-6 text-red-400"/>
              <Icon v-else-if="type === 'warning'" name="toast-warning" mainClass="h-6 w-6 text-yellow-400"/>
              <Icon v-else-if="type === 'info'" name="toast-info" mainClass="h-6 w-6 text-blue-400"/>
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium text-gray-900 dark:text-white">
              {{ title }}
            </p>
            <p class="mt-1 text-sm text-gray-500">
              {{ description }}
            </p>
          </div>
          <div class="ml-4 flex-shrink-0 flex">
            <button @click="show = !show" :class="{'dark:bg-gray-800': !loggedIn, 'dark:bg-gray-950': loggedIn}" class="ml-auto -mx-1.5 -my-1.5 p-1.5 bg-gray-50 text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-primary-300 dark:focus:ring-primary-950 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-gray-400">
              <span class="sr-only">Close</span>
              <Icon name="close" mainClass="h-5 w-5"/>
            </button>
          </div>
        </div>
      </div>
  </TransitionRoot>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { TransitionRoot } from '@headlessui/vue'
import { usePage } from '@inertiajs/vue3';
import Icon from '@js/Shared/Icon.vue';

export default defineComponent({
  emits: ['hide'],
  components: {
    TransitionRoot,
    Icon,
  },
  props: {
    loggedIn: {
      type: Boolean,
      required: false,
      default: true
    },
    type: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
  },
  setup(props, { emit }){
    const show = ref<Boolean>(true);
    let timeoutId: NodeJS.Timeout | null = null;

    const onMouseEnter = () => {
      if(timeoutId){
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    };

    const onMouseLeave = () => {
      if(!timeoutId){
        timeoutId = setTimeout(() => {
          show.value = false;
        }, 2000);
      }
    };

    onMounted(() => {
      timeoutId = setTimeout(() => {
        show.value = false;
        emit('hide');
      }, 2000);
    });
    
    watch(show, (newVal) => {
      let props = usePage().props;
      
      if(!newVal && typeof props?.toast !== 'undefined'){
        props.toast = null;
      }
    });

    return {
      onMouseEnter,
      onMouseLeave,
      show,
    };
  }
});
</script>