<template>
  <Head v-if="card != null" :title="`${card?.title} | ${getAppName()}`"/>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto" @close="handleClose()">
      <div class="flex items-end sm:items-start justify-center w-screen h-screen text-center">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-black bg-opacity-50"/>
        </TransitionChild>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div :class="{' min-h-screen sm:min-h-[--card-modal-placeholder-height]': card === null}" class="bg-white dark:bg-gray-900 text-left relative my-auto inline-block text-base font-normal leading-5 sm:rounded-lg transform w-screen sm:max-w-[--card-modal-width] transition-all sm:my-8 sm:align-middle">
          <template v-if="card != null">
            <div class="flex items-center justify-between px-4 pt-4 pb-1.5 rounded-t md:px-6 md:pt-6">
              <div class="text-xl font-semibold text-gray-900 dark:text-white">
                <div class="text-gray-600 dark:text-gray-400 inline-flex items-center mb-2 text-md font-semibold text-center">
                  <svg class="w-5 h-5 mr-1 stroke-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M6 7.75H11.5M3 14.75H20.5M6 10.25H8.5M3 17.5V6.25C3 5.65326 3.23705 5.08097 3.65901 4.65901C4.08097 4.23705 4.65326 4 5.25 4H18.75C19.3467 4 19.919 4.23705 20.341 4.65901C20.7629 5.08097 21 5.65326 21 6.25V17.5C21 18.0967 20.7629 18.669 20.341 19.091C19.919 19.5129 19.3467 19.75 18.75 19.75H5.25C4.65326 19.75 4.08097 19.5129 3.65901 19.091C3.23705 18.669 3 18.0967 3 17.5Z"/>
                  </svg>
                  <Menu as="div" class="grid gap-x-1 text-start flex-[0_0_auto] grid-cols-[1fr_max-content] min-h-[--card-header-height] max-w-[--card-header-width] items-center relative">
                    {{ card?.title }}
                    <div class="flex-grow-0 flex-shrink-0 rounded-md text-gray-500">
                      <MenuButton class="lg:hidden">
                        <Cog8ToothIcon class="h-4 w-4"/>
                      </MenuButton>
                    </div>
                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                      <MenuItems class="bg-white dark:bg-gray-850 border-gray-200 dark:border-gray-750 divide-gray-100 dark:divide-gray-750 border origin-top-right absolute z-5 top-5 right-0 max-w-32 w-24 py-1 text-xs list-none rounded shadow-sm">
                        <ul role="none">
                          <li @click="checklistModalIsOpen = true" class="text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-white cursor-pointer select-none p-1">
                            {{$trans('main.boards.buttons.checklist')}}
                          </li>
                          <li @click="copyCard" class="text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-white cursor-pointer select-none p-1">
                            {{$trans('main.shared.buttons.copy')}}
                          </li>
                          <li @click="isDeleteCardModalVisible = true" class="text-red-700 dark:hover:text-white dark:hover:bg-red-800 hover:bg-red-100 cursor-pointer select-none p-1">
                            {{$trans('main.shared.buttons.delete')}}
                          </li>
                        </ul>
                      </MenuItems>
                    </transition>
                  </Menu>
                </div>
              </div>
              <button type="button" @click="handleClose()" class="text-gray-500 dark:text-gray-400 focus:ring-gray-200 dark:focus:ring-gray-700 hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none focus:ring-4 rounded-lg text-sm p-2">
                <XMarkIcon class="w-5 h-5 stroke-2"/>
              </button>
            </div>
            <!-- Modal body -->
            <div class="flex flex-col sm:flex-row justify-between">
              <div class="w-full max-w-[--card-content-width] ">
                <div class="px-4 md:px-6">
                  <div class="flex flex-col items-start justify-center mb-4 ml-6 space-y-3">
                    <div class="text-sm text-gray-500 dark:text-gray-400">
                      {{$trans('main.boards.added_by')}}
                      <a href="#" class="text-primary-700 no-underline cursor-pointer hover:underline dark:text-primary-500">
                      {{ `${propsCard?.creator_name}` }}
                      </a>, {{cardCreatedAtFormatted}}
                    </div>
                    <MembersMenu
                      :card="card"
                      :board="board"
                      @update-card="handleUpdateLightCard"/>
                    <TagsMenu
                      :colors="colors"
                      :board="board"
                      :card="card"
                      @update-board="handleUpdateBoard"
                      @update-card="handleUpdateLightCard"/>
                  </div>
                    <div class="text-gray-900 dark:text-gray-400 inline-flex items-center mb-2 text-md font-semibold text-center">
                      <Bars3CenterLeftIcon class="w-5 h-5 mr-1 stroke-2"/>
                      {{ $trans('main.boards.titles.description') }}
                    </div>
                    <div v-if="card?.description || descriptionIsOpenForm" class="text-black dark:text-white mb-4 ml-6 space-y-2 text-base">
                      <template v-if="!descriptionIsOpenForm">
                        <div @click="openDescriptionForm" v-html="shortDescription ? truncateText(card?.description ?? '', 200) : card?.description"></div>
                        <template v-if="(card?.description ?? '').length > 200">
                          <div v-if="shortDescription" @click="shortDescription = false" class="text-sm font-semibold text-primary-700 cursor-pointer w-max hover:underline dark:text-primary-500">
                            {{$trans('main.boards.buttons.show_all_description')}}
                          </div>
                          <div v-else @click="shortDescription = true" class="text-sm font-semibold text-primary-700 cursor-pointer w-max hover:underline dark:text-primary-500">
                            {{$trans('main.boards.buttons.show_less_description')}}
                          </div>
                        </template>
                      </template>
                      <template v-else>
                        <div class="flex flex-col">
                          <Editor v-if="user"
                            id="b_c_c_description" 
                            name="b_c_c_description"
                            :form="descriptionForm" 
                            @focusout="descriptionIsOpenForm = false"
                            :members="board.members"
                            :current-user-id="user.id"
                            ref="descriptionEditor"
                            v-model="descriptionForm.b_c_c_description"/>
                          <div class="flex flex-row mt-2">
                            <Button classes="mr-2" @click="submitDescription" size="sm" :text="$trans('main.shared.buttons.save')"/>
                            <Button color="gray" size="sm" :text="$trans('main.shared.buttons.hide')" @click="closeDescriptionForm()"/>
                          </div>
                        </div>
                      </template>
                    </div>
                    <template v-else>
                      <div v-if="!descriptionIsOpenForm" class="mb-4 ml-6">
                        <button @click="openDescriptionForm()" class="bg-white dark:bg-gray-700 text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 border-gray-200 dark:border-gray-600 border font-semibold rounded-lg text-nano px-2 py-1 text-center inline-flex items-center">
                          <PlusIcon class="w-4 h-4 stroke-2 mr-1"/>
                          {{ $trans('main.boards.buttons.add_description') }}
                        </button>
                      </div>
                    </template>
                  <CheckList
                    :board="board"
                    :card="card"
                    :open="checklistModalIsOpen"
                    @update-card="handleUpdateLightCard"
                    @update-actions="updateActions"
                    @close-checklist-modal="handleСloseChecklistModal"/>
                  <div class="clear-both mb-6 relative block">
                    <!-- Header -->
                    <div class="justify-between items-center flex min-h-8 m-0 mb-1 relative">
                      <h3 class="text-gray-900 dark:text-gray-400 inline-flex items-center mb-2 text-md font-semibold text-center">
                        <ListBulletIcon class="w-5 h-5 mr-1 stroke-2"/>
                        {{ $trans('main.boards.titles.history') }}
                      </h3>
                      <div class="float-right mr-0.5 block">
                        <ButtonSecondary :text="$trans(`main.shared.buttons.${card?.actions_visible ? 'hide' : 'show'}`)" @click="throttledToggleActions()" size="sm"/>
                      </div>
                    </div>
                    <div class="block relative mb-2">
                      <div class="flex items-start space-x-4">
                        <div class="min-w-0 flex-1">
                          <div class="flex space-x-3">
                            <div class="flex-shrink-0">
                              <div class="relative">
                                <UserAvatar v-if="user" :user="user" size="md" :withTooltip="false"/>
                                <span class="bg-white dark:bg-gray-900 absolute -bottom-0.5 -right-1 rounded-tl px-0.5 py-px">
                                  <ChatBubbleLeftEllipsisIcon class="h-3 w-3 sm:h-5 sm:w-5 text-gray-400"/>
                                </span>
                              </div>
                            </div>
                            <div class="min-w-0 flex-1">
                              <form @submit.prevent="submitNewComment">
                                <div>
                                  <Editor v-if="user"
                                    id="b_c_c_content" 
                                    name="b_c_c_content"
                                    :form="newCommentForm" 
                                    :members="board.members"
                                    :current-user-id="user.id"
                                    v-model="newCommentForm.b_c_c_content"/>
                                </div>
                                <div class="mt-2 flex items-center justify-start">
                                  <Button :text="$trans('main.boards.buttons.comment')"
                                    size="sm"
                                    rounded="md"
                                    :loading="newCommentForm.processing"
                                    :cursorNotAllowed="!newCommentForm.isDirty"
                                    :disabled="newCommentForm.invalid('b_c_c_content')"/>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- List -->
                    <div v-if="(card?.actions_visible ?? null) === true || actionsIsLoading" class="block">
                      <div v-if="actionsIsLoading" class="ml-12 min-h-8 py-0 relative block my-3">
                        <div role="status" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
                          <svg class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25"/>
                            <circle class="spinner_7WDj" cx="12" cy="2.5" r="1.5"/>
                          </svg>
                        </div>
                      </div>
                      <div v-else v-for="item in actionsAndComments ?? []" class="ml-12 min-h-8 py-0 relative block my-3">
                        <div v-if="item?.type === 'action'">
                          <div class="h-8 -left-10 absolute top-1 w-8 block">
                            <UserAvatar :user="item.user" size="sm" :withTooltip="false"/>
                          </div>
                          <div class="text-gray-600 dark:text-gray-400 m-0 break-words block text-sm">
                            <span v-html="item.content"></span>
                          </div>
                          <div class="text-gray-600 dark:text-gray-400 text-nano m-0 min-w-28">
                            {{ formatTime(item.created_at) }}
                          </div>
                        </div>
                        <div v-else-if="item?.type === 'comment'">
                          <div class="h-8 -left-10 absolute top-1 w-8 block">
                            <UserAvatar :user="item.creator" size="sm" :withTooltip="false"/>
                          </div>
                          <div class="m-0">
                            <span class="text-gray-600 dark:text-gray-400 text-sm font-bold cursor-pointer">
                              {{ item.creator.name }}
                            </span>
                            <span class="inline-block min-w-1"></span>
                            <template v-if="editCommentForm._comment_id != item.id">
                              <span class="text-gray-500 dark:text-gray-600 text-nano font-normal whitespace-pre leading-3 m-0 mb-1.5 min-w-24">
                                {{ formatTime(item.created_at) + `${item.is_changed ? ` (${$trans('main.boards.changed')})` : ''}` }}
                              </span>
                              <div>
                                <div class="bg-gray-100 dark:bg-gray-950 block mt-1 rounded-lg box-border clear-both max-w-full overflow-hidden overflow-ellipsis">
                                  <div v-html="item.content" class="text-gray-700 dark:text-gray-400 comment-content py-3 px-3 break-words text-sm block text-ellipsis overflow-hidden max-w-full font-normal leading-8 cursor-auto"></div>
                                </div>
                              </div>
                            </template>
                            <div v-else>
                              <form @submit.prevent="submitEditComment">
                                <div>
                                  <Editor
                                    id="edit-comment-area" 
                                    name="b_c_c_content"
                                    :form="editCommentForm" 
                                    :members="board.members"
                                    :current-user-id="user?.id ?? 0"
                                    v-model="editCommentForm.b_c_c_content"/>
                                </div>
                                <div class="mt-2 flex items-center justify-start">
                                  <Button :text="$trans('main.shared.buttons.update')"
                                    size="sm"
                                    rounded="md"
                                    :loading="editCommentForm.processing"
                                    :cursorNotAllowed="!editCommentForm.isDirty"
                                    :disabled="editCommentForm.invalid('b_c_c_content')"/>
                                  <Button type="button" color="gray" class="ml-2" @click="closeEditCommentForm" :text="$trans('main.shared.buttons.hide')" size="sm" rounded="md"/>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div class="items-center flex flex-wrap leading-6 mr-6" v-if="editCommentForm._comment_id != item.id">
                            <div class="text-nano m-0 min-w-28 block self-start">
                              <span class="text-gray-500 dark:text-gray-700 cursor-pointer text-mini">
                                <a type="button" @click="openEditCommentForm(item)" class="underline">
                                  {{ $trans('main.shared.buttons.edit') }}
                                </a>
                                • 
                                <a type="button" @click="openDeleteCommentModal(item)" class="underline">
                                  {{ $trans('main.shared.buttons.delete') }}
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="hidden lg:flex w-full sm:w-48 flex-col mb-4 sm:mb-0 px-4 sm:px-0 sm:pr-4 md:pr-6"> -->
              <div>
                <div class="sticky top-4 space-y-2 lg:flex w-full sm:w-48 flex-col mb-4 sm:mb-0 px-4 sm:px-0 sm:pr-4 md:pr-6">

                  <div class="text-gray-900 dark:text-gray-300 hidden sm:inline-flex mb-1 items-center text-tiny font-semibold text-start">
                    <span class="leading-normal whitespace-nowrap">
                      {{ $trans('main.boards.titles.add_to_card') }}
                    </span>
                  </div>
                  <ButtonSecondary size="xl" classes="items-center text-petite !py-1.5 !px-2 !font-medium flex justify-center sm:justify-start" @click="checklistModalIsOpen = true" :text="$trans('main.boards.buttons.checklist')">
                    <CheckCircleIcon class="w-4 h-4 mr-1 stroke-1"/>
                  </ButtonSecondary>
                  <div class="text-gray-900 dark:text-gray-300 hidden sm:inline-flex items-center mt-2 mb-1 text-tiny font-semibold text-start">
                    <span class="leading-normal whitespace-nowrap">
                      {{ $trans('main.boards.titles.actions') }}
                    </span>
                  </div>
                  <ButtonSecondary size="xl" classes="mt-1 sm:mt-0 items-center text-petite !py-1.5 !px-2 !font-medium flex justify-center sm:justify-start" @click="copyCard" :text="$trans('main.shared.buttons.copy')">
                    <DocumentDuplicateIcon class="w-4 h-4 mr-1 stroke-1"/>
                  </ButtonSecondary>
                  <ButtonSecondary size="xl" classes="mt-1 items-center text-petite !py-1.5 !px-2 !font-medium flex justify-center sm:justify-start" @click="isDeleteCardModalVisible = true" :text="$trans('main.shared.buttons.delete')">
                    <TrashIcon class="w-4 h-4 mr-1 stroke-1"/>
                  </ButtonSecondary>
                </div>
              </div>
            </div>
            <Attempt
              :open="isDeleteCardModalVisible" 
              :close="(value: boolean) => isDeleteCardModalVisible = value" 
              :is-api="true"
              routeName="api.v1.boards.cards.destroy"
              :send-callback="handleRemoveMicroCard"
              :delete-callback="handleRemoveMicroCard"
              :routeArgument="{ card: propsCard?.id ?? false }"
              :title="$trans('main.attempts.delete_board_card.title')" 
              :description="$trans('main.attempts.delete_board_card.description')" 
              :successButtonText="$trans('main.attempts.buttons.success_button')" 
              :cancelButtonText="$trans('main.attempts.buttons.cancel_button')"/>
            <Attempt
              :open="isDeleteCommentModalVisible" 
              :close="(value: boolean) => isDeleteCommentModalVisible = value" 
              :is-api="true"
              routeName="api.v1.comments.destroy"
              :send-callback="removeComment"
              :delete-callback="removeComment"
              :routeArgument="{ card: propsCard?.id ?? false, comment: commentToDelete?.id ?? false }"
              :title="$trans('main.attempts.delete_comment_card.title')" 
              :description="$trans('main.attempts.delete_comment_card.description')" 
              :successButtonText="$trans('main.attempts.buttons.success_button')" 
              :cancelButtonText="$trans('main.attempts.buttons.cancel_button')"/>
          </template>
          <template v-else>
            <div role="status" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
              <svg class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25"/>
                <circle class="spinner_7WDj" cx="12" cy="2.5" r="1.5"/>
              </svg>
            </div>
          </template>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<style scoped>
.fade-enter-active, .fade-leave-active { transition: max-height 0.5s ease; overflow: hidden; }
.comment-content p:empty::before { content: ''; display: inline-block; }
.comment-content { white-space: pre-wrap; word-wrap: break-word; }
.comment-content .editor-paragraph { margin: 0; padding: 0; }
.fade-enter-to, .fade-leave { max-height: 500px; }
.fade-enter, .fade-leave-to { max-height: 0; }
.comment-content { @apply w-max; }
</style>
<script lang="ts">
import { StarIcon, XMarkIcon, PlusIcon, ListBulletIcon, CheckCircleIcon, EllipsisHorizontalCircleIcon, DocumentDuplicateIcon, TrashIcon, FaceSmileIcon, Cog8ToothIcon } from '@heroicons/vue/24/outline';
import { defineComponent, toRef, computed, ref, watch, PropType, onMounted, onUnmounted, inject, shallowRef, defineAsyncComponent } from 'vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
const CheckList = defineAsyncComponent(() => import('@/Pages/Boards/Card/Components/CheckList.vue'));
import { useCommentsAndActions } from '@/Pages/Boards/Card/Composables/useCommentsAndActions';
import { truncateText, socketChannelNameBuilder, getAppName, sendRequest } from '@/mixins';
import { useCardDescription } from '@/Pages/Boards/Card/Composables/useCardDescription';
import { ChatBubbleLeftEllipsisIcon, UserCircleIcon } from '@heroicons/vue/20/solid';
import MembersMenu from '@/Pages/Boards/Card/Components/MembersMenu.vue';
import { default as UserHandle } from '@/Types/Resources/User/Handle';
import { default as LightCard } from '@/Types/Resources/Card/Light';
import { default as MicroCard } from '@/Types/Resources/Card/Micro';
import TagsMenu from '@/Pages/Boards/Card/Components/TagsMenu.vue';
import { default as Board } from '@/Types/Resources/Board/Base';
import { Bars3CenterLeftIcon } from '@heroicons/vue/20/solid';
import { Menu, MenuButton, MenuItems } from '@headlessui/vue';
import { PayloadResponse } from '@/Types/API/PayloadResponse';
import ButtonSecondary from '@/Shared/ButtonSecondary.vue';
import { Channel } from '../../../../socket/channels.js';
import { Head, usePage, router } from '@inertiajs/vue3';
import relativeTime from 'dayjs/plugin/relativeTime';
import Action from '@/Types/Resources/Action/Light';
import Editor from './Components/Editor/Editor.vue';
import UserAvatar from '@/Shared/UserAvatar.vue';
import TextArea from '@/Shared/TextArea.vue';
import Attempt from '@/Shared/Attempt.vue';
import { Socket } from 'socket.io-client';
import { useRelativeTime } from '@/utils';
import Button from '@/Shared/Button.vue';
import { Link } from '@inertiajs/vue3';
import { Page } from '@inertiajs/core';
import Auth from '@/Types/App/Auth';
import route from '@/ziggy';

import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';
import 'dayjs/locale/uk';


dayjs?.extend(relativeTime);

export default defineComponent({
  emits: [
    'remove-comment', 
    'update-board',
    'update-board-card', 
    'remove-board-card', 
    'copy-board-card', 
    'close', 
  ],
  props: {
    open: {
      type: Boolean,
      required: true
    },
    card: {
      type: [Object, null] as PropType<LightCard|null>,
      required: true
    },
    board: {
      type: Object as PropType<Board>,
      required: true
    },
    colors: {
      type: Array as () => Array<string>,
      required: true
    },
  },
  components: {
    EllipsisHorizontalCircleIcon,
    ChatBubbleLeftEllipsisIcon,
    DocumentDuplicateIcon,
    Bars3CenterLeftIcon,
    ButtonSecondary,
    TransitionChild,
    CheckCircleIcon,
    UserCircleIcon,
    ListboxOptions,
    ListBulletIcon,
    TransitionRoot,
    FaceSmileIcon,
    DialogOverlay,
    Cog8ToothIcon,
    ListboxButton,
    ListboxOption,
    ListboxLabel,
    MembersMenu,
    DialogTitle,
    UserAvatar,
    MenuButton,
    MenuItems,
    CheckList,
    XMarkIcon,
    TrashIcon,
    TextArea,
    StarIcon,
    TagsMenu,
    PlusIcon,
    Listbox,
    Attempt,
    Editor,
    Button,
    Dialog,
    Menu,
    Head,
    Link,
  },
  setup(props, { emit }) {
    const now = ref(new Date());
    const nowIntervalId = ref<number|null>(null);
    const updateNow = () => now.value = new Date();
    const shortDescription = ref<boolean>(true);
    const page: Page = usePage();
    const auth: Auth | undefined = page.props?.auth as Auth | undefined;
    const user: UserHandle | undefined = auth?.user as UserHandle | undefined;
    const isDeleteCardModalVisible = ref<boolean>(false);
    const descriptionEditor = ref<typeof Editor|null>(null);
    const checklistModalIsOpen = ref<boolean>(false);
    const lang = computed<string>(() => (usePage().props?.lang == 'ua' ? 'uk' : usePage().props?.lang) as string);
    const propsBoard = toRef(props, 'board');
    const propsCard = toRef(props, 'card');
    const card = shallowRef<LightCard|null>(null);
    const cardCreatedAtFormatted = computed(() => formatRelativeTime(card.value?.created_at ?? null, lang.value));
    const open = toRef(props, 'open');
    const socket = inject<Socket>('$socket');
    const socketChannel= ref<string|null>(null);

    const handleUpdateLightCard = (newCard: LightCard) => {
      card.value = newCard;
      emit('update-board-card', convertToMicroCard(newCard));
    };

    const handleRemoveMicroCard = () => {
      if(card.value) {
        emit('remove-board-card', convertToMicroCard(card.value));
      }

      handleClose();
    };

    const handleCopyMicroCard = (card: MicroCard) => {
      emit('copy-board-card', card);

      handleClose();
    };

    const handleUpdateBoard = (newBoard: Board) => {
      emit('update-board', newBoard);
    };

    const handleСloseChecklistModal = (value: boolean) => {
      checklistModalIsOpen.value = value;
    };
    
    const {
      formatRelativeTime
    } = useRelativeTime();

    const formatTime = (date: string | null): string | null => {
      return formatRelativeTime(date, lang.value).value;
    };

    const {
      descriptionIsOpenForm,
      resetDescriptionForm,
      closeDescriptionForm,
      openDescriptionForm,
      submitDescription,
      descriptionForm,
    } = useCardDescription(card, descriptionEditor, handleUpdateLightCard);

    const {
      isDeleteCommentModalVisible,
      throttledToggleActions,
      openDeleteCommentModal,
      closeEditCommentForm,
      openEditCommentForm,
      actionsAndComments,
      submitEditComment,
      actionsIsLoading,
      submitNewComment,
      editCommentForm,
      commentToDelete,
      newCommentForm,
      updateActions,
      removeComment,
      copyCard,
      actions,
    } = useCommentsAndActions(card, auth?.user?.id, handleUpdateLightCard, handleCopyMicroCard);

    watch(() => open.value, (newVal) => {
      if(!newVal){
        card.value = null;

        newCommentForm.b_c_c_content = '';
        newCommentForm.b_c_c_creator_id = auth?.user?.id ?? null;
        closeEditCommentForm();

        socket?.off(socketChannel.value ?? undefined);
        socketChannel.value = null;
      }
    }, {
      immediate: true
    });

    watch(() => propsCard.value, (newCard) => {
      card.value = newCard;

      if(card.value){
        resetDescriptionForm();
        
        socketChannel.value = socketChannelNameBuilder(Channel.BoardCardActionsUpdatedEvent, card.value?.id ?? "");
        socket?.on(socketChannel.value, (data: {actions: Array<Action>}) => {
          actions.value = data.actions;
        });

        if(card.value.actions_visible){
          sendRequest({
            name: 'api.v1.actions',
            params: {
              card_id: card.value?.id
            },
          }, (data: PayloadResponse<Array<Action>>) => {
            actions.value = data.payload;
          });
        }
      }
    }, {
      immediate: true
    });

    function handleClose(value: Boolean = false): void
    {
      emit('close', value);

      if(!value) card.value = null;

      clearBoardState();
    }

    function clearBoardState(): void
    {
      router.get(route('boards.show', {
        board: propsBoard.value?.id
      }), undefined, {
        preserveState: true,
        except: ['card', 'board']
      });
    }

    function convertToMicroCard(card: LightCard): MicroCard
    {
      return {
        id: card.id,
        column_id: card.column_id,
        title: card.title,
        exists_description: card.exists_description,
        position: card.position,
        number: card.number,
        members: card.members,
        tags: card.tags,
      } as MicroCard;
    }

    onMounted(() => {
      nowIntervalId.value = window.setInterval(() => updateNow(), 60000);
    });

    onUnmounted(() => {
      if(nowIntervalId.value !== null) window.clearInterval(nowIntervalId.value);
    });

    return {
      isDeleteCommentModalVisible,
      handleСloseChecklistModal,
      isDeleteCardModalVisible,
      cardCreatedAtFormatted,
      openDeleteCommentModal,
      throttledToggleActions,
      descriptionIsOpenForm,
      handleUpdateLightCard,
      handleRemoveMicroCard,
      checklistModalIsOpen,
      closeEditCommentForm,
      closeDescriptionForm,
      openEditCommentForm,
      openDescriptionForm,
      actionsAndComments,
      handleUpdateBoard,
      submitDescription,
      submitEditComment,
      descriptionEditor,
      shortDescription,
      submitNewComment,
      actionsIsLoading,
      descriptionForm,
      commentToDelete,
      editCommentForm,
      newCommentForm,
      updateActions,
      removeComment,
      truncateText,
      handleClose,
      formatTime,
      propsBoard,
      getAppName,
      propsCard,
      copyCard,
      route,
      user,
      open,
      card,
    }
  },
});
</script>