import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Link = _resolveComponent("Link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.item.icon)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: `${_ctx.item.link as string}-head-item`,
          class: "icon-container min-w-6 min-h-6",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigateToLink && _ctx.navigateToLink(...args)))
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createVNode(_component_Link, {
      class: _normalizeClass(["text-sm transition-all font-medium", [_ctx.item.current ? 'text-primary-600 dark:text-primary-500' : _ctx.itemColor]]),
      href: _ctx.route(_ctx.item.link_name != undefined ? _ctx.item.link_name : _ctx.item.link, _ctx.item.link_params ? _ctx.item.link_params : {})
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$trans(_ctx.item.name)), 1)
      ]),
      _: 1
    }, 8, ["href", "class"])
  ], 64))
}