import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "md:hidden fixed bottom-0 z-35 w-full bg-white dark:bg-gray-900 border-t border-slate-100 dark:border-gray-800" }
const _hoisted_2 = { class: "flex items-center justify-around h-[--footer-height]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Link = _resolveComponent("Link")!

  return (_openBlock(), _createElementBlock("footer", null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "h-[--footer-height] md:hidden" }, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (item) => {
          return (_openBlock(), _createBlock(_component_Link, {
            href: _ctx.route(item.link_name != undefined ? item.link_name : item.link, item.link_params ? item.link_params : {}),
            class: _normalizeClass(["dark:text-gray-100 hover:text-slate-900 flex items-center justify-center w-1/5 h-[--footer-height]", {'text-slate-900 dark:text-gray-100': item.current, 
                  'text-slate-500 dark:text-gray-500': !item.current}])
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), { class: "w-6 h-6" }))
            ]),
            _: 2
          }, 1032, ["href", "class"]))
        }), 256))
      ])
    ])
  ]))
}