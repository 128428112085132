<template>
  <div :class="[mainClasses, 'relative flex flex-col items-center group/tooltip']">
    <slot/>
    <div v-if="text" :class="[blockClasses, 'absolute bottom-0 flex-col items-center hidden mb-6 group-hover/tooltip:flex']">
      <span :class="[textClasses, 'bg-white dark:bg-gray-800 text-gray-900 dark:text-white whitespace-normal rounded-lg relative z-10 p-2 text-sm font-medium leading-none whitespace-no-wrap shadow-lg']">
        {{ text }}
      </span>
      <div class="w-3 h-3 -mt-2 rotate-45 bg-white dark:bg-gray-800 z-10 shadow-lg rounded-[3px]"></div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    text: {
      type: [String, null] as PropType<string | null>,
      required: true,
    },
    mainClasses: {
      type: String,
      default: '',
    },
    blockClasses: {
      type: String,
      default: '',
    },
    textClasses: {
      type: String,
      default: '',
    },
  }
});
</script>
