import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "groupRef",
  class: "opacity-0"
}
const _hoisted_2 = ["cx", "cy", "filter"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("g", _hoisted_1, [
    _createElementVNode("circle", {
      ref: "circleRef",
      cx: _ctx.cx,
      cy: _ctx.cy,
      r: "1",
      style: _normalizeStyle(_ctx.circleStyle),
      filter: _ctx.filter
    }, null, 12, _hoisted_2)
  ], 512))
}