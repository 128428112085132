import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "mt-1 relative rounded-md shadow-sm" }
const _hoisted_3 = ["id", "type", "disabled", "name", "autocomplete", "required"]
const _hoisted_4 = {
  key: 0,
  class: "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
}
const _hoisted_5 = {
  key: 0,
  class: "mt-2 text-sm text-red-600 dark:text-red-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: "block text-sm font-medium text-gray-700 dark:text-white"
    }, _toDisplayString(_ctx.$trans(_ctx.label)), 9, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        id: _ctx.id,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.timeoutValidate && _ctx.timeoutValidate(...args))),
        type: _ctx.type,
        disabled: _ctx.disabled,
        name: _ctx.name,
        autocomplete: _ctx.autocomplete,
        required: _ctx.required,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
        class: _normalizeClass([{
      'border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700': !_ctx.form.invalid(_ctx.name),
      'border-red-300 dark:border-red-500 text-red-700 dark:text-red-500 focus:ring-red-500 focus:border-red-500 pr-10': _ctx.form.invalid(_ctx.name),
    }, "bg-gray-50 sm:bg-transparent dark:bg-gray-850 dark:focus:ring-primary-500 dark:focus:border-primary-500 sm:backdrop-blur-sm sm:backdrop-filter block w-full focus:outline-none sm:text-sm rounded-md"])
      }, null, 42, _hoisted_3), [
        [_vModelDynamic, _ctx.value]
      ]),
      (_ctx.form.invalid(_ctx.name))
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[2] || (_cache[2] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              fill: "none",
              viewBox: "0 0 24 24",
              "stroke-width": "1.5",
              stroke: "currentColor",
              class: "h-5 w-5 text-red-500"
            }, [
              _createElementVNode("path", {
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                d: "M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
              })
            ], -1)
          ])))
        : _createCommentVNode("", true)
    ]),
    (_ctx.withError && _ctx.form.invalid(_ctx.name))
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.form.errors[_ctx.name]), 1))
      : _createCommentVNode("", true)
  ], 64))
}