<template>
  <Link as="a" :href="route('login')" class="mx-auto mb-8 w-auto text-slate-900 dark:text-white">
    <img src="/dist/images/logo-text.svg" class="h-8 dark:filter-white" alt="Atlant Logo" />
  </Link>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { Link } from '@inertiajs/vue3';
import route from '@/ziggy';

export default defineComponent({
  components: {
    Link
  },
  setup() {
    return { route };
  }
});
</script>