import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, Transition as _Transition, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = ["content"]
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "bg-white dark:bg-gray-950 border-gray-200 dark:border-gray-800 border-b fixed md:hidden z-40 w-full" }
const _hoisted_4 = { class: "h-16 sm:h-[--header-height] w-full flex items-center gap-4 p-3 max-w-none mx-auto" }
const _hoisted_5 = {
  class: "flex-1 select-none relative hover:opacity-80 transition-all",
  style: {"width":"28px","height":"28px"}
}
const _hoisted_6 = {
  class: "",
  href: "/"
}
const _hoisted_7 = {
  class: "relative",
  style: {"width":"28px","height":"28px"}
}
const _hoisted_8 = {
  class: "",
  style: {"width":"28px","height":"28px"}
}
const _hoisted_9 = {
  class: "dark:filter-white",
  style: {"vertical-align":"top","width":"28px","height":"28px"},
  width: "128",
  height: "151",
  viewBox: "0 0 128 151",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_10 = { class: "flex-1 flex justify-end items-center" }
const _hoisted_11 = { class: "flex flex-1 justify-end" }
const _hoisted_12 = {
  key: 0,
  class: "inline-flex absolute bottom-10 justify-center items-center w-6 h-6 text-nano font-bold text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900"
}
const _hoisted_13 = { class: "ml-3 hidden text-sm font-medium text-gray-700 lg:block" }
const _hoisted_14 = { role: "none" }
const _hoisted_15 = { class: "truncate select-none py-2 px-3 text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-white" }
const _hoisted_16 = { class: "truncate select-none flex border-t border-gray-100 dark:border-gray-750 py-2 px-3 text-red-700 dark:hover:text-white dark:hover:bg-red-800 hover:bg-red-100" }
const _hoisted_17 = { class: "relative whitespace-nowrap" }
const _hoisted_18 = {
  key: 0,
  class: "absolute top-0 right-0 w-1.5 h-1.5 bg-red-700 dark:text-red-600 rounded-full mt-1 -mr-1"
}
const _hoisted_19 = { class: "bg-transparent hidden md:block md:fixed z-40 w-full backdrop-blur-3xl backdrop-filter" }
const _hoisted_20 = { class: "flex flex-1 items-center gap-2 py-2.5 mx-4 justify-between" }
const _hoisted_21 = { class: "flex items-center overflow-x-auto" }
const _hoisted_22 = { class: "relative p-2" }
const _hoisted_23 = { class: "flex gap-4 ml-4 items-center" }
const _hoisted_24 = { class: "flex items-center justify-end gap-2" }
const _hoisted_25 = {
  key: 0,
  class: "inline-flex absolute bottom-6 ml-4 justify-center items-center min-w-5 h-5 text-nano font-bold text-white bg-red-500 rounded-full"
}
const _hoisted_26 = { class: "p-0.5" }
const _hoisted_27 = { class: "absolute inset-0 overflow-hidden motion-safe:transition-all motion-safe:duration-300 motion-safe:ease-in-out" }
const _hoisted_28 = { class: "pointer-events-none fixed top-12 sm:top-20 right-4 flex max-w-[90vw]" }
const _hoisted_29 = { class: "pointer-events-auto w-screen max-w-sm" }
const _hoisted_30 = { class: "sm:flex sm:items-start rounded-b-lg" }
const _hoisted_31 = { class: "text-left w-full max-w-[90vw] px-1" }
const _hoisted_32 = { class: "bg-white dark:bg-gray-900 mt-4 max-h-[60vh] overflow-y-auto no-scrollbar rounded-lg" }
const _hoisted_33 = { class: "pb-[--user-notification-dropdown-footer-height] rounded-t-lg rounded-b-lg" }
const _hoisted_34 = { class: "flex-shrink-0" }
const _hoisted_35 = { class: "w-full ps-3" }
const _hoisted_36 = { class: "text-gray-500 text-sm mb-1.5 dark:text-gray-400 flex flex-row" }
const _hoisted_37 = { class: "flex flex-1" }
const _hoisted_38 = ["innerHTML"]
const _hoisted_39 = {
  key: 0,
  class: "flex flex-1 h-2 w-2 rounded-xl max-w-2 bg-primary-700 dark:bg-primary-600"
}
const _hoisted_40 = { class: "text-gray-600 dark:text-gray-500 text-xs flex flex-row" }
const _hoisted_41 = { class: "flex flex-1 flex-row" }
const _hoisted_42 = {
  key: 0,
  class: "bg-gray-100 dark:bg-gray-850 text-gray-900 dark:text-gray-100 text-small flex flex-row p-2 rounded-md mt-1"
}
const _hoisted_43 = ["innerHTML"]
const _hoisted_44 = {
  key: 1,
  class: "mx-auto mt-7 max-w-52"
}
const _hoisted_45 = { class: "mb-6 -space-y-6 px-2 min-h-28" }
const _hoisted_46 = { class: "pb-10 text-center" }
const _hoisted_47 = { class: "text-gray-900 dark:text-white font-semibold" }
const _hoisted_48 = { class: "text-gray-500 dark:text-gray-400 mt-1 text-xs font-regular" }
const _hoisted_49 = {
  key: 1,
  class: "min-h-28"
}
const _hoisted_50 = { class: "items-center flex flex-row space-x-2 justify-between absolute bottom-0 left-0 right-0 bg-gray-200/60 dark:bg-gray-800/70 backdrop-blur-md h-[--user-notification-dropdown-footer-height] rounded-b-lg px-4 py-3" }
const _hoisted_51 = { class: "truncate" }
const _hoisted_52 = { class: "flex min-w-0 flex-1 flex-col" }
const _hoisted_53 = { class: "text-gray-700 dark:text-white truncate text-sm font-medium" }
const _hoisted_54 = { class: "truncate text-sm text-gray-600 dark:text-gray-300" }
const _hoisted_55 = { role: "none" }
const _hoisted_56 = { class: "select-none py-2 px-3 text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-white" }
const _hoisted_57 = { class: "select-none flex border-t border-gray-100 dark:border-gray-750 py-2 px-3 text-red-700 dark:hover:text-white dark:hover:bg-red-800 hover:bg-red-100" }
const _hoisted_58 = { class: "relative whitespace-nowrap" }
const _hoisted_59 = {
  key: 0,
  class: "absolute top-0 right-0 w-1.5 h-1.5 bg-red-700 dark:text-red-600 rounded-full mt-1 -mr-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Head = _resolveComponent("Head")!
  const _component_BellIcon = _resolveComponent("BellIcon")!
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_Link = _resolveComponent("Link")!
  const _component_SwitchLabel = _resolveComponent("SwitchLabel")!
  const _component_Switch = _resolveComponent("Switch")!
  const _component_SwitchGroup = _resolveComponent("SwitchGroup")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_HeaderItem = _resolveComponent("HeaderItem")!
  const _component_DialogOverlay = _resolveComponent("DialogOverlay")!
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_DialogTitle = _resolveComponent("DialogTitle")!
  const _component_EnvelopeOpenIcon = _resolveComponent("EnvelopeOpenIcon")!
  const _component_DialogPanel = _resolveComponent("DialogPanel")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!
  const _component_ChevronUpDownIcon = _resolveComponent("ChevronUpDownIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Head, null, {
      default: _withCtx(() => [
        _createElementVNode("meta", {
          name: "theme-color",
          content: _ctx.dark ? '#111928' : '#F9FAFB'
        }, null, 8, _hoisted_1),
        _createElementVNode("link", {
          rel: "icon",
          type: "image/svg+xml",
          href: _ctx.dark ? '/favicon-dark.svg' : '/favicon.svg'
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }),
    _createElementVNode("header", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("a", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(), _createElementBlock("svg", _hoisted_9, _cache[15] || (_cache[15] = [
                  _createElementVNode("path", {
                    d: "M90.7288 129.489H37.6972L29.817 151H0L62.8286 0H64.9584L128 151H99.0349L90.7288 129.489ZM82.8486 108.831L67.3012 68.3653L63.6805 56.4386L60.2729 68.3653L45.3644 108.831H82.8486Z",
                    fill: "black"
                  }, null, -1)
                ])))
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_BellIcon, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleChangeNotificationsDrawerState(!_ctx.notificationsDrawerIsOpen))),
              class: "h-6 w-6 mr-2.5",
              "aria-hidden": "true"
            }),
            (_ctx.auth.notifications_length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.auth.notifications_length > 99 ? '99+' : _ctx.auth.notifications_length), 1))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_Menu, {
            as: "div",
            class: "relative flex flex-1 justify-end max-w-max"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MenuButton, { class: "focus:ring-gray-300 dark:focus:ring-gray-700 relative flex max-w-xs items-center rounded-full text-sm focus:outline-none focus:ring-offset-2 lg:rounded-md lg:p-2 lg:hover:bg-gray-50" }, {
                default: _withCtx(() => [
                  _cache[16] || (_cache[16] = _createElementVNode("span", { class: "absolute -inset-1.5 lg:hidden" }, null, -1)),
                  _createVNode(_component_UserAvatar, {
                    user: _ctx.auth.user,
                    withTooltip: false,
                    size: "sm"
                  }, null, 8, ["user"]),
                  _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.auth.user?.name), 1)
                ]),
                _: 1
              }),
              _createVNode(_Transition, {
                "enter-active-class": "transition ease-out duration-100",
                "enter-from-class": "transform opacity-0 scale-95",
                "enter-to-class": "transform opacity-100 scale-100",
                "leave-active-class": "transition ease-in duration-75",
                "leave-from-class": "transform opacity-100 scale-100",
                "leave-to-class": "transform opacity-0 scale-95"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MenuItems, { class: "mt-8 border border-gray-200 dark:border-gray-750 origin-top-right absolute py-1 right-0 min-w-max max-w-56 max-h-64 overflow-scroll text-base list-none bg-white rounded-lg divide-gray-100 shadow-sm dark:bg-gray-850 dark:divide-gray-750" }, {
                    default: _withCtx(() => [
                      _createElementVNode("ul", _hoisted_14, [
                        _createElementVNode("li", _hoisted_15, [
                          _createVNode(_component_Link, {
                            href: _ctx.route('profile.edit'),
                            class: "cursor-pointer block text-sm"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$trans("main.settings")), 1)
                            ]),
                            _: 1
                          }, 8, ["href"])
                        ]),
                        _createElementVNode("li", {
                          class: "truncate select-none py-2 px-3 text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-white",
                          onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.dark = !_ctx.dark), ["stop"]))
                        }, [
                          _createVNode(_component_SwitchGroup, {
                            as: "div",
                            class: "flex items-center justify-between cursor-pointer"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_SwitchLabel, {
                                as: "span",
                                class: "text-sm mr-3",
                                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dark = !_ctx.dark))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$trans('main.shared.dark_theme')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_Switch, {
                                modelValue: _ctx.dark,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dark) = $event)),
                                class: _normalizeClass([[_ctx.dark ? 'bg-gray-800 dark:bg-primary-600' : 'bg-gray-200 dark:bg-primary-800 '], "select-none relative inline-flex h-4 w-7 items-center rounded-full"]),
                                onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.dark = !_ctx.dark), ["stop"]))
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", {
                                    class: _normalizeClass([[_ctx.dark ? 'translate-x-3 bg-white' : 'translate-x-1 bg-white dark:bg-primary-400'], "inline-block h-3 w-3 transform rounded-full transition"])
                                  }, null, 2)
                                ]),
                                _: 1
                              }, 8, ["modelValue", "class"])
                            ]),
                            _: 1
                          })
                        ]),
                        _createElementVNode("li", _hoisted_16, [
                          _createVNode(_component_Link, {
                            method: "delete",
                            href: _ctx.route('logout'),
                            as: "button",
                            class: "cursor-pointer flex-auto truncate block w-full text-sm text-left"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$trans('main.logout')), 1)
                            ]),
                            _: 1
                          }, 8, ["href"])
                        ]),
                        _createVNode(_component_Link, {
                          as: "li",
                          href: _ctx.route('changelog'),
                          class: "text-gray-400 dark:text-gray-500 border-gray-300 dark:border-gray-700 hover:text-gray-500 dark:hover:text-gray-400 hover:cursor-pointer text-tiny text-left border-t border-dashed select-none flex pt-2 pb-1 px-3"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(`v.${_ctx.appVersion}`) + _toDisplayString(` · ${_ctx.$trans('changelog.versions_and')} `) + " ", 1),
                            _createElementVNode("span", _hoisted_17, [
                              _createTextVNode(_toDisplayString(_ctx.$trans('changelog.changelog')) + " ", 1),
                              (!_ctx.user?.has_seen_latest_version)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_18))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 1
                        }, 8, ["href"])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("header", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_Logo, { "logo-color": _ctx.logoColor }, null, 8, ["logo-color"]),
            _cache[17] || (_cache[17] = _createStaticVNode("<div class=\"hidden\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"100%\" height=\"100%\" fill=\"none\" viewBox=\"0 0 32 33\"><path fill=\"#111827\" fill-opacity=\"0.2\" d=\"M0 18.83L.08 6.832C.094 4.66 1.613 2.798 3.71 2.383L15.297.086c2.784-.552 5.368 1.627 5.35 4.51l-.08 12c-.015 2.171-1.534 4.032-3.631 4.448L5.349 23.341C2.565 23.893-.019 21.714 0 18.83z\"></path><path fill=\"#111827\" fill-opacity=\"0.5\" d=\"M5.677 23.617l.08-12C5.77 9.447 7.29 7.586 9.387 7.17l11.587-2.296c2.784-.552 5.368 1.626 5.349 4.51l-.08 12c-.014 2.171-1.533 4.032-3.63 4.448l-11.587 2.297c-2.784.551-5.368-1.627-5.349-4.51z\"></path><path fill=\"#111827\" fill-opacity=\"0.8\" d=\"M11.354 28.404l.08-12c.014-2.172 1.533-4.032 3.63-4.448l11.587-2.297c2.784-.552 5.368 1.627 5.349 4.51l-.08 12c-.014 2.172-1.533 4.033-3.63 4.448l-11.587 2.297c-2.784.552-5.368-1.627-5.35-4.51z\"></path></svg></div>", 1))
          ]),
          _createElementVNode("ul", _hoisted_23, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (item) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                (item.show)
                  ? (_openBlock(), _createElementBlock("li", {
                      key: item.name,
                      class: "text-ellipsis whitespace-nowrap flex items-end min-h-6"
                    }, [
                      _createVNode(_component_HeaderItem, {
                        item: item,
                        itemColor: _ctx.navigationColor
                      }, null, 8, ["item", "itemColor"])
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 256))
          ])
        ]),
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("button", {
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleChangeNotificationsDrawerState(!_ctx.notificationsDrawerIsOpen))),
            class: "focus:ring-gray-900 dark:focus:ring-primary-700 lg:hover:bg-gray-100/50 lg:dark:hover:bg-gray-700/50 bg-gray-200/50 dark:bg-gray-800/50 mr-2 relative flex items-center rounded-full text-sm focus:outline-none focus:ring-2 md:rounded-md md:p-1.5"
          }, [
            _createVNode(_component_BellIcon, {
              class: "h-6 w-6",
              "aria-hidden": "true"
            }),
            (_ctx.notificationsLength)
              ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                  _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.notificationsLength > 99 ? '99+' : _ctx.notificationsLength), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_TransitionRoot, {
            as: "template",
            show: _ctx.notificationsDrawerIsOpen,
            appear: "",
            "focus-trap": "self"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Dialog, {
                as: "div",
                class: "z-50 fixed inset-0 overflow-hidden",
                onClose: _cache[10] || (_cache[10] = ($event: any) => (_ctx.handleChangeNotificationsDrawerState(false)))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_27, [
                    _createVNode(_component_TransitionChild, {
                      as: "template",
                      enter: "transition-opacity ease-linear duration-300",
                      "enter-from": "opacity-0",
                      "enter-to": "opacity-100",
                      leave: "transition-opacity ease-linear duration-300",
                      "leave-from": "opacity-100",
                      "leave-to": "opacity-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DialogOverlay, { class: "absolute inset-0 bg-transparent" })
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_28, [
                      _createVNode(_component_TransitionChild, {
                        onAfterEnter: _cache[8] || (_cache[8] = ($event: any) => (_ctx.notificationAfterEnter = true)),
                        onAfterLeave: _cache[9] || (_cache[9] = ($event: any) => (_ctx.notificationAfterEnter = false)),
                        as: "template",
                        enter: "transform transition ease-in-out duration-500",
                        "enter-from": "translate-x-full",
                        "enter-to": "-translate-x-0",
                        leave: "transform transition ease-in-out duration-500",
                        "leave-from": "-translate-x-0",
                        "leave-to": "translate-x-full"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_29, [
                            _createVNode(_component_DialogPanel, { class: "rounded-lg pt-3 bg-gray-200/60 dark:bg-gray-800/70 backdrop-blur-xl dark:backdrop-blur-md" }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_30, [
                                  _createElementVNode("div", _hoisted_31, [
                                    _createVNode(_component_DialogTitle, {
                                      as: "h3",
                                      class: "pl-3 text-base font-semibold leading-6 text-gray-900 dark:text-white"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$trans('notification.title')), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createElementVNode("div", _hoisted_32, [
                                      _createElementVNode("div", _hoisted_33, [
                                        (!_ctx.notificationsHideOrShowLoading)
                                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                              ((_ctx.user?.hide_read_notifications ? _ctx.unreadNotifications : _ctx.notifications.data).length > 0)
                                                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList((_ctx.user?.hide_read_notifications ? _ctx.unreadNotifications : _ctx.notifications.data), (notification) => {
                                                    return (_openBlock(), _createBlock(_resolveDynamicComponent(notification.url ? 'Link' : 'div'), {
                                                      key: notification.id,
                                                      href: notification.url,
                                                      class: _normalizeClass([notification.url != null ? 'cursor-pointer' : 'cursor-default', "flex px-4 py-3 hover:bg-gray-50 dark:hover:bg-gray-950 !outline-none"])
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createElementVNode("div", _hoisted_34, [
                                                          _createVNode(_component_UserAvatar, {
                                                            user: notification.initiator,
                                                            size: "md",
                                                            "with-tooltip": false
                                                          }, null, 8, ["user"])
                                                        ]),
                                                        _createElementVNode("div", _hoisted_35, [
                                                          _createElementVNode("div", _hoisted_36, [
                                                            _createElementVNode("div", _hoisted_37, [
                                                              _createElementVNode("div", {
                                                                innerHTML: notification.header
                                                              }, null, 8, _hoisted_38)
                                                            ]),
                                                            (!notification.is_read)
                                                              ? (_openBlock(), _createElementBlock("div", _hoisted_39))
                                                              : _createCommentVNode("", true)
                                                          ]),
                                                          _createElementVNode("div", _hoisted_40, [
                                                            _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.formatCustomDate(notification.created_at, _ctx.lang)), 1),
                                                            _createElementVNode("div", {
                                                              class: _normalizeClass(["flex flex-1 flex-row justify-end", {'text-primary-600 dark:text-primary-500': !notification.is_read}])
                                                            }, _toDisplayString(_ctx.formatTime(notification.created_at)), 3)
                                                          ]),
                                                          (notification.footer != null)
                                                            ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                                                                _createElementVNode("div", {
                                                                  innerHTML: _ctx.truncateHTML(notification.footer, 500)
                                                                }, null, 8, _hoisted_43)
                                                              ]))
                                                            : _createCommentVNode("", true)
                                                        ])
                                                      ]),
                                                      _: 2
                                                    }, 1032, ["href", "class"]))
                                                  }), 128))
                                                : (_openBlock(), _createElementBlock("div", _hoisted_44, [
                                                    _createElementVNode("div", _hoisted_45, [
                                                      (_ctx.isAnimatedPlaceholder && (_ctx.user?.hide_read_notifications ? _ctx.unreadNotifications : _ctx.notifications.data).length === 0)
                                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                            _cache[18] || (_cache[18] = _createElementVNode("div", { class: "bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700 motion-preset-slide-up-lg -translate-x-4 -rotate-6 rounded-lg border-2 p-2 shadow-lg motion-ease-out-back" }, [
                                                              _createElementVNode("div", { class: "bg-gray-100 dark:bg-gray-700 mb-1 h-4 w-full rounded-md" }),
                                                              _createElementVNode("div", { class: "bg-gray-100 dark:bg-gray-700 h-3 w-3/4 rounded-md" })
                                                            ], -1)),
                                                            _cache[19] || (_cache[19] = _createElementVNode("div", { class: "bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700 motion-preset-slide-up-lg translate-x-4 rotate-6 rounded-lg border-2 p-2 shadow-lg motion-delay-150 motion-ease-out-back" }, [
                                                              _createElementVNode("div", { class: "bg-gray-100 dark:bg-gray-700 mb-1 h-4 w-full rounded-md" }),
                                                              _createElementVNode("div", { class: "bg-gray-100 dark:bg-gray-700 h-3 w-3/4 rounded-md" })
                                                            ], -1)),
                                                            _cache[20] || (_cache[20] = _createElementVNode("div", { class: "bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700 motion-preset-slide-up-lg -translate-x-4 -rotate-6 rounded-lg border-2 p-2 shadow-lg motion-delay-300 motion-ease-out-back" }, [
                                                              _createElementVNode("div", { class: "bg-gray-100 dark:bg-gray-700 mb-1 h-4 w-full rounded-md" }),
                                                              _createElementVNode("div", { class: "bg-gray-100 dark:bg-gray-700 h-3 w-3/4 rounded-md" })
                                                            ], -1))
                                                          ], 64))
                                                        : _createCommentVNode("", true)
                                                    ]),
                                                    _createElementVNode("div", _hoisted_46, [
                                                      _createElementVNode("p", _hoisted_47, _toDisplayString(_ctx.$trans('notification.empty_state.title')), 1),
                                                      _createElementVNode("p", _hoisted_48, _toDisplayString(_ctx.$trans('notification.empty_state.description')), 1)
                                                    ])
                                                  ]))
                                            ], 64))
                                          : (_openBlock(), _createElementBlock("div", _hoisted_49, _cache[21] || (_cache[21] = [
                                              _createElementVNode("div", {
                                                role: "status",
                                                class: "absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
                                              }, [
                                                _createElementVNode("svg", {
                                                  class: "w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600",
                                                  viewBox: "0 0 24 24",
                                                  xmlns: "http://www.w3.org/2000/svg"
                                                }, [
                                                  _createElementVNode("path", {
                                                    d: "M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z",
                                                    opacity: ".25"
                                                  }),
                                                  _createElementVNode("circle", {
                                                    class: "spinner_7WDj",
                                                    cx: "12",
                                                    cy: "2.5",
                                                    r: "1.5"
                                                  })
                                                ])
                                              ], -1)
                                            ])))
                                      ])
                                    ]),
                                    _createElementVNode("div", _hoisted_50, [
                                      _createElementVNode("button", {
                                        onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.markAllAsRead && _ctx.markAllAsRead(...args))),
                                        type: "button",
                                        class: "bg-white dark:bg-white/10 hover:bg-gray-50 dark:hover:bg-white/20 text-gray-900 dark:text-white ring-gray-300 dark:ring-transparent truncate flex-1 flex flex-row items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset justify-center"
                                      }, [
                                        _createVNode(_component_EnvelopeOpenIcon, {
                                          class: "w-4 h-4 mr-1 stroke-2",
                                          "aria-hidden": "true"
                                        }),
                                        _createElementVNode("span", _hoisted_51, _toDisplayString(_ctx.$trans('notification.mark_all_as_read')) + _toDisplayString(_ctx.notificationsMarkedAsReadLoading ? '...' : ''), 1)
                                      ]),
                                      _createElementVNode("button", {
                                        onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.hideOrShowNotification && _ctx.hideOrShowNotification(...args))),
                                        type: "button",
                                        class: "bg-primary-600 dark:bg-primary-700 hover:bg-primary-500 dark:hover:bg-primary-600 text-white flex-1 truncate rounded-md px-3 py-2 text-sm font-semibold shadow-sm"
                                      }, _toDisplayString(_ctx.user?.hide_read_notifications ? _ctx.$trans('notification.view_all') : _ctx.$trans('notification.hide_read')), 1)
                                    ])
                                  ])
                                ])
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["show"]),
          _createVNode(_component_Menu, {
            as: "div",
            class: "relative"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MenuButton, { class: "focus:ring-gray-900 dark:focus:ring-primary-700 lg:hover:bg-gray-100/50 lg:dark:hover:bg-gray-700/50 bg-gray-200/50 dark:bg-gray-800/50 min-w-48 max-w-56 space-x-2 justify-between relative flex items-center rounded-full text-sm focus:outline-none focus:ring-2 md:rounded-md md:p-1.5" }, {
                default: _withCtx(() => [
                  _cache[22] || (_cache[22] = _createElementVNode("span", { class: "absolute -inset-1.5 lg:hidden" }, null, -1)),
                  _createVNode(_component_UserAvatar, {
                    user: _ctx.auth.user,
                    withTooltip: false,
                    size: "xs"
                  }, null, 8, ["user"]),
                  _createElementVNode("span", _hoisted_52, [
                    _createElementVNode("span", _hoisted_53, _toDisplayString(_ctx.auth.user?.name), 1),
                    _createElementVNode("span", _hoisted_54, _toDisplayString(`@${_ctx.auth.user?.nickname}`), 1)
                  ]),
                  _createVNode(_component_ChevronUpDownIcon, {
                    class: "text-gray-600 dark:text-gray-300 ml-1 hidden h-5 w-5 flex-shrink-0 md:block",
                    "aria-hidden": "true"
                  })
                ]),
                _: 1
              }),
              _createVNode(_Transition, {
                "enter-active-class": "transition ease-out duration-100",
                "enter-from-class": "transform opacity-0 scale-95",
                "enter-to-class": "transform opacity-100 scale-100",
                "leave-active-class": "transition ease-in duration-75",
                "leave-from-class": "transform opacity-100 scale-100",
                "leave-to-class": "transform opacity-0 scale-95"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MenuItems, { class: "border border-gray-200 dark:border-gray-750 origin-top-right absolute py-1 mt-2 right-0 min-w-[-webkit-fill-available] min-w-[-moz-available] max-w-56 max-h-64 overflow-scroll text-base list-none bg-white rounded-lg divide-gray-100 shadow-sm dark:bg-gray-850 dark:divide-gray-750" }, {
                    default: _withCtx(() => [
                      _createElementVNode("ul", _hoisted_55, [
                        _createElementVNode("li", _hoisted_56, [
                          _createVNode(_component_Link, {
                            href: _ctx.route('profile.edit'),
                            class: "cursor-pointer block text-sm"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$trans("main.settings")), 1)
                            ]),
                            _: 1
                          }, 8, ["href"])
                        ]),
                        _createElementVNode("li", {
                          class: "select-none py-2 px-3 text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-white",
                          onClick: _cache[14] || (_cache[14] = _withModifiers(($event: any) => (_ctx.dark = !_ctx.dark), ["stop"]))
                        }, [
                          _createVNode(_component_SwitchGroup, {
                            as: "div",
                            class: "flex items-center justify-between cursor-pointer"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_SwitchLabel, {
                                as: "span",
                                class: "text-sm",
                                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.dark = !_ctx.dark))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$trans('main.shared.dark_theme')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_Switch, {
                                modelValue: _ctx.dark,
                                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.dark) = $event)),
                                class: _normalizeClass([[_ctx.dark ? 'bg-gray-800 dark:bg-primary-600' : 'bg-gray-200 dark:bg-primary-800 '], "select-none relative inline-flex h-4 w-7 items-center rounded-full"]),
                                onClick: _cache[13] || (_cache[13] = _withModifiers(($event: any) => (_ctx.dark = !_ctx.dark), ["stop"]))
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", {
                                    class: _normalizeClass([[_ctx.dark ? 'translate-x-3 bg-white' : 'translate-x-1 bg-white dark:bg-primary-400'], "inline-block h-3 w-3 transform rounded-full transition"])
                                  }, null, 2)
                                ]),
                                _: 1
                              }, 8, ["modelValue", "class"])
                            ]),
                            _: 1
                          })
                        ]),
                        _createElementVNode("li", _hoisted_57, [
                          _createVNode(_component_Link, {
                            method: "delete",
                            href: _ctx.route('logout'),
                            as: "button",
                            class: "cursor-pointer flex-auto truncate block w-full text-sm text-left"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$trans('main.logout')), 1)
                            ]),
                            _: 1
                          }, 8, ["href"])
                        ]),
                        _createVNode(_component_Link, {
                          as: "li",
                          href: _ctx.route('changelog'),
                          class: "text-gray-400 dark:text-gray-500 border-gray-300 dark:border-gray-700 hover:text-gray-500 dark:hover:text-gray-400 hover:cursor-pointer text-tiny text-left border-t border-dashed select-none flex pt-2 pb-1 px-3"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(`v.${_ctx.appVersion}`) + _toDisplayString(` · ${_ctx.$trans('changelog.versions_and')} `) + " ", 1),
                            _createElementVNode("span", _hoisted_58, [
                              _createTextVNode(_toDisplayString(_ctx.$trans('changelog.changelog')) + " ", 1),
                              (!_ctx.user?.has_seen_latest_version)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_59))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 1
                        }, 8, ["href"])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _cache[23] || (_cache[23] = _createElementVNode("div", { class: "h-16 sm:h-[--header-height]" }, null, -1))
  ], 64))
}