import { default as LightList } from '@/Types/Resources/CheckList/Light';
import { default as LightCard } from '@/Types/Resources/Card/Light';
import { updateLightCardFunction } from '@/Pages/Boards/Types';
import { PayloadResponse } from '@/Types/API/PayloadResponse';
import { useForm } from 'laravel-precognition-vue-inertia';
import { ref, Ref, nextTick } from 'vue';
import { sendRequest } from '@/mixins';
import { getApiToken } from '@/mixins';
import route from '@/ziggy';

type EditList = {
  _token: string;
  _checklist_id: string | null;
  b_c_c_c_name: string;
};

type CreateList = {
  _token: string;
  _position: number;
  b_c_c_c_name: string;
};

export function useCheckListActions(lists: Ref<LightList[]>, card: Ref<LightCard|null>, handleUpdateCard: updateLightCardFunction, handleCloseCreateListModal: Function)
{
  const listMode = ref<'create'|'edit'|null>(null);
  const editList = ref<LightList|null>(null);
  const isDeleteChecklistAttemptVisible = ref<boolean>(false);
  const idOfChecklistToDelete = ref<string>("");

  const createListForm = useForm('post', route('api.v1.boards.cards.checklists.store', {
    card: card.value?.id
  }), {
    _token: getApiToken(),
    _position: 0,
    b_c_c_c_name: ""
  } as CreateList);

  const editListForm = useForm('put', route('api.v1.boards.cards.checklists.update'), {
    _token: getApiToken(),
    _checklist_id: null,
    b_c_c_c_name: ""
  } as EditList);

  function openListEditForm(list: LightList): void
  {
    listMode.value = 'edit';
    editList.value = list;
    editListForm.b_c_c_c_name = list.name;

    nextTick(() => {
      const textarea = document.getElementById(`edit-list-area-${list.id}`) as HTMLTextAreaElement;
      if (textarea) {
        textarea.focus();
        textarea.select();
      }
    });
  }

  function onEditListFormBlur(list: LightList): void
  {
    if(editListForm.b_c_c_c_name !== list.name){
      const newName = editListForm.b_c_c_c_name;
      const oldName = list.name;
      
      list.name = newName;
      editListForm._checklist_id = list.id;

      sendRequest({
        name: 'api.v1.boards.cards.checklists.update',
        data: {
          _checklist_id: list.id,
          b_c_c_c_name: newName
        }
      }, (data: PayloadResponse<LightList>) => {
        list.name = data.payload.name;
      }, () => {
        list.name = oldName;
      });
    }

    listMode.value = null;
    editListForm.reset();
  }

  function submitCreateListForm(): void
  {
    createListForm._position = lists.value.length;

    if(card.value === null) return;

    sendRequest({
      name: 'api.v1.boards.cards.checklists.store',
      params: { 
        card: card.value?.id
      },
      data: {
        _position: createListForm._position,
        b_c_c_c_name: createListForm.b_c_c_c_name,
      },
    }, (data: PayloadResponse<LightList>) => {
      lists.value.push(data.payload);

      handleUpdateCard({...card.value, checklists: lists.value} as LightCard);
      handleCloseCreateListModal();

      createListForm.reset();
      createListForm.clearErrors();
    });
  }

  function toggleDeleteChecklistModal(value: boolean, listId: string): void
  {
    idOfChecklistToDelete.value = listId;
    isDeleteChecklistAttemptVisible.value = value;
  }

  function isCompletedPercentage(list: LightList): number
  {
    let completed = list.checks.filter((check) => check.is_completed).length;
    let total = list.checks.length;

    if(total === 0) return 0;

    return Math.round((completed / total) * 100);
  }

  function deleteList(): void
  {
    lists.value = lists.value.filter(list => list.id !== idOfChecklistToDelete.value);

    handleUpdateCard({...card.value, checklists: lists.value} as LightCard);
  }

  return {
    isDeleteChecklistAttemptVisible,
    toggleDeleteChecklistModal,
    isCompletedPercentage,
    idOfChecklistToDelete,
    submitCreateListForm,
    onEditListFormBlur,
    openListEditForm,
    createListForm,
    editListForm,
    deleteList,
    listMode,
    editList
  };
}