import localization, { loadTranslations } from './localization';
import { createApp, h, ComponentPublicInstance } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { RouteParams } from "./Types/App/ziggy";
import { io, Socket } from 'socket.io-client';
import { MotionPlugin } from '@vueuse/motion';
import route, { RouteName } from "@/ziggy";
import { InertiaPages } from '@/pages';

let socketDomain = process.env.MIX_SOCKET_DOMAIN;
let socket: Socket = io(`${socketDomain}`, { transports: ['polling'] });

createInertiaApp({
  progress: {
    color: '#3f83f8',
    includeCSS: true,
  },
  // resolve: (name) => name != undefined ? import(`./Pages/${name}.vue`) : import('./Pages/Errors.vue'),
  resolve: (name) => require(`./Pages/${name}`) as InertiaPages,
  setup({ App, props, plugin, el }) {
    const app = createApp({ render: () => h(App as unknown as ComponentPublicInstance, props) })
    .use(plugin)
    .use(localization, {
      lang: document?.documentElement?.lang ?? 'en',
      resolve: (lang: string) => loadTranslations(lang),
    })
    .mixin({
      methods: {
        route(name: RouteName, params?: RouteParams<string & {}>, absolute?: boolean) {
          return route(name, params, absolute);
        }
      }
    });
    app.provide('$socket', socket);
    app.use(MotionPlugin);
    app.mount(el as Element);
  },
}).then();