import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "space-y-20 py-20 sm:space-y-32 sm:py-32" }
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "relative mb-10 xl:mb-0" }
const _hoisted_4 = { class: "pointer-events-none absolute left-[max(-0.5rem,calc(50%-18.625rem))] top-0 z-50 flex h-4 items-center justify-end gap-x-2 lg:left-0 lg:right-[calc(max(2rem,50%-38rem)+40rem)] lg:min-w-[32rem] xl:h-8" }
const _hoisted_5 = ["href"]
const _hoisted_6 = ["datetime"]
const _hoisted_7 = { class: "mx-auto max-w-7xl px-6 lg:flex lg:px-8" }
const _hoisted_8 = { class: "lg:ml-96 lg:flex lg:w-full lg:justify-end lg:pl-32" }
const _hoisted_9 = { class: "mx-auto max-w-lg lg:mx-0 lg:w-0 lg:max-w-xl lg:flex-auto" }
const _hoisted_10 = { class: "flex" }
const _hoisted_11 = ["href"]
const _hoisted_12 = ["datetime"]
const _hoisted_13 = { class: "mx-auto max-w-7xl px-6 lg:flex lg:px-8" }
const _hoisted_14 = { class: "lg:ml-96 lg:flex lg:w-full lg:justify-end lg:pl-32" }
const _hoisted_15 = {
  class: "mx-auto max-w-lg lg:mx-0 lg:w-0 lg:max-w-xl lg:flex-auto typography",
  "data-mdx-content": "true"
}
const _hoisted_16 = {
  key: 0,
  class: "relative mt-8 overflow-hidden rounded-xl bg-gray-50 dark:bg-gray-900 [&+*]:mt-8"
}
const _hoisted_17 = ["href"]
const _hoisted_18 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, null, {
    default: _withCtx(() => [
      _createElementVNode("main", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.changes, (change) => {
          return (_openBlock(), _createElementBlock("article", {
            id: change.id,
            class: "scroll-mt-16 pb-0"
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("header", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("a", {
                    class: "inline-flex",
                    href: `#${change.id}-suggestions`
                  }, [
                    _createElementVNode("time", {
                      datetime: change.date,
                      class: "xl:text-white/50 hidden xl:pointer-events-auto xl:block xl:text-2xs/4 xl:font-medium"
                    }, _toDisplayString(_ctx.formatLocalizedDate(change.date, _ctx.lang)), 9, _hoisted_6)
                  ], 8, _hoisted_5),
                  _cache[0] || (_cache[0] = _createElementVNode("div", { class: "h-[0.0625rem] w-3.5 bg-gray-400 lg:-mr-3.5 xl:mr-0 xl:bg-gray-300" }, null, -1))
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("a", {
                          class: "inline-flex",
                          href: `#${change.id}-suggestions`
                        }, [
                          _createElementVNode("time", {
                            datetime: change.date,
                            class: "text-gray-500 dark:text-white/50 text-2xs/4 font-medium xl:hidden"
                          }, _toDisplayString(_ctx.formatLocalizedDate(change.date, _ctx.lang)), 9, _hoisted_12)
                        ], 8, _hoisted_11)
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    (change.image)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_16, _cache[1] || (_cache[1] = [
                          _createElementVNode("div", { class: "pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10 dark:ring-white/10" }, null, -1)
                        ])))
                      : _createCommentVNode("", true),
                    _createElementVNode("h2", null, [
                      _createElementVNode("a", {
                        href: `#${change.id}-suggestions`
                      }, _toDisplayString(change.title[_ctx.lang]), 9, _hoisted_17)
                    ]),
                    (_openBlock(), _createBlock(_resolveDynamicComponent({template: change.description[_ctx.lang]}))),
                    (change.improvements)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createElementVNode("h3", {
                            id: `#${change.id}-improvements`
                          }, [
                            _cache[2] || (_cache[2] = _createElementVNode("svg", {
                              viewBox: "0 0 16 16",
                              "aria-hidden": "true"
                            }, [
                              _createElementVNode("path", {
                                fill: "#38BDF8",
                                d: "M5.338 9.805c.11.418.439.747.857.857C7.282 10.948 8 11.44 8 12s-.718 1.052-1.805 1.338c-.418.11-.747.439-.857.857C5.052 15.281 4.56 16 4 16s-1.052-.718-1.338-1.805a1.205 1.205 0 0 0-.856-.857C.718 13.052 0 12.56 0 12s.718-1.052 1.806-1.338c.417-.11.746-.439.856-.857C2.948 8.718 3.441 8 4 8c.56 0 1.052.718 1.338 1.805Z"
                              }),
                              _createElementVNode("path", {
                                fill: "#7DD3FC",
                                d: "M12.717 2.432c.1.42.43.75.85.852C15.026 3.633 16 4.27 16 5s-.975 1.367-2.432 1.716c-.42.101-.75.432-.851.852C12.367 9.025 11.729 10 11 10c-.729 0-1.367-.975-1.716-2.432-.101-.42-.431-.75-.851-.852C6.975 6.367 6 5.73 6 5c0-.73.975-1.367 2.433-1.717.42-.1.75-.43.85-.85C9.634.974 10.272 0 11 0c.73 0 1.367.975 1.717 2.432Z"
                              })
                            ], -1)),
                            _createTextVNode(" " + _toDisplayString(_ctx.$trans('changelog.improvements')), 1)
                          ], 8, _hoisted_18),
                          _createElementVNode("ul", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(change.improvements, (improvement) => {
                              return (_openBlock(), _createElementBlock("li", null, _toDisplayString(improvement[_ctx.lang]), 1))
                            }), 256))
                          ])
                        ], 64))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ])
          ], 8, _hoisted_2))
        }), 256))
      ])
    ]),
    _: 1
  }))
}