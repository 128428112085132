<template>
  <nav :class="navClass" aria-label="Breadcrumb">
    <ol class="inline-flex items-center space-x-1 md:space-x-2">
      <li class="inline-flex items-center">
        <Link :href="route('dashboard')" class="inline-flex items-center text-gray-700 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white">
          <svg class="w-5 h-5 mr-2.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
          </svg>
          {{ $trans('main.shared.home') }}
        </Link>
      </li>
      <li v-for="(page, index) in pages" :key="`${index}-${page.name}-breadcrumb-link`">
        <div class="flex items-center">
          <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
          </svg>
          <Link :href="route(page.route, page.argument)" class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-300 dark:hover:text-white">
            {{ page.name }}
          </Link>
        </div>
      </li>
    </ol>
  </nav>
</template>
<script lang="ts">
import { Link } from '@inertiajs/vue3';
import { defineComponent } from "vue";
import Page from '@/Types/App/Page';
import route from '@/ziggy';

export default defineComponent({
  props: {
    pages: {
      type: Array<Page>,
      required: false,
      default() {
        return [];
      }
    },
    navClass: {
      type: String,
      required: false,
      default: "flex mb-5"
    }
  },
  components: {
    Link,
  },
  setup(){
    return { route };
  }
});
</script>