import { default as LightColumn } from '@/Types/Resources/Column/Light';
import { default as UserHandle } from '@/Types/Resources/User/Handle';
import { default as LightCard } from '@/Types/Resources/Card/Light';
import { default as MicroCard } from '@/Types/Resources/Card/Micro';
import { default as BaseBoard } from '@/Types/Resources/Board/Base';
import { router } from '@inertiajs/vue3';
import { ref, Ref, watch } from 'vue';
import { debounce } from 'lodash';
import route from '@/ziggy';

export function useCardActions(initialShowingCard: MicroCard|null, columns: Ref<Array<LightColumn>>, user: Ref<UserHandle>, board: Ref<BaseBoard>)
{
  const card = ref<MicroCard|null>(initialShowingCard);
  const isCreateCardModalVisible = ref(false);
  const isEditCardModalVisible = ref(false);
  const cardBeingEdited = ref<MicroCard|null>(null);
  const targetColumnForNewCard = ref<LightColumn|null>(null);
  const cardBeingShown = ref<LightCard|null>(null);
  const isDetailsModalVisible = ref<boolean>(false);

  const openShowCardModal = debounce((cardId: string) => {
    cardBeingShown.value = null;
    isDetailsModalVisible.value = true;
    
    router.get(route('boards.show', {
      board: board.value.id
    }), {
      card_id: cardId
    }, {
      preserveState: true,
      only: ['card'],
      onSuccess: (page) => {
        cardBeingShown.value = page.props.card as LightCard;
      },
    });
  }, 300);

  watch(() => card, (newnCard) => {
    if(newnCard.value !== null){
      openShowCardModal(newnCard.value.id);
    }
  },{
    immediate: true
  });

  function openNewCardForm(column: LightColumn): void
  {
    isCreateCardModalVisible.value = true;
    targetColumnForNewCard.value = column;
  }

  function openEditCardModal(card: MicroCard): void
  {
    isEditCardModalVisible.value = true;
    cardBeingEdited.value = card;
  }

  function checkUserParticipationInCard(card: MicroCard): Boolean
  {
    return card.members.some(member => member.id === user.value.id);
  }

  function handleEditCardModal(value: boolean): void
  {
    cardBeingEdited.value = null;
    isEditCardModalVisible.value = value;
  }

  function handleCreateCardModal(value: boolean): void
  {
    isCreateCardModalVisible.value = value;
    targetColumnForNewCard.value = null;
  }

  function handleShowCardModal(value: boolean): void
  {
    isDetailsModalVisible.value = value;
  }

  function handleCopyCard(card: MicroCard): void
  {
    columns.value.forEach((column) => {
      if (column.id === card.column_id) {
        // column.cards.push(card);
      }
    });
  }

  function handleRemoveCard(card: MicroCard): void
  {
    columns.value.forEach((column) => {
      if (column.id === card.column_id) {
        column.cards.data = column.cards.data.filter((c) => c.id !== card.id);
      }
    });
  }

  return {
    checkUserParticipationInCard,
    isCreateCardModalVisible,
    isEditCardModalVisible,
    targetColumnForNewCard,
    isDetailsModalVisible,
    handleCreateCardModal,
    handleShowCardModal,
    handleEditCardModal,
    openEditCardModal,
    openShowCardModal,
    handleRemoveCard,
    cardBeingEdited,
    openNewCardForm,
    cardBeingShown,
    handleCopyCard,
  };
}