<template>
  <TransitionRoot as="template" :show="open" appear>
    <Dialog as="div" class="z-50 fixed inset-0 overflow-hidden" @close="close()">
      <div class="absolute inset-0 overflow-hidden">
        <!-- Overlay -->
        <TransitionChild
          appear
          :show="open"
          as="template"
          enter="transition-opacity ease-linear duration-100"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-100"
          leave-from="opacity-100"
          leave-to="opacity-0">
          <DialogOverlay class="absolute inset-0 bg-black bg-opacity-50 dark:bg-opacity-50" />
        </TransitionChild>
        <!-- Drawer -->
        <div class="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10 sm:pr-16">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="-translate-x-full">
            <div class="pointer-events-auto w-screen max-w-sm">
              <form class="flex h-full flex-col bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-200 shadow-xl" @submit.prevent="submit">
                <div class="flex-1 overflow-y-auto mb-8">
                  <!-- Header -->
                  <div class="bg-gray-50 dark:bg-gray-850 px-4 py-4 sm:px-6">
                    <div class="flex justify-between items-center">
                      <div>
                        <DialogTitle class="text-lg font-medium text-gray-900 dark:text-white">
                          {{ $trans('main.boards.titles.edit_board') }}
                        </DialogTitle>
                      </div>
                      <Cross @click="close()"/>
                    </div>
                  </div>
                  <!-- Content -->
                  <div class="space-y-6 px-4 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 dark:sm:divide-gray-700">
                    <div class="max-h-[calc(100vh-200px)]">
                      <div class="grid-cols-2">
                        <div class="md:col-span-2">
                          <div class="grid gap-6">
                            <div class="col-span-6 sm:col-span-3">
                              <Input
                                id="b_name"
                                type="text"
                                label="validation.attributes.b_name"
                                name="b_name"
                                v-model="editForm.b_name"
                                :required="true"
                                :form="editForm"/>
                            </div>
                            <div class="col-span-6 sm:col-span-3">
                              <Select id="b_status" 
                                name="b_status"
                                label="validation.attributes.b_status" 
                                v-model="editForm.b_status"
                                value="item"
                                trans-key="main.boards.statuses"
                                :items="statuses"
                                :required="true"/>
                            </div>
                            <div class="col-span-6 sm:col-span-3">
                              <FileUpload
                                id="b_bg_path"
                                name="b_bg_path"
                                :form="editForm"
                                @file-selected="handleFileSelected"
                                label="validation.attributes.b_bg_path"
                                v-model="editForm.b_bg_path"
                                placeholder="main.boards.select_new_bg"/>
                            </div>
                            <div class="col-span-6 sm:col-span-3">
                              <ColorPicker
                                label="validation.attributes.b_color"
                                v-model="editForm.b_color"
                                :form="editForm"
                                name="b_color"/>
                            </div>
                            <div class="col-span-6 sm:col-span-3">
                              <Share
                                :board-id="board?.id"
                                :hash-link="editForm._hash_link"
                                :hash-id="editForm.b_hash_id"
                                @updateHashLink="($event) => editForm._hash_link = $event"
                                @updateHashId="($event) => editForm.b_hash_id = $event"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Footer -->
                <div class="flex-shrink-0 border-t border-gray-200 dark:border-gray-750 px-2 py-3 sm:px-4">
                  <div class="flex justify-end space-x-3">
                    <div class="flex justify-end space-x-3">
                      <Button v-if="board" 
                        :text="$trans('main.shared.buttons.delete')" 
                        @click="openDeleteBoardModal(board.id)" 
                        type="button" 
                        color="red" 
                        class="text-sm"/>
                      <button type="submit" :class="{'cursor-progress': editForm.processing}" class="bg-gray-900 dark:bg-primary-800  text-white dark:text-gray-100 hover:bg-gray-700 dark:hover:bg-primary-750 focus:ring-gray-700 dark:focus:ring-primary-950 dark:ring-offset-transparent focus:ring-2 focus:ring-offset-2 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                        <svg v-if="editForm.processing" role="status" class="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                        </svg>
                        {{$trans('main.shared.buttons.update')}}{{editForm.processing ? '...' : ''}}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <Attempt 
    :open="deleteModalIsOpen" 
    :close="(value: boolean) => deleteModalIsOpen = value" 
    routeName="boards.destroy" 
    :routeArgument="{ id: deleteBoardId }"
    :title="$trans('main.attempts.delete_board.title')" 
    :description="$trans('main.attempts.delete_board.description')" 
    :successButtonText="$trans('main.attempts.buttons.success_button')" 
    :cancelButtonText="$trans('main.attempts.buttons.cancel_button')"/>
</template>
<script lang="ts">
import { Listbox, ListboxButton, ComboboxInput, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { default as BoardList } from '@/Types/Resources/Board/List';
import { defineComponent, ref, toRef, PropType, watch } from "vue";
import { default as Member } from '@/Types/Resources/User/Member';
import { PlusIcon, CheckIcon } from '@heroicons/vue/20/solid';
import { useForm } from 'laravel-precognition-vue-inertia';
import Share from '@js/Pages/Boards/Components/Share.vue';
import FileUpload from '@js/Shared/FileUpload.vue';
import ColorPicker from '@/Shared/ColorPicker.vue';
import UserAvatar from '@js/Shared/UserAvatar.vue';
import Attempt from '@js/Shared/Attempt.vue';
import Select from '@js/Shared/Select.vue';
import Button from '@js/Shared/Button.vue';
import Cross from '@js/Shared/Cross.vue';
import Input from '@js/Shared/Input.vue';
import route from '@/ziggy';

type BoardEdit = {
  _hash_link: string | null;
  b_hash_id: number | null;
  b_name: string;
  b_status: string;
  b_bg_path: File | null;
  b_color: string | null;
};

export default defineComponent({
  emits: ['close'],
  components: {
    TransitionChild,
    TransitionRoot,
    ListboxOptions,
    DialogOverlay,
    ListboxButton,
    ComboboxInput,
    ListboxOption,
    DialogTitle,
    ColorPicker,
    FileUpload,
    UserAvatar,
    CheckIcon,
    PlusIcon,
    Attempt,
    Listbox,
    Select,
    Button,
    Dialog,
    Input,
    Cross,
    Share
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    board: {
      type: [Object, null] as PropType<BoardList | null>,
      required: true
    },
    members: {
      type: Array as () => Array<Member>,
      required: true
    },
    statuses: {
      type: Array as () => Array<string>,
      required: true
    },
    onClose: {
      type: Function,
      default: () => {}
    }
  },
  setup(props, {emit: $emit}) {
    const open = toRef(props, 'isOpen');
    const board = toRef(props, 'board');
    const createModalSubmitButton = ref(null);
    const changeTimeFrom = ref<boolean>(false);
    const changeTimeTo = ref<boolean>(false);
    const allMembers = ref<Array<Member>>(props.members);
    const deleteModalIsOpen = ref<boolean>(false);
    const deleteBoardId = ref<string | null>(null);
    const editForm = useForm('post', () => route('boards.update', {
      board: board.value?.id
    }), {
      _hash_link: '',
      b_hash_id: null,
      b_name: '',
      b_status: 'active',
      b_bg_path: null,
      b_color: '',
    } as BoardEdit);

    // useTransitionFix(open);

    watch(open, (newOpen) => {
      if(newOpen && board.value !== null){
        editForm._hash_link = route('boards.invite', {hash: board.value?.hash_encoded});
        editForm.b_hash_id = board.value?.hash_id;
        editForm.b_name = board.value?.name;
        editForm.b_status = board.value?.status;
        editForm.b_bg_path = null;
        editForm.b_color = board.value?.color;
      }
    });

    function close(): void
    {
      $emit('close');
      editForm.reset();
      editForm.clearErrors();
    }

    function openDeleteBoardModal(boardId: string): void
    {
      close();

      deleteBoardId.value = boardId;
      deleteModalIsOpen.value = true;
    }

    function submit(): void
    {
      editForm.submit({
        forceFormData: true,
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
          editForm.reset();
          editForm.clearErrors();
          close();
        }
      });
    }

    function handleFileSelected(file: File): void
    {
      editForm.b_bg_path = file;

      const imagePreview = ref('');
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        imagePreview.value = e.target?.result as string;
      };
      reader.readAsDataURL(file);

      // 2. Проверить размер файла
      // const maxSize = 5 * 1024 * 1024; // 5MB
      // if (file.size > maxSize) {
      //   console.error('File is too large');
        // Можно также установить ошибку в форме
        // editForm.setError('bg_path', 'The file is too large. Maximum size is 5MB.');
      // }

      // 3. Проверить тип файла
      // const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
      // if (!allowedTypes.includes(file.type)) {
        // console.error('Invalid file type');
        // editForm.setError('bg_path', 'Invalid file type. Please upload a JPEG, PNG or GIF image.');
      // }
    };

    return {
      createModalSubmitButton,
      openDeleteBoardModal,
      handleFileSelected,
      deleteModalIsOpen,
      changeTimeFrom,
      deleteBoardId,
      changeTimeTo,
      allMembers,
      editForm,
      submit,
      route,
      close,
      open,
    };
  }
});
</script> 