<template>
  <TransitionRoot
    appear
    as="div"
    :show="(show as boolean)"
    class="bg-white dark:bg-gray-800 select-none max-w-xs w-full shadow-lg rounded-2xl pointer-events-auto overflow-hidden relative"
    enter="transform ease-out duration-300 transition"
    enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    enter-to="translate-y-0 opacity-100 sm:translate-x-0"
    leave="transform ease-out duration-300 transition"
    leave-from="translate-y-0 opacity-100 sm:translate-x-0"
    leave-to="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2">
    <component 
      :is="notification.url ? 'Link' : 'div'"
      :key="notification.id"
      :href="notification.url"
      :class="notification.url != null ? 'cursor-pointer' : 'cursor-default'"
      class="z-10 pointer-events-auto w-full max-w-md rounded-2xl relative">
      <div class="w-full py-3.5 px-5 relative">
        <div class="flex items-start" :class="[notification.footer != null ? 'items-start' : 'items-center']">
          <div class="flex-shrink-0 flex items-center max-h-12">
            <UserAvatar :user="notification.initiator" size="md" :with-tooltip="false"/>
          </div>
          <div class="ml-3 flex-1 flex items-center flex-col text-start">
            <p v-if="notification.header != null" v-html="notification.header" class="leading-4 text-start text-small font-nunito text-gray-900 dark:text-gray-200"/>
            <p v-if="notification.footer != null" v-html="notification.footer" class="text-petite w-full text-balance leading-4 font-nunito font-light text-gray-500 dark:text-gray-400"/>
          </div>
        </div>
        <div class="absolute top-3 right-3">
          <button @click.prevent="show = false" type="button" class="inline-flex rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-gray-900">
            <span class="sr-only">Close</span>
            <XMarkIcon class="h-5 w-5" aria-hidden="true"/>
          </button>
        </div>
      </div>
     </component>
  </TransitionRoot>
</template>
<script lang="ts">
import ShortNotification from '@/Types/Resources/Notification/Short';
import { defineComponent, PropType, ref } from "vue";
import { XMarkIcon } from '@heroicons/vue/20/solid';
import { TransitionRoot } from '@headlessui/vue';
import UserAvatar from '@/Shared/UserAvatar.vue';
import { Link } from '@inertiajs/vue3';

export default defineComponent({
  components: {
    TransitionRoot,
    UserAvatar,
    XMarkIcon,
    Link
  },
  props: {
    notification: {
      type: Object as PropType<ShortNotification>,
      required: true
    },
  },
  setup() {
    const show = ref<boolean>(true);
    return {
      show,
    };
  }
});
</script>