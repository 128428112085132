import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center mt-32 p-10" }
const _hoisted_2 = { class: "mx-auto my-auto max-w-lg" }
const _hoisted_3 = {
  role: "list",
  class: "mt-6 divide-y divide-gray-200 dark:divide-gray-800 border-b border-t border-gray-200 dark:border-gray-800"
}
const _hoisted_4 = { class: "group relative flex items-start space-x-3 py-4" }
const _hoisted_5 = { class: "flex-shrink-0" }
const _hoisted_6 = { class: "min-w-0 flex-1" }
const _hoisted_7 = { class: "text-sm font-medium text-gray-900" }
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "text-sm text-gray-500" }
const _hoisted_10 = { class: "flex-shrink-0 self-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "text-gray-900 dark:text-white text-base font-semibold leading-6" }, "Create your first project", -1)),
          _cache[2] || (_cache[2] = _createElementVNode("p", { class: "mt-1 text-sm text-gray-500" }, "Get started by selecting a template or start from an empty project.", -1)),
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, itemIdx) => {
              return (_openBlock(), _createElementBlock("li", { key: itemIdx }, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("span", {
                      class: _normalizeClass([item.iconColor, 'inline-flex h-10 w-10 items-center justify-center rounded-lg'])
                    }, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                        class: "h-6 w-6 text-white",
                        "aria-hidden": "true"
                      }))
                    ], 2)
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("a", {
                        href: item.href,
                        class: "dark:text-white"
                      }, [
                        _cache[0] || (_cache[0] = _createElementVNode("span", {
                          class: "absolute inset-0",
                          "aria-hidden": "true"
                        }, null, -1)),
                        _createTextVNode(" " + _toDisplayString(item.name), 1)
                      ], 8, _hoisted_8)
                    ]),
                    _createElementVNode("p", _hoisted_9, _toDisplayString(item.description), 1)
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_ChevronRightIcon, {
                      class: "h-5 w-5 text-gray-400 group-hover:text-gray-500",
                      "aria-hidden": "true"
                    })
                  ])
                ])
              ]))
            }), 128))
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "mt-6 flex" }, [
            _createElementVNode("a", {
              href: "#",
              class: "text-sm font-medium text-primary-600 hover:text-primary-500"
            }, [
              _createTextVNode(" Or start from an empty project "),
              _createElementVNode("span", { "aria-hidden": "true" }, " →")
            ])
          ], -1))
        ])
      ])
    ]),
    _: 1
  }))
}