<template>
  <Head :title="$trans('main.profile.titles.main')"/>
  <ParentLayout>
    <main>
      <header>
        <nav class="border-gray-200 dark:border-gray-800 flex overflow-x-auto border-b py-4">
          <ul role="list" class="text-gray-500 dark:text-gray-400 flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 sm:px-6 lg:px-8">
            <li v-for="item in navigation">
              <Link :href="item.href" :class="{'text-primary-600': item.current}">
                {{ $trans(item.key) }}
              </Link>
            </li>
          </ul>
        </nav>
        <slot name="header"/>
      </header>
      <slot name="content"/>
    </main>
  </ParentLayout>
</template>
<script lang="ts">
import { default as ParentLayout } from '@js/Shared/Layout.vue';
import { Link, Head } from '@inertiajs/vue3';
import { isCurrentRoute } from '@/mixins';
import { defineComponent } from 'vue';
import route from '@/ziggy';

export default defineComponent({
  name: 'Layout',
  components: {
    ParentLayout,
    Link,
    Head
  },
  setup(props) {
    const navigation = [
      { 
        key: 'main.profile.navigation.general', 
        href: route('profile.edit'), 
        current: isCurrentRoute(['profile.edit']) 
      },
      // { key: 'main.sites.navigation.ips', href: '#', current: false },
      // { key: 'main.sites.navigation.contacts', href: '#', current: false },
      // { key: 'main.sites.navigation.healthbeats', href: '#', current: false },
      // { key: 'main.sites.navigation.referrals', href: '#', current: false },
      // { key: 'main.sites.navigation.subscribers', href: '#', current: false },
    ];

    return { navigation };
  }
})
</script>