<template>
  <div class="float-left mr-2 mb-4 max-w-full">
    <div class="text-gray-900 dark:text-gray-400 inline-flex items-center mb-2 text-sm font-semibold text-center">
      <TagIcon class="w-4 h-4 mr-1"/>
      {{ $trans('main.boards.titles.tags') }}
    </div>
    <div class="flex flex-wrap gap-1 items-center">
      <template v-for="tag in tags ?? []">
        <span v-if="tagExistsInBoard(tag.id)"
          :key="tag.id"
          @click="openMenu(tag)"
          :class="`bg-${tag.color}-600 dark:bg-${tag.color}-500`" 
          class="text-white cursor-pointer flex flex-wrap h-7 content-center justify-center rounded-md relative mb-0 px-3 max-w-full min-w-12 box-border leading-8 text-sm font-medium text-left overflow-hidden overflow-ellipsis whitespace-nowrap">
          {{ tag.title }}
        </span>
      </template>
      <Menu v-slot="{ close }">
        <div class="relative inline-block text-left">
          <MenuButton as="button" ref="menuButtonRef" class="bg-white dark:bg-gray-700 text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 border-gray-200 dark:border-gray-600 border font-semibold rounded-lg text-nano px-2 py-1 text-center inline-flex items-center">
            <PlusIcon class="w-4 h-4 stroke-2" :class="{'mr-1': !tags?.length}"/>
            <template v-if="!tags?.length" class="text-sm">
              {{ $trans('main.boards.buttons.add_tag') }}
            </template>
          </MenuButton>
          <MenuItems as="div" :class="mode === 'delete' ? 'w-64' : 'w-60'" class="bg-white dark:bg-gray-950 border-gray-200 dark:border-gray-750 divide-gray-100 dark:divide-gray-750 shadow-lg absolute left-0 mt-2 origin-top-right border z-50 block text-white font-sans text-sm leading-5 font-normal rounded-lg box-border outline-none overflow-hidden">
            <!-- Header -->
            <div class="items-center grid grid-cols-[32px_1fr_32px] p-[4px_8px] relative text-center">
              <button v-if="mode === 'create' || mode === 'edit' || mode === 'delete'" @click="mode === 'delete' ? mode = 'edit' : mode = null" class="text-gray-500 dark:text-gray-400 z-2 col-start-1 row-start-1 rounded-lg flex w-8 h-8 justify-center items-center bg-transparent border-none cursor-pointer m-0 p-0 outline-none">
                <ChevronLeftIcon class="w-4 h-4 stroke-2"/>
              </button>
              <h2 v-if="mode === 'create'" class="text-gray-500 dark:text-gray-400 z-1 text-sm col-[1_/_span_3] font-semibold tracking-tight leading-10 block relative h-10 m-0 overflow-hidden px-8 overflow-ellipsis whitespace-nowrap row-start-1">
                {{ $trans('main.boards.titles.tag_creating') }}
              </h2>
              <h2 v-else-if="mode === 'edit'" class="text-gray-500 dark:text-gray-400 z-1 text-sm col-[1_/_span_3] font-semibold tracking-tight leading-10 block relative h-10 m-0 overflow-hidden px-8 overflow-ellipsis whitespace-nowrap row-start-1">
                {{ $trans('main.boards.titles.tag_editing') }}
              </h2>
              <span v-else-if="mode === 'delete'" class="text-gray-500 dark:text-gray-400 z-1 box-border block text-sm col-[1_/_span_3] font-semibold row-start-1 h-10 leading-10 m-0 overflow-hidden px-8 relative overflow-ellipsis whitespace-nowrap">
                {{ $trans('main.boards.titles.tag_deleting') }}
              </span>
              <span v-else class="text-gray-500 dark:text-gray-400 z-1 box-border block text-sm col-[1_/_span_3] font-semibold row-start-1 h-10 leading-10 m-0 overflow-hidden px-8 relative overflow-ellipsis whitespace-nowrap">
                {{ $trans('main.boards.titles.tags') }}
              </span>
              <button @click="close()" class="z-2 text-gray-500 dark:text-gray-400 rounded-md col-start-3 row-start-1 p-1.5">
                <XMarkIcon class="w-4 h-4 stroke-2"/>
              </button>
            </div>
            <!-- Content -->
            <div class="block overflow-x-hidden overflow-y-auto py-0 px-3 pb-3">
              <div class="block max-h-96">
                <template v-if="mode == 'create' || mode == 'edit'">
                  <div class="bg-gray-100 dark:bg-zinc-950 m-0 -mx-3 p-8 block">
                    <span :class="`bg-${form?.b_t_color}-600 dark:bg-${form?.b_t_color}-500`" class="text-white inline-block relative mb-0 rounded px-3 max-w-full min-w-[48px] h-8 box-border leading-8 text-sm font-medium text-left overflow-hidden overflow-ellipsis whitespace-nowrap w-full">
                      {{ form.b_t_title }}
                    </span>
                  </div>
                  <p class="text-gray-750 dark:text-gray-400 text-nano font-semibold leading-4 mt-3">
                    {{ $trans('validation.attributes.b_t_title') }}
                  </p>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input v-model="form.b_t_title"
                      @change="form.validate('b_t_title')" 
                      @keydown="ignoreSpace"
                      :class="{
                        'border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700': !form.invalid('b_t_title'),
                        'border-red-300 dark:border-red-500 text-red-700 dark:text-red-500 focus:ring-red-500 focus:border-red-500 pr-10': form.invalid('b_t_title'),
                      }"
                      class="border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700 bg-gray-50 dark:bg-gray-850 dark:focus:ring-primary-500 dark:focus:border-primary-500 my-1 block w-full focus:outline-none sm:text-sm rounded-md"/>
                    <div v-if="form.invalid('b_t_title')" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon class="h-5 w-5 text-red-500" />
                    </div>
                  </div>
                  <p v-if="form.invalid('b_t_title')" class="mt-2 text-nano text-red-600 dark:text-red-500">
                    {{ form.errors['b_t_title'] }}
                  </p>
                  <p class="text-gray-750 dark:text-gray-400 text-nano font-semibold leading-4 mt-3">
                    {{ $trans('validation.attributes.b_t_color') }}
                  </p>
                  <div class="block">
                    <div class="grid grid-cols-5 gap-1 mb-2">
                      <div v-for="color in colors ?? []" @click="form.b_t_color = color" :class="{'border border-primary-500 dark:border-primary-600': form.b_t_color == color}" class="relative rounded border-2 h-8 block border-transparent">
                        <div :class="`bg-${color}-600 dark:bg-${color}-500 ${form.b_t_color == color ? 'selected-color' : ''}`" class="relative rounded w-full h-full cursor-pointer block">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-row">
                    <button @click="submit()" :disabled="form.invalid('b_t_title') || form.invalid('b_t_color')" class="text-gray-500 dark:text-gray-400 w-full text-sm leading-5 font-medium box-border inline-flex items-center justify-center rounded cursor-pointer p-1.5 no-underline whitespace-normal shadow-none border-none">
                      {{ mode === 'create' ? $trans('main.boards.buttons.create_tag') : $trans('main.boards.buttons.update_tag') }}
                    </button>
                    <button v-if="mode === 'edit'" type="button" @click="setMode('delete')" class="text-red-500 dark:text-red-400 w-full text-sm leading-5 font-medium box-border inline-flex items-center justify-center rounded cursor-pointer p-1.5 no-underline whitespace-normal shadow-none border-none">
                      {{ $trans('main.boards.buttons.delete_tag') }}
                    </button>
                  </div>
                </template>
                <template v-if="mode === 'delete'">
                  <span class="mb-1.5 break-word block w-max leading-relaxed" v-html="$trans('main.boards.descriptions.delete_tag')"></span>
                  <Button :text="$trans('main.shared.buttons.delete')" @click="destroyTag" size="sm" classes="w-full justify-center" type="button" color="red"/>
                </template>
                <template v-if="mode === null">
                  <input v-model="search" @keydown="ignoreSpace" class="border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700 bg-gray-50 dark:bg-gray-850 dark:focus:ring-primary-500 dark:focus:border-primary-500 my-1 block w-full pr-10 focus:outline-none text-xs rounded-md"/>
                  <p v-if="!searchBoardTags.length" class="bg-gray-100 dark:bg-gray-900 text-gray-600 dark:text-white rounded m-0 p-[24px_6px] text-center">
                    {{ $trans('main.boards.no_results') }}
                  </p>
                  <p v-if="searchBoardTags.length" class="text-gray-750 dark:text-gray-400 text-nano font-semibold leading-4 mt-3">
                    {{ $trans('main.boards.titles.tags') }}
                  </p>
                  <ul class="pt-1 pb-2">
                    <li v-for="tag in searchBoardTags ?? []" :key="tag.id" class="pb-1 last:pb-0">
                      <label class="box-border m-0 p-[0_0_1px_1px] w-full cursor-pointer relative inline-flex items-center text-[var(--ds-text-subtle,#44546f)] text-nano font-bold leading-4">
                        <input :checked="tagIsSelected(tag)"
                          @change="toggleTag(tag)"
                          type="checkbox" 
                          class="relative flex-shrink-0 overflow-hidden whitespace-nowrap transition-all duration-200 ease-in-out w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 dark:ring-offset-gray-800 dark:bg-gray-850 dark:border-gray-750">
                        <span class="flex-grow ml-3 min-w-0">
                          <div class="mb-0 flex items-center flex-grow gap-1 min-w-0">
                            <span :class="`bg-${tag.color}-600 dark:bg-${tag.color}-500`" class="w-full inline-block relative mb-0 rounded px-3 max-w-full min-w-[48px] h-8 box-border leading-8 text-white text-sm font-medium text-left overflow-hidden overflow-ellipsis whitespace-nowrap">
                              {{ tag.title }}
                            </span>
                            <button @click="setMode('edit',tag)" class="flex-shrink-0 mb-0 p-2 bg-transparent border-none shadow-none text-sm leading-5 box-border inline-flex items-center justify-center rounded cursor-pointer no-underline whitespace-normal font-medium transition-colors duration-75 ease-in">
                              <PencilIcon class="w-4 h-4 stroke-2 text-gray-750 dark:text-gray-400"/>
                            </button>
                          </div>
                        </span>
                      </label>
                    </li>
                  </ul>
                </template>
                <button v-if="mode == null" @click="setMode('create')" :disabled="form.invalid('b_t_title') || form.invalid('b_t_color')" class="text-gray-500 dark:text-gray-400 w-full text-sm leading-5 font-medium box-border inline-flex items-center justify-center rounded cursor-pointer p-1.5 no-underline whitespace-normal shadow-none border-none">
                  {{ $trans('main.boards.buttons.create_tag') }}
                </button>
              </div>
            </div>
          </MenuItems>
        </div>
      </Menu>
    </div>
  </div>
</template>
<style scoped>
.selected-color::after {
  @apply border-white dark:border-gray-950 top-0 right-0 bottom-0 left-0 border-2 rounded content-[''] absolute pointer-events-none;
}
</style>
<script lang="ts">
import { XMarkIcon, PlusIcon, ChevronLeftIcon, ExclamationCircleIcon, PencilIcon, TagIcon } from '@heroicons/vue/24/outline';
import { defineComponent, ref, computed, toRef, watch, shallowRef } from 'vue';
import { default as BaseBoard } from '@/Types/Resources/Board/Base';
import { default as LightCard } from '@/Types/Resources/Card/Light';
import { default as LightTag } from '@/Types/Resources/Tag/Light';
import { ignoreSpace, sendRequest, getApiToken } from '@/mixins';
import { Menu, MenuButton, MenuItems } from '@headlessui/vue';
import { PayloadResponse } from '@/Types/API/PayloadResponse';
import { useForm } from 'laravel-precognition-vue-inertia';
import Tag from '@/Types/Resources/Tag/Light';
import Button from '@/Shared/Button.vue';
import route from '@/ziggy';

export default defineComponent({
  name: 'TagsMenu',
  components: {
    ExclamationCircleIcon,
    ChevronLeftIcon,
    MenuButton,
    PencilIcon,
    XMarkIcon,
    MenuItems,
    PlusIcon,
    TagIcon,
    Button,
    Menu,
  },
  emits: [
    'update-board',
    'update-card'
  ],
  props: {
    colors: {
      type: Array as () => string[],
      required: true
    },
    board: {
      type: Object as () => BaseBoard|null,
      default: null
    },
    card: {
      type: Object as () => LightCard|null,
      default: null
    },
  },
  setup(props, { emit }) {
    const menuButtonRef = ref<typeof MenuButton|null>(null);
    const mode = ref<'create'|'edit'|'delete'|null>(null);
    const editTag = ref<Tag|null>(null);
    const propsBoard = toRef(props, 'board');
    const propsCard = toRef(props, 'card');
    const board = shallowRef<BaseBoard|null>(propsBoard.value);
    const card = shallowRef<LightCard|null>(propsCard.value);
    const tags = ref<Array<Tag>>(card.value?.tags ?? []);
    const search = ref<string>('');
    const searchBoardTags = computed<Array<Tag>>(() => {
      if(search.value === '') {
        return board.value?.tags ?? [];
      }else{
        return board.value?.tags.filter((tag) => {
          return tag.title.toLowerCase().includes(search.value.toLowerCase());
        }) ?? [];
      }
    });
    const form = useForm(() => mode.value === 'create' ? 'post' : 'put', () => mode.value === 'create' 
      ? route('api.v1.tags.store', { 
        board: board.value?.id, 
        card: card.value?.id
      }) : route('api.v1.tags.update', { 
        card: props.card?.id,
        tag: editTag.value?.id
      }), {
        _token: getApiToken(),
        b_t_title: "",
        b_t_color: ""
    });

    watch(() => propsCard.value, (newCard) => {
      card.value = newCard;
      tags.value = newCard?.tags ?? [];
    });

    watch(() => propsBoard.value, (newBoard) => {
      board.value = newBoard;
    });

    function handleUpdateCard(newCard: LightCard): void
    {
      tags.value = newCard.tags;
      card.value = newCard;
      
      emit('update-card', newCard);
    }

    function handleUpdateBoard(newBoard: BaseBoard): void
    {
      board.value = newBoard;

      emit('update-board', newBoard);
    }

    function openMenu(tag: Tag|null): void
    {
      if(mode.value === 'edit' && tag !== null){
        setMode('edit', tag);
      }

      menuButtonRef.value?.$el?.click();
    }

    function setMode(newMode: null|'create'|'edit'|'delete', tag: Tag|null = null): void
    {
      mode.value = newMode;

      if(mode.value === 'create') {
        form.b_t_title = '';
        form.b_t_color = props.colors.length ? props.colors[0] : '';
      }
      
      if(mode.value === 'edit') {
        editTag.value = tag;

        form.reset();
        form.clearErrors();
        form.b_t_title = editTag.value?.title ?? '';
        form.b_t_color = editTag.value?.color ?? '';
      }
    }

    function fillData(data: PayloadResponse<{card: LightCard, board: BaseBoard}>): void
    {
      handleUpdateBoard(data.payload.board);
      handleUpdateCard(data.payload.card);

      mode.value = null;
      form.reset();
      form.clearErrors();
    }

    function toggleTag(tag: Tag): void
    {
      sendRequest({
        name: `api.v1.boards.cards.${tagIsSelected(tag) ? 'unassign' : 'assign'}.tag`,
        params: {
          card: propsCard?.value?.id,
          tag: tag.id 
        },
      }, (data: PayloadResponse<LightCard>) => handleUpdateCard(data.payload));
    }

    function tagExistsInBoard(tagId: string): boolean
    {
      return board.value?.tags.find((t) => t.id === tagId) ? true : false;
    }

    function tagIsSelected(tag: Tag): boolean
    {
      return card.value?.tags.find((t) => t.id === tag.id) ? true : false;
    }

    function destroyTag(): void
    {
      if(editTag.value?.id === null) return;
      
      sendRequest({
        name: 'api.v1.tags.destroy',
        params: {
          board: editTag.value?.board_id,
          card: card.value?.id,
          tag: editTag.value?.id
        }
      }, (data: PayloadResponse<{board_tags: Array<LightTag>, card_tags: Array<LightTag>}>) => {
        if(board.value){
          handleUpdateBoard({
            ...board.value,
            tags: data.payload.board_tags
          });
        }

        tags.value = data.payload.card_tags;

        mode.value = null;
        form.reset();
        form.clearErrors();
      });
    }

    function submit(): void
    {
      if(mode.value == 'create'){
        sendRequest({
          name: 'api.v1.tags.store',
          params: {
            board: board?.value?.id,
            card: card?.value?.id
          },
          data: {
            b_t_title: form.b_t_title,
            b_t_color: form.b_t_color
          }
        }, (data: PayloadResponse<{card: LightCard, board: BaseBoard}>) => fillData(data));
      }else{
        sendRequest({
          name: 'api.v1.tags.update',
          params: {
            card: card?.value?.id,
            tag: editTag.value?.id
          },
          data: {
            b_t_title: form.b_t_title,
            b_t_color: form.b_t_color
          }
        }, (data: PayloadResponse<{card: LightCard, board: BaseBoard}>) => fillData(data));
      }
    }

    return {
      tagExistsInBoard,
      searchBoardTags,
      tagIsSelected,
      menuButtonRef,
      ignoreSpace,
      destroyTag,
      toggleTag,
      openMenu,
      setMode,
      submit,
      search,
      mode,
      tags,
      form
    };
  },
});
</script>