import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Link = _resolveComponent("Link")!

  return (_openBlock(), _createBlock(_component_Link, {
    as: "div",
    href: _ctx.route('main')
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        src: "/dist/images/logo-text.svg",
        class: _normalizeClass(["h-4 w-4 min-w-20", [..._ctx.logoColor]]),
        alt: "Atlant Logo"
      }, null, 2)
    ]),
    _: 1
  }, 8, ["href"]))
}