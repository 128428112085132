import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "justify-between group items-center flex min-h-8 m-0 mb-2 relative" }
const _hoisted_2 = {
  key: 0,
  class: "float-left flex flex-row mr-0.5"
}
const _hoisted_3 = { class: "text-gray-600 dark:text-gray-400 block" }
const _hoisted_4 = { class: "text-gray-600 dark:text-gray-400 inline-flex items-center text-md font-semibold text-start cursor-pointer" }
const _hoisted_5 = {
  key: 1,
  class: "text-gray-600 dark:text-gray-400 inline-flex items-center mb-2 text-md font-semibold text-center list-no-drag"
}
const _hoisted_6 = ["id", "onFocusout"]
const _hoisted_7 = { class: "float-right mr-0.5 block space-y-1" }
const _hoisted_8 = { class: "ml-4 block" }
const _hoisted_9 = { class: "text-gray-600 dark:text-gray-400 flex justify-end font-bold text-nano leading-[0.625rem] absolute text-center w-8" }
const _hoisted_10 = { class: "rounded-md clear-both m-0 ml-10 overflow-hidden relative block" }
const _hoisted_11 = { class: "w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700" }
const _hoisted_12 = {
  key: 0,
  class: "ml-14 mt-1 list-no-drag"
}
const _hoisted_13 = { class: "flex flex-row mt-2" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "cursor-pointer absolute left-2 m-1.5 text-center" }
const _hoisted_17 = ["disabled", "checked"]
const _hoisted_18 = { class: "cursor-pointer break-words rounded-lg m-0 overflow-hidden py-2" }
const _hoisted_19 = { class: "flex flex-row break-words" }
const _hoisted_20 = {
  key: 0,
  class: "w-full px-1 check-no-drag"
}
const _hoisted_21 = { class: "flex flex-row mt-2" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = {
  key: 1,
  class: "inline-flex w-full break-words select-none"
}
const _hoisted_24 = { class: "inline-flex flex-row float-right" }
const _hoisted_25 = { class: "md:hidden group-hover:md:inline-flex" }
const _hoisted_26 = { role: "none" }
const _hoisted_27 = ["onClick"]
const _hoisted_28 = ["onClick"]
const _hoisted_29 = { class: "flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" }
const _hoisted_30 = { class: "bg-white dark:bg-gray-900 text-left relative inline-block text-base font-normal leading-5 rounded-lg overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-md w-full" }
const _hoisted_31 = { class: "flex items-center justify-between p-4 border-b rounded-t md:px-6 dark:border-gray-800" }
const _hoisted_32 = { class: "text-xl font-semibold dark:text-white" }
const _hoisted_33 = { class: "p-4 space-y-6 md:px-6" }
const _hoisted_34 = { class: "grid grid-cols-2 gap-6 mb-4" }
const _hoisted_35 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_36 = { class: "flex items-center p-4 space-x-3 border-t border-gray-200 rounded-b md:p-6 dark:border-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckCircleIcon = _resolveComponent("CheckCircleIcon")!
  const _component_ButtonSecondary = _resolveComponent("ButtonSecondary")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_EllipsisHorizontalCircleIcon = _resolveComponent("EllipsisHorizontalCircleIcon")!
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_VueDraggableNext = _resolveComponent("VueDraggableNext")!
  const _component_Attempt = _resolveComponent("Attempt")!
  const _component_DialogOverlay = _resolveComponent("DialogOverlay")!
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_VueDraggableNext, {
      emptyInsertThreshold: _ctx.listDragOptions.emptyInsertThreshold,
      preventOnFilter: _ctx.listDragOptions.preventOnFilter,
      forceFallback: _ctx.listDragOptions.forceFallback,
      ghostClass: _ctx.listDragOptions.ghostClass,
      animation: _ctx.listDragOptions.animation,
      handle: `.${_ctx.listDragOptions.handle}`,
      itemKey: _ctx.listDragOptions.itemKey,
      filter: _ctx.listDragOptions.filter,
      onChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onListPositionChange())),
      delay: _ctx.listDragOptions.delay,
      group: _ctx.listDragOptions.group,
      onStart: _cache[10] || (_cache[10] = ($event: any) => (_ctx.onListDragStart())),
      onEnd: _cache[11] || (_cache[11] = ($event: any) => (_ctx.onListDragEnd())),
      list: _ctx.lists
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lists, (list) => {
          return (_openBlock(), _createElementBlock("div", {
            key: list.id,
            class: _normalizeClass(_ctx.listDragOptions.handle, _ctx.checkDragOptions.handle)
          }, [
            _createElementVNode("div", _hoisted_1, [
              (_ctx.listMode == null || _ctx.listMode == 'create' || (_ctx.listMode == 'edit' && _ctx.editList?.id != list.id))
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_CheckCircleIcon, { class: "w-5 h-5 mr-1 stroke-2" })
                    ]),
                    _createElementVNode("h3", _hoisted_4, _toDisplayString(list.name), 1)
                  ]))
                : (_ctx.listMode === 'edit' && _ctx.editList?.id == list.id)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_CheckCircleIcon, { class: "w-5 h-5 mr-1 stroke-2" }),
                      _withDirectives(_createElementVNode("textarea", {
                        id: `edit-list-area-${list.id}`,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editListForm.b_c_c_c_name) = $event)),
                        onFocusout: ($event: any) => (_ctx.onEditListFormBlur(list)),
                        class: "static content-center top-0 right-0 bottom-0 left-0 bg-transparent rounded-md shadow-none box-border font-semibold m-0 min-h-5 overflow-hidden break-words py-1.5 pr-2 pl-3 resize-none h-8"
                      }, null, 40, _hoisted_6), [
                        [_vModelText, _ctx.editListForm.b_c_c_c_name]
                      ])
                    ]))
                  : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_7, [
                (_ctx.checkMode != 'create' || _ctx.createList?.id != list.id)
                  ? (_openBlock(), _createBlock(_component_ButtonSecondary, {
                      key: 0,
                      classes: "mr-1 ",
                      onClick: ($event: any) => (_ctx.openCheckCreateForm(list)),
                      text: _ctx.$trans('main.boards.buttons.add_new_check'),
                      size: "sm"
                    }, null, 8, ["onClick", "text"]))
                  : _createCommentVNode("", true),
                (_ctx.listMode != 'edit' || _ctx.editList?.id != list.id)
                  ? (_openBlock(), _createBlock(_component_ButtonSecondary, {
                      key: 1,
                      classes: "mr-1 ",
                      onClick: ($event: any) => (_ctx.openListEditForm(list)),
                      text: _ctx.$trans('main.shared.buttons.edit'),
                      size: "sm"
                    }, null, 8, ["onClick", "text"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_ButtonSecondary, {
                  onClick: ($event: any) => (_ctx.toggleDeleteChecklistModal(true, list.id)),
                  classes: "mr-1 ",
                  text: _ctx.$trans('main.shared.buttons.delete'),
                  size: "sm"
                }, null, 8, ["onClick", "text"])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.isCompletedPercentage(list)) + "% ", 1),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", {
                    class: "bg-blue-600 transition-width transition-background duration-150 ease-in h-2.5 rounded-full",
                    style: _normalizeStyle(`width: ${_ctx.isCompletedPercentage(list)}%`)
                  }, null, 4)
                ])
              ])
            ]),
            (_ctx.checkMode == 'create' && _ctx.createList?.id == list.id)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createVNode(_component_TextArea, {
                    id: `create-check-area-${list.id}`,
                    rows: 2,
                    name: "b_c_c_c_c_content",
                    required: "",
                    "with-error": true,
                    "without-label": true,
                    form: _ctx.createCheckForm,
                    modelValue: _ctx.createCheckForm.b_c_c_c_c_content,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.createCheckForm.b_c_c_c_c_content) = $event))
                  }, null, 8, ["id", "form", "modelValue"]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("button", {
                      onClick: ($event: any) => (_ctx.submitCheckCreateForm(list)),
                      class: "bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-600 text-black dark:text-gray-300 my-0 ml-0 mr-1 items-center border-none rounded shadow-none box-border cursor-pointer inline-flex text-sm font-medium justify-center leading-5 p-1.5 no-underline transition-colors duration-75 ease-in whitespace-normal"
                    }, _toDisplayString(_ctx.$trans('main.shared.buttons.create')), 9, _hoisted_14),
                    _createElementVNode("button", {
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeCheckCreateForm && _ctx.closeCheckCreateForm(...args))),
                      class: "bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-600 text-black dark:text-gray-300 m-0 items-center border-none rounded shadow-none box-border cursor-pointer inline-flex text-sm font-medium justify-center leading-5 p-1.5 no-underline transition-colors duration-75 ease-in whitespace-normal"
                    }, _toDisplayString(_ctx.$trans('main.shared.buttons.hide')), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_VueDraggableNext, {
              class: "ml-2 mt-1 transition-height transition-padding transition-margin transition-colors duration-150 ease-in",
              emptyInsertThreshold: _ctx.checkDragOptions.emptyInsertThreshold,
              preventOnFilter: _ctx.checkDragOptions.preventOnFilter,
              forceFallback: _ctx.checkDragOptions.forceFallback,
              ghostClass: _ctx.checkDragOptions.ghostClass,
              onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onCheckPositionChange($event))),
              animation: _ctx.checkDragOptions.animation,
              handle: `.${_ctx.checkDragOptions.handle}`,
              itemKey: _ctx.checkDragOptions.itemKey,
              filter: _ctx.checkDragOptions.filter,
              group: _ctx.checkDragOptions.group,
              delay: _ctx.checkDragOptions.delay,
              onStart: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onCheckDragStart())),
              onEnd: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onCheckDragEnd())),
              list: list.checks
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.checks, (check) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: check.id,
                    onClick: ($event: any) => (_ctx.handleCheckClick($event, check)),
                    class: _normalizeClass(["block relative rounded-lg box-border clear-both pl-10 my-1 origin-left-bottom", [_ctx.checkMode != 'edit' && _ctx.editCheck?.id != check.id ? 'group hover:bg-gray-100 dark:hover:bg-gray-950' : '', _ctx.checkDragOptions.handle]])
                  }, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("input", {
                        type: "checkbox",
                        disabled: _ctx.checkIsDragging,
                        checked: check.is_completed,
                        class: "bg-gray-50 dark:bg-gray-850 border-gray-300 dark:border-gray-750 focus:ring-primary-300 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-3 rounded cursor-pointer h-4 w-4 appearance-[checkbox] inline box-border p-0"
                      }, null, 8, _hoisted_17)
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("div", _hoisted_19, [
                        (_ctx.checkMode === 'edit' && _ctx.editCheck?.id == check.id)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                              _createVNode(_component_TextArea, {
                                id: `edit-check-area-${check.id}`,
                                rows: 2,
                                name: "b_c_c_c_c_content",
                                required: "",
                                "with-error": true,
                                "without-label": true,
                                form: _ctx.editCheckForm,
                                modelValue: _ctx.editCheckForm.b_c_c_c_c_content,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editCheckForm.b_c_c_c_c_content) = $event))
                              }, null, 8, ["id", "form", "modelValue"]),
                              _createElementVNode("div", _hoisted_21, [
                                _createElementVNode("button", {
                                  onClick: ($event: any) => (_ctx.submitCheckEditForm(check)),
                                  class: "bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-600 text-black dark:text-gray-300 my-0 ml-0 mr-1 items-center border-none rounded shadow-none box-border cursor-pointer inline-flex text-sm font-medium justify-center leading-5 p-1.5 no-underline transition-colors duration-75 ease-in whitespace-normal"
                                }, _toDisplayString(_ctx.$trans('main.shared.buttons.update')), 9, _hoisted_22),
                                _createElementVNode("button", {
                                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.closeCheckEditForm && _ctx.closeCheckEditForm(...args))),
                                  class: "bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-600 text-black dark:text-gray-300 m-0 items-center border-none rounded shadow-none box-border cursor-pointer inline-flex text-sm font-medium justify-center leading-5 p-1.5 no-underline transition-colors duration-75 ease-in whitespace-normal"
                                }, _toDisplayString(_ctx.$trans('main.shared.buttons.hide')), 1)
                              ])
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_23, [
                              _createElementVNode("span", {
                                class: _normalizeClass([{'line-through': check.is_completed}, "text-gray-600 dark:text-gray-400 self-center flex-1 mb-0 min-h-5 break-words cursor-pointer"])
                              }, _toDisplayString(check.content), 3),
                              (_openBlock(), _createBlock(_component_Menu, {
                                as: "div",
                                key: check.id,
                                class: "z-5 group-hover:grid gap-x-1 hidden flex-[0_0_auto] grid-cols-[1fr_max-content] min-h-5 px-4 pb-0"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_24, [
                                    _createElementVNode("div", _hoisted_25, [
                                      _createVNode(_component_MenuButton, {
                                        onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_EllipsisHorizontalCircleIcon, { class: "h-4 w-4 check-no-drag" })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _createVNode(_Transition, {
                                      "enter-active-class": "transition ease-out duration-100",
                                      "enter-from-class": "transform opacity-0 scale-95",
                                      "enter-to-class": "transform opacity-100 scale-100",
                                      "leave-active-class": "transition ease-in duration-75",
                                      "leave-from-class": "transform opacity-100 scale-100",
                                      "leave-to-class": "transform opacity-0 scale-95"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_MenuItems, { class: "bg-white dark:bg-gray-850 border-gray-200 dark:border-gray-750 divide-gray-100 dark:divide-gray-750 hidden group-hover:block check-no-drag border absolute right-0 mt-6 origin-top-right max-w-32 w-auto text-sm list-none rounded shadow-sm" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("ul", _hoisted_26, [
                                              _createElementVNode("li", {
                                                onClick: _withModifiers(($event: any) => (_ctx.openCheckEditForm(check)), ["stop"]),
                                                class: "text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-white column-no-drag cursor-pointer select-none px-2 py-1"
                                              }, _toDisplayString(_ctx.$trans('main.shared.buttons.edit')), 9, _hoisted_27),
                                              _createElementVNode("li", {
                                                onClick: _withModifiers(($event: any) => (_ctx.deleteCheck(list, check)), ["stop"]),
                                                class: "text-red-700 dark:hover:text-white dark:hover:bg-red-800 hover:bg-red-100 column-no-drag cursor-pointer select-none px-2 py-1 rounded"
                                              }, _toDisplayString(_ctx.$trans('main.shared.buttons.delete')), 9, _hoisted_28)
                                            ])
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ])
                                ]),
                                _: 2
                              }, 1024))
                            ]))
                      ])
                    ])
                  ], 10, _hoisted_15))
                }), 128))
              ]),
              _: 2
            }, 1032, ["emptyInsertThreshold", "preventOnFilter", "forceFallback", "ghostClass", "animation", "handle", "itemKey", "filter", "group", "delay", "list"])
          ], 2))
        }), 128))
      ]),
      _: 1
    }, 8, ["emptyInsertThreshold", "preventOnFilter", "forceFallback", "ghostClass", "animation", "handle", "itemKey", "filter", "delay", "group", "list"]),
    _createVNode(_component_Attempt, {
      open: _ctx.isDeleteChecklistAttemptVisible,
      close: (value) => _ctx.isDeleteChecklistAttemptVisible = value,
      "is-api": true,
      routeName: "api.v1.boards.cards.checklists.destroy",
      sendCallback: _ctx.deleteList,
      "delete-callback": _ctx.deleteList,
      routeArgument: { checklist: _ctx.idOfChecklistToDelete },
      title: _ctx.$trans('main.attempts.delete_board_card_checklist.title'),
      description: _ctx.$trans('main.attempts.delete_board_card_checklist.description'),
      successButtonText: _ctx.$trans('main.attempts.buttons.success_button'),
      cancelButtonText: _ctx.$trans('main.attempts.buttons.cancel_button')
    }, null, 8, ["open", "close", "sendCallback", "delete-callback", "routeArgument", "title", "description", "successButtonText", "cancelButtonText"]),
    _createVNode(_component_TransitionRoot, {
      as: "template",
      show: _ctx.checklistModalIsOpen
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Dialog, {
          as: "div",
          class: "fixed z-50 inset-0 overflow-y-auto",
          onClose: _cache[16] || (_cache[16] = ($event: any) => (_ctx.handleCloseCreateListModal()))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_29, [
              _createVNode(_component_TransitionChild, {
                as: "template",
                enter: "ease-out duration-300",
                "enter-from": "opacity-0",
                "enter-to": "opacity-100",
                leave: "ease-in duration-200",
                "leave-from": "opacity-100",
                "leave-to": "opacity-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DialogOverlay, { class: "fixed inset-0 bg-black bg-opacity-50" })
                ]),
                _: 1
              }),
              _cache[18] || (_cache[18] = _createElementVNode("span", {
                class: "hidden sm:inline-block sm:align-middle sm:h-screen",
                "aria-hidden": "true"
              }, "​", -1)),
              _createVNode(_component_TransitionChild, {
                as: "template",
                enter: "ease-out duration-300",
                "enter-from": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
                "enter-to": "opacity-100 translate-y-0 sm:scale-100",
                leave: "ease-in duration-200",
                "leave-from": "opacity-100 translate-y-0 sm:scale-100",
                "leave-to": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("form", {
                      onSubmit: _cache[15] || (_cache[15] = _withModifiers(($event: any) => (_ctx.submitCreateListForm()), ["prevent"])),
                      class: "relative rounded-lg shadow"
                    }, [
                      _createElementVNode("div", _hoisted_31, [
                        _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.$trans('main.boards.titles.add_new_checlist')), 1),
                        _createElementVNode("button", {
                          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.handleCloseCreateListModal())),
                          type: "button",
                          class: "text-gray-400 bg-transparent hover:bg-gray-300 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                        }, _cache[17] || (_cache[17] = [
                          _createElementVNode("svg", {
                            class: "w-5 h-5",
                            fill: "currentColor",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, [
                            _createElementVNode("path", {
                              "fill-rule": "evenodd",
                              d: "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z",
                              "clip-rule": "evenodd"
                            })
                          ], -1)
                        ]))
                      ]),
                      _createElementVNode("div", _hoisted_33, [
                        _createElementVNode("div", _hoisted_34, [
                          _createElementVNode("div", _hoisted_35, [
                            _createVNode(_component_Input, {
                              id: "b_c_c_c_name",
                              type: "text",
                              name: "b_c_c_c_name",
                              label: "validation.attributes.b_c_c_c_name",
                              autocomplete: "on",
                              modelValue: _ctx.createListForm.b_c_c_c_name,
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.createListForm.b_c_c_c_name) = $event)),
                              required: true,
                              form: _ctx.createListForm as any
                            }, null, 8, ["modelValue", "form"])
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_36, [
                        _createVNode(_component_Button, {
                          text: _ctx.$trans('main.boards.buttons.add_checklist'),
                          loading: _ctx.createListForm.processing,
                          cursorNotAllowed: !_ctx.createListForm.isDirty,
                          disabled: _ctx.createListForm.invalid('b_c_c_c_name')
                        }, null, 8, ["text", "loading", "cursorNotAllowed", "disabled"]),
                        _createElementVNode("button", {
                          type: "button",
                          onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.handleCloseCreateListModal())),
                          class: "w-24 text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 hover:border-gray-300 font-semibold rounded-lg text-sm py-2.5 text-center dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:bg-gray-600"
                        }, _toDisplayString(_ctx.$trans('main.shared.buttons.cancel')), 1)
                      ])
                    ], 32)
                  ])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}