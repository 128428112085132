<template>
  <Head :title="$trans('table.reports.title')"/>
  <Layout :body-classes="['bg-white', 'dark:bg-gray-900', 'h-full']"
          :main-classes="['bg-white', 'dark:bg-gray-900']">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="mt-8 flow-root">
        <div class="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 overflow-x-scroll">
          <div class="inline-block min-w-full py-2 align-middle">
            <table class="min-w-full border-separate border-spacing-0">
              <thead>
                <tr>
                  <th v-html="$trans('table.reports.head.html.id')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:table-cell sm:pl-6 lg:pl-8"></th>
                  <th v-html="$trans('table.reports.head.html.type')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"></th>
                  <th v-html="$trans('table.reports.head.html.user')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"></th>
                  <th v-html="$trans('table.reports.head.html.ip')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"></th>
                  <th v-html="$trans('table.reports.head.html.success')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"></th>
                  <th v-html="$trans('table.reports.head.html.text')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"></th>
                  <th v-html="$trans('table.users.head.html.created_at')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold lg:table-cell"></th>
                  <th scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(report, reportIdx) in reports?.data" :key="report.id">
                  <td :class="[reportIdx !== reports.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 lg:pl-8']">
                    {{ report.id }}
                  </td>
                  <td :class="[reportIdx !== reports.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 text-center whitespace-nowrap uppercase py-4 px-4 text-sm font-medium']">
                    {{ report.type }}
                  </td>
                  <td :class="[reportIdx !== reports.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 text-center whitespace-nowrap py-4 px-4 text-sm font-medium']">
                    {{ report?.user_name ?? $trans('table.shared.no_data') }}
                  </td>
                  <td :class="[reportIdx !== reports.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 text-center whitespace-nowrap py-4 px-4 text-sm font-medium']">
                    {{ report.ip_address ?? $trans('table.shared.no_data')  }}
                  </td>
                  <td :class="[reportIdx !== reports.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500']">
                    <Badge v-if="report.success_type != null" :type="report.success_type" :text="report.is_success"/>
                  </td>
                  <td :class="[reportIdx !== reports.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 lg:pl-8']">
                    {{ report.text ?? $trans('table.shared.no_data')  }}
                  </td>
                  <td :class="[reportIdx !== reports.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 lg:pl-8']">
                    {{ report.created_at ?? $trans('table.shared.no_data') }}
                  </td>
                  <td :class="[reportIdx !== reports.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 relative whitespace-nowrap py-4 px-2 text-right text-sm font-medium sm:pr-8 lg:pr-8']">
                    <span @click="show(report)" class="text-primary-600 dark:text-primary-800 hover:text-primary-900 dark:hover:text-primary-700 cursor-pointer">
                      {{$trans('table.shared.show')}}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="!reports?.data?.length" class="flex items-center justify-center my-48">
              <h2 class="text-gray-400 dark:text-gray-300 box-border block text-2xl leading-5 relative text-center">
                {{ $trans('table.reports.placeholder') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Pagination v-if="reports?.data?.length" :data="reports"/>
  </Layout>
  <Show :report="showedReport"
    :isOpen="drawerOpen" 
    :onClose="close"/>
</template>
<script lang="ts">
import { default as ReportList } from '@/Types/Resources/Report/List';
import Breadcrumb from '@js/Shared/Breadcrumb.vue';
import Pagination from '@js/Shared/Pagination.vue';
import Paginator from '@/Types/App/Paginator';
import Show from '@js/Pages/Reports/Show.vue';
import { Head, Link } from '@inertiajs/vue3';
import Layout from '@js/Shared/Layout.vue';
import { defineComponent, ref } from "vue";
import Badge from '@js/Shared/Badge.vue';
import Icon from '@js/Shared/Icon.vue';

export default defineComponent({
  props: {
    reports: {
      type: Object as () => Paginator<ReportList>,
      required: true,
    } 
  },
  components: {
    Pagination,
    Breadcrumb,
    Layout,
    Badge,
    Icon,
    Link,
    Show,
    Head,
  },
  setup() {
    const drawerOpen = ref<Boolean>(false);
    const showedReport = ref<ReportList | null>(null);

    function show(log: ReportList): void
    {
      showedReport.value = log;
      drawerOpen.value = true;
    }

    function close(): void
    {
      drawerOpen.value = false;

      setTimeout(() => {
        showedReport.value = null;
      }, 500);
    }

    return {
      showedReport,
      drawerOpen,
      close,
      show,
    }
  },
})
</script>