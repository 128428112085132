import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "block truncate" }
const _hoisted_3 = { class: "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" }
const _hoisted_4 = ["id", "name", "accept"]
const _hoisted_5 = {
  key: 0,
  class: "mt-2 text-sm text-red-600 dark:text-red-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloudArrowUpIcon = _resolveComponent("CloudArrowUpIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: "block text-sm font-medium text-gray-700 dark:text-white"
    }, _toDisplayString(_ctx.$trans(_ctx.label)), 9, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.divClass, 'cursor-pointer']),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.triggerFileInput && _ctx.triggerFileInput(...args))),
      tabindex: 0,
      onKeydown: [
        _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.triggerFileInput && _ctx.triggerFileInput(...args)), ["enter"])),
        _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.triggerFileInput && _ctx.triggerFileInput(...args)), ["space"]))
      ]
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["bg-gray-50 dark:bg-gray-850 border rounded-md px-3 py-2 shadow-sm focus:outline-none focus:ring-1 sm:text-sm", {
          'border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700': !_ctx.form.invalid(_ctx.name),
          'border-red-300 dark:border-red-500 text-red-700 dark:text-red-500 focus:ring-red-500 focus:border-red-500': _ctx.form.invalid(_ctx.name),
        }])
      }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.fileName || _ctx.$trans(_ctx.placeholder)), 1)
      ], 2),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_CloudArrowUpIcon, {
          class: "h-5 w-5 text-gray-400",
          "aria-hidden": "true"
        })
      ]),
      _createElementVNode("input", {
        type: "file",
        id: _ctx.id,
        ref: "fileInput",
        name: _ctx.name,
        accept: _ctx.accept,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleFileChange && _ctx.handleFileChange(...args))),
        class: "hidden"
      }, null, 40, _hoisted_4)
    ], 34),
    (_ctx.form.invalid(_ctx.name))
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.form.errors[_ctx.name]), 1))
      : _createCommentVNode("", true)
  ]))
}