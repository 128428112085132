import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "relative rounded-md shadow-sm" }
const _hoisted_3 = ["id", "required", "rows"]
const _hoisted_4 = {
  key: 0,
  class: "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
}
const _hoisted_5 = {
  key: 1,
  class: "text-red-600 dark:text-red-500 mt-2 text-sm"
}
const _hoisted_6 = { class: "font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.label && _ctx.withoutLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          class: "block text-sm font-medium text-gray-700 dark:text-white"
        }, _toDisplayString(_ctx.$trans(_ctx.label)), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("textarea", {
        id: _ctx.id,
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.form.validate(_ctx.name))),
        onFocusout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.form.validate(_ctx.name))),
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model) = $event)),
        required: _ctx.required,
        rows: _ctx.rows,
        class: _normalizeClass(["bg-gray-50 dark:bg-gray-850 dark:focus:ring-primary-500 dark:focus:border-primary-500 border sm:text-sm rounded-lg block w-full p-2.5", {
        'border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700': !_ctx.form.invalid(_ctx.name),
        'border-red-500 dark:border-red-500 text-red-900 dark:text-red-500 focus:ring-red-500 focus:border-red-500': _ctx.form.invalid(_ctx.name)
      }])
      }, null, 42, _hoisted_3), [
        [_vModelText, _ctx.model]
      ]),
      (_ctx.form.invalid(_ctx.name))
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[3] || (_cache[3] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              fill: "none",
              viewBox: "0 0 24 24",
              "stroke-width": "1.5",
              stroke: "currentColor",
              class: "h-5 w-5 text-red-500"
            }, [
              _createElementVNode("path", {
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                d: "M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
              })
            ], -1)
          ])))
        : _createCommentVNode("", true)
    ]),
    (_ctx.withError && _ctx.form.invalid(_ctx.name))
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.form.errors[_ctx.name]), 1)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}