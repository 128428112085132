import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" }
const _hoisted_2 = { class: "bg-white dark:bg-gray-900 text-left relative inline-block text-base font-normal leading-5 rounded-lg overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl w-full" }
const _hoisted_3 = { class: "flex items-center justify-between p-4 border-b rounded-t md:px-6 dark:border-gray-800" }
const _hoisted_4 = { class: "text-xl font-semibold dark:text-white" }
const _hoisted_5 = { class: "p-4 space-y-6 md:px-6" }
const _hoisted_6 = { class: "grid grid-cols-2 gap-6 mb-4" }
const _hoisted_7 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_8 = { class: "flex items-center p-4 space-x-3 border-t border-gray-200 rounded-b md:p-6 dark:border-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogOverlay = _resolveComponent("DialogOverlay")!
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createBlock(_component_TransitionRoot, {
    as: "template",
    show: _ctx.open
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Dialog, {
        as: "div",
        class: "fixed z-50 inset-0 overflow-y-auto",
        onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleClose()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_TransitionChild, {
              as: "template",
              enter: "ease-out duration-300",
              "enter-from": "opacity-0",
              "enter-to": "opacity-100",
              leave: "ease-in duration-200",
              "leave-from": "opacity-100",
              "leave-to": "opacity-0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DialogOverlay, { class: "fixed inset-0 bg-black bg-opacity-50" })
              ]),
              _: 1
            }),
            _cache[6] || (_cache[6] = _createElementVNode("span", {
              class: "hidden sm:inline-block sm:align-middle sm:h-screen",
              "aria-hidden": "true"
            }, "​", -1)),
            _createVNode(_component_TransitionChild, {
              as: "template",
              enter: "ease-out duration-300",
              "enter-from": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
              "enter-to": "opacity-100 translate-y-0 sm:scale-100",
              leave: "ease-in duration-200",
              "leave-from": "opacity-100 translate-y-0 sm:scale-100",
              "leave-to": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("form", {
                    onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.submit()), ["prevent"])),
                    class: "relative rounded-lg shadow"
                  }, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$trans('main.boards.titles.edit_card')), 1),
                      _createElementVNode("button", {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClose())),
                        type: "button",
                        class: "text-gray-400 bg-transparent hover:bg-gray-300 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                      }, _cache[5] || (_cache[5] = [
                        _createElementVNode("svg", {
                          class: "w-5 h-5",
                          fill: "currentColor",
                          viewBox: "0 0 20 20",
                          xmlns: "http://www.w3.org/2000/svg"
                        }, [
                          _createElementVNode("path", {
                            "fill-rule": "evenodd",
                            d: "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z",
                            "clip-rule": "evenodd"
                          })
                        ], -1)
                      ]))
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_Input, {
                            id: "b_c_c_title",
                            type: "text",
                            name: "b_c_c_title",
                            label: "validation.attributes.b_c_c_title",
                            autocomplete: "on",
                            modelValue: _ctx.form.b_c_c_title,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.b_c_c_title) = $event)),
                            required: true,
                            form: _ctx.form as any
                          }, null, 8, ["modelValue", "form"])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_Button, {
                        text: _ctx.$trans('main.boards.buttons.update_card'),
                        loading: _ctx.form.processing,
                        cursorNotAllowed: !_ctx.form.isDirty,
                        disabled: _ctx.form.invalid('b_c_c_title')
                      }, null, 8, ["text", "loading", "cursorNotAllowed", "disabled"]),
                      _createElementVNode("button", {
                        type: "button",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleClose())),
                        class: "w-24 text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 hover:border-gray-300 font-semibold rounded-lg text-sm py-2.5 text-center dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:bg-gray-600"
                      }, _toDisplayString(_ctx.$trans('main.shared.buttons.cancel')), 1)
                    ])
                  ], 32)
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show"]))
}