<template>
  <path
    ref="pathRef"
    stroke="white"
    stroke-opacity="0.2"
    stroke-dasharray="1"
    stroke-dashoffset="1"
    pathLength="1"
    fill="transparent"
    :d="pathD"
    class="invisible"
  />
  <Star
    v-for="(point, index) in uniquePoints"
    :key="index"
    :point="point"
    :blurId="blurId"
  />
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, PropType } from 'vue';
import { timeline, type TimelineSegment } from 'motion';
import Star from './Star.vue';

type StarType = [number, number, boolean?, boolean?];

export default defineComponent({
  name: 'Constellation',
  components: { Star },
  props: {
    points: {
      type: null as unknown as PropType<StarType[]>,
      required: true,
    },
    blurId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const pathRef = ref<SVGPathElement | null>(null);

    const uniquePoints = computed(() =>
      props.points.filter(
        (point, index) =>
          props.points.findIndex((p) => JSON.stringify(p) === JSON.stringify(point)) === index,
      ),
    );

    const isFilled = computed(() => uniquePoints.value.length !== props.points.length);

    const pathD = computed(() =>
      `M ${props.points.map((p) => p.slice(0, 2).join(',')).join(' L ')}`,
    );

    onMounted(() => {
      if (!pathRef.value) return;

      const sequence: Array<TimelineSegment> = [
        [
          pathRef.value,
          { strokeDashoffset: 0, visibility: 'visible' },
          { duration: 5, delay: Math.random() * 3 + 2 },
        ],
      ];

      if (isFilled.value) {
        sequence.push([
          pathRef.value,
          { fill: 'rgba(255, 255, 255, 0.02)' },
          { duration: 1 },
        ]);
      }

      const animation = timeline(sequence);

      return () => {
        animation.cancel();
      };
    });

    return {
      pathRef,
      uniquePoints,
      pathD,
      blurId: props.blurId,
    };
  },
});
</script>