import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "wysiwyg-editor" }
const _hoisted_2 = { class: "flex space-x-2" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["for"]
const _hoisted_5 = {
  key: 1,
  class: "mt-2 text-sm text-red-600 dark:text-red-400"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editor_content = _resolveComponent("editor-content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action, index) => {
        return (_openBlock(), _createElementBlock("button", {
          key: index,
          type: "button",
          onClick: ($event: any) => (_ctx.executeAction(action.command)),
          class: "p-1 rounded-md text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 dark:focus:ring-offset-gray-800"
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(action.icon), { class: "h-4 w-4 stroke-2" }))
        ], 8, _hoisted_3))
      }), 128))
    ]),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          class: "block text-sm font-medium text-gray-700 dark:text-gray-300"
        }, _toDisplayString(_ctx.label), 9, _hoisted_4))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([
        'mt-1 border rounded-md shadow-sm focus-within:ring-1 focus-within:ring-gray-700 focus-within:border-gray-700',
        'dark:bg-gray-850/50 dark:border-gray-700 dark:focus-within:ring-primary-700',
        { 'border-red-300 dark:border-red-500': _ctx.form.errors[_ctx.name] }
      ])
    }, [
      (_ctx.editor)
        ? (_openBlock(), _createBlock(_component_editor_content, {
            key: 0,
            editor: _ctx.editor
          }, null, 8, ["editor"]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.form.errors[_ctx.name])
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.form.errors[_ctx.name]), 1))
      : _createCommentVNode("", true)
  ]))
}