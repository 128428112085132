<template>
  <VueDraggableNext
    :emptyInsertThreshold="listDragOptions.emptyInsertThreshold" 
    :preventOnFilter="listDragOptions.preventOnFilter"
    :forceFallback="listDragOptions.forceFallback"
    :ghostClass="listDragOptions.ghostClass"
    :animation="listDragOptions.animation"
    :handle="`.${listDragOptions.handle}`"
    :itemKey="listDragOptions.itemKey"
    :filter="listDragOptions.filter"
    @change="onListPositionChange()"
    :delay="listDragOptions.delay"
    :group="listDragOptions.group"
    @start="onListDragStart()"
    @end="onListDragEnd()"
    :list="lists">
    <div v-for="list in lists" :key="list.id" :class="listDragOptions.handle, checkDragOptions.handle">
      <div class="justify-between group items-center flex min-h-8 m-0 mb-2 relative ">
        <template v-if="listMode == null || listMode == 'create' || (listMode == 'edit' && editList?.id != list.id)">
          <div class="float-left flex flex-row mr-0.5">
            <div class="text-gray-600 dark:text-gray-400 block">
              <CheckCircleIcon class="w-5 h-5 mr-1 stroke-2"/>
            </div>
            <h3 class="text-gray-600 dark:text-gray-400 inline-flex items-center text-md font-semibold text-start cursor-pointer">
              {{ list.name }}
            </h3>
          </div>
        </template>
        <template v-else-if="listMode === 'edit' && editList?.id == list.id">
          <div class="text-gray-600 dark:text-gray-400 inline-flex items-center mb-2 text-md font-semibold text-center list-no-drag">
            <CheckCircleIcon class="w-5 h-5 mr-1 stroke-2"/>
            <textarea :id="`edit-list-area-${list.id}`" v-model="editListForm.b_c_c_c_name" @focusout="onEditListFormBlur(list)" class="static content-center top-0 right-0 bottom-0 left-0 bg-transparent rounded-md shadow-none box-border font-semibold m-0 min-h-5 overflow-hidden break-words py-1.5 pr-2 pl-3 resize-none h-8"></textarea>
          </div>
        </template>
        <div class="float-right mr-0.5 block space-y-1">
          <ButtonSecondary v-if="checkMode != 'create' || createList?.id != list.id"
            classes="mr-1 "
            @click="openCheckCreateForm(list)"
            :text="$trans('main.boards.buttons.add_new_check')"
            size="sm"/>
          <ButtonSecondary v-if="listMode != 'edit' || editList?.id != list.id" 
            classes="mr-1 "
            @click="openListEditForm(list)"
            :text="$trans('main.shared.buttons.edit')"
            size="sm"/>
          <ButtonSecondary @click="toggleDeleteChecklistModal(true, list.id)"
            classes="mr-1 " 
            :text="$trans('main.shared.buttons.delete')"
            size="sm"/>
        </div>
      </div>
      <div class="ml-4 block">
        <span class="text-gray-600 dark:text-gray-400 flex justify-end font-bold text-nano leading-[0.625rem] absolute text-center w-8">
          {{ isCompletedPercentage(list) }}%
        </span>
        <div class="rounded-md clear-both m-0 ml-10 overflow-hidden relative block">
          <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div class="bg-blue-600 transition-width transition-background duration-150 ease-in h-2.5 rounded-full" :style="`width: ${isCompletedPercentage(list)}%`"></div>
          </div>
        </div>
      </div>
      <div class="ml-14 mt-1 list-no-drag" v-if="checkMode == 'create' && createList?.id == list.id">
        <TextArea :id="`create-check-area-${list.id}`" :rows="2" name="b_c_c_c_c_content" required :with-error="true" :without-label="true" :form="createCheckForm" v-model="createCheckForm.b_c_c_c_c_content"/>
        <div class="flex flex-row mt-2">
          <button @click="submitCheckCreateForm(list)" class="bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-600 text-black dark:text-gray-300 my-0 ml-0 mr-1 items-center border-none rounded shadow-none box-border cursor-pointer inline-flex text-sm font-medium justify-center leading-5 p-1.5 no-underline transition-colors duration-75 ease-in whitespace-normal">
            {{ $trans('main.shared.buttons.create') }}
          </button>
          <button @click="closeCheckCreateForm" class="bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-600 text-black dark:text-gray-300 m-0 items-center border-none rounded shadow-none box-border cursor-pointer inline-flex text-sm font-medium justify-center leading-5 p-1.5 no-underline transition-colors duration-75 ease-in whitespace-normal">
            {{ $trans('main.shared.buttons.hide') }}
          </button>
        </div>
      </div>
      <!-- Checks -->
      <VueDraggableNext class="ml-2 mt-1 transition-height transition-padding transition-margin transition-colors duration-150 ease-in"
        :emptyInsertThreshold="checkDragOptions.emptyInsertThreshold"
        :preventOnFilter="checkDragOptions.preventOnFilter"
        :forceFallback="checkDragOptions.forceFallback"
        :ghostClass="checkDragOptions.ghostClass"
        @change="onCheckPositionChange($event)"
        :animation="checkDragOptions.animation"
        :handle="`.${checkDragOptions.handle}`"
        :itemKey="checkDragOptions.itemKey"
        :filter="checkDragOptions.filter"
        :group="checkDragOptions.group"
        :delay="checkDragOptions.delay"
        @start="onCheckDragStart()"
        @end="onCheckDragEnd()"
        :list="list.checks">
        <div v-for="check in list.checks" 
          :key="check.id" 
          @click="handleCheckClick($event, check)" 
          class="block relative rounded-lg box-border clear-both pl-10 my-1 origin-left-bottom"
          :class="[checkMode != 'edit' && editCheck?.id != check.id ? 'group hover:bg-gray-100 dark:hover:bg-gray-950' : '', checkDragOptions.handle]">
          <!-- Input -->
          <div class="cursor-pointer absolute left-2 m-1.5 text-center">
            <input type="checkbox" :disabled="checkIsDragging" :checked="check.is_completed" class="bg-gray-50 dark:bg-gray-850 border-gray-300 dark:border-gray-750 focus:ring-primary-300 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-3 rounded cursor-pointer h-4 w-4 appearance-[checkbox] inline box-border p-0"/>
          </div>
          <!-- Title -->
          <div class="cursor-pointer break-words rounded-lg m-0 overflow-hidden py-2">
            <div class="flex flex-row break-words">
              <div v-if="checkMode === 'edit' && editCheck?.id == check.id" class="w-full px-1 check-no-drag">
                <TextArea :id="`edit-check-area-${check.id}`" :rows="2" name="b_c_c_c_c_content" required :with-error="true" :without-label="true" :form="editCheckForm" v-model="editCheckForm.b_c_c_c_c_content"/>
                <div class="flex flex-row mt-2">
                  <button @click="submitCheckEditForm(check)" class="bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-600 text-black dark:text-gray-300 my-0 ml-0 mr-1 items-center border-none rounded shadow-none box-border cursor-pointer inline-flex text-sm font-medium justify-center leading-5 p-1.5 no-underline transition-colors duration-75 ease-in whitespace-normal">
                    {{ $trans('main.shared.buttons.update') }}
                  </button>
                  <button @click="closeCheckEditForm" class="bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-600 text-black dark:text-gray-300 m-0 items-center border-none rounded shadow-none box-border cursor-pointer inline-flex text-sm font-medium justify-center leading-5 p-1.5 no-underline transition-colors duration-75 ease-in whitespace-normal">
                    {{ $trans('main.shared.buttons.hide') }}
                  </button>
                </div>
              </div>
              <div v-else class="inline-flex w-full break-words select-none">
                <span :class="{'line-through': check.is_completed}" class="text-gray-600 dark:text-gray-400 self-center flex-1 mb-0 min-h-5 break-words cursor-pointer">
                  {{ check.content }}
                </span>
                <Menu as="div" :key="check.id" class="z-5 group-hover:grid gap-x-1 hidden flex-[0_0_auto] grid-cols-[1fr_max-content] min-h-5 px-4 pb-0">
                  <div class="inline-flex flex-row float-right">
                    <div class="md:hidden group-hover:md:inline-flex">
                      <MenuButton @click.stop>
                        <EllipsisHorizontalCircleIcon class="h-4 w-4 check-no-drag"/>
                      </MenuButton>
                    </div>
                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                      <MenuItems class="bg-white dark:bg-gray-850 border-gray-200 dark:border-gray-750 divide-gray-100 dark:divide-gray-750 hidden group-hover:block check-no-drag border absolute right-0 mt-6 origin-top-right max-w-32 w-auto text-sm list-none rounded shadow-sm">
                        <ul role="none">
                          <li @click.stop="openCheckEditForm(check)" class="text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-white column-no-drag cursor-pointer select-none px-2 py-1">
                            {{$trans('main.shared.buttons.edit')}}
                          </li>
                          <li @click.stop="deleteCheck(list, check)" class="text-red-700 dark:hover:text-white dark:hover:bg-red-800 hover:bg-red-100 column-no-drag cursor-pointer select-none px-2 py-1 rounded">
                            {{$trans('main.shared.buttons.delete')}}
                          </li>
                        </ul>
                      </MenuItems>
                    </transition>
                  </div>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </VueDraggableNext>
    </div>
  </VueDraggableNext>
  <Attempt
    :open="isDeleteChecklistAttemptVisible"
    :close="(value: boolean) => isDeleteChecklistAttemptVisible = value"
    :is-api="true"
    routeName="api.v1.boards.cards.checklists.destroy"
    :sendCallback="deleteList"
    :delete-callback="deleteList"
    :routeArgument="{ checklist: idOfChecklistToDelete }"
    :title="$trans('main.attempts.delete_board_card_checklist.title')" 
    :description="$trans('main.attempts.delete_board_card_checklist.description')" 
    :successButtonText="$trans('main.attempts.buttons.success_button')"
    :cancelButtonText="$trans('main.attempts.buttons.cancel_button')"/>
  <TransitionRoot as="template" :show="checklistModalIsOpen">
    <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto" @close="handleCloseCreateListModal()">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-black bg-opacity-50"/>
        </TransitionChild>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template"
          enter="ease-out duration-300" 
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" 
          enter-to="opacity-100 translate-y-0 sm:scale-100" 
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100" 
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="bg-white dark:bg-gray-900 text-left relative inline-block text-base font-normal leading-5 rounded-lg overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-md w-full">
            <form @submit.prevent="submitCreateListForm()" class="relative rounded-lg shadow">
              <!-- Modal header -->
              <div class="flex items-center justify-between p-4 border-b rounded-t md:px-6 dark:border-gray-800">
                <div class="text-xl font-semibold dark:text-white">
                  {{ $trans('main.boards.titles.add_new_checlist') }}
                </div>
                <button @click="handleCloseCreateListModal()" type="button" class="text-gray-400 bg-transparent hover:bg-gray-300 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white">
                  <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                  </svg>
                </button>
              </div>
              <!-- Modal body -->
              <div class="p-4 space-y-6 md:px-6">
                <div class="grid grid-cols-2 gap-6 mb-4">
                  <div class="col-span-6 sm:col-span-3">
                    <Input id="b_c_c_c_name" 
                      type="text"
                      name="b_c_c_c_name"
                      label="validation.attributes.b_c_c_c_name" 
                      autocomplete="on"
                      v-model="createListForm.b_c_c_c_name"
                      :required="true"
                      :form="createListForm as any"/>
                  </div>
                </div>
              </div>
              <!-- Modal footer -->
              <div class="flex items-center p-4 space-x-3 border-t border-gray-200 rounded-b md:p-6 dark:border-gray-800">
                <Button :text="$trans('main.boards.buttons.add_checklist')"
                  :loading="createListForm.processing"
                  :cursorNotAllowed="!createListForm.isDirty"
                  :disabled="createListForm.invalid('b_c_c_c_name')"/>
                <button type="button" @click="handleCloseCreateListModal()" class="w-24 text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 hover:border-gray-300 font-semibold rounded-lg text-sm py-2.5 text-center dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:bg-gray-600">
                    {{ $trans('main.shared.buttons.cancel') }}
                </button>
              </div>
            </form>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<style scoped>
  .check-ghost { @apply opacity-0; }
  .list-ghost { @apply opacity-100 bg-gray-100 dark:bg-gray-750 rounded-lg; }
  .list-ghost * { visibility: hidden; }
  @-moz-document url-prefix() { input[type='checkbox'] { -moz-appearance: checkbox; } }
</style>
<script lang="ts">
import { EllipsisHorizontalCircleIcon, CheckCircleIcon } from '@heroicons/vue/24/outline';
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { defineComponent, toRef, ref, onMounted, watch, shallowRef, PropType } from 'vue';
import { useCheckListDragControl } from './Composables/useCheckListDragControl';
import { default as LightList } from '@/Types/Resources/CheckList/Light';
import { useCheckListActions } from './Composables/useCheckListActions';
import { useCheckDragControl } from './Composables/useCheckDragControl';
import { default as LightAction} from '@/Types/Resources/Action/Light';
import { default as LightCheck } from '@/Types/Resources/Check/Light';
import { default as BaseBoard } from '@/Types/Resources/Board/Base';
import { default as LightCard } from '@/Types/Resources/Card/Light';
import { useCheckActions } from './Composables/useCheckActions';
import { Menu, MenuButton, MenuItems } from '@headlessui/vue';
import ButtonSecondary from '@/Shared/ButtonSecondary.vue';
import { VueDraggableNext } from 'vue-draggable-next';
import TextArea from '@/Shared/TextArea.vue';
import { autoResizeTextarea } from '@/utils';
import Attempt from '@/Shared/Attempt.vue';
import Button from '@/Shared/Button.vue';
import Input from '@/Shared/Input.vue';

export default defineComponent({
  name: 'CheckList',
  components: {
    EllipsisHorizontalCircleIcon,
    VueDraggableNext,
    CheckCircleIcon,
    TransitionChild,
    ButtonSecondary,
    TransitionRoot,
    DialogOverlay,
    MenuButton,
    MenuItems,
    TextArea,
    Attempt,
    Button,
    Dialog,
    Input,
    Menu,
  },
  emits: [
    "update-card",
    "update-actions",
    "close-checklist-modal"
  ], 
  props: {
    board : {
      type: [Object, null] as PropType<BaseBoard|null>,
    },
    card: {
      type: [Object, null] as PropType<LightCard|null>,
    },
    open: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const checklistModalIsOpen = toRef(props, 'open');
    const propsBoard = toRef(props, 'board');
    const propsCard = toRef(props, 'card');
    const board = ref<BaseBoard|null>(propsBoard.value as BaseBoard);
    const card = ref<LightCard|null>(propsCard.value as LightCard);
    const lists = shallowRef<Array<LightList>>([]);
    const checkIsDragging = ref<boolean>(false);

    const {
      isDeleteChecklistAttemptVisible,
      toggleDeleteChecklistModal,
      isCompletedPercentage,
      idOfChecklistToDelete,
      submitCreateListForm,
      onEditListFormBlur,
      openListEditForm,
      createListForm,
      editListForm,
      deleteList,
      listMode,
      editList
    } = useCheckListActions(lists, card, handleUpdateCard, handleCloseCreateListModal);

    const {
      onListPositionChange,
      isListBeingDragged,
      listDragOptions,
      onListDragStart,
      onListDragEnd,
    } = useCheckListDragControl(lists);

    const {
      submitCheckCreateForm,
      toggleCheckCompletion,
      closeCheckCreateForm,
      openCheckCreateForm,
      submitCheckEditForm,
      closeCheckEditForm,
      openCheckEditForm,
      createCheckForm,
      editCheckForm,
      deleteCheck,
      createList,
      editCheck,
      checkMode
    } = useCheckActions(lists, checkIsDragging, card, handleUpdateCard, handleUpdateActions);

    const {
      onCheckPositionChange,
      checkDragOptions,
      onCheckDragStart,
      onCheckDragEnd
    } = useCheckDragControl(lists, card, checkIsDragging, handleUpdateCard);

    watch(() => propsBoard.value, (newBoard) => {
      board.value = newBoard as BaseBoard
    }, {
      immediate: true
    });

    watch(() => propsCard.value, (newCard) => {
      card.value = newCard as LightCard
      lists.value = newCard?.checklists as Array<LightList>;
    }, {
      immediate: true
    });

    function handleUpdateCard(newCard: LightCard): void
    {
      card.value = newCard;
      
      emit('update-card', newCard);
    }

    function handleUpdateActions(actions: Array<LightAction>): void
    {
      emit('update-actions', actions);
    }

    function handleCloseCreateListModal(value: boolean = false): void
    {
      createListForm.reset();
      createListForm.clearErrors();

      emit('close-checklist-modal', value);
    }
    
    function handleCheckClick(event: MouseEvent, check: LightCheck): void
    {
      const target = event.target as HTMLElement;
      
      if(target.closest('.check-no-drag') || target.tagName === 'TEXTAREA') return;
      
      toggleCheckCompletion(check);
    }

    onMounted(() => {
      card.value = propsCard?.value as LightCard;
      lists.value = propsCard.value?.checklists as Array<LightList>;
    });

    return {
      isDeleteChecklistAttemptVisible,
      handleCloseCreateListModal,
      toggleDeleteChecklistModal,
      idOfChecklistToDelete,
      isCompletedPercentage,
      toggleCheckCompletion,
      submitCheckCreateForm,
      onCheckPositionChange,
      checklistModalIsOpen,
      closeCheckCreateForm,
      submitCreateListForm,
      onListPositionChange,
      openCheckCreateForm,
      submitCheckEditForm,
      closeCheckEditForm,
      autoResizeTextarea,
      isListBeingDragged,
      onEditListFormBlur,
      openCheckEditForm,
      openListEditForm,
      handleCheckClick,
      onCheckDragStart,
      checkDragOptions,
      listDragOptions,
      onListDragStart,
      checkIsDragging,
      createCheckForm,
      createListForm,
      onCheckDragEnd,
      onListDragEnd,
      editCheckForm,
      editListForm,
      deleteCheck,
      createList,
      propsBoard,
      deleteList,
      propsCard,
      editCheck,
      checkMode,
      listMode,
      editList,
      board,
      lists,
      card
    };
  },
});
</script>