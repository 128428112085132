<template>
  <Layout>
    <main class="space-y-20 py-20 sm:space-y-32 sm:py-32">
      <article v-for="change in changes" :id="change.id" class="scroll-mt-16 pb-0">
        <div>
          <header class="relative mb-10 xl:mb-0">
            <div class="pointer-events-none absolute left-[max(-0.5rem,calc(50%-18.625rem))] top-0 z-50 flex h-4 items-center justify-end gap-x-2 lg:left-0 lg:right-[calc(max(2rem,50%-38rem)+40rem)] lg:min-w-[32rem] xl:h-8">
              <a class="inline-flex" :href="`#${change.id}-suggestions`">
                <time :datetime="change.date" class="xl:text-white/50 hidden xl:pointer-events-auto xl:block xl:text-2xs/4 xl:font-medium">
                  {{ formatLocalizedDate(change.date, lang) }}
                </time>
              </a>
              <div class="h-[0.0625rem] w-3.5 bg-gray-400 lg:-mr-3.5 xl:mr-0 xl:bg-gray-300"></div>
            </div>
            <div class="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
              <div class="lg:ml-96 lg:flex lg:w-full lg:justify-end lg:pl-32">
                <div class="mx-auto max-w-lg lg:mx-0 lg:w-0 lg:max-w-xl lg:flex-auto">
                  <div class="flex">
                    <a class="inline-flex" :href="`#${change.id}-suggestions`">
                      <time :datetime="change.date" class="text-gray-500 dark:text-white/50 text-2xs/4 font-medium xl:hidden">
                        {{ formatLocalizedDate(change.date, lang) }}
                      </time>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div class="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
            <div class="lg:ml-96 lg:flex lg:w-full lg:justify-end lg:pl-32">
              <div class="mx-auto max-w-lg lg:mx-0 lg:w-0 lg:max-w-xl lg:flex-auto typography" data-mdx-content="true">
                <div v-if="change.image" class="relative mt-8 overflow-hidden rounded-xl bg-gray-50 dark:bg-gray-900 [&amp;+*]:mt-8">
                  <!-- <img alt="" loading="lazy" width="1728" height="936" decoding="async" data-nimg="1" style="color:transparent" sizes=""> -->
                  <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10 dark:ring-white/10"></div>
                </div>
                <h2>
                  <a :href="`#${change.id}-suggestions`">
                    {{ change.title[lang] }}
                  </a>
                </h2>
                <component :is="{template: change.description[lang]}"/>
                <template v-if="change.improvements">
                  <h3 :id="`#${change.id}-improvements`">
                    <svg viewBox="0 0 16 16" aria-hidden="true">
                      <path fill="#38BDF8" d="M5.338 9.805c.11.418.439.747.857.857C7.282 10.948 8 11.44 8 12s-.718 1.052-1.805 1.338c-.418.11-.747.439-.857.857C5.052 15.281 4.56 16 4 16s-1.052-.718-1.338-1.805a1.205 1.205 0 0 0-.856-.857C.718 13.052 0 12.56 0 12s.718-1.052 1.806-1.338c.417-.11.746-.439.856-.857C2.948 8.718 3.441 8 4 8c.56 0 1.052.718 1.338 1.805Z"></path>
                      <path fill="#7DD3FC" d="M12.717 2.432c.1.42.43.75.85.852C15.026 3.633 16 4.27 16 5s-.975 1.367-2.432 1.716c-.42.101-.75.432-.851.852C12.367 9.025 11.729 10 11 10c-.729 0-1.367-.975-1.716-2.432-.101-.42-.431-.75-.851-.852C6.975 6.367 6 5.73 6 5c0-.73.975-1.367 2.433-1.717.42-.1.75-.43.85-.85C9.634.974 10.272 0 11 0c.73 0 1.367.975 1.717 2.432Z"></path>
                    </svg>
                    {{ $trans('changelog.improvements') }}
                  </h3>
                  <ul>
                    <li v-for="improvement in change.improvements">
                      {{ improvement[lang] }}
                    </li>
                  </ul>
                </template>
              </div>
            </div>
          </div>
        </div>
      </article>
    </main>
  </Layout>
</template>
<script lang="ts">
import { Head, Link, usePage } from '@inertiajs/vue3';
import { useLocalizedDateFormatter } from '@/utils';
import Change from '@/Types/App/System/Change';
import changelog from '@/data/changelog.json';
import Layout from './Components/Layout.vue';
import Toast from '@/Shared/Toast.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Layout,
    Toast,
    Link,
    Head,
  },
  setup() {
    const { formatLocalizedDate } = useLocalizedDateFormatter();
    const lang: string = usePage().props?.lang as string;
    const changes = changelog as Array<Change>;

    return {
      formatLocalizedDate,
      changes,
      lang
    };
  },
})
</script>