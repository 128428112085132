import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "name", "required"]
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 0,
  class: "mt-2 text-sm text-red-600 dark:text-red-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: "block text-sm font-medium text-gray-700 dark:text-white"
    }, _toDisplayString(_ctx.$trans(_ctx.label)), 9, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.divClass)
    }, [
      _withDirectives(_createElementVNode("select", {
        id: _ctx.id,
        name: _ctx.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
        required: _ctx.required,
        class: _normalizeClass([{
        'border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700': !_ctx.hasError(),
        'border-red-300 dark:border-red-500 text-red-700 dark:text-red-500 focus:ring-red-500 focus:border-red-500': _ctx.hasError(),
      }, "bg-gray-50 dark:bg-gray-850 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full pr-10 focus:outline-none sm:text-sm rounded-md"])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createElementBlock("option", {
            key: _ctx.optionKey != null ? (item?.[_ctx.optionKey] ? item?.[_ctx.optionKey] : index) : index,
            value: _ctx.value === 'item' ? item : _ctx.value ? item?.[_ctx.value] : index
          }, _toDisplayString(_ctx.transKey ? _ctx.$trans(`${_ctx.transKey}.${item}`) : _ctx.title ? item?.[_ctx.title] : item), 9, _hoisted_3))
        }), 128))
      ], 10, _hoisted_2), [
        [_vModelSelect, _ctx.model]
      ])
    ], 2),
    (_ctx.withError && _ctx.hasError())
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.getError()), 1))
      : _createCommentVNode("", true)
  ], 64))
}