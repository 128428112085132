<template>
  <footer>
    <div class="h-[--footer-height] md:hidden"></div>
    <div class="md:hidden fixed bottom-0 z-35 w-full bg-white dark:bg-gray-900 border-t border-slate-100 dark:border-gray-800">
      <div class="flex items-center justify-around h-[--footer-height]">
        <template v-for="item in navigation">
          <Link :href="route(item.link_name != undefined ? item.link_name : item.link, item.link_params ? item.link_params : {})" class="dark:text-gray-100 hover:text-slate-900 flex items-center justify-center w-1/5 h-[--footer-height]"
          :class="{'text-slate-900 dark:text-gray-100': item.current, 
                  'text-slate-500 dark:text-gray-500': !item.current}">
            <component :is="item.icon" class="w-6 h-6"/>
          </Link>

        </template>
        <!-- <a class="text-slate-900 font-medium dark:text-zinc-100 hover:text-slate-900 transition-all" href="/explore" aria-current="page">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" class="w-6 text-slate-800 dark:text-zinc-200">
              <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z"></path>
              <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z"></path>
            </svg>
        </a>
        <a class="text-slate-500 dark:text-zinc-100 hover:text-slate-900 transition-all" href="/designers">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="w-6 text-slate-500 dark:text-zinc-500">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"></path>
            </svg>
        </a>
        <a class="text-slate-500 dark:text-zinc-100 hover:text-slate-900 transition-all" href="/teams">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="w-6 text-slate-500 dark:text-zinc-500">
              <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"></path>
            </svg>
        </a>
        <a class="text-slate-500 dark:text-zinc-100 hover:text-slate-900 transition-all" href="/jobs">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="w-6 text-slate-500 dark:text-zinc-500">
              <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"></path>
            </svg>
        </a>
        <a class="text-slate-500 dark:text-zinc-100 hover:text-slate-900 transition-all" href="/activity">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="w-6 text-slate-500 dark:text-zinc-500">
              <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"></path>
            </svg>
        </a>
        <a class="text-slate-500 dark:text-zinc-100 hover:text-slate-900 transition-all" href="/messages">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="w-6 text-slate-500 dark:text-zinc-500">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"></path>
            </svg>
        </a> -->
      </div>
    </div>
  </footer>
</template>
<script lang="ts">
import { defineComponent, Component } from 'vue';
import { Menu, MenuButton, MenuItems, Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';
import { HomeIcon, CalendarIcon, UserGroupIcon, Cog8ToothIcon } from '@heroicons/vue/20/solid';
import FooterItem from '@/Types/App/Components/FooterItem';
import { isPermitted, isCurrentRoute } from '@/mixins';
import { usePage, Link, Head } from '@inertiajs/vue3'
import { Method } from '@inertiajs/core';
import { RouteName } from '@/ziggy';
import Auth from "@/Types/App/Auth";
import { isMac } from '@/mixins';
import { Roles } from '@/enums';
import route from '@/ziggy';

type FootItem = {
  icon: Component,
  link: RouteName,
  show: boolean,
  current: boolean,
};

export default defineComponent({
  components: {
    UserGroupIcon,
    Cog8ToothIcon,
    CalendarIcon,
    HomeIcon,
    Link
  },
  setup() {
    const navigation: Array<FooterItem> = [
      {
        icon: HomeIcon,
        current: isCurrentRoute(['boards']),
        show: true,
        link_name: 'boards',
        link_params: { status: 'active' }
      },
      // {
      //   link: 'sites',
      //   current: isCurrentRoute(['sites', 'sites.edit', 'sites.show']),
      //   show: isPermitted('sites', 'read'),
      // },
      // {
      //   link: 'logs', 
      //   name: 'main.side_menu.activity.inside.logs',
      //   show: isPermitted('logs', 'read'),
      //   current: isCurrentRoute(['logs'])
      // },
      // {
      //   link: 'reports', 
      //   name: 'main.side_menu.activity.inside.reports',
      //   show: isPermitted('reports', 'read'),
      //   current: isCurrentRoute(['reports'])
      // },
      {
        icon: UserGroupIcon,
        link: 'users',
        show: isPermitted([Roles.SUPER_ADMIN, Roles.ADMIN]),
        current: isCurrentRoute(['users'])
      },
      {
        icon: Cog8ToothIcon,
        link: 'profile.edit',
        show: true,
        current: isCurrentRoute(['profile.edit'])
      },
      // {
      //   link: 'system.permissions', 
      //   name: 'main.side_menu.settings.inside.permissions',
      //   show: isPermitted('permissions', 'read'),
      //   current: isCurrentRoute(['system.permissions'])
      // },
    ];

    return {
      navigation,
      route
    };
  }
});
</script>