<template>
  <Head v-if="command !== null" :title="`${$trans('main.commands.show.title')} #${command?.pid} | ${getAppName()}`"/>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="z-50 fixed inset-0 overflow-hidden" @close="close()">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0 bg-black bg-opacity-50 dark:bg-opacity-50" />
        <div class="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10 sm:pr-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-300 sm:duration-500"
            enter-from="-translate-x-full" enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-300 sm:duration-500" leave-from="translate-x-0"
            leave-to="-translate-x-full">
            <div class="pointer-events-auto w-auto">
              <div class="flex h-full flex-col bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-200 shadow-xl min-w-auto max-w-[100vw] sm:min-w-96">
                <div class="flex-1 overflow-y-auto">
                  <!-- Header -->
                  <div class="bg-gray-50 dark:bg-gray-850 px-4 py-6 sm:px-6">
                    <div class="flex justify-between items-start">
                      <div>
                        <!-- Title -->
                        <DialogTitle class="text-lg font-medium text-gray-900 dark:text-white">
                          {{ `${$trans('main.commands.show.title')} ${command?.pid !== null ? `#${command?.pid}` : ''}` }}
                        </DialogTitle>
                      </div>
                      <Cross @click="close()" />
                    </div>
                  </div>
                  <!-- Content -->
                  <div class="space-y-6 px-4 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 dark:sm:divide-gray-700 overflow-y-auto">
                    <div class="max-h-[calc(100vh-200px)]">
                      <div class="grid-cols-2">
                        <div class="mt-5 md:mt-0 md:col-span-2">
                          <div class="p-4">
                            <dl class="space-y-4 text-sm">
                              <div class="flex justify-between">
                                <dt class="font-semibold text-gray-800 dark:text-gray-100" v-html="$trans('main.commands.show.id')"></dt>
                                <dd class="break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300 ">
                                  {{ command?.id ? command.id : $trans('table.shared.no_data') }}
                                </dd>
                              </div>
                              <div class="flex justify-between">
                                <dt class="font-semibold text-gray-800 dark:text-gray-100" v-html="$trans('main.commands.show.user_name')"></dt>
                                <dd class="break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300">
                                  {{ command?.user ? command.user.name : $trans('table.shared.no_data') }}
                                </dd>
                              </div>
                              <div class="flex justify-between">
                                <dt class="font-semibold text-gray-800 dark:text-gray-100" v-html="$trans('main.commands.show.command')"></dt>
                                <dd class="break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300">
                                  {{ command?.value ? command.value : $trans('table.shared.no_data') }}
                                </dd>
                              </div>
                              <div class="flex justify-between">
                                <dt class="font-semibold text-gray-800 dark:text-gray-100" v-html="$trans('main.commands.show.status')"></dt>
                                <dd class="break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300">
                                  <Badge v-if="command" :type="getBadgeType(command?.status)" :text="$trans(`main.commands.statuses.${command?.status}`)">
                                    <div v-if="command?.status === 'running'" role="status">
                                      <svg aria-hidden="true" class="w-4 h-4 me-2 text-yellow-200 animate-spin dark:text-yellow-400 fill-yellow-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                      <span class="sr-only">Loading...</span>
                                    </div>
                                  </Badge>
                                </dd>
                              </div>
                              <div class="flex" v-if="command?.output !== '' && command?.output !== null">
                                <dd class="terminal-window">
                                  <highlightjs autodetect :code="command?.output ?? ''"/>
                                </dd>
                              </div>
                              <div class="flex justify-between">
                                <dt class="font-semibold text-gray-800 dark:text-gray-100" v-html="$trans('main.commands.show.started_at')"></dt>
                                <dd class="break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300">
                                  {{ command?.started_at ? command.started_at : $trans('table.shared.no_data') }}
                                </dd>
                              </div>
                              <div class="flex items-start justify-between">
                                <dt class="font-semibold text-gray-800 dark:text-gray-100" v-html="$trans('main.commands.show.finished_at')"></dt>
                                <dd class="break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300">
                                  {{ command?.finished_at ? command.finished_at : $trans('table.shared.no_data') }}
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Footer -->
                <div class="flex-shrink-0 border-t border-gray-200 dark:border-gray-750 px-4 py-5 sm:px-6">
                  <div class="flex justify-end space-x-3">
                    <div class="flex justify-end space-x-3">
                      <button @click="close"
                        class="bg-gray-900 dark:bg-primary-800 hover:bg-gray-700 dark:hover:bg-primary-700 text-white dark:text-gray-100 focus:ring-gray-700 dark:focus:ring-primary-950 focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                        {{ $trans('table.shared.close') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<style>
@import 'highlight.js/styles/tomorrow-night-bright.css';

.terminal-window {
  @apply bg-gray-950 dark:bg-gray-850/50 backdrop-blur rounded-lg p-4 font-mono text-sm w-full overflow-x-auto border border-gray-750/50;
}

.command-output {
  @apply text-gray-300 whitespace-pre p-2;
  font-family: 'Menlo', 'Monaco', monospace;
}

.terminal-window::-webkit-scrollbar {
  @apply w-1.5 h-1.5;
}

.terminal-window::-webkit-scrollbar-track {
  @apply bg-transparent rounded-lg;
}

.terminal-window::-webkit-scrollbar-thumb {
  @apply bg-gray-700 rounded-lg hover:bg-gray-600;
}

.hljs {
  @apply bg-transparent;
  font-family: 'Menlo', 'Monaco', monospace;
}
</style>
<script lang="ts">
import { defineComponent, PropType, toRef, inject, ref, onMounted, onUnmounted, computed, watch } from 'vue';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { default as BaseCommand } from '@/Types/Resources/Command/Base';
import { default as HandleUser } from '@/Types/Resources/User/Handle';
import { socketChannelNameBuilder, getAppName } from '@/mixins';
import { Channel } from '../../../socket/channels.js';
import hljsVuePlugin from "@highlightjs/vue-plugin";
import { Head, usePage } from '@inertiajs/vue3';
import { Socket } from 'socket.io-client';
import Cross from '@js/Shared/Cross.vue';
import Badge from '@js/Shared/Badge.vue';
import Auth from "@/Types/App/Auth";
import 'highlight.js/lib/common';

export default defineComponent({
  components: {
    highlightjs: hljsVuePlugin.component,
    TransitionChild,
    TransitionRoot,
    DialogOverlay,
    DialogTitle,
    Dialog,
    Cross,
    Badge,
    Head
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function,
      default: () => { }
    },
    command: {
      type: [Object, null] as PropType<BaseCommand | null>,
      required: false
    }
  },
  setup(props) {
    const open = toRef(props, 'isOpen');
    const command = ref<BaseCommand | null>(props.command ?? null);
    const auth = computed(() => usePage().props?.auth as Auth);
    const user = computed(() => auth.value.user as HandleUser);
    const socket = inject<Socket>('$socket');
    const socketUserCommandUpdatedChannel = ref<string|null>(null);

    watch(() => props.command, () => {
      command.value = props.command ?? null;
    });

    function close(): void {
      props.onClose();
    }

    function getBadgeType(status: BaseCommand['status']): 'success' | 'warning' | 'attempt' | 'error' | 'info' {
      switch (status) {
        case 'queued':
          return 'warning';
        case 'running':
          return 'warning';
        case 'completed':
          return 'success';
        case 'failed':
          return 'error';
        case 'stopping':
          return 'info';
        case 'stopped':
          return 'info';
        default:
          return 'info';
      }
    }

    onMounted(() => {
      socketUserCommandUpdatedChannel.value = socketChannelNameBuilder(Channel.UserCommandUpdatedEvent, user.value.id);
      socket?.on(socketUserCommandUpdatedChannel.value, (data: { command: BaseCommand }) => {
        if(command.value?.id !== data.command.id) return;
        
        command.value = data.command;
      });
    });

    onUnmounted(() => {
      socket?.off(socketUserCommandUpdatedChannel.value ?? undefined);
      socketUserCommandUpdatedChannel.value = null;
    });

    return {
      getBadgeType,
      getAppName,
      command,
      close,
      open,
    };
  }
});
</script>