<template>
  <label v-if="label && withoutLabel" :for="id" class="block text-sm font-medium text-gray-700 dark:text-white">
    {{ $trans(label) }}
  </label>
  <div class="relative rounded-md shadow-sm">
    <textarea :id="id" @change="form.validate(name)" @focusout="form.validate(name)" v-model="model" :required="required" :rows="rows" class="bg-gray-50 dark:bg-gray-850 dark:focus:ring-primary-500 dark:focus:border-primary-500 border sm:text-sm rounded-lg block w-full p-2.5"
      :class="{
        'border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700': !form.invalid(name),
        'border-red-500 dark:border-red-500 text-red-900 dark:text-red-500 focus:ring-red-500 focus:border-red-500': form.invalid(name)
      }"></textarea>
    <div v-if="form.invalid(name)" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 text-red-500">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
      </svg>
    </div>
  </div>
  <p v-if="withError && form.invalid(name)" class="text-red-600 dark:text-red-500 mt-2 text-sm">
    <span class="font-medium">
      {{ form.errors[name] }}
    </span>
  </p>
</template>
<script lang="ts">
import { PropType, defineComponent, ref, watch } from "vue";
import { useForm } from 'laravel-precognition-vue-inertia';

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true
    },
    modelValue: {
      type: [String, Number] as PropType<string | number>,
      required: true
    },
    rows: {
      type: Number,
      required: false,
      default: 3
    },
    required: {
      type: Boolean,
      required: true,
    },
    form: {
      type: Object,
      required: true
    },
    withError: {
      type: Boolean,
      default: true,
    },
    withoutLabel: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'click'],
  setup(props, { emit }) {
    const model = ref(props.modelValue);
  
    watch(() => props.modelValue, (newValue) => {
      model.value = newValue;
    });
  
    watch(model, (newValue) => {
      emit('update:modelValue', newValue);
    });

    return {
      model,
    };
  }
});
</script>