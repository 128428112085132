<template>
    <div class="sticky bg-white dark:bg-gray-900 border-gray-200 dark:border-gray-750 top-[-1px] z-40 flex h-16 shrink-0 items-center gap-x-4 border-b px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-4">
      <slot/>
      <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 justify-end">
        <div class="flex items-center">
          <Menu v-slot="{ close }" as="div" class="ml-2 relative">
            <div>
              <MenuButton class="p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-700">
                <span class="sr-only">View notifications</span>
                <BellIcon class="h-6 w-6" aria-hidden="true"/>
                <div v-if="new_notifications"
                  class="inline-flex absolute -top-2 -right-2 justify-center items-center w-6 h-6 text-nano font-bold text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900">
                  {{ new_notifications_length }}
                </div>
              </MenuButton>
            </div>
            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="bg-gray-50 dark:bg-gray-850 border border-gray-200 dark:border-gray-750 origin-top-right w-72 absolute mt-2 right-0 py-1 my-4 max-w-sm text-base list-none rounded divide-gray-100 shadow-sm dark:divide-gray-750">
                <div class="block py-2 px-4 cursor-default text-base font-medium text-center text-gray-900 dark:text-gray-300 border-b border-gray-100 dark:border-gray-750">
                  {{$trans('main.notifications_title')}}
                </div>
                <div v-if="!notifications?.length" class="block py-2 px-4 text-sm font-normal text-center text-gray-700 dark:text-gray-400">
                  <span class="text-center">
                    {{$trans('main.notifications_empty')}}
                  </span>
                </div>
                <div v-else>
                  <div v-for="notification in notifications || []" :key="notification.uuid" :class="{'last:border-b-0': !new_notifications}" class="break-all flex cursor-default py-1 px-3 border-b hover:bg-gray-100 dark:hover:bg-gray-750 dark:border-gray-750">
                    <div class="flex-shrink-0">
                      <div v-if="notification.type === 'success'" class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                        <CheckIcon class="w-5 h-5"/>
                      </div>
                      <div v-else-if="notification.type === 'error'" class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                        <XMarkIcon class="w-5 h-5"/>
                      </div>
                      <div v-else-if="notification.type === 'warning'" class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
                        <ExclamationTriangleIcon class="w-5 h-5"/>
                      </div>
                      <div v-else-if="notification.type === 'info'" class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-lg dark:bg-blue-700 dark:text-blue-200">
                        <InformationCircleIcon class="w-5 h-5"/>
                      </div>
                    </div>
                    <div class="pl-2 w-full">
                      <div class="text-gray-500 font-normal text-sm dark:text-gray-400 break-words">
                        <span class="font-semibold text-gray-900 dark:text-white">
                          {{ notification.title }}
                        </span>
                        <br/>
                        {{ notification.description }}
                      </div>
                      <div class="text-nano font-medium text-primary-700 dark:text-primary-400">
                        {{ notification.format_created_at }}
                      </div>
                    </div>
                  </div>
                </div>
                <Link v-if="new_notifications" @click="() => close()" as="div" :method="('post' as Method)" :href="route('notifications.read')" class="block cursor-pointer pt-2 text-sm font-normal text-center text-gray-900 bg-gray-50 hover:bg-gray-100 dark:bg-gray-850 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:underline">
                  <div class="inline-flex items-center">
                    <EyeIcon class="mr-2 w-5 h-5"/>
                    {{ $trans('main.notifications_read_all') }}
                  </div>
                </Link>
              </MenuItems>
            </transition>
          </Menu>
          <!-- Profile dropdown -->
          <Menu v-slot="{close}" as="div" class="ml-4 relative">
            <div>
              <MenuButton class="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-700">
                <span class="sr-only">Open user menu</span>
                <img class="block h-8 w-8 rounded-full" :class="[ auth.user?.photo_path ? 'object-cover' : 'dark:filter-white']" :src="auth.user?.photo_path ? auth.user.photo_path : '/dist/images/user-placeholder.svg'"/>
              </MenuButton>
            </div>
            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="border border-gray-200 dark:border-gray-750 origin-top-right absolute mt-2 right-0 w-48 py-1 text-base list-none bg-white rounded divide-gray-100 shadow-sm dark:bg-gray-850 dark:divide-gray-750">
                <div class="select-none py-3 px-4 border-b border-gray-100 dark:border-gray-750" role="none">
                  <p class="text-sm truncate text-gray-900 dark:text-white" role="none">
                    {{ auth.user?.name }}
                  </p>
                  <p class="text-sm"
                    :class="{'text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-pink-500 to-teal-400': auth.user?.role.value == 'super_admin',
                              'text-primary-700 dark:text-primary-500': auth.user?.role.value == 'admin',
                              'text-teal-700 dark:text-teal-500': auth.user?.role.value == 'moderator',
                              'text-gray-700 dark:text-gray-500': auth.user?.role.value == 'user'}">
                    {{ auth.user?.role.name }}
                  </p>
                </div>
                <ul class="pb-1" role="none">
                  <li class="select-none py-2 px-4 text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-white">
                    <Link :href="route('profile.edit')" class="cursor-pointer block text-sm">
                      {{$trans("main.settings")}}
                    </Link>
                  </li>
                  <li @click="setCmdOpen(true, close)" class="select-none flex justify-between py-2 px-4 hover:bg-gray-100 text-gray-700 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-white">
                    <span class="cursor-pointer text-sm block">
                      {{$trans("cmd.title")}}
                    </span>
                    <span class="text-sm flex-none text-gray-500 dark:text-gray-400">
                      {{isMac() ? '⌘ K' : 'Ctrl K'}}
                    </span>
                  </li>
                  <li class="select-none flex border-t border-gray-100 dark:border-gray-750 py-2 px-4 text-red-700 dark:hover:text-white dark:hover:bg-red-800 hover:bg-red-100">
                    <Link method="delete" :href="route('logout')" as="button" class="cursor-pointer flex-auto truncate block w-full text-sm text-left">
                      {{$trans('main.logout')}}
                    </Link>
                  </li>
                </ul>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>
  <CommandPalette :isOpen="cmdOpen" :close="(value: Boolean) => cmdOpen = value"/>
</template>
<script lang="ts">
import { BellIcon, ExclamationTriangleIcon, InformationCircleIcon, EyeIcon } from '@heroicons/vue/24/outline';
import { CheckIcon, XMarkIcon } from '@heroicons/vue/20/solid';
import { Menu, MenuButton, MenuItems } from '@headlessui/vue';
// import Notification from "@/Types/App/User/Notification";
import { defineComponent, computed, ref } from 'vue';
import CommandPalette from "@js/Pages/Command.vue";
import { usePage, Link } from '@inertiajs/vue3';
import { Method } from '@inertiajs/core';
import Icon from "@js/Shared/Icon.vue";
import Auth from "@/Types/App/Auth";
import { isMac } from '@/mixins';
import route from '@/ziggy';

export default defineComponent({
  // INFO: Navbar height is 4rem
  components: {
    ExclamationTriangleIcon,
    InformationCircleIcon,
    CommandPalette,
    MenuButton,
    MenuItems,
    XMarkIcon,
    CheckIcon,
    BellIcon,
    EyeIcon,
    Menu,
    Link,
    Icon,
  },
  setup() {
    const cmdOpen = ref<Boolean>(false);
    
    const auth = computed(() => usePage().props?.auth as Auth);
    // const notifications = computed(() => auth?.value.notifications as Array<Notification>);
    const notifications = computed(() => []);

    const new_notifications = computed(() => auth?.value.notifications_length > 0 ? true : false);
    const new_notifications_length = computed(() => auth?.value.notifications_length);

    function setCmdOpen(value: Boolean, callback: Function): void
    {
      cmdOpen.value = value;
      callback();
    }

    return {
      new_notifications_length,
      new_notifications,
      notifications,
      setCmdOpen,
      cmdOpen,
      route,
      isMac,
      auth,
    };
  },
})
</script>