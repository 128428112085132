<template>
  <Head :title="$trans('table.logs.title')"/>
  <Layout :body-classes="['bg-white', 'dark:bg-gray-950', 'h-full']"
          :main-classes="['bg-white', 'dark:bg-gray-950']">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="mt-8 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle">
            <table class="min-w-full divide-y divide-gray-300 dark:divide-gray-700">
              <thead>
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white" v-html="$trans('table.logs.head.html.id')"/>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white" v-html="$trans('table.logs.head.html.user_ip')"/>
                  <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-white" v-html="$trans('table.logs.head.html.type_status')"/>
                  <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-white" v-html="$trans('table.logs.head.html.url_function')"/>
                  <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-white" v-html="$trans('table.logs.head.html.data_info')"/>
                  <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-white" v-html="$trans('table.logs.head.html.created_at')"/>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span class="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 dark:divide-gray-800">
                <tr v-for="log in logs.data" :key="log.id" class="cursor-default">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 dark:text-gray-300">
                    {{ log.id }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <div class="text-gray-900 dark:text-gray-300">{{ log.user_id || $trans('main.shared.undefined_up') }}</div>
                    <div class="text-gray-500">{{ log.ip || $trans('main.shared.undefined_up') }}</div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-center">
                    <div class="text-gray-900 dark:text-gray-300">{{ log.type }}</div>
                    <Badge :type="log.status" :text="log.status" class="mt-1"/>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-center">
                    <Tooltip :text="log.url">
                      <div class="text-gray-900 dark:text-gray-300">{{ truncateText(log.url, 15) }}</div>
                    </Tooltip>
                    <Tooltip :text="log.function">
                      <div class="text-gray-500">{{ truncateText(log.function, 15) }}</div>
                    </Tooltip>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-center">
                    <Tooltip :text="log.encoded_data">
                      <div class="text-gray-900 dark:text-gray-300">{{ truncateText(log.encoded_data, 15) }}</div>
                    </Tooltip>
                    <Tooltip :text="log.info">
                      <div class="text-gray-500">{{ truncateText(log.info, 15) }}</div>
                    </Tooltip>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-900 dark:text-gray-300">
                    {{ log.created_at }}
                  </td>
                  <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-0">
                    <span @click="showLog(log)" 
                          class="text-primary-600 dark:text-primary-800 hover:text-primary-900 dark:hover:text-primary-700 cursor-pointer">
                      {{ $trans('table.shared.show') }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <Pagination :data="logs"/>
  </Layout>
  <Show v-if="showedLog"
    :log="showedLog"
    :isOpen="drawerOpen"
    :onClose="close"/>
</template>
<script lang="ts">
import ParentTDElement from '@/Types/App/Components/ParentTDElement';
import { default as LogList } from '@/Types/Resources/Log/List';
import HeaderColumn from '@/Types/App/Components/HeaderColumn';
import Pagination from '@js/Shared/Pagination.vue';
import Paginator from '@/Types/App/Paginator';
import Tooltip from '@js/Shared/Tooltip.vue';
import Layout from '@js/Shared/Layout.vue';
import Show from '@js/Pages/Logs/Show.vue';
import { defineComponent, ref } from "vue";
import Badge from '@js/Shared/Badge.vue';
import { Head } from '@inertiajs/vue3';

export default defineComponent({
  props: {
    logs: Object as () => Paginator<LogList>
  },
  components: {
    Pagination,
    Tooltip,
    Layout,
    Badge,
    Show,
    Head,
  },
  setup() {
    const drawerOpen = ref<Boolean>(false);
    const showedLog = ref<LogList | null>(null);
    const headerColumns: Array<HeaderColumn> = [
      {
        key: 'table.logs.head.html.id'
      },
      {
        key: 'table.logs.head.html.user_ip',
        location: 'left',
      },
      {
        key: 'table.logs.head.html.type_status',
        location: 'center',
      },
      {
        key: 'table.logs.head.html.url_function',
        location: 'center',
      },
      {
        key: 'table.logs.head.html.data_info',
        location: 'center',
      },
      {
        key: 'table.logs.head.html.created_at'
      }
    ];
    const TDElements: Array<ParentTDElement> = [
      {
        index: 0,
        attribute: 'id',
        class: 'text-center'
      },
      {
        index: 1,
        attribute: 'user_id',
        child: {
          attribute: 'ip',
        },
      },
      {
        index: 2,
        attribute: 'type',
        class: 'text-center',
        child: {
          attribute: 'status',
          class: 'text-center mt-1',
          badge_type: 'status'
        },
      },
      {
        index: 3,
        attribute: 'url',
        substring: true,
        tooltip: true,
        child: {
          attribute: 'function',
          substring: true,
          tooltip: true,
        },
      },
      {
        index: 4,
        attribute: 'encoded_data',
        substring: true,
        tooltip: true,
        child: {
          attribute: 'info',
          substring: true,
          tooltip: true,
        },
      },
      {
        index: 5,
        attribute: 'created_at',
        class: 'text-center'
      },
    ];

    function truncateText(text: string | number | null | undefined, length: number = 15): string
    {
      if (text === null || text === undefined || text === '') {
        // @ts-ignore
        return this.$trans('main.shared.undefined_up');
      }
      
      const str = String(text);
      if (str.length <= length) {
        return str;
      }
      
      return `${str.substring(0, length)}...`;
    }

    function showLog(log: LogList): void
    {
      showedLog.value = log;
      drawerOpen.value = true;
    }

    function close(): void
    {
      drawerOpen.value = false;

      setTimeout(() => {
        showedLog.value = null;
      }, 500);
    }

    return {
      headerColumns,
      truncateText,
      TDElements,
      drawerOpen,
      showedLog,
      showLog,
      close,
    }
  },
})
</script>