import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "px-4 sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "mt-8 flow-root" }
const _hoisted_3 = { class: "-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" }
const _hoisted_4 = { class: "inline-block min-w-full py-2 align-middle" }
const _hoisted_5 = { class: "min-w-full divide-y divide-gray-300 dark:divide-gray-700" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "divide-y divide-gray-200 dark:divide-gray-800" }
const _hoisted_13 = { class: "whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 dark:text-gray-300" }
const _hoisted_14 = { class: "whitespace-nowrap px-3 py-4 text-sm" }
const _hoisted_15 = { class: "text-gray-900 dark:text-gray-300" }
const _hoisted_16 = { class: "text-gray-500" }
const _hoisted_17 = { class: "whitespace-nowrap px-3 py-4 text-sm text-center" }
const _hoisted_18 = { class: "text-gray-900 dark:text-gray-300" }
const _hoisted_19 = { class: "whitespace-nowrap px-3 py-4 text-sm text-center" }
const _hoisted_20 = { class: "text-gray-900 dark:text-gray-300" }
const _hoisted_21 = { class: "text-gray-500" }
const _hoisted_22 = { class: "whitespace-nowrap px-3 py-4 text-sm text-center" }
const _hoisted_23 = { class: "text-gray-900 dark:text-gray-300" }
const _hoisted_24 = { class: "text-gray-500" }
const _hoisted_25 = { class: "whitespace-nowrap px-3 py-4 text-sm text-center text-gray-900 dark:text-gray-300" }
const _hoisted_26 = { class: "relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-0" }
const _hoisted_27 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Head = _resolveComponent("Head")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_Layout = _resolveComponent("Layout")!
  const _component_Show = _resolveComponent("Show")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Head, {
      title: _ctx.$trans('table.logs.title')
    }, null, 8, ["title"]),
    _createVNode(_component_Layout, {
      "body-classes": ['bg-white', 'dark:bg-gray-950', 'h-full'],
      "main-classes": ['bg-white', 'dark:bg-gray-950']
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("table", _hoisted_5, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", {
                        scope: "col",
                        class: "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white",
                        innerHTML: _ctx.$trans('table.logs.head.html.id')
                      }, null, 8, _hoisted_6),
                      _createElementVNode("th", {
                        scope: "col",
                        class: "px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white",
                        innerHTML: _ctx.$trans('table.logs.head.html.user_ip')
                      }, null, 8, _hoisted_7),
                      _createElementVNode("th", {
                        scope: "col",
                        class: "px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-white",
                        innerHTML: _ctx.$trans('table.logs.head.html.type_status')
                      }, null, 8, _hoisted_8),
                      _createElementVNode("th", {
                        scope: "col",
                        class: "px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-white",
                        innerHTML: _ctx.$trans('table.logs.head.html.url_function')
                      }, null, 8, _hoisted_9),
                      _createElementVNode("th", {
                        scope: "col",
                        class: "px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-white",
                        innerHTML: _ctx.$trans('table.logs.head.html.data_info')
                      }, null, 8, _hoisted_10),
                      _createElementVNode("th", {
                        scope: "col",
                        class: "px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-white",
                        innerHTML: _ctx.$trans('table.logs.head.html.created_at')
                      }, null, 8, _hoisted_11),
                      _cache[0] || (_cache[0] = _createElementVNode("th", {
                        scope: "col",
                        class: "relative py-3.5 pl-3 pr-4 sm:pr-0"
                      }, [
                        _createElementVNode("span", { class: "sr-only" }, "Actions")
                      ], -1))
                    ])
                  ]),
                  _createElementVNode("tbody", _hoisted_12, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logs.data, (log) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: log.id,
                        class: "cursor-default"
                      }, [
                        _createElementVNode("td", _hoisted_13, _toDisplayString(log.id), 1),
                        _createElementVNode("td", _hoisted_14, [
                          _createElementVNode("div", _hoisted_15, _toDisplayString(log.user_id || _ctx.$trans('main.shared.undefined_up')), 1),
                          _createElementVNode("div", _hoisted_16, _toDisplayString(log.ip || _ctx.$trans('main.shared.undefined_up')), 1)
                        ]),
                        _createElementVNode("td", _hoisted_17, [
                          _createElementVNode("div", _hoisted_18, _toDisplayString(log.type), 1),
                          _createVNode(_component_Badge, {
                            type: log.status,
                            text: log.status,
                            class: "mt-1"
                          }, null, 8, ["type", "text"])
                        ]),
                        _createElementVNode("td", _hoisted_19, [
                          _createVNode(_component_Tooltip, {
                            text: log.url
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.truncateText(log.url, 15)), 1)
                            ]),
                            _: 2
                          }, 1032, ["text"]),
                          _createVNode(_component_Tooltip, {
                            text: log.function
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.truncateText(log.function, 15)), 1)
                            ]),
                            _: 2
                          }, 1032, ["text"])
                        ]),
                        _createElementVNode("td", _hoisted_22, [
                          _createVNode(_component_Tooltip, {
                            text: log.encoded_data
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.truncateText(log.encoded_data, 15)), 1)
                            ]),
                            _: 2
                          }, 1032, ["text"]),
                          _createVNode(_component_Tooltip, {
                            text: log.info
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.truncateText(log.info, 15)), 1)
                            ]),
                            _: 2
                          }, 1032, ["text"])
                        ]),
                        _createElementVNode("td", _hoisted_25, _toDisplayString(log.created_at), 1),
                        _createElementVNode("td", _hoisted_26, [
                          _createElementVNode("span", {
                            onClick: ($event: any) => (_ctx.showLog(log)),
                            class: "text-primary-600 dark:text-primary-800 hover:text-primary-900 dark:hover:text-primary-700 cursor-pointer"
                          }, _toDisplayString(_ctx.$trans('table.shared.show')), 9, _hoisted_27)
                        ])
                      ]))
                    }), 128))
                  ])
                ])
              ])
            ])
          ])
        ]),
        _createVNode(_component_Pagination, { data: _ctx.logs }, null, 8, ["data"])
      ]),
      _: 1
    }),
    (_ctx.showedLog)
      ? (_openBlock(), _createBlock(_component_Show, {
          key: 0,
          log: _ctx.showedLog,
          isOpen: _ctx.drawerOpen,
          onClose: _ctx.close
        }, null, 8, ["log", "isOpen", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}