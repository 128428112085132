<template>
  <Head :title="$trans(`error.${status}.title`)"/>
  <div class="flex flex-col justify-center items-center px-6 mx-auto h-screen xl:px-0 dark:bg-gray-900">
    <div class="block md:max-w-lg">
      <img src="/dist/images/error.svg" class="filter-black dark:filter-white" alt="Error illustration">
    </div>
    <div class="text-center xl:max-w-4xl">
      <h1 class="mb-3 text-2xl font-bold leading-tight text-gray-900 sm:text-4xl lg:text-5xl dark:text-white">
        {{ $trans(`error.${status}.title`) }}
      </h1>
      <p class="mb-5 text-base font-normal text-gray-500 md:text-lg dark:text-gray-400">
        {{ $trans(`error.${status}.description`) }}
      </p>
      <div class="inline-flex rounded-md shadow-sm">
        <Link as="button" :href="route('main')" class="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-transparent rounded-lg border border-gray-900 hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
          <svg class="mr-2 w-4 h-4 fill-current" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"></path>
          </svg>
          {{ $trans("main.back") }}
        </Link>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, onUnmounted, reactive } from "vue";
import { Head, Link } from '@inertiajs/vue3';
import route from '@/ziggy';

export default defineComponent({
  components: {
    Link,
    Head
  },
  props: {
    status: {
      type: Number,
      required: false,
      default: 500
    },
  },
  setup () {
    const root: HTMLElement = reactive(document.documentElement as HTMLElement)
    const dark: Boolean = localStorage.getItem('theme') === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)

    onMounted(() => {
      if (dark) root.classList.add('dark');
    });
    onUnmounted(() => {
      if (dark) root.classList.remove('dark');
    });

    return {
      route
    }
  }
});
</script>