import { default as LightColumn } from '@/Types/Resources/Column/Light';
import type { updateColumnFunction } from '@/Pages/Boards/Types';
import { PayloadResponse } from '@/Types/API/PayloadResponse';
import { updateSyncToken } from '@/utils';
import { ref, computed, Ref } from 'vue';
import { sendRequest } from '@/mixins';

type MicroCard = {
  id: string
  column_id: string
  position: number
}

export function useCardDragControl(columns: Ref<LightColumn[]>, boardId: string, syncTokenTimeout: NodeJS.Timeout|null, syncToken: Ref<string | null>, updateColumn: updateColumnFunction)
{
  const isCardBeingDragged = ref<boolean>(false);
  const cardDragOptions = computed(() => ({
    group: { 
      name: 'cards',
      pull: true,
      put: (to, from) => from.options.group.name === 'cards', // true
      revertClone: true 
    },
    easing: "cubic-bezier(0.2, 1, 0.1, 1)",
    handle: 'card-drag-handle',
    invertedSwapThreshold: 0.5,
    emptyInsertThreshold: 100,
    removeCloneOnHide: true,
    ghostClass: 'ghost-card',
    ignoreTransition: true,
    filter: '.card-no-drag',
    delayOnTouchOnly: true,
    touchStartThreshold: 1,
    dragoverBubble: false,
    preventOnFilter: true,
    direction: 'vertical',
    fallbackTolerance: 3,
    supportPointer: true,
    forceFallback: true,
    swapThreshold: 0.3,
    invertSwap: true,
    animation: 150,
    itemKey: 'id',
    sort: true,
    delay: 10,
  }));

  function onCardPositionChange(event: any, column: LightColumn): void
  {
    let card = (event.added || event.moved)?.element;
    let data: {
      board_id: string,
      moved_column: { id: string|null, cards: Array<MicroCard>|null },
      from_column: { id: string|null, cards: Array<MicroCard>|null },
      to_column: { id: string|null, cards: Array<MicroCard>|null }
    } = { 
      board_id: boardId,
      moved_column: { id: null, cards: [] },
      from_column: { id: null, cards: [] },
      to_column: { id: null, cards: [] }
    };

    if (event.added) {
      let oldColumn = getColumnById(card?.column_id);
      let newColumn = getColumnById(column.id);
      
      if (oldColumn) {
        data.from_column.id = oldColumn.id;
        data.from_column.cards = oldColumn.cards.data.map((c, index) => ({
          id: c.id,
          column_id: oldColumn.id,
          position: index
        }));
      }

      if (newColumn) {
        data.to_column.id = newColumn.id;
        data.to_column.cards = newColumn.cards.data.map((c, index) => ({
          id: c.id,
          column_id: newColumn.id,
          position: index
        }));
      }
    } else if (event.moved) {
      data.moved_column.id = column.id;
      data.moved_column.cards = column.cards.data.map((c, index) => ({
        id: c.id,
        column_id: column.id,
        position: index
      }));
    }
    
    if ((data.from_column.id != null && data.to_column.id != null) || data.moved_column.id != null) {
      sendRequest({
        name: 'api.v1.boards.cards.update.positions',
        params: { board: boardId },
        sync_token: updateSyncToken(syncToken, syncTokenTimeout),
        data: data
      }, (data: PayloadResponse<Array<LightColumn>>) => {
        data.payload.forEach((column) => {
          updateColumn(column);
        });
      });
    }
  }

  function onCardDragStart(): void
  {
    isCardBeingDragged.value = true;
  }

  function onCardDragEnd(): void
  {
    isCardBeingDragged.value = false;
  }

  function getColumnById(columnId: string): LightColumn | null
  {
    return columns.value.find(column => column.id === columnId) || null;
  }

  return {
    onCardPositionChange,
    isCardBeingDragged,
    updateSyncToken,
    cardDragOptions,
    onCardDragStart,
    onCardDragEnd,
  };
}