<template>
  <Layout :site-id="site.id" :site-name="site.name">
    <template #content>
      <div class="divide-gray-200 dark:divide-gray-800 divide-y">
        <div class="grid lg:max-w-full max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 class="text-gray-900 dark:text-white text-base font-semibold leading-7">
              {{ `${$trans('main.sites.edit_title')} ${site.name}` }}
            </h2>
            <p class="text-gray-600 dark:text-gray-400 mt-1 text-sm leading-6">
              {{ $trans('main.sites.edit_description') }}
            </p>
          </div>
          <form class="md:col-span-2" @submit.prevent="submit">
            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
              <div class="col-span-6 sm:col-span-3">
                <Input id="site_name" 
                  type="text"
                  name="s_name"
                  label="validation.attributes.s_name"
                  autocomplete="on"
                  v-model="editForm.s_name"
                  div-class="mt-2 relative rounded-md shadow-sm"
                  :required="false"
                  :form="editForm"/>
              </div>
              <div class="col-span-6 sm:col-span-3">
                <Input id="site_tag" 
                  type="text"
                  name="s_tag"
                  label="validation.attributes.s_tag"
                  autocomplete="on"
                  v-model="editForm.s_tag"
                  div-class="mt-2 relative rounded-md shadow-sm"
                  :required="false"
                  :form="editForm"/>
              </div>
              <div class="col-span-6 sm:col-span-3">
                <Input id="site_path" 
                  type="text"
                  name="s_path"
                  label="validation.attributes.s_path"
                  autocomplete="on"
                  v-model="editForm.s_path"
                  div-class="mt-2 relative rounded-md shadow-sm"
                  :required="false"
                  :form="editForm"/>
              </div>
              <div class="col-span-6 sm:col-span-3">
                <Select id="active"
                  label="validation.attributes.s_active" 
                  name="s_active" 
                  v-model="editForm.s_active"
                  value="item" :items="[true, false]"
                  div-class="mt-2 relative rounded-md shadow-sm"
                  :required="false"
                  :errors="editForm.errors"/>
              </div>
              <div class="col-span-6 sm:col-span-3">
                <Select id="protocol"
                  label="validation.attributes.s_protocol" 
                  name="s_protocol"
                  v-model="editForm.s_protocol"
                  div-class="mt-2 relative rounded-md shadow-sm"
                  value="item"
                  :items="['http', 'https']"
                  :required="false"
                  :errors="editForm.errors"/>
              </div>
              <div class="col-span-6 sm:col-span-3">
                <Select id="version"
                  label="validation.attributes.s_version" 
                  name="s_version" 
                  v-model="editForm.s_version"
                  div-class="mt-2 relative rounded-md shadow-sm"
                  value="item" 
                  :items="['v1', 'v2']"
                  :required="false"
                  :errors="editForm.errors"/>
              </div>
            </div>
            <div class="mt-6 flex items-center justify-start gap-x-6">
              <!-- Update -->
              <Button
                :text="$trans('main.sites.update_site')"
                :loading="editForm.processing"
                :cursorNotAllowed="!editForm.isDirty"/>
              <!-- Delete -->
              <Button
                @click="setDeleteModalIsOpen(true, site?.id)"
                type="button"
                color="red"
                :text="$trans('table.shared.delete')"/>
            </div>
          </form>
        </div>
      </div>
    </template>
  </Layout>
  <Attempt :open="deleteModalIsOpen" 
    :close="(value: boolean) => deleteModalIsOpen = value" 
    route-name="sites.destroy" 
    :route-argument="{ id: deleteSiteId }" 
    :title="$trans('main.attempts.delete_site.title')" 
    :description="$trans('main.attempts.delete_site.description')" 
    :success-button-text="$trans('main.attempts.buttons.success_button')" 
    :cancel-button-text="$trans('main.attempts.buttons.cancel_button')" />
</template>
<script lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { default as SiteEdit } from '@/Types/Resources/Site/Edit';
import { useForm } from 'laravel-precognition-vue-inertia';
import Layout from '@js/Pages/Sites/Components/Layout.vue';
import Attempt from '@js/Shared/Attempt.vue';
import Button from '@js/Shared/Button.vue';
import { defineComponent, ref } from 'vue';
import Select from '@js/Shared/Select.vue';
import Input from '@js/Shared/Input.vue';
import route from '@/ziggy';

type SiteEditForm = {
  s_name: string;
  s_tag: string;
  s_path: string;
  s_active: boolean;
  s_protocol: string;
  s_version: string;
}

export default defineComponent({
  components: {
    TransitionChild,
    TransitionRoot,
    DialogPanel,
    Attempt,
    Dialog,
    Layout,
    Select,
    Button,
    Input
  },
  props: {
    site: {
      type: Object as () => SiteEdit,
      default: false
    },
  },
  setup(props) {
    const deleteModalIsOpen = ref<boolean>(false);
    const deleteSiteId = ref<number>(0);
    const editForm = useForm<SiteEditForm>('put', () => route('sites.update', {id: props.site.id}), {
      s_name: props.site.name,
      s_tag: props.site.tag,
      s_path: props.site.path,
      s_active: props.site.active,
      s_protocol: props.site.protocol,
      s_version: props.site.version,
    });

    function hasError(name: string): boolean
    {
      return name in editForm.errors;
    }

    function getError(name: string): string
    {
      return editForm.errors.hasOwnProperty(name) && editForm.errors[name]
        ? editForm.errors[name]
        : '';
    }

    function submit(): void
    {
      if(editForm.isDirty && props.site.id){
        editForm.submit({
          preserveState: true,
          preserveScroll: true,
          onSuccess: () => {
            editForm.clearErrors();
          },
          onError: () => {
            editForm.reset();
          }
        });
      }
    }

    function setDeleteModalIsOpen(value: boolean, siteId: number): void
    {
      deleteSiteId.value = siteId;
      deleteModalIsOpen.value = value;
    }

    return {
      setDeleteModalIsOpen,
      deleteModalIsOpen,
      deleteSiteId,
      hasError,
      getError,
      editForm,
      submit,
    };
  }
})
</script>