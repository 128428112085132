import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Star = _resolveComponent("Star")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("path", {
      ref: "pathRef",
      stroke: "white",
      "stroke-opacity": "0.2",
      "stroke-dasharray": "1",
      "stroke-dashoffset": "1",
      pathLength: "1",
      fill: "transparent",
      d: _ctx.pathD,
      class: "invisible"
    }, null, 8, _hoisted_1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uniquePoints, (point, index) => {
      return (_openBlock(), _createBlock(_component_Star, {
        key: index,
        point: point,
        blurId: _ctx.blurId
      }, null, 8, ["point", "blurId"]))
    }), 128))
  ], 64))
}