import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "inline-flex items-center space-x-1 md:space-x-2" }
const _hoisted_2 = { class: "inline-flex items-center" }
const _hoisted_3 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Link = _resolveComponent("Link")!

  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(_ctx.navClass),
    "aria-label": "Breadcrumb"
  }, [
    _createElementVNode("ol", _hoisted_1, [
      _createElementVNode("li", _hoisted_2, [
        _createVNode(_component_Link, {
          href: _ctx.route('dashboard'),
          class: "inline-flex items-center text-gray-700 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white"
        }, {
          default: _withCtx(() => [
            _cache[0] || (_cache[0] = _createElementVNode("svg", {
              class: "w-5 h-5 mr-2.5",
              fill: "currentColor",
              viewBox: "0 0 20 20",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", { d: "M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" })
            ], -1)),
            _createTextVNode(" " + _toDisplayString(_ctx.$trans('main.shared.home')), 1)
          ]),
          _: 1
        }, 8, ["href"])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: `${index}-${page.name}-breadcrumb-link`
        }, [
          _createElementVNode("div", _hoisted_3, [
            _cache[1] || (_cache[1] = _createElementVNode("svg", {
              class: "w-6 h-6 text-gray-400",
              fill: "currentColor",
              viewBox: "0 0 20 20",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                "fill-rule": "evenodd",
                d: "M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z",
                "clip-rule": "evenodd"
              })
            ], -1)),
            _createVNode(_component_Link, {
              href: _ctx.route(page.route, page.argument),
              class: "ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-300 dark:hover:text-white"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(page.name), 1)
              ]),
              _: 2
            }, 1032, ["href"])
          ])
        ]))
      }), 128))
    ])
  ], 2))
}