<template>
  <Head :title="$trans('main.reset_password_title')"/>
  <Layout>
    <div class="relative flex flex-1 flex-col items-center justify-center pb-16 pt-12">
      <Logo/>
      <h1 class="sr-only" v-text="$trans('main.reset_password_title')" />
      <form @submit.prevent="submit" class="w-full max-w-sm sm:bg-white sm:dark:bg-gray-900 sm:border-gray-100 sm:dark:border-gray-750 sm:p-5 sm:border-2 sm:bg-opacity-80 sm:backdrop-blur-sm sm:backdrop-filter sm:rounded-md">
        <div>
          <Input id="email" 
            type="email"
            name="email"
            label="main.email" 
            autocomplete="on"
            v-model="form.email"
            :required="true"
            :form="form as any"/>
        </div>
        <Submit main-class="mt-4"
          title="main.reset_password_action"
          :processing="form.processing"
          :disabled="form.invalid('email')"/>
      </form>
    </div>
    <footer class="relative shrink-0">
      <div class="space-y-4 text-sm text-gray-900 sm:flex sm:items-center sm:justify-center sm:space-x-4 sm:space-y-0">
        <div class="flex justify-center font-medium text-gray-500 dark:text-gray-400">
          <p v-text="$trans('main.already_have') " class="text-center sm:text-left"/>
          <br/>
          <Link as="a" :href="route('login')" class="text-primary-700 hover:underline dark:text-primary-500">
            {{ $trans('main.login_here') }}
          </Link>
        </div>
      </div>
    </footer>
  </Layout>
</template>
<script lang="ts">
import { useForm } from 'laravel-precognition-vue-inertia';
import { Head, Link } from '@inertiajs/vue3';
import Layout from './Components/Layout.vue';
import Submit from './Components/Submit.vue';
import Input from './Components/Input.vue';
import Logo from './Components/Logo.vue';
import Toast from '@/Shared/Toast.vue';
import { defineComponent } from 'vue';
import route from '@/ziggy';

export default defineComponent({
  components: {
    Layout,
    Submit,
    Toast,
    Input,
    Link,
    Head,
    Logo,
  },
  setup() {
    const form = useForm('post', route('reset.store'), {
      email: ''
    });

    const submit = () => form.submit({
      preserveScroll: true,
      onSuccess: () => form.reset(),
    });

    return {
      submit,
      route,
      form,
    };
  },
})
</script>