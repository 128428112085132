import { default as Column } from '@/Types/Resources/Column/Light';
import { ref, computed, Ref, markRaw } from 'vue';
import { updateSyncToken } from '@/utils';
import { sendRequest } from '@/mixins';

export function useColumnDragControl(columns: Ref<Column[]>, boardId: string, syncTokenTimeout: NodeJS.Timeout|null, syncToken: Ref<string|null>)
{
  const isColumnBeingDragged = ref<boolean>(false);
  const columnDragOptions = computed(() => markRaw(({
    group: { name: 'columns', pull: false, put: false },
    easing: 'cubic-bezier(0, 0.55, 0.45, 1)',
    chosenClass: 'sortable-col-chosen',
    handle: 'column-drag-handle',
    ghostClass: 'ghost-column',
    filter: '.column-no-drag',
    emptyInsertThreshold: 50,
    removeCloneOnHide: true,
    direction: 'horizontal',
    delayOnTouchOnly: true,
    preventOnFilter: false,
    dragoverBubble: false,
    fallbackTolerance: 5,
    forceFallback: true,
    animation: 200,
    itemKey: 'id',
    delay: 10,
  })));

  function onColumnPositionChange(): void
  {
    sendRequest({
      name: 'api.v1.columns.positions.update',
      params: { board: boardId },
      sync_token: updateSyncToken(syncToken, syncTokenTimeout),
      data: {
        columns: columns.value.map((column, index) => ({
          id: column.id,
          position: index
        }))
      }
    });
  }

  function onColumnDragStart(): void
  {
    isColumnBeingDragged.value = true;
  }

  function onColumnDragEnd(): void
  {
    isColumnBeingDragged.value = false;
  }

  return {
    onColumnPositionChange,
    isColumnBeingDragged,
    columnDragOptions,
    onColumnDragStart,
    onColumnDragEnd,
    updateSyncToken,
    columns,
  };
}