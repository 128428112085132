import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "block text-sm text-gray-300 list-disc break-words text-left" }
const _hoisted_2 = { class: "text-gray-300 inline-block text-sm list-disc mb-1 break-words text-left" }
const _hoisted_3 = { class: "text-gray-300 block text-lg font-semibold list-disc m-0 text-left break-all" }
const _hoisted_4 = { class: "flex items-center gap-x-3" }
const _hoisted_5 = { class: "text-gray-800 dark:text-white min-w-0 text-sm font-semibold leading-6" }
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["textContent"]
const _hoisted_8 = { class: "text-gray-600 dark:text-gray-400 mt-3 flex items-center gap-x-2.5 text-nano leading-5" }
const _hoisted_9 = ["textContent"]
const _hoisted_10 = {
  key: 0,
  class: "whitespace-nowrap"
}
const _hoisted_11 = { class: "flex flex-row items-center justify-around text-sm text-gray-300 ml-4 list-disc text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Link = _resolveComponent("Link")!
  const _component_LineChart = _resolveComponent("LineChart")!

  return (_openBlock(), _createElementBlock("li", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args))),
    class: "dark:bg-gray-950 text-gray-300 border-gray-300 dark:border-gray-800 px-4 py-4 sm:px-6 lg:px-8 cursor-pointer items-center space-x-4 flex text-sm justify-between list-disc break-words text-left border-t"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: _normalizeClass([{
                'text-green-500 bg-green-100 dark:bg-green-900': _ctx.site.last_beat?.success === true,
                'text-red-500 bg-red-100 dark:bg-red-900': _ctx.site.last_beat?.success === false,
              }, "flex-none rounded-full p-1"])
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("div", { class: "h-2 w-2 rounded-full bg-current" }, null, -1)
            ]), 2),
            _createElementVNode("h2", _hoisted_5, [
              _createVNode(_component_Link, {
                href: _ctx.route('sites.show', _ctx.site.id),
                class: "flex gap-x-2"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    textContent: _toDisplayString(_ctx.site.name),
                    class: "truncate"
                  }, null, 8, _hoisted_6),
                  _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-gray-500 dark:text-gray-400" }, "/", -1)),
                  _createElementVNode("span", {
                    textContent: _toDisplayString(_ctx.site.tag),
                    class: "whitespace-nowrap"
                  }, null, 8, _hoisted_7)
                ]),
                _: 1
              }, 8, ["href"])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("p", {
          textContent: _toDisplayString(_ctx.$trans('main.sites.health_check')),
          class: "truncate"
        }, null, 8, _hoisted_9),
        _cache[3] || (_cache[3] = _createElementVNode("svg", {
          viewBox: "0 0 2 2",
          class: "fill-gray-500 dark:fill-gray-300 h-0.5 w-0.5 flex-none"
        }, [
          _createElementVNode("circle", {
            cx: "1",
            cy: "1",
            r: "1"
          })
        ], -1)),
        (_ctx.site.last_beat?.format_created_at)
          ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.site.last_beat?.format_created_at), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_LineChart, {
        actions: _ctx.site.last_beat?.actions ?? []
      }, null, 8, ["actions"]),
      _cache[4] || (_cache[4] = _createElementVNode("svg", {
        class: "h-5 w-5 flex-none text-gray-400",
        viewBox: "0 0 20 20",
        fill: "currentColor",
        "aria-hidden": "true"
      }, [
        _createElementVNode("path", {
          "fill-rule": "evenodd",
          d: "M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z",
          "clip-rule": "evenodd"
        })
      ], -1))
    ])
  ]))
}