import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "float-left mr-2 mb-4 max-w-full" }
const _hoisted_2 = { class: "text-gray-900 dark:text-gray-400 inline-flex items-center mb-2 text-sm font-semibold text-center" }
const _hoisted_3 = { class: "flex flex-wrap gap-1 items-center" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "relative inline-block text-left" }
const _hoisted_6 = { class: "items-center grid grid-cols-[32px_1fr_32px] p-[4px_8px] relative text-center" }
const _hoisted_7 = {
  key: 1,
  class: "text-gray-500 dark:text-gray-400 z-1 text-sm col-[1_/_span_3] font-semibold tracking-tight leading-10 block relative h-10 m-0 overflow-hidden px-8 overflow-ellipsis whitespace-nowrap row-start-1"
}
const _hoisted_8 = {
  key: 2,
  class: "text-gray-500 dark:text-gray-400 z-1 text-sm col-[1_/_span_3] font-semibold tracking-tight leading-10 block relative h-10 m-0 overflow-hidden px-8 overflow-ellipsis whitespace-nowrap row-start-1"
}
const _hoisted_9 = {
  key: 3,
  class: "text-gray-500 dark:text-gray-400 z-1 box-border block text-sm col-[1_/_span_3] font-semibold row-start-1 h-10 leading-10 m-0 overflow-hidden px-8 relative overflow-ellipsis whitespace-nowrap"
}
const _hoisted_10 = {
  key: 4,
  class: "text-gray-500 dark:text-gray-400 z-1 box-border block text-sm col-[1_/_span_3] font-semibold row-start-1 h-10 leading-10 m-0 overflow-hidden px-8 relative overflow-ellipsis whitespace-nowrap"
}
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "block overflow-x-hidden overflow-y-auto py-0 px-3 pb-3" }
const _hoisted_13 = { class: "block max-h-96" }
const _hoisted_14 = { class: "bg-gray-100 dark:bg-zinc-950 m-0 -mx-3 p-8 block" }
const _hoisted_15 = { class: "text-gray-750 dark:text-gray-400 text-nano font-semibold leading-4 mt-3" }
const _hoisted_16 = { class: "mt-1 relative rounded-md shadow-sm" }
const _hoisted_17 = {
  key: 0,
  class: "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
}
const _hoisted_18 = {
  key: 0,
  class: "mt-2 text-nano text-red-600 dark:text-red-500"
}
const _hoisted_19 = { class: "text-gray-750 dark:text-gray-400 text-nano font-semibold leading-4 mt-3" }
const _hoisted_20 = { class: "block" }
const _hoisted_21 = { class: "grid grid-cols-5 gap-1 mb-2" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { class: "flex flex-row" }
const _hoisted_24 = ["disabled"]
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = {
  key: 0,
  class: "bg-gray-100 dark:bg-gray-900 text-gray-600 dark:text-white rounded m-0 p-[24px_6px] text-center"
}
const _hoisted_27 = {
  key: 1,
  class: "text-gray-750 dark:text-gray-400 text-nano font-semibold leading-4 mt-3"
}
const _hoisted_28 = { class: "pt-1 pb-2" }
const _hoisted_29 = { class: "box-border m-0 p-[0_0_1px_1px] w-full cursor-pointer relative inline-flex items-center text-[var(--ds-text-subtle,#44546f)] text-nano font-bold leading-4" }
const _hoisted_30 = ["checked", "onChange"]
const _hoisted_31 = { class: "flex-grow ml-3 min-w-0" }
const _hoisted_32 = { class: "mb-0 flex items-center flex-grow gap-1 min-w-0" }
const _hoisted_33 = ["onClick"]
const _hoisted_34 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TagIcon = _resolveComponent("TagIcon")!
  const _component_PlusIcon = _resolveComponent("PlusIcon")!
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_ChevronLeftIcon = _resolveComponent("ChevronLeftIcon")!
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_ExclamationCircleIcon = _resolveComponent("ExclamationCircleIcon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_PencilIcon = _resolveComponent("PencilIcon")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TagIcon, { class: "w-4 h-4 mr-1" }),
      _createTextVNode(" " + _toDisplayString(_ctx.$trans('main.boards.titles.tags')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags ?? [], (tag) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (_ctx.tagExistsInBoard(tag.id))
            ? (_openBlock(), _createElementBlock("span", {
                key: tag.id,
                onClick: ($event: any) => (_ctx.openMenu(tag)),
                class: _normalizeClass([`bg-${tag.color}-600 dark:bg-${tag.color}-500`, "text-white cursor-pointer flex flex-wrap h-7 content-center justify-center rounded-md relative mb-0 px-3 max-w-full min-w-12 box-border leading-8 text-sm font-medium text-left overflow-hidden overflow-ellipsis whitespace-nowrap"])
              }, _toDisplayString(tag.title), 11, _hoisted_4))
            : _createCommentVNode("", true)
        ], 64))
      }), 256)),
      _createVNode(_component_Menu, null, {
        default: _withCtx(({ close }) => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_MenuButton, {
              as: "button",
              ref: "menuButtonRef",
              class: "bg-white dark:bg-gray-700 text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 border-gray-200 dark:border-gray-600 border font-semibold rounded-lg text-nano px-2 py-1 text-center inline-flex items-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PlusIcon, {
                  class: _normalizeClass(["w-4 h-4 stroke-2", {'mr-1': !_ctx.tags?.length}])
                }, null, 8, ["class"]),
                (!_ctx.tags?.length)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.$trans('main.boards.buttons.add_tag')), 1)
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 512),
            _createVNode(_component_MenuItems, {
              as: "div",
              class: _normalizeClass([_ctx.mode === 'delete' ? 'w-64' : 'w-60', "bg-white dark:bg-gray-950 border-gray-200 dark:border-gray-750 divide-gray-100 dark:divide-gray-750 shadow-lg absolute left-0 mt-2 origin-top-right border z-50 block text-white font-sans text-sm leading-5 font-normal rounded-lg box-border outline-none overflow-hidden"])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  (_ctx.mode === 'create' || _ctx.mode === 'edit' || _ctx.mode === 'delete')
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mode === 'delete' ? _ctx.mode = 'edit' : _ctx.mode = null)),
                        class: "text-gray-500 dark:text-gray-400 z-2 col-start-1 row-start-1 rounded-lg flex w-8 h-8 justify-center items-center bg-transparent border-none cursor-pointer m-0 p-0 outline-none"
                      }, [
                        _createVNode(_component_ChevronLeftIcon, { class: "w-4 h-4 stroke-2" })
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.mode === 'create')
                    ? (_openBlock(), _createElementBlock("h2", _hoisted_7, _toDisplayString(_ctx.$trans('main.boards.titles.tag_creating')), 1))
                    : (_ctx.mode === 'edit')
                      ? (_openBlock(), _createElementBlock("h2", _hoisted_8, _toDisplayString(_ctx.$trans('main.boards.titles.tag_editing')), 1))
                      : (_ctx.mode === 'delete')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$trans('main.boards.titles.tag_deleting')), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$trans('main.boards.titles.tags')), 1)),
                  _createElementVNode("button", {
                    onClick: ($event: any) => (close()),
                    class: "z-2 text-gray-500 dark:text-gray-400 rounded-md col-start-3 row-start-1 p-1.5"
                  }, [
                    _createVNode(_component_XMarkIcon, { class: "w-4 h-4 stroke-2" })
                  ], 8, _hoisted_11)
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    (_ctx.mode == 'create' || _ctx.mode == 'edit')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("span", {
                              class: _normalizeClass([`bg-${_ctx.form?.b_t_color}-600 dark:bg-${_ctx.form?.b_t_color}-500`, "text-white inline-block relative mb-0 rounded px-3 max-w-full min-w-[48px] h-8 box-border leading-8 text-sm font-medium text-left overflow-hidden overflow-ellipsis whitespace-nowrap w-full"])
                            }, _toDisplayString(_ctx.form.b_t_title), 3)
                          ]),
                          _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$trans('validation.attributes.b_t_title')), 1),
                          _createElementVNode("div", _hoisted_16, [
                            _withDirectives(_createElementVNode("input", {
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.b_t_title) = $event)),
                              onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.form.validate('b_t_title'))),
                              onKeydown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.ignoreSpace && _ctx.ignoreSpace(...args))),
                              class: _normalizeClass([{
                        'border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700': !_ctx.form.invalid('b_t_title'),
                        'border-red-300 dark:border-red-500 text-red-700 dark:text-red-500 focus:ring-red-500 focus:border-red-500 pr-10': _ctx.form.invalid('b_t_title'),
                      }, "border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700 bg-gray-50 dark:bg-gray-850 dark:focus:ring-primary-500 dark:focus:border-primary-500 my-1 block w-full focus:outline-none sm:text-sm rounded-md"])
                            }, null, 34), [
                              [_vModelText, _ctx.form.b_t_title]
                            ]),
                            (_ctx.form.invalid('b_t_title'))
                              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                  _createVNode(_component_ExclamationCircleIcon, { class: "h-5 w-5 text-red-500" })
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          (_ctx.form.invalid('b_t_title'))
                            ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(_ctx.form.errors['b_t_title']), 1))
                            : _createCommentVNode("", true),
                          _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$trans('validation.attributes.b_t_color')), 1),
                          _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("div", _hoisted_21, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors ?? [], (color) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  onClick: ($event: any) => (_ctx.form.b_t_color = color),
                                  class: _normalizeClass([{'border border-primary-500 dark:border-primary-600': _ctx.form.b_t_color == color}, "relative rounded border-2 h-8 block border-transparent"])
                                }, [
                                  _createElementVNode("div", {
                                    class: _normalizeClass([`bg-${color}-600 dark:bg-${color}-500 ${_ctx.form.b_t_color == color ? 'selected-color' : ''}`, "relative rounded w-full h-full cursor-pointer block"])
                                  }, null, 2)
                                ], 10, _hoisted_22))
                              }), 256))
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_23, [
                            _createElementVNode("button", {
                              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.submit())),
                              disabled: _ctx.form.invalid('b_t_title') || _ctx.form.invalid('b_t_color'),
                              class: "text-gray-500 dark:text-gray-400 w-full text-sm leading-5 font-medium box-border inline-flex items-center justify-center rounded cursor-pointer p-1.5 no-underline whitespace-normal shadow-none border-none"
                            }, _toDisplayString(_ctx.mode === 'create' ? _ctx.$trans('main.boards.buttons.create_tag') : _ctx.$trans('main.boards.buttons.update_tag')), 9, _hoisted_24),
                            (_ctx.mode === 'edit')
                              ? (_openBlock(), _createElementBlock("button", {
                                  key: 0,
                                  type: "button",
                                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setMode('delete'))),
                                  class: "text-red-500 dark:text-red-400 w-full text-sm leading-5 font-medium box-border inline-flex items-center justify-center rounded cursor-pointer p-1.5 no-underline whitespace-normal shadow-none border-none"
                                }, _toDisplayString(_ctx.$trans('main.boards.buttons.delete_tag')), 1))
                              : _createCommentVNode("", true)
                          ])
                        ], 64))
                      : _createCommentVNode("", true),
                    (_ctx.mode === 'delete')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createElementVNode("span", {
                            class: "mb-1.5 break-word block w-max leading-relaxed",
                            innerHTML: _ctx.$trans('main.boards.descriptions.delete_tag')
                          }, null, 8, _hoisted_25),
                          _createVNode(_component_Button, {
                            text: _ctx.$trans('main.shared.buttons.delete'),
                            onClick: _ctx.destroyTag,
                            size: "sm",
                            classes: "w-full justify-center",
                            type: "button",
                            color: "red"
                          }, null, 8, ["text", "onClick"])
                        ], 64))
                      : _createCommentVNode("", true),
                    (_ctx.mode === null)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.search) = $event)),
                            onKeydown: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.ignoreSpace && _ctx.ignoreSpace(...args))),
                            class: "border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700 bg-gray-50 dark:bg-gray-850 dark:focus:ring-primary-500 dark:focus:border-primary-500 my-1 block w-full pr-10 focus:outline-none text-xs rounded-md"
                          }, null, 544), [
                            [_vModelText, _ctx.search]
                          ]),
                          (!_ctx.searchBoardTags.length)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_26, _toDisplayString(_ctx.$trans('main.boards.no_results')), 1))
                            : _createCommentVNode("", true),
                          (_ctx.searchBoardTags.length)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_27, _toDisplayString(_ctx.$trans('main.boards.titles.tags')), 1))
                            : _createCommentVNode("", true),
                          _createElementVNode("ul", _hoisted_28, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchBoardTags ?? [], (tag) => {
                              return (_openBlock(), _createElementBlock("li", {
                                key: tag.id,
                                class: "pb-1 last:pb-0"
                              }, [
                                _createElementVNode("label", _hoisted_29, [
                                  _createElementVNode("input", {
                                    checked: _ctx.tagIsSelected(tag),
                                    onChange: ($event: any) => (_ctx.toggleTag(tag)),
                                    type: "checkbox",
                                    class: "relative flex-shrink-0 overflow-hidden whitespace-nowrap transition-all duration-200 ease-in-out w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 dark:ring-offset-gray-800 dark:bg-gray-850 dark:border-gray-750"
                                  }, null, 40, _hoisted_30),
                                  _createElementVNode("span", _hoisted_31, [
                                    _createElementVNode("div", _hoisted_32, [
                                      _createElementVNode("span", {
                                        class: _normalizeClass([`bg-${tag.color}-600 dark:bg-${tag.color}-500`, "w-full inline-block relative mb-0 rounded px-3 max-w-full min-w-[48px] h-8 box-border leading-8 text-white text-sm font-medium text-left overflow-hidden overflow-ellipsis whitespace-nowrap"])
                                      }, _toDisplayString(tag.title), 3),
                                      _createElementVNode("button", {
                                        onClick: ($event: any) => (_ctx.setMode('edit',tag)),
                                        class: "flex-shrink-0 mb-0 p-2 bg-transparent border-none shadow-none text-sm leading-5 box-border inline-flex items-center justify-center rounded cursor-pointer no-underline whitespace-normal font-medium transition-colors duration-75 ease-in"
                                      }, [
                                        _createVNode(_component_PencilIcon, { class: "w-4 h-4 stroke-2 text-gray-750 dark:text-gray-400" })
                                      ], 8, _hoisted_33)
                                    ])
                                  ])
                                ])
                              ]))
                            }), 128))
                          ])
                        ], 64))
                      : _createCommentVNode("", true),
                    (_ctx.mode == null)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 3,
                          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setMode('create'))),
                          disabled: _ctx.form.invalid('b_t_title') || _ctx.form.invalid('b_t_color'),
                          class: "text-gray-500 dark:text-gray-400 w-full text-sm leading-5 font-medium box-border inline-flex items-center justify-center rounded cursor-pointer p-1.5 no-underline whitespace-normal shadow-none border-none"
                        }, _toDisplayString(_ctx.$trans('main.boards.buttons.create_tag')), 9, _hoisted_34))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["class"])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}