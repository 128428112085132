<template>
  <div class="hidden md:block" v-if="actions.length > 2" :id="chartName"/>
</template>
<style>
  .ct-grid {
    @apply !stroke-none;
  }
  .ct-line {
    @apply !stroke-[2px];
  }
  .ct-series-a .ct-line {
    @apply !stroke-green-500;
  }
</style>
<script lang="ts">
import { LineChart, Interpolation, LineChartData } from 'chartist';
import { Action } from '@/Types/Resources/Heartbeat/Light';
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
  props: {
    actions: {
      type: Array as () => Array<Action>,
      default: () => [],
      required: false,
    },
    width: {
      type: String,
      default: '155px',
      required: false,
    },
    height: {
      type: String,
      default: '30px',
      required: false,
    },
  },
  setup (props) {
    const chartName = 'chart-stats-' + Date.now() + '-' + Math.random().toString(36).substring(2, 11);

    function prepareDataForChart(data: Array<Action>): LineChartData
    {
      const labels: string[] = data.map(item => item.date);
      const series: number[][] = [data.map(item => item.count)];
      
      const config = {
        labels: labels,
        series: series,
      };

      return config;
    }

    function calculateAverage(data: Array<Action>): { low: number, high: number }
    {
      if(data.length <= 2){
        return { 
          low:0, 
          high:0
        };
      }

      let minCount = data[0].count;
      let maxCount = data[0].count;
      let total = 0;

      for (const point of data) {
        total += point.count;
        if (point.count < minCount) {
          minCount = point.count;
        }
        if (point.count > maxCount) {
          maxCount = point.count;
        }
      }

      const low = (total - maxCount) / (data.length - 1);
      const high = (total - minCount) / (data.length - 1);

      return { low, high };
    }

    onMounted(() => {
      if(!props.actions 
      || props.actions?.length <= 2) {
        return;
      }

      const { low, high } = calculateAverage(props?.actions);
      
      new LineChart(
        document.getElementById(chartName),
        prepareDataForChart(props?.actions),
        {
          axisY: {
            showLabel: false,
          },
          axisX: {
            showLabel: false,
            showGrid: false,
          },
          lineSmooth: Interpolation.cardinal({
            tension: 0,
            fillHoles: true
          }),
          showArea: false,
          showPoint: false,
          areaBase: 0,
          showGridBackground: false,
          width: props.width,
          height: props.height,
          // width: '200px',
          // height: '90px',
          low: low,
          high: high,
        }
      );
    });

    return {
      chartName
    };
  },
});
</script>