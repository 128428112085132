import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" }
const _hoisted_2 = { class: "relative inline-block align-bottom bg-white dark:bg-gray-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" }
const _hoisted_3 = { class: "sm:flex sm:items-start" }
const _hoisted_4 = { class: "mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left" }
const _hoisted_5 = { class: "mt-2" }
const _hoisted_6 = { class: "text-sm text-gray-600 dark:text-gray-400" }
const _hoisted_7 = { class: "mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogOverlay = _resolveComponent("DialogOverlay")!
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_DialogTitle = _resolveComponent("DialogTitle")!
  const _component_Link = _resolveComponent("Link")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createBlock(_component_TransitionRoot, {
    as: "template",
    show: _ctx.open
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Dialog, {
        as: "div",
        class: "fixed z-50 inset-0 overflow-y-auto",
        onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setAttemptModalIsOpen(false)))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_TransitionChild, {
              as: "template",
              enter: "ease-out duration-300",
              "enter-from": "opacity-0",
              "enter-to": "opacity-100",
              leave: "ease-in duration-200",
              "leave-from": "opacity-100",
              "leave-to": "opacity-0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DialogOverlay, { class: "fixed inset-0 bg-black bg-opacity-50" })
              ]),
              _: 1
            }),
            _cache[5] || (_cache[5] = _createElementVNode("span", {
              class: "hidden sm:inline-block sm:align-middle sm:h-screen",
              "aria-hidden": "true"
            }, "​", -1)),
            _createVNode(_component_TransitionChild, {
              as: "template",
              enter: "ease-out duration-300",
              "enter-from": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
              "enter-to": "opacity-100 translate-y-0 sm:scale-100",
              leave: "ease-in duration-200",
              "leave-from": "opacity-100 translate-y-0 sm:scale-100",
              "leave-to": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 dark:bg-red-400 sm:mx-0 sm:h-10 sm:w-10" }, [
                      _createElementVNode("svg", {
                        class: "h-6 w-6 text-red-600 dark:text-red-800",
                        "aria-hidden": "true",
                        xmlns: "http://www.w3.org/2000/svg",
                        fill: "none",
                        viewBox: "0 0 24 24",
                        "stroke-width": "1.5",
                        stroke: "currentColor"
                      }, [
                        _createElementVNode("path", {
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          d: "M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                        })
                      ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_DialogTitle, {
                        as: "h3",
                        class: "text-lg leading-6 font-medium text-gray-900 dark:text-gray-400"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.title), 1)
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.description), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    (_ctx.isApi)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.send())),
                          class: "bg-red-700 dark:bg-red-600 text-white hover:bg-red-800 dark:hover:bg-red-700 focus:ring-red-700 dark:focus:ring-red-800 rounded-lg focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent sm:w-auto sm:text-sm w-full justify-center inline-flex items-center px-3 py-2 text-sm font-medium text-center"
                        }, _toDisplayString(_ctx.successButtonText), 1))
                      : (_openBlock(), _createBlock(_component_Link, {
                          key: 1,
                          as: "button",
                          "preserve-scroll": "",
                          href: _ctx.route(_ctx.routeName, _ctx.routeArgument),
                          method: _ctx.routeMethod,
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setAttemptModalIsOpen(false, true))),
                          class: "bg-red-700 dark:bg-red-600 text-white hover:bg-red-800 dark:hover:bg-red-700 focus:ring-red-700 dark:focus:ring-red-800 rounded-lg focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent sm:w-auto sm:text-sm w-full justify-center inline-flex items-center px-3 py-2 text-sm font-medium text-center"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.successButtonText), 1)
                          ]),
                          _: 1
                        }, 8, ["href", "method"])),
                    _createElementVNode("button", {
                      type: "button",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setAttemptModalIsOpen(false))),
                      class: "bg-primary-700 dark:bg-primary-600 hover:bg-primary-800 dark:hover:bg-primary-700 focus:ring-primary-700 dark:focus:ring-primary-800 text-white rounded-lg sm:mt-0 sm:w-auto sm:text-sm inline-flex w-full mt-3 justify-center focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent items-center px-3 py-2 sm:ml-3 text-sm font-medium text-center"
                    }, _toDisplayString(_ctx.cancelButtonText), 1)
                  ])
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show"]))
}