import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["content"]
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "relative flex flex-1 flex-col overflow-hidden px-4 py-8 sm:px-6 lg:px-8" }
const _hoisted_4 = {
  "aria-live": "assertive",
  class: "z-50 fixed inset-0 flex items-end px-6 py-6 pointer-events-none sm:p-6 sm:items-start"
}
const _hoisted_5 = { class: "w-full flex flex-col items-center space-y-4 sm:items-end" }
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "block md:hidden absolute inset-0 text-gray-900/[0.07] gradient" }
const _hoisted_8 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Head = _resolveComponent("Head")!
  const _component_Toast = _resolveComponent("Toast")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Head, null, {
      default: _withCtx(() => [
        _createElementVNode("meta", {
          name: "theme-color",
          content: _ctx.dark ? '#0E1521' : '#F9FAFB'
        }, null, 8, _hoisted_1),
        _createElementVNode("link", {
          rel: "icon",
          type: "image/svg+xml",
          href: _ctx.dark ? '/favicon-dark.svg' : '/favicon.svg'
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }),
    _createElementVNode("main", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toasts, (toast, index) => {
            return (_openBlock(), _createBlock(_component_Toast, {
              key: index,
              loggedIn: false,
              type: toast.type,
              title: toast.title,
              description: toast.description
            }, null, 8, ["type", "title", "description"]))
          }), 128))
        ])
      ]),
      _createElementVNode("img", {
        class: "left-1/2 w-[70.5rem] hidden xl:block absolute inset-0 dark:filter-white",
        src: `/dist/walls/${_ctx.selectedWallpaper?.path}.svg`,
        alt: _ctx.selectedWallpaper?.name
      }, null, 8, _hoisted_6),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("img", {
          class: "right-1/2 w-[30.5rem] absolute inset-0 dark:filter-white",
          src: `/dist/walls/${_ctx.selectedWallpaper?.path}.svg`,
          alt: _ctx.selectedWallpaper?.name
        }, null, 8, _hoisted_8)
      ]),
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 64))
}