import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "absolute z-50 w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg max-h-60 overflow-auto ring-1 ring-black ring-opacity-5 focus:outline-none" }
const _hoisted_2 = {
  class: "py-1",
  role: "menu",
  "aria-orientation": "vertical",
  "aria-labelledby": "options-menu"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { class: "ml-3" }
const _hoisted_6 = {
  key: 1,
  class: "text-center px-4 py-2 text-sm text-gray-500 dark:text-gray-400"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserAvatar = _resolveComponent("UserAvatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.items.length)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.items, (item, index) => {
            return (_openBlock(), _createElementBlock("button", {
              key: index,
              onClick: ($event: any) => (_ctx.selectItem(index)),
              class: _normalizeClass(["text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:bg-gray-100 dark:focus:bg-gray-700 w-full text-left px-2 py-1 text-sm", { 
            'bg-gray-100 dark:bg-gray-700': index === _ctx.selectedIndex,
            'text-primary-600 dark:text-primary-400': item.is_current_user
          }]),
              role: "menuitem"
            }, [
              _createElementVNode("span", _hoisted_4, [
                _createVNode(_component_UserAvatar, {
                  user: item,
                  size: "xs",
                  withTooltip: false
                }, null, 8, ["user"]),
                _createElementVNode("span", _hoisted_5, _toDisplayString(item.name), 1)
              ])
            ], 10, _hoisted_3))
          }), 128))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$trans('main.boards.no_members_found')), 1))
    ])
  ]))
}