<template>
  <Head>
    <meta name="theme-color" :content="dark ? '#0E1521' : '#F9FAFB'"/>
    <link rel="icon" type="image/svg+xml" :href="dark ? '/favicon-dark.svg' : '/favicon.svg'"/>
  </Head>
  <main class="relative flex flex-1 flex-col overflow-hidden px-4 py-8 sm:px-6 lg:px-8">
    <div aria-live="assertive" class="z-50 fixed inset-0 flex items-end px-6 py-6 pointer-events-none sm:p-6 sm:items-start">
      <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
        <Toast v-for="(toast, index) in toasts"
          :key="index"
          :loggedIn="false"
          :type="toast.type" 
          :title="toast.title" 
          :description="toast.description"/>
      </div>
    </div>
    <img class="left-1/2 w-[70.5rem] hidden xl:block absolute inset-0 dark:filter-white" :src="`/dist/walls/${selectedWallpaper?.path}.svg`" :alt="selectedWallpaper?.name" />
    <div class="block md:hidden absolute inset-0 text-gray-900/[0.07] gradient">
      <img class="right-1/2 w-[30.5rem] absolute inset-0 dark:filter-white" :src="`/dist/walls/${selectedWallpaper?.path}.svg`" :alt="selectedWallpaper?.name" />
    </div>
    <slot/>
  </main>
</template>
<style>
  #app {
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    overflow: hidden;
  }
  .bg-\[color\:var\(--html-bg-color\,theme\(colors\.white\)\)\] {
    background-color: var(--html-bg-color,#fff);
  }
  .gradient {
    -webkit-mask-image: linear-gradient(to top, transparent 70%, white);
    mask-image: linear-gradient(to top, transparent 70%, white);
  }
</style>
<script lang="ts">
import { defineComponent, onMounted, onUnmounted, watch, reactive, ref } from 'vue';
import { Head, Link, usePage } from '@inertiajs/vue3';
import ToastType from '@/Types/App/Feedback/Toast';
import Toast from '@/Shared/Toast.vue';
import route from '@/ziggy';

type Wallpaper = { path: string; name: string; };

export default defineComponent({
  components: {
    Link,
    Head,
    Toast
  },
  setup() {
    const wallpapers: Wallpaper[] = [
      { path: '1', name: 'Maiden Tower' },
      { path: '2', name: 'Zeus Temple' },
      { path: '3', name: 'Amida Buddha' },
      { path: '4', name: 'St Basil Cathedral' },
      { path: '5', name: 'Piramid of Giza' },
      { path: '6', name: 'Moai Statue' },
      { path: '7', name: 'Taj Mahal' },
      { path: '8', name: 'Stonehenge' },
      { path: '9', name: 'Pisa Tower' },
      { path: '10', name: 'Library of Celsus' },
      { path: '11', name: 'Eiffel Tower' },
      { path: '12', name: 'Forbidden City' },
      { path: '13', name: 'Wall of China' },
      { path: '14', name: 'Brandenburger Gate' },
      { path: '15', name: 'Arc De Triomphe' },
      { path: '16', name: 'Kinderdijk Windmills' },
      { path: '17', name: 'National Gallery' },
      { path: '18', name: 'Mount Rushmore' },
      { path: '19', name: 'Liberty Statue' },
      { path: '20', name: 'Berlin Cathedral' },
      { path: '21', name: 'Redeemer Statue' },
      { path: '22', name: 'Opera House' },
      { path: '23', name: 'Ell Castillo' },
      { path: '24', name: 'Burj Al Arab' },
      { path: '25', name: 'Colosseum' },
    ];
    const randomImage: Number = new Number(Math.floor(Math.random() * (25 - 1) + 1));
    const selectedWallpaper = ref<Wallpaper | null>(wallpapers[randomImage as number]);
    const root: HTMLElement = reactive(document.documentElement as HTMLElement)
    const body: HTMLBodyElement = reactive(document.body as HTMLBodyElement)
    const toasts: any[] = reactive([] as Array<ToastType>)
    const dark: Boolean = localStorage.getItem('theme') === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)

    watch(() => usePage().props?.toast, (toast) => {
      if (typeof toast === 'object' && toast !== null && toast !== undefined) {
        setTimeout(() => toasts.push(toast), 600)
      }
    },
    {
      deep: true,
      immediate: true,
    });

    onMounted(() => {
      if (dark) root.classList.add('dark')

      body.classList.add('font-sans', 'antialiased', 'text-gray-600', 'min-h-full', 'flex', 'flex-col', 'bg-white', 'dark:bg-gray-950', '[overflow-anchor:none]')
      root.classList.add('h-full', 'js-focus-visible')
    });
    onUnmounted(() => {
      if (dark) root.classList.remove('dark')

      body.classList.remove('font-sans', 'antialiased', 'text-gray-600', 'min-h-full', 'flex', 'flex-col', 'bg-white', 'dark:bg-gray-950', '[overflow-anchor:none]')
      root.classList.remove('h-full', 'js-focus-visible')
    });

    return {
      selectedWallpaper,
      toasts,
      route,
      dark,
    };
  },
})
</script>