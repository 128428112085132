import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  type: "button",
  class: "bg-transparent hover:bg-gray-200 dark:hover:bg-gray-750 dark:hover:text-white focus:ring-gray-700 dark:focus:ring-primary-950 focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent text-gray-400 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
}

import { XMarkIcon } from '@heroicons/vue/20/solid';

export default /*@__PURE__*/_defineComponent({
  __name: 'Cross',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createVNode(_unref(XMarkIcon), { class: "w-6 h-6" })
  ]))
}
}

})