<template>
  <Head :title="$trans('main.sign_up')"/>
  <Layout>
    <div class="relative flex flex-1 flex-col items-center justify-center sm:pb-16 pt-12">
      <Logo/>
      <h1 class="sr-only" v-text="$trans('main.sign_up')"/>
      <form @submit.prevent="submit" class="sm:bg-white sm:dark:bg-gray-900 sm:border-gray-100 sm:dark:border-gray-750 w-full max-w-sm sm:p-5 sm:border-2 sm:bg-opacity-80 sm:backdrop-blur-sm sm:backdrop-filter sm:rounded-md">
        <div class="mb-6">
          <Input id="r_first_name" 
            type="text"
            name="r_first_name"
            label="validation.attributes.r_first_name" 
            autocomplete="on"
            v-model="registerForm.r_first_name"
            :required="true"
            :form="registerForm as any"/>
        </div>
        <div class="mb-6">
          <Input id="r_last_name" 
            type="text"
            name="r_last_name"
            label="validation.attributes.r_last_name" 
            autocomplete="on"
            v-model="registerForm.r_last_name"
            :required="true"
            :form="registerForm as any"/>
        </div>
        <div class="mb-6">
          <Select
            id="r_country_id"
            label="validation.attributes.r_country_id"
            name="r_country_id"
            v-model="registerForm.r_country_id"
            div-class="mt-2 relative rounded-md shadow-sm"
            value="id" 
            title="name"
            :items="countries"
            :required="false"
            :errors="registerForm.errors"/>
        </div>
        <div class="mb-6">
          <Input id="r_email" 
            type="email"
            name="r_email"
            label="validation.attributes.r_email" 
            autocomplete="on"
            v-model="registerForm.r_email"
            :required="true"
            :form="registerForm as any"/>
        </div>
        <div class="mb-6">
          <Input id="r_password" 
            type="password"
            name="r_password"
            label="validation.attributes.r_password" 
            autocomplete="on"
            v-model="registerForm.r_password"
            :required="true"
            :form="registerForm as any"/>
        </div>
        <div class="mb-6">
          <Input id="r_password_confirmation" 
            type="password"
            name="r_password_confirmation"
            label="validation.attributes.r_password_confirmation" 
            autocomplete="on"
            v-model="registerForm.r_password_confirmation"
            :required="true"
            :form="registerForm as any"/>
				</div>
        <Submit title="main.sign_up"
          main-class="mt-4" 
          :processing="registerForm.processing"
          :disabled="registerForm.invalid('r_first_name') || 
                    registerForm.invalid('r_last_name') || 
                    registerForm.invalid('r_email') || 
                    registerForm.invalid('r_password') || 
                    registerForm.invalid('r_password_confirmation')"/>
      </form>
    </div>
    <footer class="relative shrink-0">
      <div class="space-y-4 text-sm text-gray-900 sm:flex sm:items-center sm:justify-center sm:space-x-4 sm:space-y-0">
        <div class="flex justify-center font-medium text-gray-500 dark:text-gray-400">
          <p v-text="$trans('main.already_have') " class="text-center sm:text-left"/>
          <br/>
          <Link as="a" :href="route('login')" class="text-primary-700 hover:underline dark:text-primary-500">
            {{ $trans('main.login_here') }}
          </Link>
        </div>
      </div>
    </footer>
  </Layout>
</template>
<script lang="ts">
import CountryLight from '@/Types/Resources/World/CountryLight';
import { useForm } from 'laravel-precognition-vue-inertia';
import { Head, Link } from '@inertiajs/vue3';
import Layout from './Components/Layout.vue';
import Submit from './Components/Submit.vue';
import { defineComponent, ref } from "vue";
import Input from './Components/Input.vue';
import Select from '@/Shared/Select.vue';
import Logo from './Components/Logo.vue';
import Toast from '@/Shared/Toast.vue';
import route from '@/ziggy';

type RegisterForm = {
  r_first_name: string,
  r_last_name: string,
  r_country_id: number | null,
  r_email: string,
  r_password: string,
  r_password_confirmation: string,
};

export default defineComponent({
  components: {
    Select,
    Layout,
    Submit,
    Input,
    Toast,
    Logo,
    Link,
    Head,
	},
  props: {
    countries: {
      type: Array as () => Array<CountryLight>,
      default: () => [],
    },
  },
  setup() {
    const registerForm = useForm<RegisterForm>('post', route('register.store'), {
      r_first_name: 'Artem',
      r_last_name: 'Shevchenko',
      r_country_id: null,
      r_email: 'iibigforko@gmail.com',
      r_password: 'secret',
      r_password_confirmation: 'secret',
    });

    const submit = () => registerForm.submit({
      preserveScroll: true,
      onSuccess: () => registerForm.reset(),
    });

    return { 
      registerForm,
      submit,
      route,
    };
  }
});
</script>