import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Constellation = _resolveComponent("Constellation")!
  const _component_Star = _resolveComponent("Star")!

  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 881 211",
    fill: "white",
    "aria-hidden": "true",
    class: _normalizeClass(['pointer-events-none absolute w-[55.0625rem] origin-top-right rotate-[30deg] overflow-visible opacity-70 -right-44 top-14', _ctx.className])
  }, [
    _createElementVNode("defs", null, [
      _createElementVNode("filter", { id: _ctx.blurId }, _cache[0] || (_cache[0] = [
        _createElementVNode("feGaussianBlur", {
          in: "SourceGraphic",
          stdDeviation: ".5"
        }, null, -1)
      ]), 8, _hoisted_1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.constellations, (points, index) => {
      return (_openBlock(), _createBlock(_component_Constellation, {
        key: index,
        points: points,
        blurId: _ctx.blurId
      }, null, 8, ["points", "blurId"]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stars, (point, index) => {
      return (_openBlock(), _createBlock(_component_Star, {
        key: index,
        point: point,
        blurId: _ctx.blurId
      }, null, 8, ["point", "blurId"]))
    }), 128))
  ], 2))
}