<template>
  <Head :title="$trans('main.sites.list_title')"/>
  <Layout>
    <div class="sm:flex px-4 py-4 sm:px-6 sm:py-6 lg:px-8">
      <div class="dark:divide-gray-700 sm:divide-gray-100 items-center mb-3 sm:flex sm:divide-x sm:mb-0">
        <div class="lg:pr-3">
          {{ $trans('main.sites.list_title') }}
        </div>
      </div>
      <div class="flex items-center ml-auto space-x-2 sm:space-x-3">
        <button type="button" @click="setCreateDrawerIsOpen(true)" class="bg-gray-900 dark:bg-primary-800  text-white dark:text-gray-100 hover:bg-gray-700 dark:hover:bg-primary-750 focus:ring-gray-700 dark:focus:ring-primary-950 focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent inline-flex items-center justify-center w-1/2 px-3 py-2 text-sm font-medium text-center rounded-lg sm:w-auto">
          <PlusIcon class="w-5 h-5 mr-2 -ml-1"/>
          {{$trans('main.sites.create_site')}}
        </button>
      </div>
    </div>
    <ul role="list" class="block text-sm text-gray-300 list-disc m-0 pl-0">
      <Site v-if="sites.data.length" v-for="site in sites.data" :site="site"/>
      <div v-else class="flex items-center justify-center my-48">
        <h2 class="text-gray-400 dark:text-gray-300 box-border block text-2xl leading-5 relative text-center">
          {{ $trans('main.sites.list_placeholder') }}
        </h2>
      </div>
    </ul>
    <Pagination v-if="sites.data.length" :data="sites"/>
    <Create :isOpen="createDrawerIsOpen" @close="setCreateDrawerIsOpen(false)"/>
  </Layout>
</template>
<script lang="ts">
import { Bars3Icon, ChevronRightIcon, ChevronUpDownIcon, MagnifyingGlassIcon, PlusIcon } from '@heroicons/vue/20/solid';
import { default as SiteList } from '@/Types/Resources/Site/List';
import Site from '@js/Pages/Sites/Components/Site.vue'
import Pagination from '@js/Shared/Pagination.vue';
import Create from '@js/Pages/Sites/Create.vue'
import Paginator from '@/Types/App/Paginator';
import { defineComponent, ref } from 'vue';
import Layout from '@js/Shared/Layout.vue'
import { Head } from '@inertiajs/vue3'
import 'chartist/dist/index.css';

export default defineComponent({
  props: {
    sites: {
      type: Object as () => Paginator<SiteList>,
      required: true,
    }
  },
  components: {
    MagnifyingGlassIcon,
    ChevronUpDownIcon,
    ChevronRightIcon,
    Pagination,
    Bars3Icon,
    PlusIcon,
    Layout,
    Create,
    Head,
    Site,
  },
  setup() {
    const createDrawerIsOpen = ref(false)

    function setCreateDrawerIsOpen(value: boolean): void
    {
      createDrawerIsOpen.value = value;
    }

    return {
      setCreateDrawerIsOpen,
      createDrawerIsOpen,
    };
  },
})
</script>