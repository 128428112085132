import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Link = _resolveComponent("Link")!

  return (_openBlock(), _createBlock(_component_Link, {
    as: "a",
    href: _ctx.route('login'),
    class: "mx-auto mb-8 w-auto text-slate-900 dark:text-white"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("img", {
        src: "/dist/images/logo-text.svg",
        class: "h-8 dark:filter-white",
        alt: "Atlant Logo"
      }, null, -1)
    ])),
    _: 1
  }, 8, ["href"]))
}