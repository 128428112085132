import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-[--column-width] my-0 mx-1.5 first:ml-3 box-border inline-block h-full scroll-m-2 align-top whitespace-nowrap" }
const _hoisted_2 = { class: "bg-gray-800 w-full h-full whitespace-normal relative flex flex-col box-border rounded-xl p-3 animate-pulse" }
const _hoisted_3 = { class: "space-y-2 overflow-y-hidden flex-grow pr-1" }
const _hoisted_4 = { class: "flex space-x-1 mb-2" }
const _hoisted_5 = { class: "flex justify-between items-center mt-2" }
const _hoisted_6 = { class: "flex space-x-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "h-8 bg-gray-700 rounded-md mb-3 flex items-center justify-between" }, [
        _createElementVNode("div", { class: "w-3/4 h-4 bg-gray-600 rounded ml-1" }),
        _createElementVNode("div", { class: "w-5 h-5 bg-gray-600 rounded-full mr-1 my-0.5" })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.randomValue, (i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i,
            class: "bg-gray-700 rounded-md p-2 shadow-sm"
          }, [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (j) => {
                return _createElementVNode("div", {
                  key: j,
                  class: "h-2 bg-gray-600 rounded w-8"
                })
              }), 64))
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "h-4 bg-gray-600 rounded w-11/12 mb-2" }, null, -1)),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "h-3 bg-gray-600 rounded w-1/2 mb-2" }, null, -1)),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (k) => {
                  return _createElementVNode("div", {
                    key: k,
                    class: "w-5 h-5 bg-gray-600 rounded-full"
                  })
                }), 64))
              ]),
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "w-8 h-3 bg-gray-600 rounded" }, null, -1))
            ])
          ]))
        }), 128))
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "h-12 bg-gray-700 rounded-md mt-3 flex items-center" }, [
        _createElementVNode("div", { class: "w-5 h-5 bg-gray-600 rounded-full ml-2" }),
        _createElementVNode("div", { class: "w-1/2 h-3 bg-gray-600 rounded ml-2" })
      ], -1))
    ])
  ]))
}