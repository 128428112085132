<template>
  <Head>
    <meta name="theme-color" :content="dark ? '#111928' : '#F9FAFB'"/>
    <link rel="icon" type="image/svg+xml" :href="dark ? '/favicon-dark.svg' : '/favicon.svg'"/>
  </Head>
  <header class="bg-white dark:bg-gray-950 border-gray-200 dark:border-gray-800 border-b fixed md:hidden z-40 w-full">
    <div class="h-16 sm:h-[--header-height] w-full flex items-center gap-4 p-3 max-w-none mx-auto">
      <div class="flex-1 select-none relative hover:opacity-80 transition-all" style="width: 28px; height: 28px;">
        <a class="" href="/">
          <div class="relative" style="width: 28px; height: 28px;">
            <div class="" style="width: 28px; height: 28px;">
              <svg class="dark:filter-white" style="vertical-align: top; width: 28px; height: 28px;" width="128" height="151" viewBox="0 0 128 151" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M90.7288 129.489H37.6972L29.817 151H0L62.8286 0H64.9584L128 151H99.0349L90.7288 129.489ZM82.8486 108.831L67.3012 68.3653L63.6805 56.4386L60.2729 68.3653L45.3644 108.831H82.8486Z" fill="black"/>
              </svg>
            </div>
          </div>
        </a>
      </div>
      <!-- <span class="text-sm font-medium text-slate-800 dark:text-zinc-200 capitalize">explore / trending</span> -->
      <div class="flex-1 flex justify-end items-center">
        <div class="flex flex-1 justify-end">
          <BellIcon @click="handleChangeNotificationsDrawerState(!notificationsDrawerIsOpen)" class="h-6 w-6 mr-2.5" aria-hidden="true"/>
          <div v-if="auth.notifications_length"
            class="inline-flex absolute bottom-10 justify-center items-center w-6 h-6 text-nano font-bold text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900">
            {{ auth.notifications_length > 99 ? '99+' : auth.notifications_length }}
          </div>
        </div>
        <Menu as="div" class="relative flex flex-1 justify-end max-w-max">
          <MenuButton class="focus:ring-gray-300 dark:focus:ring-gray-700 relative flex max-w-xs items-center rounded-full text-sm focus:outline-none focus:ring-offset-2 lg:rounded-md lg:p-2 lg:hover:bg-gray-50">
            <span class="absolute -inset-1.5 lg:hidden" />
            <UserAvatar :user="auth.user" :withTooltip="false" size="sm"/>
            <!-- <img class="w-8 h-8 block rounded-full" :class="[ auth.user?.photo_path ? 'object-cover' : 'dark:filter-white']" :src="auth.user?.photo_path ? auth.user.photo_path : '/dist/images/user-placeholder.svg'"/> -->
            <span class="ml-3 hidden text-sm font-medium text-gray-700 lg:block">
              {{ auth.user?.name }}
            </span>
          </MenuButton>
          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="mt-8 border border-gray-200 dark:border-gray-750 origin-top-right absolute py-1 right-0 min-w-max max-w-56 max-h-64 overflow-scroll text-base list-none bg-white rounded-lg divide-gray-100 shadow-sm dark:bg-gray-850 dark:divide-gray-750">
              <ul role="none">
                <li class="truncate select-none py-2 px-3 text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-white">
                  <Link :href="route('profile.edit')" class="cursor-pointer block text-sm">
                    {{$trans("main.settings")}}
                  </Link>
                </li>
                <li class="truncate select-none py-2 px-3 text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-white" @click.stop="dark = !dark">
                  <SwitchGroup as="div" class="flex items-center justify-between cursor-pointer">
                    <SwitchLabel as="span" class="text-sm mr-3" @click="dark = !dark">
                      {{ $trans('main.shared.dark_theme') }}
                    </SwitchLabel>
                    <Switch v-model="dark" :class="[dark ? 'bg-gray-800 dark:bg-primary-600' : 'bg-gray-200 dark:bg-primary-800 ']" class="select-none relative inline-flex h-4 w-7 items-center rounded-full" @click.stop="dark = !dark">
                      <span :class="[dark ? 'translate-x-3 bg-white' : 'translate-x-1 bg-white dark:bg-primary-400']" class="inline-block h-3 w-3 transform rounded-full transition"></span>
                    </Switch>
                  </SwitchGroup>
                </li>
                <li class="truncate select-none flex border-t border-gray-100 dark:border-gray-750 py-2 px-3 text-red-700 dark:hover:text-white dark:hover:bg-red-800 hover:bg-red-100">
                  <Link method="delete" :href="route('logout')" as="button" class="cursor-pointer flex-auto truncate block w-full text-sm text-left">
                    {{$trans('main.logout')}}
                  </Link>
                </li>
                <Link as="li" :href="route('changelog')" class="text-gray-400 dark:text-gray-500 border-gray-300 dark:border-gray-700 hover:text-gray-500 dark:hover:text-gray-400 hover:cursor-pointer text-tiny text-left border-t border-dashed select-none flex pt-2 pb-1 px-3">
                  {{ `v.${appVersion}` }}{{ ` · ${$trans('changelog.versions_and')}&nbsp;` }}
                  <span class="relative whitespace-nowrap">
                    {{ $trans('changelog.changelog') }}
                    <span v-if="!user?.has_seen_latest_version" class="absolute top-0 right-0 w-1.5 h-1.5 bg-red-700 dark:text-red-600 rounded-full mt-1 -mr-1"></span>
                  </span>
                </Link>
              </ul>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>
  </header>
  <header class="bg-transparent hidden md:block md:fixed z-40 w-full backdrop-blur-3xl backdrop-filter">
    <div class="flex flex-1 items-center gap-2 py-2.5 mx-4 justify-between">
      <div class="flex items-center overflow-x-auto">
        <div class="relative p-2">
          <Logo :logo-color="logoColor"/>
          <div class="hidden">
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 32 33">
                <path fill="#111827" fill-opacity="0.2" d="M0 18.83L.08 6.832C.094 4.66 1.613 2.798 3.71 2.383L15.297.086c2.784-.552 5.368 1.627 5.35 4.51l-.08 12c-.015 2.171-1.534 4.032-3.631 4.448L5.349 23.341C2.565 23.893-.019 21.714 0 18.83z"></path>
                <path fill="#111827" fill-opacity="0.5" d="M5.677 23.617l.08-12C5.77 9.447 7.29 7.586 9.387 7.17l11.587-2.296c2.784-.552 5.368 1.626 5.349 4.51l-.08 12c-.014 2.171-1.533 4.032-3.63 4.448l-11.587 2.297c-2.784.551-5.368-1.627-5.349-4.51z"></path>
                <path fill="#111827" fill-opacity="0.8" d="M11.354 28.404l.08-12c.014-2.172 1.533-4.032 3.63-4.448l11.587-2.297c2.784-.552 5.368 1.627 5.349 4.51l-.08 12c-.014 2.172-1.533 4.033-3.63 4.448l-11.587 2.297c-2.784.552-5.368-1.627-5.35-4.51z"></path>
            </svg>
          </div>
        </div>
        <ul class="flex gap-4 ml-4 items-center">
          <template v-for="item in navigation">
            <li v-if="item.show" :key="item.name" class="text-ellipsis whitespace-nowrap flex items-end min-h-6">
              <HeaderItem :item="item" :itemColor="navigationColor"/>
            </li>
          </template>
        </ul>
      </div>
      <div class="flex items-center justify-end gap-2">
        <button @click="handleChangeNotificationsDrawerState(!notificationsDrawerIsOpen)" class="focus:ring-gray-900 dark:focus:ring-primary-700 lg:hover:bg-gray-100/50 lg:dark:hover:bg-gray-700/50 bg-gray-200/50 dark:bg-gray-800/50 mr-2 relative flex items-center rounded-full text-sm focus:outline-none focus:ring-2 md:rounded-md md:p-1.5">
          <BellIcon class="h-6 w-6" aria-hidden="true"/>
          <div v-if="notificationsLength"class="inline-flex absolute bottom-6 ml-4 justify-center items-center min-w-5 h-5 text-nano font-bold text-white bg-red-500 rounded-full">
            <span class="p-0.5">
              {{ notificationsLength > 99 ? '99+' : notificationsLength }}
            </span>
          </div>
        </button>
        <TransitionRoot as="template" :show="notificationsDrawerIsOpen" appear focus-trap="self">
          <Dialog as="div" class="z-50 fixed inset-0 overflow-hidden" @close="handleChangeNotificationsDrawerState(false)">
            <div class="absolute inset-0 overflow-hidden motion-safe:transition-all motion-safe:duration-300 motion-safe:ease-in-out">
              <TransitionChild
                as="template"
                enter="transition-opacity ease-linear duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0">
                <DialogOverlay class="absolute inset-0 bg-transparent"/>
              </TransitionChild>
              <!-- Drawer -->
              <div class="pointer-events-none fixed top-12 sm:top-20 right-4 flex max-w-[90vw]">
                <TransitionChild
                  @after-enter="notificationAfterEnter = true"
                  @after-leave="notificationAfterEnter = false"
                  as="template"
                  enter="transform transition ease-in-out duration-500"
                  enter-from="translate-x-full"
                  enter-to="-translate-x-0"
                  leave="transform transition ease-in-out duration-500"
                  leave-from="-translate-x-0"
                  leave-to="translate-x-full">
                  <div class="pointer-events-auto w-screen max-w-sm">
                    <DialogPanel class="rounded-lg pt-3 bg-gray-200/60 dark:bg-gray-800/70 backdrop-blur-xl dark:backdrop-blur-md">
                      <div class="sm:flex sm:items-start rounded-b-lg">
                        <div class="text-left w-full max-w-[90vw] px-1">
                          <DialogTitle as="h3" class="pl-3 text-base font-semibold leading-6 text-gray-900 dark:text-white">
                            {{ $trans('notification.title') }}
                          </DialogTitle>
                          <div class="bg-white dark:bg-gray-900 mt-4 max-h-[60vh] overflow-y-auto no-scrollbar rounded-lg">
                            <div class="pb-[--user-notification-dropdown-footer-height] rounded-t-lg rounded-b-lg">
                              <template v-if="!notificationsHideOrShowLoading">
                                <component 
                                  :is="notification.url ? 'Link' : 'div'"
                                  v-if="(user?.hide_read_notifications ? unreadNotifications : notifications.data).length > 0"
                                  v-for="notification in (user?.hide_read_notifications ? unreadNotifications : notifications.data)"
                                  :key="notification.id"
                                  :href="notification.url"
                                  :class="notification.url != null ? 'cursor-pointer' : 'cursor-default'"
                                  class="flex px-4 py-3 hover:bg-gray-50 dark:hover:bg-gray-950 !outline-none">
                                  <div class="flex-shrink-0">
                                    <UserAvatar :user="notification.initiator" size="md" :with-tooltip="false"/>
                                  </div>
                                  <div class="w-full ps-3">
                                    <div class="text-gray-500 text-sm mb-1.5 dark:text-gray-400 flex flex-row">
                                      <div class="flex flex-1">
                                        <div v-html="notification.header"/>
                                      </div>
                                      <div v-if="!notification.is_read" class="flex flex-1 h-2 w-2 rounded-xl max-w-2 bg-primary-700 dark:bg-primary-600">
                                      </div>
                                    </div>
                                    <div class="text-gray-600 dark:text-gray-500 text-xs flex flex-row">
                                      <div class=" flex flex-1 flex-row">
                                        {{ formatCustomDate(notification.created_at, lang) }}
                                      </div>
                                      <div class="flex flex-1 flex-row justify-end" :class="{'text-primary-600 dark:text-primary-500': !notification.is_read}">
                                        {{ formatTime(notification.created_at) }}
                                      </div>
                                    </div>
                                    <div v-if="notification.footer != null" class="bg-gray-100 dark:bg-gray-850 text-gray-900 dark:text-gray-100 text-small flex flex-row p-2 rounded-md mt-1">
                                      <div v-html="truncateHTML(notification.footer, 500)"/>
                                    </div>
                                  </div>
                                </component>
                                <div v-else class="mx-auto mt-7 max-w-52">
                                  <div class="mb-6 -space-y-6 px-2 min-h-28">
                                    <template v-if="isAnimatedPlaceholder && (user?.hide_read_notifications ? unreadNotifications : notifications.data).length === 0">
                                      <div class="bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700 motion-preset-slide-up-lg -translate-x-4 -rotate-6 rounded-lg border-2 p-2 shadow-lg motion-ease-out-back">
                                        <div class="bg-gray-100 dark:bg-gray-700 mb-1 h-4 w-full rounded-md"></div>
                                        <div class="bg-gray-100 dark:bg-gray-700 h-3 w-3/4 rounded-md"></div>
                                      </div>
                                      <div class="bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700 motion-preset-slide-up-lg translate-x-4 rotate-6 rounded-lg border-2 p-2 shadow-lg motion-delay-150 motion-ease-out-back">
                                        <div class="bg-gray-100 dark:bg-gray-700 mb-1 h-4 w-full rounded-md"></div>
                                        <div class="bg-gray-100 dark:bg-gray-700 h-3 w-3/4 rounded-md"></div>
                                      </div>
                                      <div class="bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700 motion-preset-slide-up-lg -translate-x-4 -rotate-6 rounded-lg border-2 p-2 shadow-lg motion-delay-300 motion-ease-out-back">
                                        <div class="bg-gray-100 dark:bg-gray-700 mb-1 h-4 w-full rounded-md"></div>
                                        <div class="bg-gray-100 dark:bg-gray-700 h-3 w-3/4 rounded-md"></div>
                                      </div>
                                    </template>
                                  </div>
                                  <div class="pb-10 text-center">
                                    <p class="text-gray-900 dark:text-white font-semibold">
                                      {{ $trans('notification.empty_state.title') }}
                                    </p>
                                    <p class="text-gray-500 dark:text-gray-400 mt-1 text-xs font-regular">
                                      {{ $trans('notification.empty_state.description') }}
                                    </p>
                                  </div>
                                </div>
                              </template>
                              <div v-else class="min-h-28">
                                <div role="status" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
                                  <svg class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25"/>
                                    <circle class="spinner_7WDj" cx="12" cy="2.5" r="1.5"/>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="items-center flex flex-row space-x-2 justify-between absolute bottom-0 left-0 right-0 bg-gray-200/60 dark:bg-gray-800/70 backdrop-blur-md h-[--user-notification-dropdown-footer-height] rounded-b-lg px-4 py-3">
                            <button @click="markAllAsRead" type="button" class="bg-white dark:bg-white/10 hover:bg-gray-50 dark:hover:bg-white/20 text-gray-900 dark:text-white ring-gray-300 dark:ring-transparent truncate flex-1 flex flex-row items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset justify-center">
                              <EnvelopeOpenIcon class="w-4 h-4 mr-1 stroke-2" aria-hidden="true"/>
                              <span class="truncate">
                                {{ $trans('notification.mark_all_as_read') }}{{ notificationsMarkedAsReadLoading ? '...' : '' }}
                              </span>
                            </button>
                            <button @click="hideOrShowNotification" type="button" class="bg-primary-600 dark:bg-primary-700 hover:bg-primary-500 dark:hover:bg-primary-600 text-white flex-1 truncate rounded-md px-3 py-2 text-sm font-semibold shadow-sm">
                              {{ user?.hide_read_notifications ? $trans('notification.view_all') : $trans('notification.hide_read') }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </DialogPanel>
                  </div>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </TransitionRoot>
        <Menu as="div" class="relative">
          <MenuButton class="focus:ring-gray-900 dark:focus:ring-primary-700 lg:hover:bg-gray-100/50 lg:dark:hover:bg-gray-700/50 bg-gray-200/50 dark:bg-gray-800/50 min-w-48 max-w-56 space-x-2 justify-between relative flex items-center rounded-full text-sm focus:outline-none focus:ring-2 md:rounded-md md:p-1.5">
            <span class="absolute -inset-1.5 lg:hidden" />
            <UserAvatar :user="auth.user" :withTooltip="false" size="xs"/>
            <span class="flex min-w-0 flex-1 flex-col">
              <span class="text-gray-700 dark:text-white truncate text-sm font-medium">
                {{ auth.user?.name }}
              </span>
              <span class="truncate text-sm text-gray-600 dark:text-gray-300">
                {{ `@${auth.user?.nickname}` }}
              </span>
            </span>
            <ChevronUpDownIcon class="text-gray-600 dark:text-gray-300 ml-1 hidden h-5 w-5 flex-shrink-0 md:block" aria-hidden="true" />
          </MenuButton>
          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="border border-gray-200 dark:border-gray-750 origin-top-right absolute py-1 mt-2 right-0 min-w-[-webkit-fill-available] min-w-[-moz-available] max-w-56 max-h-64 overflow-scroll text-base list-none bg-white rounded-lg divide-gray-100 shadow-sm dark:bg-gray-850 dark:divide-gray-750">
              <ul role="none">
                <li class="select-none py-2 px-3 text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-white">
                  <Link :href="route('profile.edit')" class="cursor-pointer block text-sm">
                    {{$trans("main.settings")}}
                  </Link>
                </li>
                <li class="select-none py-2 px-3 text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-white" @click.stop="dark = !dark">
                  <SwitchGroup as="div" class="flex items-center justify-between cursor-pointer">
                    <SwitchLabel as="span" class="text-sm" @click="dark = !dark">
                      {{ $trans('main.shared.dark_theme') }}
                    </SwitchLabel>
                    <Switch v-model="dark" :class="[dark ? 'bg-gray-800 dark:bg-primary-600' : 'bg-gray-200 dark:bg-primary-800 ']" class="select-none relative inline-flex h-4 w-7 items-center rounded-full" @click.stop="dark = !dark">
                      <span :class="[dark ? 'translate-x-3 bg-white' : 'translate-x-1 bg-white dark:bg-primary-400']" class="inline-block h-3 w-3 transform rounded-full transition"></span>
                    </Switch>
                  </SwitchGroup>
                </li>
                <li class="select-none flex border-t border-gray-100 dark:border-gray-750 py-2 px-3 text-red-700 dark:hover:text-white dark:hover:bg-red-800 hover:bg-red-100">
                  <Link method="delete" :href="route('logout')" as="button" class="cursor-pointer flex-auto truncate block w-full text-sm text-left">
                    {{$trans('main.logout')}}
                  </Link>
                </li>
                <Link as="li" :href="route('changelog')" class="text-gray-400 dark:text-gray-500 border-gray-300 dark:border-gray-700 hover:text-gray-500 dark:hover:text-gray-400 hover:cursor-pointer text-tiny text-left border-t border-dashed select-none flex pt-2 pb-1 px-3">
                  {{ `v.${appVersion}` }}{{ ` · ${$trans('changelog.versions_and')}&nbsp;` }}
                  <span class="relative whitespace-nowrap">
                    {{ $trans('changelog.changelog') }}
                    <span v-if="!user?.has_seen_latest_version" class="absolute top-0 right-0 w-1.5 h-1.5 bg-red-700 dark:text-red-600 rounded-full mt-1 -mr-1"></span>
                  </span>
                </Link>
              </ul>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>
  </header>
  <div class="h-16 sm:h-[--header-height]"/>
</template>
<script lang="ts">
import { Menu, MenuButton, MenuItems, Switch, SwitchGroup, SwitchLabel, RadioGroupOption, RadioGroup, MenuItem  } from '@headlessui/vue';
import { defineComponent, computed, ref, reactive, onMounted, onUnmounted, watch, PropType, nextTick, toRef, Ref } from 'vue';
import { Dialog, DialogOverlay, DialogTitle, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { default as HeadItem } from '@/Types/App/Components/HeaderItem';
import { PayloadResponse } from '@/Types/API/PayloadResponse';
import { EnvelopeOpenIcon } from '@heroicons/vue/24/outline';
import { ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import { useRelativeTime, formatCustomDate } from '@/utils';
import { isPermitted, isCurrentRoute } from '@/mixins';
import { usePage, Link, Head } from '@inertiajs/vue3';
import { BellIcon } from '@heroicons/vue/24/outline';
import UserAvatar from '@/Shared/UserAvatar.vue';
import HeaderItem from '@/Shared/HeaderItem.vue';
import { isMac, sendRequest } from '@/mixins';
import { truncateHTML } from '@/mixins';
import Logo from '@/Shared/Logo.vue';
import Auth from "@/Types/App/Auth";
import { throttle } from 'lodash';
import { Roles } from '@/enums';
import route from '@/ziggy';

export default defineComponent({
  // INFO: Navbar height is 4.6rem
  name: 'Header',
  emits: [
    'change-notifications-drawer-state',
    'update-notifications'
  ],
  props: {
    logoColor: {
      type: Array as PropType<string[]>,
      default: ['dark:filter-white'],
    },
    navigationColor: {
      type: Array as PropType<string[]>,
      default: ['text-slate-800', 'dark:text-gray-200'],
    },
    propNotificationsLength: {
      type: Number as PropType<number>,
      default: 0,
    },
    propNotificationsDrawerIsOpen: {
      type: Boolean as PropType<Boolean>,
      default: false,
    },
    propNotifications: {
      type: Object as PropType<Auth['notifications']>,
      default: [],
    },
  },
  components: {
    ChevronUpDownIcon,
    RadioGroupOption,
    EnvelopeOpenIcon,
    TransitionChild,
    TransitionRoot,
    DialogOverlay,
    DialogTitle,
    SwitchGroup,
    SwitchLabel,
    DialogPanel,
    UserAvatar,
    RadioGroup,
    HeaderItem,
    MenuButton,
    MenuItems,
    MenuItem,
    BellIcon,
    Dialog,
    Switch,
    Head,
    Menu,
    Link,
    Logo
  },
  setup(props, { emit }) {
    const appVersion = process.env.MIX_APP_VERSION;
    const cmdOpen = ref<Boolean>(false);
    const auth = computed(() => usePage().props?.auth as Auth);
    const user = ref<Auth['user']>(auth.value.user);
    const root: HTMLElement = reactive(document.documentElement as HTMLElement);
    const body: HTMLBodyElement = reactive(document.body as HTMLBodyElement);
    const dark = ref<boolean>(false);
    const localStorageDark: boolean = localStorage.getItem('theme') === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches);

    const notifications = toRef(props, 'propNotifications') as Ref<Auth['notifications']>;
    const unreadNotifications = computed<Auth['notifications']['data']>(() => notifications.value.data.filter((notification) => !notification.is_read));
    const notificationsLength = toRef(props, 'propNotificationsLength') as Ref<number>;
    const notificationsHideOrShowLoading = ref<boolean>(false);
    const notificationsMarkedAsReadLoading = ref<boolean>(false);
    const notificationsDrawerIsOpen = toRef(props, 'propNotificationsDrawerIsOpen') as Ref<boolean>;
    const notificationAfterEnter = ref<boolean>(false);
    const isAnimatedPlaceholder = ref<boolean>(false);
    const lang = computed<string>(() => (usePage().props?.lang == 'ua' ? 'uk' : usePage().props?.lang) as string);
    const { formatRelativeTime } = useRelativeTime();
    const formatTime = (date: string | null): string | null => {
      return formatRelativeTime(date, lang.value).value;
    };
    
    const navigation: Array<HeadItem> = [
      {
        link_name: 'boards',
        link_params: { status: 'active' },
        name: "main.side_menu.boards.title",
        current: isCurrentRoute(['boards']),
        show: true,
      },
      {
        link: 'sites',
        name: "main.side_menu.sites.title",
        current: isCurrentRoute(['sites', 'sites.edit', 'sites.show']),
        show: isPermitted([Roles.SUPER_ADMIN, Roles.ADMIN]),
      },
      {
        link: 'logs', 
        name: 'main.side_menu.activity.inside.logs',
        show: isPermitted([Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MODERATOR]),
        current: isCurrentRoute(['logs']),
      },
      {
        link: 'reports', 
        name: 'main.side_menu.activity.inside.reports',
        show: isPermitted([Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MODERATOR]),
        current: isCurrentRoute(['reports'])
      },
      {
        link: 'commands', 
        name: 'table.commands.title',
        show: isPermitted([Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MODERATOR]),
        current: isCurrentRoute(['commands'])
      },
      {
        link: 'users',
        name: 'main.side_menu.settings.inside.users',
        show: isPermitted([Roles.SUPER_ADMIN, Roles.ADMIN]),
        current: isCurrentRoute(['users'])
      },
    ];

    // useTransitionFix(notificationsDrawerIsOpen);

    watch(() => dark.value, (value) => darkMode(value));
    watch(notificationAfterEnter, (value) => {
      if (value) {
        isAnimatedPlaceholder.value = false;

        nextTick(() => {
          setTimeout(() => {
            isAnimatedPlaceholder.value = true;
          }, 100);
        });
      }else{
        isAnimatedPlaceholder.value = false;
      }
    });

    const markAllAsRead = throttle(async (): Promise<void> =>
    {
      if(unreadNotifications.value.length === 0) return;

      let oldNotifications = notifications.value.data;
      let newNotifications = notifications.value;

      newNotifications.data.forEach(notification => {
        notification.is_read = true;
      });

      handleUpdateNotifications(newNotifications);

      await sendRequest({
        name: 'api.v1.notifications.mark-as-read'
      }, undefined, () => {
        handleUpdateNotifications({
          ...notifications.value,
          data: oldNotifications
        });
      });
    }, 1000);

    const hideOrShowNotification = throttle(async (): Promise<void> =>
    {      
      let oldHideReadNotifications = user.value?.hide_read_notifications ?? false;

      if(oldHideReadNotifications){
        notificationsHideOrShowLoading.value = true;
      }
      if(user.value !== null){
        user.value.hide_read_notifications = !user.value.hide_read_notifications;
      }

      await sendRequest({
        name: 'api.v1.notifications.toggle-hide-read-and-get'
      }, (response: PayloadResponse<{user: Auth['user'], notifications: Auth['notifications']}>) => {
        user.value = response.payload.user;
        handleUpdateNotifications(response.payload.notifications);
        notificationsHideOrShowLoading.value = false;
      }, () => {
        if(user.value !== null){
          user.value.hide_read_notifications = oldHideReadNotifications;
        }
        notificationsHideOrShowLoading.value = false;
      });
    }, 1000);

    function handleChangeNotificationsDrawerState(value: boolean): void
    {
      emit('change-notifications-drawer-state', value);
    }

    function handleUpdateNotifications(newNotifications: Auth['notifications']): void
    {
      emit('update-notifications', newNotifications);
    }

    function darkMode(value: boolean): void 
    {
      if(value){
        root.classList.add('dark');
        localStorage.setItem('theme', 'dark');
        dark.value = true;
      }else{
        root.classList.remove("dark");
        localStorage.setItem('theme', 'light');
        dark.value = false;
      }
    };

    function setCmdOpen(value: Boolean, callback: Function): void
    {
      cmdOpen.value = value;
      callback();
    }

    onMounted(() => {
      dark.value = localStorageDark;
      
      root.classList.add(dark.value ? 'dark' : 'light');
      body.classList.add('h-full');
    });

    onUnmounted(() => {
      root.classList.remove(dark.value ? 'dark' : 'light');
      body.classList.remove('h-full');

      dark.value = false;
    });

    return {
      handleChangeNotificationsDrawerState,
      notificationsMarkedAsReadLoading,
      notificationsHideOrShowLoading,
      notificationsDrawerIsOpen,
      hideOrShowNotification,
      notificationAfterEnter,
      isAnimatedPlaceholder,
      notificationsLength,
      unreadNotifications,
      formatCustomDate,
      notifications,
      markAllAsRead,
      truncateHTML,
      formatTime,
      appVersion,
      navigation,
      setCmdOpen,
      darkMode,
      isMac,
      route,
      dark,
      auth,
      user,
      lang
    };
  },
});
</script>