<template>
  <Head>
    <title>{{ $trans('main.boards.titles.boards') }}</title>
    <template v-for="{ bg_path_medium } in boards">
      <link v-if="bg_path_medium" rel="prefetch" as="image" :href="bg_path_medium"/>
    </template>
  </Head>
  <Layout :status="status" :statuses="statuses" :members="members">
    <VueDraggableNext class="select-none isolate mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-5"
      @change="onBoardPositionChange"
      @start="onBoardDragStart"
      @end="onBoardDragEnd"
      itemKey="id"
      v-model="boards"
      :group="{ name: 'boards', pull: 'clone', put: false }"
      :sort="true"
      handle=".drag-handle"
      filter=".board-no-drag"
      :animation="200"
      :delay="50"
      easing="cubic-bezier(0, 0.55, 0.45, 1)"
      ghostClass="ghost"
      chosenClass="sortable-board-chosen"
      dragClass="sortable-drag"
      :fallbackOnBody="false"
      :fallbackClass="'sortable-fallback'"
      :dragoverBubble="false"
      :removeCloneOnHide="true"
      :forceFallback="true"
      direction="vertical"
      :swapThreshold="0.65"
      :invertSwap="true"
      :delayOnTouchOnly="true"
      :fallbackTolerance="3"
      :touchStartThreshold="3">
      <div class="drag-handle h-max sm:max-w-xs group bg-gray-50 dark:bg-gray-800/50 cursor-pointer overflow-hidden shadow-sm hover:shadow-md rounded-lg flex flex-col border border-gray-200 dark:border-gray-700 relative"
        :style="getBoardBackgroundStyle(board)"
        @click.prevent="openBoard(board)"
        v-for="board in boards"
        :key="board.id">
        <div class="p-4 sm:p-5 flex-grow flex flex-col">
          <div :class="{'mb-3': board.three_latest_actions.length > 0}" class="flex justify-between items-start">
            <div class="flex items-center w-1/3 flex-grow space-x-2">
              <div v-if="board.color" :style="`background-color: ${board.color};`" class="min-w-1 min-h-5 rounded-full"/>
              <h3 class="dark:text-white text-base sm:text-lg font-semibold truncate pr-8" :class="board.bg_path_medium ? 'text-white' : 'text-gray-900'">
                {{ board.name }}
              </h3>
            </div>
            <div class="flex items-center flex-shrink-0 space-x-2">
              <span class="text-sm font-medium px-2 py-0.5 rounded-full"
                :class="{
                  'bg-green-100 dark:bg-green-500/10 text-green-700 dark:text-green-400': board.status === 'active',
                  'bg-yellow-100 dark:bg-yellow-400/10 text-yellow-700 dark:text-yellow-500': board.status === 'inactive',
                  'bg-gray-100 dark:bg-gray-400/10 text-gray-700 dark:text-gray-400': board.status === 'archived',
                }">
                {{ $trans(`main.boards.statuses.${board.status}`) }}
              </span>
              <button @click.stop="openEditBoard(board)" class="p-1 bg-white dark:bg-gray-700 rounded-full shadow-md text-gray-400 hover:text-gray-600 dark:text-gray-300 dark:hover:text-gray-100">
                <svg class="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path>
                </svg>
              </button>
            </div>
          </div>
          <div v-if="board.three_latest_actions.length > 0" class="mt-2 bg-gray-200/90 dark:bg-gray-800/80 rounded-md px-2">
            <ul class="divide-y divide-gray-400/40 dark:divide-gray-600/50">
              <li v-for="action in board.three_latest_actions" @click.stop="openBoardWithCard(board.id, action.card_id)" :key="action.card_id" class="group/item flex items-center space-x-2 py-1.5 cursor-pointer">
                <span class="flex-shrink-0 w-1.5 h-1.5 bg-blue-500 rounded-full"></span>
                <div class="flex-grow min-w-0 flex justify-between items-center board-no-drag">
                  <p v-html="action.short_content" class="text-gray-600 dark:text-gray-300 group-hover/item:text-gray-900 dark:group-hover/item:text-white text-tiny truncate"/>
                  <p class="text-nano text-gray-500 dark:text-gray-400 whitespace-nowrap ml-2">
                    {{ action.created_at_diff }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="bg-gray-50 dark:bg-gray-700/80 px-2 py-2 flex items-center justify-between text-sm text-gray-500 dark:text-gray-400 border-t border-gray-200 dark:border-gray-600">
          <span class="flex items-center">
            <ClockIcon class="mr-1.5 h-3.5 w-3.5 stroke-2"/>
            {{ board.last_action_diff }}
          </span>
          <span class="flex items-center">
            <UserGroupIcon class="mr-1.5 h-3.5 w-3.5 stroke-2"/>
            {{ board.members_count }}
          </span>
        </div>
      </div>
    </VueDraggableNext>
    <div v-if="!boards.length" class="flex flex-col flex-1 py-4 md:py-8 md:pt-0">
      <div class="relative w-full">
        <div class="relative flex justify-center items-center py-8 md:py-12">
          <div class="flex flex-col justify-center items-center gap-2">
            <FaceFrownIcon class="text-gray-400 dark:text-gray-600 w-8 h-8"/>
            <span class="text-sm text-gray-400 dark:text-gray-600">
              {{ $trans('main.boards.no_boards_found') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <Edit
    :is-open="isOpenBoardEdit"
    :board="beindEditedBoard"
    :members="members"
    :statuses="statuses"
    @close="isOpenBoardEdit = false"/>
</template>
<style scoped>
.scrollbar-thin::-webkit-scrollbar-thumb { background-color: rgba(156, 163, 175, 0.5); border-radius: 2px;}
.dark .scrollbar-thin::-webkit-scrollbar-thumb { background-color: rgba(75, 85, 99, 0.5); }
.scrollbar-thin::-webkit-scrollbar { width: 4px; }
.ghost { opacity: 0; }
</style>
<script lang="ts">
import { ExclamationCircleIcon, ClockIcon, UserGroupIcon, FaceFrownIcon } from '@heroicons/vue/24/outline';
import { defineComponent, ref, watch, onMounted, toRef } from 'vue';
import { default as BoardList } from '@/Types/Resources/Board/List';
import { default as Member } from '@/Types/Resources/User/Member';
import { PayloadResponse } from '@/Types/API/PayloadResponse';
import { VueDraggableNext } from 'vue-draggable-next';
import { router, Head } from '@inertiajs/vue3';
import Layout from './Components/Layout.vue';
import { dragscroll } from 'vue-dragscroll';
import Button from '@/Shared/Button.vue';
import { sendRequest } from '@/mixins';
import Edit from './Edit.vue';
import route from '@/ziggy';

export default defineComponent({
  directives: {
    dragscroll: dragscroll
  },
  props: {
    status: {
      type: String as () => 'active' | 'inactive' | 'archived',
      required: true
    },
    boards: {
      type: Array as () => Array<BoardList>,
      required: true,
    },
    statuses: {
      type: Array as () => Array<string>,
      required: true
    },
    members: {
      type: Array as () => Array<Member>,
      required: true
    }
  },
  components: {
    ExclamationCircleIcon,
    VueDraggableNext,
    UserGroupIcon,
    FaceFrownIcon,
    ClockIcon,
    Button,
    Layout,
    Edit,
    Head
  },
  setup(props){
    const loadedMediumImages = ref<Set<string>>(new Set());
    const isBoardBeingDragged = ref<boolean>(false);
    const boards = toRef<Array<BoardList>>(props.boards);
    const createDrawerIsOpen = ref<boolean>(false);
    const editDrawerIsOpen = ref<boolean>(false);
    const editBoard = ref<BoardList | null>(null);
    const isOpenBoardEdit = ref<boolean>(false);
    const beindEditedBoard = ref<BoardList | null>(null);
    const deleteModalIsOpen = ref<boolean>(false);
    const deleteBoardId = ref<string | null>(null);

    watch(() => props.boards, (newBoards: Array<BoardList>) => {
      boards.value = newBoards;
      loadBoardImages();
    }, {
      immediate: true
    });

    function openBoard(board: BoardList): void
    {
      if(isBoardBeingDragged.value) return;

      router.get(route('boards.show', board.id));
    }

    function openEditBoard(board: BoardList): void
    {
      if(board.hash_id === null){
        sendRequest({
          name: 'api.v1.boards.regenerate-hash',
          params: {
            board: board.id
          }
        }, (data: PayloadResponse<{hash_id: number, hash_link: string, hash_encoded: string}>) => {
          let newBoard = {
            ...board,
            hash_id: data.payload.hash_id,
            hash_encoded: data.payload.hash_encoded
          };

          handleUpdateBoard(newBoard);
          
          beindEditedBoard.value = newBoard;
          isOpenBoardEdit.value = true;
        });
      }else{
        beindEditedBoard.value = board;
        isOpenBoardEdit.value = true;
      }
    }

    function openBoardWithCard(boardId: string, cardId: string): void
    {
      router.get(route('boards.show', {
        board: boardId,
        card_id: cardId
      }));
    }

    function onBoardDragStart(): void
    {
      isBoardBeingDragged.value = true;
    }

    function onBoardDragEnd(): void
    {
      setTimeout(() => {
        isBoardBeingDragged.value = false;
      }, 100);
    }

    function onBoardPositionChange(): void
    {
      sendRequest({
        name: 'api.v1.boards.positions.update',
        data: {
          status: props.status,
          boards: boards.value.flatMap((board, index) => ({
            id: board.id,
            position: index
          }))
        },
      });
    }

    function handleUpdateBoards(data: Array<BoardList>): void
    {
      boards.value = data;
    }

    function handleUpdateBoard(board: BoardList): void
    {
      handleUpdateBoards(boards.value.map((b: BoardList) => {
        if(b.id === board.id){
          b = board;
        }

        return b;
      }));
    }

    function getBoardBackgroundStyle(board: BoardList): string
    {
      const imagePath = loadedMediumImages.value.has(board.id) 
        ? board.bg_path_medium 
        : board.bg_path_thumbnail;

      return imagePath
        ? `background-image: url('${imagePath}'); 
          background-size: cover; 
          background-position: center; 
          background-repeat: no-repeat; width: 100%;`
          // background-blend-mode: color-burn;`
        : '';
    }

    function loadBoardImages(): void
    {
      boards.value.forEach((board: BoardList) => {
        if (board.bg_path_thumbnail !== null && board.bg_path_medium !== null) {
          const mediumImg = new Image();

          mediumImg.onload = () => {
            loadedMediumImages.value.add(board.id);
          };

          mediumImg.src = board.bg_path_medium;
        }
      });
    }

    onMounted(() => {
      loadBoardImages();
    });

    return {
      getBoardBackgroundStyle,
      onBoardPositionChange,
      createDrawerIsOpen,
      deleteModalIsOpen,
      openBoardWithCard,
      onBoardDragStart,
      beindEditedBoard,
      editDrawerIsOpen,
      isOpenBoardEdit,
      onBoardDragEnd,
      deleteBoardId,
      openEditBoard,
      openBoard,
      editBoard,
      boards,
      route
    };
  }
});
</script>