<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto" @close="handleClose()">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-black bg-opacity-50"/>
        </TransitionChild>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template"
          enter="ease-out duration-300" 
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" 
          enter-to="opacity-100 translate-y-0 sm:scale-100" 
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100" 
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="bg-white dark:bg-gray-900 text-left relative inline-block text-base font-normal leading-5 rounded-lg overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl w-full">
            <form @submit.prevent="submit()" class="relative rounded-lg shadow">
              <!-- Modal header -->
              <div class="flex items-center justify-between p-4 border-b rounded-t md:px-6 dark:border-gray-800">
                  <div class="text-xl font-semibold dark:text-white">
                    {{ $trans('main.boards.titles.edit_card') }}
                  </div>
                  <button @click="handleClose()" type="button" class="text-gray-400 bg-transparent hover:bg-gray-300 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white">
                      <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                      </svg>
                  </button>
              </div>
              <!-- Modal body -->
              <div class="p-4 space-y-6 md:px-6">
                <div class="grid grid-cols-2 gap-6 mb-4">
                  <div class="col-span-6 sm:col-span-3">
                    <Input id="b_c_c_title" 
                      type="text"
                      name="b_c_c_title"
                      label="validation.attributes.b_c_c_title" 
                      autocomplete="on"
                      v-model="form.b_c_c_title"
                      :required="true"
                      :form="form as any"/>
                  </div>
                </div>
              </div>
              <!-- Modal footer -->
              <div class="flex items-center p-4 space-x-3 border-t border-gray-200 rounded-b md:p-6 dark:border-gray-800">
                <Button :text="$trans('main.boards.buttons.update_card')"
                  :loading="form.processing"
                  :cursorNotAllowed="!form.isDirty"
                  :disabled="form.invalid('b_c_c_title')"/>
                <button type="button" @click="handleClose()" class="w-24 text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 hover:border-gray-300 font-semibold rounded-lg text-sm py-2.5 text-center dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:bg-gray-600">
                    {{ $trans('main.shared.buttons.cancel') }}
                </button>
              </div>
            </form>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script lang="ts">
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { default as Card } from '@/Types/Resources/Card/Micro';
import { defineComponent, toRef, watch, PropType } from 'vue';
import { PayloadResponse } from '@/Types/API/PayloadResponse';
import { useForm } from 'laravel-precognition-vue-inertia';
import { sendRequest, getApiToken } from '@/mixins';
import { PlusIcon } from '@heroicons/vue/20/solid';
import TextArea from '@/Shared/TextArea.vue';
import Button from '@/Shared/Button.vue';
import { Link } from '@inertiajs/vue3';
import Input from '@/Shared/Input.vue';
import route from '@/ziggy';

export default defineComponent({
  props: {
    open: {
      type: Boolean,
      required: true
    },
    card: {
      type: [Object, null] as PropType<Card|null>,
      default: ""
    },
  },
  components: {
    TransitionChild,
    TransitionRoot,
    DialogOverlay,
    DialogTitle,
    PlusIcon,
    TextArea,
    Button,
    Dialog,
    Input,
    Link,
  },
  setup(props, { emit }) {
    const open = toRef(props, 'open');
    const form = useForm('put', () => route('api.v1.boards.cards.update', {
      card: props.card?.id,
    }), {
      _token: getApiToken(),
      _number: null,
      b_c_c_title: props.card?.title || '',
    });

    function handleClose(value: Boolean = false): void
    {
      emit('close', value);

      form.clearErrors();
    }

    function handleCard(card: Card): void
    {
      emit('update-card', card);
    }

    watch(() => props.card, (card) => {
      if(card) {
        form.b_c_c_title = card.title || '';
      }
    });

    watch(() => props.open, (isOpen) => {
      if(isOpen) {
        form.defaults();
      }else{
        form.b_c_c_title = '';

        form.clearErrors();
      }
    });

    function submit()
    {
      sendRequest({
        name: 'api.v1.boards.cards.update',
        params: {
          card: props.card?.id,
        },
        data: {
          b_c_c_title: form.b_c_c_title,
        }
      }, (data: PayloadResponse<Card>) => {
        handleCard(data.payload);
        handleClose();
      }, (errors) => {
        form.setErrors(errors);
      });
    }

    return {
      handleClose,
      handleCard,
      submit,
      route,
      open,
      form,
    }
  },
});
</script>