import { createElementVNode as _createElementVNode, openBlock as _openBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sticky bottom-[-1px] right-0 lg:rounded-b-lg items-center w-full p-4 bg-white border-t border-gray-200 sm:flex sm:justify-between dark:bg-gray-900 dark:border-gray-850" }
const _hoisted_2 = { class: "flex items-center mb-8 sm:mb-0 justify-center sm:justify-end" }
const _hoisted_3 = { class: "text-sm font-normal select-none text-gray-500 dark:text-gray-400" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "font-semibold text-gray-900 dark:text-white" }
const _hoisted_6 = { class: "flex items-center space-x-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Link = _resolveComponent("Link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Link, {
        as: "button",
        disabled: !_ctx.data.prev_page_url,
        href: _ctx.data.prev_page_url ? _ctx.data.prev_page_url : `#`,
        "preserve-scroll": "",
        class: "hidden sm:inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-850 dark:hover:text-white"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("svg", {
            class: "w-7 h-7",
            fill: "currentColor",
            viewBox: "0 0 20 20",
            xmlns: "http://www.w3.org/2000/svg"
          }, [
            _createElementVNode("path", {
              "fill-rule": "evenodd",
              d: "M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z",
              "clip-rule": "evenodd"
            })
          ], -1)
        ])),
        _: 1
      }, 8, ["disabled", "href"]),
      _createVNode(_component_Link, {
        as: "button",
        disabled: !_ctx.data.next_page_url,
        href: _ctx.data.next_page_url ? _ctx.data.next_page_url : `#`,
        "preserve-scroll": "",
        class: "hidden sm:inline-flex justify-center p-1 mr-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-850 dark:hover:text-white"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("svg", {
            class: "w-7 h-7",
            fill: "currentColor",
            viewBox: "0 0 20 20",
            xmlns: "http://www.w3.org/2000/svg"
          }, [
            _createElementVNode("path", {
              "fill-rule": "evenodd",
              d: "M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z",
              "clip-rule": "evenodd"
            })
          ], -1)
        ])),
        _: 1
      }, 8, ["disabled", "href"]),
      _createElementVNode("span", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.$trans('pagination.showing')) + " ", 1),
        _createElementVNode("span", {
          class: "font-semibold text-gray-900 dark:text-white",
          innerHTML: `${_ctx.data.from ? _ctx.data.from : 0} - ${_ctx.data.to ? _ctx.data.to : 0}`
        }, null, 8, _hoisted_4),
        _createTextVNode(" " + _toDisplayString(_ctx.$trans('pagination.of')) + " ", 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.splitNumber(_ctx.data.total)), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.data.prev_page_url)
        ? (_openBlock(), _createBlock(_component_Link, {
            key: 0,
            as: "a",
            href: _ctx.data.prev_page_url,
            "preserve-scroll": "",
            class: "bg-gray-900 dark:bg-primary-800 text-white dark:text-gray-100 hover:bg-gray-700 dark:hover:bg-primary-750 focus:ring-gray-700 dark:focus:ring-primary-950 focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent select-none inline-flex items-center justify-center flex-1 px-3 py-2 text-sm font-medium text-center rounded-lg"
          }, {
            default: _withCtx(() => [
              _cache[2] || (_cache[2] = _createElementVNode("svg", {
                class: "w-5 h-5 mr-1 -ml-1",
                fill: "currentColor",
                viewBox: "0 0 20 20",
                xmlns: "http://www.w3.org/2000/svg"
              }, [
                _createElementVNode("path", {
                  "fill-rule": "evenodd",
                  d: "M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z",
                  "clip-rule": "evenodd"
                })
              ], -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.$trans('pagination.previous_title')), 1)
            ]),
            _: 1
          }, 8, ["href"]))
        : _createCommentVNode("", true),
      (_ctx.data.next_page_url)
        ? (_openBlock(), _createBlock(_component_Link, {
            key: 1,
            as: "a",
            href: _ctx.data.next_page_url,
            "preserve-scroll": "",
            class: "bg-gray-900 dark:bg-primary-800 text-white dark:text-gray-100 hover:bg-gray-700 dark:hover:bg-primary-750 focus:ring-gray-700 dark:focus:ring-primary-950 focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent select-none inline-flex items-center justify-center flex-1 px-3 py-2 text-sm font-medium text-center rounded-lg"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$trans('pagination.next_title')) + " ", 1),
              _cache[3] || (_cache[3] = _createElementVNode("svg", {
                class: "w-5 h-5 ml-1 -mr-1",
                fill: "currentColor",
                viewBox: "0 0 20 20",
                xmlns: "http://www.w3.org/2000/svg"
              }, [
                _createElementVNode("path", {
                  "fill-rule": "evenodd",
                  d: "M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z",
                  "clip-rule": "evenodd"
                })
              ], -1))
            ]),
            _: 1
          }, 8, ["href"]))
        : _createCommentVNode("", true)
    ])
  ]))
}