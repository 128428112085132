import type { updateBoardFunction, updateColumnFunction } from '@/Pages/Boards/Types';
import { default as Column } from '@/Types/Resources/Column/Light';
import { default as Board } from '@/Types/Resources/Board/Base';
import { useForm } from 'laravel-precognition-vue-inertia';
import { autoResizeTextarea } from '@/utils';
import { ref, Ref, nextTick } from 'vue';
import route from '@/ziggy';

export function useColumnActions(board: Ref<Board>, columns: Ref<Array<Column>>, updateBoard: updateBoardFunction, updateColumn: updateColumnFunction)
{
  const isCreateColumnFormVisible = ref<boolean>(false);
  const isDeleteColumnModalVisible = ref<boolean>(false);
  const idOfColumnToDelete = ref<string>('');
  const editColumnArea = ref<HTMLTextAreaElement | null>(null);

  const newColumnFormState = useForm('post', () => route('boards.columns.store', {id: board.value.id}), {
    _position: columns.value.length,
    b_c_name: null as string | null
  });

  const editColumnFormState = useForm('put', () => route('boards.columns.update', {id: editColumnFormState._column_id}), {
    _column_id: null as string | null,
    b_c_name: null as string | null
  });
  
  function createColumn(): void
  {
    if (newColumnFormState.isDirty) {
      newColumnFormState.submit({
        preserveState: true,
        preserveScroll: true,
        onSuccess: (data) => {
          isCreateColumnFormVisible.value = false;
          newColumnFormState.reset();
          newColumnFormState.clearErrors();

          // Fix this for cards pagination in columns
          updateBoard(data.props.board as Board);

          nextTick(() => {
            autoResizeTextarea('new-column-area');
          });
        }
      });
    }
  }

  function openColumnEditForm(column: Column, close: Function): void
  {
    editColumnFormState.b_c_name = column.name;
    editColumnFormState._column_id = column.id;

    close();

    nextTick(() => {
      const textarea = document.getElementById('edit-column-area') as HTMLTextAreaElement;
      
      if (textarea) {
        textarea.focus();
        textarea.select();
        autoResizeTextarea(textarea);
      }
    });
  }

  function onEditColumnFormBlur(column: Column): void
  {
    if(editColumnFormState.b_c_name !== column.name) {
      editColumnFormState.submit({
        preserveState: true,
        preserveScroll: true,
        onSuccess: (data) => {
          let board = data.props.board as Board;

          updateColumn({ 
            ...column, 
            name: board.columns.find((c: Column) => c.id === column.id)?.name ?? column.name
          });
        }
      });
    }
    editColumnFormState.reset();
  }

  function toggleDeleteColumnModal(value: boolean, columnId: string, close: Function): void
  {
    idOfColumnToDelete.value = columnId;
    isDeleteColumnModalVisible.value = value;
    close();
  }

  function toggleCreateColumnFormVisibility(value: boolean = false): void
  {
    newColumnFormState.reset();
    newColumnFormState.clearErrors();
    isCreateColumnFormVisible.value = value;
  }

  return {
    toggleCreateColumnFormVisibility,
    isDeleteColumnModalVisible,
    isCreateColumnFormVisible,
    toggleDeleteColumnModal,
    onEditColumnFormBlur,
    editColumnFormState,
    openColumnEditForm,
    newColumnFormState,
    idOfColumnToDelete,
    editColumnArea,
    createColumn,
  };
}