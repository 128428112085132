import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-6 gap-6 overflow-x-scroll p-1" }
const _hoisted_2 = { class: "flex items-center space-x-3" }
const _hoisted_3 = {
  key: 0,
  class: "mt-2 text-sm text-red-600 dark:text-red-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioGroupOption = _resolveComponent("RadioGroupOption")!
  const _component_RadioGroup = _resolveComponent("RadioGroup")!

  return (_openBlock(), _createBlock(_component_RadioGroup, {
    modelValue: _ctx.selectedColor as string,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedColor as string) = $event))
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.mainClass + ' block mb-2 text-sm font-medium')
      }, _toDisplayString(_ctx.$trans(_ctx.label)), 3),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none", [_ctx.selectedColor && _ctx.selectedColor === _ctx.customColorValue && !_ctx.isPresetColor(_ctx.selectedColor) ? 'ring-2' : '']]),
            style: _normalizeStyle({'--tw-ring-color': _ctx.customColorValue}),
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openColorPicker && _ctx.openColorPicker(...args)))
          }, [
            _createElementVNode("span", {
              "aria-hidden": "true",
              style: _normalizeStyle({'background-color': _ctx.customColorValue || 'transparent', 'border': _ctx.customColorValue ? 'none' : '2px solid #6B7280'}),
              class: "h-7 w-7 rounded-full"
            }, null, 4),
            _withDirectives(_createElementVNode("input", {
              ref: "colorPickerInput",
              type: "color",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.customColorValue) = $event)),
              onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateCustomColor && _ctx.updateCustomColor(...args))),
              class: "absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            }, null, 544), [
              [_vModelText, _ctx.customColorValue]
            ])
          ], 6),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors, (color) => {
            return (_openBlock(), _createBlock(_component_RadioGroupOption, {
              key: color.value,
              value: color.value
            }, {
              default: _withCtx(({ checked }) => [
                _createElementVNode("div", {
                  class: _normalizeClass([
              'relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none',
              checked ? 'ring-2' : ''
            ]),
                  style: _normalizeStyle({'--tw-ring-color': color.value})
                }, [
                  _createElementVNode("span", {
                    "aria-hidden": "true",
                    style: _normalizeStyle({'background-color': color.value}),
                    class: "h-7 w-7 rounded-full"
                  }, null, 4)
                ], 6)
              ]),
              _: 2
            }, 1032, ["value"]))
          }), 128))
        ])
      ]),
      (_ctx.withError && _ctx.form.invalid(_ctx.name))
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.form.errors[_ctx.name]), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}