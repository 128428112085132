<template>
  <Head>
    <title>{{ board.name }} | {{ getAppName() }}</title>
    <link v-if="board.bg_path_medium" rel="preload" as="image" :href="board.bg_path_medium"/>
    <link v-if="board.bg_path_original" rel="preload" as="image" :href="board.bg_path_original"/>
  </Head>
  <Layout :main-classes="[]" :div-classes="['bg-transparent', 'ring-transparent']" :logo-color="['filter-white']" :navigation-color="['text-white']">
    <!-- Main Content -->
    <div class="flex flex-col h-full mr-0 relative rounded-lg">
      <!-- Header -->
      <header class="bg-gray-50 dark:bg-gray-850/40 border-gray-300/50 dark:border-gray-850/10 backdrop-blur-xl md:rounded-lg py-4 sm:px-6 flex items-center justify-between h-auto relative z-0 border-b">
        <!-- Project Name -->
        <span class="relative basis-8/12 flex flex-row items-center min-h-8 flex-nowrap max-w-full">
          <div class="text-gray-950 dark:text-white basis-auto items-center bg-transparent rounded-md border-0 cursor-pointer flex flex-shrink-0 font-sans text-base font-medium h-8 justify-center leading-8 m-0 overflow-visible p-1.5 relative text-center no-underline transition-colors duration-20 ease-in-out w-8 whitespace-no-wrap appearance-none shadow-none box-border">
            <span class="inline-block leading-none">
              <svg class="w-5 h-5 stroke-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 20C9 14.1421 9 5 9 5V20ZM9 20H4.125C3.504 20 3 19.496 3 18.875V6.125C3 5.504 3.504 5 4.125 5H19.875C20.496 5 21 5.504 21 6.125V12C21 12.621 20.496 13 19.875 13H15M15 5V13M15 13V16H9.625C9.004 16 9 15.121 9 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
          </div>
          <!-- Name -->
          <div class="basis-auto  break-words roundedcursor-default inline-flex relative whitespace-nowrap leading-8	mb-0 max-w-full" tabindex="0" role="textbox">
            <h1 class="bg-transparent text-gray-950 dark:text-white content-center truncate py-1.5 sm:py-0 pl-1 pr-2.5 text-lg font-bold no-underline text-ellipsis h-full leading-tight whitespace-normal overflow-hidden">
              {{ board.name }}
            </h1>
          </div>
          <!-- Favorite Button -->
          <button class="text-gray-950 dark:text-white basis-auto items-center bg-transparent rounded-md border-0 cursor-pointer flex flex-shrink-0 font-sans text-base font-medium h-8 justify-center leading-8 m-0 overflow-visible p-1.5 relative text-center no-underline transition-colors duration-20 ease-in-out w-8 whitespace-no-wrap appearance-none shadow-none box-border">
            <span class="inline-block leading-none">
              <SolidStar v-if="board.is_pinned" @click="toggleBoardPinStatus" class="h-4 w-4 leading-4" aria-hidden="true"/>
              <OutlineStar v-else @click="toggleBoardPinStatus" class="h-4 w-4 leading-4" aria-hidden="true"/>
            </span>
          </button>
        </span>
        <!-- Project Users & Sharing -->
        <div class="relative hidden basis-4/12  md:flex justify-end items-center min-h-8 flex-nowrap">
          <!-- Filtration -->
          <!-- <FunnelIcon class="h-4 w-4 leading-4 mr-2" aria-hidden="true"/>
          <span>
            Filter
          </span>
          <div v-if="board.members.length > 0" class="hidden md:block mx-4 h-6 w-px bg-gray-300 dark:bg-gray-700"></div> -->
          <div class="flex -space-x-2 overflow-hidden p-0.5">
            <UserAvatar v-for="member in board.members.slice(0, 5)" :key="member.id" :user="member" size="sm"/>
          </div>
          <div v-if="board.members.length > 5" class="block text-nano p-1.5">
            + {{ board.members.length - 5 }}
          </div>
        </div>
      </header>
      <!-- Columns -->
      <div class="grow mt-3">
        <VueDraggableNext class="board board-scrollbar overflow-x-auto overflow-y-hidden select-none whitespace-nowrap bottom-0 left-0 right-0 top-0 mb-2 pb-2"
          :empty-insert-threshold="columnDragOptions.emptyInsertThreshold"
          :fallbackTolerance="columnDragOptions.fallbackTolerance"
          :removeCloneOnHide="columnDragOptions.removeCloneOnHide"
          v-dragscroll:nochilddrag.x.noMiddleMouse="isNotMobile"
          :prevent-on-filter="columnDragOptions.preventOnFilter"
          :dragoverBubble="columnDragOptions.dragoverBubble"
          :force-fallback="columnDragOptions.forceFallback"
          :chosen-class="columnDragOptions.chosenClass"
          :ghost-class="columnDragOptions.ghostClass"
          :animation="columnDragOptions.animation"
          :direction="columnDragOptions.direction"
          :handle="`.${columnDragOptions.handle}`"
          :item-key="columnDragOptions.itemKey"
          :filter="columnDragOptions.filter"
          :easing="columnDragOptions.easing"
          :delay="columnDragOptions.delay"
          :group="columnDragOptions.group"
          @change="onColumnPositionChange"
          @start="onColumnDragStart"
          @end="onColumnDragEnd"
          v-model="columns">
          <!-- Column -->
          <div v-for="column in columns" :key="column.id" data-dragscroll class="w-[--column-width] max-h-[--column-height] my-0 mx-1.5 first:ml-3 box-border inline-block !h-full scroll-m-2 align-top whitespace-nowrap">
            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <div data-no-dragscroll :class="columnDragOptions.handle" class="column bg-gray-100 dark:bg-black w-full whitespace-normal relative max-h-full flex flex-col box-border rounded-xl">
                <!-- Column Header -->
                <Menu v-slot="{ close }" as="div" class="grid gap-x-1 flex-[0_0_auto] grid-cols-[1fr_max-content] min-h-[--column-header-height] px-4 pt-2 pb-0 relative">
                  <span v-if="editColumnFormState._column_id != column.id" class="leading-4 overflow-hidden break-words font-medium pt-2 pl-1 pr-2 w-full h-auto min-w-min">
                    {{ column.name }}
                  </span>
                  <textarea v-else id="edit-column-area" v-model="editColumnFormState.b_c_name" @input="autoResizeTextarea('edit-column-area')" @focusout="onEditColumnFormBlur(column)" maxlength="255" autocomplete="off" class="border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700 column-no-drag leading-4 max-h-64 mt-2 static content-center bg-transparent rounded-md shadow-none box-border font-semibold m-0 h-8 overflow-hidden break-words py-2 px-2 resize-none"></textarea>
                  <div class="column-no-drag flex-grow-0 flex-shrink-0 mb-0 px-2 pt-2 rounded-md text-gray-500">
                    <MenuButton>
                      <EllipsisHorizontalIcon class="h-4 w-4"/>
                    </MenuButton>
                  </div>
                  <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <MenuItems class="bg-white dark:bg-gray-850 border-gray-200 dark:border-gray-750 divide-gray-100 dark:divide-gray-750 border origin-top-left absolute mt-10 z-5 left-56 max-w-32 w-48 py-1 text-sm list-none rounded shadow-sm">
                      <ul role="none">
                        <li @click.stop="openColumnEditForm(column, close)" :class="{'column-no-drag': isNotMobile}" class="text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-white cursor-pointer select-none px-3 py-2">
                          {{$trans('main.shared.buttons.edit')}}
                        </li>
                        <li @click.stop="toggleDeleteColumnModal(true, column.id, close)" :class="{'column-no-drag': isNotMobile}" class="text-red-700 dark:hover:text-white dark:hover:bg-red-800 hover:bg-red-100 cursor-pointer select-none px-3 py-2">
                          {{$trans('main.shared.buttons.delete')}}
                        </li>
                      </ul>
                    </MenuItems>
                  </transition>
                </Menu>
                <!-- List Cards -->
                <VueDraggableNext class="my-1 list-cards mx-auto overflow-y-auto overflow-x-hidden column-no-drag" 
                  :invertedSwapThreshold="cardDragOptions.invertedSwapThreshold"
                  :emptyInsertThreshold="cardDragOptions.emptyInsertThreshold"
                  :touchStartThreshold="cardDragOptions.touchStartThreshold"
                  :removeCloneOnHide="cardDragOptions.removeCloneOnHide"
                  :fallbackTolerance="cardDragOptions.fallbackTolerance"
                  :ignoreTransition="cardDragOptions.ignoreTransition"
                  :delayOnTouchOnly="cardDragOptions.delayOnTouchOnly"
                  :preventOnFilter="cardDragOptions.preventOnFilter"
                  :supportPointer="cardDragOptions.supportPointer"
                  :dragoverBubble="cardDragOptions.dragoverBubble"
                  @change="onCardPositionChange($event, column)"
                  :swapThreshold="cardDragOptions.swapThreshold"
                  :forceFallback="cardDragOptions.forceFallback"
                  :ghostClass="cardDragOptions.ghostClass"
                  :invertSwap="cardDragOptions.invertSwap"
                  :animation="cardDragOptions.animation"
                  :direction="cardDragOptions.direction"
                  :handle="`.${cardDragOptions.handle}`"
                  :itemKey="cardDragOptions.itemKey"
                  :easing="cardDragOptions.easing"
                  :filter="cardDragOptions.filter"
                  :delay="cardDragOptions.delay"
                  :group="cardDragOptions.group"
                  :sort="cardDragOptions.sort"
                  v-model="column.cards.data"
                  @start="onCardDragStart"
                  @end="onCardDragEnd">
                  <div v-for="element in column.cards.data" :key="element.id" :class="cardDragOptions.handle" class="card-scrollbar column-no-drag p-2 flex flex-[1_1_auto] flex-col gap-2 justify-between my-0 pt-2 pb-0 min-h-0 overflow-y-auto overflow-x-hidden">
                    <div class="bg-white dark:bg-gray-900 text-gray-950 dark:text-white h-auto rounded-md shadow-sm cursor-pointer block flex-shrink-0 font-sans text-sm font-normal leading-5 mb-1 max-w-[--card-width] min-h-[--card-height] relative select-none w-60"
                      :class="{'group hover:opacity-100 hover:outline hover:outline-2 hover:outline-blue-500': !isCardBeingDragged}">
                      <!-- Card Details -->
                      <div @click="openShowCardModal(element.id)" class="card overflow-hidden relative z-15 py-2 pr-2 pl-3">
                        <div v-if="element.tags.length > 0" class="flex flex-wrap gap-1 mb-1 mr-1.5">
                          <template v-for="tag in element.tags">
                            <div v-if="existsTag(tag)" class="inline-flex max-w-[calc(100%-0.25rem)]">
                              <button :class="`bg-${tag.color}-600 dark:bg-${tag.color}-500 my-0 px-0 py-0 min-w-[--card-tag-width] max-w-[--card-tag-width] h-[--card-tag-height] inline-block rounded leading-4 box-border`">
                              </button>
                            </div>
                          </template>
                        </div>
                        <!-- Card Title -->
                        <span class="break-words clear-both block mx-0 mt-0 mb-1 no-underline text-sm">
                          {{ element.title }}
                        </span>
                        <!-- Card Badges -->
                        <div class="float-left max-w-full mt-1.5">
                          <div class="text-gray-500 flex items-center overflow-hidden relative no-underline text-ellipsis align-top">
                            <EyeIcon v-if="checkUserParticipationInCard(element)" class="h-4 w-4 mr-2" aria-hidden="true"/>
                            <Bars3CenterLeftIcon v-if="element.exists_description" class="h-4 w-4 mr-2" aria-hidden="true"/>
                            <span v-if="element.number" class="flex items-center">
                              <HashtagIcon class="h-4 w-4" aria-hidden="true"/>
                              {{ element.number }}
                            </span>
                          </div>
                        </div>
                        <!-- Card Members -->
                        <div class="float-right m-0">
                          <div class="cursor-pointer flex -space-x-2 overflow-hidden p-0.5 float-right text-sm font-normal leading-5 ml-1 relative no-underline select-none z-0">
                            <UserAvatar v-for="member in element.members.slice(0, 5)" :key="member.id" :user="member" size="xs" :with-tooltip="false"/>
                          </div>
                        </div>
                      </div>
                      <button @click.prevent="openEditCardModal(element)" :class="{'column-no-drag card-no-drag': isNotMobile}" class="bg-gray-200 dark:bg-gray-900 text-gray-600 dark:text-gray-500 hover:bg-gray-300 dark:hover:bg-gray-850 md:opacity-0 group-hover:opacity-100 flex absolute top-0.5 right-0.5 rounded-full p-1.5 z-15 text-sm leading-5 box-border items-center justify-center cursor-pointer no-underline whitespace-normal shadow-none border-none font-medium">
                        <PencilIcon class="h-4 w-4 inline-block"/>
                      </button>
                    </div>
                  </div>
                  <CardSkeleton
                    main-class="card-no-drag column-no-drag"
                    :ref="el => setCardSkeletonRef(column.id, el)"
                    :column-id="column.id"
                    :current-page="column.cards.current_page"
                    :last-page="column.cards.last_page"
                    @load-more="() => loadMoreCards(column)"/>
                </VueDraggableNext>
                <div class="items-center flex flex-shrink-0 font-sans text-base font-normal h-11 justify-between leading-5 user-select-none w-68">
                  <div class="bg-opacity-0 rounded-2xl flex flex-auto flex-grow flex-shrink-0 font-sans text-base font-normal h-5 leading-5 mb-2 ml-2 mt-2 pb-1 pl-1.5 pr-2 relative no-underline user-select-none w-52">
                    <span @click="openNewCardForm(column)" :class="{'column-no-drag': isNotMobile}" class="text-gray-600 cursor-pointer inline-block font-trellicons text-lg font-normal h-5 leading-5 mr-1.5 text-center user-select-none align-bottom w-5 antialiased">
                      <PlusIcon class="h-5 w-5"/>
                    </span>
                    <span @click="openNewCardForm(column)" :class="{'column-no-drag': isNotMobile}" class="text-gray-600 cursor-pointer inline font-sans text-sm font-normal leading-5 user-select-none">
                      {{ $trans('main.boards.buttons.add_card') }}
                    </span>
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <div class="column-no-drag my-0 mx-1.5 first:ml-3 last:mr-3 box-border inline-block !h-full scroll-m-2 align-top whitespace-nowrap">
            <div v-if="isCreateColumnFormVisible" class="self-start flex-shrink-0 block min-h-5">
              <form @submit.prevent="createColumn" class="rounded-xl p-2 w-60 bg-gray-100 dark:bg-black shadow-[var(--ds-shadow-raised,0_1px_1px_#091e4240,0_0_1px_#091e424f)] box-border">
                <div class="relative rounded-md shadow-sm">
                  <textarea
                    id="new-column-area"
                    v-model="newColumnFormState.b_c_name"
                    maxlength="255"
                    @change="newColumnFormState.validate('b_c_name')"
                    @input="autoResizeTextarea('new-column-area')"
                    :class="{
                      'border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700': !newColumnFormState.invalid('b_c_name'),
                      'border-red-300 dark:border-red-500 text-red-700 dark:text-red-500 focus:ring-red-500 focus:border-red-500': newColumnFormState.invalid('b_c_name')
                    }" class="form-column-area bg-gray-50 dark:bg-gray-850 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full py-2 px-2 focus:outline-none rounded-md !leading-4 h-8 resize-none"></textarea>
                </div>
                <p v-if="newColumnFormState.invalid('b_c_name')" class="my-1 text-nano whitespace-normal text-red-600 dark:text-red-500">
                  {{ newColumnFormState.errors['b_c_name'] }}
                </p>
                <div :class="[newColumnFormState.invalid('b_c_name') ? 'mt-0' : 'mt-4']" class="flex items-center justify-start gap-1 rounded-sm">
                  <button type="submit" class="bg-primary-700 dark:bg-primary-600 hover:bg-primary-800 dark:hover:bg-primary-700 text-white rounded-lg sm:w-auto text-sm inline-flex w-full justify-center items-center px-2.5 py-1.5 sm:mr-3 font-medium text-center">
                    {{ $trans('main.shared.buttons.save') }}
                  </button>
                  <button type="button" @click="toggleCreateColumnFormVisibility()" class="bg-transparent text-gray-500 mx-2 sm:mx-0 mb-0 border-none shadow-none inline-flex items-center justify-center rounded text-sm font-medium leading-5 box-border cursor-pointer no-underline whitespace-normal">
                    {{ $trans('main.shared.buttons.cancel') }}
                  </button>
                </div>
              </form>
            </div>
            <div v-else clas="items-center appearance-none border-none rounded-xl box-border text-white cursor-pointer inline-flex text-sm font-medium h-11 justify-start mb-2 px-3 py-3 relative select-none transition-colors duration-75 ease-in-out">
              <button @click="isCreateColumnFormVisible = true" class="bg-gray-100/50 dark:bg-gray-800/75 text-gray-950 dark:text-white w-60 backdrop-blur items-center appearance-none rounded-xl border-transparent shadow-none box-border cursor-pointer inline-flex text-sm font-medium h-11 justify-center mb-2 p-3 relative transition duration-75 ease-in-out select-none">
                <span class="mr-2 inline-block leading-none">
                  <PlusIcon class="h-5 w-5"/>
                </span>
                {{ $trans('main.boards.buttons.add_column') }}
              </button>
            </div>
          </div>
        </VueDraggableNext>
      </div>
    </div>
  </Layout>
  <CardShow
    v-if="isDetailsModalVisible"
    :card="cardBeingShown"
    :board="board"
    :colors="colors"
    :open="isDetailsModalVisible"
    @update-board="handleUpdateBoard"
    @update-board-card="handleUpdateMicroCard"
    @remove-board-card="handleRemoveCard"
    @copy-board-card="handleCopyCard"
    @close="handleShowCardModal"/>
  <CardCreate
    :open="isCreateCardModalVisible"
    :board="board"
    :column="targetColumnForNewCard"
    @close="handleCreateCardModal"
    @update-column="handleUpdateColumn"/>
  <CardEdit
    :card="cardBeingEdited"
    :open="isEditCardModalVisible"
    @close="handleEditCardModal"
    @update-card="handleUpdateMicroCard"/>
  <Attempt 
    :open="isDeleteColumnModalVisible"
    :close="(value: boolean) => isDeleteColumnModalVisible = value" 
    routeName="boards.columns.destroy"
    :delete-callback="() => handleUpdateAllColumns(columns.filter(column => column.id !== idOfColumnToDelete))"
    :routeArgument="{ id: idOfColumnToDelete }" 
    :title="$trans('main.attempts.delete_board_column.title')" 
    :description="$trans('main.attempts.delete_board_column.description')" 
    :successButtonText="$trans('main.attempts.buttons.success_button')" 
    :cancelButtonText="$trans('main.attempts.buttons.cancel_button')"/>
</template>
<style scoped>
  .form-column-area::-webkit-scrollbar { display: none; }
  .form-column-area { -ms-overflow-style: none; }
  .form-column-area { scrollbar-width: none; }

  .board-scrollbar::-webkit-scrollbar { display: none; }
  .board-scrollbar { -ms-overflow-style: none; }
  .board-scrollbar { scrollbar-width: none; }

  .card-scrollbar::-webkit-scrollbar { display: none; }
  .card-scrollbar { -ms-overflow-style: none; }
  .card-scrollbar { scrollbar-width: none; }

  .list-cards::-webkit-scrollbar { width: 6px; height: 8px; }
  .list-cards { scrollbar-color: var(#091e4224) var(#091e420f); scrollbar-width: thin;}

  .ghost-card .card {
    @apply bg-gray-200 dark:bg-gray-950/90 text-gray-950 border-gray-400 dark:border-gray-800 border-dashed rounded-md border-2;
  }
  .ghost-card .card * {
    @apply opacity-0;
  }

  .ghost-column .column {
    @apply bg-gray-400/50 dark:bg-gray-950/90 text-gray-950 border-gray-500 dark:border-gray-700 border-dashed rounded-xl border-2;
  }

  .ghost-column .column * {
    @apply opacity-0;
  }

  .sortable-col-chosen { height: var(--column-height) !important; }

  /* FIX ME */
  /* @media (max-width: 768px) { .board { height: calc(100vh - 14rem) }; } */
  @media (max-width: 768px) { .board { height: calc(100vh - 13rem) }; }
  @media (min-width: 768px) { .board { height: calc(100vh - 11rem) }; }
</style>
<script lang="ts">
import { defineComponent, ref, shallowRef, onMounted, computed, onUnmounted, watch, inject, PropType, defineAsyncComponent } from 'vue';
import type { updateColumnsFunction, updateColumnFunction, updateBoardFunction, updateMicroCardFunction } from '@/Pages/Boards/Types';
import { StarIcon as SolidStar, PlusIcon, EllipsisHorizontalIcon, HashtagIcon, Bars3CenterLeftIcon } from '@heroicons/vue/20/solid';
import { StarIcon as OutlineStar, FunnelIcon, EyeIcon, ExclamationCircleIcon } from '@heroicons/vue/24/outline';
const CardCreate = defineAsyncComponent(() => import('@/Pages/Boards/Card/Create.vue'));
import { useColumnDragControl } from '@/Pages/Boards/Composables/useColumnDragControl';
const CardShow = defineAsyncComponent(() => import('@/Pages/Boards/Card/Show.vue'));
const CardEdit = defineAsyncComponent(() => import('@/Pages/Boards/Card/Edit.vue'));
import { useCardDragControl } from '@/Pages/Boards/Composables/useCardDragControl';
import { useCardPagination } from '@/Pages/Boards/Composables/useCardPagination';
import { useColumnActions } from '@/Pages/Boards/Composables/useColumnActions';
import { sendRequest, getAppName, socketChannelNameBuilder } from '@/mixins';
import { useCardActions } from '@/Pages/Boards/Composables/useCardActions';
import { default as LightColumn } from '@/Types/Resources/Column/Light';
import { default as HandleUser } from '@/Types/Resources/User/Handle';
import { default as BaseBoard } from '@/Types/Resources/Board/Base';
import { default as MicroCard } from '@/Types/Resources/Card/Micro';
import { default as LightTag } from '@/Types/Resources/Tag/Light';
import { Menu, MenuButton, MenuItems } from '@headlessui/vue';
import { PayloadResponse } from '@/Types/API/PayloadResponse';
import CardSkeleton from './Components/CardSkeleton.vue';
import { VueDraggableNext } from 'vue-draggable-next';
import { Channel } from '../../../socket/channels.js';
import { PencilIcon } from '@heroicons/vue/16/solid';
import Member from '@/Types/Resources/User/Member';
import UserAvatar from '@/Shared/UserAvatar.vue';
import { Head, usePage } from '@inertiajs/vue3';
import { useRemember } from '@inertiajs/vue3';
import { autoResizeTextarea } from '@/utils';
import { dragscroll } from 'vue-dragscroll';
import Attempt from '@/Shared/Attempt.vue';
import { useBreakpoints } from '@/mixins';
import { Socket } from 'socket.io-client';
import Layout from '@/Shared/Layout.vue';
import Auth from "@/Types/App/Auth";
import { debounce } from 'lodash';

export default defineComponent({
  name: 'BoardShow',
  directives: {
    dragscroll: dragscroll
  },
  props: {
    board: {
      type: Object as () => BaseBoard,
      required: true
    },
    colors: {
      type: Array as () => Array<string>,
      required: true
    },
    card: {
      type: [Object, null] as PropType<MicroCard|null>,
      default: null
    },
  },
  components: {
    EllipsisHorizontalIcon,
    ExclamationCircleIcon,
    Bars3CenterLeftIcon,
    VueDraggableNext,
    CardSkeleton,
    HashtagIcon,
    OutlineStar,
    UserAvatar,
    MenuButton,
    FunnelIcon,
    PencilIcon,
    CardCreate,
    MenuItems,
    SolidStar,
    PlusIcon,
    CardShow,
    CardEdit,
    Attempt,
    EyeIcon,
    Layout,
    Head,
    Menu,
  },
  setup(props)
  {
    let syncTokenTimeout: NodeJS.Timeout | null = null;
    const syncToken = ref<string | null>(null);
    
    const { isXs } = useBreakpoints();
    const isNotMobile = computed(() => !isXs.value);
    const app = document.getElementById('app');
    const auth = computed(() => usePage().props?.auth as Auth);
    const user = computed(() => auth.value.user as HandleUser);
    
    const board = shallowRef<BaseBoard>(useRemember(props.board, 'boardState') as BaseBoard);
    const columns = shallowRef<Array<LightColumn>>(useRemember(props.board.columns, 'columnsState') as Array<LightColumn>);
    const boardTags = computed<Array<LightTag>>(() => board.value.tags ?? []);
      
    const socket = inject<Socket>('$socket');
    const socketBoardDetailsUpdatedChannel = ref<string|null>(null);
    const socketColumnsUpdatedChannel = ref<string|null>(null);
    const socketCardMovedChannel = ref<string|null>(null);

    const handleUpdateBoard: updateBoardFunction = debounce((newBoard: BaseBoard) => {
      if(newBoard.bg_path_original !== board.value.bg_path_original 
      || newBoard.bg_path_medium !== board.value.bg_path_medium)
      {
        unloadBackgroundImage();
        loadBackgroundImage();
      }

      board.value = newBoard;
      columns.value = newBoard.columns;
    }, 100);

    const handleUpdateAllColumns: updateColumnsFunction = (newColumns: Array<LightColumn>) => {
      columns.value = newColumns;
    };
    
    const handleUpdateColumn: updateColumnFunction = (newColumn: LightColumn) => {
      columns.value = columns.value.map((column) => {
        if(column.id == newColumn.id){
          return newColumn;
        }else{
          return column;
        }
      });

      props.board.columns = JSON.parse(JSON.stringify(columns.value));
    };
    
    const handleUpdateMicroCard: updateMicroCardFunction = (newCard: MicroCard) => {
      columns.value.forEach((column: LightColumn) => {
        column.cards.data = column.cards.data.map((c) => {
          if(c.id === newCard.id){
            return newCard;
          }else{
            return c;
          }
        });
        column.cards.data = column.cards.data.map((c: MicroCard) => c.id === newCard.id ? newCard : c);
      });

      props.board.columns = JSON.parse(JSON.stringify(columns.value));
    };

    const {
      toggleCreateColumnFormVisibility,
      isDeleteColumnModalVisible,
      isCreateColumnFormVisible,
      toggleDeleteColumnModal,
      onEditColumnFormBlur,
      editColumnFormState,
      openColumnEditForm,
      idOfColumnToDelete,
      newColumnFormState,
      editColumnArea,
      createColumn,
    } = useColumnActions(board, columns, handleUpdateBoard, handleUpdateColumn);

    const {
      checkUserParticipationInCard,
      isCreateCardModalVisible,
      isEditCardModalVisible,
      targetColumnForNewCard,
      isDetailsModalVisible,
      handleCreateCardModal,
      handleShowCardModal,
      handleEditCardModal,
      openShowCardModal,
      openEditCardModal,
      handleRemoveCard,
      openNewCardForm,
      cardBeingEdited,
      cardBeingShown,
      handleCopyCard,
    } = useCardActions(props.card, columns, user, board);

    const { 
      onColumnPositionChange,
      columnDragOptions,
      onColumnDragStart,
      onColumnDragEnd,
    } = useColumnDragControl(columns, board.value.id, syncTokenTimeout, syncToken);

    const {
      onCardPositionChange,
      isCardBeingDragged,
      onCardDragStart,
      cardDragOptions,
      onCardDragEnd,
    } = useCardDragControl(columns, board.value.id, syncTokenTimeout, syncToken, handleUpdateColumn);

    const {
      setCardSkeletonRef,
      loadMoreCards,
      hasMoreCards,
    } = useCardPagination(board.value.id, handleUpdateColumn);
    
    watch(() => columns, () => {
      props.board.columns = JSON.parse(JSON.stringify(columns.value));
    },{
      immediate: true
    });

    function existsTag(tag: LightTag): boolean
    {
      return boardTags.value.some(t => t.id === tag.id);
    }

    function toggleBoardPinStatus(): void
    {
      sendRequest({
        name: 'api.v1.boards.pin',
        params: { board: props.board.id },
      }, (data: PayloadResponse<BaseBoard>) => {
        board.value.is_pinned = data.payload.is_pinned;
      });
    }

    function loadBackgroundImage(): void
    {
      if (board.value.bg_path_medium && board.value.bg_path_original) {
        const mediumImg = new Image();
        const originalImg = new Image();

        mediumImg.onload = () => {
          app?.style.setProperty('background-image', `url('${board.value.bg_path_medium}')`);
          app?.style.setProperty('background-size', 'cover');
          app?.style.setProperty('background-position', 'center');
          app?.style.setProperty('background-repeat', 'no-repeat');
          app?.style.setProperty('width', '100%');
          app?.style.setProperty('height', '100vh');
          
          originalImg.src = board.value?.bg_path_original ?? '';
        };

        originalImg.onload = () => {
          app?.style.setProperty('background-image', `url('${board.value.bg_path_original}')`);
        };
        
        mediumImg.src = board.value.bg_path_medium;
      }
    }

    function unloadBackgroundImage(): void
    {
      if (board.value.bg_path_medium && board.value.bg_path_original) {
        app?.style.removeProperty('background-image');
        app?.style.removeProperty('background-size');
        app?.style.removeProperty('background-position');
        app?.style.removeProperty('background-repeat');
        app?.style.removeProperty('width');
        app?.style.removeProperty('height');
      }
    }

    onMounted(() => {
      loadBackgroundImage();

      socketBoardDetailsUpdatedChannel.value = socketChannelNameBuilder(Channel.BoardDetailsUpdatedEvent, board.value?.id ?? "");
      socketColumnsUpdatedChannel.value = socketChannelNameBuilder(Channel.BoardColumnsUpdatedEvent, board.value?.id ?? "");
      socketCardMovedChannel.value = socketChannelNameBuilder(Channel.BoardCardMovedEvent, board.value?.id ?? "");

      socket?.on(socketBoardDetailsUpdatedChannel.value, (data: {
        id: string,
        name: string,
        bg_path_original: string|null,
        bg_path_medium: string|null,
        is_pinned: boolean,
        tags: Array<LightTag>,
        members: Array<Member>,
      }) => {
        handleUpdateBoard({
          ...board.value,
          name: data.name,
          bg_path_original: data.bg_path_original,
          bg_path_medium: data.bg_path_medium,
          is_pinned: data.is_pinned,
          tags: data.tags,
          members: data.members,
        });
      });
      socket?.on(socketColumnsUpdatedChannel.value, (data: {sync_token: string|null, columns: Array<{id: string, position: number}>}) => {
        if(syncToken.value === null || (syncToken.value !== null && syncToken.value === data.sync_token))
        {
          data.columns.forEach((column: {id: string, position: number}) => {
            let newColumns = columns.value.map((c) => {
              if(c.id === column.id){
                c.position = column.position;
              }
              return c;
            });

            newColumns = newColumns.sort((a, b) => a.position - b.position);
            
            handleUpdateAllColumns(newColumns);
          });
        }
      });
      socket?.on(socketCardMovedChannel.value, (data: {sync_token: string|null, columns: Array<LightColumn>}) => {
        if(syncToken.value === null || (syncToken.value !== null && syncToken.value === data.sync_token))
        {
          data.columns.forEach((column: LightColumn) => {
            handleUpdateColumn(column);
          });
        }
      });
    });

    onUnmounted(() => {
      unloadBackgroundImage();
      
      socket?.off(socketBoardDetailsUpdatedChannel.value ?? undefined);
      socket?.off(socketColumnsUpdatedChannel.value ?? undefined);
      socket?.off(socketCardMovedChannel.value ?? undefined);

      socketBoardDetailsUpdatedChannel.value = null;
      socketColumnsUpdatedChannel.value = null;
      socketCardMovedChannel.value = null;
    });

    return {
      toggleCreateColumnFormVisibility,
      checkUserParticipationInCard,
      isDeleteColumnModalVisible,
      isCreateColumnFormVisible,
      isCreateCardModalVisible,
      toggleDeleteColumnModal,
      isEditCardModalVisible,
      targetColumnForNewCard,
      onColumnPositionChange,
      handleUpdateAllColumns,
      isDetailsModalVisible,
      handleUpdateMicroCard,
      handleCreateCardModal,
      onCardPositionChange,
      toggleBoardPinStatus,
      onEditColumnFormBlur,
      handleShowCardModal,
      handleEditCardModal,
      editColumnFormState,
      handleUpdateColumn,
      newColumnFormState,
      autoResizeTextarea,
      openColumnEditForm,
      isCardBeingDragged,
      idOfColumnToDelete,
      setCardSkeletonRef,
      columnDragOptions,
      handleUpdateBoard,
      openShowCardModal,
      onColumnDragStart,
      openEditCardModal,
      handleRemoveCard,
      cardDragOptions,
      cardBeingEdited,
      openNewCardForm,
      onColumnDragEnd,
      onCardDragStart,
      handleCopyCard,
      cardBeingShown,
      editColumnArea,
      loadMoreCards,
      onCardDragEnd,
      hasMoreCards,
      createColumn,
      isNotMobile,
      getAppName,
      existsTag,
      columns,
      board,
    };
  },
});
</script>