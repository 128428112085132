import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative flex flex-1 flex-col items-center justify-center sm:pb-16 pt-12" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { class: "mb-6" }
const _hoisted_4 = { class: "mb-6" }
const _hoisted_5 = { class: "mb-6" }
const _hoisted_6 = { class: "mb-6" }
const _hoisted_7 = { class: "mb-6" }
const _hoisted_8 = { class: "mb-6" }
const _hoisted_9 = { class: "relative shrink-0" }
const _hoisted_10 = { class: "space-y-4 text-sm text-gray-900 sm:flex sm:items-center sm:justify-center sm:space-x-4 sm:space-y-0" }
const _hoisted_11 = { class: "flex justify-center font-medium text-gray-500 dark:text-gray-400" }
const _hoisted_12 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Head = _resolveComponent("Head")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Submit = _resolveComponent("Submit")!
  const _component_Link = _resolveComponent("Link")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Head, {
      title: _ctx.$trans('main.sign_up')
    }, null, 8, ["title"]),
    _createVNode(_component_Layout, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Logo),
          _createElementVNode("h1", {
            class: "sr-only",
            textContent: _toDisplayString(_ctx.$trans('main.sign_up'))
          }, null, 8, _hoisted_2),
          _createElementVNode("form", {
            onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"])),
            class: "sm:bg-white sm:dark:bg-gray-900 sm:border-gray-100 sm:dark:border-gray-750 w-full max-w-sm sm:p-5 sm:border-2 sm:bg-opacity-80 sm:backdrop-blur-sm sm:backdrop-filter sm:rounded-md"
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Input, {
                id: "r_first_name",
                type: "text",
                name: "r_first_name",
                label: "validation.attributes.r_first_name",
                autocomplete: "on",
                modelValue: _ctx.registerForm.r_first_name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.registerForm.r_first_name) = $event)),
                required: true,
                form: _ctx.registerForm as any
              }, null, 8, ["modelValue", "form"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Input, {
                id: "r_last_name",
                type: "text",
                name: "r_last_name",
                label: "validation.attributes.r_last_name",
                autocomplete: "on",
                modelValue: _ctx.registerForm.r_last_name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.registerForm.r_last_name) = $event)),
                required: true,
                form: _ctx.registerForm as any
              }, null, 8, ["modelValue", "form"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_Select, {
                id: "r_country_id",
                label: "validation.attributes.r_country_id",
                name: "r_country_id",
                modelValue: _ctx.registerForm.r_country_id,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.registerForm.r_country_id) = $event)),
                "div-class": "mt-2 relative rounded-md shadow-sm",
                value: "id",
                title: "name",
                items: _ctx.countries,
                required: false,
                errors: _ctx.registerForm.errors
              }, null, 8, ["modelValue", "items", "errors"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_Input, {
                id: "r_email",
                type: "email",
                name: "r_email",
                label: "validation.attributes.r_email",
                autocomplete: "on",
                modelValue: _ctx.registerForm.r_email,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.registerForm.r_email) = $event)),
                required: true,
                form: _ctx.registerForm as any
              }, null, 8, ["modelValue", "form"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_Input, {
                id: "r_password",
                type: "password",
                name: "r_password",
                label: "validation.attributes.r_password",
                autocomplete: "on",
                modelValue: _ctx.registerForm.r_password,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.registerForm.r_password) = $event)),
                required: true,
                form: _ctx.registerForm as any
              }, null, 8, ["modelValue", "form"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_Input, {
                id: "r_password_confirmation",
                type: "password",
                name: "r_password_confirmation",
                label: "validation.attributes.r_password_confirmation",
                autocomplete: "on",
                modelValue: _ctx.registerForm.r_password_confirmation,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.registerForm.r_password_confirmation) = $event)),
                required: true,
                form: _ctx.registerForm as any
              }, null, 8, ["modelValue", "form"])
            ]),
            _createVNode(_component_Submit, {
              title: "main.sign_up",
              "main-class": "mt-4",
              processing: _ctx.registerForm.processing,
              disabled: _ctx.registerForm.invalid('r_first_name') || 
                    _ctx.registerForm.invalid('r_last_name') || 
                    _ctx.registerForm.invalid('r_email') || 
                    _ctx.registerForm.invalid('r_password') || 
                    _ctx.registerForm.invalid('r_password_confirmation')
            }, null, 8, ["processing", "disabled"])
          ], 32)
        ]),
        _createElementVNode("footer", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("p", {
                textContent: _toDisplayString(_ctx.$trans('main.already_have') ),
                class: "text-center sm:text-left"
              }, null, 8, _hoisted_12),
              _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
              _createVNode(_component_Link, {
                as: "a",
                href: _ctx.route('login'),
                class: "text-primary-700 hover:underline dark:text-primary-500"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$trans('main.login_here')), 1)
                ]),
                _: 1
              }, 8, ["href"])
            ])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}