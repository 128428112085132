import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "relative flex flex-1 flex-col items-center justify-center pb-16 pt-12" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { class: "mb-6" }
const _hoisted_4 = { class: "mb-6" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "mt-2 text-center text-mini font-medium text-red-600 dark:text-red-500" }
const _hoisted_7 = { class: "relative shrink-0" }
const _hoisted_8 = { class: "space-y-4 text-sm text-gray-900 sm:flex sm:items-center sm:justify-center sm:space-x-4 sm:space-y-0" }
const _hoisted_9 = { class: "flex justify-center font-medium text-gray-500 dark:text-gray-400" }
const _hoisted_10 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Head = _resolveComponent("Head")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Submit = _resolveComponent("Submit")!
  const _component_Link = _resolveComponent("Link")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Head, {
      title: _ctx.$trans('main.reset_password_title')
    }, null, 8, ["title"]),
    _createVNode(_component_Layout, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Logo),
          _createElementVNode("h1", {
            class: "sr-only",
            textContent: _toDisplayString(_ctx.$trans('main.reset_password_title'))
          }, null, 8, _hoisted_2),
          _createElementVNode("form", {
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"])),
            class: "w-full max-w-sm sm:bg-white sm:dark:bg-gray-900 sm:border-gray-100 sm:dark:border-gray-750 sm:p-5 sm:border-2 sm:bg-opacity-80 sm:backdrop-blur-sm sm:backdrop-filter sm:rounded-md"
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Input, {
                id: "password",
                type: "password",
                name: "password",
                label: "main.password",
                modelValue: _ctx.form.password,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.password) = $event)),
                "with-error": false,
                required: true,
                form: _ctx.form as any
              }, null, 8, ["modelValue", "form"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Input, {
                id: "password_confirmation",
                type: "password",
                name: "password_confirmation",
                label: "main.password_confirmation",
                modelValue: _ctx.form.password_confirmation,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.password_confirmation) = $event)),
                "with-error": false,
                required: true,
                form: _ctx.form as any
              }, null, 8, ["modelValue", "form"])
            ]),
            (_ctx.form.hasErrors)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.errors, (error) => {
                    return (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(error), 1))
                  }), 256))
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_Submit, {
              "main-class": "mt-4",
              title: "main.reset_password_action",
              processing: _ctx.form.processing,
              disabled: _ctx.form.invalid('password') || 
                    _ctx.form.invalid('password_confirmation')
            }, null, 8, ["processing", "disabled"])
          ], 32)
        ]),
        _createElementVNode("footer", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("p", {
                textContent: _toDisplayString(_ctx.$trans('main.already_have') ),
                class: "text-center sm:text-left"
              }, null, 8, _hoisted_10),
              _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
              _createVNode(_component_Link, {
                as: "a",
                href: _ctx.route('login'),
                class: "text-primary-700 hover:underline dark:text-primary-500"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$trans('main.login_here')), 1)
                ]),
                _: 1
              }, 8, ["href"])
            ])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}