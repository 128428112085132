<template>
  <RadioGroup v-model="selectedColor as string">
    <span :class="mainClass + ' block mb-2 text-sm font-medium'">
      {{ $trans(label) }}
    </span>
    <div class="grid grid-cols-6 gap-6 overflow-x-scroll p-1">
      <div class="flex items-center space-x-3">
        <div class="relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none"
          :class="[selectedColor && selectedColor === customColorValue && !isPresetColor(selectedColor) ? 'ring-2' : '']"
          :style="{'--tw-ring-color': customColorValue}"
          @click="openColorPicker">
          <span aria-hidden="true" 
            :style="{'background-color': customColorValue || 'transparent', 'border': customColorValue ? 'none' : '2px solid #6B7280'}" 
            class="h-7 w-7 rounded-full"/>
          <input ref="colorPickerInput"
            type="color"
            v-model="customColorValue"
            @input="updateCustomColor"
            class="absolute inset-0 w-full h-full opacity-0 cursor-pointer"/>
        </div>
        <RadioGroupOption v-for="color in colors" :key="color.value" :value="color.value" v-slot="{ checked }">
          <div :class="[
              'relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none',
              checked ? 'ring-2' : ''
            ]"
            :style="{'--tw-ring-color': color.value}">
            <span aria-hidden="true" :style="{'background-color': color.value}" class="h-7 w-7 rounded-full"/>
          </div>
        </RadioGroupOption>
      </div>
    </div>
    <p v-if="withError && form.invalid(name)" class="mt-2 text-sm text-red-600 dark:text-red-500">
      {{ form.errors[name] }}
    </p>
  </RadioGroup>
</template>
<script lang="ts">
import { RadioGroup, RadioGroupOption } from '@headlessui/vue';
import { defineComponent, computed, ref, watch } from 'vue';

export default defineComponent({
  name: 'ColorPicker',
  components: {
    RadioGroupOption,
    RadioGroup,
  },
  props: {
    modelValue: {
      type: String as () => string | null,
      default: ''
    },
    mainClass: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true,
    },
    withError: {
      type: Boolean,
      default: true,
    },
    form: {
      type: Object,
      required: true
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const colors: Array<{ value: string }> = [
      { value: '#7cb7ff' },
      { value: '#1c64f2' },
      { value: '#057a55' },
      { value: '#19bdca' },
      { value: '#5850ec' },
      { value: '#7e3af2' },
      { value: '#d61f69' },
      { value: '#e02424' },
      { value: '#ff8a4c' },
      { value: '#e3a009' },
      { value: '#4b5563' },
    ];

    const customColorValue = ref(props.modelValue);
    const colorPickerInput = ref<HTMLInputElement | null>(null);

    const selectedColor = computed({
      get: () => props.modelValue,
      set: (value: string|null) => emit('update:modelValue', value)
    });

    const updateCustomColor = () => {
      selectedColor.value = customColorValue.value;
    };

    const openColorPicker = () => {
      if (colorPickerInput.value) {
        colorPickerInput.value.click();
      }
    };

    const isPresetColor = (color: string) => {
      return colors.some(c => c.value === color);
    };

    const ringColorStyle = computed(() => ({
    class: 'relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none',
    style: { '--tw-ring-color': customColorValue.value }
  }));

    watch(() => props.modelValue, (newValue) => {
      customColorValue.value = newValue;
    });

    return {
      updateCustomColor,
      customColorValue,
      colorPickerInput,
      openColorPicker,
      ringColorStyle,
      isPresetColor,
      selectedColor,
      colors,
    };
  }
});
</script>