import { default as LightList } from '@/Types/Resources/CheckList/Light'; 
import { default as LightAction } from '@/Types/Resources/Action/Light';
import { default as LightCard } from '@/Types/Resources/Card/Light';
import { default as Check } from '@/Types/Resources/Check/Light';
import { updateLightCardFunction } from '@/Pages/Boards/Types';
import { PayloadResponse } from '@/Types/API/PayloadResponse';
import { useForm } from 'laravel-precognition-vue-inertia';
import { ref, Ref, nextTick } from 'vue';
import { sendRequest } from '@/mixins';
import { getApiToken } from '@/mixins';

import route from '@/ziggy';

type CreateCheck = {
  _token: string;
  _position: number;
  _checklist_id: string | null;
  b_c_c_c_c_content: string;
};

type EditCheck = {
  _token: string;
  _position: number;
  _check_id: string | null;
  b_c_c_c_c_content: string;
};

export function useCheckActions(lists: Ref<LightList[]>, checkIsDragging: Ref<boolean>, card: Ref<LightCard|null>, handleUpdateCard: updateLightCardFunction, handleUpdateActions: Function)
{
  const checkMode = ref<'create'|'edit'|null>(null);
  const editCheck = ref<Check|null>(null);
  const createList = ref<LightList|null>(null);

  const createCheckForm = useForm('post', route('api.v1.boards.cards.checklists.checks.store'), {
    _token: getApiToken(),
    _position: 0,
    _checklist_id: null,
    b_c_c_c_c_content: "",
  } as CreateCheck);

  const editCheckForm = useForm('put', route('api.v1.boards.cards.checklists.checks.update'), {
    _token: getApiToken(),
    _position: 0,
    _check_id: null,
    b_c_c_c_c_content: "",
  } as EditCheck);

  function openCheckCreateForm(list: LightList)
  {
    checkMode.value = 'create';
    createList.value = list;
    
    createCheckForm._position = list.checks.length;
    createCheckForm._checklist_id = list.id;
    createCheckForm.b_c_c_c_c_content = '';

    nextTick(() => {
      const textarea: HTMLTextAreaElement | null = document.getElementById(`create-check-area-${list.id}`) as HTMLTextAreaElement;

      textarea?.focus();
      textarea?.select();
    });
  }

  function closeCheckCreateForm()
  {
    createCheckForm._position = 0;
    createCheckForm._checklist_id = null;
    createCheckForm.b_c_c_c_c_content = '';
    createCheckForm.clearErrors();

    checkMode.value = null;
  }

  function submitCheckCreateForm(list: LightList)
  {
    sendRequest({
      name: 'api.v1.boards.cards.checklists.checks.store',
      data: {
        _checklist_id: createCheckForm._checklist_id,
        _position: createCheckForm._position,
        b_c_c_c_c_content: createCheckForm.b_c_c_c_c_content,
      },
    }, (data: PayloadResponse<Array<Check>>) => {
      list.checks = data.payload;

      checkMode.value = null;
      createCheckForm._position = 0;
      createCheckForm._checklist_id = null;
      createCheckForm.b_c_c_c_c_content = '';

      createCheckForm.reset();
      createCheckForm.clearErrors();
    }, (errors) => {
      createCheckForm.setErrors(errors);
    });
    
    handleUpdateCard({...card.value, checklists: lists.value} as LightCard);
  }
  
  function openCheckEditForm(check: Check)
  {
    checkMode.value = 'edit';
    editCheck.value = check;

    editCheckForm._position = check.position;
    editCheckForm._check_id = check.id;
    editCheckForm.b_c_c_c_c_content = check.content;

    nextTick(() => {
      const textarea: HTMLTextAreaElement | null = document.getElementById(`edit-check-area-${check.id}`) as HTMLTextAreaElement;

      textarea?.focus();
      textarea?.select();
    });
  }

  function closeCheckEditForm()
  {
    editCheckForm._position = 0;
    editCheckForm._check_id = null;
    editCheckForm.b_c_c_c_c_content = '';
    editCheckForm.clearErrors();

    checkMode.value = null;
    editCheck.value = null;
  }

  function submitCheckEditForm(check: Check)
  {
    sendRequest({
      name: 'api.v1.boards.cards.checklists.checks.update',
      data: {
        _position: editCheckForm._position,
        _check_id: editCheckForm._check_id,
        b_c_c_c_c_content: editCheckForm.b_c_c_c_c_content,
      },
    }, (data: PayloadResponse<Check>) => {
      check.id = data.payload.id;
      check.content = data.payload.content;
      check.is_completed = data.payload.is_completed;
      check.position = data.payload.position;
      
      handleUpdateCard({...card.value, checklists: lists.value} as LightCard);

      checkMode.value = null;
      editCheck.value = null;

      editCheckForm._position = 0;
      editCheckForm._check_id = null;
      editCheckForm.b_c_c_c_c_content = '';

      editCheckForm.reset();
      editCheckForm.clearErrors();
    }, (errors) => {
      editCheckForm.setErrors(errors);
    });
  }

  function toggleCheckCompletion(check: Check): void
  {
    if(checkIsDragging.value) return;
    
    check.is_completed = !check.is_completed;
    
    // FIXME: Add setTimeout and is_completed value for backend

    sendRequest({
      name: 'api.v1.boards.cards.checklists.checks.toggle',
      // sync_token: updateSyncToken(),
      params: { check: check.id },
    }, (data: PayloadResponse<{actions: Array<LightAction>, check: Check}>) => {
      check = data.payload.check;
      
      handleUpdateActions(data.payload.actions);
    }, () => {
      check.is_completed = !check.is_completed;
    });

    handleUpdateCard({...card.value, checklists: lists.value} as LightCard);
  }

  function deleteCheck(list: LightList, check: Check): void
  {
    sendRequest({
      name: 'api.v1.boards.cards.checklists.checks.destroy',
      params: { check: check.id },
    }, () => {
      lists.value = lists.value.map((l) => {
        if(l.id === list.id){
          l.checks = l.checks.filter((c) => c.id !== check.id);
        }

        return l;
      });
    });
    
    handleUpdateCard({...card.value, checklists: lists.value} as LightCard);
  }
  return {
    openCheckCreateForm,
    closeCheckCreateForm,
    submitCheckCreateForm,
    openCheckEditForm,
    closeCheckEditForm,
    submitCheckEditForm,
    toggleCheckCompletion,
    createCheckForm,
    editCheckForm,
    deleteCheck,
    editCheck,
    checkMode,
    createList,
  };
}