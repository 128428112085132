import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "border-gray-200 dark:border-gray-800 border-b flex flex-col items-start justify-between gap-x-8 gap-y-4 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "flex items-center gap-x-3" }
const _hoisted_3 = { class: "flex gap-x-3 text-base leading-7" }
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = { class: "border-gray-200 dark:border-gray-800 border-b grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4" }
const _hoisted_8 = { class: "border-gray-200 dark:border-gray-800 border-t py-6 px-4 sm:px-6 lg:px-8" }
const _hoisted_9 = { class: "text-gray-900 dark:text-gray-400 text-sm font-medium leading-6" }
const _hoisted_10 = { class: "mt-2 flex items-baseline gap-x-2" }
const _hoisted_11 = { class: "text-gray-900 dark:text-white text-4xl font-semibold tracking-tight" }
const _hoisted_12 = { class: "border-gray-200 dark:border-gray-800 border-t py-6 px-4 sm:px-6 lg:px-8 sm:border-l" }
const _hoisted_13 = { class: "text-gray-900 dark:text-gray-400 text-sm font-medium leading-6" }
const _hoisted_14 = { class: "mt-2 flex items-baseline gap-x-2" }
const _hoisted_15 = { class: "text-gray-900 dark:text-white text-4xl font-semibold tracking-tight" }
const _hoisted_16 = { class: "border-gray-200 dark:border-gray-800 border-t py-6 px-4 sm:px-6 lg:px-8 lg:border-l" }
const _hoisted_17 = { class: "text-gray-900 dark:text-gray-400 text-sm font-medium leading-6" }
const _hoisted_18 = { class: "mt-2 flex items-baseline gap-x-2" }
const _hoisted_19 = { class: "text-gray-900 dark:text-white text-4xl font-semibold tracking-tight" }
const _hoisted_20 = { class: "border-gray-200 dark:border-gray-800 border-t py-6 px-4 sm:px-6 lg:px-8 sm:border-l" }
const _hoisted_21 = { class: "text-gray-900 dark:text-gray-400 text-sm font-medium leading-6" }
const _hoisted_22 = { class: "mt-2 flex items-baseline gap-x-2" }
const _hoisted_23 = { class: "text-gray-900 dark:text-white text-4xl font-semibold tracking-tight" }
const _hoisted_24 = { class: "border-gray-200 dark:border-gray-800 overflow-x-auto border-t pt-11" }
const _hoisted_25 = { class: "px-4 text-base font-semibold leading-7 text-black dark:text-white sm:px-6 lg:px-8" }
const _hoisted_26 = { class: "min-w-full border-separate border-spacing-0" }
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = ["innerHTML"]
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = ["innerHTML"]
const _hoisted_31 = ["innerHTML"]
const _hoisted_32 = ["innerHTML"]
const _hoisted_33 = ["innerHTML"]
const _hoisted_34 = ["innerHTML"]
const _hoisted_35 = { class: "text-sm font-normal text-gray-500 dark:text-gray-400" }
const _hoisted_36 = {
  key: 0,
  class: "flex items-center justify-center my-48"
}
const _hoisted_37 = { class: "text-gray-400 dark:text-gray-300 box-border block text-2xl leading-5 relative text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, {
    "site-id": _ctx.site.id,
    "site-name": _ctx.site.name
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: _normalizeClass([{
                'text-green-500 bg-green-100 dark:bg-green-900': _ctx.site.last_beat?.success === true,
                'text-red-500 bg-red-100 dark:bg-red-900': _ctx.site.last_beat?.success === false,
              }, "flex-none rounded-full p-1"])
            }, _cache[0] || (_cache[0] = [
              _createElementVNode("div", { class: "h-2 w-2 rounded-full bg-current" }, null, -1)
            ]), 2),
            _createElementVNode("h1", _hoisted_3, [
              _createElementVNode("span", {
                textContent: _toDisplayString(_ctx.site.name),
                class: "text-gray-800 dark:text-white font-semibold"
              }, null, 8, _hoisted_4),
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-gray-400" }, "/", -1)),
              _createElementVNode("span", {
                textContent: _toDisplayString(_ctx.site.tag),
                class: "text-gray-800 dark:text-white font-semibold"
              }, null, 8, _hoisted_5)
            ])
          ]),
          _createElementVNode("p", {
            textContent: _toDisplayString(_ctx.$trans('main.sites.health_description')),
            class: "text-gray-500 dark:text-gray-400 mt-2 text-nano leading-6"
          }, null, 8, _hoisted_6)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$trans('main.sites.stats.average_response_time')), 1),
          _createElementVNode("p", _hoisted_10, [
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.stats?.average_response_time ? _ctx.parseAverageResponseTime(_ctx.stats?.average_response_time) : 0 + _ctx.$trans('main.times.mils')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$trans('main.sites.stats.average_actions_per_day')), 1),
          _createElementVNode("p", _hoisted_14, [
            _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.stats?.average_actions_per_day ? _ctx.stats?.average_actions_per_day : 0), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$trans('main.sites.stats.popular_iso_code')), 1),
          _createElementVNode("p", _hoisted_18, [
            _createElementVNode("span", _hoisted_19, _toDisplayString((_ctx.popularCountryEmoji ?? _ctx.$trans('table.shared.no_data')) + _ctx.truncateText(_ctx.popularCountryName, 20)), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.$trans('main.sites.stats.percentage_of_successful_actions')), 1),
          _createElementVNode("p", _hoisted_22, [
            _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.stats?.percentage_of_successful_actions ? `${_ctx.stats?.percentage_of_successful_actions}%`: _ctx.$trans('table.shared.no_data')), 1)
          ])
        ])
      ])
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("h2", _hoisted_25, _toDisplayString(_ctx.$trans('table.sites.actions.title')), 1),
        _createElementVNode("table", _hoisted_26, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", {
                innerHTML: _ctx.$trans('table.sites.actions.head.id'),
                scope: "col",
                class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold"
              }, null, 8, _hoisted_27),
              _createElementVNode("th", {
                innerHTML: _ctx.$trans('table.sites.actions.head.ip_address'),
                scope: "col",
                class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold"
              }, null, 8, _hoisted_28),
              _createElementVNode("th", {
                innerHTML: _ctx.$trans('table.sites.actions.head.route'),
                scope: "col",
                class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold"
              }, null, 8, _hoisted_29),
              _createElementVNode("th", {
                innerHTML: _ctx.$trans('table.sites.actions.head.method'),
                scope: "col",
                class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold"
              }, null, 8, _hoisted_30),
              _createElementVNode("th", {
                innerHTML: _ctx.$trans('table.sites.actions.head.status_code_text'),
                scope: "col",
                class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold"
              }, null, 8, _hoisted_31),
              _createElementVNode("th", {
                innerHTML: _ctx.$trans('table.sites.actions.head.others'),
                scope: "col",
                class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold"
              }, null, 8, _hoisted_32),
              _createElementVNode("th", {
                innerHTML: _ctx.$trans('table.sites.actions.head.response_time'),
                scope: "col",
                class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold"
              }, null, 8, _hoisted_33),
              _createElementVNode("th", {
                innerHTML: _ctx.$trans('table.sites.actions.head.created_at'),
                scope: "col",
                class: "bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-center text-sm font-semibold"
              }, null, 8, _hoisted_34)
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions?.data, (action, actionIdx) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: action.id
              }, [
                _createElementVNode("td", {
                  class: _normalizeClass([_ctx.checkLastIndex(actionIdx) ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap p-4 text-sm text-center font-medium'])
                }, _toDisplayString(action.id), 3),
                _createElementVNode("td", {
                  class: _normalizeClass([_ctx.checkLastIndex(actionIdx) ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap p-4 text-sm text-center font-medium'])
                }, _toDisplayString(action.iso_code != null ? _ctx.findCountryByIsoCode(action.iso_code)?.emoji : '') + " " + _toDisplayString(action.ip_address) + " " + _toDisplayString(action.iso_code != null ? `(${action.iso_code})` : ''), 3),
                _createElementVNode("td", {
                  class: _normalizeClass([_ctx.checkLastIndex(actionIdx) ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap p-4 text-sm text-center font-medium'])
                }, _toDisplayString(_ctx.truncateText(action.route, 30)), 3),
                _createElementVNode("td", {
                  class: _normalizeClass([_ctx.checkLastIndex(actionIdx) ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap p-4 text-sm text-center font-medium'])
                }, [
                  _createVNode(_component_Badge, {
                    type: action.method === 'GET' ? 'success' : 'warning',
                    text: action.method,
                    class: "mt-1"
                  }, null, 8, ["type", "text"])
                ], 2),
                _createElementVNode("td", {
                  class: _normalizeClass([_ctx.checkLastIndex(actionIdx) ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap px-3 py-4 text-sm text-center'])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass([_ctx.statusCodeColor(action.status_code), 'flex items-center justify-center gap-x-2 text-base font-semibold'])
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass([_ctx.statusCodeColor(action.status_code, false), 'flex-none rounded-full p-1'])
                    }, _cache[2] || (_cache[2] = [
                      _createElementVNode("div", { class: "h-1.5 w-1.5 rounded-full bg-current" }, null, -1)
                    ]), 2),
                    _createElementVNode("span", {
                      class: _normalizeClass([_ctx.statusCodeColor(action.status_code)])
                    }, _toDisplayString(action.status_code), 3)
                  ], 2),
                  _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.truncateText(action.status_text, 30)), 1)
                ], 2),
                _createElementVNode("td", {
                  class: _normalizeClass([_ctx.checkLastIndex(actionIdx) ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap p-4 text-sm text-center font-medium'])
                }, [
                  (action.is_secure)
                    ? (_openBlock(), _createBlock(_component_Badge, {
                        key: 0,
                        type: "success",
                        text: _ctx.$trans('table.sites.actions.tags.secure'),
                        class: "m-1"
                      }, null, 8, ["text"]))
                    : _createCommentVNode("", true),
                  (action.is_redirect)
                    ? (_openBlock(), _createBlock(_component_Badge, {
                        key: 1,
                        type: "info",
                        text: _ctx.$trans('table.sites.actions.tags.redirect'),
                        class: "m-1"
                      }, null, 8, ["text"]))
                    : _createCommentVNode("", true),
                  (action.is_ajax)
                    ? (_openBlock(), _createBlock(_component_Badge, {
                        key: 2,
                        type: "info",
                        text: _ctx.$trans('table.sites.actions.tags.ajax'),
                        class: "m-1"
                      }, null, 8, ["text"]))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("td", {
                  class: _normalizeClass([_ctx.checkLastIndex(actionIdx) ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap p-4 text-sm text-center'])
                }, _toDisplayString(action.response_time), 3),
                _createElementVNode("td", {
                  class: _normalizeClass([_ctx.checkLastIndex(actionIdx) ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap p-4 text-sm text-center'])
                }, _toDisplayString(action.created_at), 3)
              ]))
            }), 128))
          ])
        ])
      ]),
      (!_ctx.actions?.data?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
            _createElementVNode("h2", _hoisted_37, _toDisplayString(_ctx.$trans('main.sites.actions_placeholder')), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.actions?.data != null)
        ? (_openBlock(), _createBlock(_component_Pagination, {
            key: 1,
            data: _ctx.actions
          }, null, 8, ["data"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["site-id", "site-name"]))
}