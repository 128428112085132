import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeClass as _normalizeClass, Transition as _Transition, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "flex flex-col h-full mr-0 relative rounded-lg" }
const _hoisted_4 = { class: "bg-gray-50 dark:bg-gray-850/40 border-gray-300/50 dark:border-gray-850/10 backdrop-blur-xl md:rounded-lg py-4 sm:px-6 flex items-center justify-between h-auto relative z-0 border-b" }
const _hoisted_5 = { class: "relative basis-8/12 flex flex-row items-center min-h-8 flex-nowrap max-w-full" }
const _hoisted_6 = {
  class: "basis-auto break-words roundedcursor-default inline-flex relative whitespace-nowrap leading-8 mb-0 max-w-full",
  tabindex: "0",
  role: "textbox"
}
const _hoisted_7 = { class: "bg-transparent text-gray-950 dark:text-white content-center truncate py-1.5 sm:py-0 pl-1 pr-2.5 text-lg font-bold no-underline text-ellipsis h-full leading-tight whitespace-normal overflow-hidden" }
const _hoisted_8 = { class: "text-gray-950 dark:text-white basis-auto items-center bg-transparent rounded-md border-0 cursor-pointer flex flex-shrink-0 font-sans text-base font-medium h-8 justify-center leading-8 m-0 overflow-visible p-1.5 relative text-center no-underline transition-colors duration-20 ease-in-out w-8 whitespace-no-wrap appearance-none shadow-none box-border" }
const _hoisted_9 = { class: "inline-block leading-none" }
const _hoisted_10 = { class: "relative hidden basis-4/12 md:flex justify-end items-center min-h-8 flex-nowrap" }
const _hoisted_11 = { class: "flex -space-x-2 overflow-hidden p-0.5" }
const _hoisted_12 = {
  key: 0,
  class: "block text-nano p-1.5"
}
const _hoisted_13 = { class: "grow mt-3" }
const _hoisted_14 = {
  key: 0,
  class: "leading-4 overflow-hidden break-words font-medium pt-2 pl-1 pr-2 w-full h-auto min-w-min"
}
const _hoisted_15 = ["onFocusout"]
const _hoisted_16 = { class: "column-no-drag flex-grow-0 flex-shrink-0 mb-0 px-2 pt-2 rounded-md text-gray-500" }
const _hoisted_17 = { role: "none" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["onClick"]
const _hoisted_20 = ["onClick"]
const _hoisted_21 = {
  key: 0,
  class: "flex flex-wrap gap-1 mb-1 mr-1.5"
}
const _hoisted_22 = {
  key: 0,
  class: "inline-flex max-w-[calc(100%-0.25rem)]"
}
const _hoisted_23 = { class: "break-words clear-both block mx-0 mt-0 mb-1 no-underline text-sm" }
const _hoisted_24 = { class: "float-left max-w-full mt-1.5" }
const _hoisted_25 = { class: "text-gray-500 flex items-center overflow-hidden relative no-underline text-ellipsis align-top" }
const _hoisted_26 = {
  key: 2,
  class: "flex items-center"
}
const _hoisted_27 = { class: "float-right m-0" }
const _hoisted_28 = { class: "cursor-pointer flex -space-x-2 overflow-hidden p-0.5 float-right text-sm font-normal leading-5 ml-1 relative no-underline select-none z-0" }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = { class: "items-center flex flex-shrink-0 font-sans text-base font-normal h-11 justify-between leading-5 user-select-none w-68" }
const _hoisted_31 = { class: "bg-opacity-0 rounded-2xl flex flex-auto flex-grow flex-shrink-0 font-sans text-base font-normal h-5 leading-5 mb-2 ml-2 mt-2 pb-1 pl-1.5 pr-2 relative no-underline user-select-none w-52" }
const _hoisted_32 = ["onClick"]
const _hoisted_33 = ["onClick"]
const _hoisted_34 = { class: "column-no-drag my-0 mx-1.5 first:ml-3 last:mr-3 box-border inline-block !h-full scroll-m-2 align-top whitespace-nowrap" }
const _hoisted_35 = {
  key: 0,
  class: "self-start flex-shrink-0 block min-h-5"
}
const _hoisted_36 = { class: "relative rounded-md shadow-sm" }
const _hoisted_37 = {
  key: 0,
  class: "my-1 text-nano whitespace-normal text-red-600 dark:text-red-500"
}
const _hoisted_38 = {
  type: "submit",
  class: "bg-primary-700 dark:bg-primary-600 hover:bg-primary-800 dark:hover:bg-primary-700 text-white rounded-lg sm:w-auto text-sm inline-flex w-full justify-center items-center px-2.5 py-1.5 sm:mr-3 font-medium text-center"
}
const _hoisted_39 = {
  key: 1,
  clas: "items-center appearance-none border-none rounded-xl box-border text-white cursor-pointer inline-flex text-sm font-medium h-11 justify-start mb-2 px-3 py-3 relative select-none transition-colors duration-75 ease-in-out"
}
const _hoisted_40 = { class: "mr-2 inline-block leading-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Head = _resolveComponent("Head")!
  const _component_SolidStar = _resolveComponent("SolidStar")!
  const _component_OutlineStar = _resolveComponent("OutlineStar")!
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_EllipsisHorizontalIcon = _resolveComponent("EllipsisHorizontalIcon")!
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_EyeIcon = _resolveComponent("EyeIcon")!
  const _component_Bars3CenterLeftIcon = _resolveComponent("Bars3CenterLeftIcon")!
  const _component_HashtagIcon = _resolveComponent("HashtagIcon")!
  const _component_PencilIcon = _resolveComponent("PencilIcon")!
  const _component_CardSkeleton = _resolveComponent("CardSkeleton")!
  const _component_VueDraggableNext = _resolveComponent("VueDraggableNext")!
  const _component_PlusIcon = _resolveComponent("PlusIcon")!
  const _component_Layout = _resolveComponent("Layout")!
  const _component_CardShow = _resolveComponent("CardShow")!
  const _component_CardCreate = _resolveComponent("CardCreate")!
  const _component_CardEdit = _resolveComponent("CardEdit")!
  const _component_Attempt = _resolveComponent("Attempt")!
  const _directive_dragscroll = _resolveDirective("dragscroll")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Head, null, {
      default: _withCtx(() => [
        _createElementVNode("title", null, _toDisplayString(_ctx.board.name) + " | " + _toDisplayString(_ctx.getAppName()), 1),
        (_ctx.board.bg_path_medium)
          ? (_openBlock(), _createElementBlock("link", {
              key: 0,
              rel: "preload",
              as: "image",
              href: _ctx.board.bg_path_medium
            }, null, 8, _hoisted_1))
          : _createCommentVNode("", true),
        (_ctx.board.bg_path_original)
          ? (_openBlock(), _createElementBlock("link", {
              key: 1,
              rel: "preload",
              as: "image",
              href: _ctx.board.bg_path_original
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_Layout, {
      "main-classes": [],
      "div-classes": ['bg-transparent', 'ring-transparent'],
      "logo-color": ['filter-white'],
      "navigation-color": ['text-white']
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("header", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "text-gray-950 dark:text-white basis-auto items-center bg-transparent rounded-md border-0 cursor-pointer flex flex-shrink-0 font-sans text-base font-medium h-8 justify-center leading-8 m-0 overflow-visible p-1.5 relative text-center no-underline transition-colors duration-20 ease-in-out w-8 whitespace-no-wrap appearance-none shadow-none box-border" }, [
                _createElementVNode("span", { class: "inline-block leading-none" }, [
                  _createElementVNode("svg", {
                    class: "w-5 h-5 stroke-2",
                    viewBox: "0 0 24 24",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }, [
                    _createElementVNode("path", {
                      d: "M9 20C9 14.1421 9 5 9 5V20ZM9 20H4.125C3.504 20 3 19.496 3 18.875V6.125C3 5.504 3.504 5 4.125 5H19.875C20.496 5 21 5.504 21 6.125V12C21 12.621 20.496 13 19.875 13H15M15 5V13M15 13V16H9.625C9.004 16 9 15.121 9 14.5",
                      stroke: "currentColor",
                      "stroke-width": "1.5",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round"
                    })
                  ])
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.board.name), 1)
              ]),
              _createElementVNode("button", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, [
                  (_ctx.board.is_pinned)
                    ? (_openBlock(), _createBlock(_component_SolidStar, {
                        key: 0,
                        onClick: _ctx.toggleBoardPinStatus,
                        class: "h-4 w-4 leading-4",
                        "aria-hidden": "true"
                      }, null, 8, ["onClick"]))
                    : (_openBlock(), _createBlock(_component_OutlineStar, {
                        key: 1,
                        onClick: _ctx.toggleBoardPinStatus,
                        class: "h-4 w-4 leading-4",
                        "aria-hidden": "true"
                      }, null, 8, ["onClick"]))
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.board.members.slice(0, 5), (member) => {
                  return (_openBlock(), _createBlock(_component_UserAvatar, {
                    key: member.id,
                    user: member,
                    size: "sm"
                  }, null, 8, ["user"]))
                }), 128))
              ]),
              (_ctx.board.members.length > 5)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, " + " + _toDisplayString(_ctx.board.members.length - 5), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _withDirectives((_openBlock(), _createBlock(_component_VueDraggableNext, {
              class: "board board-scrollbar overflow-x-auto overflow-y-hidden select-none whitespace-nowrap bottom-0 left-0 right-0 top-0 mb-2 pb-2",
              "empty-insert-threshold": _ctx.columnDragOptions.emptyInsertThreshold,
              fallbackTolerance: _ctx.columnDragOptions.fallbackTolerance,
              removeCloneOnHide: _ctx.columnDragOptions.removeCloneOnHide,
              "prevent-on-filter": _ctx.columnDragOptions.preventOnFilter,
              dragoverBubble: _ctx.columnDragOptions.dragoverBubble,
              "force-fallback": _ctx.columnDragOptions.forceFallback,
              "chosen-class": _ctx.columnDragOptions.chosenClass,
              "ghost-class": _ctx.columnDragOptions.ghostClass,
              animation: _ctx.columnDragOptions.animation,
              direction: _ctx.columnDragOptions.direction,
              handle: `.${_ctx.columnDragOptions.handle}`,
              "item-key": _ctx.columnDragOptions.itemKey,
              filter: _ctx.columnDragOptions.filter,
              easing: _ctx.columnDragOptions.easing,
              delay: _ctx.columnDragOptions.delay,
              group: _ctx.columnDragOptions.group,
              onChange: _ctx.onColumnPositionChange,
              onStart: _ctx.onColumnDragStart,
              onEnd: _ctx.onColumnDragEnd,
              modelValue: _ctx.columns,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.columns) = $event))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: column.id,
                    "data-dragscroll": "",
                    class: "w-[--column-width] max-h-[--column-height] my-0 mx-1.5 first:ml-3 box-border inline-block !h-full scroll-m-2 align-top whitespace-nowrap"
                  }, [
                    _createVNode(_Transition, {
                      "enter-active-class": "transition ease-out duration-100",
                      "enter-from-class": "transform opacity-0 scale-95",
                      "enter-to-class": "transform opacity-100 scale-100",
                      "leave-active-class": "transition ease-in duration-75",
                      "leave-from-class": "transform opacity-100 scale-100",
                      "leave-to-class": "transform opacity-0 scale-95"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          "data-no-dragscroll": "",
                          class: _normalizeClass([_ctx.columnDragOptions.handle, "column bg-gray-100 dark:bg-black w-full whitespace-normal relative max-h-full flex flex-col box-border rounded-xl"])
                        }, [
                          _createVNode(_component_Menu, {
                            as: "div",
                            class: "grid gap-x-1 flex-[0_0_auto] grid-cols-[1fr_max-content] min-h-[--column-header-height] px-4 pt-2 pb-0 relative"
                          }, {
                            default: _withCtx(({ close }) => [
                              (_ctx.editColumnFormState._column_id != column.id)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(column.name), 1))
                                : _withDirectives((_openBlock(), _createElementBlock("textarea", {
                                    key: 1,
                                    id: "edit-column-area",
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editColumnFormState.b_c_name) = $event)),
                                    onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.autoResizeTextarea('edit-column-area'))),
                                    onFocusout: ($event: any) => (_ctx.onEditColumnFormBlur(column)),
                                    maxlength: "255",
                                    autocomplete: "off",
                                    class: "border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700 column-no-drag leading-4 max-h-64 mt-2 static content-center bg-transparent rounded-md shadow-none box-border font-semibold m-0 h-8 overflow-hidden break-words py-2 px-2 resize-none"
                                  }, null, 40, _hoisted_15)), [
                                    [_vModelText, _ctx.editColumnFormState.b_c_name]
                                  ]),
                              _createElementVNode("div", _hoisted_16, [
                                _createVNode(_component_MenuButton, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_EllipsisHorizontalIcon, { class: "h-4 w-4" })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _createVNode(_Transition, {
                                "enter-active-class": "transition ease-out duration-100",
                                "enter-from-class": "transform opacity-0 scale-95",
                                "enter-to-class": "transform opacity-100 scale-100",
                                "leave-active-class": "transition ease-in duration-75",
                                "leave-from-class": "transform opacity-100 scale-100",
                                "leave-to-class": "transform opacity-0 scale-95"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_MenuItems, { class: "bg-white dark:bg-gray-850 border-gray-200 dark:border-gray-750 divide-gray-100 dark:divide-gray-750 border origin-top-left absolute mt-10 z-5 left-56 max-w-32 w-48 py-1 text-sm list-none rounded shadow-sm" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("ul", _hoisted_17, [
                                        _createElementVNode("li", {
                                          onClick: _withModifiers(($event: any) => (_ctx.openColumnEditForm(column, close)), ["stop"]),
                                          class: _normalizeClass([{'column-no-drag': _ctx.isNotMobile}, "text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-white cursor-pointer select-none px-3 py-2"])
                                        }, _toDisplayString(_ctx.$trans('main.shared.buttons.edit')), 11, _hoisted_18),
                                        _createElementVNode("li", {
                                          onClick: _withModifiers(($event: any) => (_ctx.toggleDeleteColumnModal(true, column.id, close)), ["stop"]),
                                          class: _normalizeClass([{'column-no-drag': _ctx.isNotMobile}, "text-red-700 dark:hover:text-white dark:hover:bg-red-800 hover:bg-red-100 cursor-pointer select-none px-3 py-2"])
                                        }, _toDisplayString(_ctx.$trans('main.shared.buttons.delete')), 11, _hoisted_19)
                                      ])
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_VueDraggableNext, {
                            class: "my-1 list-cards mx-auto overflow-y-auto overflow-x-hidden column-no-drag",
                            invertedSwapThreshold: _ctx.cardDragOptions.invertedSwapThreshold,
                            emptyInsertThreshold: _ctx.cardDragOptions.emptyInsertThreshold,
                            touchStartThreshold: _ctx.cardDragOptions.touchStartThreshold,
                            removeCloneOnHide: _ctx.cardDragOptions.removeCloneOnHide,
                            fallbackTolerance: _ctx.cardDragOptions.fallbackTolerance,
                            ignoreTransition: _ctx.cardDragOptions.ignoreTransition,
                            delayOnTouchOnly: _ctx.cardDragOptions.delayOnTouchOnly,
                            preventOnFilter: _ctx.cardDragOptions.preventOnFilter,
                            supportPointer: _ctx.cardDragOptions.supportPointer,
                            dragoverBubble: _ctx.cardDragOptions.dragoverBubble,
                            onChange: ($event: any) => (_ctx.onCardPositionChange($event, column)),
                            swapThreshold: _ctx.cardDragOptions.swapThreshold,
                            forceFallback: _ctx.cardDragOptions.forceFallback,
                            ghostClass: _ctx.cardDragOptions.ghostClass,
                            invertSwap: _ctx.cardDragOptions.invertSwap,
                            animation: _ctx.cardDragOptions.animation,
                            direction: _ctx.cardDragOptions.direction,
                            handle: `.${_ctx.cardDragOptions.handle}`,
                            itemKey: _ctx.cardDragOptions.itemKey,
                            easing: _ctx.cardDragOptions.easing,
                            filter: _ctx.cardDragOptions.filter,
                            delay: _ctx.cardDragOptions.delay,
                            group: _ctx.cardDragOptions.group,
                            sort: _ctx.cardDragOptions.sort,
                            modelValue: column.cards.data,
                            "onUpdate:modelValue": ($event: any) => ((column.cards.data) = $event),
                            onStart: _ctx.onCardDragStart,
                            onEnd: _ctx.onCardDragEnd
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(column.cards.data, (element) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: element.id,
                                  class: _normalizeClass([_ctx.cardDragOptions.handle, "card-scrollbar column-no-drag p-2 flex flex-[1_1_auto] flex-col gap-2 justify-between my-0 pt-2 pb-0 min-h-0 overflow-y-auto overflow-x-hidden"])
                                }, [
                                  _createElementVNode("div", {
                                    class: _normalizeClass(["bg-white dark:bg-gray-900 text-gray-950 dark:text-white h-auto rounded-md shadow-sm cursor-pointer block flex-shrink-0 font-sans text-sm font-normal leading-5 mb-1 max-w-[--card-width] min-h-[--card-height] relative select-none w-60", {'group hover:opacity-100 hover:outline hover:outline-2 hover:outline-blue-500': !_ctx.isCardBeingDragged}])
                                  }, [
                                    _createElementVNode("div", {
                                      onClick: ($event: any) => (_ctx.openShowCardModal(element.id)),
                                      class: "card overflow-hidden relative z-15 py-2 pr-2 pl-3"
                                    }, [
                                      (element.tags.length > 0)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(element.tags, (tag) => {
                                              return (_openBlock(), _createElementBlock(_Fragment, null, [
                                                (_ctx.existsTag(tag))
                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                                                      _createElementVNode("button", {
                                                        class: _normalizeClass(`bg-${tag.color}-600 dark:bg-${tag.color}-500 my-0 px-0 py-0 min-w-[--card-tag-width] max-w-[--card-tag-width] h-[--card-tag-height] inline-block rounded leading-4 box-border`)
                                                      }, null, 2)
                                                    ]))
                                                  : _createCommentVNode("", true)
                                              ], 64))
                                            }), 256))
                                          ]))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("span", _hoisted_23, _toDisplayString(element.title), 1),
                                      _createElementVNode("div", _hoisted_24, [
                                        _createElementVNode("div", _hoisted_25, [
                                          (_ctx.checkUserParticipationInCard(element))
                                            ? (_openBlock(), _createBlock(_component_EyeIcon, {
                                                key: 0,
                                                class: "h-4 w-4 mr-2",
                                                "aria-hidden": "true"
                                              }))
                                            : _createCommentVNode("", true),
                                          (element.exists_description)
                                            ? (_openBlock(), _createBlock(_component_Bars3CenterLeftIcon, {
                                                key: 1,
                                                class: "h-4 w-4 mr-2",
                                                "aria-hidden": "true"
                                              }))
                                            : _createCommentVNode("", true),
                                          (element.number)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_26, [
                                                _createVNode(_component_HashtagIcon, {
                                                  class: "h-4 w-4",
                                                  "aria-hidden": "true"
                                                }),
                                                _createTextVNode(" " + _toDisplayString(element.number), 1)
                                              ]))
                                            : _createCommentVNode("", true)
                                        ])
                                      ]),
                                      _createElementVNode("div", _hoisted_27, [
                                        _createElementVNode("div", _hoisted_28, [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(element.members.slice(0, 5), (member) => {
                                            return (_openBlock(), _createBlock(_component_UserAvatar, {
                                              key: member.id,
                                              user: member,
                                              size: "xs",
                                              "with-tooltip": false
                                            }, null, 8, ["user"]))
                                          }), 128))
                                        ])
                                      ])
                                    ], 8, _hoisted_20),
                                    _createElementVNode("button", {
                                      onClick: _withModifiers(($event: any) => (_ctx.openEditCardModal(element)), ["prevent"]),
                                      class: _normalizeClass([{'column-no-drag card-no-drag': _ctx.isNotMobile}, "bg-gray-200 dark:bg-gray-900 text-gray-600 dark:text-gray-500 hover:bg-gray-300 dark:hover:bg-gray-850 md:opacity-0 group-hover:opacity-100 flex absolute top-0.5 right-0.5 rounded-full p-1.5 z-15 text-sm leading-5 box-border items-center justify-center cursor-pointer no-underline whitespace-normal shadow-none border-none font-medium"])
                                    }, [
                                      _createVNode(_component_PencilIcon, { class: "h-4 w-4 inline-block" })
                                    ], 10, _hoisted_29)
                                  ], 2)
                                ], 2))
                              }), 128)),
                              _createVNode(_component_CardSkeleton, {
                                "main-class": "card-no-drag column-no-drag",
                                ref_for: true,
                                ref: el => _ctx.setCardSkeletonRef(column.id, el),
                                "column-id": column.id,
                                "current-page": column.cards.current_page,
                                "last-page": column.cards.last_page,
                                onLoadMore: () => _ctx.loadMoreCards(column)
                              }, null, 8, ["column-id", "current-page", "last-page", "onLoadMore"])
                            ]),
                            _: 2
                          }, 1032, ["invertedSwapThreshold", "emptyInsertThreshold", "touchStartThreshold", "removeCloneOnHide", "fallbackTolerance", "ignoreTransition", "delayOnTouchOnly", "preventOnFilter", "supportPointer", "dragoverBubble", "onChange", "swapThreshold", "forceFallback", "ghostClass", "invertSwap", "animation", "direction", "handle", "itemKey", "easing", "filter", "delay", "group", "sort", "modelValue", "onUpdate:modelValue", "onStart", "onEnd"]),
                          _createElementVNode("div", _hoisted_30, [
                            _createElementVNode("div", _hoisted_31, [
                              _createElementVNode("span", {
                                onClick: ($event: any) => (_ctx.openNewCardForm(column)),
                                class: _normalizeClass([{'column-no-drag': _ctx.isNotMobile}, "text-gray-600 cursor-pointer inline-block font-trellicons text-lg font-normal h-5 leading-5 mr-1.5 text-center user-select-none align-bottom w-5 antialiased"])
                              }, [
                                _createVNode(_component_PlusIcon, { class: "h-5 w-5" })
                              ], 10, _hoisted_32),
                              _createElementVNode("span", {
                                onClick: ($event: any) => (_ctx.openNewCardForm(column)),
                                class: _normalizeClass([{'column-no-drag': _ctx.isNotMobile}, "text-gray-600 cursor-pointer inline font-sans text-sm font-normal leading-5 user-select-none"])
                              }, _toDisplayString(_ctx.$trans('main.boards.buttons.add_card')), 11, _hoisted_33)
                            ])
                          ])
                        ], 2)
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                }), 128)),
                _createElementVNode("div", _hoisted_34, [
                  (_ctx.isCreateColumnFormVisible)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                        _createElementVNode("form", {
                          onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.createColumn && _ctx.createColumn(...args)), ["prevent"])),
                          class: "rounded-xl p-2 w-60 bg-gray-100 dark:bg-black shadow-[var(--ds-shadow-raised,0_1px_1px_#091e4240,0_0_1px_#091e424f)] box-border"
                        }, [
                          _createElementVNode("div", _hoisted_36, [
                            _withDirectives(_createElementVNode("textarea", {
                              id: "new-column-area",
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newColumnFormState.b_c_name) = $event)),
                              maxlength: "255",
                              onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.newColumnFormState.validate('b_c_name'))),
                              onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.autoResizeTextarea('new-column-area'))),
                              class: _normalizeClass([{
                      'border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700': !_ctx.newColumnFormState.invalid('b_c_name'),
                      'border-red-300 dark:border-red-500 text-red-700 dark:text-red-500 focus:ring-red-500 focus:border-red-500': _ctx.newColumnFormState.invalid('b_c_name')
                    }, "form-column-area bg-gray-50 dark:bg-gray-850 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full py-2 px-2 focus:outline-none rounded-md !leading-4 h-8 resize-none"])
                            }, null, 34), [
                              [_vModelText, _ctx.newColumnFormState.b_c_name]
                            ])
                          ]),
                          (_ctx.newColumnFormState.invalid('b_c_name'))
                            ? (_openBlock(), _createElementBlock("p", _hoisted_37, _toDisplayString(_ctx.newColumnFormState.errors['b_c_name']), 1))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", {
                            class: _normalizeClass([[_ctx.newColumnFormState.invalid('b_c_name') ? 'mt-0' : 'mt-4'], "flex items-center justify-start gap-1 rounded-sm"])
                          }, [
                            _createElementVNode("button", _hoisted_38, _toDisplayString(_ctx.$trans('main.shared.buttons.save')), 1),
                            _createElementVNode("button", {
                              type: "button",
                              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleCreateColumnFormVisibility())),
                              class: "bg-transparent text-gray-500 mx-2 sm:mx-0 mb-0 border-none shadow-none inline-flex items-center justify-center rounded text-sm font-medium leading-5 box-border cursor-pointer no-underline whitespace-normal"
                            }, _toDisplayString(_ctx.$trans('main.shared.buttons.cancel')), 1)
                          ], 2)
                        ], 32)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_39, [
                        _createElementVNode("button", {
                          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isCreateColumnFormVisible = true)),
                          class: "bg-gray-100/50 dark:bg-gray-800/75 text-gray-950 dark:text-white w-60 backdrop-blur items-center appearance-none rounded-xl border-transparent shadow-none box-border cursor-pointer inline-flex text-sm font-medium h-11 justify-center mb-2 p-3 relative transition duration-75 ease-in-out select-none"
                        }, [
                          _createElementVNode("span", _hoisted_40, [
                            _createVNode(_component_PlusIcon, { class: "h-5 w-5" })
                          ]),
                          _createTextVNode(" " + _toDisplayString(_ctx.$trans('main.boards.buttons.add_column')), 1)
                        ])
                      ]))
                ])
              ]),
              _: 1
            }, 8, ["empty-insert-threshold", "fallbackTolerance", "removeCloneOnHide", "prevent-on-filter", "dragoverBubble", "force-fallback", "chosen-class", "ghost-class", "animation", "direction", "handle", "item-key", "filter", "easing", "delay", "group", "onChange", "onStart", "onEnd", "modelValue"])), [
              [
                _directive_dragscroll,
                _ctx.isNotMobile,
                "nochilddrag",
                {
                  x: true,
                  noMiddleMouse: true
                }
              ]
            ])
          ])
        ])
      ]),
      _: 1
    }),
    (_ctx.isDetailsModalVisible)
      ? (_openBlock(), _createBlock(_component_CardShow, {
          key: 0,
          card: _ctx.cardBeingShown,
          board: _ctx.board,
          colors: _ctx.colors,
          open: _ctx.isDetailsModalVisible,
          onUpdateBoard: _ctx.handleUpdateBoard,
          onUpdateBoardCard: _ctx.handleUpdateMicroCard,
          onRemoveBoardCard: _ctx.handleRemoveCard,
          onCopyBoardCard: _ctx.handleCopyCard,
          onClose: _ctx.handleShowCardModal
        }, null, 8, ["card", "board", "colors", "open", "onUpdateBoard", "onUpdateBoardCard", "onRemoveBoardCard", "onCopyBoardCard", "onClose"]))
      : _createCommentVNode("", true),
    _createVNode(_component_CardCreate, {
      open: _ctx.isCreateCardModalVisible,
      board: _ctx.board,
      column: _ctx.targetColumnForNewCard,
      onClose: _ctx.handleCreateCardModal,
      onUpdateColumn: _ctx.handleUpdateColumn
    }, null, 8, ["open", "board", "column", "onClose", "onUpdateColumn"]),
    _createVNode(_component_CardEdit, {
      card: _ctx.cardBeingEdited,
      open: _ctx.isEditCardModalVisible,
      onClose: _ctx.handleEditCardModal,
      onUpdateCard: _ctx.handleUpdateMicroCard
    }, null, 8, ["card", "open", "onClose", "onUpdateCard"]),
    _createVNode(_component_Attempt, {
      open: _ctx.isDeleteColumnModalVisible,
      close: (value) => _ctx.isDeleteColumnModalVisible = value,
      routeName: "boards.columns.destroy",
      "delete-callback": () => _ctx.handleUpdateAllColumns(_ctx.columns.filter(column => column.id !== _ctx.idOfColumnToDelete)),
      routeArgument: { id: _ctx.idOfColumnToDelete },
      title: _ctx.$trans('main.attempts.delete_board_column.title'),
      description: _ctx.$trans('main.attempts.delete_board_column.description'),
      successButtonText: _ctx.$trans('main.attempts.buttons.success_button'),
      cancelButtonText: _ctx.$trans('main.attempts.buttons.cancel_button')
    }, null, 8, ["open", "close", "delete-callback", "routeArgument", "title", "description", "successButtonText", "cancelButtonText"])
  ], 64))
}