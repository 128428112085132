import { Ziggy as RoutesConfig } from "./ziggy.generated";
import { RouteParams, Router as BaseRouter } from "./Types/App/ziggy";
import Ziggy from "ziggy/index.es";

export type RouteName = keyof typeof RoutesConfig.routes;

export interface ZiggyRouter extends BaseRouter
{
    current(): string | undefined;
    current(name: RouteName): boolean;
    check(name: RouteName): boolean;
}

export default function route(name: RouteName, params?: RouteParams<string & {}>, absolute?: boolean): string
{
    return Ziggy(name, params, absolute, RoutesConfig) as string;
}

export function routeMethod(name: RouteName): string
{
    return RoutesConfig.routes[name].methods[0] as string;
}

export const Router: ZiggyRouter = Ziggy(false, false, false, RoutesConfig) as unknown as ZiggyRouter;