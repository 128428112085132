import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["data-tooltip"]
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["user-avatar-wrapper", {'no-tooltip': !_ctx.withTooltip}]),
    "data-tooltip": _ctx.tooltipText
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.classes, _ctx.sizeClass, 'user-avatar rounded-full overflow-hidden'])
    }, [
      (_ctx.user?.photo_path)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.user.photo_path,
            alt: _ctx.altText,
            class: "w-full h-full object-cover"
          }, null, 8, _hoisted_2))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass([
          `bg-${_ctx.userColor}-${_ctx.colorShade}`,
          'flex items-center justify-center w-full h-full'
        ])
          }, [
            _createElementVNode("span", {
              class: _normalizeClass([_ctx.fontSizeClass, 'font-nunito font-semibold text-white select-none'])
            }, _toDisplayString(_ctx.initials), 3)
          ], 2))
    ], 2)
  ], 10, _hoisted_1))
}