import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.mainClasses, 'relative flex flex-col items-center group/tooltip'])
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([_ctx.blockClasses, 'absolute bottom-0 flex-col items-center hidden mb-6 group-hover/tooltip:flex'])
        }, [
          _createElementVNode("span", {
            class: _normalizeClass([_ctx.textClasses, 'bg-white dark:bg-gray-800 text-gray-900 dark:text-white whitespace-normal rounded-lg relative z-10 p-2 text-sm font-medium leading-none whitespace-no-wrap shadow-lg'])
          }, _toDisplayString(_ctx.text), 3),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "w-3 h-3 -mt-2 rotate-45 bg-white dark:bg-gray-800 z-10 shadow-lg rounded-[3px]" }, null, -1))
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}