<template>
  <Layout>
    <template #content>
      <div class="divide-gray-200 dark:divide-gray-800 divide-y">
        <div class="grid lg:max-w-full max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-10 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 class="text-gray-900 dark:text-white text-base font-semibold leading-7">
              {{ $trans('main.profile.personal.title') }}
            </h2>
            <p class="text-gray-600 dark:text-gray-400 mt-1 text-sm leading-6">
              {{ $trans('main.profile.personal.description') }}
            </p>
          </div>
          <form class="md:col-span-2" @submit.prevent="submitGeneral">
            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
              <div class="col-span-full flex items-center gap-x-8">
                <UserAvatar :user="user" size="3xl" :withTooltip="false" :classes="`${user.photo_path ? 'rounded-lg' : ''}`"/>
                <div>
                  <h3 class="text-gray-900 dark:text-white mb-1 max-w-48 truncate text-xl font-bold">
                    {{ user.name }}
                  </h3>
                  <div class="mb-2 text-sm font-normal" 
                    :class="{'text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-pink-500 to-teal-400 font-bold': user.role.value == 'super_admin',
                            'text-primary-700 dark:text-primary-500': user.role.value == 'admin',
                            'text-teal-700 dark:text-teal-500': user.role.value == 'moderator',
                            'text-gray-700 dark:text-gray-500': user.role.value == 'user'}">
                    {{ user.role.name }}
                  </div>
                  <input type="file" class="hidden" ref="fileInput" accept="image/*" @change="changeAvatar"/>
                  <button type="button" @click="browse" class="bg-gray-900 dark:bg-primary-800 hover:bg-gray-700 dark:hover:bg-primary-700 text-white dark:text-gray-100 focus:ring-gray-700 dark:focus:ring-primary-950 max-w-48 focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent inline-flex items-center py-1 px-3 text-sm font-light text-center rounded-md">
                    <svg class="mr-2 -ml-1 w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                    </svg>
                    {{ $trans('main.profile.titles.avatar') }}
                  </button>
                  <br/>
                  <span v-if="avatarForm.errors.photo" class="mt-2 text-sm font-medium text-red-700 dark:text-red-500">
                    {{ avatarForm.errors.photo }}
                  </span>
                </div>
              </div>
              <div class="col-span-6 sm:col-span-3">
                <Input
                  id="p_first_name"
                  type="text"
                  label="validation.attributes.p_first_name"
                  div-class="mt-1 relative rounded-md shadow-sm"
                  name="p_first_name"
                  v-model="generalForm.p_first_name"
                  :placeholder="$trans('main.profile.placeholders.first_name')"
                  :required="true"
                  :form="generalForm"/>
              </div>
              <div class="col-span-6 sm:col-span-3">
                <Input
                  id="p_last_name"
                  type="text"
                  label="validation.attributes.p_last_name"
                  div-class="mt-1 relative rounded-md shadow-sm"
                  name="p_last_name"
                  v-model="generalForm.p_last_name"
                  :placeholder="$trans('main.profile.placeholders.last_name')"
                  :required="true"
                  :form="generalForm"/>
              </div>
              <div class="col-span-6 sm:col-span-3">
                <Input
                  id="p_nickname"
                  type="text"
                  label="validation.attributes.p_nickname"
                  div-class="mt-1 relative rounded-md shadow-sm"
                  name="p_nickname"
                  v-model="generalForm.p_nickname"
                  :placeholder="$trans('main.profile.placeholders.nickname')"
                  :required="true"
                  :form="generalForm"/>
              </div>
              <div class="col-span-6 sm:col-span-3">
                <Input
                  id="p_phone_number"
                  type="text"
                  label="validation.attributes.p_phone_number"
                  div-class="mt-1 relative rounded-md shadow-sm"
                  name="p_phone_number"
                  v-model="generalForm.p_phone_number"
                  :placeholder="$trans('main.profile.placeholders.phone_number')"
                  :required="false"
                  :form="generalForm"/>
              </div>
              <div class="col-span-6 sm:col-span-full">
                <Input
                  id="p_address"
                  type="text"
                  label="validation.attributes.p_address"
                  div-class="mt-1 relative rounded-md shadow-sm"
                  name="p_address"
                  v-model="generalForm.p_address"
                  :placeholder="$trans('main.profile.placeholders.address')"
                  :required="false"
                  :form="generalForm"/>
              </div>
              <div class="col-span-6 sm:col-span-3">
                <Select
                  id="p_country"
                  label="validation.attributes.p_country"
                  name="p_country"
                  v-model="generalForm.p_country"
                  option-key="id"
                  value="id" 
                  title="name"
                  :items="countries"
                  :required="false"
                  :errors="generalForm.errors"/>
              </div>
              <div class="col-span-6 sm:col-span-3">
                <Select
                  id="p_language"
                  label="validation.attributes.p_language"
                  name="p_language"
                  v-model="generalForm.p_language"
                  option-key="value"
                  value="value" 
                  title="name"
                  :items="languages"
                  :required="false"
                  :errors="generalForm.errors"/>
              </div>
            </div>
            <div class="mt-6 flex">
              <Button :text="$trans('main.profile.buttons.update')"
                :disabled="generalForm.invalid('p_first_name') || generalForm.invalid('p_last_name')"
                :loading="generalForm.processing"
                :cursorNotAllowed="!generalForm.isDirty"/>
            </div>
          </form>
        </div>
        <div class="grid lg:max-w-full max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-10 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 class="text-gray-900 dark:text-white text-base font-semibold leading-7">
              {{ $trans('main.profile.password.title') }}
            </h2>
            <p class="text-gray-600 dark:text-gray-400 mt-1 text-sm leading-6">
              {{ $trans('main.profile.password.description') }}
            </p>
          </div>
          <form class="md:col-span-2" @submit.prevent="submitPassword">
            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
              <div class="col-span-full">
                <Input
                  id="p_current_password"
                  type="password"
                  label="validation.attributes.p_current_password"
                  div-class="mt-1 relative rounded-md shadow-sm"
                  name="p_current_password"
                  v-model="passwordForm.p_current_password"
                  :placeholder="$trans('main.profile.placeholders.old_password')"
                  :required="true"
                  :form="passwordForm"/>
              </div>
              <div class="col-span-full">
                <Input
                  id="p_password"
                  type="password"
                  label="validation.attributes.p_password"
                  div-class="mt-1 relative rounded-md shadow-sm"
                  name="p_password"
                  v-model="passwordForm.p_password"
                  :placeholder="$trans('main.profile.placeholders.new_password')"
                  :required="true"
                  :form="passwordForm"/>
              </div>
              <div class="col-span-full">
                <Input
                  id="p_password_confirmation"
                  type="password"
                  label="validation.attributes.p_password_confirmation"
                  div-class="mt-1 relative rounded-md shadow-sm"
                  name="p_password_confirmation"
                  v-model="passwordForm.p_password_confirmation"
                  :placeholder="$trans('main.profile.placeholders.new_password_confirmation')"
                  :required="true"
                  :form="passwordForm"/>
              </div>
            </div>
            <div class="mt-6 flex">
              <Button :text="$trans('main.profile.buttons.update')"
                :loading="passwordForm.processing"
                :cursorNotAllowed="!passwordForm.isDirty"
                :disabled="passwordForm.invalid('p_current_password') ||
                          passwordForm.invalid('p_password') ||
                          passwordForm.invalid('p_password_confirmation')"/>
            </div>
          </form>
        </div>
        <div class="grid lg:max-w-full max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-10 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 class="text-gray-900 dark:text-white text-base font-semibold leading-7">
              {{ $trans('main.profile.email.title') }}
            </h2>
            <p class="text-gray-600 dark:text-gray-400 mt-1 text-sm leading-6">
              {{ $trans('main.profile.email.description') }}
            </p>
          </div>
          <form class="md:col-span-2" @submit.prevent="submitEmail">
            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
              <div class="col-span-full">
                <Input
                  id="p_current_email"
                  type="email"
                  label="validation.attributes.p_current_email"
                  div-class="mt-1 relative rounded-md shadow-sm"
                  name="p_current_email"
                  v-model="emailForm.p_current_email"
                  :placeholder="$trans('main.profile.placeholders.current_email')"
                  :required="true"
                  :form="emailForm"/>
              </div>
              <div class="col-span-full">
                <Input
                  id="p_email"
                  type="email"
                  label="validation.attributes.p_email"
                  div-class="mt-1 relative rounded-md shadow-sm"
                  name="p_email"
                  v-model="emailForm.p_email"
                  :placeholder="$trans('main.profile.placeholders.new_email')"
                  :required="true"
                  :form="emailForm"/>
              </div>
              <div class="col-span-full">
                <Input
                  id="p_email_confirmation"
                  type="email"
                  label="validation.attributes.p_email_confirmation"
                  div-class="mt-1 relative rounded-md shadow-sm"
                  name="p_email_confirmation"
                  v-model="emailForm.p_email_confirmation"
                  :placeholder="$trans('main.profile.placeholders.new_email_confirmation')"
                  :required="true"
                  :form="emailForm"/>
              </div>
            </div>
            <div class="mt-6 flex">
              <Button :text="$trans('main.profile.buttons.update')"
                :loading="emailForm.processing"
                :cursorNotAllowed="!emailForm.isDirty"
                :disabled="emailForm.invalid('p_current_email') ||
                          emailForm.invalid('p_email') ||
                          emailForm.invalid('p_email_confirmation')"/>
            </div>
          </form>
        </div>
        <div class="grid lg:max-w-full max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-10 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 class="text-gray-900 dark:text-white text-base font-semibold leading-7">
              {{ $trans('main.profile.sessions.title') }}
            </h2>
            <p class="text-gray-600 dark:text-gray-400 mt-1 text-sm leading-6">
              {{ $trans('main.profile.sessions.description') }}
            </p>
          </div>
          <form class="md:col-span-2" @submit.prevent="submitSession">
            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
              <div class="col-span-full">
                <Input
                  id="p_s_password"
                  type="password"
                  label="validation.attributes.p_s_password"
                  div-class="mt-1 relative rounded-md shadow-sm"
                  name="p_s_password"
                  v-model="sessionForm.p_s_password"
                  :required="true"
                  :form="sessionForm"/>
              </div>
            </div>
            <div class="mt-6 flex">
              <Button :text="$trans('main.profile.sessions.title')"
                :loading="sessionForm.processing"
                :cursorNotAllowed="!sessionForm.isDirty"
                :disabled="sessionForm.invalid('p_s_password')"/>
            </div>
          </form>
        </div>
        <!-- <div class="grid lg:max-w-full max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-10 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 class="text-base font-semibold leading-7 text-white">Delete account</h2>
            <p class="mt-1 text-sm leading-6 text-gray-400">No longer want to use our service? You can delete your account
              here. This action is not reversible. All information related to this account will be deleted permanently.</p>
          </div>
          <form class="flex items-center md:col-span-2">
            <button type="submit"
              class="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400">Yes,
              delete my account</button>
          </form>
        </div> -->
      </div>
    </template>
  </Layout>
</template>
<script lang="ts">
import { default as UserProfile } from '@/Types/Resources/User/Profile';
import { router, useForm as useFormVue } from '@inertiajs/vue3';
import { useForm } from 'laravel-precognition-vue-inertia';
import UserAvatar from '@js/Shared/UserAvatar.vue';
import Layout from '@js/Pages/Profile/Layout.vue';
import { Inertia } from '@inertiajs/inertia';
import Select from '@js/Shared/Select.vue';
import Button from '@js/Shared/Button.vue';
import { defineComponent, ref } from 'vue';
import Country from '@/Types/App/Country';
import Input from '@js/Shared/Input.vue';
import route from '@/ziggy';

type AvatarForm = {
  photo: File | null,
};
type GeneralForm = {
  p_first_name: string,
  p_last_name: string,
  p_nickname: string,
  p_phone_number: string | null,
  p_address: string | null,
  p_country: number | null,
  p_language: string,
};
type PasswordForm = {
  p_current_password: string,
  p_password: string,
  p_password_confirmation: string,
};
type EmailForm = {
  p_current_email: string,
  p_email: string,
  p_email_confirmation: string,
};
type SessionForm = {
  p_s_password: string,
};

export default defineComponent({
  components: {
    UserAvatar,
    Layout,
    Select,
    Button,
    Input,
  },
  props: {
    user: {
      type: Object as () => UserProfile,
      default: "",
    },
    countries: {
      type: Array as () => Array<Country>,
      default: () => [],
    },
    languages: {
      type: Array as () => string[],
      default: () => [],
    },
  },
  setup(props) {
    const fileInput = ref<HTMLInputElement | null>(null);
    const avatarForm = useFormVue<AvatarForm>({
      photo: null
    });
    const generalForm = useForm<GeneralForm>('put', route('profile.edit.update.general'), {
      p_first_name: props.user.first_name,
      p_last_name: props.user.last_name,
      p_nickname: props.user.nickname,
      p_phone_number: props.user.phone_number,
      p_address: props.user.address,
      p_country: props.user.country_id,
      p_language: props.user.language,
    });
    const passwordForm = useForm<PasswordForm>('put', route('profile.edit.update.password'), {
      p_current_password: "",
      p_password: "",
      p_password_confirmation: ""
    });
    const emailForm = useForm<EmailForm>('put', route('profile.edit.update.email'), {
      p_current_email: "",
      p_email: "",
      p_email_confirmation: ""
    });
    const sessionForm = useForm<SessionForm>('delete', route('profile.destroy.session'), {
      p_s_password: ""
    });
    
    function changeAvatar(event: Event)
    {
      // @ts-ignore
      avatarForm.photo = event.target.files.length ? event.target.files[0] : null;
      fileInput.value = null;
      
      router.post(route('profile.edit.update.avatar'), {
        _method: 'put',
        photo: avatarForm.photo,
      });
    }

    function submitGeneral(): void
    {
      if(generalForm.isDirty){
        let reloadPage = props.user.language != generalForm.p_language;
        
        generalForm.submit({
          preserveScroll: true,
          onSuccess: () => {
            if(reloadPage) Inertia.reload();

            router.reload();
          }
        });
      }
    }

    function submitPassword(): void
    {
      if(passwordForm.isDirty){
        passwordForm.submit({
          preserveScroll: true,
          onSuccess: () => {
            passwordForm.reset();
          }
        });
      }
    }

    function submitEmail(): void
    {
      if(emailForm.isDirty){
        emailForm.submit({
          preserveScroll: true,
          onSuccess: () => {
            emailForm.reset();
          }
        });
      }
    }

    function submitSession(): void
    {
      sessionForm.submit({
        preserveScroll: true,
        onSuccess: () => {
          sessionForm.reset();
        }
      });
    }

    function browse(): void
    {
      avatarForm.photo = null;
      fileInput.value?.click();
    }

    return {
      submitPassword,
      submitGeneral,
      submitSession,
      changeAvatar,
      passwordForm,
      sessionForm,
      generalForm,
      submitEmail,
      avatarForm,
      emailForm,
      fileInput,
      browse,
    };
  }
})
</script>