import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "z-50 fixed inset-0 flex items-end px-6 py-6 pointer-events-none sm:p-6 sm:items-start"
}
const _hoisted_2 = { class: "w-full flex flex-col items-center space-y-4 sm:items-end" }
const _hoisted_3 = {
  key: 1,
  class: "z-50 fixed inset-0 flex items-end px-6 py-6 pointer-events-none sm:p-6 sm:items-start"
}
const _hoisted_4 = { class: "w-full flex flex-col items-center space-y-4 sm:items-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notification = _resolveComponent("Notification")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_Header = _resolveComponent("Header")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_CommandPalette = _resolveComponent("CommandPalette")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.toastNotifications.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toastNotifications, (notification) => {
              return (_openBlock(), _createBlock(_component_Notification, {
                key: notification.id,
                notification: notification
              }, null, 8, ["notification"]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.toast)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.toast)
              ? (_openBlock(), _createBlock(_component_Toast, {
                  key: 0,
                  onHide: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toast = null)),
                  type: _ctx.toast.type,
                  title: _ctx.toast.title,
                  description: _ctx.toast.description
                }, null, 8, ["type", "title", "description"]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Header, {
      onChangeNotificationsDrawerState: _ctx.changeNotificationsDrawerState,
      "prop-notifications-drawer-is-open": _ctx.notificationsDrawerIsOpen,
      onUpdateNotifications: _ctx.handleUpdateNotifications,
      "prop-notifications-length": _ctx.notificationsLength,
      "prop-notifications": _ctx.notifications,
      "navigation-color": _ctx.navigationColor,
      "logo-color": _ctx.logoColor
    }, null, 8, ["onChangeNotificationsDrawerState", "prop-notifications-drawer-is-open", "onUpdateNotifications", "prop-notifications-length", "prop-notifications", "navigation-color", "logo-color"]),
    _createElementVNode("main", {
      class: _normalizeClass([[..._ctx.mainClasses], "flex flex-1 flex-col sm:pb-2 md:px-2 min-h-[calc(100vh-8rem)] sm:min-h-[calc(100vh-4.6rem)]"])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass([[..._ctx.divClasses], "grow md:rounded-lg md:shadow-sm md:ring-1"])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ], 2),
    _createVNode(_component_Footer),
    _createVNode(_component_CommandPalette, {
      isOpen: _ctx.cmdOpen,
      close: (value) => _ctx.cmdOpen = value
    }, null, 8, ["isOpen", "close"])
  ], 64))
}