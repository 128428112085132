<template>
  <label :for="id" class="block text-sm font-medium text-gray-700 dark:text-white">
    {{ $trans(label) }}
  </label>
  <div class="mt-1 relative rounded-md shadow-sm">
    <input :id="id" @input="timeoutValidate" :type="type" :disabled="disabled" :name="name" :autocomplete="autocomplete" :required="required" v-model="value" :class="{
      'border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700': !form.invalid(name),
      'border-red-300 dark:border-red-500 text-red-700 dark:text-red-500 focus:ring-red-500 focus:border-red-500 pr-10': form.invalid(name),
    }" class="bg-gray-50 sm:bg-transparent dark:bg-gray-850 dark:focus:ring-primary-500 dark:focus:border-primary-500 sm:backdrop-blur-sm sm:backdrop-filter block w-full focus:outline-none sm:text-sm rounded-md">
    <div v-if="form.invalid(name)" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 text-red-500">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
      </svg>
    </div>
  </div>
  <p v-if="withError && form.invalid(name)" class="mt-2 text-sm text-red-600 dark:text-red-500">
    {{ form.errors[name] }}
  </p>
</template>
<script lang="ts">
import { useForm } from 'laravel-precognition-vue-inertia';
import { defineComponent, ref, watch, PropType } from "vue";

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true,
    },
    autocomplete: {
      type: String as () => 'on' | 'off',
      required: false,
      default: 'off'
    },
    required: {
      type: Boolean,
      required: true,
    },
    modelValue: {
      type: String,
      required: true
    },
    withError: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    withPrecognition: {
      type: Boolean,
      default: true,
    },
    form: {
      type: Object as PropType<ReturnType<typeof useForm>>,
      required: true
    },
  },
  emits: ['update:modelValue', 'click'],
  setup(props, { emit }) {
    const value = ref(props.modelValue);
  
    watch(() => props.modelValue, (newValue) => {
      value.value = newValue;
    });
  
    watch(value, (newValue) => {
      emit('update:modelValue', newValue);
    });

    function timeoutValidate(): void
    {
      if(props.withPrecognition){
        setTimeout(() => {
          props.form.validate(props.name);
        }, 1500);
      }
    }

    return { timeoutValidate, value };
  }
});
</script>