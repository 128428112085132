<template>
  <span :class="{
    'bg-green-50 dark:bg-green-400/10 ring-green-500/10 dark:ring-green-400/20 text-green-600 dark:text-green-400': type === 'success',
    'bg-yellow-50 dark:bg-yellow-400/10 ring-yellow-500/10 dark:ring-yellow-400/20 text-yellow-600 dark:text-yellow-400': type === 'warning' || type === 'attempt',
    'bg-red-50 dark:bg-red-400/10 ring-red-500/10 dark:ring-red-400/20 text-red-600 dark:text-red-400': type === 'error',
    'bg-blue-50 dark:bg-blue-400/10 ring-blue-500/10 dark:ring-blue-400/20 text-blue-600 dark:text-blue-400': type === 'info',
    'inline-flex items-center rounded-md px-2 py-1 text-sm font-medium ring-1 ring-inset': true
  }">
    <slot />
    {{ text }}
  </span>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    type: {
      type: String as () => 'success' | 'warning' | 'attempt' | 'error' | 'info',
      default: 'success',
    },
    text: {
      type: String,
      required: true,
    },
  }
})
</script>