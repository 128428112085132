<template>
  <label :for="id" class="block text-sm font-medium text-gray-700 dark:text-white">
    {{ $trans(label) }}
  </label>
  <div :class="divClass">
    <select :id="id" :name="name" v-model="model" :required="required" :class="{
        'border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700': !hasError(),
        'border-red-300 dark:border-red-500 text-red-700 dark:text-red-500 focus:ring-red-500 focus:border-red-500': hasError(),
      }" class="bg-gray-50 dark:bg-gray-850 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full pr-10 focus:outline-none sm:text-sm rounded-md">
      <option v-for="(item, index) in items"
                :key="optionKey != null ? (item?.[optionKey] ? item?.[optionKey] : index) : index"
                :value="value === 'item' ? item : value ? item?.[value] : index">
        {{ transKey ? $trans(`${transKey}.${item}`) : title ? item?.[title] : item }}
      </option>
    </select>
  </div>
  <p v-if="withError && hasError()" class="mt-2 text-sm text-red-600 dark:text-red-500">
    {{ getError() }}
  </p>
</template>
<script lang="ts">
import { ExclamationCircleIcon } from "@heroicons/vue/24/outline";
import { PropType, defineComponent, ref, watch } from "vue";

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, null] as PropType<string | null>,
      required: false
    },
    title: {
      type: [String, null] as PropType<string | null>,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: true,
    },
    modelValue: {
      type: [String, Number, Boolean, null] as PropType<string | number | boolean | null>,
      required: true
    },
    optionKey: {
      type: [String, null] as PropType<string | null>,
      required: false
    },
    transKey: {
      type: [String, undefined] as PropType<string | undefined>,
      required: false
    },
    items: {
      type: [Array, Object] as PropType<Array<Object | string>>,
      required: false,
      default: () => []
    },
    withError: {
      type: Boolean,
      required: false,
      default: true
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({})
    },
    divClass: {
      type: String,
      required: false,
      default: 'mt-1 relative rounded-md shadow-sm'
    }
  },
  emits: ['update:modelValue', 'click'],
  components: {
    ExclamationCircleIcon
  },
  setup(props, { emit }) {
    const model = ref(props.modelValue);
  
    watch(() => props.modelValue, (newValue) => {
      model.value = newValue;
    });
  
    watch(model, (newValue) => {
      emit('update:modelValue', newValue);
    });

    function hasError(): boolean
    {
      return props.name in props.errors;
    }

    function getError(): string
    {
      return props.errors.hasOwnProperty(props.name) && props.errors[props.name] ? props.errors[props.name] : '';
    }

    return {
      hasError,
      getError,
      model,
    };
  }
});
</script>