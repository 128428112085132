<template>
  <TransitionRoot :show="open" as="template" @after-leave="query = ''">
    <Dialog :open="open" as="div" class="fixed inset-0 z-50 overflow-y-auto p-4 sm:p-6 md:p-20" @close="setOpen(false)">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <DialogOverlay class="bg-gray-900/50 dark:bg-gray-900/90 fixed inset-0 transition-opacity" />
      </TransitionChild>
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
        <Combobox as="div" :displayValue="(item) => item.value" class="mx-auto max-w-2xl transform divide-y divide-gray-300 dark:divide-gray-750 divide-opacity-10 overflow-hidden rounded-xl bg-white dark:bg-gray-850 bg-opacity-80 shadow-2xl ring-1 ring-black ring-opacity-5 backdrop-blur backdrop-filter transition-all">
          <div class="flex content-center flex-row items-center justify-between py-2 px-4">
            <CommandLineIcon class="pointer-events-none top-4 left-4 h-5 w-5 text-gray-900 dark:text-gray-500 text-opacity-40"/>
            <ComboboxInput
              :placeholder="$trans('cmd.description')" 
              @change="query = $event.target.value"
              @keydown="handleKeyDown" 
              class="w-full border-0 bg-transparent pr-4 text-gray-900 dark:text-white placeholder-gray-500 focus:ring-0 sm:text-sm"/>
          </div>
        </Combobox>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>
<script lang="ts">
import { TransitionChild, ComboboxOptions, TransitionRoot, ComboboxOption, ComboboxInput, DialogOverlay, Combobox, Dialog } from '@headlessui/vue';
import { default as HandleUser } from '@/Types/Resources/User/Handle';
import { defineComponent, toRef, onMounted, onUnmounted } from "vue";
import { CommandLineIcon } from '@heroicons/vue/24/outline';
import { Link, router } from '@inertiajs/vue3';
import { usePage } from '@inertiajs/vue3';
import Icon from '@js/Shared/Icon.vue';
import { computed, ref } from 'vue';
import Auth from "@/Types/App/Auth";
import { isMac } from '@/mixins';
import route from '@/ziggy';

export default defineComponent({
    props: {
      isOpen: {
        type: Boolean,
        default: () => false
      },    
      close: {
        type: Function,
        default: () => {},
      }
    },
    components: {
      CommandLineIcon,
      TransitionChild,
      ComboboxOptions,
      ComboboxOption,
      TransitionRoot,
      DialogOverlay,
      ComboboxInput,
      Combobox,
      Dialog,
      Link,
      Icon,
    },
    setup(props) {
      const auth = computed(() => usePage().props?.auth as Auth);
      const user = computed(() => auth.value.user as HandleUser);
      const open = toRef(props, 'isOpen');
      const query = ref<String>('');
      const handleKeyUp = (event: KeyboardEvent) => {
        const isCmdOrCtrl = isMac() ? event.metaKey : event.ctrlKey;

        if(isCmdOrCtrl && event.code === 'KeyK'){
          event.preventDefault();
          setOpen(!props.isOpen);
        }
      };

      function setOpen(value: Boolean): void
      {
        props.close(value);
      }

      function handleKeyDown(event: KeyboardEvent): void
      {
        if(event.key === 'Enter' && query.value.length > 0){
          router.get(route('command.execute', undefined, true), {
            value: String(query.value)
          });
        }
      }

      onMounted(function () {
        if(user.value.role.value !== 'user'){
          window.addEventListener('keydown', handleKeyUp);
        }
      });
      onUnmounted(function () {
        if(user.value.role.value !== 'user'){
          window.removeEventListener('keydown', handleKeyUp);
        }
      });
  
      return {
        handleKeyDown,
        setOpen,
        query,
        route,
        open,
      }
    },
});
</script>