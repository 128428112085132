import Editor from '@/Pages/Boards/Card/Components/Editor/Editor.vue';
import type { updateLightCardFunction } from '@/Pages/Boards/Types';
import { default as LightCard } from '@/Types/Resources/Card/Light';
import { PayloadResponse } from '@/Types/API/PayloadResponse';
import { useForm } from 'laravel-precognition-vue-inertia';
import { getApiToken, sendRequest } from '@/mixins';
import { Ref, ref, nextTick } from 'vue';

import route from '@/ziggy';

type UpdateDescription = {
  _token: string;
  b_c_c_description: string;
};

export function useCardDescription(card: Ref<LightCard|null>, descriptionEditor: Ref<typeof Editor|null>, updateLightCard: updateLightCardFunction)
{
  const descriptionIsOpenForm = ref<boolean>(false);
  const descriptionForm = useForm('post', () => route('api.v1.boards.cards.update', {
    card: card.value?.id
  }), {
    _token: getApiToken(),
    b_c_c_description: ''
  } as UpdateDescription);

  function openDescriptionForm(): void
  {
    descriptionForm.reset();
    descriptionForm.clearErrors();

    descriptionForm.b_c_c_description = card.value?.description ?? '';
    descriptionIsOpenForm.value = true;

    nextTick(() => {
      descriptionEditor.value?.focusEditor();
    });
  }

  function closeDescriptionForm(): void
  {
    descriptionIsOpenForm.value = false;
    
    descriptionForm.reset();
    descriptionForm.clearErrors();
  }

  function resetDescriptionForm(): void
  {
    descriptionForm.reset();
    descriptionForm.clearErrors();
    
    descriptionIsOpenForm.value = false;
    descriptionForm.b_c_c_description = card.value?.description ?? '';
  }

  function submitDescription(): void
  {
    if(card.value?.description !== descriptionForm.b_c_c_description){
      sendRequest({
        name: 'api.v1.boards.cards.update',
        params: { card: card.value?.id },
        data: {
          b_c_c_description: descriptionForm.b_c_c_description,
        },
      }, (data: PayloadResponse<LightCard>) => {
        updateLightCard(data.payload);
        closeDescriptionForm();
      }, (errors) => {
        descriptionForm.setErrors(errors);
      });
    }else{
      closeDescriptionForm();
    }
  }

  return {
    descriptionIsOpenForm,
    closeDescriptionForm,
    resetDescriptionForm,
    openDescriptionForm,
    submitDescription,
    descriptionForm,
  };
}