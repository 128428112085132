import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "type", "name", "autocomplete", "required", "placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
}
const _hoisted_4 = {
  key: 0,
  class: "mt-2 text-sm text-red-600 dark:text-red-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExclamationCircleIcon = _resolveComponent("ExclamationCircleIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: "block text-sm font-medium text-gray-700 dark:text-white"
    }, _toDisplayString(_ctx.$trans(_ctx.label)), 9, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.divClass)
    }, [
      _withDirectives(_createElementVNode("input", {
        id: _ctx.id,
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.form.validate(_ctx.name))),
        type: _ctx.type,
        name: _ctx.name,
        autocomplete: _ctx.autocomplete,
        required: _ctx.required,
        placeholder: _ctx.placeholder,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
        class: _normalizeClass(["bg-gray-50 dark:bg-gray-850 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full focus:outline-none sm:text-sm rounded-md", {
        'border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700': !_ctx.form.invalid(_ctx.name),
        'border-red-300 dark:border-red-500 text-red-700 dark:text-red-500 focus:ring-red-500 focus:border-red-500 pr-10': _ctx.form.invalid(_ctx.name),
      }])
      }, null, 42, _hoisted_2), [
        [_vModelDynamic, _ctx.value]
      ]),
      (_ctx.form.invalid(_ctx.name))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_ExclamationCircleIcon, { class: "h-5 w-5 text-red-500" })
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.withError && _ctx.form.invalid(_ctx.name))
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.form.errors[_ctx.name]), 1))
      : _createCommentVNode("", true)
  ], 64))
}