import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "border-gray-200 dark:border-gray-800 flex overflow-x-auto border-b py-4" }
const _hoisted_2 = {
  role: "list",
  class: "text-gray-500 dark:text-gray-400 flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 sm:px-6 lg:px-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Head = _resolveComponent("Head")!
  const _component_Link = _resolveComponent("Link")!
  const _component_ParentLayout = _resolveComponent("ParentLayout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Head, {
      title: _ctx.$trans('main.profile.titles.main')
    }, null, 8, ["title"]),
    _createVNode(_component_ParentLayout, null, {
      default: _withCtx(() => [
        _createElementVNode("main", null, [
          _createElementVNode("header", null, [
            _createElementVNode("nav", _hoisted_1, [
              _createElementVNode("ul", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (item) => {
                  return (_openBlock(), _createElementBlock("li", null, [
                    _createVNode(_component_Link, {
                      href: item.href,
                      class: _normalizeClass({'text-primary-600': item.current})
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$trans(item.key)), 1)
                      ]),
                      _: 2
                    }, 1032, ["href", "class"])
                  ]))
                }), 256))
              ])
            ]),
            _renderSlot(_ctx.$slots, "header")
          ]),
          _renderSlot(_ctx.$slots, "content")
        ])
      ]),
      _: 3
    })
  ], 64))
}