<template>
  <Head :title="$trans('table.users.title')"/>
  <Layout :body-classes="['bg-white', 'dark:bg-gray-950', 'h-full']"
          :main-classes="['bg-white', 'dark:bg-gray-950']">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-base my-4 font-semibold leading-6 text-gray-900 dark:text-white">
            {{ $trans('table.users.title') }}
          </h1>
          <div class="sm:flex">
            <div class="items-center mb-3 sm:flex sm:divide-x sm:divide-gray-100 sm:mb-0 dark:divide-gray-700">
              <div class="lg:pr-3">
                <label for="users-search" class="sr-only">
                  {{ `${$trans('main.shared.search')}...` }}
                </label>
                <div class="relative lg:w-64 xl:w-96">
                  <input id="users-search" type="text" :placeholder="`${$trans('main.shared.search')}...`" v-model="form.search" class="bg-gray-50 dark:bg-gray-850 dark:focus:ring-primary-500 dark:focus:border-primary-500 border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700 block w-full pr-10 focus:outline-none sm:text-sm rounded-md">
                </div>
              </div>
            </div>
            <div class="flex items-center ml-auto space-x-2 sm:space-x-3">
              <button type="button" @click="setCreateModalIsOpen(true)" class="bg-gray-900 dark:bg-primary-800  text-white dark:text-gray-100 hover:bg-gray-700 dark:hover:bg-primary-750 focus:ring-gray-700 dark:focus:ring-primary-950 focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent inline-flex items-center justify-center w-1/2 px-3 py-2 text-sm font-medium text-center rounded-lg sm:w-auto">
                <Icon name="plus" mainClass="w-6 h-6 mr-2 -ml-1"/>
                {{$trans('table.users.buttons.create_user')}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8 flow-root">
        <div class="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 overflow-x-scroll">
          <div class="inline-block min-w-full py-2 align-middle">
            <table class="min-w-full border-separate border-spacing-0">
              <thead>
                <tr>
                  <th v-html="$trans('table.users.head.id')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 hidden border-b py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:table-cell sm:pl-6 lg:pl-8"></th>
                  <th v-html="$trans('table.users.head.html.name_email')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b px-3 py-3.5 text-left text-sm font-semibold lg:table-cell"></th>
                  <th v-html="$trans('table.users.head.html.country_language')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 hidden border-b px-3 py-3.5 text-left text-sm font-semibold lg:table-cell"></th>
                  <th v-html="$trans('table.users.head.html.role')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 hidden border-b px-3 py-3.5 text-left text-sm font-semibold lg:table-cell"></th>
                  <th v-html="$trans('table.users.head.html.last_activity')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 hidden border-b px-3 py-3.5 text-left text-sm font-semibold lg:table-cell"></th>
                  <th v-html="$trans('table.users.head.html.created_at')" scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 hidden border-b px-3 py-3.5 text-left text-sm font-semibold lg:table-cell"></th>
                  <th scope="col" class="bg-white dark:bg-transparent text-gray-900 dark:text-gray-200 border-gray-300 dark:border-gray-850 border-b py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(user, personIdx) in users?.data" :key="user.email">
                  <td :class="[personIdx !== users.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 text-gray-500 dark:text-gray-400 whitespace-nowrap hidden sm:table-cell py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 lg:pl-8']">
                    {{ user.id }}
                  </td>
                  <td :class="[personIdx !== users.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 whitespace-nowrap px-3 py-4 text-sm text-gray-500']">
                    <!-- <img v-if="user.photo_path" class="w-10 h-10 object-cover rounded-full" alt="Atlant" :src="user.photo_path"/>
                    <img v-if="!user.photo_path" alt="Atlant" class="w-10 h-10 rounded-full dark:filter-white" src="/dist/images/user-placeholder.svg"> -->
                    <div class="text-base font-semibold text-gray-900 dark:text-white">
                      {{ truncateText(user.name, 30) }}
                    </div>
                    <div class="text-sm font-normal text-gray-500 dark:text-gray-400">
                      {{ truncateText(user.email, 30) }}
                    </div>
                  </td>
                  <td :class="[personIdx !== users.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell']">
                    <div class="text-base font-semibold text-gray-900 dark:text-white">
                      {{ truncateText(user.country_name, 20) }}
                    </div>
                    <div class="text-sm font-normal text-gray-500 dark:text-gray-400">
                      {{ user.language ? $trans(`main.shared.languages.${user.language}`) : $trans('main.shared.undefined_up') }}
                    </div>
                  </td>
                  <td :class="{'border-b border-gray-200 dark:border-gray-850': personIdx !== users.data.length - 1, 
                              'bg-white dark:bg-gray-950 text-gray-500 whitespace-nowrap hidden px-3 py-4 text-sm lg:table-cell': true,
                              'text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-pink-500 to-teal-400': user.role?.value == 'super_admin',
                              'text-primary-700 dark:text-primary-500': user.role?.value == 'admin',
                              'text-teal-700 dark:text-teal-500': user.role?.value == 'moderator',
                              'text-gray-700 dark:text-gray-500': user.role?.value == 'user' }">
                    {{ user.role?.name }}
                  </td>
                  <td :class="[personIdx !== users.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell']">
                    {{ user.last_activity ? user.last_activity : $trans('main.shared.undefined_up') }}
                  </td>
                  <td :class="[personIdx !== users.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell']">
                    {{ user.created_at }}
                  </td>
                  <td :class="[personIdx !== users.data.length - 1 ? 'border-b border-gray-200 dark:border-gray-850' : '', 'bg-white dark:bg-gray-950 relative whitespace-nowrap py-4 px-2 text-right text-sm font-medium sm:pr-8 lg:pr-8']">
                    <!-- Edit -->
                    <span @click="setEditDrawerIsOpen(true, user)" class="text-primary-600 dark:text-primary-800 hover:text-primary-900 dark:hover:text-primary-700 cursor-pointer">
                      {{$trans('table.shared.edit')}}
                      <span class="sr-only">{{$trans('table.users.buttons.edit_user')}}</span>
                      <br class="sm:hidden"/>
                    </span>
                    <!-- Block @ts-ignore -->
                    <Link v-if="!user.block" as="span" :href="route('users.block', {'user': user?.id})" :method="('put' as Method)" preserve-scroll class="text-yellow-600 dark:text-yellow-800 hover:text-yellow-900 dark:hover:text-yellow-700 cursor-pointer">
                      {{$trans('table.shared.block')}}
                      <span class="sr-only">{{$trans('table.shared.block')}}</span>
                      <br class="sm:hidden"/>
                    </Link>
                    <!-- Unblock @ts-ignore -->
                    <Link v-if="user.block" as="span" :href="route('users.unblock', {'user': user?.id})" :method="('put' as Method)" preserve-scroll class="text-yellow-600 dark:text-yellow-800 hover:text-yellow-900 dark:hover:text-yellow-700 cursor-pointer">
                      {{$trans('table.shared.unblock')}}
                      <span class="sr-only">{{$trans('table.shared.unblock')}}</span>
                      <br class="sm:hidden"/>
                    </Link>
                    <!-- Delete @ts-ignore -->
                    <span v-if="!user.deleted_at" @click="setDeleteModalIsOpen(true, user?.id)" class="text-red-600 dark:text-red-800 hover:text-red-900 dark:hover:text-red-700 cursor-pointer">
                      {{$trans('table.shared.delete')}}
                      <span class="sr-only">{{$trans('table.shared.delete')}}</span>
                      <br class="sm:hidden"/>
                    </span>
                    <!-- Restore @ts-ignore -->
                    <Link v-show="user.deleted_at" as="span" :href="route('users.restore', {'user': user?.id})" :method="('put' as Method)" preserve-scroll class="text-yellow-600 dark:text-yellow-800 hover:text-yellow-900 dark:hover:text-yellow-700 cursor-pointer">
                      {{$trans('table.shared.restore')}}
                      <span class="sr-only">{{$trans('table.shared.restore')}}</span>
                      <br class="sm:hidden"/>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="!users?.data?.length" class="flex items-center justify-center my-48">
              <h2 class="text-gray-400 dark:text-gray-300 box-border block text-2xl leading-5 relative text-center">
                {{$trans('table.users.placeholder')}}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Pagination v-if="users?.data?.length" :data="users"/>
  </Layout>
  <Create :drawerIsOpen="(createDrawerIsOpen as boolean)" :close="(value: boolean) => createDrawerIsOpen = value" :countries="countries" :languages="(languages as Array<string>)" :roles="roles" />
  <Edit :isOpen="(editDrawerIsOpen as boolean)" :onClose="(value: boolean) => editDrawerIsOpen = value" :user="editUser" :countries="countries" :languages="languages" :roles="roles" />
  <Attempt :open="deleteModalIsOpen" :close="(value: boolean) => deleteModalIsOpen = value" routeName="users.destroy" :routeArgument="{ id: deleteUserId }" :title="$trans('main.attempts.delete_user.title')" :description="$trans('main.attempts.delete_user.description')" :successButtonText="$trans('main.attempts.buttons.success_button')" :cancelButtonText="$trans('main.attempts.buttons.cancel_button')" />
</template>
<script lang="ts">
import { default as UserList } from '@/Types/Resources/User/List';
import CountryLight from '@/Types/Resources/World/CountryLight';
import { Head, Link, usePage, router } from '@inertiajs/vue3';
import { defineComponent, reactive, watch, ref } from 'vue';
import Breadcrumb from '@js/Shared/Breadcrumb.vue';
import Pagination from '@js/Shared/Pagination.vue';
import Paginator from '@/Types/App/Paginator';
import Attempt from '@js/Shared/Attempt.vue';
import Layout from '@js/Shared/Layout.vue';
import { Method } from '@inertiajs/core';
import Role from '@/Types/App/User/Role';
import { truncateText } from '@/mixins';
import Icon from '@js/Shared/Icon.vue';
import Auth from '@/Types/App/Auth';
import Create from './Create.vue';
import Edit from './Edit.vue';
import route from '@/ziggy';

export default defineComponent({
  props: {
    users: {
      type: Object as () => Paginator<UserList>,
      required: true,
    },
    filters: {
      type: Object,
      default: () => ({
        search: '',
      }),
    },
    countries: {
      type: Array<CountryLight>,
    },
    roles: {
      type: Object as () => Array<Role>,
    },
    languages: Array,
  },
  components: {
    Pagination,
    Breadcrumb,
    Attempt,
    Layout,
    Create,
    Edit,
    Icon,
    Link,
    Head,
  },
  setup(props) {
    const auth: Auth = usePage().props?.auth as Auth
    const createDrawerIsOpen = ref<boolean>(false)
    const editDrawerIsOpen = ref<boolean>(false)
    const editUser = ref<UserList>({} as UserList)
    const deleteModalIsOpen = ref<boolean>(false)
    const deleteUserId = ref<Number>(0)
    const form = reactive({
      search: props.filters.search,
    })

    watch(form, () => {
      router.get(route('users'), form, {
        preserveState: true,
        preserveScroll: true,
      })
    })

    function setCreateModalIsOpen(value: boolean): void
    {
      createDrawerIsOpen.value = value
    }

    function setEditDrawerIsOpen(value: boolean, user: UserList): void
    {
      editUser.value = user
      editDrawerIsOpen.value = value
    }

    function setDeleteModalIsOpen(value: boolean, userId: Number): void
    {
      deleteUserId.value = userId
      deleteModalIsOpen.value = value
    }

    return {
      setDeleteModalIsOpen,
      setCreateModalIsOpen,
      setEditDrawerIsOpen,
      createDrawerIsOpen,
      deleteModalIsOpen,
      editDrawerIsOpen,
      deleteUserId,
      truncateText,
      editUser,
      route,
      auth,
      form,
    }
  },
})
</script>
