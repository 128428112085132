<template>
  <Head :title="`Atlant - ${$trans('changelog.html_title')}`">
    <meta name="theme-color" :content="dark ? '#0E1521' : '#F9FAFB'"/>
    <link rel="icon" type="image/svg+xml" :href="dark ? '/favicon-dark.svg' : '/favicon.svg'"/>
  </Head>
  <div class="flex min-h-full flex-col">
    <div class="relative flex-none overflow-hidden px-6 lg:pointer-events-none lg:fixed lg:inset-0 lg:z-40 lg:flex lg:px-0">
      <div class="absolute inset-0 -z-10 overflow-hidden bg-zinc-950 lg:right-[calc(max(2rem,50%-38rem)+40rem)] lg:min-w-[32rem]">
          <svg class="absolute -bottom-48 left-[-40%] h-[80rem] w-[180%] lg:-right-40 lg:bottom-auto lg:left-auto lg:top-[-40%] lg:h-[180%] lg:w-[80rem]" aria-hidden="true">
            <defs>
              <radialGradient id=":S1:-desktop" cx="100%">
                <stop offset="0%" stop-color="rgba(56, 189, 248, 0.3)"></stop>
                <stop offset="53.95%" stop-color="rgba(0, 71, 255, 0.09)"></stop>
                <stop offset="100%" stop-color="rgba(10, 14, 23, 0)"></stop>
              </radialGradient>
              <radialGradient id=":S1:-mobile" cy="100%">
                <stop offset="0%" stop-color="rgba(56, 189, 248, 0.3)"></stop>
                <stop offset="53.95%" stop-color="rgba(0, 71, 255, 0.09)"></stop>
                <stop offset="100%" stop-color="rgba(10, 14, 23, 0)"></stop>
              </radialGradient>
            </defs>
            <rect width="100%" height="100%" fill="url(#:S1:-desktop)" class="hidden lg:block"></rect>
            <rect width="100%" height="100%" fill="url(#:S1:-mobile)" class="lg:hidden"></rect>
          </svg>
          <div class="absolute inset-x-0 bottom-0 right-0 h-px bg-white mix-blend-overlay lg:left-auto lg:top-0 lg:h-auto lg:w-px"></div>
      </div>
      <div class="relative flex w-full lg:pointer-events-auto lg:mr-[calc(max(2rem,50%-38rem)+40rem)] lg:min-w-[32rem] lg:overflow-y-auto lg:overflow-x-hidden lg:pl-[max(4rem,calc(50%-38rem))]">
          <div class="mx-auto max-w-lg lg:mx-0 lg:flex lg:w-96 lg:max-w-none lg:flex-col lg:before:flex-1 lg:before:pt-6">
            <div class="pb-16 pt-20 sm:pb-20 sm:pt-32 lg:py-20">
              <div class="relative">
                <StarField/>
                <div>
                  <Link as="div" :href="route('login')">
                    <img src="/dist/images/logo-text.svg" class="filter-white inline-block h-8 w-auto" alt="Atlant Logo"/>
                  </Link>
                </div>
                <h1 class="mt-10 font-display text-4xl/tight font-light text-white">
                  {{ $trans('changelog.title') }}
                </h1>
                <p class="mt-4 mb-10 text-sm/6 text-gray-300">
                  {{ $trans('changelog.description') }}
                </p>
                <!-- <form class="relative isolate mt-8 flex items-center pr-1">
                    <label for=":S3:" class="sr-only">Email address</label><input required="" type="email" autocomplete="email" id=":S3:" placeholder="Email address" class="peer w-0 flex-auto bg-transparent px-4 py-2.5 text-base text-white placeholder:text-gray-500 focus:outline-none sm:text-[0.8125rem]/6" name="email">
                    <button class="group relative isolate flex-none rounded-md py-1.5 text-[0.8125rem]/6 font-semibold text-white pl-2.5 pr-[calc(9/16*1rem)]" type="submit">
                      <span class="absolute inset-0 rounded-md bg-gradient-to-b from-white/80 to-white opacity-10 transition-opacity group-hover:opacity-15"></span>
                      <span class="absolute inset-0 rounded-md opacity-7.5 shadow-[inset_0_1px_1px_white] transition-opacity group-hover:opacity-10"></span>
                      Get updates
                      <span aria-hidden="true">→</span>
                    </button>
                    <div class="absolute inset-0 -z-10 rounded-lg transition peer-focus:ring-4 peer-focus:ring-sky-300/15"></div>
                    <div class="absolute inset-0 -z-10 rounded-lg bg-white/2.5 ring-1 ring-white/15 transition peer-focus:ring-sky-300"></div>
                </form>
                <div class="mt-8 flex flex-wrap justify-center gap-x-1 gap-y-3 sm:gap-x-2 lg:justify-start">
                  <a class="flex-none group relative isolate flex items-center rounded-lg px-2 py-0.5 text-[0.8125rem]/6 font-medium text-white/30 transition-colors hover:text-sky-300 gap-x-3" href="#">
                    <span class="absolute inset-0 -z-10 scale-75 rounded-lg bg-white/5 opacity-0 transition group-hover:scale-100 group-hover:opacity-100"></span>
                    <svg viewBox="0 0 16 16" aria-hidden="true" fill="currentColor" class="h-4 w-4 flex-none">
                        <path d="M7 3.41a1 1 0 0 0-.668-.943L2.275 1.039a.987.987 0 0 0-.877.166c-.25.192-.398.493-.398.812V12.2c0 .454.296.853.725.977l3.948 1.365A1 1 0 0 0 7 13.596V3.41ZM9 13.596a1 1 0 0 0 1.327.946l3.948-1.365c.429-.124.725-.523.725-.977V2.017c0-.32-.147-.62-.398-.812a.987.987 0 0 0-.877-.166L9.668 2.467A1 1 0 0 0 9 3.41v10.186Z"></path>
                    </svg>
                    <span class="self-baseline text-white">Documentation</span>
                  </a>
                  <a class="flex-none group relative isolate flex items-center rounded-lg px-2 py-0.5 text-[0.8125rem]/6 font-medium text-white/30 transition-colors hover:text-sky-300 gap-x-3" href="#">
                    <span class="absolute inset-0 -z-10 scale-75 rounded-lg bg-white/5 opacity-0 transition group-hover:scale-100 group-hover:opacity-100"></span>
                    <svg viewBox="0 0 16 16" aria-hidden="true" fill="currentColor" class="h-4 w-4 flex-none">
                        <path d="M8 .198a8 8 0 0 0-8 8 7.999 7.999 0 0 0 5.47 7.59c.4.076.547-.172.547-.384 0-.19-.007-.694-.01-1.36-2.226.482-2.695-1.074-2.695-1.074-.364-.923-.89-1.17-.89-1.17-.725-.496.056-.486.056-.486.803.056 1.225.824 1.225.824.714 1.224 1.873.87 2.33.666.072-.518.278-.87.507-1.07-1.777-.2-3.644-.888-3.644-3.954 0-.873.31-1.586.823-2.146-.09-.202-.36-1.016.07-2.118 0 0 .67-.214 2.2.82a7.67 7.67 0 0 1 2-.27 7.67 7.67 0 0 1 2 .27c1.52-1.034 2.19-.82 2.19-.82.43 1.102.16 1.916.08 2.118.51.56.82 1.273.82 2.146 0 3.074-1.87 3.75-3.65 3.947.28.24.54.73.54 1.48 0 1.07-.01 1.93-.01 2.19 0 .21.14.46.55.38A7.972 7.972 0 0 0 16 8.199a8 8 0 0 0-8-8Z"></path>
                    </svg>
                    <span class="self-baseline text-white">GitHub</span>
                  </a>
                  <a class="flex-none group relative isolate flex items-center rounded-lg px-2 py-0.5 text-[0.8125rem]/6 font-medium text-white/30 transition-colors hover:text-sky-300 gap-x-3" href="/feed.xml">
                    <span class="absolute inset-0 -z-10 scale-75 rounded-lg bg-white/5 opacity-0 transition group-hover:scale-100 group-hover:opacity-100"></span>
                    <svg viewBox="0 0 16 16" aria-hidden="true" fill="currentColor" class="h-4 w-4 flex-none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 3a.5.5 0 0 1 .5-.5h.5c5.523 0 10 4.477 10 10v.5a.5.5 0 0 1-.5.5h-.5a.5.5 0 0 1-.5-.5v-.5A8.5 8.5 0 0 0 3.5 4H3a.5.5 0 0 1-.5-.5V3Zm0 4.5A.5.5 0 0 1 3 7h.5A5.5 5.5 0 0 1 9 12.5v.5a.5.5 0 0 1-.5.5H8a.5.5 0 0 1-.5-.5v-.5a4 4 0 0 0-4-4H3a.5.5 0 0 1-.5-.5v-.5Zm0 5a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"></path>
                    </svg>
                    <span class="self-baseline text-white">RSS</span>
                  </a>
                </div> -->
                <!-- <button class="group relative isolate flex-none rounded-md py-1.5 text-[0.8125rem]/6 font-semibold text-white pl-2.5 pr-[calc(9/16*1rem)]" type="submit">
                  <span class="absolute inset-0 rounded-md bg-gradient-to-b from-white/80 to-white opacity-10 transition-opacity group-hover:opacity-15"></span>
                  <span class="absolute inset-0 rounded-md opacity-7.5 shadow-[inset_0_1px_1px_white] transition-opacity group-hover:opacity-10"></span>
                  Get updates
                  <span aria-hidden="true">→</span>
                </button> -->
                <Link as="button" :href="route('login')" class="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20">
                  {{ $trans('changelog.buttons.go') }}
                </Link>
              </div>
            </div>
            <div class="flex flex-1 items-end justify-center pb-4 lg:justify-start lg:pb-6">
              <p class="flex items-baseline gap-x-2 text-[0.8125rem]/6 text-gray-500">
                {{ $trans('changelog.created_by') }}
                <a href="https://pantheon.business" target="_blank" class="relative cursor-pointer rounded-md font-medium text-pink-600 dark:text-primary-600 hover:text-pink-500 dark:hover:text-primary-500">
                  Pantheon Software
                </a>
              </p>
            </div>
          </div>
      </div>
    </div>
    <button @click="darkMode(!dark)" type="button" class="group absolute right-4 top-4 z-50 -m-2.5 p-2.5">
      <span class="sr-only">Switch to light theme</span>
      <svg v-if="dark"viewBox="0 0 24 24" aria-hidden="true" class="h-6 w-6 fill-white opacity-50 transition-opacity group-hover:opacity-100 lg:fill-gray-900 lg:dark:fill-white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm-5-8a5 5 0 0 0 5 5V7a5 5 0 0 0-5 5Z"></path>
      </svg>
      <svg v-else viewBox="0 0 24 24" aria-hidden="true" class="h-6 w-6 fill-white opacity-50 transition-opacity group-hover:opacity-100 lg:fill-gray-900 lg:dark:fill-white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm-5-8a5 5 0 0 0 5 5V7a5 5 0 0 0-5 5Z"></path>
      </svg>
    </button>
    <div class="relative flex-auto">
      <div class="pointer-events-none absolute inset-0 z-50 overflow-hidden lg:right-[calc(max(2rem,50%-38rem)+40rem)] lg:min-w-[32rem] lg:overflow-visible">
        <svg class="absolute left-[max(0px,calc(50%-18.125rem))] top-0 h-full w-1.5 lg:left-full lg:ml-1 xl:left-auto xl:right-1 xl:ml-0" aria-hidden="true">
          <defs>
            <pattern id=":S4:" width="6" height="8" patternUnits="userSpaceOnUse">
              <path d="M0 0H6M0 8H6" class="stroke-sky-900/10 xl:stroke-white/10 dark:stroke-white/10" fill="none"></path>
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#:S4:)"></rect>
        </svg>
      </div>
      <slot/>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, onUnmounted, watch, reactive, ref } from 'vue';
import { Head, Link, usePage } from '@inertiajs/vue3';
import ToastType from '@/Types/App/Feedback/Toast';
import StarField from './StarField.vue';
import Toast from '@/Shared/Toast.vue';
import Logo from '@/Shared/Logo.vue';
import route from '@/ziggy';

export default defineComponent({
  components: {
    StarField,
    Toast,
    Logo,
    Link,
    Head,
  },
  setup() {
    const root: HTMLElement = reactive(document.documentElement as HTMLElement)
    const body: HTMLBodyElement = reactive(document.body as HTMLBodyElement)
    const toasts: any[] = reactive([] as Array<ToastType>)
    const dark = ref<boolean>(false);
    const localStorageDark: boolean = localStorage.getItem('theme') === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches);

    watch(() => dark.value, (value) => darkMode(value));
    watch(() => usePage().props?.toast, (toast) => {
      if (typeof toast === 'object' && toast !== null && toast !== undefined) {
        setTimeout(() => toasts.push(toast), 600)
      }
    },
    {
      deep: true,
      immediate: true,
    });

    function darkMode(value: boolean): void 
    {
      if(value){
        root.classList.add('dark');
        localStorage.setItem('theme', 'dark');
        dark.value = true;
      }else{
        root.classList.remove("dark");
        localStorage.setItem('theme', 'light');
        dark.value = false;
      }
    };

    onMounted(() => {
      dark.value = localStorageDark;

      root.classList.add('h-full', 'antialiased');
      body.classList.add('bg-white', 'dark:bg-zinc-950');
    });

    onUnmounted(() => {
      root.classList.remove('h-full', 'antialiased');
      body.classList.remove('bg-white', 'dark:bg-zinc-950');

      dark.value = false;
    });

    return {
      darkMode,
      toasts,
      route,
      dark,
    };
  },
})
</script>