<template>
  <svg
    viewBox="0 0 881 211"
    fill="white"
    aria-hidden="true"
    :class="['pointer-events-none absolute w-[55.0625rem] origin-top-right rotate-[30deg] overflow-visible opacity-70 -right-44 top-14', className]"
  >
    <defs>
      <filter :id="blurId">
        <feGaussianBlur in="SourceGraphic" stdDeviation=".5" />
      </filter>
    </defs>
    <Constellation
      v-for="(points, index) in constellations"
      :key="index"
      :points="points"
      :blurId="blurId"
    />
    <Star
      v-for="(point, index) in stars"
      :key="index"
      :point="point"
      :blurId="blurId"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Star from './Star.vue';
import Constellation from './Constellation.vue';

type StarType = [number, number, boolean?, boolean?];

const stars: Array<StarType> = [
  [4, 4, true, true],
  [4, 44, true],
  [36, 22],
  [50, 146, true, true],
  [64, 43, true, true],
  [76, 30, true],
  [101, 116],
  [140, 36, true],
  [149, 134],
  [162, 74, true],
  [171, 96, true, true],
  [210, 56, true, true],
  [235, 90],
  [275, 82, true, true],
  [306, 6],
  [307, 64, true, true],
  [380, 68, true],
  [380, 108, true, true],
  [391, 148, true, true],
  [405, 18, true],
  [412, 86, true, true],
  [426, 210, true, true],
  [427, 56, true, true],
  [538, 138],
  [563, 88, true, true],
  [611, 154, true, true],
  [637, 150],
  [651, 146, true],
  [682, 70, true, true],
  [683, 128],
  [781, 82, true, true],
  [785, 158, true],
  [832, 146, true, true],
  [852, 89],
];

const constellations: Array<Array<StarType>> = [
  [
    [247, 103],
    [261, 86],
    [307, 104],
    [357, 36],
  ],
  [
    [586, 120],
    [516, 100],
    [491, 62],
    [440, 107],
    [477, 180],
    [516, 100],
  ],
  [
    [733, 100],
    [803, 120],
    [879, 113],
    [823, 164],
    [803, 120],
  ],
];

export default defineComponent({
  name: 'StarField',
  components: { Star, Constellation },
  props: {
    className: {
      type: String,
      default: '',
    },
  },
  setup() {
    const blurId = `blur-${Math.random().toString(36).substr(2, 9)}`;

    return {
      blurId,
      stars,
      constellations,
    };
  },
});
</script>