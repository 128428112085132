import { default as LightColumn } from '@/Types/Resources/Column/Light';
import type { updateColumnFunction } from '@/Pages/Boards/Types';
import { PayloadResponse } from '@/Types/API/PayloadResponse';
import { sendRequest } from '@/mixins';
import { ref } from 'vue';


export function useCardPagination(boardId: string, updateColumn: updateColumnFunction)
{
  const cardSkeletonRefs = ref<{ [key: string]: any }>({});

  function setCardSkeletonRef(columnId: string, el: any): void
  {
    if (el) {
      cardSkeletonRefs.value[columnId] = el;
    }
  }

  function hasMoreCards(column: LightColumn): boolean
  {
    return column.cards.current_page < column.cards.last_page;
  }

  function loadMoreCards(column: LightColumn): void
  {
    sendRequest({
      name: 'api.v1.columns.get',
      params: { 
        board: boardId,
        column: column.id,
        page: column.cards.current_page + 1
      }
    }, (data: PayloadResponse<LightColumn>) => {
      if (data.payload.cards.data.length > 0) {
        const updatedColumn = {
          ...column,
          cards: {
            ...column.cards,
            data: [...column.cards.data, ...data.payload.cards.data],
            current_page: data.payload.cards.current_page,
            last_page: data.payload.cards.last_page,
          }
        };
        updateColumn(updatedColumn);
      }
      
      const skeletonRef = cardSkeletonRefs.value[column.id];
      if (skeletonRef && typeof skeletonRef.resetLoading === 'function') {
        skeletonRef.resetLoading();
      }
    });
  }

  return {
    setCardSkeletonRef,
    cardSkeletonRefs,
    loadMoreCards,
    hasMoreCards,
  };
}