import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass({
    'bg-green-50 dark:bg-green-400/10 ring-green-500/10 dark:ring-green-400/20 text-green-600 dark:text-green-400': _ctx.type === 'success',
    'bg-yellow-50 dark:bg-yellow-400/10 ring-yellow-500/10 dark:ring-yellow-400/20 text-yellow-600 dark:text-yellow-400': _ctx.type === 'warning' || _ctx.type === 'attempt',
    'bg-red-50 dark:bg-red-400/10 ring-red-500/10 dark:ring-red-400/20 text-red-600 dark:text-red-400': _ctx.type === 'error',
    'bg-blue-50 dark:bg-blue-400/10 ring-blue-500/10 dark:ring-blue-400/20 text-blue-600 dark:text-blue-400': _ctx.type === 'info',
    'inline-flex items-center rounded-md px-2 py-1 text-sm font-medium ring-1 ring-inset': true
  })
  }, [
    _renderSlot(_ctx.$slots, "default"),
    _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
  ], 2))
}