import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, Transition as _Transition, createTextVNode as _createTextVNode, Fragment as _Fragment, withModifiers as _withModifiers, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-end sm:items-start justify-center w-screen h-screen text-center" }
const _hoisted_2 = { class: "flex items-center justify-between px-4 pt-4 pb-1.5 rounded-t md:px-6 md:pt-6" }
const _hoisted_3 = { class: "text-xl font-semibold text-gray-900 dark:text-white" }
const _hoisted_4 = { class: "text-gray-600 dark:text-gray-400 inline-flex items-center mb-2 text-md font-semibold text-center" }
const _hoisted_5 = { class: "flex-grow-0 flex-shrink-0 rounded-md text-gray-500" }
const _hoisted_6 = { role: "none" }
const _hoisted_7 = { class: "flex flex-col sm:flex-row justify-between" }
const _hoisted_8 = { class: "w-full max-w-[--card-content-width]" }
const _hoisted_9 = { class: "px-4 md:px-6" }
const _hoisted_10 = { class: "flex flex-col items-start justify-center mb-4 ml-6 space-y-3" }
const _hoisted_11 = { class: "text-sm text-gray-500 dark:text-gray-400" }
const _hoisted_12 = {
  href: "#",
  class: "text-primary-700 no-underline cursor-pointer hover:underline dark:text-primary-500"
}
const _hoisted_13 = { class: "text-gray-900 dark:text-gray-400 inline-flex items-center mb-2 text-md font-semibold text-center" }
const _hoisted_14 = {
  key: 0,
  class: "text-black dark:text-white mb-4 ml-6 space-y-2 text-base"
}
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = {
  key: 1,
  class: "flex flex-col"
}
const _hoisted_17 = { class: "flex flex-row mt-2" }
const _hoisted_18 = {
  key: 0,
  class: "mb-4 ml-6"
}
const _hoisted_19 = { class: "clear-both mb-6 relative block" }
const _hoisted_20 = { class: "justify-between items-center flex min-h-8 m-0 mb-1 relative" }
const _hoisted_21 = { class: "text-gray-900 dark:text-gray-400 inline-flex items-center mb-2 text-md font-semibold text-center" }
const _hoisted_22 = { class: "float-right mr-0.5 block" }
const _hoisted_23 = { class: "block relative mb-2" }
const _hoisted_24 = { class: "flex items-start space-x-4" }
const _hoisted_25 = { class: "min-w-0 flex-1" }
const _hoisted_26 = { class: "flex space-x-3" }
const _hoisted_27 = { class: "flex-shrink-0" }
const _hoisted_28 = { class: "relative" }
const _hoisted_29 = { class: "bg-white dark:bg-gray-900 absolute -bottom-0.5 -right-1 rounded-tl px-0.5 py-px" }
const _hoisted_30 = { class: "min-w-0 flex-1" }
const _hoisted_31 = { class: "mt-2 flex items-center justify-start" }
const _hoisted_32 = {
  key: 0,
  class: "block"
}
const _hoisted_33 = {
  key: 0,
  class: "ml-12 min-h-8 py-0 relative block my-3"
}
const _hoisted_34 = { class: "ml-12 min-h-8 py-0 relative block my-3" }
const _hoisted_35 = { key: 0 }
const _hoisted_36 = { class: "h-8 -left-10 absolute top-1 w-8 block" }
const _hoisted_37 = { class: "text-gray-600 dark:text-gray-400 m-0 break-words block text-sm" }
const _hoisted_38 = ["innerHTML"]
const _hoisted_39 = { class: "text-gray-600 dark:text-gray-400 text-nano m-0 min-w-28" }
const _hoisted_40 = { key: 1 }
const _hoisted_41 = { class: "h-8 -left-10 absolute top-1 w-8 block" }
const _hoisted_42 = { class: "m-0" }
const _hoisted_43 = { class: "text-gray-600 dark:text-gray-400 text-sm font-bold cursor-pointer" }
const _hoisted_44 = { class: "text-gray-500 dark:text-gray-600 text-nano font-normal whitespace-pre leading-3 m-0 mb-1.5 min-w-24" }
const _hoisted_45 = { class: "bg-gray-100 dark:bg-gray-950 block mt-1 rounded-lg box-border clear-both max-w-full overflow-hidden overflow-ellipsis" }
const _hoisted_46 = ["innerHTML"]
const _hoisted_47 = { key: 1 }
const _hoisted_48 = { class: "mt-2 flex items-center justify-start" }
const _hoisted_49 = {
  key: 0,
  class: "items-center flex flex-wrap leading-6 mr-6"
}
const _hoisted_50 = { class: "text-nano m-0 min-w-28 block self-start" }
const _hoisted_51 = { class: "text-gray-500 dark:text-gray-700 cursor-pointer text-mini" }
const _hoisted_52 = ["onClick"]
const _hoisted_53 = ["onClick"]
const _hoisted_54 = { class: "sticky top-4 space-y-2 lg:flex w-full sm:w-48 flex-col mb-4 sm:mb-0 px-4 sm:px-0 sm:pr-4 md:pr-6" }
const _hoisted_55 = { class: "text-gray-900 dark:text-gray-300 hidden sm:inline-flex mb-1 items-center text-tiny font-semibold text-start" }
const _hoisted_56 = { class: "leading-normal whitespace-nowrap" }
const _hoisted_57 = { class: "text-gray-900 dark:text-gray-300 hidden sm:inline-flex items-center mt-2 mb-1 text-tiny font-semibold text-start" }
const _hoisted_58 = { class: "leading-normal whitespace-nowrap" }
const _hoisted_59 = {
  key: 1,
  role: "status",
  class: "absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Head = _resolveComponent("Head")!
  const _component_DialogOverlay = _resolveComponent("DialogOverlay")!
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_Cog8ToothIcon = _resolveComponent("Cog8ToothIcon")!
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_MembersMenu = _resolveComponent("MembersMenu")!
  const _component_TagsMenu = _resolveComponent("TagsMenu")!
  const _component_Bars3CenterLeftIcon = _resolveComponent("Bars3CenterLeftIcon")!
  const _component_Editor = _resolveComponent("Editor")!
  const _component_Button = _resolveComponent("Button")!
  const _component_PlusIcon = _resolveComponent("PlusIcon")!
  const _component_CheckList = _resolveComponent("CheckList")!
  const _component_ListBulletIcon = _resolveComponent("ListBulletIcon")!
  const _component_ButtonSecondary = _resolveComponent("ButtonSecondary")!
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_ChatBubbleLeftEllipsisIcon = _resolveComponent("ChatBubbleLeftEllipsisIcon")!
  const _component_CheckCircleIcon = _resolveComponent("CheckCircleIcon")!
  const _component_DocumentDuplicateIcon = _resolveComponent("DocumentDuplicateIcon")!
  const _component_TrashIcon = _resolveComponent("TrashIcon")!
  const _component_Attempt = _resolveComponent("Attempt")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.card != null)
      ? (_openBlock(), _createBlock(_component_Head, {
          key: 0,
          title: `${_ctx.card?.title} | ${_ctx.getAppName()}`
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    _createVNode(_component_TransitionRoot, {
      as: "template",
      show: _ctx.open
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Dialog, {
          as: "div",
          class: "fixed z-50 inset-0 overflow-y-auto",
          onClose: _cache[18] || (_cache[18] = ($event: any) => (_ctx.handleClose()))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_TransitionChild, {
                as: "template",
                enter: "ease-out duration-300",
                "enter-from": "opacity-0",
                "enter-to": "opacity-100",
                leave: "ease-in duration-200",
                "leave-from": "opacity-100",
                "leave-to": "opacity-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DialogOverlay, { class: "fixed inset-0 bg-black bg-opacity-50" })
                ]),
                _: 1
              }),
              _cache[24] || (_cache[24] = _createElementVNode("span", {
                class: "hidden sm:inline-block sm:align-middle sm:h-screen",
                "aria-hidden": "true"
              }, " ​ ", -1)),
              _createElementVNode("div", {
                class: _normalizeClass([{' min-h-screen sm:min-h-[--card-modal-placeholder-height]': _ctx.card === null}, "bg-white dark:bg-gray-900 text-left relative my-auto inline-block text-base font-normal leading-5 sm:rounded-lg transform w-screen sm:max-w-[--card-modal-width] transition-all sm:my-8 sm:align-middle"])
              }, [
                (_ctx.card != null)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                          _createElementVNode("div", _hoisted_4, [
                            _cache[19] || (_cache[19] = _createElementVNode("svg", {
                              class: "w-5 h-5 mr-1 stroke-2",
                              viewBox: "0 0 24 24",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }, [
                              _createElementVNode("path", {
                                stroke: "currentColor",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                d: "M6 7.75H11.5M3 14.75H20.5M6 10.25H8.5M3 17.5V6.25C3 5.65326 3.23705 5.08097 3.65901 4.65901C4.08097 4.23705 4.65326 4 5.25 4H18.75C19.3467 4 19.919 4.23705 20.341 4.65901C20.7629 5.08097 21 5.65326 21 6.25V17.5C21 18.0967 20.7629 18.669 20.341 19.091C19.919 19.5129 19.3467 19.75 18.75 19.75H5.25C4.65326 19.75 4.08097 19.5129 3.65901 19.091C3.23705 18.669 3 18.0967 3 17.5Z"
                              })
                            ], -1)),
                            _createVNode(_component_Menu, {
                              as: "div",
                              class: "grid gap-x-1 text-start flex-[0_0_auto] grid-cols-[1fr_max-content] min-h-[--card-header-height] max-w-[--card-header-width] items-center relative"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.card?.title) + " ", 1),
                                _createElementVNode("div", _hoisted_5, [
                                  _createVNode(_component_MenuButton, { class: "lg:hidden" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_Cog8ToothIcon, { class: "h-4 w-4" })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _createVNode(_Transition, {
                                  "enter-active-class": "transition ease-out duration-100",
                                  "enter-from-class": "transform opacity-0 scale-95",
                                  "enter-to-class": "transform opacity-100 scale-100",
                                  "leave-active-class": "transition ease-in duration-75",
                                  "leave-from-class": "transform opacity-100 scale-100",
                                  "leave-to-class": "transform opacity-0 scale-95"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_MenuItems, { class: "bg-white dark:bg-gray-850 border-gray-200 dark:border-gray-750 divide-gray-100 dark:divide-gray-750 border origin-top-right absolute z-5 top-5 right-0 max-w-32 w-24 py-1 text-xs list-none rounded shadow-sm" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("ul", _hoisted_6, [
                                          _createElementVNode("li", {
                                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.checklistModalIsOpen = true)),
                                            class: "text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-white cursor-pointer select-none p-1"
                                          }, _toDisplayString(_ctx.$trans('main.boards.buttons.checklist')), 1),
                                          _createElementVNode("li", {
                                            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.copyCard && _ctx.copyCard(...args))),
                                            class: "text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-white cursor-pointer select-none p-1"
                                          }, _toDisplayString(_ctx.$trans('main.shared.buttons.copy')), 1),
                                          _createElementVNode("li", {
                                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isDeleteCardModalVisible = true)),
                                            class: "text-red-700 dark:hover:text-white dark:hover:bg-red-800 hover:bg-red-100 cursor-pointer select-none p-1"
                                          }, _toDisplayString(_ctx.$trans('main.shared.buttons.delete')), 1)
                                        ])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _createElementVNode("button", {
                          type: "button",
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleClose())),
                          class: "text-gray-500 dark:text-gray-400 focus:ring-gray-200 dark:focus:ring-gray-700 hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none focus:ring-4 rounded-lg text-sm p-2"
                        }, [
                          _createVNode(_component_XMarkIcon, { class: "w-5 h-5 stroke-2" })
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("div", _hoisted_11, [
                                _createTextVNode(_toDisplayString(_ctx.$trans('main.boards.added_by')) + " ", 1),
                                _createElementVNode("a", _hoisted_12, _toDisplayString(`${_ctx.propsCard?.creator_name}`), 1),
                                _createTextVNode(", " + _toDisplayString(_ctx.cardCreatedAtFormatted), 1)
                              ]),
                              _createVNode(_component_MembersMenu, {
                                card: _ctx.card,
                                board: _ctx.board,
                                onUpdateCard: _ctx.handleUpdateLightCard
                              }, null, 8, ["card", "board", "onUpdateCard"]),
                              _createVNode(_component_TagsMenu, {
                                colors: _ctx.colors,
                                board: _ctx.board,
                                card: _ctx.card,
                                onUpdateBoard: _ctx.handleUpdateBoard,
                                onUpdateCard: _ctx.handleUpdateLightCard
                              }, null, 8, ["colors", "board", "card", "onUpdateBoard", "onUpdateCard"])
                            ]),
                            _createElementVNode("div", _hoisted_13, [
                              _createVNode(_component_Bars3CenterLeftIcon, { class: "w-5 h-5 mr-1 stroke-2" }),
                              _createTextVNode(" " + _toDisplayString(_ctx.$trans('main.boards.titles.description')), 1)
                            ]),
                            (_ctx.card?.description || _ctx.descriptionIsOpenForm)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                  (!_ctx.descriptionIsOpenForm)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _createElementVNode("div", {
                                          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.openDescriptionForm && _ctx.openDescriptionForm(...args))),
                                          innerHTML: _ctx.shortDescription ? _ctx.truncateText(_ctx.card?.description ?? '', 200) : _ctx.card?.description
                                        }, null, 8, _hoisted_15),
                                        ((_ctx.card?.description ?? '').length > 200)
                                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                              (_ctx.shortDescription)
                                                ? (_openBlock(), _createElementBlock("div", {
                                                    key: 0,
                                                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.shortDescription = false)),
                                                    class: "text-sm font-semibold text-primary-700 cursor-pointer w-max hover:underline dark:text-primary-500"
                                                  }, _toDisplayString(_ctx.$trans('main.boards.buttons.show_all_description')), 1))
                                                : (_openBlock(), _createElementBlock("div", {
                                                    key: 1,
                                                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.shortDescription = true)),
                                                    class: "text-sm font-semibold text-primary-700 cursor-pointer w-max hover:underline dark:text-primary-500"
                                                  }, _toDisplayString(_ctx.$trans('main.boards.buttons.show_less_description')), 1))
                                            ], 64))
                                          : _createCommentVNode("", true)
                                      ], 64))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                        (_ctx.user)
                                          ? (_openBlock(), _createBlock(_component_Editor, {
                                              key: 0,
                                              id: "b_c_c_description",
                                              name: "b_c_c_description",
                                              form: _ctx.descriptionForm,
                                              onFocusout: _cache[7] || (_cache[7] = ($event: any) => (_ctx.descriptionIsOpenForm = false)),
                                              members: _ctx.board.members,
                                              "current-user-id": _ctx.user.id,
                                              ref: "descriptionEditor",
                                              modelValue: _ctx.descriptionForm.b_c_c_description,
                                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.descriptionForm.b_c_c_description) = $event))
                                            }, null, 8, ["form", "members", "current-user-id", "modelValue"]))
                                          : _createCommentVNode("", true),
                                        _createElementVNode("div", _hoisted_17, [
                                          _createVNode(_component_Button, {
                                            classes: "mr-2",
                                            onClick: _ctx.submitDescription,
                                            size: "sm",
                                            text: _ctx.$trans('main.shared.buttons.save')
                                          }, null, 8, ["onClick", "text"]),
                                          _createVNode(_component_Button, {
                                            color: "gray",
                                            size: "sm",
                                            text: _ctx.$trans('main.shared.buttons.hide'),
                                            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.closeDescriptionForm()))
                                          }, null, 8, ["text"])
                                        ])
                                      ]))
                                ]))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  (!_ctx.descriptionIsOpenForm)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                        _createElementVNode("button", {
                                          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.openDescriptionForm())),
                                          class: "bg-white dark:bg-gray-700 text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 border-gray-200 dark:border-gray-600 border font-semibold rounded-lg text-nano px-2 py-1 text-center inline-flex items-center"
                                        }, [
                                          _createVNode(_component_PlusIcon, { class: "w-4 h-4 stroke-2 mr-1" }),
                                          _createTextVNode(" " + _toDisplayString(_ctx.$trans('main.boards.buttons.add_description')), 1)
                                        ])
                                      ]))
                                    : _createCommentVNode("", true)
                                ], 64)),
                            _createVNode(_component_CheckList, {
                              board: _ctx.board,
                              card: _ctx.card,
                              open: _ctx.checklistModalIsOpen,
                              onUpdateCard: _ctx.handleUpdateLightCard,
                              onUpdateActions: _ctx.updateActions,
                              onCloseChecklistModal: _ctx.handleСloseChecklistModal
                            }, null, 8, ["board", "card", "open", "onUpdateCard", "onUpdateActions", "onCloseChecklistModal"]),
                            _createElementVNode("div", _hoisted_19, [
                              _createElementVNode("div", _hoisted_20, [
                                _createElementVNode("h3", _hoisted_21, [
                                  _createVNode(_component_ListBulletIcon, { class: "w-5 h-5 mr-1 stroke-2" }),
                                  _createTextVNode(" " + _toDisplayString(_ctx.$trans('main.boards.titles.history')), 1)
                                ]),
                                _createElementVNode("div", _hoisted_22, [
                                  _createVNode(_component_ButtonSecondary, {
                                    text: _ctx.$trans(`main.shared.buttons.${_ctx.card?.actions_visible ? 'hide' : 'show'}`),
                                    onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.throttledToggleActions())),
                                    size: "sm"
                                  }, null, 8, ["text"])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_23, [
                                _createElementVNode("div", _hoisted_24, [
                                  _createElementVNode("div", _hoisted_25, [
                                    _createElementVNode("div", _hoisted_26, [
                                      _createElementVNode("div", _hoisted_27, [
                                        _createElementVNode("div", _hoisted_28, [
                                          (_ctx.user)
                                            ? (_openBlock(), _createBlock(_component_UserAvatar, {
                                                key: 0,
                                                user: _ctx.user,
                                                size: "md",
                                                withTooltip: false
                                              }, null, 8, ["user"]))
                                            : _createCommentVNode("", true),
                                          _createElementVNode("span", _hoisted_29, [
                                            _createVNode(_component_ChatBubbleLeftEllipsisIcon, { class: "h-3 w-3 sm:h-5 sm:w-5 text-gray-400" })
                                          ])
                                        ])
                                      ]),
                                      _createElementVNode("div", _hoisted_30, [
                                        _createElementVNode("form", {
                                          onSubmit: _cache[13] || (_cache[13] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitNewComment && _ctx.submitNewComment(...args)), ["prevent"]))
                                        }, [
                                          _createElementVNode("div", null, [
                                            (_ctx.user)
                                              ? (_openBlock(), _createBlock(_component_Editor, {
                                                  key: 0,
                                                  id: "b_c_c_content",
                                                  name: "b_c_c_content",
                                                  form: _ctx.newCommentForm,
                                                  members: _ctx.board.members,
                                                  "current-user-id": _ctx.user.id,
                                                  modelValue: _ctx.newCommentForm.b_c_c_content,
                                                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.newCommentForm.b_c_c_content) = $event))
                                                }, null, 8, ["form", "members", "current-user-id", "modelValue"]))
                                              : _createCommentVNode("", true)
                                          ]),
                                          _createElementVNode("div", _hoisted_31, [
                                            _createVNode(_component_Button, {
                                              text: _ctx.$trans('main.boards.buttons.comment'),
                                              size: "sm",
                                              rounded: "md",
                                              loading: _ctx.newCommentForm.processing,
                                              cursorNotAllowed: !_ctx.newCommentForm.isDirty,
                                              disabled: _ctx.newCommentForm.invalid('b_c_c_content')
                                            }, null, 8, ["text", "loading", "cursorNotAllowed", "disabled"])
                                          ])
                                        ], 32)
                                      ])
                                    ])
                                  ])
                                ])
                              ]),
                              ((_ctx.card?.actions_visible ?? null) === true || _ctx.actionsIsLoading)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                    (_ctx.actionsIsLoading)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_33, _cache[20] || (_cache[20] = [
                                          _createElementVNode("div", {
                                            role: "status",
                                            class: "absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
                                          }, [
                                            _createElementVNode("svg", {
                                              class: "w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600",
                                              viewBox: "0 0 24 24",
                                              xmlns: "http://www.w3.org/2000/svg"
                                            }, [
                                              _createElementVNode("path", {
                                                d: "M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z",
                                                opacity: ".25"
                                              }),
                                              _createElementVNode("circle", {
                                                class: "spinner_7WDj",
                                                cx: "12",
                                                cy: "2.5",
                                                r: "1.5"
                                              })
                                            ])
                                          ], -1)
                                        ])))
                                      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.actionsAndComments ?? [], (item) => {
                                          return (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                            (item?.type === 'action')
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                                                  _createElementVNode("div", _hoisted_36, [
                                                    _createVNode(_component_UserAvatar, {
                                                      user: item.user,
                                                      size: "sm",
                                                      withTooltip: false
                                                    }, null, 8, ["user"])
                                                  ]),
                                                  _createElementVNode("div", _hoisted_37, [
                                                    _createElementVNode("span", {
                                                      innerHTML: item.content
                                                    }, null, 8, _hoisted_38)
                                                  ]),
                                                  _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.formatTime(item.created_at)), 1)
                                                ]))
                                              : (item?.type === 'comment')
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                                                    _createElementVNode("div", _hoisted_41, [
                                                      _createVNode(_component_UserAvatar, {
                                                        user: item.creator,
                                                        size: "sm",
                                                        withTooltip: false
                                                      }, null, 8, ["user"])
                                                    ]),
                                                    _createElementVNode("div", _hoisted_42, [
                                                      _createElementVNode("span", _hoisted_43, _toDisplayString(item.creator.name), 1),
                                                      _cache[21] || (_cache[21] = _createElementVNode("span", { class: "inline-block min-w-1" }, null, -1)),
                                                      (_ctx.editCommentForm._comment_id != item.id)
                                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                            _createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.formatTime(item.created_at) + `${item.is_changed ? ` (${_ctx.$trans('main.boards.changed')})` : ''}`), 1),
                                                            _createElementVNode("div", null, [
                                                              _createElementVNode("div", _hoisted_45, [
                                                                _createElementVNode("div", {
                                                                  innerHTML: item.content,
                                                                  class: "text-gray-700 dark:text-gray-400 comment-content py-3 px-3 break-words text-sm block text-ellipsis overflow-hidden max-w-full font-normal leading-8 cursor-auto"
                                                                }, null, 8, _hoisted_46)
                                                              ])
                                                            ])
                                                          ], 64))
                                                        : (_openBlock(), _createElementBlock("div", _hoisted_47, [
                                                            _createElementVNode("form", {
                                                              onSubmit: _cache[15] || (_cache[15] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitEditComment && _ctx.submitEditComment(...args)), ["prevent"]))
                                                            }, [
                                                              _createElementVNode("div", null, [
                                                                _createVNode(_component_Editor, {
                                                                  id: "edit-comment-area",
                                                                  name: "b_c_c_content",
                                                                  form: _ctx.editCommentForm,
                                                                  members: _ctx.board.members,
                                                                  "current-user-id": _ctx.user?.id ?? 0,
                                                                  modelValue: _ctx.editCommentForm.b_c_c_content,
                                                                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editCommentForm.b_c_c_content) = $event))
                                                                }, null, 8, ["form", "members", "current-user-id", "modelValue"])
                                                              ]),
                                                              _createElementVNode("div", _hoisted_48, [
                                                                _createVNode(_component_Button, {
                                                                  text: _ctx.$trans('main.shared.buttons.update'),
                                                                  size: "sm",
                                                                  rounded: "md",
                                                                  loading: _ctx.editCommentForm.processing,
                                                                  cursorNotAllowed: !_ctx.editCommentForm.isDirty,
                                                                  disabled: _ctx.editCommentForm.invalid('b_c_c_content')
                                                                }, null, 8, ["text", "loading", "cursorNotAllowed", "disabled"]),
                                                                _createVNode(_component_Button, {
                                                                  type: "button",
                                                                  color: "gray",
                                                                  class: "ml-2",
                                                                  onClick: _ctx.closeEditCommentForm,
                                                                  text: _ctx.$trans('main.shared.buttons.hide'),
                                                                  size: "sm",
                                                                  rounded: "md"
                                                                }, null, 8, ["onClick", "text"])
                                                              ])
                                                            ], 32)
                                                          ]))
                                                    ]),
                                                    (_ctx.editCommentForm._comment_id != item.id)
                                                      ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
                                                          _createElementVNode("div", _hoisted_50, [
                                                            _createElementVNode("span", _hoisted_51, [
                                                              _createElementVNode("a", {
                                                                type: "button",
                                                                onClick: ($event: any) => (_ctx.openEditCommentForm(item)),
                                                                class: "underline"
                                                              }, _toDisplayString(_ctx.$trans('main.shared.buttons.edit')), 9, _hoisted_52),
                                                              _cache[22] || (_cache[22] = _createTextVNode(" • ")),
                                                              _createElementVNode("a", {
                                                                type: "button",
                                                                onClick: ($event: any) => (_ctx.openDeleteCommentModal(item)),
                                                                class: "underline"
                                                              }, _toDisplayString(_ctx.$trans('main.shared.buttons.delete')), 9, _hoisted_53)
                                                            ])
                                                          ])
                                                        ]))
                                                      : _createCommentVNode("", true)
                                                  ]))
                                                : _createCommentVNode("", true)
                                          ]))
                                        }), 256))
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ]),
                        _createElementVNode("div", null, [
                          _createElementVNode("div", _hoisted_54, [
                            _createElementVNode("div", _hoisted_55, [
                              _createElementVNode("span", _hoisted_56, _toDisplayString(_ctx.$trans('main.boards.titles.add_to_card')), 1)
                            ]),
                            _createVNode(_component_ButtonSecondary, {
                              size: "xl",
                              classes: "items-center text-petite !py-1.5 !px-2 !font-medium flex justify-center sm:justify-start",
                              onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.checklistModalIsOpen = true)),
                              text: _ctx.$trans('main.boards.buttons.checklist')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_CheckCircleIcon, { class: "w-4 h-4 mr-1 stroke-1" })
                              ]),
                              _: 1
                            }, 8, ["text"]),
                            _createElementVNode("div", _hoisted_57, [
                              _createElementVNode("span", _hoisted_58, _toDisplayString(_ctx.$trans('main.boards.titles.actions')), 1)
                            ]),
                            _createVNode(_component_ButtonSecondary, {
                              size: "xl",
                              classes: "mt-1 sm:mt-0 items-center text-petite !py-1.5 !px-2 !font-medium flex justify-center sm:justify-start",
                              onClick: _ctx.copyCard,
                              text: _ctx.$trans('main.shared.buttons.copy')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_DocumentDuplicateIcon, { class: "w-4 h-4 mr-1 stroke-1" })
                              ]),
                              _: 1
                            }, 8, ["onClick", "text"]),
                            _createVNode(_component_ButtonSecondary, {
                              size: "xl",
                              classes: "mt-1 items-center text-petite !py-1.5 !px-2 !font-medium flex justify-center sm:justify-start",
                              onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.isDeleteCardModalVisible = true)),
                              text: _ctx.$trans('main.shared.buttons.delete')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_TrashIcon, { class: "w-4 h-4 mr-1 stroke-1" })
                              ]),
                              _: 1
                            }, 8, ["text"])
                          ])
                        ])
                      ]),
                      _createVNode(_component_Attempt, {
                        open: _ctx.isDeleteCardModalVisible,
                        close: (value) => _ctx.isDeleteCardModalVisible = value,
                        "is-api": true,
                        routeName: "api.v1.boards.cards.destroy",
                        "send-callback": _ctx.handleRemoveMicroCard,
                        "delete-callback": _ctx.handleRemoveMicroCard,
                        routeArgument: { card: _ctx.propsCard?.id ?? false },
                        title: _ctx.$trans('main.attempts.delete_board_card.title'),
                        description: _ctx.$trans('main.attempts.delete_board_card.description'),
                        successButtonText: _ctx.$trans('main.attempts.buttons.success_button'),
                        cancelButtonText: _ctx.$trans('main.attempts.buttons.cancel_button')
                      }, null, 8, ["open", "close", "send-callback", "delete-callback", "routeArgument", "title", "description", "successButtonText", "cancelButtonText"]),
                      _createVNode(_component_Attempt, {
                        open: _ctx.isDeleteCommentModalVisible,
                        close: (value) => _ctx.isDeleteCommentModalVisible = value,
                        "is-api": true,
                        routeName: "api.v1.comments.destroy",
                        "send-callback": _ctx.removeComment,
                        "delete-callback": _ctx.removeComment,
                        routeArgument: { card: _ctx.propsCard?.id ?? false, comment: _ctx.commentToDelete?.id ?? false },
                        title: _ctx.$trans('main.attempts.delete_comment_card.title'),
                        description: _ctx.$trans('main.attempts.delete_comment_card.description'),
                        successButtonText: _ctx.$trans('main.attempts.buttons.success_button'),
                        cancelButtonText: _ctx.$trans('main.attempts.buttons.cancel_button')
                      }, null, 8, ["open", "close", "send-callback", "delete-callback", "routeArgument", "title", "description", "successButtonText", "cancelButtonText"])
                    ], 64))
                  : (_openBlock(), _createElementBlock("div", _hoisted_59, _cache[23] || (_cache[23] = [
                      _createElementVNode("svg", {
                        class: "w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600",
                        viewBox: "0 0 24 24",
                        xmlns: "http://www.w3.org/2000/svg"
                      }, [
                        _createElementVNode("path", {
                          d: "M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z",
                          opacity: ".25"
                        }),
                        _createElementVNode("circle", {
                          class: "spinner_7WDj",
                          cx: "12",
                          cy: "2.5",
                          r: "1.5"
                        })
                      ], -1)
                    ])))
              ], 2)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}