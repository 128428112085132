<template>
  <label :for="id" class="block text-sm font-medium text-gray-700 dark:text-white">
    {{ $trans(label) }}
  </label>
  <div :class="divClass">
    <input :id="id" 
      @change="form.validate(name)" 
      :type="type" 
      :name="name" 
      :autocomplete="autocomplete" 
      :required="required" 
      :placeholder="placeholder"
      v-model="value"
      class="bg-gray-50 dark:bg-gray-850 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full focus:outline-none sm:text-sm rounded-md"
      :class="{
        'border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700': !form.invalid(name),
        'border-red-300 dark:border-red-500 text-red-700 dark:text-red-500 focus:ring-red-500 focus:border-red-500 pr-10': form.invalid(name),
      }">
    <div v-if="form.invalid(name)" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
      <ExclamationCircleIcon class="h-5 w-5 text-red-500" />
    </div>
  </div>
  <p v-if="withError && form.invalid(name)" class="mt-2 text-sm text-red-600 dark:text-red-500">
    {{ form.errors[name] }}
  </p>
</template>
<script lang="ts">
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline';
import { defineComponent, ref, watch, PropType } from "vue";
import { useForm } from 'laravel-precognition-vue';

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true,
    },
    autocomplete: {
      type: String as () => 'on' | 'off',
      required: false,
      default: 'off'
    },
    required: {
      type: Boolean,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    modelValue: {
      type: [String, Number, Boolean, null] as PropType<string | number | boolean | null>,
      required: true
    },
    withError: {
      type: Boolean,
      default: true,
    },
    form: {
      // type: Object as () => ReturnType<typeof useForm>,
      type: Object as PropType<ReturnType<typeof useForm>>,
      required: true
    },
    divClass: {
      type: String,
      required: false,
      default: 'mt-1 relative rounded-md shadow-sm'
    }
  },
  components: { ExclamationCircleIcon },
  emits: ['update:modelValue', 'click'],
  setup(props, { emit }) {
    const value = ref(props.modelValue);
  
    watch(() => props.modelValue, (newValue) => {
      value.value = newValue;
    });
  
    watch(value, (newValue) => {
      emit('update:modelValue', newValue);
    });

    return { value };
  }
});
</script>