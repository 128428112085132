<template>
  <Head>
    <meta name="theme-color" :content="dark ? '#111928' : '#F9FAFB'"/>
    <link rel="icon" type="image/svg+xml" :href="dark ? '/favicon-dark.svg' : '/favicon.svg'"/>
  </Head>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50 lg:hidden" @close="open = false">
      <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-900/50 dark:bg-gray-900/90" />
      </TransitionChild>
      <div class="fixed inset-0 flex">
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                <button type="button" class="-m-2.5 p-2.5" @click="open = false">
                  <span class="sr-only">Close sidebar</span>
                  <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true"/>
                </button>
              </div>
            </TransitionChild>
            <!-- Start Phone -->
            <div class="bg-white dark:bg-gray-900 border-gray-200 dark:border-gray-800 flex grow border-r flex-col gap-y-5 overflow-y-auto px-6 pb-4">
              <div class="flex h-16 shrink-0 items-center justify-center">
                <img src="/dist/images/logo-text.svg" class=" mx-0.5 mt-2 h-8 w-auto dark:filter-white" alt="Atlant Logo"/>
              </div>
              <nav class="flex flex-1 flex-col">
                <ul role="list" class="flex flex-1 flex-col gap-y-7">
                  <!-- Navigation Items -->
                  <li>
                    <div class="text-mini my-2 font-semibold leading-6 text-gray-400">
                      {{ $trans('main.navigation') }}
                    </div>
                    <ul role="list" class="-mx-2 space-y-1">
                      <li v-for="item in navigation" :key="item.name">
                        <Disclosure v-if="item.children && item.show" as="div" :defaultOpen="getTouch(item.name)" v-slot="{ open, close }">
                          <DisclosureButton @click="setTouch(!open, item.name, close)" :class="[item.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'group flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold']">
                            <component :is="item.icon" :class="[item.current ? 'text-primary-600' : 'group-hover:text-primary-600 dark:group-hover:text-white', 'text-gray-400 h-6 w-6 shrink-0']" aria-hidden="true"/>
                            {{ $trans(item.name) }}
                            <ChevronRightIcon :class="[open ? 'rotate-90 text-gray-500' : 'text-gray-400', 'ml-auto h-5 w-5 shrink-0']" aria-hidden="true" />
                          </DisclosureButton>
                          <DisclosurePanel as="ul" class="mt-1 px-2">
                            <li v-for="subItem in item.children" :key="subItem.title">
                              <Link v-if="subItem.show" as="a" :href="route(subItem.route)" :class="[subItem.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'block rounded-md py-2 pr-2 pl-9 mt-1 text-sm leading-6']">
                                {{ $trans(subItem.title) }}
                              </Link>
                            </li>
                          </DisclosurePanel>
                        </Disclosure>
                        <Link v-else-if="!item.children && item.show" as="a" :href="route(item.link)" :class="[item.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                          <component :is="item.icon" :class="[item.current ? 'text-primary-600' : 'group-hover:text-primary-600 dark:group-hover:text-white', 'text-gray-400 h-6 w-6 shrink-0']" aria-hidden="true"/>
                          {{ $trans(item.name) }}
                          <span v-if="item.count" class="bg-white dark:bg-gray-900 text-gray-600 dark:text-white ring-gray-200 dark:ring-gray-700 ml-auto w-9 min-w-max whitespace-nowrap rounded-full px-2.5 py-0.5 text-center text-nano font-medium leading-5 ring-1 ring-inset" aria-hidden="true">
                            {{ item.count }}
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <!-- Projects -->
                  <li>
                    <div class="text-mini my-2 font-semibold leading-6 text-gray-400">
                      {{ $trans('main.side_menu.projects.title') }}
                    </div>
                    <ul role="list" class="-mx-2 mt-2 space-y-1">
                      <li v-if="projects?.length != undefined" v-for="project in projects" :key="project.name">
                        <!-- <a :href="project.href" :class="[project.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']"> -->
                        <a href="#" :class="[project.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                          <span :class="[project.current ? 'text-primary-600 border-primary-600' : 'text-gray-400 border-gray-200 dark:border-gray-700 group-hover:border-primary-600 dark:group-hover:border-gray-700 group-hover:text-primary-600 dark:group-hover:text-white', 'bg-white dark:bg-gray-800 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium']">
                            {{ project.initial }}
                          </span>
                          <span class="truncate">
                            {{ project.name }}
                          </span>
                        </a>
                      </li>
                      <li v-else>
                        <a href="#" class="text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                          <span class="text-gray-400 border-gray-200 dark:border-gray-700 group-hover:border-primary-600 dark:group-hover:border-gray-700 group-hover:text-primary-600 dark:group-hover:text-white bg-white dark:bg-gray-800 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium">
                            +
                          </span>
                          <span class="truncate">
                            {{ $trans('main.side_menu.projects.placeholder') }}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <!-- Footer -->
                  <li class="mt-auto flex justify-evenly">
                    <button @click="open = !open" class="text-gray-500 hover:text-gray-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800 inline-flex justify-center p-2 rounded cursor-pointer">
                      <ArrowLeftCircleIcon class="w-6 h-6"/>
                    </button>
                    <button type="button" @click="darkMode(!dark)" class="text-gray-500 hover:text-gray-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800 inline-flex justify-center p-2 rounded cursor-pointer">
                      <MoonIcon v-if="dark" class="w-6 h-6"/>
                      <SunIcon v-else class="w-6 h-6"/>
                    </button>
                    <Menu>
                      <MenuButton as="button" class="inline-flex justify-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white">
                        <Icon :name="`flag-${language}`" mainClass="h-5 w-5 rounded-full mt-0.5"/>
                      </MenuButton>
                      <MenuItems as="div"
                        :style="['transform: translate3d(188px, -64px, 0px);', 'position: absolute; inset: auto auto 0px 0px; margin: 0px;']"
                        class="z-50 my-4 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-850">
                        <ul class="py-1">
                          <MenuItem as="li">
                            <a :href="route('profile.language', {lang: 'en'})" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white">
                              <div class="inline-flex items-center">
                                <Icon name="flag-en" mainClass="h-4 w-4 rounded-full mr-2"/>
                                {{ $trans('main.shared.languages.en') }}
                              </div>
                            </a>
                          </MenuItem>
                          <MenuItem as="li">
                            <a :href="route('profile.language', {lang: 'pl'})" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white">
                              <div class="inline-flex items-center">
                                <Icon name="flag-pl" mainClass="h-4 w-4 rounded-full mr-2"/>
                                {{ $trans('main.shared.languages.pl') }}
                              </div>
                            </a>
                          </MenuItem>
                          <MenuItem as="li">
                            <a :href="route('profile.language', {lang: 'ua'})" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white">
                              <div class="inline-flex items-center">
                                <Icon name="flag-ua" mainClass="h-4 w-4 rounded-full mr-2"/>         
                                {{ $trans('main.shared.languages.ua') }}
                              </div>
                            </a>
                          </MenuItem>
                        </ul>
                      </MenuItems>
                    </Menu>
                  </li>
                </ul>
              </nav>
            </div>
            <!-- End Phone -->
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
  <!-- Desktop -->
  <div v-if="!short" class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
    <div class=" bg-white dark:bg-gray-900 border-gray-200 dark:border-gray-800 flex grow flex-col gap-y-5 overflow-y-auto border-r px-6 pb-4">
      <div class="flex h-16 shrink-0 items-center justify-center">
        <img src="/dist/images/logo-text.svg" class=" mx-0.5 mt-2 h-8 w-auto dark:filter-white" alt="Atlant Logo"/>
      </div>
      <nav class="flex flex-1 flex-col">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <!-- Navigation Items -->
          <li>
            <div class="text-mini my-2 font-semibold leading-6 text-gray-400">
              {{ $trans('main.navigation') }}
            </div>
            <ul role="list" class="-mx-2 space-y-1">
              <li v-for="item in navigation" :key="item.name">
                <Disclosure v-if="item.children && item.show" as="div" :defaultOpen="getTouch(item.name)" v-slot="{ open, close }">
                  <DisclosureButton @click="setTouch(!open, item.name, close)" :class="[item.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'group flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold']">
                    <component :is="item.icon" :class="[item.current ? 'text-primary-600' : 'group-hover:text-primary-600 dark:group-hover:text-white', 'text-gray-400 h-6 w-6 shrink-0']" aria-hidden="true"/>
                    {{ $trans(item.name) }}
                    <ChevronRightIcon :class="[open ? 'rotate-90 text-gray-500' : 'text-gray-400', 'ml-auto h-5 w-5 shrink-0']" aria-hidden="true" />
                  </DisclosureButton>
                  <DisclosurePanel as="ul" class="mt-1 px-2">
                    <li v-for="subItem in item.children" :key="subItem.title">
                      <Link v-if="subItem.show" as="a" :href="route(subItem.route)" :class="[subItem.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'block rounded-md py-2 pr-2 pl-9 mt-1 text-sm leading-6']">
                        {{ $trans(subItem.title) }}
                      </Link>
                    </li>
                  </DisclosurePanel>
                </Disclosure>
                <Link v-else-if="!item.children && item.show" as="a" :href="route(item.link)" :class="[item.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                  <component :is="item.icon" :class="[item.current ? 'text-primary-600' : 'group-hover:text-primary-600 dark:group-hover:text-white', 'text-gray-400 h-6 w-6 shrink-0']" aria-hidden="true"/>
                  {{ $trans(item.name) }}
                  <span v-if="item.count" class="bg-white dark:bg-gray-900 text-gray-600 dark:text-white ring-gray-200 dark:ring-gray-700 ml-auto w-9 min-w-max whitespace-nowrap rounded-full px-2.5 py-0.5 text-center text-nano font-medium leading-5 ring-1 ring-inset" aria-hidden="true">
                    {{ item.count }}
                  </span>
                </Link>
              </li>
            </ul>
          </li>
          <!-- Projects -->
          <li>
            <div class="text-mini my-2 font-semibold leading-6 text-gray-400">
              {{ $trans('main.side_menu.projects.title') }}
            </div>
            <ul role="list" class="-mx-2 mt-2 space-y-1">
              <li v-if="projects?.length != undefined" v-for="project in projects" :key="project.name">
                <!-- <a :href="project.href" :class="[project.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']"> -->
                <a href="#" :class="[project.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                  <span :class="[project.current ? 'text-primary-600 border-primary-600' : 'text-gray-400 border-gray-200 dark:border-gray-700 group-hover:border-primary-600 dark:group-hover:border-gray-700 group-hover:text-primary-600 dark:group-hover:text-white', 'bg-white dark:bg-gray-800 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium']">
                    {{ project.initial }}
                  </span>
                  <span class="truncate">
                    {{ project.name }}
                  </span>
                </a>
              </li>
              <li v-else>
                <a href="#" class="text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                  <span class="text-gray-400 border-gray-200 dark:border-gray-700 group-hover:border-primary-600 dark:group-hover:border-gray-700 group-hover:text-primary-600 dark:group-hover:text-white bg-white dark:bg-gray-800 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium">
                    +
                  </span>
                  <span class="truncate">
                    {{ $trans('main.side_menu.projects.placeholder') }}
                  </span>
                </a>
              </li>
            </ul>
          </li>
          <!-- Footer -->
          <li class="mt-auto flex justify-evenly">
            <button @click="setShort(!short)" class="text-gray-500 hover:text-gray-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800 inline-flex justify-center p-2 rounded cursor-pointer">
              <ArrowLeftCircleIcon class="w-6 h-6"/>
            </button>
            <button type="button" @click="darkMode(!dark)" class="text-gray-500 hover:text-gray-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800 inline-flex justify-center p-2 rounded cursor-pointer">
              <MoonIcon v-if="dark" class="w-6 h-6"/>
              <SunIcon v-else class="w-6 h-6"/>
            </button>
            <Menu>
              <MenuButton as="button" class="inline-flex justify-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white">
                <Icon :name="`flag-${language}`" mainClass="h-5 w-5 rounded-full mt-0.5"/>
              </MenuButton>
              <MenuItems as="div" 
                :style="[short ? 'transform: translate3d(162px, -54px, 0px);' : 'transform: translate3d(162px, -66px, 0px);', 'position: absolute; inset: auto auto 0px 0px; margin: 0px;']"
                class="z-50 my-4 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-850">
                <ul class="py-1">
                  <MenuItem as="li">
                    <a :href="!isCurrentLang('en') ? route('profile.language', {lang: 'en'}) : '#'" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white">
                      <div class="inline-flex items-center">
                        <Icon name="flag-en" mainClass="h-4 w-4 rounded-full mr-2"/>
                        {{ $trans('main.shared.languages.en') }}
                      </div>
                    </a>
                  </MenuItem>
                  <MenuItem as="li">
                    <a :href="!isCurrentLang('pl') ? route('profile.language', {lang: 'pl'}) : '#'" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white">
                      <div class="inline-flex items-center">
                        <Icon name="flag-pl" mainClass="h-4 w-4 rounded-full mr-2"/>
                        {{ $trans('main.shared.languages.pl') }}
                      </div>
                    </a>
                  </MenuItem>
                  <MenuItem as="li">
                    <a :href="!isCurrentLang('ua') ? route('profile.language', {lang: 'ua'}) : '#'" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white">
                      <div class="inline-flex items-center">
                        <Icon name="flag-ua" mainClass="h-4 w-4 rounded-full mr-2"/>         
                        {{ $trans('main.shared.languages.ua') }}
                      </div>
                    </a>
                  </MenuItem>
                </ul>
              </MenuItems>
            </Menu>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <!-- Desktop Short -->
  <div v-else="short" class="hidden divide-gray-200 dark:border-gray-750 lg:bg-white dark:lg:bg-gray-900 lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:block lg:w-20 lg:overflow-y-auto lg:pb-4 border-r">
    <div class="flex h-16 shrink-0 items-center justify-center">
      <img src="/dist/images/logo-short.svg" class=" mx-0.5 mt-5 h-8 w-auto dark:filter-white" alt="Atlant Logo"/>
    </div>
    <nav class="mt-8">
      <ul role="list" class="flex flex-col items-center space-y-1">
        <li v-for="item in navigation" :key="item.name">
          <Disclosure v-if="item.children && item.show" as="div" :defaultOpen="getTouch(item.name)" v-slot="{ open, close }">
            <DisclosureButton @click="setTouch(!open, item.name, close)" :class="[item.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'group flex justify-center items-center w-full text-left rounded-md p-3 gap-x-3 text-sm leading-6 font-semibold']">
              <component :is="item.icon" :class="[item.current ? 'text-primary-600' : 'group-hover:text-primary-600 dark:group-hover:text-white', 'text-gray-400 h-6 w-6 shrink-0']" aria-hidden="true"/>
            </DisclosureButton>
            <DisclosurePanel as="ul" class="space-y-2 py-2">
              <li v-for="subItem in item.children" :key="subItem.title">
                <Link v-if="subItem.show" as="a" :href="route(subItem.route)" :class="[subItem.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'flex justify-center uppercase rounded-md py-2 px-4 text-base font-bold leading-6']">
                  {{ $trans(subItem.title).substring(0, 1) }}
                </Link>
              </li>
            </DisclosurePanel>
          </Disclosure>
          <Link v-else-if="!item.children && item.show" as="a" :href="route(item.link)" :class="[item.current ? 'bg-primary-50 dark:bg-primary-950 text-primary-600 dark:text-primary-700' : 'text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-gray-600 dark:hover:text-white', 'group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold']">
            <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
            <span class="sr-only">
              {{ $trans(item.name) }}
            </span>
          </Link>
        </li>
        <li class="absolute pb-2.5 bottom-0 items-center space-y-2 flex flex-col spaxe-x-4 justify-evenly">
          <button @click="setShort(!short)" class="text-gray-500 hover:text-gray-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800 inline-flex justify-center p-2 rounded cursor-pointer">
            <ArrowRightCircleIcon class="w-6 h-6"/>
          </button>
          <button type="button" @click="darkMode(!dark)" class="text-gray-500 hover:text-gray-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800 inline-flex justify-center p-2 rounded cursor-pointer">
            <MoonIcon v-if="dark" class="w-6 h-6"/>
            <SunIcon v-else class="w-6 h-6"/>
          </button>
          <Menu>
            <MenuButton as="button" class="inline-flex justify-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white">
              <Icon :name="`flag-${language}`" mainClass="h-5 w-5 rounded-full mt-0.5"/>
            </MenuButton>
            <MenuItems as="div" 
              :style="[short ? 'transform: translate3d(-3px, -54px, 0px);' : 'transform: translate3d(162px, -66px, 0px);', 'position: absolute; inset: auto auto 0px 0px; margin: 0px;']"
              class="z-50 my-4 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-850">
              <ul class="py-1">
                <MenuItem as="li">
                  <a :href="!isCurrentLang('en') ? route('profile.language', {lang: 'en'}) : '#'" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white">
                    <div class="inline-flex items-center">
                      <Icon name="flag-en" :main-class="[!short && 'mr-2', 'h-4 w-4 rounded-full']"/>
                      <span v-if="!short">
                        {{ $trans('main.shared.languages.en') }}
                      </span>
                    </div>
                  </a>
                </MenuItem>
                <MenuItem as="li">
                  <a :href="!isCurrentLang('pl') ? route('profile.language', {lang: 'pl'}) : '#'" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white">
                    <div class="inline-flex items-center">
                      <Icon name="flag-pl" :main-class="[!short && 'mr-2', 'h-4 w-4 rounded-full']"/>
                      <span v-if="!short">
                        {{ $trans('main.shared.languages.pl') }}
                      </span>
                    </div>
                  </a>
                </MenuItem>
                <MenuItem as="li">
                  <a :href="!isCurrentLang('ua') ? route('profile.language', {lang: 'ua'}) : '#'" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white">
                    <div class="inline-flex items-center">       
                      <Icon name="flag-ua" :main-class="[!short && 'mr-2', 'h-4 w-4 rounded-full']"/>
                      <span v-if="!short">
                        {{ $trans('main.shared.languages.ua') }}
                      </span>
                    </div>
                  </a>
                </MenuItem>
              </ul>
            </MenuItems>
          </Menu>
        </li>
      </ul>
    </nav>
  </div>
  <div :class="short ? 'lg:pl-20' : 'lg:pl-72'">
    <Navbar>
      <button type="button" class="-m-2.5 p-2.5 text-gray-400 lg:hidden" @click="open = true">
        <span class="sr-only">Open sidebar</span>
        <Bars3Icon class="h-6 w-6" aria-hidden="true" />
      </button>
    </Navbar>
    <slot/>
  </div>
</template>
<script lang="ts">
import {
  DisclosureButton,
  DisclosurePanel,
  TransitionChild, 
  TransitionRoot,
  DialogPanel,
  Disclosure,
  MenuButton,
  MenuItems,
  MenuItem,
  Dialog,
  Menu,
} from '@headlessui/vue';
import {
  WrenchScrewdriverIcon,
  ArrowRightCircleIcon,
  ArrowLeftCircleIcon,
  ChartBarSquareIcon,
  CalendarDaysIcon,
  ViewColumnsIcon,
  Cog6ToothIcon,
  SignalIcon,
  WindowIcon,
  XMarkIcon,
  MoonIcon,
  HomeIcon,
  SunIcon,
} from '@heroicons/vue/24/outline';
import { 
  defineComponent, 
  onUnmounted,
  ComputedRef,
  onMounted,
  Component, 
  computed,
  reactive, 
  ref, 
} from 'vue';
import { ChevronRightIcon, Bars3Icon } from '@heroicons/vue/20/solid';
import { isPermitted, isCurrentRoute } from '@/mixins';
import { usePage, Link, Head } from '@inertiajs/vue3'
import Navbar from '@js/Shared/Navbar.vue';
import Project from '@/Types/App/Project';
import Icon from '@js/Shared/Icon.vue';
import Auth from '@/Types/App/Auth';
import { RouteName } from '@/ziggy';
import route from '@/ziggy';
import { Roles } from '@/enums';

type SideItem = { 
  icon: Component,
  name: string,
  link: RouteName,
  count?: number|ComputedRef<number>,
  children?: Array<Child>,
  show: boolean,
  current: boolean,
};

type Child = {
  route: RouteName,
  title: string,
  show: boolean,
  current: boolean,
}

export default defineComponent({
  props: {
    mainBackground: {
      type: String,
      default: 'bg-gray-50 dark:bg-gray-900',
    }
  },
  components: {
    WrenchScrewdriverIcon,
    ArrowRightCircleIcon,
    ArrowLeftCircleIcon,
    ChartBarSquareIcon,
    CalendarDaysIcon,
    ChevronRightIcon,
    DisclosureButton, 
    ViewColumnsIcon,
    DisclosurePanel,
    TransitionChild,
    TransitionRoot,
    Cog6ToothIcon,
    DialogPanel,
    Disclosure,
    SignalIcon,
    MenuButton,
    MenuItems,
    Bars3Icon,
    XMarkIcon,
    HomeIcon,
    MoonIcon,
    MenuItem,
    SunIcon,
    Navbar,
    Dialog,
    Link,
    Head,
    Icon,
    Menu,
  },
  setup() {
    // Layout
    const root: HTMLElement = reactive(document.documentElement as HTMLElement);
    const body: HTMLBodyElement = reactive(document.body as HTMLBodyElement);
    const dark = ref<boolean>(false);
    const localStorageDark: boolean = localStorage.getItem('theme') === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches);
    const localStorageShort: boolean = localStorage.getItem('sidebarShort') === 'true' || false;

    // Sidebar
    const open = ref<boolean>(false);
    const short = ref<boolean>(true);
    const language = computed<string>(() => usePage().props?.lang as string);
    const auth = computed<Auth>(() => usePage().props?.auth as Auth);
    const projects = computed<Array<Project>>(() => auth.value.projects as Array<Project>);

    const navigation: Array<SideItem> = [
      // { 
      //   icon: HomeIcon,
      //   name: 'main.side_menu.dashboards.title',
      //   current: isCurrentRoute(['dashboard']),
      //   show: true,
      //   link: '#',
      //   children: [
      //     {
      //       route: 'dashboard', 
      //       title: 'main.side_menu.dashboards.inside.main',
      //       current: isCurrentRoute(['dashboard']),
      //       show: true,
      //     }
      //   ]
      // },
      {
        icon: HomeIcon,
        name: "main.side_menu.boards.title",
        current: isCurrentRoute(['boards']),
        show: isPermitted([Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MODERATOR, Roles.USER]),
        link: 'boards',
      },
      {
        icon: WindowIcon,
        name: "main.side_menu.sites.title",
        link: 'sites',
        current: isCurrentRoute(['sites', 'sites.edit', 'sites.show']),
        show: isPermitted([Roles.SUPER_ADMIN, Roles.ADMIN]),
      },
      {
        icon: SignalIcon,
        name: "main.side_menu.activity.title",
        link: '#',
        show: isPermitted([Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MODERATOR]),
        current: isCurrentRoute(['logs', 'reports']),
        children: [
          {
            route: 'logs', 
            title: 'main.side_menu.activity.inside.logs',
            show: isPermitted([Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MODERATOR]),
            current: isCurrentRoute(['logs'])
          },
          {
            route: 'reports', 
            title: 'main.side_menu.activity.inside.reports',
            show: isPermitted([Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MODERATOR]),
            current: isCurrentRoute(['reports'])
          },
        ]
      },
      {
        icon: Cog6ToothIcon,
        name: "main.side_menu.settings.title",
        link: '#',
        show: isPermitted([Roles.SUPER_ADMIN, Roles.ADMIN]),
        current: isCurrentRoute(['users', 'system.permissions']),
        children: [
          {
            route: 'users', 
            title: 'main.side_menu.settings.inside.users',
            show: isPermitted([Roles.SUPER_ADMIN, Roles.ADMIN]),
            current: isCurrentRoute(['users'])
          },
        ]
      },
    ];
    
    function getShort(defaultValue: boolean = false): boolean
    {
      const value: string|null = localStorage.getItem('sidebarShort');

      return value == null ? defaultValue : value == "true";
    }

    function setShort(value: boolean): void
    {
      if(value){
        localStorage.setItem('sidebarShort', 'true');
      }else{
        localStorage.setItem('sidebarShort', 'false');
      }

      short.value = value;
    };

    function getTouch(name: string, defaultValue: boolean = false): boolean
    {
      const value: string|null = localStorage.getItem(`openSidebarTabs.${name}`);

      return value == null ? defaultValue : value == "true";
    }
    
    function setTouch(valueOpen: boolean, name: string, callback: Function): void
    {
      if(valueOpen){
        localStorage.setItem(`openSidebarTabs.${name}`, "true");
      }else{
        localStorage.setItem(`openSidebarTabs.${name}`, "false");
        callback();
      }
    }

    function isCurrentLang(lang: string): boolean
    {
      return language.value == lang;
    }

    function darkMode(value: boolean): void 
    {
      if(value){
        root.classList.add('dark');
        localStorage.setItem('theme', 'dark');
        dark.value = true;
      }else{
        root.classList.remove("dark");
        localStorage.setItem('theme', 'light');
        dark.value = false;
      }
    };

    onMounted(() => {
      dark.value = localStorageDark;
      short.value = localStorageShort;
      
      root.classList.add(dark.value ? 'dark' : 'light');
      body.classList.add('h-full');
    });

    onUnmounted(() => {
      root.classList.remove(dark.value ? 'dark' : 'light');
      body.classList.remove('h-full');

      dark.value = false;
    });

    return {
      isCurrentLang,
      navigation,
      setShort,
      darkMode,
      getTouch,
      setTouch,
      getShort,
      language,
      projects,
      route,
      short,
      open,
      dark,
    };
  },
})
</script>