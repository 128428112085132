import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "p-4 sm:p-5 flex-grow flex flex-col" }
const _hoisted_4 = { class: "flex items-center w-1/3 flex-grow space-x-2" }
const _hoisted_5 = { class: "flex items-center flex-shrink-0 space-x-2" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 0,
  class: "mt-2 bg-gray-200/90 dark:bg-gray-800/80 rounded-md px-2"
}
const _hoisted_8 = { class: "divide-y divide-gray-400/40 dark:divide-gray-600/50" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "flex-grow min-w-0 flex justify-between items-center board-no-drag" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "text-nano text-gray-500 dark:text-gray-400 whitespace-nowrap ml-2" }
const _hoisted_13 = { class: "bg-gray-50 dark:bg-gray-700/80 px-2 py-2 flex items-center justify-between text-sm text-gray-500 dark:text-gray-400 border-t border-gray-200 dark:border-gray-600" }
const _hoisted_14 = { class: "flex items-center" }
const _hoisted_15 = { class: "flex items-center" }
const _hoisted_16 = {
  key: 0,
  class: "flex flex-col flex-1 py-4 md:py-8 md:pt-0"
}
const _hoisted_17 = { class: "relative w-full" }
const _hoisted_18 = { class: "relative flex justify-center items-center py-8 md:py-12" }
const _hoisted_19 = { class: "flex flex-col justify-center items-center gap-2" }
const _hoisted_20 = { class: "text-sm text-gray-400 dark:text-gray-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Head = _resolveComponent("Head")!
  const _component_ClockIcon = _resolveComponent("ClockIcon")!
  const _component_UserGroupIcon = _resolveComponent("UserGroupIcon")!
  const _component_VueDraggableNext = _resolveComponent("VueDraggableNext")!
  const _component_FaceFrownIcon = _resolveComponent("FaceFrownIcon")!
  const _component_Layout = _resolveComponent("Layout")!
  const _component_Edit = _resolveComponent("Edit")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Head, null, {
      default: _withCtx(() => [
        _createElementVNode("title", null, _toDisplayString(_ctx.$trans('main.boards.titles.boards')), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.boards, ({ bg_path_medium }) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            bg_path_medium
              ? (_openBlock(), _createElementBlock("link", {
                  key: 0,
                  rel: "prefetch",
                  as: "image",
                  href: bg_path_medium
                }, null, 8, _hoisted_1))
              : _createCommentVNode("", true)
          ], 64))
        }), 256))
      ]),
      _: 1
    }),
    _createVNode(_component_Layout, {
      status: _ctx.status,
      statuses: _ctx.statuses,
      members: _ctx.members
    }, {
      default: _withCtx(() => [
        _createVNode(_component_VueDraggableNext, {
          class: "select-none isolate mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-5",
          onChange: _ctx.onBoardPositionChange,
          onStart: _ctx.onBoardDragStart,
          onEnd: _ctx.onBoardDragEnd,
          itemKey: "id",
          modelValue: _ctx.boards,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.boards) = $event)),
          group: { name: 'boards', pull: 'clone', put: false },
          sort: true,
          handle: ".drag-handle",
          filter: ".board-no-drag",
          animation: 200,
          delay: 50,
          easing: "cubic-bezier(0, 0.55, 0.45, 1)",
          ghostClass: "ghost",
          chosenClass: "sortable-board-chosen",
          dragClass: "sortable-drag",
          fallbackOnBody: false,
          fallbackClass: 'sortable-fallback',
          dragoverBubble: false,
          removeCloneOnHide: true,
          forceFallback: true,
          direction: "vertical",
          swapThreshold: 0.65,
          invertSwap: true,
          delayOnTouchOnly: true,
          fallbackTolerance: 3,
          touchStartThreshold: 3
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.boards, (board) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "drag-handle h-max sm:max-w-xs group bg-gray-50 dark:bg-gray-800/50 cursor-pointer overflow-hidden shadow-sm hover:shadow-md rounded-lg flex flex-col border border-gray-200 dark:border-gray-700 relative",
                style: _normalizeStyle(_ctx.getBoardBackgroundStyle(board)),
                onClick: _withModifiers(($event: any) => (_ctx.openBoard(board)), ["prevent"]),
                key: board.id
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", {
                    class: _normalizeClass([{'mb-3': board.three_latest_actions.length > 0}, "flex justify-between items-start"])
                  }, [
                    _createElementVNode("div", _hoisted_4, [
                      (board.color)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            style: _normalizeStyle(`background-color: ${board.color};`),
                            class: "min-w-1 min-h-5 rounded-full"
                          }, null, 4))
                        : _createCommentVNode("", true),
                      _createElementVNode("h3", {
                        class: _normalizeClass(["dark:text-white text-base sm:text-lg font-semibold truncate pr-8", board.bg_path_medium ? 'text-white' : 'text-gray-900'])
                      }, _toDisplayString(board.name), 3)
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("span", {
                        class: _normalizeClass(["text-sm font-medium px-2 py-0.5 rounded-full", {
                  'bg-green-100 dark:bg-green-500/10 text-green-700 dark:text-green-400': board.status === 'active',
                  'bg-yellow-100 dark:bg-yellow-400/10 text-yellow-700 dark:text-yellow-500': board.status === 'inactive',
                  'bg-gray-100 dark:bg-gray-400/10 text-gray-700 dark:text-gray-400': board.status === 'archived',
                }])
                      }, _toDisplayString(_ctx.$trans(`main.boards.statuses.${board.status}`)), 3),
                      _createElementVNode("button", {
                        onClick: _withModifiers(($event: any) => (_ctx.openEditBoard(board)), ["stop"]),
                        class: "p-1 bg-white dark:bg-gray-700 rounded-full shadow-md text-gray-400 hover:text-gray-600 dark:text-gray-300 dark:hover:text-gray-100"
                      }, _cache[2] || (_cache[2] = [
                        _createElementVNode("svg", {
                          class: "h-4 w-4",
                          fill: "none",
                          stroke: "currentColor",
                          viewBox: "0 0 24 24",
                          xmlns: "http://www.w3.org/2000/svg"
                        }, [
                          _createElementVNode("path", {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            d: "M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                          })
                        ], -1)
                      ]), 8, _hoisted_6)
                    ])
                  ], 2),
                  (board.three_latest_actions.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("ul", _hoisted_8, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(board.three_latest_actions, (action) => {
                            return (_openBlock(), _createElementBlock("li", {
                              onClick: _withModifiers(($event: any) => (_ctx.openBoardWithCard(board.id, action.card_id)), ["stop"]),
                              key: action.card_id,
                              class: "group/item flex items-center space-x-2 py-1.5 cursor-pointer"
                            }, [
                              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "flex-shrink-0 w-1.5 h-1.5 bg-blue-500 rounded-full" }, null, -1)),
                              _createElementVNode("div", _hoisted_10, [
                                _createElementVNode("p", {
                                  innerHTML: action.short_content,
                                  class: "text-gray-600 dark:text-gray-300 group-hover/item:text-gray-900 dark:group-hover/item:text-white text-tiny truncate"
                                }, null, 8, _hoisted_11),
                                _createElementVNode("p", _hoisted_12, _toDisplayString(action.created_at_diff), 1)
                              ])
                            ], 8, _hoisted_9))
                          }), 128))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("span", _hoisted_14, [
                    _createVNode(_component_ClockIcon, { class: "mr-1.5 h-3.5 w-3.5 stroke-2" }),
                    _createTextVNode(" " + _toDisplayString(board.last_action_diff), 1)
                  ]),
                  _createElementVNode("span", _hoisted_15, [
                    _createVNode(_component_UserGroupIcon, { class: "mr-1.5 h-3.5 w-3.5 stroke-2" }),
                    _createTextVNode(" " + _toDisplayString(board.members_count), 1)
                  ])
                ])
              ], 12, _hoisted_2))
            }), 128))
          ]),
          _: 1
        }, 8, ["onChange", "onStart", "onEnd", "modelValue"]),
        (!_ctx.boards.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createVNode(_component_FaceFrownIcon, { class: "text-gray-400 dark:text-gray-600 w-8 h-8" }),
                    _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$trans('main.boards.no_boards_found')), 1)
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["status", "statuses", "members"]),
    _createVNode(_component_Edit, {
      "is-open": _ctx.isOpenBoardEdit,
      board: _ctx.beindEditedBoard,
      members: _ctx.members,
      statuses: _ctx.statuses,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isOpenBoardEdit = false))
    }, null, 8, ["is-open", "board", "members", "statuses"])
  ], 64))
}