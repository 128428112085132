<template>
  <Link as="div" :href="route('main')">
    <img src="/dist/images/logo-text.svg" class="h-4 w-4 min-w-20" :class="[...logoColor]" alt="Atlant Logo"/>
  </Link>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Link } from '@inertiajs/vue3';
import route from '@/ziggy';

export default defineComponent({
  props: {
    logoColor: {
      type: Array as PropType<string[]>,
      default: ['dark:filter-white'],
    },
  },
  components: {
    Link
  },
  setup() {
    return { route };
  }
});
</script>