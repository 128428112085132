import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["content"]
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "flex min-h-full flex-col" }
const _hoisted_4 = { class: "relative flex-none overflow-hidden px-6 lg:pointer-events-none lg:fixed lg:inset-0 lg:z-40 lg:flex lg:px-0" }
const _hoisted_5 = { class: "relative flex w-full lg:pointer-events-auto lg:mr-[calc(max(2rem,50%-38rem)+40rem)] lg:min-w-[32rem] lg:overflow-y-auto lg:overflow-x-hidden lg:pl-[max(4rem,calc(50%-38rem))]" }
const _hoisted_6 = { class: "mx-auto max-w-lg lg:mx-0 lg:flex lg:w-96 lg:max-w-none lg:flex-col lg:before:flex-1 lg:before:pt-6" }
const _hoisted_7 = { class: "pb-16 pt-20 sm:pb-20 sm:pt-32 lg:py-20" }
const _hoisted_8 = { class: "relative" }
const _hoisted_9 = { class: "mt-10 font-display text-4xl/tight font-light text-white" }
const _hoisted_10 = { class: "mt-4 mb-10 text-sm/6 text-gray-300" }
const _hoisted_11 = { class: "flex flex-1 items-end justify-center pb-4 lg:justify-start lg:pb-6" }
const _hoisted_12 = { class: "flex items-baseline gap-x-2 text-[0.8125rem]/6 text-gray-500" }
const _hoisted_13 = {
  key: 0,
  viewBox: "0 0 24 24",
  "aria-hidden": "true",
  class: "h-6 w-6 fill-white opacity-50 transition-opacity group-hover:opacity-100 lg:fill-gray-900 lg:dark:fill-white"
}
const _hoisted_14 = {
  key: 1,
  viewBox: "0 0 24 24",
  "aria-hidden": "true",
  class: "h-6 w-6 fill-white opacity-50 transition-opacity group-hover:opacity-100 lg:fill-gray-900 lg:dark:fill-white"
}
const _hoisted_15 = { class: "relative flex-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Head = _resolveComponent("Head")!
  const _component_StarField = _resolveComponent("StarField")!
  const _component_Link = _resolveComponent("Link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Head, {
      title: `Atlant - ${_ctx.$trans('changelog.html_title')}`
    }, {
      default: _withCtx(() => [
        _createElementVNode("meta", {
          name: "theme-color",
          content: _ctx.dark ? '#0E1521' : '#F9FAFB'
        }, null, 8, _hoisted_1),
        _createElementVNode("link", {
          rel: "icon",
          type: "image/svg+xml",
          href: _ctx.dark ? '/favicon-dark.svg' : '/favicon.svg'
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }, 8, ["title"]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"absolute inset-0 -z-10 overflow-hidden bg-zinc-950 lg:right-[calc(max(2rem,50%-38rem)+40rem)] lg:min-w-[32rem]\"><svg class=\"absolute -bottom-48 left-[-40%] h-[80rem] w-[180%] lg:-right-40 lg:bottom-auto lg:left-auto lg:top-[-40%] lg:h-[180%] lg:w-[80rem]\" aria-hidden=\"true\"><defs><radialGradient id=\":S1:-desktop\" cx=\"100%\"><stop offset=\"0%\" stop-color=\"rgba(56, 189, 248, 0.3)\"></stop><stop offset=\"53.95%\" stop-color=\"rgba(0, 71, 255, 0.09)\"></stop><stop offset=\"100%\" stop-color=\"rgba(10, 14, 23, 0)\"></stop></radialGradient><radialGradient id=\":S1:-mobile\" cy=\"100%\"><stop offset=\"0%\" stop-color=\"rgba(56, 189, 248, 0.3)\"></stop><stop offset=\"53.95%\" stop-color=\"rgba(0, 71, 255, 0.09)\"></stop><stop offset=\"100%\" stop-color=\"rgba(10, 14, 23, 0)\"></stop></radialGradient></defs><rect width=\"100%\" height=\"100%\" fill=\"url(#:S1:-desktop)\" class=\"hidden lg:block\"></rect><rect width=\"100%\" height=\"100%\" fill=\"url(#:S1:-mobile)\" class=\"lg:hidden\"></rect></svg><div class=\"absolute inset-x-0 bottom-0 right-0 h-px bg-white mix-blend-overlay lg:left-auto lg:top-0 lg:h-auto lg:w-px\"></div></div>", 1)),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_StarField),
                _createElementVNode("div", null, [
                  _createVNode(_component_Link, {
                    as: "div",
                    href: _ctx.route('login')
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createElementVNode("img", {
                        src: "/dist/images/logo-text.svg",
                        class: "filter-white inline-block h-8 w-auto",
                        alt: "Atlant Logo"
                      }, null, -1)
                    ])),
                    _: 1
                  }, 8, ["href"])
                ]),
                _createElementVNode("h1", _hoisted_9, _toDisplayString(_ctx.$trans('changelog.title')), 1),
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$trans('changelog.description')), 1),
                _createVNode(_component_Link, {
                  as: "button",
                  href: _ctx.route('login'),
                  class: "rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$trans('changelog.buttons.go')), 1)
                  ]),
                  _: 1
                }, 8, ["href"])
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("p", _hoisted_12, [
                _createTextVNode(_toDisplayString(_ctx.$trans('changelog.created_by')) + " ", 1),
                _cache[2] || (_cache[2] = _createElementVNode("a", {
                  href: "https://pantheon.business",
                  target: "_blank",
                  class: "relative cursor-pointer rounded-md font-medium text-pink-600 dark:text-primary-600 hover:text-pink-500 dark:hover:text-primary-500"
                }, " Pantheon Software ", -1))
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.darkMode(!_ctx.dark))),
        type: "button",
        class: "group absolute right-4 top-4 z-50 -m-2.5 p-2.5"
      }, [
        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "sr-only" }, "Switch to light theme", -1)),
        (_ctx.dark)
          ? (_openBlock(), _createElementBlock("svg", _hoisted_13, _cache[4] || (_cache[4] = [
              _createElementVNode("path", {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm-5-8a5 5 0 0 0 5 5V7a5 5 0 0 0-5 5Z"
              }, null, -1)
            ])))
          : (_openBlock(), _createElementBlock("svg", _hoisted_14, _cache[5] || (_cache[5] = [
              _createElementVNode("path", {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm-5-8a5 5 0 0 0 5 5V7a5 5 0 0 0-5 5Z"
              }, null, -1)
            ])))
      ]),
      _createElementVNode("div", _hoisted_15, [
        _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"pointer-events-none absolute inset-0 z-50 overflow-hidden lg:right-[calc(max(2rem,50%-38rem)+40rem)] lg:min-w-[32rem] lg:overflow-visible\"><svg class=\"absolute left-[max(0px,calc(50%-18.125rem))] top-0 h-full w-1.5 lg:left-full lg:ml-1 xl:left-auto xl:right-1 xl:ml-0\" aria-hidden=\"true\"><defs><pattern id=\":S4:\" width=\"6\" height=\"8\" patternUnits=\"userSpaceOnUse\"><path d=\"M0 0H6M0 8H6\" class=\"stroke-sky-900/10 xl:stroke-white/10 dark:stroke-white/10\" fill=\"none\"></path></pattern></defs><rect width=\"100%\" height=\"100%\" fill=\"url(#:S4:)\"></rect></svg></div>", 1)),
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ], 64))
}