import { useForm as usePrecognitionForm } from 'laravel-precognition-vue-inertia';
import { RequestMethod, ValidationConfig } from 'laravel-precognition';
import { default as UserHandle } from '@/Types/Resources/User/Handle';
import { computed, onMounted, onUnmounted, ref } from "vue";
import { HttpStatusCode } from '@/Types/API/HttpStatusCode';
import { Channel, ChannelEvents } from '../socket/channels';
import { routeMethod, RouteName } from "./ziggy";
import { RouteParams } from "./Types/App/ziggy";
import { default as ziggyRoute } from "./ziggy";
import worldData from '@js/data/countries.json';
import axios, { AxiosResponse } from 'axios';
import Country from '@/Types/App/Country';
import { usePage } from '@inertiajs/vue3';
import Handle from '@/Types/App/Handle';
import Auth from "@/Types/App/Auth";

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export const useBreakpoints = () => {
  const windowWidth = ref(window.innerWidth);

  const onWidthChange = () => windowWidth.value = window.innerWidth;
  onMounted(() => window.addEventListener('resize', onWidthChange));
  onUnmounted(() => window.removeEventListener('resize', onWidthChange));
  
  const breakpoint = computed((): Breakpoint => {
    if (windowWidth.value < 640) return 'xs';
    if (windowWidth.value < 768) return 'sm';
    if (windowWidth.value < 1024) return 'md';
    if (windowWidth.value < 1280) return 'lg';
    if (windowWidth.value < 1536) return 'xl';
    return '2xl';
  });

  const width = computed(() => windowWidth.value);

  const isBreakpoint = (bp: Breakpoint) => breakpoint.value === bp;

  return { 
    width, 
    breakpoint,
    isBreakpoint,
    isXs: computed(() => isBreakpoint('xs')),
    isSm: computed(() => isBreakpoint('sm')),
    isMd: computed(() => isBreakpoint('md')),
    isLg: computed(() => isBreakpoint('lg')),
    isXl: computed(() => isBreakpoint('xl')),
    is2Xl: computed(() => isBreakpoint('2xl')),
  };
}

export function isPermitted(roles: Array<string>): boolean
{
  const auth: Auth = usePage().props?.auth as Auth;
  const user: UserHandle = auth.user as UserHandle;

  return roles.includes(user.role.value);
}

export function getLocale(): string
{
  return usePage().props.lang as string;
}


// export function useForm(method: RequestMethod | (() => RequestMethod), url: string | (() => string), inputs: Record<string, unknown>, config?: ValidationConfig)
// {
//   return usePrecognitionForm(method, url, {
//     _token: getApiToken(),
//     ...inputs,
//   }, config);
// }

export function isMac(): boolean 
{
  try {
    return CSS.supports('-webkit-appearance', 'textfield');
  } catch (error) {
    console.error('Error detecting Mac:', error);
    return false;
  }
}

export function isCurrentRoute(routes: Array<string>, withURI: boolean = false): boolean
{
  let route = usePage().props?.route as Handle['route'];
  let routeName = route.name as RouteName;
  let routeURI = route.uri as string;
  
  return withURI 
    ? routeURI === routes[0]
    : routes.includes(routeName as string);
}

export const truncateText = (text?: string | null, length?: number): string => {
  if (!text) return '';

  const maxLength = length ?? Math.floor(text.length / 2);
  const truncated = text.slice(0, maxLength);

  return text.length > maxLength ? `${truncated}...` : truncated;
}

export const truncateHTML = (html: string, maxLength: number): string => {
  const el = document.createElement('div');
  el.innerHTML = html;
  
  function truncate(node: Node, length: number): number {
    if (node.nodeType === Node.TEXT_NODE) {
      const text = node.textContent || '';
      if (text.length > length) {
        (node as Text).data = text.slice(0, length) + '...';
        return 0;
      }
      return length - text.length;
    }
    
    if (node.nodeType === Node.ELEMENT_NODE) {
      let remaining = length;
      const childNodes = Array.from(node.childNodes);
      for (const child of childNodes) {
        remaining = truncate(child, remaining);
        if (remaining <= 0) {
          while (child.nextSibling) {
            node.removeChild(child.nextSibling);
          }
          break;
        }
      }
      return remaining;
    }
    
    return length;
  }
  
  truncate(el, maxLength);
  return el.innerHTML;
}

export function findCountryByIsoCode(isoCode: string|undefined|null): Country | null
{
  return isoCode != null  || isoCode != undefined ? (worldData.find(function (country: Country): boolean
  {
    (country: Country) => country.iso2 === isoCode || country.iso3 === isoCode

    return country.iso2 === isoCode || country.iso3 === isoCode;
  }) || null) : null;
}

export function socketChannelNameBuilder(channel: Channel, id: number | string): string
{
  
  const redisPrefix = process.env.MIX_SOCKET_REDIS_PREFIX;
  const event = ChannelEvents[channel];

  if (!event) throw new Error(`Event not found for channel: ${channel}`);
  
  return `${redisPrefix}${channel}.${id}:${event}`;
}

export function ignoreSpace(e: KeyboardEvent): void
{
  const allowedHeadlessUIKeys = [
      "ArrowUp",
      "ArrowDown",
      "Enter",
      "Space",
      "Home",
      "End",
      "Escape",
  ];
  
  if (!allowedHeadlessUIKeys.includes(e.key)) e.stopPropagation();
}

export function getApiToken(): string
{
  return ((usePage().props?.auth as Auth).user as UserHandle).token as string;
}

export function getAppName(): string
{
  return process.env.MIX_APP_NAME as string;
}

export function sendRequest<T = any>(
  route: {
    name: RouteName,
    params?: RouteParams<string & {}>,
    data?: any,
    sync_token?: string|null
  },
  callback?: (response: T) => void,
  failCallback?: (error: any) => void
): any
{
  axios.request<T>({
    method: routeMethod(route.name),
    url: ziggyRoute(route.name, route?.params as RouteParams<string & {}>),
    data: route.data,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getApiToken()}`,
      'X-Sync-Token': route.sync_token ?? ''
    }
  }).then((response: AxiosResponse<T>) => {
    if((response.status as HttpStatusCode) === 200 && callback){
      callback(response.data as T);
    }
  }).catch(error => {
    if(process.env.MIX_APP_ENV !== 'prod') console.error('Error:', error);

    if(failCallback) failCallback(error.response.data.errors);
  });
}