<template>
  <div class="absolute z-50 w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg max-h-60 overflow-auto ring-1 ring-black ring-opacity-5 focus:outline-none">
    <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
      <template v-if="items.length">
        <button v-for="(item, index) in items"
          :key="index"
          @click="selectItem(index)"
          class="text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:bg-gray-100 dark:focus:bg-gray-700 w-full text-left px-2 py-1 text-sm"
          :class="{ 
            'bg-gray-100 dark:bg-gray-700': index === selectedIndex,
            'text-primary-600 dark:text-primary-400': item.is_current_user
          }"
          role="menuitem">
          <span class="flex items-center">
            <UserAvatar :user="item" size="xs" :withTooltip="false"/>
            <span class="ml-3">
              {{ item.name }}
            </span>
          </span>
        </button>
      </template>
      <div v-else class="text-center px-4 py-2 text-sm text-gray-500 dark:text-gray-400">
        {{ $trans('main.boards.no_members_found') }}
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { default as UserMember } from '@/Types/Resources/User/Member';
import UserAvatar from '@/Shared/UserAvatar.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    UserAvatar
  },
  props: {
    items: {
      type: Array as () => Array<UserMember>,
      required: true,
    },
    command: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selectedIndex: 0,
    }
  },
  watch: {
    items() {
      this.selectedIndex = 0
    },
  },
  methods: {
    onKeyDown({ event }: { event: KeyboardEvent }) {
      if(event.key === 'ArrowUp'){
        this.upHandler()
        return true
      }
      if(event.key === 'ArrowDown'){
        this.downHandler()
        return true
      }
      if(event.key === 'Enter'){
        this.enterHandler()
        return true
      }
      return false
    },
    upHandler() {
      this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length
    },
    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length
    },
    enterHandler() {
      this.selectItem(this.selectedIndex)
    },
    selectItem(index: number): void
    {
      const item = this.items[index]
      if (item && item.name) {
        this.command({
          id: item.id,
          name: String(item.name),
          label: String(item.name),
          is_current_user: Boolean(item.is_current_user)
        })
      }
    }
  },
})
</script>