import { default as LightAction } from '@/Types/Resources/Action/Light';
import type { updateLightCardFunction } from '@/Pages/Boards/Types';
import { default as LightCard } from '@/Types/Resources/Card/Light';
import { default as MicroCard } from '@/Types/Resources/Card/Micro';
import { PayloadResponse } from '@/Types/API/PayloadResponse';
import { useForm } from 'laravel-precognition-vue-inertia';
import { ref, Ref, nextTick, computed , watch} from 'vue';
import Comment from '@/Types/Resources/Comment/Light';
import { getApiToken, sendRequest } from '@/mixins';
import { throttle } from 'lodash';

import route from '@/ziggy';

type CreateComment = {
  _token: string;
  b_c_c_content: string;
  b_c_c_creator_id: string|number|null;
};

type EditComment = {
  _token: string;
  _comment_id: string|number|null;
  b_c_c_content: string;
};

export function useCommentsAndActions(card: Ref<LightCard|null>, userId: number|undefined, updateLightCard: updateLightCardFunction, handleCopyMicroCard: Function)
{
  const actions = ref<Array<LightAction>>([]);
  const actionsAndComments = computed<Array<LightAction | Comment>>(() => {
    return [...card.value?.comments?.map(comment => ({ ...comment, type: 'comment' })) ?? [],
            ...actions.value?.map((action: LightAction) => ({ ...action, type: 'action' })) ?? []].sort((a, b) => {
              const dateA = new Date(a.created_at);
              const dateB = new Date(b.created_at);

              return dateB.getTime() - dateA.getTime();
            }) as Array<LightAction | Comment>;
  });

  const actionsIsLoading = ref<boolean>(false);
  const commentToDelete = ref<Comment|null>(null);
  const isDeleteCommentModalVisible = ref<boolean>(false);
  const newCommentForm = useForm('post', () => route('api.v1.comments.store', {
    card: card.value?.id
  }), {
    _token: getApiToken(),
    b_c_c_content: "",
    b_c_c_creator_id: userId,
  } as CreateComment);

  const editCommentForm = useForm('put', () => route('api.v1.comments.update', {
    card: card.value?.id
  }), {
    _token: getApiToken(),
    _comment_id: null,
    b_c_c_content: "",
  } as EditComment);

  watch(() => newCommentForm.b_c_c_content, (newValue) => {
    if(newCommentForm.errors.b_c_c_content !== newValue) {
      newCommentForm.clearErrors('b_c_c_content');
    }
  });

  watch(() => editCommentForm.b_c_c_content, (newValue) => {
    if(editCommentForm.errors.b_c_c_content !== newValue) {
      editCommentForm.clearErrors('b_c_c_content');
    }
  });

  function submitNewComment(): void
  {
    sendRequest({
      name: 'api.v1.comments.store',
      params: { card: card.value?.id },
      data: {
        b_c_c_content: newCommentForm.b_c_c_content,
        b_c_c_creator_id: newCommentForm.b_c_c_creator_id,
      },
    }, (data: PayloadResponse<LightCard>) => {
      updateLightCard(data.payload);
      resetCreateCommentForm();
    }, (errors) => {
      newCommentForm.setErrors(errors);
    });
  }

  function submitEditComment(): void
  {
    sendRequest({
      name: 'api.v1.comments.update',
      params: { card: card.value?.id },
      data: {
        _comment_id: editCommentForm._comment_id,
        b_c_c_content: editCommentForm.b_c_c_content,
      },
    }, (data: PayloadResponse<LightCard>) => {
      updateLightCard(data.payload);
      closeEditCommentForm();
    }, (errors) => {
      editCommentForm.setErrors(errors);
    });
  }

  function resetCreateCommentForm(): void
  {
    newCommentForm.b_c_c_content = '';
    newCommentForm.b_c_c_creator_id = userId ?? null;

    newCommentForm.reset();
    newCommentForm.clearErrors();
  }

  function openEditCommentForm(comment: Comment): void
  {
    editCommentForm._comment_id = comment.id;
    editCommentForm.b_c_c_content = comment.content;
    
    nextTick(() => {
      const textarea: HTMLTextAreaElement | null = document.getElementById('edit-comment-area') as HTMLTextAreaElement;

      textarea?.focus();
      textarea?.select();
    });
  }

  function closeEditCommentForm(): void
  {
    editCommentForm._comment_id = null;
    editCommentForm.b_c_c_content = '';

    editCommentForm.reset();
    editCommentForm.clearErrors();
  }

  function openDeleteCommentModal(comment: Comment): void
  {
    commentToDelete.value = comment;
    isDeleteCommentModalVisible.value = true;
  }

  function closeDeleteCommentModal(): void
  {
    commentToDelete.value = null;
    isDeleteCommentModalVisible.value = false;
  }

  function removeComment(): void
  {
    updateLightCard({
      ...card.value,
      comments: card.value?.comments?.filter(c => c.id !== commentToDelete.value?.id),
    } as LightCard);

    closeDeleteCommentModal();
  }

  function copyCard(): void
  {
    sendRequest({
      name: 'api.v1.boards.cards.copy',
      params: { card: card.value?.id },
    }, (data: PayloadResponse<MicroCard>) => handleCopyMicroCard(data.payload));
  }

  function getActions(): void
  {
    sendRequest({
      name: 'api.v1.actions',
      params: { card: card.value?.id },
    }, (data: PayloadResponse<Array<LightAction>>) => updateLightCard({...card.value, actions: data.payload} as LightCard));
  }

  function updateActions(newActions: Array<LightAction>): void
  {
    actions.value = newActions;
  }

  function updateActionsVisibility(value: boolean): void
  {
    updateLightCard({
      ...card.value,
      actions_visible: value,
    } as LightCard);
  }

  const throttledToggleActions = throttle(async (): Promise<void> =>
  {
    if (actionsIsLoading.value) return;

    let actionsIsVisible = card.value?.actions_visible ?? null;

    if (actionsIsVisible === true) {
      updateActionsVisibility(false);
    }
    actionsIsLoading.value = !actionsIsVisible;

    try {
      const data: PayloadResponse<Array<LightAction>> = await new Promise((resolve, reject) => {
        sendRequest({
          name: `api.v1.actions.${actionsIsVisible ? 'hide' : 'show'}`,
          params: { card: card.value?.id },
        }, resolve, reject);
      });
      updateActionsVisibility(actionsIsVisible ? false : true);
      updateActions(data.payload ?? []);
    } catch (error) {
      console.error('Error toggling actions:', error);
      updateActionsVisibility(actionsIsVisible ?? false);
    } finally {
      actionsIsLoading.value = false;
    }
  }, 1000);

  return {
    isDeleteCommentModalVisible,
    throttledToggleActions,
    openDeleteCommentModal,
    closeEditCommentForm,
    openEditCommentForm,
    actionsAndComments,
    submitEditComment,
    actionsIsLoading,
    submitNewComment,
    editCommentForm,
    commentToDelete,
    newCommentForm,
    updateActions,
    removeComment,
    getActions,
    copyCard,
    actions
  };
}