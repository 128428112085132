import { App, reactive } from 'vue';

interface LocalizationOptions {
  lang: string;
  resolve: (lang: string) => Promise<Record<string, string>>;
}

interface LocalizationPlugin {
  currentLang: string;
  translations: Record<string, string>;
  trans: TransFunction;
}

export type TransFunction = (key: string) => string;

export function createLocalizationPlugin(options: LocalizationOptions) {
    const state = reactive({
      currentLang: options.lang,
      translations: {},
    });
  
    async function load(lang: string){ state.translations = await options.resolve(lang); }
    function trans(key: string): string { return state.translations[key] || key; }
  
    load(options.lang); 

    return {
        get currentLang() { return state.currentLang; },
        set currentLang(lang: string) { state.currentLang = lang; },
        trans,
        changeLanguage: async (lang: string) => {
          state.currentLang = lang;
          await load(lang);
        }
    };
}

export async function loadTranslations(lang: string): Promise<Record<string, string>> {
  const appName: string = process.env.MIX_APP_NAME || 'atlant';
  const appVersion: string = process.env.MIX_APP_VERSION || '0.0.1';
  
  const cacheKey = `${appName}_${appVersion}_${lang}`;
  const cachedTranslations = localStorage.getItem(cacheKey);
  
  if (cachedTranslations) {
    return JSON.parse(cachedTranslations);
  }
  
  try {
    const module = await import(`@lang/${lang}.json`);
    const translations = module.default;
    localStorage.setItem(cacheKey, JSON.stringify(translations));
    return translations;
  } catch (error) {
    console.error(`Failed to load translations for lang: ${lang}`, error);
    return {};
  }
}


// export async function loadTranslations(lang: string): Promise<Record<string, string>>
// {
//   try {
//     // Динамически загружаем файл переводов для указанного языка
//     const module = await import(`@lang/${lang}.json`);
//     const translations = module.default;

//     // Подсчитываем количество ключей в объекте переводов
//     const keysCount = Object.keys(translations).length;

//     // Формируем уникальный ключ кэша на основе языка и количества ключей
//     const cacheKey = `translations_${lang}_${keysCount}`;

//     // Проверяем, есть ли уже такие переводы в localStorage
//     const cachedTranslations = localStorage.getItem(cacheKey);
//     if (cachedTranslations) {
//       return JSON.parse(cachedTranslations);
//     }

//     // Сохраняем новые переводы в localStorage
//     localStorage.setItem(cacheKey, JSON.stringify(translations));
//     return translations;
//   } catch (error) {
//     console.error(`Failed to load translations for lang: ${lang}`, error);
//     return {};
//   }
// }

export default
{
    install(app: App, options: LocalizationOptions) {
      const localizationPlugin = createLocalizationPlugin(options);
      app.config.globalProperties.$trans = localizationPlugin.trans;
    }
};