<template>
  <Layout>
    <div class="md:flex max-w-none mx-auto">
      <div class="flex-1 flex-col relative">
        <div class="flex items-center justify-between gap-2 p-4 md:p-8 pb-0">
          <div class="hidden md:flex items-center justify-between text-sm rounded-lg">
            <nav>
              <ul class="flex gap-1">
                <li v-for="status in statuses" class="inline-block">
                  <Link class="flex items-center gap-2 px-3.5 py-1.5 rounded-md cursor-pointer"
                    :href="route('boards', { status })"
                    :class="{'bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-200': isCurrentRoute([route('boards', status)], true),
                            'text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-gray-800 dark:hover:text-gray-200': !isCurrentRoute([route('boards', status)], true)}">
                    {{ $trans(`main.boards.statuses_names.${status}`) }}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div class="md:hidden relative flex justify-center">
            <Combobox as="div" v-model="selectedStatus">
              <div class="relative mt-2">
                <ComboboxInput @change="redirectToStatus" hidden :display-value="(status) => $trans(`main.boards.statuses.${status}`)"/>
                <ComboboxButton type="button">
                  <div class="flex items-center justify-between bg-gray-100 dark:bg-gray-800 dark:text-gray-200 px-3.5 py-1.5 text-sm rounded-lg font-medium">
                    <span>
                      {{ $trans(`main.boards.statuses.${selectedStatus}`) }}
                    </span>
                    <ChevronUpDownIcon class="w-4 ml-1 text-gray-500 dark:text-gray-400" aria-hidden="true"/>
                  </div>
                </ComboboxButton>
                <ComboboxOptions v-if="statuses.length > 0" class="bg-white/95 dark:bg-gray-800 absolute z-50 top-[2.4rem] left-0 w-32 rounded-lg backdrop-blur drop-shadow-2xl focus:outline-none select-none transform opacity-100 scale-100">
                  <ComboboxOption v-for="(status, index) in statuses" :key="`${status}-${index}`" :value="status" as="template" v-slot="{ active, selected }">
                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-3 text-sm', active ? 'text-gray-900 dark:text-white' : 'text-gray-900 dark:text-white']">
                    <!-- <li class="relative cursor-default select-none py-2 pl-3 pr-9 text-sm text-gray-900"> -->
                      <span :class="['block truncate', selected && 'font-semibold']">
                        {{ $trans(`main.boards.statuses.${status}`) }}
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </div>
            </Combobox>
          </div>
          <!-- <div class="md:hidden relative flex justify-center">
            <button aria-label="layer-actions" type="button" aria-haspopup="menu" aria-expanded="false" data-headlessui-state="">
              <div class="flex items-center justify-between bg-slate-100 dark:bg-zinc-800 dark:text-zinc-200 px-3.5 py-1.5 text-sm rounded-lg font-medium">
                <span class="capitalize">trending</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="w-4 ml-1 text-slate-500 dark:text-zinc-400">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"></path>
                </svg>
              </div>
            </button>
          </div> -->
          <div class="flex gap-2">
            <div class="flex items-center bg-slate-100 dark:bg-zinc-800 dark:text-zinc-200 text-sm rounded-lg divide-x-4 divide-white dark:divide-zinc-900">
              <Button :text="$trans('main.boards.buttons.create_board')" type="button" size="sm" @click="openCreateDrawer"/>
            </div>
          </div>
        </div>
        <div class="flex flex-col flex-1 p-4 md:p-8 md:pt-0">
          <div class="relative w-full">
            <slot/>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <Create 
    :is-open="createDrawerIsOpen"
    :members="members"
    :statuses="statuses"
    :selected-status="selectedStatus"
    @close="createDrawerIsOpen = false"/>
</template>
<script lang="ts">
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import { default as Member } from '@/Types/Resources/User/Member';
import { default as Board } from '@/Types/Resources/Board/Base';
import { defineComponent, ref, watch } from 'vue';
import Create from '@/Pages/Boards/Create.vue';
import { Link, router } from '@inertiajs/vue3';
import { isCurrentRoute } from '@/mixins';
import Layout from '@/Shared/Layout.vue';
import Button from '@/Shared/Button.vue';
import Sidebar from './Sidebar.vue';
import route from '@/ziggy';

export default defineComponent({
  components: {
    ChevronUpDownIcon,
    ComboboxOptions,
    ComboboxOption,
    ComboboxButton,
    ComboboxInput,
    CheckIcon,
    Combobox,
    Sidebar,
    Layout,
    Button,
    Create,
    Link
  },
  props: {
    status: {
      type: String as () => 'active' | 'inactive' | 'archived',
      required: true
    },
    statuses: {
      type: Array as () => Array<string>,
      required: true
    },
    members: {
      type: Array as () => Array<Member>,
      required: true
    }
  },
  setup(props){
    const selectedStatus = ref<'active' | 'inactive' | 'archived'>(props.status);
    const createDrawerIsOpen = ref<boolean>(false);
    const editDrawerIsOpen = ref<boolean>(false);
    const editBoard = ref<Board | null>(null);

    watch(() => selectedStatus.value, () => {
      redirectToStatus();
    });

    function redirectToStatus(): void
    {
      router.get(route('boards', { status: selectedStatus.value }));
    }

    function openCreateDrawer(): void
    {
      createDrawerIsOpen.value = true;
    }

    return {
      createDrawerIsOpen,
      openCreateDrawer,
      editDrawerIsOpen,
      redirectToStatus,
      isCurrentRoute,
      selectedStatus,
      editBoard,
      route
    };
  }
})
</script>