<template>
  <Head :title="$trans('main.reset_password_title')"/>
  <Layout>
    <div class="relative flex flex-1 flex-col items-center justify-center pb-16 pt-12">
      <Logo/>
      <h1 class="sr-only" v-text="$trans('main.reset_password_title')" />
      <form @submit.prevent="submit" class="w-full max-w-sm sm:bg-white sm:dark:bg-gray-900 sm:border-gray-100 sm:dark:border-gray-750 sm:p-5 sm:border-2 sm:bg-opacity-80 sm:backdrop-blur-sm sm:backdrop-filter sm:rounded-md">
        <div class="mb-6">
          <Input id="password" 
            type="password"
            name="password"
            label="main.password"
            v-model="form.password"
            :with-error="false"
            :required="true"
            :form="form as any"/>
        </div>
        <div class="mb-6">
          <Input id="password_confirmation" 
            type="password"
            name="password_confirmation"
            label="main.password_confirmation"
            v-model="form.password_confirmation"
            :with-error="false"
            :required="true"
            :form="form as any"/>
        </div>
				<div v-if="form.hasErrors" >
					<p v-for="error in form.errors" class="mt-2 text-center text-mini font-medium text-red-600 dark:text-red-500">
						{{ error }}
					</p>
				</div>
        <Submit main-class="mt-4" 
          title="main.reset_password_action"
          :processing="form.processing"
          :disabled="form.invalid('password') || 
                    form.invalid('password_confirmation')"/>
      </form>
    </div>
    <footer class="relative shrink-0">
      <div class="space-y-4 text-sm text-gray-900 sm:flex sm:items-center sm:justify-center sm:space-x-4 sm:space-y-0">
        <div class="flex justify-center font-medium text-gray-500 dark:text-gray-400">
          <p v-text="$trans('main.already_have') " class="text-center sm:text-left"/>
          <br/>
          <Link as="a" :href="route('login')" class="text-primary-700 hover:underline dark:text-primary-500">
            {{ $trans('main.login_here') }}
          </Link>
        </div>
      </div>
    </footer>
  </Layout>
</template>
<script lang="ts">
import { useForm } from 'laravel-precognition-vue-inertia';
import { Head, Link, usePage } from '@inertiajs/vue3';
import { defineComponent, computed } from "vue";
import Layout from './Components/Layout.vue';
import Submit from './Components/Submit.vue';
import Input from './Components/Input.vue';
import Logo from './Components/Logo.vue';
import route from '@/ziggy';

export default defineComponent({
  components: {
    Layout,
    Submit,
    Input,
    Logo,
    Head,
    Link,
  },
  setup () {
    const token = computed(() => usePage().props?.token as string);
    const form = useForm('post', route('reset.store_confirmation', token.value), {
      password: '',
      password_confirmation: ''
    });
    
    const submit = () => form.submit({
      preserveScroll: true,
      onSuccess: () => form.reset(),
    });
    
    return {
      submit, 
      route,
      token,
      form,
    };
  }
});
</script>