import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["content"]
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "fixed inset-0 flex" }
const _hoisted_4 = { class: "absolute left-full top-0 flex w-16 justify-center pt-5" }
const _hoisted_5 = { class: "bg-white dark:bg-gray-900 border-gray-200 dark:border-gray-800 flex grow border-r flex-col gap-y-5 overflow-y-auto px-6 pb-4" }
const _hoisted_6 = { class: "flex flex-1 flex-col" }
const _hoisted_7 = {
  role: "list",
  class: "flex flex-1 flex-col gap-y-7"
}
const _hoisted_8 = { class: "text-mini my-2 font-semibold leading-6 text-gray-400" }
const _hoisted_9 = {
  role: "list",
  class: "-mx-2 space-y-1"
}
const _hoisted_10 = {
  key: 0,
  class: "bg-white dark:bg-gray-900 text-gray-600 dark:text-white ring-gray-200 dark:ring-gray-700 ml-auto w-9 min-w-max whitespace-nowrap rounded-full px-2.5 py-0.5 text-center text-nano font-medium leading-5 ring-1 ring-inset",
  "aria-hidden": "true"
}
const _hoisted_11 = { class: "text-mini my-2 font-semibold leading-6 text-gray-400" }
const _hoisted_12 = {
  role: "list",
  class: "-mx-2 mt-2 space-y-1"
}
const _hoisted_13 = { class: "truncate" }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = {
  href: "#",
  class: "text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
}
const _hoisted_16 = { class: "truncate" }
const _hoisted_17 = { class: "mt-auto flex justify-evenly" }
const _hoisted_18 = { class: "py-1" }
const _hoisted_19 = ["href"]
const _hoisted_20 = { class: "inline-flex items-center" }
const _hoisted_21 = ["href"]
const _hoisted_22 = { class: "inline-flex items-center" }
const _hoisted_23 = ["href"]
const _hoisted_24 = { class: "inline-flex items-center" }
const _hoisted_25 = {
  key: 0,
  class: "hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col"
}
const _hoisted_26 = { class: "bg-white dark:bg-gray-900 border-gray-200 dark:border-gray-800 flex grow flex-col gap-y-5 overflow-y-auto border-r px-6 pb-4" }
const _hoisted_27 = { class: "flex flex-1 flex-col" }
const _hoisted_28 = {
  role: "list",
  class: "flex flex-1 flex-col gap-y-7"
}
const _hoisted_29 = { class: "text-mini my-2 font-semibold leading-6 text-gray-400" }
const _hoisted_30 = {
  role: "list",
  class: "-mx-2 space-y-1"
}
const _hoisted_31 = {
  key: 0,
  class: "bg-white dark:bg-gray-900 text-gray-600 dark:text-white ring-gray-200 dark:ring-gray-700 ml-auto w-9 min-w-max whitespace-nowrap rounded-full px-2.5 py-0.5 text-center text-nano font-medium leading-5 ring-1 ring-inset",
  "aria-hidden": "true"
}
const _hoisted_32 = { class: "text-mini my-2 font-semibold leading-6 text-gray-400" }
const _hoisted_33 = {
  role: "list",
  class: "-mx-2 mt-2 space-y-1"
}
const _hoisted_34 = { class: "truncate" }
const _hoisted_35 = { key: 1 }
const _hoisted_36 = {
  href: "#",
  class: "text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
}
const _hoisted_37 = { class: "truncate" }
const _hoisted_38 = { class: "mt-auto flex justify-evenly" }
const _hoisted_39 = { class: "py-1" }
const _hoisted_40 = ["href"]
const _hoisted_41 = { class: "inline-flex items-center" }
const _hoisted_42 = ["href"]
const _hoisted_43 = { class: "inline-flex items-center" }
const _hoisted_44 = ["href"]
const _hoisted_45 = { class: "inline-flex items-center" }
const _hoisted_46 = {
  key: 1,
  class: "hidden divide-gray-200 dark:border-gray-750 lg:bg-white dark:lg:bg-gray-900 lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:block lg:w-20 lg:overflow-y-auto lg:pb-4 border-r"
}
const _hoisted_47 = { class: "mt-8" }
const _hoisted_48 = {
  role: "list",
  class: "flex flex-col items-center space-y-1"
}
const _hoisted_49 = { class: "sr-only" }
const _hoisted_50 = { class: "absolute pb-2.5 bottom-0 items-center space-y-2 flex flex-col spaxe-x-4 justify-evenly" }
const _hoisted_51 = { class: "py-1" }
const _hoisted_52 = ["href"]
const _hoisted_53 = { class: "inline-flex items-center" }
const _hoisted_54 = { key: 0 }
const _hoisted_55 = ["href"]
const _hoisted_56 = { class: "inline-flex items-center" }
const _hoisted_57 = { key: 0 }
const _hoisted_58 = ["href"]
const _hoisted_59 = { class: "inline-flex items-center" }
const _hoisted_60 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Head = _resolveComponent("Head")!
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon")!
  const _component_DisclosureButton = _resolveComponent("DisclosureButton")!
  const _component_Link = _resolveComponent("Link")!
  const _component_DisclosurePanel = _resolveComponent("DisclosurePanel")!
  const _component_Disclosure = _resolveComponent("Disclosure")!
  const _component_ArrowLeftCircleIcon = _resolveComponent("ArrowLeftCircleIcon")!
  const _component_MoonIcon = _resolveComponent("MoonIcon")!
  const _component_SunIcon = _resolveComponent("SunIcon")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_DialogPanel = _resolveComponent("DialogPanel")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!
  const _component_ArrowRightCircleIcon = _resolveComponent("ArrowRightCircleIcon")!
  const _component_Bars3Icon = _resolveComponent("Bars3Icon")!
  const _component_Navbar = _resolveComponent("Navbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Head, null, {
      default: _withCtx(() => [
        _createElementVNode("meta", {
          name: "theme-color",
          content: _ctx.dark ? '#111928' : '#F9FAFB'
        }, null, 8, _hoisted_1),
        _createElementVNode("link", {
          rel: "icon",
          type: "image/svg+xml",
          href: _ctx.dark ? '/favicon-dark.svg' : '/favicon.svg'
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }),
    _createVNode(_component_TransitionRoot, {
      as: "template",
      show: _ctx.open
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Dialog, {
          as: "div",
          class: "relative z-50 lg:hidden",
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.open = false))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TransitionChild, {
              as: "template",
              enter: "transition-opacity ease-linear duration-300",
              "enter-from": "opacity-0",
              "enter-to": "opacity-100",
              leave: "transition-opacity ease-linear duration-300",
              "leave-from": "opacity-100",
              "leave-to": "opacity-0"
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createElementVNode("div", { class: "fixed inset-0 bg-gray-900/50 dark:bg-gray-900/90" }, null, -1)
              ])),
              _: 1
            }),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_TransitionChild, {
                as: "template",
                enter: "transition ease-in-out duration-300 transform",
                "enter-from": "-translate-x-full",
                "enter-to": "translate-x-0",
                leave: "transition ease-in-out duration-300 transform",
                "leave-from": "translate-x-0",
                "leave-to": "-translate-x-full"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DialogPanel, { class: "relative mr-16 flex w-full max-w-xs flex-1" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TransitionChild, {
                        as: "template",
                        enter: "ease-in-out duration-300",
                        "enter-from": "opacity-0",
                        "enter-to": "opacity-100",
                        leave: "ease-in-out duration-300",
                        "leave-from": "opacity-100",
                        "leave-to": "opacity-0"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("button", {
                              type: "button",
                              class: "-m-2.5 p-2.5",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = false))
                            }, [
                              _cache[10] || (_cache[10] = _createElementVNode("span", { class: "sr-only" }, "Close sidebar", -1)),
                              _createVNode(_component_XMarkIcon, {
                                class: "h-6 w-6 text-white",
                                "aria-hidden": "true"
                              })
                            ])
                          ])
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_5, [
                        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "flex h-16 shrink-0 items-center justify-center" }, [
                          _createElementVNode("img", {
                            src: "/dist/images/logo-text.svg",
                            class: "mx-0.5 mt-2 h-8 w-auto dark:filter-white",
                            alt: "Atlant Logo"
                          })
                        ], -1)),
                        _createElementVNode("nav", _hoisted_6, [
                          _createElementVNode("ul", _hoisted_7, [
                            _createElementVNode("li", null, [
                              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$trans('main.navigation')), 1),
                              _createElementVNode("ul", _hoisted_9, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (item) => {
                                  return (_openBlock(), _createElementBlock("li", {
                                    key: item.name
                                  }, [
                                    (item.children && item.show)
                                      ? (_openBlock(), _createBlock(_component_Disclosure, {
                                          key: 0,
                                          as: "div",
                                          defaultOpen: _ctx.getTouch(item.name)
                                        }, {
                                          default: _withCtx(({ open, close }) => [
                                            _createVNode(_component_DisclosureButton, {
                                              onClick: ($event: any) => (_ctx.setTouch(!open, item.name, close)),
                                              class: _normalizeClass([item.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'group flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold'])
                                            }, {
                                              default: _withCtx(() => [
                                                (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                                                  class: _normalizeClass([item.current ? 'text-primary-600' : 'group-hover:text-primary-600 dark:group-hover:text-white', 'text-gray-400 h-6 w-6 shrink-0']),
                                                  "aria-hidden": "true"
                                                }, null, 8, ["class"])),
                                                _createTextVNode(" " + _toDisplayString(_ctx.$trans(item.name)) + " ", 1),
                                                _createVNode(_component_ChevronRightIcon, {
                                                  class: _normalizeClass([open ? 'rotate-90 text-gray-500' : 'text-gray-400', 'ml-auto h-5 w-5 shrink-0']),
                                                  "aria-hidden": "true"
                                                }, null, 8, ["class"])
                                              ]),
                                              _: 2
                                            }, 1032, ["onClick", "class"]),
                                            _createVNode(_component_DisclosurePanel, {
                                              as: "ul",
                                              class: "mt-1 px-2"
                                            }, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (subItem) => {
                                                  return (_openBlock(), _createElementBlock("li", {
                                                    key: subItem.title
                                                  }, [
                                                    (subItem.show)
                                                      ? (_openBlock(), _createBlock(_component_Link, {
                                                          key: 0,
                                                          as: "a",
                                                          href: _ctx.route(subItem.route),
                                                          class: _normalizeClass([subItem.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'block rounded-md py-2 pr-2 pl-9 mt-1 text-sm leading-6'])
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(_ctx.$trans(subItem.title)), 1)
                                                          ]),
                                                          _: 2
                                                        }, 1032, ["href", "class"]))
                                                      : _createCommentVNode("", true)
                                                  ]))
                                                }), 128))
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1032, ["defaultOpen"]))
                                      : (!item.children && item.show)
                                        ? (_openBlock(), _createBlock(_component_Link, {
                                            key: 1,
                                            as: "a",
                                            href: _ctx.route(item.link),
                                            class: _normalizeClass([item.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'])
                                          }, {
                                            default: _withCtx(() => [
                                              (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                                                class: _normalizeClass([item.current ? 'text-primary-600' : 'group-hover:text-primary-600 dark:group-hover:text-white', 'text-gray-400 h-6 w-6 shrink-0']),
                                                "aria-hidden": "true"
                                              }, null, 8, ["class"])),
                                              _createTextVNode(" " + _toDisplayString(_ctx.$trans(item.name)) + " ", 1),
                                              (item.count)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(item.count), 1))
                                                : _createCommentVNode("", true)
                                            ]),
                                            _: 2
                                          }, 1032, ["href", "class"]))
                                        : _createCommentVNode("", true)
                                  ]))
                                }), 128))
                              ])
                            ]),
                            _createElementVNode("li", null, [
                              _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$trans('main.side_menu.projects.title')), 1),
                              _createElementVNode("ul", _hoisted_12, [
                                (_ctx.projects?.length != undefined)
                                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.projects, (project) => {
                                      return (_openBlock(), _createElementBlock("li", {
                                        key: project.name
                                      }, [
                                        _createElementVNode("a", {
                                          href: "#",
                                          class: _normalizeClass([project.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'])
                                        }, [
                                          _createElementVNode("span", {
                                            class: _normalizeClass([project.current ? 'text-primary-600 border-primary-600' : 'text-gray-400 border-gray-200 dark:border-gray-700 group-hover:border-primary-600 dark:group-hover:border-gray-700 group-hover:text-primary-600 dark:group-hover:text-white', 'bg-white dark:bg-gray-800 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium'])
                                          }, _toDisplayString(project.initial), 3),
                                          _createElementVNode("span", _hoisted_13, _toDisplayString(project.name), 1)
                                        ], 2)
                                      ]))
                                    }), 128))
                                  : (_openBlock(), _createElementBlock("li", _hoisted_14, [
                                      _createElementVNode("a", _hoisted_15, [
                                        _cache[11] || (_cache[11] = _createElementVNode("span", { class: "text-gray-400 border-gray-200 dark:border-gray-700 group-hover:border-primary-600 dark:group-hover:border-gray-700 group-hover:text-primary-600 dark:group-hover:text-white bg-white dark:bg-gray-800 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium" }, " + ", -1)),
                                        _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$trans('main.side_menu.projects.placeholder')), 1)
                                      ])
                                    ]))
                              ])
                            ]),
                            _createElementVNode("li", _hoisted_17, [
                              _createElementVNode("button", {
                                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open = !_ctx.open)),
                                class: "text-gray-500 hover:text-gray-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800 inline-flex justify-center p-2 rounded cursor-pointer"
                              }, [
                                _createVNode(_component_ArrowLeftCircleIcon, { class: "w-6 h-6" })
                              ]),
                              _createElementVNode("button", {
                                type: "button",
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.darkMode(!_ctx.dark))),
                                class: "text-gray-500 hover:text-gray-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800 inline-flex justify-center p-2 rounded cursor-pointer"
                              }, [
                                (_ctx.dark)
                                  ? (_openBlock(), _createBlock(_component_MoonIcon, {
                                      key: 0,
                                      class: "w-6 h-6"
                                    }))
                                  : (_openBlock(), _createBlock(_component_SunIcon, {
                                      key: 1,
                                      class: "w-6 h-6"
                                    }))
                              ]),
                              _createVNode(_component_Menu, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_MenuButton, {
                                    as: "button",
                                    class: "inline-flex justify-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_Icon, {
                                        name: `flag-${_ctx.language}`,
                                        mainClass: "h-5 w-5 rounded-full mt-0.5"
                                      }, null, 8, ["name"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_MenuItems, {
                                    as: "div",
                                    style: _normalizeStyle(['transform: translate3d(188px, -64px, 0px);', 'position: absolute; inset: auto auto 0px 0px; margin: 0px;']),
                                    class: "z-50 my-4 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-850"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("ul", _hoisted_18, [
                                        _createVNode(_component_MenuItem, { as: "li" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("a", {
                                              href: _ctx.route('profile.language', {lang: 'en'}),
                                              class: "block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white"
                                            }, [
                                              _createElementVNode("div", _hoisted_20, [
                                                _createVNode(_component_Icon, {
                                                  name: "flag-en",
                                                  mainClass: "h-4 w-4 rounded-full mr-2"
                                                }),
                                                _createTextVNode(" " + _toDisplayString(_ctx.$trans('main.shared.languages.en')), 1)
                                              ])
                                            ], 8, _hoisted_19)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_MenuItem, { as: "li" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("a", {
                                              href: _ctx.route('profile.language', {lang: 'pl'}),
                                              class: "block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white"
                                            }, [
                                              _createElementVNode("div", _hoisted_22, [
                                                _createVNode(_component_Icon, {
                                                  name: "flag-pl",
                                                  mainClass: "h-4 w-4 rounded-full mr-2"
                                                }),
                                                _createTextVNode(" " + _toDisplayString(_ctx.$trans('main.shared.languages.pl')), 1)
                                              ])
                                            ], 8, _hoisted_21)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_MenuItem, { as: "li" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("a", {
                                              href: _ctx.route('profile.language', {lang: 'ua'}),
                                              class: "block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white"
                                            }, [
                                              _createElementVNode("div", _hoisted_24, [
                                                _createVNode(_component_Icon, {
                                                  name: "flag-ua",
                                                  mainClass: "h-4 w-4 rounded-full mr-2"
                                                }),
                                                _createTextVNode(" " + _toDisplayString(_ctx.$trans('main.shared.languages.ua')), 1)
                                              ])
                                            ], 8, _hoisted_23)
                                          ]),
                                          _: 1
                                        })
                                      ])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ])
                          ])
                        ])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["show"]),
    (!_ctx.short)
      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            _cache[14] || (_cache[14] = _createElementVNode("div", { class: "flex h-16 shrink-0 items-center justify-center" }, [
              _createElementVNode("img", {
                src: "/dist/images/logo-text.svg",
                class: "mx-0.5 mt-2 h-8 w-auto dark:filter-white",
                alt: "Atlant Logo"
              })
            ], -1)),
            _createElementVNode("nav", _hoisted_27, [
              _createElementVNode("ul", _hoisted_28, [
                _createElementVNode("li", null, [
                  _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.$trans('main.navigation')), 1),
                  _createElementVNode("ul", _hoisted_30, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (item) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: item.name
                      }, [
                        (item.children && item.show)
                          ? (_openBlock(), _createBlock(_component_Disclosure, {
                              key: 0,
                              as: "div",
                              defaultOpen: _ctx.getTouch(item.name)
                            }, {
                              default: _withCtx(({ open, close }) => [
                                _createVNode(_component_DisclosureButton, {
                                  onClick: ($event: any) => (_ctx.setTouch(!open, item.name, close)),
                                  class: _normalizeClass([item.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'group flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold'])
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                                      class: _normalizeClass([item.current ? 'text-primary-600' : 'group-hover:text-primary-600 dark:group-hover:text-white', 'text-gray-400 h-6 w-6 shrink-0']),
                                      "aria-hidden": "true"
                                    }, null, 8, ["class"])),
                                    _createTextVNode(" " + _toDisplayString(_ctx.$trans(item.name)) + " ", 1),
                                    _createVNode(_component_ChevronRightIcon, {
                                      class: _normalizeClass([open ? 'rotate-90 text-gray-500' : 'text-gray-400', 'ml-auto h-5 w-5 shrink-0']),
                                      "aria-hidden": "true"
                                    }, null, 8, ["class"])
                                  ]),
                                  _: 2
                                }, 1032, ["onClick", "class"]),
                                _createVNode(_component_DisclosurePanel, {
                                  as: "ul",
                                  class: "mt-1 px-2"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (subItem) => {
                                      return (_openBlock(), _createElementBlock("li", {
                                        key: subItem.title
                                      }, [
                                        (subItem.show)
                                          ? (_openBlock(), _createBlock(_component_Link, {
                                              key: 0,
                                              as: "a",
                                              href: _ctx.route(subItem.route),
                                              class: _normalizeClass([subItem.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'block rounded-md py-2 pr-2 pl-9 mt-1 text-sm leading-6'])
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$trans(subItem.title)), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["href", "class"]))
                                          : _createCommentVNode("", true)
                                      ]))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["defaultOpen"]))
                          : (!item.children && item.show)
                            ? (_openBlock(), _createBlock(_component_Link, {
                                key: 1,
                                as: "a",
                                href: _ctx.route(item.link),
                                class: _normalizeClass([item.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'])
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                                    class: _normalizeClass([item.current ? 'text-primary-600' : 'group-hover:text-primary-600 dark:group-hover:text-white', 'text-gray-400 h-6 w-6 shrink-0']),
                                    "aria-hidden": "true"
                                  }, null, 8, ["class"])),
                                  _createTextVNode(" " + _toDisplayString(_ctx.$trans(item.name)) + " ", 1),
                                  (item.count)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_31, _toDisplayString(item.count), 1))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1032, ["href", "class"]))
                            : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ])
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.$trans('main.side_menu.projects.title')), 1),
                  _createElementVNode("ul", _hoisted_33, [
                    (_ctx.projects?.length != undefined)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.projects, (project) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: project.name
                          }, [
                            _createElementVNode("a", {
                              href: "#",
                              class: _normalizeClass([project.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'])
                            }, [
                              _createElementVNode("span", {
                                class: _normalizeClass([project.current ? 'text-primary-600 border-primary-600' : 'text-gray-400 border-gray-200 dark:border-gray-700 group-hover:border-primary-600 dark:group-hover:border-gray-700 group-hover:text-primary-600 dark:group-hover:text-white', 'bg-white dark:bg-gray-800 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium'])
                              }, _toDisplayString(project.initial), 3),
                              _createElementVNode("span", _hoisted_34, _toDisplayString(project.name), 1)
                            ], 2)
                          ]))
                        }), 128))
                      : (_openBlock(), _createElementBlock("li", _hoisted_35, [
                          _createElementVNode("a", _hoisted_36, [
                            _cache[13] || (_cache[13] = _createElementVNode("span", { class: "text-gray-400 border-gray-200 dark:border-gray-700 group-hover:border-primary-600 dark:group-hover:border-gray-700 group-hover:text-primary-600 dark:group-hover:text-white bg-white dark:bg-gray-800 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium" }, " + ", -1)),
                            _createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.$trans('main.side_menu.projects.placeholder')), 1)
                          ])
                        ]))
                  ])
                ]),
                _createElementVNode("li", _hoisted_38, [
                  _createElementVNode("button", {
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setShort(!_ctx.short))),
                    class: "text-gray-500 hover:text-gray-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800 inline-flex justify-center p-2 rounded cursor-pointer"
                  }, [
                    _createVNode(_component_ArrowLeftCircleIcon, { class: "w-6 h-6" })
                  ]),
                  _createElementVNode("button", {
                    type: "button",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.darkMode(!_ctx.dark))),
                    class: "text-gray-500 hover:text-gray-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800 inline-flex justify-center p-2 rounded cursor-pointer"
                  }, [
                    (_ctx.dark)
                      ? (_openBlock(), _createBlock(_component_MoonIcon, {
                          key: 0,
                          class: "w-6 h-6"
                        }))
                      : (_openBlock(), _createBlock(_component_SunIcon, {
                          key: 1,
                          class: "w-6 h-6"
                        }))
                  ]),
                  _createVNode(_component_Menu, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_MenuButton, {
                        as: "button",
                        class: "inline-flex justify-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Icon, {
                            name: `flag-${_ctx.language}`,
                            mainClass: "h-5 w-5 rounded-full mt-0.5"
                          }, null, 8, ["name"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_MenuItems, {
                        as: "div",
                        style: _normalizeStyle([_ctx.short ? 'transform: translate3d(162px, -54px, 0px);' : 'transform: translate3d(162px, -66px, 0px);', 'position: absolute; inset: auto auto 0px 0px; margin: 0px;']),
                        class: "z-50 my-4 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-850"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("ul", _hoisted_39, [
                            _createVNode(_component_MenuItem, { as: "li" }, {
                              default: _withCtx(() => [
                                _createElementVNode("a", {
                                  href: !_ctx.isCurrentLang('en') ? _ctx.route('profile.language', {lang: 'en'}) : '#',
                                  class: "block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white"
                                }, [
                                  _createElementVNode("div", _hoisted_41, [
                                    _createVNode(_component_Icon, {
                                      name: "flag-en",
                                      mainClass: "h-4 w-4 rounded-full mr-2"
                                    }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.$trans('main.shared.languages.en')), 1)
                                  ])
                                ], 8, _hoisted_40)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_MenuItem, { as: "li" }, {
                              default: _withCtx(() => [
                                _createElementVNode("a", {
                                  href: !_ctx.isCurrentLang('pl') ? _ctx.route('profile.language', {lang: 'pl'}) : '#',
                                  class: "block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white"
                                }, [
                                  _createElementVNode("div", _hoisted_43, [
                                    _createVNode(_component_Icon, {
                                      name: "flag-pl",
                                      mainClass: "h-4 w-4 rounded-full mr-2"
                                    }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.$trans('main.shared.languages.pl')), 1)
                                  ])
                                ], 8, _hoisted_42)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_MenuItem, { as: "li" }, {
                              default: _withCtx(() => [
                                _createElementVNode("a", {
                                  href: !_ctx.isCurrentLang('ua') ? _ctx.route('profile.language', {lang: 'ua'}) : '#',
                                  class: "block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white"
                                }, [
                                  _createElementVNode("div", _hoisted_45, [
                                    _createVNode(_component_Icon, {
                                      name: "flag-ua",
                                      mainClass: "h-4 w-4 rounded-full mr-2"
                                    }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.$trans('main.shared.languages.ua')), 1)
                                  ])
                                ], 8, _hoisted_44)
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _: 1
                      }, 8, ["style"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_46, [
          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "flex h-16 shrink-0 items-center justify-center" }, [
            _createElementVNode("img", {
              src: "/dist/images/logo-short.svg",
              class: "mx-0.5 mt-5 h-8 w-auto dark:filter-white",
              alt: "Atlant Logo"
            })
          ], -1)),
          _createElementVNode("nav", _hoisted_47, [
            _createElementVNode("ul", _hoisted_48, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (item) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: item.name
                }, [
                  (item.children && item.show)
                    ? (_openBlock(), _createBlock(_component_Disclosure, {
                        key: 0,
                        as: "div",
                        defaultOpen: _ctx.getTouch(item.name)
                      }, {
                        default: _withCtx(({ open, close }) => [
                          _createVNode(_component_DisclosureButton, {
                            onClick: ($event: any) => (_ctx.setTouch(!open, item.name, close)),
                            class: _normalizeClass([item.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'group flex justify-center items-center w-full text-left rounded-md p-3 gap-x-3 text-sm leading-6 font-semibold'])
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                                class: _normalizeClass([item.current ? 'text-primary-600' : 'group-hover:text-primary-600 dark:group-hover:text-white', 'text-gray-400 h-6 w-6 shrink-0']),
                                "aria-hidden": "true"
                              }, null, 8, ["class"]))
                            ]),
                            _: 2
                          }, 1032, ["onClick", "class"]),
                          _createVNode(_component_DisclosurePanel, {
                            as: "ul",
                            class: "space-y-2 py-2"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (subItem) => {
                                return (_openBlock(), _createElementBlock("li", {
                                  key: subItem.title
                                }, [
                                  (subItem.show)
                                    ? (_openBlock(), _createBlock(_component_Link, {
                                        key: 0,
                                        as: "a",
                                        href: _ctx.route(subItem.route),
                                        class: _normalizeClass([subItem.current ? 'bg-gray-50 dark:bg-gray-800 text-primary-600 dark:text-white' : 'text-gray-700 dark:text-gray-400 hover:text-primary-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'flex justify-center uppercase rounded-md py-2 px-4 text-base font-bold leading-6'])
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$trans(subItem.title).substring(0, 1)), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["href", "class"]))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128))
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["defaultOpen"]))
                    : (!item.children && item.show)
                      ? (_openBlock(), _createBlock(_component_Link, {
                          key: 1,
                          as: "a",
                          href: _ctx.route(item.link),
                          class: _normalizeClass([item.current ? 'bg-primary-50 dark:bg-primary-950 text-primary-600 dark:text-primary-700' : 'text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-gray-600 dark:hover:text-white', 'group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold'])
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                              class: "h-6 w-6 shrink-0",
                              "aria-hidden": "true"
                            })),
                            _createElementVNode("span", _hoisted_49, _toDisplayString(_ctx.$trans(item.name)), 1)
                          ]),
                          _: 2
                        }, 1032, ["href", "class"]))
                      : _createCommentVNode("", true)
                ]))
              }), 128)),
              _createElementVNode("li", _hoisted_50, [
                _createElementVNode("button", {
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setShort(!_ctx.short))),
                  class: "text-gray-500 hover:text-gray-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800 inline-flex justify-center p-2 rounded cursor-pointer"
                }, [
                  _createVNode(_component_ArrowRightCircleIcon, { class: "w-6 h-6" })
                ]),
                _createElementVNode("button", {
                  type: "button",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.darkMode(!_ctx.dark))),
                  class: "text-gray-500 hover:text-gray-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800 inline-flex justify-center p-2 rounded cursor-pointer"
                }, [
                  (_ctx.dark)
                    ? (_openBlock(), _createBlock(_component_MoonIcon, {
                        key: 0,
                        class: "w-6 h-6"
                      }))
                    : (_openBlock(), _createBlock(_component_SunIcon, {
                        key: 1,
                        class: "w-6 h-6"
                      }))
                ]),
                _createVNode(_component_Menu, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_MenuButton, {
                      as: "button",
                      class: "inline-flex justify-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Icon, {
                          name: `flag-${_ctx.language}`,
                          mainClass: "h-5 w-5 rounded-full mt-0.5"
                        }, null, 8, ["name"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_MenuItems, {
                      as: "div",
                      style: _normalizeStyle([_ctx.short ? 'transform: translate3d(-3px, -54px, 0px);' : 'transform: translate3d(162px, -66px, 0px);', 'position: absolute; inset: auto auto 0px 0px; margin: 0px;']),
                      class: "z-50 my-4 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-850"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("ul", _hoisted_51, [
                          _createVNode(_component_MenuItem, { as: "li" }, {
                            default: _withCtx(() => [
                              _createElementVNode("a", {
                                href: !_ctx.isCurrentLang('en') ? _ctx.route('profile.language', {lang: 'en'}) : '#',
                                class: "block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white"
                              }, [
                                _createElementVNode("div", _hoisted_53, [
                                  _createVNode(_component_Icon, {
                                    name: "flag-en",
                                    "main-class": [!_ctx.short && 'mr-2', 'h-4 w-4 rounded-full']
                                  }, null, 8, ["main-class"]),
                                  (!_ctx.short)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_54, _toDisplayString(_ctx.$trans('main.shared.languages.en')), 1))
                                    : _createCommentVNode("", true)
                                ])
                              ], 8, _hoisted_52)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_MenuItem, { as: "li" }, {
                            default: _withCtx(() => [
                              _createElementVNode("a", {
                                href: !_ctx.isCurrentLang('pl') ? _ctx.route('profile.language', {lang: 'pl'}) : '#',
                                class: "block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white"
                              }, [
                                _createElementVNode("div", _hoisted_56, [
                                  _createVNode(_component_Icon, {
                                    name: "flag-pl",
                                    "main-class": [!_ctx.short && 'mr-2', 'h-4 w-4 rounded-full']
                                  }, null, 8, ["main-class"]),
                                  (!_ctx.short)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_57, _toDisplayString(_ctx.$trans('main.shared.languages.pl')), 1))
                                    : _createCommentVNode("", true)
                                ])
                              ], 8, _hoisted_55)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_MenuItem, { as: "li" }, {
                            default: _withCtx(() => [
                              _createElementVNode("a", {
                                href: !_ctx.isCurrentLang('ua') ? _ctx.route('profile.language', {lang: 'ua'}) : '#',
                                class: "block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white"
                              }, [
                                _createElementVNode("div", _hoisted_59, [
                                  _createVNode(_component_Icon, {
                                    name: "flag-ua",
                                    "main-class": [!_ctx.short && 'mr-2', 'h-4 w-4 rounded-full']
                                  }, null, 8, ["main-class"]),
                                  (!_ctx.short)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_60, _toDisplayString(_ctx.$trans('main.shared.languages.ua')), 1))
                                    : _createCommentVNode("", true)
                                ])
                              ], 8, _hoisted_58)
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    }, 8, ["style"])
                  ]),
                  _: 1
                })
              ])
            ])
          ])
        ])),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.short ? 'lg:pl-20' : 'lg:pl-72')
    }, [
      _createVNode(_component_Navbar, null, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            type: "button",
            class: "-m-2.5 p-2.5 text-gray-400 lg:hidden",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.open = true))
          }, [
            _cache[16] || (_cache[16] = _createElementVNode("span", { class: "sr-only" }, "Open sidebar", -1)),
            _createVNode(_component_Bars3Icon, {
              class: "h-6 w-6",
              "aria-hidden": "true"
            })
          ])
        ]),
        _: 1
      }),
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 64))
}