import { v4 as uuidv4 } from 'uuid';
import { Ref, ref } from 'vue';
import dayjs from 'dayjs';

export function autoResizeTextarea(textarea: HTMLTextAreaElement | string | null): void
{
  let textArea = typeof textarea === 'string' ? document.getElementById(textarea) as HTMLTextAreaElement : textarea;
  
  if (textArea) {
    const originalContent = textArea.value;
    
    textArea.style.height = 'auto';
    textArea.style.height = (textArea.scrollHeight > 32) ? '32px' : textArea.scrollHeight + 'px';
    
    textArea.value = originalContent;
    
    const newHeight = Math.max(32, textArea.scrollHeight);

    textArea.style.height = newHeight + 'px';
  }
}

export function updateSyncToken(syncToken: Ref<string | null>, syncTokenTimeout: NodeJS.Timeout | null): string | null
{
  if (syncTokenTimeout !== null) {
    clearTimeout(syncTokenTimeout);
  }

  syncToken.value = uuidv4();

  syncTokenTimeout = setTimeout(() => {
    syncToken.value = null;
  }, 5000);

  return syncToken.value;
}

import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const timers = new WeakMap<Ref<string | null>, number>();

export function useRelativeTime()
{
  const formatRelativeTime = (date: string | null, lang: string): Ref<string | null> => {
    if (!date) return ref(null);

    const formattedTime = ref<string | null>(null);

    const updateTime = () => {
      dayjs.locale(lang);
      const now = dayjs();
      const targetDate = dayjs(date);
      formattedTime.value = targetDate.from(now);
      
      const diffInSeconds = now.diff(targetDate, 'second');
      let updateInterval: number;

      if (diffInSeconds < 60) {
        updateInterval = 5000;
      } else if (diffInSeconds < 3600) {
        updateInterval = 60000;
      } else if (diffInSeconds < 86400) {
        updateInterval = 300000;
      } else {
        updateInterval = 3600000;
      }
      
      const prevTimer = timers.get(formattedTime);

      if (prevTimer) {
        clearTimeout(prevTimer);
      }
      
      const newTimer = window.setTimeout(updateTime, updateInterval);
      timers.set(formattedTime, newTimer);
    };

    updateTime();

    return formattedTime;
  };

  return { formatRelativeTime };
}

import 'dayjs/locale/en';
import 'dayjs/locale/uk';
import 'dayjs/locale/pl';

export function useLocalizedDateFormatter()
{
  const formatLocalizedDate = (date: string | null, locale: string): Ref<string | null> => {
    if (!date) return ref(null);

    const formattedDate = ref<string | null>(null);

    const updateDate = () => {
      const dayjsLocale = locale === 'ua' ? 'uk' : locale;
      dayjs.locale(dayjsLocale);
      const targetDate = dayjs(date);

      switch (locale) {
        case 'en':
          formattedDate.value = targetDate.format('MMM D, YYYY');
          break;
        case 'ua':
          formattedDate.value = targetDate.format('D MMM YYYY р.');
          break;
        case 'pl':
          formattedDate.value = targetDate.format('D MMM YYYY r.');
          break;
        default:
          formattedDate.value = targetDate.format('MMM D, YYYY');
      }
    };

    updateDate();

    const updateInterval = 24 * 60 * 60 * 1000;
    const timer = setInterval(updateDate, updateInterval);

    if(typeof window !== 'undefined'){
      window.addEventListener('beforeunload', () => clearInterval(timer));
    }

    return formattedDate;
  };

  return { formatLocalizedDate };
}

export function formatCustomDate(date: string | null, locale: string): string | null {
  if (!date) return null;

  const dayjsLocale = locale === 'ua' ? 'uk' : locale;
  dayjs.locale(dayjsLocale);
  const targetDate = dayjs(date);
  
  let monthName = targetDate.format('MMMM');
  
  monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
  
  const day = targetDate.format('D');
  const time = targetDate.format('HH:mm');

  return `${monthName} ${day} ${time}`;
}