import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-white dark:bg-gray-800 rounded-md p-3 animate-pulse"
}
const _hoisted_2 = { class: "flex space-x-1" }
const _hoisted_3 = {
  key: 1,
  class: "h-[1px]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.hasMoreCards)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref: "observer",
        class: _normalizeClass(["w-full", _ctx.isVisible && _ctx.hasMoreCards && 'p-2', _ctx.mainClass])
      }, [
        (_ctx.isVisible && _ctx.hasMoreCards)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex justify-between items-center mb-3" }, [
                _createElementVNode("div", { class: "flex-grow" }, [
                  _createElementVNode("div", { class: "h-5 bg-gray-300 dark:bg-gray-600 rounded w-[70%]" })
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (i) => {
                  return _createElementVNode("div", {
                    key: i,
                    class: "w-6 h-6 rounded-full bg-gray- bg-gray-300 dark:bg-gray-600"
                  })
                }), 64))
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3))
      ], 2))
    : _createCommentVNode("", true)
}