<template>
  <div class="float-left mr-2 mb-4 max-w-full">
    <div class="text-gray-900 dark:text-gray-400 inline-flex items-center mb-2 text-sm font-semibold text-center">
      <UserGroupIcon class="w-4 h-4 mr-1"/>
      {{ $trans('main.boards.titles.members') }}
    </div>
    <div class="flex flex-row flex-wrap">
      <div class="flex flex-wrap items-center justify-start -space-x-2">
        <UserAvatar v-for="member in cardMembers" @click="openMenu" :user="member" size="xs" :key="member.id"/>
      </div>
      <Menu v-slot="{ close }">
        <div class="relative inline-block text-left">
          <MenuButton ref="menuButtonRef" class="bg-white dark:bg-gray-700 text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 border-gray-200 dark:border-gray-600 border font-semibold rounded-lg text-nano px-2 py-1 text-center inline-flex items-center"
            :class="{'ml-1': cardMembers.length}"
            as="button">
            <PlusIcon class="w-4 h-4 stroke-2" :class="{'mr-1': !cardMembers.length}"/>
            <template v-if="!cardMembers.length">
              {{ $trans('main.boards.buttons.join') }}
            </template>
          </MenuButton>
          <MenuItems as="div" class="absolute left-0 mt-2 w-60 origin-top-right bg-white dark:bg-gray-950 border-gray-200 dark:border-gray-750 divide-gray-100 dark:divide-gray-750 border z-50 text-black dark:text-white font-sans text-sm leading-5 font-normal rounded-lg shadow-lg outline-none">
            <!-- Header -->
            <div class="items-center grid grid-cols-[32px_1fr_32px] p-[4px_8px] relative text-center">
              <span class="text-gray-500 dark:text-gray-400 z-1 col-[1_/_span_3] box-border block text-sm font-semibold row-start-1 h-10 leading-10 m-0 overflow-hidden px-8 relative overflow-ellipsis whitespace-nowrap">
                {{$trans('main.boards.titles.members')}}
              </span>
              <button @click="close()" class="text-gray-500 dark:text-gray-400 z-2 rounded-md col-start-3 row-start-1 p-1.5">
                <XMarkIcon class="w-4 h-4 stroke-2"/>
              </button>
            </div>
            <!-- Content -->
            <div class="block overflow-x-hidden overflow-y-auto py-0 px-3 pb-3">
              <div class="block max-h-96" :class="{'max-h-96': searchUnselectedMembers.length && searchCardMembers.length}">
                <input v-model="search" @keydown="ignoreSpace" class="border-gray-300 dark:border-gray-750 text-gray-900 dark:text-white focus:ring-gray-700 focus:border-gray-700 bg-gray-50 dark:bg-gray-850 dark:focus:ring-primary-500 dark:focus:border-primary-500 mt-1 block w-full focus:outline-none text-xs rounded-md"/>
                <p v-if="!searchCardMembers.length && !searchUnselectedMembers.length" class="bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-white rounded m-0 p-[24px_6px] text-center">
                  {{ $trans('main.boards.no_results') }}
                </p>
                <template v-if="searchCardMembers.length">
                  <div class="text-black dark:text-white flex items-center">
                    <h4 class="text-nano font-semibold leading-4 mt-4 mb-2">
                      {{$trans('main.boards.titles.card_members')}}
                    </h4>
                  </div>
                  <ul class="text-black dark:text-white">
                    <li v-for="member in searchCardMembers" :key="member.id">
                      <button @click="removeMember(member.id)" class="p-[4px_8px_4px_4px] h-10 flex flex-row items-center justify-start w-full m-0 bg-transparent">
                        <UserAvatar :user="member" :with-tooltip="false" classes="mr-2" size="xs" :key="member.id"/>
                        <div class="flex-1 text-left overflow-hidden overflow-ellipsis whitespace-nowrap">
                          {{ member.name }}
                        </div>
                      </button>
                    </li>
                  </ul>
                </template>
                <template v-if="searchUnselectedMembers.length">
                  <div class="text-black dark:text-white flex items-center">
                    <h4 class="text-nano font-semibold leading-4 mt-4 mb-2">
                      {{$trans('main.boards.titles.board_members')}}
                    </h4>
                  </div>
                  <ul class="text-black dark:text-white">
                    <li v-for="member in searchUnselectedMembers">
                      <button @click="addMember(member)" class="p-[4px_8px_4px_4px] h-10 flex flex-row items-center justify-start w-full m-0 bg-transparent">
                        <UserAvatar :user="member" :with-tooltip="false" classes="mr-2" size="xs" :key="member.id"/>
                        <div class="flex-1 text-left overflow-hidden overflow-ellipsis whitespace-nowrap">
                          {{ member.name }}
                        </div>
                      </button>
                    </li>
                  </ul>
                </template>
              </div>
            </div>
          </MenuItems>
        </div>
      </Menu>
    </div>
  </div>
</template>
<script lang="ts">
import { XMarkIcon, PlusIcon, UserGroupIcon } from '@heroicons/vue/24/outline';
import { defineComponent, toRef, computed, ref, watch, shallowRef } from 'vue';
import { default as BaseBoard } from '@/Types/Resources/Board/Base';
import { default as LightCard } from '@/Types/Resources/Card/Light';
import { Menu, MenuButton, MenuItems } from '@headlessui/vue';
import { PayloadResponse } from '@/Types/API/PayloadResponse';
import { ignoreSpace, sendRequest } from '@/mixins';
import Member from '@/Types/Resources/User/Member';
import UserAvatar from '@/Shared/UserAvatar.vue';

export default defineComponent({
  name: 'MembersMenu',
  emits: [
    'update-card'
  ],
  props: {
    card: {
      type: Object as () => LightCard | null,
      required: true
    },
    board: {
      type: Object as () => BaseBoard | null,
      required: true
    }
  },
  components: {
    UserGroupIcon,
    MenuButton,
    UserAvatar,
    XMarkIcon,
    MenuItems,
    PlusIcon,
    Menu,
  },
  setup(props, { emit }) {
    const menuButtonRef = ref<typeof MenuButton|null>(null);
    const search = ref<string>('');
    const propsBoard = toRef(props, 'board');
    const propsCard = toRef(props, 'card');
    const board = shallowRef<BaseBoard | null>(propsBoard.value);
    const boardMembers = computed<Array<Member>>(() => board.value?.members ?? []);
    const card = shallowRef<LightCard | null>(propsCard.value);
    const cardMembers = computed<Array<Member>>(() => card.value?.members ?? []);
    const unselectedMembers = computed<Array<Member>>(() => {
      return boardMembers.value.filter((member) => {
        return !cardMembers.value.find((cardMember) => cardMember.id === member.id) && member.name.toLowerCase().includes(search.value.toLowerCase());
      });
    });
    const searchCardMembers = computed<Array<Member>>(() => {
      if(search.value === '') {
        return cardMembers.value ?? [];
      }else{
        return cardMembers.value.filter((member) => {
          return member.name.toLowerCase().includes(search.value.toLowerCase());
        }) ?? [];
      }
    });
    const searchUnselectedMembers = computed<Array<Member>>(() => {
      if(search.value === '') {
        return unselectedMembers.value ?? [];
      }else{
        return unselectedMembers.value.filter((member) => {
          return member.name.toLowerCase().includes(search.value.toLowerCase());
        }) ?? [];
      }
    });

    watch(() => propsCard.value, (newCard) => {
      card.value = newCard;
    });

    watch(() => propsBoard.value, (newBoard) => {
      board.value = newBoard;
    });

    function handleUpdateCard(newCard: LightCard): void
    {
      card.value = newCard;

      emit('update-card', newCard);
    }

    function openMenu(): void
    {
      menuButtonRef.value?.$el?.click();
    }

    function addMember(member: Member): void
    {
      sendRequest({
        name: 'api.v1.boards.cards.assign.member',
        params: {
          card: card?.value?.id,
          user: member.id
        },
      }, (data: PayloadResponse<LightCard>) => handleUpdateCard(data.payload));
    }

    function removeMember(memberId: number): void
    {
      sendRequest({
        name: 'api.v1.boards.cards.unassign.member',
        params: {
          card: card?.value?.id,
          user: memberId
        },
      }, (data: PayloadResponse<LightCard>) => handleUpdateCard(data.payload));
    }

    return {
      searchUnselectedMembers,
      searchCardMembers,
      menuButtonRef,
      removeMember,
      ignoreSpace,
      cardMembers,
      addMember,
      openMenu,
      search,
    };
  }
});
</script>