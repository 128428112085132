<template>
  <div v-if="item.icon" :id="`${item.link as string}-head-item`" 
    class="icon-container min-w-6 min-h-6"
    @click="navigateToLink"/>
  <Link class="text-sm transition-all font-medium"
    :href="route(item.link_name != undefined ? item.link_name : item.link, item.link_params ? item.link_params : {})"
    :class="[item.current ? 'text-primary-600 dark:text-primary-500' : itemColor]">
    {{ $trans(item.name) }}
  </Link>
</template>
<script lang="ts">
import HeaderItem from '@/Types/App/Components/HeaderItem';
import { defineComponent, PropType, ref } from 'vue';
import { Link, router } from '@inertiajs/vue3';
import route from '@/ziggy';

export default defineComponent({
  name: 'HeaderItem',
  components: {
    Link,
  },
  props: {
    item: {
      type: Object as PropType<HeaderItem>,
      required: true,
    },
    itemColor: {
      type: Array as PropType<string[]>,
      default: ['text-slate-800', 'dark:text-gray-200'],
    },
  },
  setup(props) {
    const item = ref<HeaderItem>(props.item);

    function navigateToLink(): void
    {
      router.get(route(item.value.link 
        ? item.value.link 
        : item.value.link_name as string, item.value.link_params ? item.value.link_params : {}));
    }
    
    return {
      navigateToLink,
      route,
      item,
    };
  },
});
</script>