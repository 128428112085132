<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="z-50 fixed inset-0 overflow-hidden" @close="close()">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0 bg-black bg-opacity-50 dark:bg-opacity-50" />
        <div class="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10 sm:pr-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-300 sm:duration-500" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-300 sm:duration-500" leave-from="translate-x-0" leave-to="-translate-x-full">
            <div class="pointer-events-auto w-screen max-w-2xl">
              <div class="flex h-full flex-col bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-200 shadow-xl">
                <div class="flex-1 overflow-y-auto mb-8">
                  <!-- Header -->
                  <div class="bg-gray-50 dark:bg-gray-850 px-4 py-6 sm:px-6">
                    <div class="flex justify-between items-start">
                      <div>
                        <!-- Title -->
                        <DialogTitle class="text-lg font-medium text-gray-900 dark:text-white">
                          {{ `${$trans('table.logs.show.title')} #${report?.id}` }}
                        </DialogTitle>
                      </div>
                      <Cross @click="close()"/>
                    </div>
                  </div>
                  <!-- Content -->
                  <div class="space-y-6 px-4 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 dark:sm:divide-gray-700">
                    <div class="max-h-[calc(100vh-200px)]">
                      <div class="grid-cols-2">
                        <div class="mt-5 md:mt-0 md:col-span-2">
                          <div class="p-4">
                            <dl class="space-y-4 text-sm">
                              <div class="flex justify-between">
                                <dt class="font-semibold text-gray-800 dark:text-gray-100" v-html="$trans('table.reports.show.id')"></dt>
                                <dd class="break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300 ">
                                  {{ report?.id ? report.id : $trans('table.shared.no_data') }}
                                </dd>
                              </div>
                              <div class="flex justify-between">
                                <dt class="font-semibold text-gray-800 dark:text-gray-100" v-html="$trans('table.reports.show.key')"></dt>
                                <dd class="break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300">
                                  {{ report?.key ? report.key : $trans('table.shared.no_data') }}
                                </dd>
                              </div>
                              <div class="flex justify-between">
                                <dt class="font-semibold text-gray-800 dark:text-gray-100" v-html="$trans('table.reports.show.user_id')"></dt>
                                <dd class="break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300">
                                  {{ report?.user_id ? report.user_id : $trans('table.shared.no_data') }}
                                </dd>
                              </div>
                              <div class="flex justify-between">
                                <dt class="font-semibold text-gray-800 dark:text-gray-100" v-html="$trans('table.reports.show.user_name')"></dt>
                                <dd class="break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300">
                                  {{ report?.user_name ? report.user_name : $trans('table.shared.no_data') }}
                                </dd>
                              </div>
                              <div class="flex justify-between">
                                <dt class="font-semibold text-gray-800 dark:text-gray-100" v-html="$trans('table.reports.show.ip_address')"></dt>
                                <dd class="break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300">
                                  {{ report?.ip_address ? report.ip_address : $trans('table.shared.no_data') }}
                                </dd>
                              </div>
                              <div class="flex justify-between">
                                <dt class="font-semibold text-gray-800 dark:text-gray-100" v-html="$trans('table.reports.show.data')"></dt>
                                <dd class="break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300">
                                  {{ report?.data ? report.data : $trans('table.shared.no_data') }}
                                </dd>
                              </div>
                              <div class="flex items-start justify-between">
                                <dt class="font-semibold text-gray-800 dark:text-gray-100" v-html="$trans('table.reports.show.info')"></dt>
                                <dd class="break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300">
                                  {{ report?.info ? report.info : $trans('table.shared.no_data') }}
                                </dd>
                              </div>
                              <div class="flex items-start justify-between">
                                <dt class="font-semibold text-gray-800 dark:text-gray-100" v-html="$trans('table.reports.show.success')"></dt>
                                <dd class="break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300">
                                  <Badge v-if="report?.success_type != null" :type="report?.success_type" :text="report?.is_success"/>
                                </dd>
                              </div>
                              <div class="flex justify-between">
                                <dt class="font-semibold text-gray-800 dark:text-gray-100" v-html="$trans('table.reports.show.emailed_at')"></dt>
                                <dd class="break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300">
                                  {{ report?.emailed_at ? report.emailed_at : $trans('table.shared.no_data') }}
                                </dd>
                              </div>
                              <div class="flex justify-between">
                                <dt class="font-semibold text-gray-800 dark:text-gray-100" v-html="$trans('table.reports.show.created_at')"></dt>
                                <dd class="break-all font-sm font-mono overflow-auto text-gray-600 dark:text-gray-300">
                                  {{ report?.created_at ? report.created_at : $trans('table.shared.no_data') }}
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Footer -->
                <div class="flex-shrink-0 border-t border-gray-200 dark:border-gray-750 px-4 py-5 sm:px-6">
                  <div class="flex justify-end space-x-3">
                    <div class="flex justify-end space-x-3">
                      <button @click="close" class="bg-gray-900 dark:bg-primary-800 hover:bg-gray-700 dark:hover:bg-primary-700 text-white dark:text-gray-100 focus:ring-gray-700 dark:focus:ring-primary-950 focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                        {{$trans('table.shared.close')}}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script lang="ts">
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { default as ReportList } from '@/Types/Resources/Report/List';
import { defineComponent, PropType, toRef } from "vue";
import Cross from '@js/Shared/Cross.vue';
import Badge from '@js/Shared/Badge.vue';

export default defineComponent({
  components: {
    TransitionChild,
    TransitionRoot,
    DialogOverlay,
    DialogTitle,
    Dialog,
    Cross,
    Badge
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function,
      default: () => {}
    },
    report: {
      type: [Object, null] as PropType<ReportList | null>,
      required: false
    }
  },
  setup (props) {
    const open = toRef(props, 'isOpen');

    function close(): void
    {
      props.onClose();
    }

    return {
      close,
      open,
    };
  }
});
</script>