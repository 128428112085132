<template>
  <Head :title="$trans('main.sign_in')"/>
  <Layout>
    <div class="relative flex flex-1 flex-col items-center justify-center pb-16 pt-12">
      <Logo/>
      <h1 class="sr-only" v-text="$trans('main.sign_in')" />
      <p v-if="form.invalid('email') || form.invalid('password')" class="mb-2 sm:mb-6 text-center text-mini font-medium text-red-600 dark:text-red-500">
        {{ form.errors.email || form.errors.password }}
      </p>
      <form @submit.prevent="submit" class="w-full max-w-sm sm:bg-white sm:dark:bg-gray-900 sm:border-gray-100 sm:dark:border-gray-750 sm:p-5 sm:border-2 sm:bg-opacity-80 sm:backdrop-blur-sm sm:backdrop-filter sm:rounded-md">
        <div class="mb-6">
          <Input id="email" 
            type="email"
            name="email"
            label="main.email" 
            autocomplete="on"
            v-model="form.email"
            :with-error="false"
            :required="true"
            :with-precognition="false"
            :form="form as any"/>
        </div>
        <div class="mb-6">
          <Input id="password" 
            type="password"
            name="password"
            label="main.password" 
            autocomplete="on"
            v-model="form.password"
            :with-error="false"
            :required="true"
            :with-precognition="false"
            :form="form as any"/>
        </div>
        <div class="mb-4 flex items-start">
          <div class="flex items-center h-5">
            <input id="remember" 
              type="checkbox"
              name="remember" 
              aria-describedby="remember"
              v-model="form.remember" 
              class="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 dark:ring-offset-gray-800 dark:bg-gray-850 dark:border-gray-750"/>
          </div>
          <div class="ml-3 text-sm">
          <label for="remember" class="font-medium text-gray-900 dark:text-white">
            {{ $trans("main.remember_me") }}
          </label>
          </div>
          <Link as="a" :href="route('reset')" class="ml-auto text-sm text-primary-700 hover:underline dark:text-primary-500">
            {{ $trans("main.forgot_password") }}
          </Link>
        </div>
        <Submit title="main.login" 
          :processing="form.processing"/>
        <!-- OAuth Socials -->
        <!-- <div class="mt-6">
          <div class="relative">
            <div class="absolute inset-0 flex items-center">
              <div class="border-gray-300 dark:border-gray-750 w-full border-t"/>
            </div>
            <div class="relative flex justify-center text-sm">
              <span v-text="$trans('main.or_with')" class="px-2 bg-white dark:bg-gray-900 sm:dark:bg-gray-900 text-gray-500 dark:text-gray-300"/>
            </div>
          </div>
          <div class="mt-6 grid grid-cols-3 gap-3">
            <div>
              <a href="#" class="sm:bg-opacity-80 sm:backdrop-blur-sm sm:backdrop-filter w-full inline-flex justify-center py-2 px-4 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm bg-white dark:bg-gray-800 text-sm font-medium text-gray-500 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-750">
                <span v-text="$trans('main.sr_sign_in_google')" class="sr-only"/>
                <svg class="w-5 h-5 filter-black dark:filter-white" aria-hidden="true" fill="currentColor" viewBox="0 0 48 48">
                  <g clip-path="url(#a)">
                    <path fill="#000" fill-rule="evenodd" d="M33.687 13.096a13.042 13.042 0 0 0-9.207-3.598c-6.261 0-11.579 4.224-13.475 9.912a14.376 14.376 0 0 0 0 9.189h.009c1.905 5.68 7.213 9.903 13.475 9.903 3.232 0 6.007-.827 8.157-2.287v-.006a11.109 11.109 0 0 0 4.797-7.293H24.48v-9.242h22.638c.282 1.605.414 3.246.414 4.877 0 7.3-2.609 13.471-7.148 17.651l.005.004C36.412 45.875 30.953 48 24.48 48A24.003 24.003 0 0 1 3.033 34.78a24.02 24.02 0 0 1 0-21.552A23.995 23.995 0 0 1 24.48 0a23.067 23.067 0 0 1 16.059 6.244l-6.852 6.852Z" clip-rule="evenodd"/>
                  </g>
                  <defs>
                    <clipPath id="a">
                      <path fill="#fff" d="M0 0h48v48H0z"/>
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
            <div>
              <a href="#" class="sm:bg-opacity-80 sm:backdrop-blur-sm sm:backdrop-filter w-full inline-flex justify-center py-2 px-4 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm bg-white dark:bg-gray-800 text-sm font-medium text-gray-500 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-750">
                <span v-text="$trans('main.sr_sign_in_facebook')" class="sr-only"/>
                <svg class="w-5 h-5 filter-black dark:filter-white" aria-hidden="true" fill="currentColor" viewBox="0 0 48 48">
                  <g clip-path="url(#a)">
                    <path fill="#000" d="M48 24C48 10.745 37.255 0 24 0S0 10.745 0 24c0 11.979 8.776 21.908 20.25 23.708v-16.77h-6.094V24h6.094v-5.288c0-6.014 3.583-9.337 9.065-9.337 2.625 0 5.372.469 5.372.469v5.906h-3.026c-2.981 0-3.911 1.85-3.911 3.75V24h6.656l-1.064 6.938H27.75v16.77C39.224 45.908 48 35.978 48 24Z"/>
                  </g>
                  <defs>
                    <clipPath id="a">
                      <path fill="#fff" d="M0 0h48v48H0z"/>
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
            <div>
              <a href="#" class="sm:bg-opacity-80 sm:backdrop-blur-sm sm:backdrop-filter w-full inline-flex justify-center py-2 px-4 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm bg-white dark:bg-gray-800 text-sm font-medium text-gray-500 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-750">
                <span v-text="$trans('main.sr_sign_in_linkedin')" class="sr-only"/>
                <svg class="w-5 h-5 filter-black dark:filter-white" aria-hidden="true" fill="currentColor" viewBox="0 0 48 48">
                  <g clip-path="url(#a)">
                    <path fill="#000" d="M44.447 0H3.544C1.584 0 0 1.547 0 3.46V44.53C0 46.444 1.584 48 3.544 48h40.903C46.407 48 48 46.444 48 44.54V3.46C48 1.546 46.406 0 44.447 0ZM14.24 40.903H7.116V17.991h7.125v22.912ZM10.678 14.87a4.127 4.127 0 0 1-4.134-4.125 4.127 4.127 0 0 1 4.134-4.125 4.125 4.125 0 0 1 0 8.25Zm30.225 26.034h-7.115V29.766c0-2.653-.047-6.075-3.704-6.075-3.703 0-4.265 2.896-4.265 5.887v11.325h-7.107V17.991h6.826v3.13h.093c.947-1.8 3.272-3.702 6.731-3.702 7.21 0 8.541 4.744 8.541 10.912v12.572Z"/>
                  </g>
                  <defs>
                    <clipPath id="a">
                      <path fill="#fff" d="M0 0h48v48H0z"/>
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
        </div> -->
      </form>
    </div>
    <footer class="relative shrink-0">
      <div class="space-y-4 text-sm text-gray-900 sm:flex sm:items-center sm:justify-center sm:space-x-4 sm:space-y-0">
        <div class="flex justify-center font-medium text-gray-500 dark:text-gray-400">
          <p v-text="$trans('main.not_registered') " class="text-center sm:text-left"/>
          <br/>
          <Link as="a" :href="route('register')" class="text-primary-700 hover:underline dark:text-primary-500">
            {{ $trans('main.create_account') }}
          </Link>
        </div>
      </div>
    </footer>
  </Layout>
</template>
<script lang="ts">
import { useForm } from 'laravel-precognition-vue-inertia';
import { Head, Link } from '@inertiajs/vue3';
import Layout from './Components/Layout.vue';
import Submit from './Components/Submit.vue';
import Input from './Components/Input.vue';
import Logo from './Components/Logo.vue';
import Toast from '@/Shared/Toast.vue';
import { defineComponent } from 'vue';
import route from '@/ziggy';

export default defineComponent({
  components: {
    Layout,
    Submit,
    Input,
    Toast,
    Logo,
    Link,
    Head,
  },
  setup() {
    const form = useForm('post', route('login.store'), {
      email: '',
      password: '',
      remember: false,
    });

    const submit = () => form.submit({
      preserveScroll: true,
      onSuccess: () => form.reset(),
    });

    return {
      submit,
      route,
      form,
    };
  },
})
</script>