<template>
  <g ref="groupRef" class="opacity-0">
    <circle
      ref="circleRef"
      :cx="cx"
      :cy="cy"
      r="1"
      :style="circleStyle"
      :filter="filter"
    />
  </g>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, PropType } from 'vue';
import { animate } from 'motion';

type StarType = [number, number, boolean?, boolean?];

export default defineComponent({
  name: 'Star',
  props: {
    blurId: {
      type: String,
      required: true,
    },
    point: {
      type: null as unknown as PropType<StarType>,
      required: true,
    },
  },
  setup(props) {
    const groupRef = ref<SVGGElement | null>(null);
    const circleRef = ref<SVGCircleElement | null>(null);

    const [cx, cy, dim, blur] = props.point;

    const circleStyle = computed(() => ({
      transformOrigin: `${cx / 16}rem ${cy / 16}rem`,
      opacity: dim ? 0.2 : 1,
      transform: `scale(${dim ? 1 : 1.2})`,
    }));

    const filter = computed(() => (blur ? `url(#${props.blurId})` : undefined));

    onMounted(() => {
      if (!groupRef.value || !circleRef.value) return;

      const delay = Math.random() * 2;

      const animations = [
        animate(groupRef.value, { opacity: 1 }, { duration: 4, delay }),
        animate(
          circleRef.value,
          {
            opacity: dim ? [0.2, 0.5] : [1, 0.6],
            scale: dim ? [1, 1.2] : [1.2, 1],
          },
          {
            delay,
            duration: Math.random() * 2 + 2,
            direction: 'alternate',
            repeat: Infinity,
          },
        ),
      ];

      return () => {
        animations.forEach((animation) => animation.cancel());
      };
    });

    return {
      groupRef,
      circleRef,
      cx,
      cy,
      circleStyle,
      filter,
    };
  },
});
</script>