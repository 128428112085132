<template>
  <div class="sticky bottom-[-1px] right-0 lg:rounded-b-lg items-center w-full p-4 bg-white border-t border-gray-200 sm:flex sm:justify-between dark:bg-gray-900 dark:border-gray-850 ">
    <div class="flex items-center mb-8 sm:mb-0 justify-center sm:justify-end">
      <Link as="button" :disabled="!data.prev_page_url" :href="data.prev_page_url ? data.prev_page_url : `#`" preserve-scroll class="hidden sm:inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-850 dark:hover:text-white">
        <svg class="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"></path>
        </svg>
      </Link>
      <Link as="button" :disabled="!data.next_page_url" :href="data.next_page_url ? data.next_page_url : `#`" preserve-scroll class="hidden sm:inline-flex justify-center p-1 mr-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-850 dark:hover:text-white">
        <svg class="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
        </svg>
      </Link>
      <span class="text-sm font-normal select-none text-gray-500 dark:text-gray-400">
        {{$trans('pagination.showing')}} 
        <span class="font-semibold text-gray-900 dark:text-white" v-html="`${data.from ? data.from : 0} - ${data.to ? data.to : 0}`">
        </span>
        {{$trans('pagination.of')}}
        <span class="font-semibold text-gray-900 dark:text-white">
          {{ splitNumber(data.total) }}
        </span>
      </span>
    </div>
    <div class="flex items-center space-x-3">
      <Link v-if="data.prev_page_url" as="a" :href="data.prev_page_url" preserve-scroll class="bg-gray-900 dark:bg-primary-800  text-white dark:text-gray-100 hover:bg-gray-700 dark:hover:bg-primary-750 focus:ring-gray-700 dark:focus:ring-primary-950 focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent select-none inline-flex items-center justify-center flex-1 px-3 py-2 text-sm font-medium text-center rounded-lg">
        <svg class="w-5 h-5 mr-1 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"></path>
        </svg>
        {{$trans('pagination.previous_title')}}
      </Link>
      <Link v-if="data.next_page_url" as="a" :href="data.next_page_url" preserve-scroll class="bg-gray-900 dark:bg-primary-800  text-white dark:text-gray-100 hover:bg-gray-700 dark:hover:bg-primary-750 focus:ring-gray-700 dark:focus:ring-primary-950 focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent select-none inline-flex items-center justify-center flex-1 px-3 py-2 text-sm font-medium text-center rounded-lg">
        {{$trans('pagination.next_title')}}
        <svg class="w-5 h-5 ml-1 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
        </svg>
      </Link>
    </div>
  </div>
</template>
<script lang="ts">
import Paginator from '@/Types/App/Paginator';
import { Link } from '@inertiajs/vue3';
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    data: {
      type: Object as () => Paginator<any>,
      required: true
    }
  },
  components: {
    Link,
  },
  setup(){
    const splitNumber = (value: number|null) => {
      return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };
    
    return { splitNumber };
  }
});
</script>