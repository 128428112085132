<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto" @close="setAttemptModalIsOpen(false)">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-black bg-opacity-50"/>
        </TransitionChild>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template"
          enter="ease-out duration-300" 
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" 
          enter-to="opacity-100 translate-y-0 sm:scale-100" 
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100" 
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white dark:bg-gray-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 dark:bg-red-400 sm:mx-0 sm:h-10 sm:w-10">
                <svg class="h-6 w-6 text-red-600 dark:text-red-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-400">
                  {{title}}
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-600 dark:text-gray-400">
                    {{description}}
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
              <template v-if="isApi">
                <button @click="send()" class="bg-red-700 dark:bg-red-600 text-white hover:bg-red-800 dark:hover:bg-red-700 focus:ring-red-700 dark:focus:ring-red-800 rounded-lg focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent sm:w-auto sm:text-sm w-full justify-center inline-flex items-center px-3 py-2 text-sm font-medium text-center">
                  {{successButtonText}}
                </button>
              </template>
              <template v-else>
                <Link as="button" preserve-scroll :href="route(routeName, routeArgument)" :method="routeMethod" @click="setAttemptModalIsOpen(false, true)" class="bg-red-700 dark:bg-red-600 text-white hover:bg-red-800 dark:hover:bg-red-700 focus:ring-red-700 dark:focus:ring-red-800 rounded-lg focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent sm:w-auto sm:text-sm w-full justify-center inline-flex items-center px-3 py-2 text-sm font-medium text-center">
                  {{successButtonText}}
                </Link>
              </template>
              <button type="button" @click="setAttemptModalIsOpen(false)" class="bg-primary-700 dark:bg-primary-600 hover:bg-primary-800 dark:hover:bg-primary-700 focus:ring-primary-700 dark:focus:ring-primary-800 text-white rounded-lg sm:mt-0 sm:w-auto sm:text-sm inline-flex w-full mt-3 justify-center focus:ring-2 focus:ring-offset-2 dark:ring-offset-transparent items-center px-3 py-2 sm:ml-3 text-sm font-medium text-center">
                {{cancelButtonText}}
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script lang="ts">
import { TransitionChild, TransitionRoot, DialogOverlay, DialogTitle, Dialog } from '@headlessui/vue';
import { defineComponent, toRef } from 'vue';
import route, { RouteName } from '@/ziggy';
import { Method } from '@inertiajs/core';
import { sendRequest } from '@/mixins';
import { Link } from '@inertiajs/vue3';

export default defineComponent({
  props: {
    open: {
      type: Boolean,
      required: true
    },
    close: {
      type: Function,
      default: () => {}
    },
    routeName: {
      type: String as () => RouteName,
      required: true
    },
    routeArgument: {
      type: Object as () => {[key: string]: string | number | boolean;},
      required: true
    },
    routeMethod: {
      type: (String as () => Method),
      default: 'delete'
    },
    title: {
      type: String,
      default: 'Deactivate account'
    },
    description: {
      type: String,
      default: 'Are you sure you want to deactivate your account? All of your data will be permanently removed from our servers forever. This action cannot be undone.'
    },
    successButtonText: {
      type: String,
      default: 'Deactivate'
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel'
    },
    deleteCallback: {
      type: Function,
      default: () => {}
    },
    isApi: {
      type: Boolean,
      default: false
    },
    sendCallback: {
      type: Function,
      default: () => {}
    }
  },
  components: {
    TransitionChild,
    TransitionRoot,
    DialogOverlay,
    DialogTitle,
    Dialog,
    Link,
  },
  setup(props) {
    const open = toRef(props, 'open');
    const routeName = toRef(props, 'routeName');
    const routeArgument = toRef(props, 'routeArgument');

    function setAttemptModalIsOpen(value: Boolean, withDeleteCallback: Boolean = false)
    {
      props.close(value);
      if(withDeleteCallback) props.deleteCallback();
    }

    function send(): void
    {
      sendRequest({
        name: routeName.value,
        params: routeArgument.value,
      }, () => props.sendCallback());

      setAttemptModalIsOpen(false, true);
    }

    return {
      setAttemptModalIsOpen,
      routeArgument,
      routeName,
      route,
      send,
      open,
    }
  },
});
</script>